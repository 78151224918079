<template>
    <div style="width: 140px;">
        <el-tooltip :content="tooltip" placement="top" :disabled="!tooltip">
            <div v-if="order.doc" class="d-flex-end f-direction-column gap-4 w-fit ml-auto">
            В АСУ - #{{ order.doc }}
                <div>
                    <template v-if="order.status === 'new'">
                        <div class="group-orders-status-text group-orders-status-text-queue">
                            Очередь
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="order.status === 'loading'" class="d-flex-end">
                            <SmallLoader v-if="order.doc" class="mr-5"/>
                            <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                                Загрузка
                            </span>
                        </div>
                        <template v-else>
                            <span class="group-orders-status-text group-orders-status-text-done">
                                Выполнена
                            </span>
                        </template>
                    </template>
                </div>
            </div>
            <div v-else class="d-flex-end f-direction-column gap-4 w-fit ml-auto">
                Не отправлена в АСУ
                <div class="group-orders-status-text group-orders-status-text-queue">
                    Очередь
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";

export default {
    name: "OperatorPromServ",
    props: ['order'],
    components: {SmallLoader},
    created() {
    },
    computed: {
        tooltip() {
            let content = ''
            if(!this.order.confirm
            || this.order.status === 'done'
            || this.order.status === 'delivery') {
                return content
            }
            if(this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            else if(!this.order.doc) {
                content = 'Идет отправка данных'
            } else if(this.order.doc) {
                content = 'Ожидание начала загрузки'
            }
        return content
        }
    },
}
</script>

<style scoped>

</style>
