<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="position: relative; top: -1px; margin-right: 5px"
    >
        <path
            d="M2.66602 13.3334H13.3327"
            stroke="#1984E6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M2.66602 13.3333H5.33268L12.8613 5.80468C13.1216 5.54433 13.1216 5.12222 12.8613 4.86187L11.1374 3.13801C10.8771 2.87766 10.455 2.87766 10.1946 3.13801L2.66602 10.6666V13.3333Z"
            stroke="#1984E6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "EditIcon",
};
</script>
