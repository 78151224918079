<template>
    <ModuleSettingsRow>
        <template v-slot:info>
            <h3>Доступ</h3>
            <p>В данном разделе вы можете дать или отнять доступ для определенных пользователей к модулю</p>
        </template>
        <template v-slot:content>
            <el-form v-model="moduleForm" size="medium" label-position="top" v-loading="loading">
                <el-form-item label="Пользователи, которым доступен модуль:">
                    <el-checkbox-group class="inlineCheckbox" v-model="moduleForm.users">
                        <el-checkbox v-for="user in users" :key="user.id" :label="user.id">{{
                                user.name
                            }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item>
                    <el-button @click="doEdit" :loading="loading"
                               type="primary">Сохранить изменения
                    </el-button>
                </el-form-item>
            </el-form>
        </template>
    </ModuleSettingsRow>
</template>

<script>
import {httpService} from "@/services/http.service";
import ModuleSettingsRow from "@/views/components/SettingsPage/Modules/ModuleSettingsRow.vue";

export default {
    name: 'ModuleAccessSettings',
    components: {ModuleSettingsRow},
    props: ['module', 'users'],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            moduleForm: {
                name: null,
                users: [],
                integrations: [],
                relationsSender: [],
                relationsRecipient: [],
                details: null,
            }
        }
    },
    methods: {
        doEdit() {
            this.loading = true

            httpService().post(this.api.module.editOne, {
                moduleId: this.$route.params.id,
                module: this.module
            }).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('moduleGet')

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        doDelete(id) {
            this.$confirm(
                'Вы действительно хотите удалить модуль из системы?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true
                    httpService().post(this.api.module.deleteOne, {id}).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('closeDrawer')
                        this.$message({
                            message: 'Модуль удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.reloadNav = !this.reloadNav
                        this.$router.push({name: 'ModulesIndex'})
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            }).finally(() => {
                this.reloadNav = !this.reloadNav
            })
        },
    },
    watch: {
        'module': {
            handler: function () {
                if (this.module) {
                    this.moduleForm = this.module
                    if (this.moduleForm.name) this.loading = false
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
