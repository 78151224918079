<template>
    <el-row :gutter="15" class="mb-10">
        <el-col :sm="9">
            <span class="textSM textRegular neutral300">{{ head }}:</span>
        </el-col>
        <el-col :sm="15">
            <slot name="text"/>
            <span class="textSM textRegular neutral900">{{ text }}</span>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: 'CRMInfoText',
    props: ['head', 'text']
}
</script>

<style scoped>

</style>
