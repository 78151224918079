<template>
    <ModuleSettingsRow>
        <template v-slot:info>
            <h3>Общие настройки</h3>
            <p>В данном разделе вы можете изменить название модуля и ссылки в меню, а также поменять объект, к которому
                привязан модуль</p>
        </template>
        <template v-slot:content>
            <el-form v-model="moduleForm" size="medium" label-position="top" v-loading="loading">
                <FormInput
                    v-model="moduleForm.name"
                    label="Название:"
                    placeholder="Н-р: Денежный учет"
                    inputMask="$$$$$$$$$$$$$$$$$$$$$$$"
                    maskTokens="$:[\s\S]:"
                />
                <FormSelect
                    v-model="moduleForm.factory"
                    label="Объект:"
                    placeholder="Выберите объект"
                    :items="factories"
                    :noClearable="true"
                />

                <el-form-item v-if="!mobile">
                    <el-button @click="doEdit" :loading="loading"
                               type="primary">Сохранить изменения
                    </el-button>
                    <el-button @click="doDelete($route.params.id)" type="danger" plain>Удалить
                        модуль
                    </el-button>
                </el-form-item>

                <el-form-item v-if="mobile">
                    <el-button @click="doEdit" :loading="loading"
                               type="primary">Сохранить изменения
                    </el-button>
                    <el-button @click="doDelete($route.params.id)" :icon="icons.delete" plain
                               type="danger"/>
                </el-form-item>
            </el-form>
        </template>
    </ModuleSettingsRow>
</template>

<script>
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import ModuleSettingsRow from "@/views/components/SettingsPage/Modules/ModuleSettingsRow.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'ModuleGeneralSettings',
    components: {ModuleSettingsRow, FormSelect, FormInput},
    props: ['module', 'factories'],
    mixins: [mobileCheckMixin, iconsMixin],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            moduleForm: {
                name: null,
                users: [],
                integrations: [],
                relationsSender: [],
                relationsRecipient: [],
                details: null,
            },
        }
    },
    methods: {
        doEdit() {
            this.loading = true

            httpService().post(this.api.module.editOne, {
                moduleId: this.$route.params.id,
                module: this.module
            }).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('moduleGet')

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        doDelete(id) {
            this.$confirm(
                'Вы действительно хотите удалить модуль из системы?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true
                    httpService().post(this.api.module.deleteOne, {id}).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('deleteModule')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            }).finally(() => {
                this.$emit('deleteModule')
            })
        },
    },
    watch: {
        'module': {
            handler: function () {
                if (this.module) {
                    this.moduleForm = this.module
                    if (this.moduleForm.name) this.loading = false
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
