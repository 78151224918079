<template>
    <Auth title="Действия пользователей" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-create-button="true"
                    @moduleGet="moduleGet"
                />

                <el-table
                    v-loading="loading"
                    ref="table"
                    :data="displayData"
                    class="mt-15 w-100"
                >
                    <el-table-column
                        prop="date"
                        width="200px"
                        label="Дата">
                        <template v-slot="scope">
                            <span class="textRegular textSM neutral400">{{ getDateFromUnix(scope.row.date) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="user"
                        width="290px"
                        label="Пользователь">
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral700 cutOverflow">{{ scope.row.user }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        width="210px"
                        label="Действие">
                        <template v-slot="scope">
                            <span class="textMedium textSM success400">{{ scope.row.type }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="info"
                        label="Информация">
                        <template v-slot="scope">
                            <div class="textRegular textSM neutral400">
                                <template v-if="scope.row.type === 'Сформировал отчет'">
                                    {{ scope.row.info.comment }}
                                    <template v-if="scope.row.info.date_start && scope.row.info.date_end">
                                        за период {{ getDateFormDB(scope.row.info.date_start, 'shortDate') }} - {{ getDateFormDB(scope.row.info.date_end, 'shortDate') }}
                                    </template>
                                </template>
                                <template v-else-if="scope.row.type === 'Изменил отгрузку' || scope.row.type === 'Создал отгрузку'">
                                    Отгрузка id #{{ scope.row.info.mix_order_id }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import { httpService } from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'UsersLogs',
    components: {
        TablePagination,
        TableHeadBlock,
        Auth
    },
    data() {
        return {
            loading: true,
            search: '',
            filtered: [],
            page: 1,
            pageSize: 10,
            valueLength: 0,
            tabs: [{
                route: 'UsersIndex',
                name: 'Пользователи'
            }, {
                route: 'UsersLogs',
                name: 'Действия'
            }]
        }
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.users.getLogs).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.values
                }

            })
        },
        setPage(val) {
            this.page = val
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
