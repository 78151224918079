import {defineStore} from 'pinia'
import Echo from "laravel-echo";
import io from "socket.io-client"


export const useEchoStore = defineStore({
    id: 'echo',
    state: () => ({
        echo: null
    }),
    getters: {
        Echo(state) {
            if (!state.echo) {
                window.io = io

                // console.log('process.env.VUE_APP_SOCKET_HOST', process.env.VUE_APP_SOCKET_HOST)
                // console.log('process.env.VUE_APP_SOCKET_PATH', process.env.VUE_APP_SOCKET_PATH)
                //
                // console.log('process.env', process.env)

                state.echo = new Echo({
                    broadcaster: 'socket.io',
                    host: process.env.VUE_APP_SOCKET_HOST,
                    // path: process.env.VUE_APP_SOCKET_PATH,
                    enabledTransports: ['wss'],
                    auth: {headers: {Authorization: `Bearer ${localStorage.getItem('api_token')}`}}
                })
            }
            return state.echo
        },
    },
    actions: {
        testEventListener() {
            this.Echo.channel('laravel_database_test-event')
                .listen('ExampleEvent', (data) => {
                    console.log('Event received:', data);
                })
                .listen('GetModulesEvent', data => {
                    console.log('getOne(685)', data)
                })
        },
    },

})
