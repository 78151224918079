<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.01339 1.93994C5.80673 1.93994 4.01339 3.73327 4.01339 5.93994V7.86661C4.01339 8.27327 3.84006 8.89327 3.63339 9.23994L2.86673 10.5133C2.39339 11.2999 2.72006 12.1733 3.58673 12.4666C6.46006 13.4266 9.56006 13.4266 12.4334 12.4666C13.2401 12.1999 13.5934 11.2466 13.1534 10.5133L12.3867 9.23994C12.1867 8.89327 12.0134 8.27327 12.0134 7.86661V5.93994C12.0134 3.73994 10.2134 1.93994 8.01339 1.93994Z"
            stroke-miterlimit="10" stroke-linecap="round"/>
        <path
            d="M9.2467 2.13333C9.04003 2.07333 8.8267 2.02667 8.6067 2C7.9667 1.92 7.35336 1.96667 6.78003 2.13333C6.97336 1.64 7.45336 1.29333 8.01336 1.29333C8.57336 1.29333 9.05336 1.64 9.2467 2.13333Z"
            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M10.0133 12.7067C10.0133 13.8067 9.11331 14.7067 8.01331 14.7067C7.46664 14.7067 6.95997 14.48 6.59997 14.12C6.23997 13.76 6.01331 13.2533 6.01331 12.7067"
            stroke-miterlimit="10"/>
    </svg>
</template>

<script>
export default {
    name: "NotificationIcon"
}
</script>
