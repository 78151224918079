<template>
    <Auth title="Тарифы услуг">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :filterShow="filterShow"
                    create-button-label="Добавить тариф"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateDialog()"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                    :filter-items1="serviceTypes"
                />

                <MobileTableFramework v-for="(item, index) in values" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openDetailsDialog(item)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{
                                    scope.row.name
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="Тип"
                        width="340"
                    >
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.type ? 'primary600' : 'neutral400')">{{
                                    scope.row.type ? getInfo(scope.row.type, serviceTypes) : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <div class="integrationLogos">
                                    <VesySoftLogo v-if="scope.row.integration_unit.integration.key === 'VesySoft'"/>
                                    <TTSSmartWeightLogo
                                        v-if="scope.row.integration_unit.integration.key === 'SmartWeight'"/>
                                    <TTSSmartMixLogo v-if="scope.row.integration_unit.integration.key === 'SmartMix'"/>
                                    <OneCBuhLogo v-if="scope.row.integration_unit.integration.key === '1C'"/>
                                    <KIPConceptLogo v-if="scope.row.integration_unit.integration.key === 'ConceptBCY'"/>
                                    <WialonLogo v-if="scope.row.integration_unit.integration.key === 'WialonMix'"/>
                                    <GlonassSoftIcon
                                        v-if="scope.row.integration_unit.integration.key === 'GlonassSoft'"/>
                                    <WhatsAppLogo v-if="scope.row.integration_unit.integration.key === 'Wappi'"/>
                                    <h3 v-if="scope.row.integration_unit.integration.key === 'Trekerserver'"
                                        style="text-transform:uppercase;">Trekerserver</h3>
                                </div>
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="220">
                        <template v-slot="scope">
                            <div>
                            <el-button
                                size="small"
                                @click="openDetailsDialog(scope.row)">Подробнее
                            </el-button>
                                <el-button
                                    size="small"
                                    @click="openEditDialog(scope.row)"
                                    :icon="icons.edit"
                                />
                            <el-button
                                size="small"
                                @click="remove(scope.row.id)"
                                :icon="icons.delete"
                            />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-dialog
            v-model="createDialog"
            title="Новый тариф"
            :width="mobile ? '100%' : '80%'"
            :destroy-on-close="true"
        >
            <ServiceTariffCreateDialog
                @update="moduleGet"
                @close="() => createDialog = false"
            />
        </el-dialog>

        <el-dialog
            v-model="editDialog"
            :title="details?.name ?? 'Редактирование тарифа'"
            :width="mobile ? '100%' : '80%'"
            :destroy-on-close="true"
        >
            <ServiceTariffEditDialog
                :id="details?.id"
                @update="moduleGet"
                @close="() => editDialog = false"
            />
        </el-dialog>


        <el-dialog
            v-model="detailsDialog"
            :title="details?.name ?? 'Подробности тарифа'"
            :width="mobile ? '100%' : '80%'"
            :destroy-on-close="true"
        >
            <ServiceTariffDetails
                :id="details?.id"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import Filter from '@/views/components/Table/Filter/Filter.vue'
import functionsMixin from "@/mixins/functionsMixin";
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import WhatsAppLogo from "@/views/components/Logo/WhatsAppLogo.vue";
import OneCBuhLogo from "@/views/components/Logo/OneCBuhLogo.vue";
import GlonassSoftIcon from "@/views/components/Icons/GlonassSoftIcon.vue";
import TTSSmartWeightLogo from "@/views/components/Logo/TTSSmartWeightLogo.vue";
import VesySoftLogo from "@/views/components/Logo/VesySoftLogo.vue";
import TTSSmartMixLogo from "@/views/components/Logo/TTSSmartMixLogo.vue";
import WialonLogo from "@/views/components/Icons/WialonLogo.vue";
import iconsMixin from "@/mixins/iconsMixin";
import serviceInfo from "@/mixins/serviceInfo";
import ServiceTariffCreateDialog from "@/views/settings/serviceTariffs/ServiceTariffCreateDialog.vue";
import ServiceTariffDetails from "@/views/settings/serviceTariffs/components/ServiceTariffDetails.vue";
import ServiceTariffEditDialog from "@/views/settings/serviceTariffs/ServiceTariffEditDialog.vue";

export default {
    name: 'ServiceTariffsIndex',
    data() {
        return {
            loading: true,
            loadingButton: false,
            createDialog: false,
            editDialog: false,
            detailsDialog: false,
            form: {
                id: null,
                name: null,
                company_id: null,
                integration_unit_id: null,
                type: null
            },
            details: {
                id: null,
                name: null,
                company_id: null,
                integration_unit_id: null,
                type: null
            },
            filter: {
                type: {
                    label: 'Тип тарифа',
                    placeholder: 'Выберите тип',
                    value: null,
                    type: 'select',
                    items: '1'
                },
            }
        }
    },
    components: {
        ServiceTariffEditDialog,
        ServiceTariffDetails,
        ServiceTariffCreateDialog,
        WialonLogo,
        TTSSmartMixLogo,
        VesySoftLogo,
        TTSSmartWeightLogo,
        GlonassSoftIcon,
        OneCBuhLogo,
        WhatsAppLogo,
        KIPConceptLogo,
        Filter,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, iconsMixin, serviceInfo
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.serviceTariff.getAll, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.values = data.items.data
                    this.valueLength = data.items.total
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        remove(id) {
            this.$confirm('Вы действительно удалить тариф?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.serviceTariff.remove + '/' + id).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Тариф удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.moduleGet()
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            })
        },
        openDetailsDialog(details) {
            this.details = details
            this.detailsDialog = true
        },
        openCreateDialog() {
            this.form = {
                id: null,
                name: null,
                company_id: null,
                integration_unit_id: null,
                type: null
            }
            this.createDialog = true
        },
        openEditDialog(form) {
            this.details = form
            this.editDialog = true
        },
        filterValues() {

        }
    },
    computed: {},
    watch: {
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        }
    }
}
</script>

<style scoped>
.integrationLogos svg {
    height: 20px;
    position: relative;
    top: 3px;
}
</style>
