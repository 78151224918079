<template>
    <CashboxIcon v-if="moduleKey === 'cashbox'"/>
    <ContractIcon v-if="moduleKey === 'contract'"/>
    <WeighingIcon v-if="moduleKey === 'weighing'"/>
    <StorehouseIcon v-if="moduleKey === 'storehouse'"/>
    <ProductionIcon v-if="moduleKey === 'production'"/>
    <OrderIcon v-if="moduleKey === 'order'"/>
    <TechIcon v-if="moduleKey === 'tech'"/>
    <MixIcon v-if="moduleKey === 'mix'"/>
</template>

<script>
import CashboxIcon from './Icons/CashboxIcon'
import ContractIcon from './Icons/ContractIcon'
import WeighingIcon from './Icons/WeighingIcon'
import StorehouseIcon from './Icons/StorehouseIcon'
import ProductionIcon from './Icons/ProductionIcon'
import OrderIcon from './Icons/OrderIcon'
import TechIcon from './Icons/TechIcon'
import MixIcon from './Icons/MixIcon'
export default {
    name: "ModuleIcon",
    components: {MixIcon, TechIcon, OrderIcon, ProductionIcon, StorehouseIcon, WeighingIcon, ContractIcon, CashboxIcon},
    props: [
        'moduleKey'
    ]
}
</script>

<style scoped>

</style>
