<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-search="true"
        :disable-filter="true"
        create-button-label="Добавить взвешивание"
        @moduleGet="getValues"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="openDrawerNewValue"
    />

    <MobileTableFramework v-for="(item, index) in tableData" :key="index">
        <template v-slot:body>

            <MobileTableDate
                :date="item.date"
                title="Дата"
            />

            <MobileTableInfo
                title="Кол-во отгрузок"
                :body="item.count ? formatNumber(item.count) : '-'"
            />

            <MobileTableInfo
                title="Период"
            >
                <template v-slot:body>
                        <span v-if="item.timeFirstWeighing && item.timeLastWeighing">
                            {{
                                getDateFromUnix(item.timeFirstWeighing, 'HH:mm')
                            }} - {{ getDateFromUnix(item.timeLastWeighing, 'HH:mm') }}
                        </span>
                    <span v-if="item.timeFirstWeighing && !item.timeLastWeighing">
                            {{ getDateFromUnix(item.timeFirstWeighing, 'HH:mm') }}
                        </span>
                    <span v-if="!item.timeFirstWeighing && !item.timeLastWeighing">
                            -
                        </span>
                </template>
            </MobileTableInfo>

            <MobileTableInfo
                title="Объем"
                :no-border="true"
            >
                <template v-slot:body>
                    <span v-if="item.total" class="colored">{{ formatNumber(item.total) }} т.</span>
                    <span v-else>-</span>
                </template>
            </MobileTableInfo>

            <MobileTableButton
                @action="openDrawer(item)"
            />

        </template>
    </MobileTableFramework>

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100"
        v-if="!smallMobile"
        :summary-method="getSummaries"
        show-summary
        v-loading="loading"
        :data="tableData">
        <el-table-column
            prop="date"
            label="Дата"
            min-width="100px">
            <template v-slot="scope">
                {{ getDateByTz(scope.row.date, 'DD.MM.YY в HH:mm', this.tz) }}
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="Объем"
            min-width="100px"
            sortable>
            <template v-slot="scope">
                <span v-if="scope.row.total" class="colored">{{ formatNumber(scope.row.total) }} т.</span>
                <span v-else>-</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="Кол-во отгрузок"
            min-width="100px"
            sortable>
            <template v-slot="scope">
                {{ scope.row.count ? formatNumber(scope.row.count) : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="Период"
            min-width="100px">
            <template v-slot="scope">
                        <span class="color-grey" v-if="scope.row.timeFirstWeighing && scope.row.timeLastWeighing">
                            {{
                                getDateFromUnix(scope.row.timeFirstWeighing, 'HH:mm')
                            }} - {{ getDateFromUnix(scope.row.timeLastWeighing, 'HH:mm') }}
                        </span>
                <span class="color-grey" v-if="scope.row.timeFirstWeighing && !scope.row.timeLastWeighing">
                            {{ getDateFromUnix(scope.row.timeFirstWeighing, 'HH:mm') }}
                        </span>
                <span class="color-grey" v-if="!scope.row.timeFirstWeighing && !scope.row.timeLastWeighing">
                            -
                        </span>

            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="130">
            <template v-slot="scope">
                <el-button
                    size="mini"
                    @click="openDrawer(scope.row)">Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />

    <el-drawer
        title=""
        v-model="drawer"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <WeighingSimpleDetails
            :details="dayDetails"
            @moduleGet="getValues"
            @closeDrawer="() => drawer = false"
        />

    </el-drawer>
</template>

<script>
import getTimezone from '@/mixins/getTimezone'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import functionsMixin from '@/mixins/functionsMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import WeighingSimpleDetails from '@/views/components/WeighingPage/WeighingSimpleDetails'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import { httpService } from '@/services/http.service'

export default {
    name: 'WeighingSimpleTable',
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate, MobileTableFramework, WeighingSimpleDetails, TableHeadBlock, TablePagination
    },
    props: [
        'updateValues',
        'tab'
    ],
    data() {
        return {
            tableData: [],
            drawer: false,
            dayDetails: [],
            tz: null
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        getTimezone, tableMixin, basicMixin, functionsMixin, mobileCheckMixin
    ],
    methods: {
        getValues() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.weighingValue.getSimpleValues, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.tableData = data.values.data
                this.valueLength = data.values.total
                this.valueLength = data.values.total
                this.tz = data.timeZone
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        openDrawer(dayDetails) {
            this.dayDetails = dayDetails
            this.drawer = true
        },
        openDrawerNewValue() {
            this.$emit('openDrawerNewValue')
        },
        getSummaries(param) {
            const { columns } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 1) {
                    let total = 0
                    this.tableData.forEach(function (value) {
                        total = total + Number(value.total)
                    })
                    sums[index] = total.toFixed(2)
                    return
                }
                if (index === 2) {
                    let count = 0
                    this.tableData.forEach(function (value) {
                        count = count + Number(value.count)
                    })
                    sums[index] = count.toFixed(0)
                    return
                }
            })

            return sums
        },
        filterValues() {

        }
    },
    watch: {
        'updateValues': {
            handler: function () {
                if (this.tab === 'weighings') this.getValues()
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            if (this.tab === 'weighings') this.getValues()
        },
        page() {
            if (this.tab === 'weighings') this.getValues()
        },
        'filter': {
            handler: function () {
                if (this.tab === 'weighings') this.getValues()
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
