<template>
    <Auth title="Лаборатория"/>
</template>

<script>
import Auth from '../layouts/Auth'

export default {
    name: 'LabIndex',
    data() {
        return {

        }
    },
    components: {
        Auth,
    },
    created() {
        this.$router.push({ name: 'LabOrders',  params:{ id: this.$route.params.id }})
    },
}
</script>

<style scoped>

</style>
