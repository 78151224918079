<template>
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.41667 6.03289C3.41667 5.67682 3.72136 5.38816 4.09722 5.38816H6.81945C7.19531 5.38816 7.5 5.67682 7.5 6.03289C7.5 6.38897 7.19531 6.67763 6.81945 6.67763H4.09722C3.72136 6.67763 3.41667 6.38897 3.41667 6.03289Z" fill="#1984E6"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.18056 6.03289C8.18056 5.32074 8.78995 4.74342 9.54167 4.74342H12.2639C13.0156 4.74342 13.625 5.32074 13.625 6.03289V12.4803C13.625 12.8363 13.3203 13.125 12.9444 13.125H8.86111C8.48525 13.125 8.18056 12.8363 8.18056 12.4803V6.03289ZM12.2639 6.03289H9.54167V11.8355H12.2639V6.03289Z" fill="#1984E6"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.41667 4.09868C3.41667 3.74261 3.72136 3.45395 4.09722 3.45395L6.81945 3.45395C7.19531 3.45395 7.5 3.74261 7.5 4.09868C7.5 4.45476 7.19531 4.74342 6.81945 4.74342H4.09722C3.72136 4.74342 3.41667 4.45476 3.41667 4.09868Z" fill="#1984E6"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.375 2.16447C1.375 1.45232 1.98439 0.875 2.73611 0.875H8.18056C8.93228 0.875 9.54167 1.45232 9.54167 2.16447V12.4803C9.54167 12.8363 9.23697 13.125 8.86111 13.125H2.05556C1.6797 13.125 1.375 12.8363 1.375 12.4803V2.16447ZM8.18056 2.16447H2.73611V11.8355H8.18056V2.16447Z" fill="#1984E6"/>
    </svg>
</template>

<script>
export default {
    name: "BuildingIcon"
}
</script>

<style scoped>
    svg path {
        fill: currentColor;
        stroke: initial !important;
    }
</style>
