<template>
    <Auth title="Новый заказ-наряд" :tabs="tabs">
        <div class="card">

            <div class="card-body">

                <div class="serviceHeadBlock">

                    <h2 class="p-0 mt-0 mb-5">
                        Заказ-наряд {{ workNumber ? '№' + workNumber : '' }}
                    </h2>

                    <el-button
                        type="success"
                        class="color-grey mb-15"
                        @click="notAvaliableFunction"
                        plain
                    >
                        Загрузить данные из шаблона
                    </el-button>

                </div>

                <el-form label-position="top" v-loading="loading">

                    <el-row :gutter="20">

                        <el-col :sm="8">

                            <FormInput
                                v-model="form.name"
                                label="Название заказ-наряда"
                                :required="true"
                                placeholder="Например, ТО 500 замена масла"
                            />

                            <FormSelect
                                v-model="form.tech"
                                label="Техника"
                                :items="teches"
                                :required="true"
                                label-key="number"
                                placeholder="Выберите технику"
                            />

                            <FormSelect
                                v-model="form.workType"
                                label="Тип заказ-наряда"
                                :items="workTypes"
                                :required="true"
                                placeholder="Выберите тип заказ-наряда"
                            />

                        </el-col>

                        <el-col :sm="16" class="serviceDescriptionWork">

                            <FormTextarea
                                v-model="form.description"
                                :rows="10"
                                label="Описание работы"
                                placeholder="Напишите дополнительный комментарий к работам, если это необходимо"
                            />

                        </el-col>

                    </el-row>

                    <div v-if="form.tech">

                        <h2 class="p-0 mb-5">
                            {{ form.planType === 'date' ? 'Дата проведения работ' : 'Пробег проведения работ' }}</h2>

                        <!--                        <el-button v-if="form.planType==='date'" type="text" size="mini" :icon="icons.refresh" @click="setFormPlanType('mileage')"-->
                        <!--                                   style=" padding-left: 0; margin-bottom: 15px; margin-top: -5px; ">Запланировать на-->
                        <!--                            показатель пробега вместо даты-->
                        <!--                        </el-button>-->

                        <!--                        <el-button v-if="form.planType==='mileage'" type="text" size="mini" :icon="icons.refresh" @click="setFormPlanType('date')"-->
                        <!--                                   style=" padding-left: 0; margin-bottom: 15px; margin-top: -5px; ">Запланировать на-->
                        <!--                            дату вместо показателя пробега-->
                        <!--                        </el-button>-->

                        <el-row :gutter="20">

                            <el-col :sm="8" v-if="form.planType==='date'">

                                <FormDateTimePicker
                                    v-model="form.date"
                                    :required="true"
                                    label="Дата проведения работ"
                                    placeholder="Выберите дату"
                                />

                            </el-col>

                            <el-col :sm="8">

                                <el-form-item label="Тип пробега">
                                    <el-radio-group v-model="form.planType">
                                        <el-radio label="mileage">Пробег</el-radio>
                                        <el-radio label="hours">Моточасы</el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <template v-if="form.planType === 'mileage'">
                                    <FormNumber
                                        v-model="form.mileage"
                                        :required="true"
                                        label="На каком пробеге проводить работу"
                                        placeholder="Введите пробег"
                                    />

                                    <span class="color-grey"
                                          style=" margin-top: -10px; display: block; margin-bottom: 15px; font-size: 12px; ">Текущий пробег: {{
                                            getInfo(form.tech, teches, 'mileage')
                                        }} км.</span>
                                </template>
                                <template v-else-if="form.planType === 'hours'">
                                    <FormNumber
                                        v-model="form.hours"
                                        :required="true"
                                        label="На какой наработке моточасов проводить работу"
                                        placeholder="Введите мтч"
                                    />

                                    <span class="color-grey"
                                          style=" margin-top: -10px; display: block; margin-bottom: 15px; font-size: 12px; ">Текущий пробег: {{
                                            getInfo(form.tech, teches, 'engine_hours')
                                        }} мтч.</span>

                                </template>

                            </el-col>

                        </el-row>

                        <ServicePeriodSettings
                            v-model="form.workPeriod"
                            :mileage="form.mileage ?? form.hours"
                            :type="form.planType"
                            :teches="teches"
                            :tech-id="form.tech"
                            :startDate="form.date"
                            @resetPeriodSettings="resetPeriodSettings"
                        />


                        <ServiceCreateWorks
                            v-model="form.serviceWorks"
                        />

                        <ServiceCreateGoods
                            v-if="form.workType !== 1"
                            v-model="form.serviceGoods"
                        />

                        <div class="serviceSaveTemplateSwitch">

                            <el-form-item label="" class="mb-0">

                                <el-switch
                                    v-model="form.saveTemplate"
                                    active-color="#4e92f7"
                                    inactive-color="#a2a5bf">
                                </el-switch>

                            </el-form-item>
                            <div class="serviceSaveTemplateSwitchInfo">
                                <p>Сохранить заказ-наряд как шаблон</p>
                                <span>Упростит создание наряд-заказа в дальнейшем. Подходит, если проведение работ несильно отличается из раза в раз</span>
                            </div>
                        </div>

                    </div>


                    <FormAddButton
                        :loading="loading"
                        @action="createWork"
                        title="Создать заказ-наряд"
                    />

                </el-form>
            </div>
        </div>
    </Auth>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin';
import FormInput from "@/views/components/Form/FormInput";
import FormSelect from "@/views/components/Form/FormSelect";
import {httpService} from "@/services/http.service";
import FormTextarea from "@/views/components/Form/FormTextarea";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";
import FormAddButton from "@/views/components/Form/FormAddButton";
import ServiceCreateWorks from "@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateWorks";
import ServiceCreateGoods from "@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateGoods";
import Auth from "@/views/layouts/Auth";
import ServicePeriodSettings from "@/views/components/TechPage/Service/ServiceCreateWork/ServicePeriodSettings";
import FormNumber from "@/views/components/Form/FormNumber";
import functionsMixin from "@/mixins/functionsMixin";
import techTabs from "@/mixins/techTabs";

export default {
    name: 'TechServiceCreateWork',
    components: {
        FormNumber,
        ServicePeriodSettings,
        Auth,
        ServiceCreateGoods,
        ServiceCreateWorks, FormAddButton, FormDateTimePicker, FormTextarea, FormSelect, FormInput
    },
    inject: ['api'],
    mixins: [iconsMixin, functionsMixin, techTabs],
    data() {
        return {
            loading: false,
            form: {
                name: null,
                tech: null,
                date: null,
                mileage: null,
                hours: null,
                workType: null,
                planType: 'mileage',
                description: null,
                saveTemplate: false,
                serviceGoods: [],
                serviceWorks: [],
                workPeriod: {
                    status: false,
                    type: 'mileage',
                    mileage: {
                        countPeriod: null,
                        start: null,
                        remember: null,
                    },
                    calendar: {
                        dateStart: null,
                        type: 'week',
                        weekDaysList: [],
                        monthDaysList: [],
                        yearDay: null
                    },
                    saveSettings: false,
                },
            },
            workTypes: [{
                id: 1,
                name: 'Осмотр'
            }, {
                id: 2,
                name: 'Обслуживание'
            }, {
                id: 3,
                name: 'Ремонт'
            }],
            teches: [],
            workNumber: null,
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        setFormPlanType(value) {
            this.form.planType = value;
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.tech.getTechServiceNextWorkNumber, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.workNumber = data.number
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })

            httpService().post(this.api.tech.getTech, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.teches = data.values
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        resetPeriodSettings() {
            this.form.workPeriod = {
                status: false,
                type: 'mileage',
                mileage: {
                    countPeriod: null,
                    start: null,
                    remember: null,
                },
                calendar: {
                    dateStart: null,
                    type: 'week',
                    weekDaysList: [],
                    monthDaysList: [],
                    yearDay: null
                },
                saveSettings: false,
            }
        },
        createWork() {
            this.loading = true

            if (!this.form.name || !this.form.tech || !this.form.workType) {
                this.$message({
                    message: 'Заполните все обязательные поля',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            } else {

                httpService().post(this.api.techVehicle.createWork, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'tech',
                    form: this.form
                }).then((response) => {
                    let data = response.data

                    if (data.success)
                        this.loading = false
                    this.$router.push({name: 'garageDetails', params: {vehicleId: this.form.tech}})

                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    })
                    this.$router.push({name: 'logout'})
                })
            }
        },
        notAvaliableFunction() {
            this.$message({
                message: 'Функция в разработке',
                showClose: true,
                type: 'info'
            })
        }
    },
    watch: {
        'form.workPeriod.status': {
            handler: function () {
                if (!this.form.workPeriod.status && this.form.workPeriod.saveSettings) {
                    window.setTimeout(() => {
                        this.resetPeriodSettings()
                    }, 50)
                }
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
