<template>
    <WeighingInfoStatusBlock
        :status="status"
        :loading="loading"
        @moduleGet="moduleGet"
    />

    <WeighingInfoGraph
        :graph="graph"
    />

    <el-row :gutter="25">

        <el-col :sm="12">

            <InfoTopCompaniesBlock
                :top-companies="topCompanies"
            />

        </el-col>

        <el-col :sm="12">

            <InfoTopGoodsBlock
                :top-goods="topGoods"
            />

        </el-col>

    </el-row>
</template>

<script>
import moment from 'moment'
import WeighingInfoStatusBlock from '@/views/components/WeighingPage/Info/WeighingInfoStatusBlock'
import WeighingInfoGraph from '@/views/components/WeighingPage/Info/WeighingInfoGraph'
import { httpService } from '@/services/http.service'
import InfoTopGoodsBlock from "@/views/components/Blocks/InfoTopGoodsBlock.vue";
import InfoTopCompaniesBlock from "@/views/components/Blocks/InfoTopCompaniesBlock.vue";

export default {
    name: 'WeighingPageInfo',
    props: [
        'weighingId',
        'tab'
    ],
    inject: [
        'api'
    ],
    components: {
        InfoTopCompaniesBlock,
        InfoTopGoodsBlock,
        WeighingInfoGraph,
        WeighingInfoStatusBlock,
    },
    data() {
        return {
            status: 0,
            loading: false,
            period: 'today',
            topCompanies: [],
            topGoods: [],
            graph: {
                unitId: null,
                indicators: {
                    countProd: {
                        value: 0,
                        oldValue: 0,
                    },
                    speed: {
                        value: 0,
                        oldValue: 0,
                    },
                    countWeighings: {
                        value: 0,
                        oldValue: 0,
                    },
                    lastDate: null,
                },
                total: []
            }
        }
    },
    created() {
        this.addLinesCompanies()
        this.addLinesGoods()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.weighing.getInfo, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                period: this.period
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.topGoods = data.topGoods
                this.topCompanies = data.topCompanies
                this.graph = data.graph
                this.addLinesGoods()
                this.addLinesCompanies()
                this.getStatus()
                window.setInterval(() => {
                    this.loading = false
                }, 200)
            })
        },
        getStatus() {
            var CurrentDate = moment().unix()
            var duration = moment.duration(moment(CurrentDate).diff(moment(this.graph.indicators.lastDate)))
            let time = Math.round(duration.asMinutes() * 1000)
            if (time <= 60) {
                this.status = 1
            } else {
                this.status = 0
            }
        },
        addLinesGoods() {
            if (this.topGoods.length !== 5) {
                var i = 0
                var end = 5 - this.topGoods.length
                for (; i < end; i++) {
                    this.topGoods.push([{
                        'id': null,
                        'total': null
                    }])
                }
            }
        },
        addLinesCompanies() {
            if (this.topCompanies.length !== 5) {
                var i = 0
                var end = 5 - this.topCompanies.length
                for (; i < end; i++) {
                    this.topCompanies.push([{
                        'id': null,
                        'total': null
                    }])
                }
            }
        }
    },
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'info') this.moduleGet()
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) if (this.tab === 'info') this.moduleGet()
        }
    }
}
</script>

<style scoped>

</style>
