<template>
    <el-form-item :label="label">
        <div class="d-flex w-100" v-for="(value, index) in values"
                :key="index">
                <el-form-item label="" class="w-100 mr-10">
                    <el-select v-model="value.id"
                               :placeholder="placeholder">
                        <NewItemOption v-if="newItemPath" :path="newItemPath"/>
                        <el-option
                            :disabled="checkDisableItem(item.id)"
                            v-for="item in items"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label-width="0">
                    <el-button type="danger" :icon="icons.delete"
                               @click.prevent="deleteRow(index, value)"/>
                </el-form-item>
        </div>
        <el-form-item label="" class="mb-0">
            <el-button :icon="icons.plus"
                       type="info"
                       @click.prevent="addNewRow()"
                       plain>
                Добавить {{ buttonLabel ?? 'элемент' }}
            </el-button>
        </el-form-item>
    </el-form-item>
</template>

<script>
import NewItemOption from '../NewItemOption'
import iconsMixin from '@/mixins/iconsMixin'

export default {
    name: "FormSelectMultiRow",
    components: {NewItemOption},
    mixins: [iconsMixin],
    props: [
        'modelValue',
        'items',
        'label',
        'placeholder',
        'newItemPath',
        'buttonLabel'
    ],
    data() {
        return {
            values: this.modelValue
        }
    },
    methods: {
        addNewRow() {
            this.values.push({
                'id': null
            })
        },
        deleteRow(index, value) {
            var idu = this.values.indexOf(value);
            if (idu > -1) {
                this.values.splice(idu, 1);
            }
        },
        checkDisableItem(itemId) {
            if (this.values.find(el => el.id === itemId)) return true
        },
    },
    watch: {
        'values': {
            handler: function () {
                this.$emit('update:modelValue', this.values)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.values = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
