<template>
    <svg :class="[{'currentColor' : currentColor}]" width="16" height="16" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.125 4.71646L10.8833 1.8915C10.3333 1.5915 9.66667 1.5915 9.10833 1.8915L3.86668 4.71646C3.48335 4.9248 3.25 5.33315 3.25 5.78315C3.25 6.23315 3.48335 6.64146 3.86668 6.84979L9.10833 9.67484C9.38333 9.82484 9.69167 9.89984 9.99167 9.89984C10.2917 9.89984 10.6 9.82484 10.875 9.67484L16.1167 6.84979C16.5 6.64146 16.7333 6.23315 16.7333 5.78315C16.75 5.33315 16.5083 4.9248 16.125 4.71646Z"
            fill="#49515F"/>
        <path
            d="M8.24993 10.6585L3.37493 8.21682C2.99993 8.02515 2.56659 8.05015 2.20826 8.26682C1.84993 8.48349 1.6416 8.86682 1.6416 9.28349V13.8918C1.6416 14.6918 2.08325 15.4085 2.79992 15.7668L7.67493 18.2002C7.84159 18.2835 8.02494 18.3252 8.20828 18.3252C8.42496 18.3252 8.64163 18.2668 8.83329 18.1418C9.19163 17.9252 9.39996 17.5418 9.39996 17.1252V12.5168C9.40829 11.7335 8.96663 11.0168 8.24993 10.6585Z"
            fill="#49515F"/>
        <path
            d="M11.7667 10.658L16.6417 8.21633C17.0167 8.02466 17.45 8.04966 17.8083 8.26633C18.1667 8.483 18.375 8.86634 18.375 9.283V13.8913C18.375 14.6913 17.9333 15.408 17.2167 15.7663L12.3417 18.1997C12.175 18.283 11.9917 18.3247 11.8083 18.3247C11.5916 18.3247 11.375 18.2663 11.1833 18.1413C10.825 17.9247 10.6166 17.5413 10.6166 17.1247V12.5163C10.6083 11.733 11.05 11.0163 11.7667 10.658Z"
            fill="#49515F"/>
    </svg>
</template>

<script>
export default {
    name: "PackageGoodIcon",
    props: ['currentColor']
}
</script>

<style scoped>
    .currentColor {
        path {
            fill: currentColor;
        }
    }
</style>
