<template>
    <Auth title="Отгрузка">
        <div class="card">
            <div class="card-body">
                <MixPageOrderDetails
                    :order-id="$route.params.orderId"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import MixPageOrderDetails from "@/views/components/MixPage/Orders/OrderDetails/MixPageOrderDetails.vue";
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'MixOrderOnePage',
    components: {Auth, MixPageOrderDetails},
}
</script>

<style scoped>

</style>
