<template>
    <Auth :title="moduleName" :integrations="integrations" :relations="relations">

        <div class="card">
            <div class="card-body">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Сводка" name="info">

                        <WeighingPageInfo
                            :tab="activeTab"
                            :weighingId="weighingId"
                        />

                    </el-tab-pane>
                    <el-tab-pane label="Взвешивания" name="weighings">

                        <WeighingPageWeighings
                            :mode="mode"
                            :tab="activeTab"
                            :weighingId="weighingId"
                            :doUpdateValues="updateValues"
                            :operations="operations"
                            :drivers="drivers"
                            :vehicles="vehicles"
                            @createValue="() => createValue = true"
                        />

                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <el-drawer
            title=""
            v-model="createValue"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <WeighingCreate
                :moduleId="$route.params.id"
                :operations="operations"
                :vehicles="vehicles"
                :drivers="drivers"
                @moduleGet="moduleGet"
                @updateValues="() => updateValues = !updateValues"
                @close-drawer-new-value="createValue = !createValue"/>

        </el-drawer>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import WeighingCreate from '../components/WeighingPage/WeighingCreate'
import basicMixin from '../../mixins/basicMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import WeighingPageInfo from '@/views/components/WeighingPage/Info/WeighingPageInfo'
import WeighingPageWeighings from '@/views/components/WeighingPage/Weighings/WeighingPageWeighings'
import { httpService } from '@/services/http.service'

export default {
    components: {
        WeighingPageWeighings,
        WeighingPageInfo,
        Auth,
        WeighingCreate
    },
    data() {
        return {
            activeTab: 'info',
            weighingId: this.$route.params.id,
            module: null,
            moduleName: null,
            integrations: [],
            relations: [],
            drawer: null,
            createValue: null,
            editValue: false,
            operations: [],
            vehicles: [],
            drivers: [],
            updateValues: false,
            collapseActiveItem: [],
            mode: null,
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        basicMixin, mobileCheckMixin
    ],
    name: 'weighingShow',
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            this.loadingTitle = true
            httpService().post(this.api.weighing.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.module = data.module
                this.moduleName = data.module.name
                this.operations = data.module.operations
                this.vehicles = data.vehicles
                this.drivers = data.drivers
                this.integrations = data.integrations
                this.relations = data.relations
                this.mode = data.module.mode
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                // this.$router.push({name: 'logout'})
            })
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.moduleGet()
                this.activeTab = 'info'
            }
        }
    }
}
</script>

<style scoped>

</style>
