<template>
    <BaseErrorComponent :title="'Нет графика работы машин на этот день'">
        <span>Для расчёта используется график машин “По умолчанию” без арендных машин</span>
    </BaseErrorComponent>
</template>
<script>
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue"

export default {
    name: "NoVehiclesAvailable",
    components:{
        BaseErrorComponent
    }
}
</script>
<style scoped>

</style>
