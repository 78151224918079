<template>
    <el-form label-position="top">

        <FormTextarea
            v-model="report"
            label="Напишите причину отмены заказ-наряда"
            placeholder="Почему отменяются работы?"
            :rows="4"
        />

    </el-form>

    <el-button
        @click="updateWorkStatus(5)"
        type="primary"
        class="w-100">
        Отменить
    </el-button>
</template>

<script>
import {httpService} from "@/services/http.service";
import FormTextarea from "@/views/components/Form/FormTextarea";

export default {
    name: 'TechServiceCancelWork',
    components: {FormTextarea},
    props: ['serviceDetails'],
    inject: ['api'],
    data () {
        return {
            report: null
        }
    },
    methods: {
        updateWorkStatus(statusId) {
            httpService().post(this.api.techVehicle.updateStatus, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                workId: this.serviceDetails.id,
                statusId: statusId,
                report: this.report
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('closeDialog')
                this.$emit('moduleGet')

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
    }
}
</script>

<style scoped>

</style>
