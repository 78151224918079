<template>
    <div class="text-center mt-30" v-if="count === 0 && loading === false && mobile">
        <NoDataIcon/>
        <p class="color-grey">Информация не найдена</p>
    </div>
</template>

<script>
import NoDataIcon from '@/views/components/NoDataIcon'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
export default {
    name: "MobileTableNoData",
    components: {NoDataIcon},
    mixins: [mobileCheckMixin],
    props: [
        'count',
        'loading'
    ],
}
</script>

<style scoped>

</style>
