<template>
    <el-col :sm="6">

        <FormDateTimePicker
            v-model="itemDate"
            placeholder="От"
            :label="label ?? 'Дата начало'"
            :type="type ?? 'datetime'"
        />

    </el-col>
</template>

<script>
import FormDateTimePicker from '../../Form/FormDateTimePicker'
export default {
    name: "FilterDateStart",
    components: {FormDateTimePicker},
    props: [
        'modelValue',
        'label',
        'type'
    ],
    data() {
        return {
            itemDate: null
        }
    },
    watch: {
        'itemDate': {
            handler: function () {
                this.$emit('update:modelValue', this.itemDate)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.itemDate = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
