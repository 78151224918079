<template>
    <div class="mixOrderCreate">
        <el-row>
            <el-col :sm="8">
                <div class="mixOrderCreateBody">
                    <div @click="changeTab('info')"
                         :class="'buttonTab ' + (tab === 'info' ? 'activeButton' : 'notActiveButton')">Основное
                    </div>
                    <div @click="changeTab('delivery')"
                         :class="'buttonTab ' + (tab === 'delivery' ? 'activeButton' : 'notActiveButton')">Доставка
                    </div>
                    <div @click="changeTab('total')"
                         :class="'buttonTab ' + (tab === 'total' ? 'activeButton' : 'notActiveButton')">Объем
                    </div>
                    <div @click="changeTab('price')"
                         :class="'buttonTab ' + (tab === 'price' ? 'activeButton' : 'notActiveButton')">Стоимость
                    </div>
                    <div @click="changeTab('time')"
                         :class="'buttonTab ' + (tab === 'time' ? 'activeButton' : 'notActiveButton')">Время
                    </div>
                    <div @click="changeTab('other')"
                         :class="'buttonTab ' + (tab === 'other' ? 'activeButton' : 'notActiveButton')">Прочее
                    </div>
                </div>
            </el-col>
            <el-col :sm="16">
                <div class="bg-white" :style="loading ? 'opacity: 0.4' : 'opacity: 1' + '; transition: 0.3s all;'">
                    <div class="mixOrderCreateBody mixOrderCreateFormBlock">
                        <el-form ref="form" :model="createMixOrderForm" size="small" label-position="top">

                            <template v-if="tab === 'info'">

                                <FormSelect
                                    v-if="dispatch"
                                    v-model="createMixOrderForm.mixId"
                                    label="Завод"
                                    placeholder="Выберите завод"
                                    :items="mixes"
                                />

                                <el-form-item label="Тип клиента">
                                    <el-radio-group v-model="createMixOrderForm.individual" @change="resetCompany">
                                        <el-radio :label="false">Юр.лицо</el-radio>
                                        <el-radio :label="true">Физ.лицо</el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <FormSelect
                                    v-if="!createMixOrderForm.individual"
                                    @changeValue="deliveryAddressIdToNull"
                                    v-model="createMixOrderForm.companyId"
                                    label="Контрагент"
                                    placeholder="Выберите контрагента"
                                    new-item="company"
                                    :new-item-data="createMixOrderForm.companyId"
                                    :items="companies.filter(el => !el.individual)"
                                />

                                <FormSelect
                                    v-else
                                    @changeValue="deliveryAddressIdToNull"
                                    v-model="createMixOrderForm.companyId"
                                    label="Контрагент"
                                    placeholder="Выберите физ.лицо"
                                    new-item="company"
                                    :new-item-data="createMixOrderForm.companyId"
                                    :items="companies.filter(el => el.individual)"
                                />

                                <FormSelect
                                    v-model="createMixOrderForm.goodMixId"
                                    label="Рецепт"
                                    placeholder="Выберите рецепт"
                                    new-item="good"
                                    :items="goods"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.total"
                                    label="Объем (для завода)"
                                    placeholder="8.2"
                                    step="0.1"
                                />

                            </template>
                            <template v-else-if="tab === 'delivery'">

                                <el-form-item label="Тип доставки">
                                    <el-radio-group v-model="createMixOrderForm.type">
                                        <el-radio label="delivery">Доставка</el-radio>
                                        <el-radio label="take-away">Самовывоз</el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <template v-if="createMixOrderForm.type === 'delivery'">
                                    <FormSelect
                                        v-model="createMixOrderForm.deliveryAddressId"
                                        :additional-info="getData(createMixOrderForm.companyId, 'company')"
                                        label-key="address"
                                        label="Адрес доставки"
                                        placeholder="Выберите адрес доставки"
                                        new-item="companyAddress"
                                        :required="createMixOrderForm.delivery_type==='delivery'"
                                        :new-item-data="createMixOrderForm.companyId"
                                        :disabled="!createMixOrderForm.companyId"
                                        :items="createMixOrderForm.companyId ? getData(createMixOrderForm.companyId, 'company', 'addresses') : []"
                                    />
                                </template>

                                <FormSelect
                                    v-model="createMixOrderForm.vehicleId"
                                    :label="'Авто'"
                                    placeholder="Выберите транспортное средство"
                                    :items="vehicles.filter(el => el.type === 'mix')"
                                />

                                <template v-if="createMixOrderForm.type === 'delivery'">

                                    <FormSelect
                                        v-model="createMixOrderForm.driverId"
                                        label="Водитель"
                                        placeholder="Выберите водителя"
                                        :items="users.filter(el => el.roleKey === 'mixDriver')"
                                    />

                                    <FormNumber
                                        v-model="createMixOrderForm.driverDifficulty"
                                        label="Коэф. сложности для водителя"
                                        placeholder="Укажите коэффициент"
                                        step="0.1"
                                    />

                                </template>

                            </template>
                            <template v-else-if="tab === 'total'">

                                <FormNumber
                                    v-model="createMixOrderForm.total"
                                    label="Объем (для завода)"
                                    placeholder="8.2"
                                    step="0.1"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.clientTotal"
                                    label="Объем (для клиента)"
                                    placeholder="8"
                                    step="0.1"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.done"
                                    label="Объем (фактический)"
                                    placeholder="25"
                                />

                            </template>
                            <template v-else-if="tab === 'price'">

                                <FormSelect
                                    v-model="createMixOrderForm.paymentMethod"
                                    label="Метод оплаты"
                                    placeholder="Выберите метод"
                                    :items="paymentMethods"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.productPrice"
                                    label="Стоимость продукции"
                                    placeholder="0"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.deliveryPrice"
                                    label="Стоимость доставки"
                                    placeholder="0"
                                />

                            </template>
                            <template v-else-if="tab === 'time'">

                                <FormDateTimePicker
                                    v-model="createMixOrderForm.startAt"
                                    label="Время загрузки"
                                    type="datetime"
                                />

                                <FormDateTimePicker
                                    v-model="createMixOrderForm.arriveAt"
                                    label="Время прибытия на объект"
                                    type="datetime"
                                />

                                <FormDateTimePicker
                                    v-model="createMixOrderForm.unloadAt"
                                    label="Время выезда с объекта"
                                    type="datetime"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.onObjectTimePlan"
                                    label="Планируемое время разгрузки (мин.)"
                                    placeholder="0"
                                />

                                <FormNumber
                                    v-model="createMixOrderForm.onObjectTimeFact"
                                    label="Фактическое время разгрузки (мин.)"
                                    placeholder="0"
                                />
                            </template>
                            <template v-else-if="tab === 'other'">

                                <el-form-item label="Заказ">
                                    <el-select v-model="createMixOrderForm.orderId" @change="changeOrder">
                                        <el-option
                                            v-for="item in orders"
                                            :key="item.id"
                                            :label="'Заказ #' + item.doc + ' для ' + getData(item.buyer_company_id, 'company')"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </el-form-item>

                                <FormSelect
                                    v-model="createMixOrderForm.sellerCompanyId"
                                    label="Поставщик"
                                    placeholder="Выберите поставщика"
                                    new-item="company"
                                    :new-item-data="createMixOrderForm.seller_company_id"
                                    company-type="seller"
                                    :items="companies.filter(el => el.type === 'seller')"
                                    :required="true"
                                />

                                <FormSelect
                                    v-model="createMixOrderForm.carrierCompanyId"
                                    label="Перевозчик"
                                    placeholder="Выберите перевозчика"
                                    new-item="company"
                                    company-type="carrier"
                                    :new-item-data="createMixOrderForm.carrierCompanyId"
                                    :items="companies.filter(el => el.type === 'carrier')"
                                />
                            </template>
                        </el-form>

                    </div>
                </div>
            </el-col>
        </el-row>
    </div>

    <div class="mixOrderCreateFooter">
        <button
            @click="createOrder"
            class="ci-btn ci-btn_blue"
            :disabled="(!createMixOrderForm.total && !createMixOrderForm.goodMixId) || loading"
        >
            {{ order ? 'Сохранить изменения' : 'Создать отгрузку' }}
        </button>
    </div>

</template>

<script>
import FormSelect from '../../Form/FormSelect'
import FormNumber from '../../Form/FormNumber'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import {mapState, mapStores} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import {useMixStore} from '@/store/mix';
import paymentMethods from "@/mixins/paymentMethods";
import moment from 'moment/moment'

export default {
    name: 'MixPageOrderCreate',
    props: ['order', 'mixes', 'dispatch'],
    components: {
        FormDateTimePicker,
        FormNumber,
        FormSelect
    },
    mixins: [functionsMixin, paymentMethods],
    data() {
        return {
            loading: false,
            tab: 'info',
            orders: [],
            expandForm: false,
            integrationCheck: false,
            createMixOrderForm: {
                doc: null,
                mixId: null,
                goodMixId: null,
                total: null,
                clientTotal: null,
                done: null,
                companyId: null,
                vehicleId: null,
                createdAt: null,
                startAt: null,
                type: 'delivery',
                planDateLoading: null,
                sellerCompanyId: null,
                orderId: null,
                individual: false,
                deliveryAddressId: null,
                driverDifficulty: 1,
                driverId: null,
                paymentMethod: null,
                deliveryPrice: null,
                productPrice: null,
                carrierCompanyId: null,
                onObjectTimePlan: null,
                onObjectTimeFact: null,
                timeLoading: 0,
                arriveAt: null,
                unloadAt: null
            },
            operators: [],
            users: [],
            vehicles: [],
            valueOnMinute: 1,
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.createMixOrderForm = {
            doc: null,
            goodMixId: null,
            total: null,
            clientTotal: null,
            done: null,
            companyId: null,
            vehicleId: null,
            createdAt: null,
            startAt: null,
            type: 'delivery',
            planDateLoading: null,
            sellerCompanyId: null,
            orderId: null,
            individual: false,
            deliveryAddressId: null,
            driverDifficulty: 1,
            driverId: null,
            paymentMethod: null,
            deliveryPrice: null,
            productPrice: null,
            carrierCompanyId: null,
            onObjectTimePlan: null,
            onObjectTimeFact: null,
            timeLoading: 0,
            arriveAt: null,
            unloadAt: null
        }
        this.loading = true

        if (!this.order) {
            httpService().post(this.api.mix.getOrderDoc, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.operators = data.operators
                data.integrations.forEach((element) => {
                    if (element === 'ConceptBCY') {
                        this.integrationCheck = true
                    }
                })
                if (!this.integrationCheck) this.createMixOrderForm.doc = data.doc
            })
        }

        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users
        })

        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
            this.loading = false
        })

        this.getOrders()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            goods: 'getGoods',
        }),
        ...mapStores(useMixStore)
    },
    methods: {
        deliveryAddressIdToNull() {
            this.createMixOrderForm.deliveryAddressId = null;
        },
        changeTab(tab) {
            this.tab = tab
        },
        createOrder() {
            this.loading = true

            httpService().post(this.api.mix.createOrder, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: this.createMixOrderForm,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Сохранено',
                        showClose: true,
                        type: 'success'
                    })
                    window.setTimeout(() => {
                        this.$emit('moduleGet')
                    }, 50)
                    window.setTimeout(() => {
                        this.$emit('closeDrawer')
                    }, 150)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        resetCompany() {
            this.createMixOrderForm.companyId = null
            this.createMixOrderForm.deliveryAddressId = null
        },
        getOrder() {
            this.loading = true;

            httpService()
                .post(this.api.mix.getOneOrder, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    orderId: this.order.id,
                })
                .then((response) => {
                    let data = response.data;

                    if (data.success) {

                        this.createMixOrderForm = data.order
                        // this.createMixOrderForm.timeLoading = data.order.timeInfo.loading

                        if (this.createMixOrderForm.companyId) {
                            this.createMixOrderForm.individual = this.companies.find(el => el.id === this.createMixOrderForm.companyId).individual
                        }

                        // this.valueOnMinute = this.createMixOrderForm.timeLoading / this.createMixOrderForm.total

                        this.loading = false
                    }
                });
        },
        changeOrder() {
            if (this.createMixOrderForm.orderId) {
                let order = this.orders.find(el => el.id === this.createMixOrderForm.orderId)
                if (order) {
                    this.createMixOrderForm.companyId = order.buyer_company_id
                    this.createMixOrderForm.sellerCompanyId = order.sellerCompanyId
                    if (order.delivery_address) {
                        this.createMixOrderForm.deliveryAddressId = order.delivery_address.id
                    } else {
                        this.createMixOrderForm.deliveryAddressId = null
                    }
                } else {
                    this.createMixOrderForm.companyId = null
                    this.createMixOrderForm.sellerCompanyId = null
                    this.createMixOrderForm.deliveryAddressId = null
                }
            } else {
                this.createMixOrderForm.companyId = null
                this.createMixOrderForm.sellerCompanyId = null
                this.createMixOrderForm.deliveryAddressId = null
            }
        },
        getOrders() {

            const filter = {
                ...this.filter,
                ...this.mixStore.filterDate,
            };


            this.orders = []

            Object.entries(this.mixes).forEach((val) => {

                httpService().post(this.api.mix.getOrderGroups, {
                    account_module_reserve_id: val[1].id,
                    module_key: 'mix',
                    filter
                }).then((response) => {
                    let data = response.data

                    if (data.success)
                        this.orders = [...this.orders, ...data.orders]
                    this.loading = false
                })
            });
        },
    },
    watch: {
        'order': {
            handler: function () {
                if (this.order) this.getOrder()
            },
            deep: true,
            immediate: true
        },
        'createMixOrderForm.total': {
            handler: function (newTotal, oldTotal) {
                if (newTotal !== oldTotal && newTotal && oldTotal) {
                    const timeLoading = newTotal * 2.5 * 60
                    const timeLoadingOld = oldTotal * 2.5 * 60

                    this.createMixOrderForm.arriveAt = moment(this.createMixOrderForm.arriveAt).subtract(timeLoadingOld, 'seconds').add(timeLoading, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                    this.createMixOrderForm.unloadAt = moment(this.createMixOrderForm.unloadAt).subtract(timeLoadingOld, 'seconds').add(timeLoading, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
