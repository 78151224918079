<template>
    <div v-if="user.id == 1">
        <div class="p-3" style="display: flex;color: #0B0D17;background-color: #f1e6df;align-items: center;">
            <div class="mr-10 p-3 br-10" style="cursor: pointer;border: 1px solid black;background-color: #1984e6;color:white" @click="getSchedules()">Получить</div>
            <div style="width: 40px">Из БД</div>
            <el-checkbox v-model="fromDB"/>
            <div class="ml-5 mr-10 p-3 br-10" v-if="groups.length" style="cursor: pointer;border: 1px solid black;background-color: #1984e6;color:white" @click="saveSchedules()">Сохранить</div>
            <div class="mr-10 ml-5" style="display: flex;align-items: center;">
                <div style="width: 70px">Итераций:</div>
                <el-input v-model="parameters.count" style="width: 60px"/>
                <div class="ml-15" style="width: 30px">От:</div>
                <el-input style="width: 100px" v-model="from" type="time"/>
                <div style="width: 30px">До:</div>
                <el-input style="width: 100px" v-model="to" type="time"/>
                <el-input style="width: 100px" v-model="day" type="date"/>
                <div style="width: 40px;margin-left: 45px;">Тест</div>
                <el-checkbox v-model="parameters.test"/>
                <div style="width: 30px">Фон</div>
                <el-checkbox @change="changeBackgroundColor" v-model="changeColor"/>
            </div>

            <div v-if="data.length !== 0" class="mr-30">Отгрузок: {{data.countOrders}}</div>
            <div v-if="data.length !== 0" class="mr-30">Не довезли: {{data.metrics.undelivered_volume }}</div>
            <div v-if="data.length !== 0" class="mr-30">Время получение данных: {{data.getDataTime}}</div>
            <div v-if="data.length !== 0" class="mr-30">Время симуляции: {{data.simulationTime}}</div>
        </div>
        <div v-if="loader" class="w-100 text-center pt-30 pb-30" style="min-height: 700px">
            <ReportLoader/>

            <h3>Загрузка данных о заказе</h3>
            <p class="textXS textRegular neutral300">Пожалуйста, подождите...</p>
        </div>
        <div v-else>
            <SelfGantt
                v-if="loading"
                :from="groups.dateFrom"
                :to="groups.dateTo"
                :data="groups.groups"
                :timezone="data.data.timezone"
            />
        </div>
    </div>
</template>
<script>
import SelfGantt from "@/views/components/SelfGantt";
import {httpService} from "@/services/http.service";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import moment from "moment/moment";
import {mapState} from 'pinia';
import {useCommonStore} from "@/store/common";

//import moment from "moment";
export default {
    name: "TestPage",
    components: {
        SelfGantt,
        ReportLoader,
    },
    inject: ['api'],
    mounted() {
        console.log('TestPage');
    },

    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser',
        }),
    },
    created() {
        this.getMixes()
    },
    data() {
        return {
            random: true,
            fromDB: false,
            dateTo: '2024-11-04 01:00',
            dateFrom: '2024-11-5 23:00',
            loading: false,
            day: moment(),
            loader: false,
            changeColor: false,
            count: 1,
            parameters: {
                'count': 1,
                'random': false,
                'isNow': false,
                'test': false,
            },
            data: [],
            groups: [],
            groupsTest: [],
            groupsMock: [
                {
                    id: 1,
                    name: 'БСУ #1 - Пост 1',
                    rows: [
                        {
                            id: 1,
                            label: 'B606HH401',
                            tasks: [{id: 1, start: '15:31', end: '17:10', label: '11 м³ ООО "Рубеж"', color: 'green'}]
                        },
                        {
                            id: 2,
                            label: 'B606HH402',
                            tasks: [{id: 1, start: '17:10', end: '18:10', label: '11 м³ ООО "Рубеж"', color: 'gray'}]
                        },
                        {
                            id: 3,
                            label: 'B606HH403',
                            tasks: [{id: 1, start: '18:10', end: '19:15', label: '11 м³ ООО "Рубеж"', color: 'green'}]
                        },
                    ],
                },
                {
                    id: 2,
                    name: 'БСУ #1 - Пост 2',
                    rows: [
                        {
                            id: 2,
                            label: 'B606HH401',
                            tasks: [{id: 1, start: '15:31', end: '17:10', label: '11 м³ ООО "Рубеж"', color: 'gray'}]
                        },
                    ],
                },
            ],
            mixes: [],
        };
    },
    methods: {
        getSchedules() {
            this.loading = false
            this.loader = true
            if (this.fromDB) {
                //
            }
            httpService().post(this.api.dispatch.orderGroups.getTestData, {
                account_modules_id: 4,
                module_key: 'dispatch',
                parameters: this.parameters,
                day: this.day,
            }).then(({ data }) => {
                console.log(data);

                if (data.success) {
                    this.data = data
                    this.groups = data.site
                    this.groupsTest = data.site.groups.map((group) => {
                        return {
                            ...group,
                            rows: group.rows.map((row)=> {
                                return {
                                ...row,
                                tasks: row.tasks.map((task) => {
                                    return {
                                        ...task,
                                        load: '2024-11-01' + ' ' + task.load,
                                        arrive: '2024-11-01' + ' ' + task.arrive,
                                        end: '2024-11-01' + ' ' + task.end,
                                        unload: '2024-11-01' + ' ' + task.unload,
                                        start: '2024-11-01' + ' ' + task.start
                                    }
                                })
                            }
                        }),
                        mixTasks: group.mixTasks.map((mixTask) => {
                                return {
                                    ...mixTask,
                                    end: '2024-11-01' + ' ' + mixTask.end,
                                    start: '2024-11-01' + ' ' + mixTask.start
                                }
                            })
                }})
            }

                this.loading = true
                this.loader = false
            })
        },
        saveSchedules() {
            this.$message({
                message: 'Сохранение началось',
                showClose: true,
                type: 'success'
            });
            httpService().post(this.api.dispatch.orderGroups.saveTestData, {
                account_modules_id: 4,
                module_key: 'dispatch',
                orders: this.data.assignedMixOrders,
            }).then((response) => {

                let data = response.data
                if (data.success) {
                    this.$message({
                        message: 'Сохранение прошло успешно',
                        showClose: true,
                        type: 'success'
                    });
                }
            })
        },
        getMixes() {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: 4,
                module_key: 'dispatch',
            }).then((response) => {
                const {data} = response.data
                this.mixes = data
            })
        },
        changeBackgroundColor() {
            if (this.changeColor) {
                document.body.style.backgroundColor = '#0d0d0d';
            } else {
                document.body.style.backgroundColor = 'white';
            }
        },
    }
}
</script>

<style scoped>

</style>
