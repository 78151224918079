<template>
    <Auth title="Отчет по продукции">
        <div class="card">
            <div class="card-body">
                <el-form label-position="top">

                    <el-row gutter="15">

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateStart"
                                label="Начало периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateEnd"
                                label="Окончание периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>
                    </el-row>
                    <div>
                        <el-button :loading="loading" type="primary"
                                   @click="moduleGet">Сформировать отчет
                        </el-button>

                        <el-button plain :icon="icons.back"
                                   @click="$router.push('/report')">Другой отчет
                        </el-button>
                    </div>

                </el-form>

                <div v-if="loading" class="text-center mt-30">
                    <ReportLoader/>

                    <h3>Формирование отчета</h3>
                </div>

                <div class="mt-30" v-if="!loading">

                    <el-tabs v-model="reportType" class="reportTabs" type="border-card">
                        <el-tab-pane label="По продукции" name="goods">
                            <div class="reportMixItem">
                                <div class="reportMixItemBody neutral200 textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            Продукция
                                        </el-col>
                                        <el-col :sm="3">
                                            Кол-во
                                        </el-col>
                                        <el-col :sm="3">
                                            Брак
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="reportMixItem" v-for="(productionGood, index) in valuesByGoods" :key="index">
                                <div class="reportMixItemBody textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            <span class="textMedium neutral900">{{ productionGood.good }}</span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span :class="'textMedium ' + (productionGood.total > 0 ? 'primary600' : 'neutral300')">{{
                                        productionGood.total ? formatNumber(productionGood.total, 2) : '-'
                                    }}</span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span
                                    :class="'textMedium ' + (productionGood.defectTotal > 0 ? 'danger300' : 'neutral300')">{{
                                        productionGood.defectTotal ? formatNumber(productionGood.defectTotal, 2) : '-'
                                    }}</span>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="По сменам" name="shifts">
                            <div class="reportMixItem">
                                <div class="reportMixItemBody neutral200 textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            Смена
                                        </el-col>
                                        <el-col :sm="3">
                                            Кол-во
                                        </el-col>
                                        <el-col :sm="3">
                                            Брак
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="reportMixItem" v-for="(shift, index) in valuesByShifts" :key="index">
                                <div class="reportMixItemFooter textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            <span class="textMedium neutral900">{{ shift.shift ?? 'Не указана' }}</span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span :class="'textMedium ' + (shift.total > 0 ? 'primary600' : 'neutral300')">{{
                                        shift.total ? formatNumber(shift.total, 2) : '-'
                                    }}</span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span :class="'textMedium ' + (shift.defectTotal > 0 ? 'danger300' : 'neutral300')">{{
                                        shift.defectTotal ? formatNumber(shift.defectTotal, 2) : '-'
                                    }}</span>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="reportMixItemBody textRegular pt-10 pb-10">
                                    <div class="reportItemRow" v-for="(item, index) in shift.valuesByGoods" :key="index">
                                        <el-row :gutter="16">
                                            <el-col :sm="18">
                                    <span class="textRegular neutral900 cutOverflow"><span
                                        class="mr-10">-</span>{{ item.good ?? '-' }}</span>
                                            </el-col>
                                            <el-col :sm="3">
                                    <span :class="'textMedium ' + (item.total > 0 ? 'primary600' : 'neutral300')">{{
                                            item.total ? formatNumber(item.total, 2) : '-'
                                        }}</span>
                                            </el-col>
                                            <el-col :sm="3">
                                    <span :class="'textMedium ' + (item.defectTotal > 0 ? 'danger300' : 'neutral300')">{{
                                            item.defectTotal ? formatNumber(item.defectTotal, 2) : '-'
                                        }}</span>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="По дням" name="days">
                            <div class="reportMixItem">
                                <div class="reportMixItemBody neutral200 textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            Дата
                                        </el-col>
                                        <el-col :sm="3">
                                            Кол-во
                                        </el-col>
                                        <el-col :sm="3">
                                            Брак
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="reportMixItem" v-for="(value, index) in valuesByDate" :key="index">
                                <div class="reportMixItemFooter textRegular">
                                    <el-row :gutter="16">
                                        <el-col :sm="18">
                                            <span class="textMedium neutral900">{{ value.date ?? '-' }}</span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span class="textMedium primary600 p-relative">
                                    {{ value.total ? formatNumber(value.total, 2) : '-' }}
                                </span>
                                        </el-col>
                                        <el-col :sm="3">
                                <span :class="'textMedium ' + (value.defectTotal > 0 ? 'danger300' : 'neutral300')">{{
                                        value.defectTotal ? formatNumber(value.defectTotal, 2) : '-'
                                    }}</span>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="reportMixItemBody textRegular pt-10 pb-10">
                                    <template v-if="value.reports.length > 0">
                                        <div v-for="(report, index) in value.reports" :key="index">
                                            <p :class="'textRegular textSM neutral900 mb-0 ' + (index > 0 ? 'mt-5' : 'mt-0')">{{ report.report ?? '-' }}</p>
                                            <span v-if="report.downtime" class="textRegular textXS neutral300 d-block mt-5">
                    Простой - {{
                                        formatNumber(report.downtime)
                                    }} мин.
                </span>
                                        </div>
                                        <hr class="mb-0"/>
                                    </template>
                                    <div :class="'reportItemRow ' + (item.defect ? 'reportItemRowDefect' : '')"
                                         v-for="(item, index) in value.valuesByGoods" :key="index">
                                        <el-row :gutter="16">
                                            <el-col :sm="10">
                                    <span class="textRegular neutral900 cutOverflow"><span
                                        class="mr-10">-</span>{{ item.good ?? '-' }}</span>
                                            </el-col>
                                            <el-col :sm="4">
                                                <span class="textRegular neutral300">{{ item.unit !== '' ? item.unit : '-' }}</span>
                                            </el-col>
                                            <el-col :sm="4">
                                    <span class="textMedium success400">{{
                                            item.shift !== '' ? item.shift : '-'
                                        }}</span>
                                            </el-col>
                                            <el-col :sm="3">
                                    <span :class="'textMedium ' + (!item.defect ? 'primary600' : 'neutral300')">{{
                                            !item.defect ? formatNumber(item.total, 2) : '-'
                                        }}</span>
                                            </el-col>
                                            <el-col :sm="3">
                                    <span :class="'textMedium ' + (item.defect ? 'danger300' : 'neutral300')">{{
                                            item.defect ? formatNumber(item.total, 2) : '-'
                                        }}</span>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>

                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import getTimezone from '@/mixins/getTimezone'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import iconsMixin from '@/mixins/iconsMixin'
import * as dayjs from 'dayjs'
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'ReportProductionGoods',
    components: {Auth, FormDateTimePicker, ReportLoader},
    props: ['settings'],
    inject: ['api'],
    mixins: [tableMixin, getTimezone, functionsMixin, iconsMixin],
    created() {
        this.moduleGet()
    },
    data() {
        return {
            filter: {
                dateStart: this.$route.query.dateStart ? this.$route.query.dateStart : null,
                dateEnd: this.$route.query.dateEnd ? this.$route.query.dateEnd : null,
                recipient: this.$route.query.recipient ? this.$route.query.recipient : null,
                sender: this.$route.query.sender ? this.$route.query.sender : null,
                doc_number: this.$route.query.doc_number ? this.$route.query.doc_number : null,
                goods: this.$route.query.goods ? this.$route.query.goods : [],
                operation: this.$route.query.operation ? this.$route.query.operation : null,
                driver: this.$route.query.driver ? this.$route.query.driver : null,
                vehicle_number: this.$route.query.vehicle_number ? this.$route.query.vehicle_number : null,
            },
            reportType: 'goods',
            valuesByGoods: [],
            valuesByDate: [],
            valuesByShifts: [],
            pageSize: 50,
            total: 0,
            companies: [],
            operations: [],
            vehicles: [],
            drivers: [],
            showDetails: false,
            loading: true,
            loadingButton: false,
            columns: JSON.parse(localStorage.getItem('reportProductionGoods')) ?? ['date', 'good'],
            columnsData: [{
                id: 'date',
                name: 'Дата'
            }, {
                id: 'good',
                name: 'Груз'
            }]
        }
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.showDetails = false
            this.updateRouterQuery();

            httpService().post(this.api.report.productionGoods, {
                reportKey: this.$route.meta.key,
                settings: this.$route.query,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.valuesByGoods = data.valuesByGoods
                    this.valuesByDate = data.valuesByDate
                    this.valuesByShifts = data.valuesByShifts
                    this.loading = false
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        updateRouterQuery() {
            let updatedQuery = {...this.$route.query, ...this.filter};
            this.$router.push({ name: 'ReportProductionGoods', query: updatedQuery });
        },
        exportToExcel() {
            this.loadingButton = true

            httpService().post(this.api.exportExcel.exportWeighingReport, {
                settings: this.$route.query,
                filter: this.filter,
                columns: this.columns,
                timezone: this.getTimezone()
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.xlsx')
                document.body.appendChild(link)
                link.click()

                let data = response.data

                if (data.success)
                    this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        getTotal() {
            let sum = 0
            this.values.forEach(el => sum = sum + Math.floor(el.total))
            return sum
        },
        checkColumn(name) {
            return this.columns.find(el => el === name)
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    computed: {
        dateStart() {
            return dayjs(this.filter.dateStart).format('DD.MM.YYYY HH:mm')
        },
        dateEnd() {
            return dayjs(this.filter.dateEnd).format('DD.MM.YYYY HH:mm')
        },
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        })
    },
    watch: {
        'columns': {
            handler () {
                localStorage.setItem('reportProductionGoods', JSON.stringify(this.columns));
            }
        }
    }
}
</script>
