<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
        <rect width="36" height="36" fill="url(#a)" rx="18"/>
        <path fill="#49A7FD" d="M12.447 11.667v7.98c0 .653.306 1.273.833 1.666l3.473 2.6a2.09 2.09 0 0 0 2.5 0l3.474-2.6a2.08 2.08 0 0 0 .833-1.666v-7.98H12.447Z" opacity=".4"/>
        <path fill="#49A7FD" d="M24.667 12.167H11.333a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h13.334c.273 0 .5.226.5.5 0 .273-.227.5-.5.5Zm-4 3.666h-5.334a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h5.334c.273 0 .5.227.5.5 0 .274-.227.5-.5.5Zm0 3.334h-5.334a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h5.334c.273 0 .5.226.5.5 0 .273-.227.5-.5.5Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="36" y1="36" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B8DDFE"/>
                <stop offset="1" stop-color="#B8DDFE" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "PaymentBillIcon"
}
</script>

<style scoped>

</style>
