<template>
    <template v-if="step === 1">
        <p class="textMedium neutral900">Выберите документы для печати:</p>
        <div class="d-flex documentPrints">
            <el-checkbox-group v-model="selectedDocs">
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="passport" value="passport">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/passport.jpg"/>
                            <p class="textMedium textSM">Паспорт качества</p>
                        </div>
                    </template>
                </el-checkbox>
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="invoice" value="invoice">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/invoice.jpg"/>
                            <p class="textMedium textSM">ТН</p>
                        </div>
                    </template>
                </el-checkbox>
                <el-checkbox :disabled="!orders.filter(el => el.confirm).length" label="ttn" value="ttn">
                    <template #default>
                        <div class="document text-center">
                            <img src="/images/docs/TTN.png"/>
                            <p class="textMedium textSM">ТТН</p>
                        </div>
                    </template>
                </el-checkbox>
            </el-checkbox-group>
        </div>
    </template>
    <template v-else-if="step === 2">
        <p class="textMedium neutral900">Выберите отгрузки для печати:</p>

        <template v-if="orders.filter(el => el.confirm).length">

            <el-checkbox class="w-100" :indeterminate="isIndeterminate" v-model="checkAll"
                         @change="handleCheckAllChange">
                Выбрать все
            </el-checkbox>
            <el-scrollbar height="300px">
                <div style="width: 90%">
                    <el-checkbox-group v-model="selectedOrders" @change="handleCheckedCitiesChange">
                        <el-checkbox class="w-100" v-for="order in orders.filter(el => el.confirm)" :label="order.id"
                                     :key="order.id">
                            #{{ order.id }} / {{ getDateFromUnix(order.date, 'HH:mm', tz) }} - <span class="cutOverflow"
                                                                                                     style="max-width: 200px">{{
                                order.good
                            }}</span> - {{ order.vehicle }} - {{ order.total }} м³
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-scrollbar>
        </template>
        <template v-else>
            <p class="mt-30 textSM neutral400 text-center w-100">
                Нет подтвержденных заявок по заказу<br/>Нет возможности распечатать ТТН или Паспорта качества для
                отгрузок
            </p>
        </template>
    </template>

    <div class="add-shipping__footer">

        <el-button
            v-if="step !== 1"
            @click="() => step = 1"
            class="ci-btn ci-btn_default add-shipping__footer__cancel">К выбору документов
        </el-button>

        <el-button
            v-if="step === 1"
            @click="nextStep()"
            :disabled="!selectedDocs.length"
            class="ci-btn ci-btn_blue"
            type="primary">Далее
        </el-button>

        <template v-if="step === 2">
            <el-button
                @click="printDocs('download')"
                :disabled="!selectedOrders.length || loadingButton"
                :icon="icons.download"
                class="ci-btn"
                type="default">Скачать
            </el-button>
            <el-button
                @click="printDocs('print')"
                :disabled="!selectedOrders.length || selectedDocs.length > 1 || loadingButton"
                :icon="icons.print"
                class="ci-btn ci-btn_blue"
                type="primary">Распечатать
            </el-button>
        </template>
    </div>
</template>

<script>
import moment from "moment/moment";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "OperatorPrintDocs",
    computed: {
        moment() {
            return moment
        }
    },
    props: ['orders', 'mixId', 'date'],
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    data() {
        return {
            selectedDocs: [],
            selectedOrders: [],
            step: 1,
            checkAll: false,
            isIndeterminate: false,
            loadingButton: false,
            tz: 'Europe/Moscow'
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.selectedOrders = val ? this.orders.filter(el => el.confirm).map(item => item.id) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.orders.filter(el => el.confirm).length;
            // this.isIndeterminate = checkedCount > 0 && checkedCount < this.orders.length;
            this.isIndeterminate = false;
        },
        printDocs(type) {
            this.selectedDocs.forEach(template => {
                this.print(template, type)
            })
            this.$emit('close')
        },
        nextStep() {
            this.step = 2;
        },
        print(template, type) {
            if (template === 'ttn') {
                this.generateWord()
                return
            }
            httpService().post(this.api.print.docs, {
                module_reserve_id: this.mixId,
                module_key: 'mix',
                orders: this.selectedOrders,
                template: template
            }, {
                responseType: 'blob'
            }).then((response) => {

                let data = response.data
                const url = window.URL.createObjectURL(response.data)

                if (type === 'download') {
                    const link = document.createElement('a')
                    let type = 'Паспорта качества по заявкам '
                    if (template === 'invoice') {
                        type = 'Транспортные накладные по заявкам'
                    } else if (template === 'daily') {
                        type = 'График заявок'
                    }
                    link.href = url
                    link.setAttribute('download', type + '.html')
                    document.body.appendChild(link)
                    link.click()
                } else if (type === 'print') {
                    const printWindow = window.open(url, '_blank')
                    printWindow.print()
                }
                this.loadingButton = false

                if (data.success) {
                    this.loadingButton = false
                    this.step = 1
                    this.selectedDocs = []
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        generateWord() {
            for (let i = 0; i < this.selectedOrders.length; i++) {
                httpService().post(this.api.exportDocument.generateTTN, {
                    orderId: this.selectedOrders[i]
                }, {
                    responseType: 'blob'
                }).then((response) => {
                    let fileName = 'Товарно-транспортная накладная для заявки №' + this.selectedOrders[i] + '.docx';
                    const url = window.URL.createObjectURL(response.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                })
            }
        },
    },
}
</script>

<style scoped>

</style>
