<template>
    <span class="subtitle">
        {{ subTitle }}
        <slot v-if="!subTitle" name="subtitle"></slot>
    </span>
</template>

<script>
export default {
    name: "DrawerSubTitle",
    props: [
        'subTitle'
    ]
}
</script>

<style scoped>

</style>
