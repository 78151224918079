<template>
    <Auth title="Автоматизации">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    :loading="loading"
                    :disable-filter="true"
                    :disable-update-button="true"
                    :disable-search="true"
                    create-button-label="Создать автоматизацию"
                    @openCreateValueDrawer="$router.push({name: 'RelationCreate'})"
                />

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Модули"
                            :body="item.sender.name + ' -> ' + item.recipient.name"
                        />

                        <MobileTableInfo
                            title="Тип автоматизации"
                            :body="item.types.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Настроить"
                            @action="$router.push({name: 'RelationEdit', params: { id: scope.row.id }})"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile"
                    class="mt-15 w-100">
                    <el-table-column
                        prop="recipient.name"
                        label="Модули">
                        <template v-slot="scope">
                            {{ scope.row.sender.name }} -> {{ scope.row.recipient.name }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="types.name"
                        label="Тип автоматизации"
                        sortable>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button @click="$router.push({name: 'RelationEdit', params: { id: scope.row.id }})" size="mini">
                                Настроить
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import { httpService } from '@/services/http.service'

export default {
    name: 'RelationsIndex',
    data() {
        return {
            relations: [],
            loading: true,
        }
    },
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TablePagination,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, mobileCheckMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            httpService().post(this.api.settings.getRelations).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.relations

            })
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
