<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавление нового значения"/>

            <el-form ref="form" :model="createValueForm" size="small" label-position="top">

                <FormSelect
                    v-model="createValueForm.goodId"
                    label="Продукция"
                    placeholder="Выберите продукцию"
                    new-item="good"
                    :items="goods"
                />

                <FormNumber
                    v-model="createValueForm.value"
                    label="Количество"
                    placeholder="Например, 12 500"
                />

                <FormDateTimePicker
                    type="datetime"
                    v-model="createValueForm.date"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Добавить значение" @action="createValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '../Drawer/DrawerFramework'
import basicMixin from '../../../mixins/basicMixin'
import DrawerHeadTitle from '../Drawer/DrawerHeadTitle'
import DrawerCreateButton from '../Drawer/Buttons/DrawerCreateButton'
import FormDateTimePicker from '../Form/FormDateTimePicker'
import FormNumber from '../Form/FormNumber'
import FormSelect from '../Form/FormSelect'
import getTimezone from '@/mixins/getTimezone'
import { httpService } from '@/services/http.service'

export default {
    name: 'ContractValueCreate',
    components: {
        FormSelect,
        FormNumber,
        FormDateTimePicker,
        DrawerCreateButton,
        DrawerHeadTitle, DrawerFramework
    },
    props: [
        'goods',
    ],
    data() {
        return {
            loading: false,
            createValueForm: {
                goodId: '',
                value: '',
                date: '',
            },
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        basicMixin, getTimezone
    ],
    methods: {
        createValue() {
            this.loading = true

            httpService().post(this.api.contractValue.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'contract',
                contract_id: this.$route.params.id,
                contract_unit_id: this.$route.params.unitId,
                contract_good_id: this.createValueForm.goodId,
                value: this.createValueForm.value,
                date: this.createValueForm.date,
                timezone: this.getTimezone()
            }).then(() => {
                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>

</style>
