<template>
    <Auth title="Оплата">
        <div class="card">
            <div class="card-body">
                <div class="subscription-and-payment">
                    <SubscriptionAndPaymentSettings/>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import SubscriptionAndPaymentSettings from './SubscriptionAndPaymentSettings.vue';

export default {
    name: 'PaymentIndex',
    components: {
        Auth,
        SubscriptionAndPaymentSettings
    },
}
</script>

<style scoped>

</style>
