<template>

    <WeighingTable
        v-if="mode === 'normal'"
        v-loading="loading"
        @moduleGet="moduleGet"
        @openDrawerNewValue="$emit('createValue')"
        :tab="tab"
        :updateValues="updateValues"
        :operations="operations"
        :vehicles="vehicles"
        :drivers="drivers"
    />

    <WeighingSimpleTable
        v-if="mode === 'simple'"
        v-loading="loading"
        :tab="tab"
        @openDrawerNewValue="$emit('createValue')"
        :updateValues="updateValues"
        @moduleGet="moduleGet"
    />
</template>

<script>

// components
import WeighingTable from '@/views/components/WeighingPage/WeighingTable'
import basicMixin from '@/mixins/basicMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import WeighingSimpleTable from '@/views/components/WeighingPage/WeighingSimpleTable'

export default {
    name: "WeighingPageWeighings",
    components: {
        WeighingSimpleTable,
        WeighingTable,
    },
    props: [
        'weighingId',
        'tab',
        'mode',
        'doUpdateValues',
        'operations',
        'vehicles',
        'drivers'
    ],
    data() {
        return {
            activeTab: 'info',
            module: null,
            moduleName: null,
            integrations: [],
            relations: [],
            moduleId: null,
            drawer: null,
            createValue: null,
            editValue: false,
            updateValues: false,
            collapseActiveItem: [],
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        basicMixin, mobileCheckMixin
    ],
    methods: {},
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'weighings') this.updateValues = !this.updateValues
            },
            deep: true,
            immediate: true
        },
        'doUpdateValues': {
            handler: function () {
                if (this.tab === 'weighings') this.updateValues = !this.updateValues
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) if (this.tab === 'weighings') this.updateValues = !this.updateValues
        }
    }
}
</script>

<style scoped>

</style>
