<template>
    <div class="mb-30">
        <template v-if="serviceGoods.length === 0">
            <h3 v-if="!disableHeading" class="p-0 mb-15 text-500">Список {{ !disableEdit ? 'необходимых' : 'использованных' }} запчастей</h3>
            <div class="text-center topCard">
                <EmptyListIcon
                    :height="120"
                    :non-circle="true"
                />
                <p class="text-light" v-if="!disableEdit">Здесь будет показан список запчастей, необходимых для заменены в рамках
                    заказ-наряда</p>

                <p class="text-light" v-else>Для данного заказ-наряда список запчастей отсутствует</p>

                <el-button type="text" v-if="!disableEdit" class="w-100" :icon="icons.plus" @click="createGoodsDialog=true">Добавить
                    запчасти
                </el-button>
            </div>
        </template>

        <template v-else>
            <div class="d-flex-full">
                <h3 v-if="!disableHeading" class="p-0 mb-15 text-500">Список {{ !disableEdit ? 'необходимых' : 'использованных' }} запчастей</h3>
                <el-button v-if="!disableEdit" :icon="icons.edit" type="text" @click="createGoodsDialog=true">Редактировать список
                </el-button>
            </div>
            <div class="mt-10 mb-15">
                <div v-for="(good, index) in serviceGoods" :key="index" class="serviceWorkLine">
                    <template v-if="good.store_id">
                        <div>{{ index + 1 }}. {{ good.store_id ? getData((getInfo(good.store_id, techStoreGoods, 'good_id')), 'good') : '-' }}</div>
                        <div v-if="serviceStatus !== 4">{{ formatNumber(good.total) }}
                            {{ getData((getInfo(good.store_id, techStoreGoods, 'good_id')), 'good', 'unit') }}
                        </div>
                        <div v-else>{{ formatNumber(good.fact_total) }}
                            {{ getData((getInfo(good.store_id, techStoreGoods, 'good_id')), 'good', 'unit') }}
                        </div>
                    </template>
                    <template v-else>
                        <div>{{ index + 1 }}. {{ good.id ? getData((getInfo(good.id, techStoreGoods, 'good_id')), 'good') : '-' }}</div>
                        <div v-if="serviceStatus !== 4">{{ formatNumber(good.total) }}
                            {{ getData((getInfo(good.id, techStoreGoods, 'good_id')), 'good', 'unit') }}
                        </div>
                        <div v-else>{{ formatNumber(good.fact_total) }}
                            {{ getData((getInfo(good.id, techStoreGoods, 'good_id')), 'good', 'unit') }}
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
    <el-dialog
        v-model="createGoodsDialog"
        :title="dialogTitle"
        :width="!mobile ? '50%' : '100%'"
        :destroy-on-close="true"
    >

        <ServiceCreateGoodsDialog
            v-model="serviceGoods"
            :techStoreGoods="techStoreGoods"
            @changeTitle="dialogTitle=$event"
            @closeDialog="createGoodsDialog=false"
        />

    </el-dialog>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import ServiceCreateGoodsDialog from '@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateGoodsDialog'
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'ServiceCreateGoods',
    components: {EmptyListIcon, ServiceCreateGoodsDialog},
    props: ['modelValue', 'disableEdit', 'disableHeading', 'serviceStatus'],
    inject: ['api'],
    mixins: [mobileCheckMixin, functionsMixin, iconsMixin],
    data() {
        return {
            serviceGoods: this.modelValue,
            createGoodsDialog: false,
            techStoreGoods: [],
            dialogTitle: 'Список запчастей для проведения заказ-наряда'
        }
    },
    created() {
        this.getStoreGoods()
    },
    methods: {
        getStoreGoods() {
            httpService().post(this.api.tech.getTechGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.techStoreGoods = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.serviceGoods = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'serviceGoods': {
            handler: function () {
                this.$emit('update:modelValue', this.serviceGoods)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
