<template>
    <div>
        <el-input v-model="accountId" placeholder="ID аккаунта"/>
        <el-button @click="doAction('goods-duplicate-delete')">Удалить дубляж в товарах</el-button>
    </div>
</template>

<script>
import {httpService} from '@/services/http.service'

export default {
    name: 'GodModeFunctions',
    data() {
        return {
            loading: false,
            accountId: null
        }
    },
    inject: ['api'],
    methods: {
        doAction(functionName) {
            this.loading = true

            httpService().post(this.api.admin.function, {
                function: functionName,
                accountId: this.accountId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Успешно',
                        showClose: true,
                        type: 'success'
                    })
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>
