<template>
    <div v-if="data.length === 0"
        class="d-flex f-direction-column gap-8 w-fit align-center justify-center m-auto neutral100"
        :style="`height: ${windowWidth > 1280 ? '330px' : '260px'}`">
        <CRMContractsAddRowButton @add-new-row="() => $emit('addNewRow')"
                                  :tab="tab" />
    </div>
    <div class="crm-tabs-table" v-else>

        <!-- Таблица продукции -->
        <template v-if="tab === 'document_goods'">
            <el-row class="crm-tabs-table__header" :gutter="15">
                <el-col :sm="windowWidth > 1440 ? 7 : 6">
                    <span>Продукция</span>
                </el-col>
                <el-col :sm="4">
                    <span>Цена (₽, за ед.)</span>
                </el-col>
                <el-col :sm="4">
                    <span>Количество</span>
                </el-col>
                <el-col :sm="4">
                    <span>
                        НДС (%)
                        <el-checkbox class="vatCheckbox"
                            v-model="vatInPriceModel"
                            :label="windowWidth > 1440 ? 'Включен в цену' : 'Включен'"
                            size="small" />
                    </span>
                </el-col>
                <el-col :sm="4">
                    <span>Итого (₽)</span>
                </el-col>
                <el-col :sm="windowWidth > 1440 ? 1 : 2" />
            </el-row>

            <el-scrollbar class="crm-tabs-table__body" :height="windowWidth > 1280 ? '306' : '236'">
                <div v-for="(good, index) in data" :key="index">
                    <el-row :gutter="15">
                        <el-col :sm="windowWidth > 1440 ? 7 : 6">
                            <span class="textMedium neutral900">{{ good.name }}</span>
                        </el-col>
                        <el-col :sm="4">
                            <FormNumber
                                v-model="good.price"
                                label=""
                                placeholder="Укажите цену"
                                :precision="2"
                            />
                        </el-col>
                        <el-col :sm="4">
                            <FormNumber
                                v-model="good.total"
                                label=""
                                placeholder="Укажите кол-во"
                                :precision="2"
                            />
                        </el-col>
                        <el-col :sm="4">
                            <el-form-item label="">
                                <el-input
                                    class="custom-input"
                                    v-model="good.vat"
                                    type="number"
                                    placeholder="Укажите НДС"
                                    :disabled="vatInPriceModel"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :sm="4">
                            <FormNumber
                                v-model="good.full_price"
                                label=""
                                placeholder="Итого"
                                :precision="2"
                                :disabled="!good.good_id"
                            />
                        </el-col>
                        <el-col :sm="windowWidth > 1440 ? 1 : 2" class="d-flex align-center d-flex-justify-end">
                            <el-form-item>
                                <el-button class="crm-tabs-table__btn crm-tabs-table__btn--delete"
                                    :icon="binIcon"
                                    @click.prevent="() => $emit('deleteRow', good)" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <hr />
                </div>
            </el-scrollbar>
        </template>

        <!-- Таблица тарифов -->
        <template v-if="tab === 'downtime' || tab === 'pump' || tab === 'mix'">
            <el-row class="crm-tabs-table__header" :gutter="10">
                <el-col :sm="10">
                    <span>Тариф</span>
                </el-col>
                <el-col :sm="10">
                    <span>Тип тарифа</span>
                </el-col>
                <el-col :sm="4" class="text-right" />
            </el-row>

            <el-scrollbar class="crm-tabs-table__body" :height="windowWidth > 1280 ? '306' : '236'">
                <div v-for="(tariff, index) in data" :key="index">
                    <el-row :gutter="10">
                        <el-col :sm="10">
                            <span class="textSM textMedium neutral900">{{ tariff.name }}</span>
                        </el-col>
                        <el-col :sm="10" class="d-flex align-center">
                            <span :class="['textSM textRegular', tariff.depends_on !== 'other' ? 'success400' : 'neutral900']">
                                {{ getInfo(tariff.depends_on, dependsTypes) }}
                            </span>
                        </el-col>
                        <el-col :sm="4" class="d-flex align-center d-flex-justify-end" :style="{'display': 'flex' }">
                            <el-button class="crm-tabs-table__btn crm-tabs-table__btn--details"
                                       @click="openTariffDetailsDialog(tariff)">
                                       Подробнее
                            </el-button>
                            <el-button class="crm-tabs-table__btn crm-tabs-table__btn--delete"
                                       :icon="binIcon"
                                       @click.prevent="() => $emit('deleteRow', tariff)" />
                        </el-col>
                    </el-row>
                    <hr />
                </div>
            </el-scrollbar>
        </template>

        <!-- Таблица объектов -->
        <template v-if="tab === 'addresses'">
            <el-row class="crm-tabs-table__header" :gutter="10">
                <el-col :sm="1" />
                <el-col :sm="18">
                    <span>Адрес объекта</span>
                </el-col>
                <el-col :sm="5" class="text-right" />
            </el-row>

            <el-scrollbar class="crm-tabs-table__body crm-tabs-table__body--addresses" :height="windowWidth > 1280 ? '306' : '236'">
                <div v-for="(address, index) in data" :key="index">
                    <el-row :gutter="10">
                        <el-col :sm="1">
                            <span>#{{ index + 1 }}</span>
                        </el-col>
                        <el-col :sm="18">
                            <span class="textMedium neutral900">{{ address.name }}</span>
                        </el-col>
                        <el-col :sm="5" class="d-flex align-center d-flex-justify-end" :style="{'display': 'flex' }">
                            <el-button class="crm-tabs-table__btn crm-tabs-table__btn--delete"
                                       :icon="binIcon"
                                       @click.prevent="() => $emit('deleteRow', tariff)" />
                        </el-col>
                    </el-row>
                    <hr />
                </div>
            </el-scrollbar>
        </template>
    </div>

    <el-dialog v-model="tariffDetailsDialog"
               @close="closeTariffDetailsDialog"
               :title="detailsTitle"
               :width="mobile ? '100%' : '80%'"
               :destroy-on-close="true">
        <ServiceTariffDetails :id="service?.id" />
    </el-dialog>
</template>

<script>

import ServiceTariffDetails from "@/views/settings/serviceTariffs/components/ServiceTariffDetails.vue";
import iconsMixin from '@/mixins/iconsMixin';
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import serviceInfo from "@/mixins/serviceInfo";
import CRMContractsAddRowButton from "@/views/crm/components/CRMContractsAddRowButton.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";

// icons
import BinIcon from '@/views/components/Icons/BinIcon.vue';

export default {
    name: 'CRMTabsTable',
    components: { ServiceTariffDetails, CRMContractsAddRowButton, FormNumber },
    mixins: [iconsMixin, functionsMixin, serviceInfo, mobileCheckMixin],
    props: ['tab', 'data', 'vatInPrice'],
    data() {
        return {
            binIcon: BinIcon,
            tariffDetailsDialog: false,
            vatInPriceModel: false,
            service: null // выбранный по кнопке "подробнее" тариф
        }
    },
    methods: {
        openTariffDetailsDialog(tariff) {
            this.service = tariff
            this.tariffDetailsDialog = true
        },
        closeTariffDetailsDialog() {
            this.service = null
            this.tariffDetailsDialog = false
        },
    },
    computed: {
        detailsTitle() {
            if(!this.service) return 'Подробности тарифа'
            return "Подробности тарифа " + '"' + this.service.name + '"'
        }
    },
    watch: {
        'vatInPrice': {
            handler: function (newValue) {
                this.vatInPriceModel = newValue
            },
            immediate: true
        },
        'vatInPriceModel': {
            handler: function (newValue) {
                this.$emit('update:vatInPrice', newValue);
            },
            deep: true,
            immediate: true
        }
    }
};
</script>
