<template>
    <div style="width: 140px;" class="d-flex-end f-direction-column">
        <el-tooltip :content="tooltip" placement="top" :disabled="!tooltip">
            <div class="d-flex-end f-direction-column gap-4 w-fit ml-auto">
                <template v-if="order.status === 'new'">
                    <el-tooltip effect="dark" placement="top"
                                :disabled="checkActionButton(order.date)">
                        <template #content>
                            До времени начала отгрузки более 2-х часов<br/><br/>
                            Для запуска в работу свяжитесь с диспетчером<br/>или обновите
                            страницу
                        </template>
                        <el-button
                            class="operatorOrderButton"
                            type="primary"
                            :icon="icons.videoPlay"
                            :disabled="!checkActionButton(order.date) || !order.confirm"
                            @click="this.$emit('openMixOrderAction', order)"
                        />
                    </el-tooltip>
                </template>
                <div v-else class="d-flex-end f-direction-column gap-4">
                    <span v-if="order.doc">В АСУ - #{{ order.doc }}</span>
                    <span v-else>В АСУ - <SmallLoader class="ml-5"/></span>
                    <div>
                        <div v-if="order.status === 'loading'" class="d-flex-end">
                            <SmallLoader v-if="order.doc" class="mr-5"/>
                            <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                                Загрузка
                            </span>
                        </div>
                        <template v-else>
                            <span class="group-orders-status-text group-orders-status-text-done">
                                Выполнена
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";
import moment from "moment";

export default {
    name: "OperatorMeka",
    props: ['order', 'operatorCheckTimeStart'],
    components: {SmallLoader},
    mixins: [iconsMixin],
    data() {
        return {}
    },
    computed: {
        tooltip() {
            let content = ''
            if (this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            return content
        }
    },
    methods: {
        checkActionButton(timestamp) {
            if (!this.operatorCheckTimeStart) {
                const date = moment.unix(timestamp);
                const currentMoment = moment();

                let diffInMinutes = currentMoment.diff(date, 'minutes')
                diffInMinutes = -diffInMinutes
                return diffInMinutes < 120;
            }

            return true
        },
    }
}
</script>

<style scoped>

</style>
