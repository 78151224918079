<template>
    <ModuleSettingsRow>
        <template v-slot:info>
            <h3>Общие показатели</h3>
            <p>Задайте общие показатели работы бетонного завода</p>
        </template>
        <template v-slot:content>
            <el-row v-for="(item, index) in modulePlans"
                    :key="index" class="tableRow">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    {{ item.name }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7">
                    <el-button @click="openCreateValueDialog(item)" class="p-0 h-auto" type="text">{{ formatNumber(item.value) }}</el-button>
                </el-col>
            </el-row>
        </template>
    </ModuleSettingsRow>
    <ModuleSettingsRow>
        <template v-slot:info>
            <h3>Планы по производству</h3>
            <p>Укажите необходимое кол-во по каждому товару</p>
        </template>
        <template v-slot:content>
            <el-row v-for="(item, index) in moduleGoods"
                    :key="index" class="tableRow">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    {{ getData(item.goodId, 'good') }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7">
                    <el-button @click="openCreateValueDialog(item, true)" class="p-0 h-auto" type="text">{{ formatNumber(item.value) }}</el-button>
                </el-col>
            </el-row>
            <el-row class="tableRow">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    Итого
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7" class="text-500">
                    {{ formatNumber(getSum(moduleGoods)) }}
                </el-col>
            </el-row>
        </template>
    </ModuleSettingsRow>

    <el-dialog
        v-model="createValueDialog"
        :title="item.name ?? getData(item.goodId, 'good')"
        :width="mobile ? '100%' : '40%'"
        :destroy-on-close="true"
    >

        <el-form v-loading="loading" ref="form" size="small" label-position="top">

            <FormNumber
                v-model="newValue"
                label="Введите актуальное значение"
                :placeholder="item.value"
            />

        </el-form>

        <el-button
            @click="createValue()"
            type="primary"
            class="w-100">
            Добавить значение
        </el-button>

    </el-dialog>
</template>

<script>
import {httpService} from '@/services/http.service'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormNumber from '@/views/components/Form/FormNumber'
import functionsMixin from '@/mixins/functionsMixin'
import ModuleSettingsRow from "@/views/components/SettingsPage/Modules/ModuleSettingsRow.vue";

export default {
    name: 'ModulePlanEdit',
    components: {ModuleSettingsRow, FormNumber},
    inject: ['api'],
    mixins: [mobileCheckMixin, functionsMixin],
    data() {
        return {
            loading: false,
            modulePlans: [],
            moduleGoods: [],
            createValueDialog: false,
            item: {
                name: null,
                key: null,
                type: null,
                value: null,
                lastUpdate: null,
                goodId: null
            },
            newValue: null,
        }
    },
    created() {
        this.getPlans()
    },
    methods: {
        getPlans() {
            httpService().post(this.api.settings.getModulePlanSettings, {
                moduleId: this.$route.params.id
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.modulePlans = data.modulePlans
                this.moduleGoods = data.moduleGoods

            })
        },
        openCreateValueDialog(item, good) {
            if (good) {
                this.item.key = 'mix_plan_production'
                this.item.goodId = item.goodId
                this.item.value = item.value
            } else {
                this.item = item
            }
            this.createValueDialog = true
        },
        createValue() {
            this.loading = true

            httpService().post(this.api.modulePlan.createValue, {
                moduleId: this.$route.params.id,
                fieldKey: this.item.key,
                value: this.newValue,
                goodId: this.item.goodId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Значение добавлено',
                        showClose: true,
                        type: 'success'
                    })

                this.createValueDialog = false
                this.item = {
                    name: null,
                    key: null,
                    type: null,
                    value: null,
                    lastUpdate: null
                }
                this.newValue = null
                this.getPlans()
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        getSum(arr) {
            let sum = 0
            arr.forEach(el => sum = sum + el.value)
            return sum
        }
    }
}
</script>

<style scoped>

</style>
