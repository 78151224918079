<template>
    <div class="plans">
        <h3 class="mt-0">Планы</h3>

        <TableHeadBlock
            v-model="search"
            :loading="loading"
            create-button-label="Создать план"
            @moduleGet="$emit('getModule')"
            @openFilterBlock="openFilterBlock"
            @openCreateValueDrawer="newPlanDrawerToTrue"
        />

        <Filter
            :filter="filter"
            :loading="loading"
            :filter-show="filterShow"
        />

        <div class="mt-15">

            <RubezhPlanOnePlan
                v-for="(item, index) in displayData"
                :key="index"
                :plan="item"
                :tasks="tasks"
                :production="productions.find(el => el.production_plan_id === item.id.toString())"
                :production-histories="productionHistories.filter(el => el.production_history_plan_id === item.id.toString())"
                @getModule="$emit('getModule')"
            />

        </div>
    </div>

    <EmptyState
        :values="displayData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="планов"
    />

    <el-drawer
        v-model="newPlanDrawer"
        title=""
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <RubezhPlanPlanDrawerCreate
            @moduleGet="$emit('getModule')"
            @closeDrawer="newPlanDrawerToFalse"
        />

    </el-drawer>

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import tableMixin from "@/mixins/tableMixin";
import moment from "moment";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import Filter from "@/views/components/Table/Filter/Filter";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import RubezhPlanPlanDrawerCreate from "@/views/custom/rubezhPlan/components/RubezhPlanPlanDrawerCreate";
import RubezhPlanOnePlan from "@/views/custom/rubezhPlan/components/RubezhPlanOnePlan";
import EmptyState from "@/views/components/EmptyState";

export default {
    name: 'RubezhPlanPlansTable',
    components: {
        EmptyState,
        RubezhPlanOnePlan,
        RubezhPlanPlanDrawerCreate, Filter, TableHeadBlock
    },
    props: ['plans', 'tasks', 'loading', 'productions', 'productionHistories'],
    inject: ['api'],
    mixins: [functionsMixin, tableMixin, iconsMixin, mobileCheckMixin],
    data() {
        return {
            newPlanDrawer: false,
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                }
            }
        }
    },
    methods: {
        newPlanDrawerToTrue() {
            this.newPlanDrawer = true;
        },
        newPlanDrawerToFalse() {
            this.newPlanDrawer = false;
        },
        getTasks(planId, onlyDone, onlyNotDone) {
            if (this.tasks) {
                var arr = this.tasks.filter(function (el) {
                    return el.task_plan_id === planId.toString()
                })

                if (onlyDone) arr = arr.filter(function (el) {
                    return el.task_date_fact
                })

                if (onlyNotDone) arr = arr.filter(function (el) {
                    return !el.task_date_fact
                })

                if (arr) {
                    return arr
                } else {
                    return []
                }
            }
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.plan_name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.plan_date_done >= moment(this.filter.dateStart.value).format("X"))
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.plan_date_done <= moment(this.filter.dateEnd.value).format("X"))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'plans': {
            handler: function () {
                this.values = this.plans

                var ids = []

                this.plans.forEach(el => ids.push(el.id))
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
