import { defineStore } from 'pinia'
import { httpService } from '@/services/http.service'
import { API_ROUTES } from "@/const";
import moment from "moment";

const orderGroupCreateForm ={
    delivery_price_manual_edit: false,
    payment_method: null,
    delivery_price: null,
    time_free_unloading: null,
    time_unloading: null,
    gidrolotok: false,
    good_price: null,
    invoice_id: null,
    contract_id: null,
    constructive: null,
    lab: false,
    spec_good_id: null,
    good_id: null,
    manager_id: null,
    carrier_company_id: null,
    date_shipment: moment().utcOffset(0, true).startOf('day'),
    delivery_type: 'delivery',
    individual: false,
    buyer_company_id: null,
    total: null,
    comment: null,
    orders_count: 0,
    delivery_address_id: null,
    company_manager_id: null,
    delivery_difficulty: 1,
    delivery_difficulty_new: 1,
    delivery_difficulty_old: 1,
    prepayment: null,
    pump: false,
    pump_vehicle_id: null,
    pump_price: null,
    pump_cost_type: null,
    comment_pump_driver: null,
    mixers: [],
    seller_company_id: null,
    type_delivery: 'withoutInterval',
    type_mixers_count: 'many',
    mix_ids: [],
    mixer_ids: [],
    mixer_max_volume: null,
    axle: null,
    custom_mixers_enabled: false,
    max_load: false,
    max_remainder: 0.0,
    custom_mixers:[]
}

export const useOrderGroupsStore = defineStore('orderGroups', {
    state: () => {
        return {
            orderGroupCreateForm:{
                delivery_price_manual_edit: false,
                payment_method: null,
                delivery_price: null,
                time_free_unloading: null,
                time_unloading: null,
                contract_id: null,
                gidrolotok: false,
                good_price: null,
                invoice_id: null,
                constructive: null,
                lab: false,
                spec_good_id: null,
                good_id: null,
                manager_id: null,
                carrier_company_id: null,
                date_shipment: moment().utcOffset(0, true).startOf('day'),
                delivery_type: 'delivery',
                individual: false,
                buyer_company_id: null,
                total: null,
                comment: null,
                orders_count: 0,
                delivery_address_id: null,
                company_manager_id: null,
                delivery_difficulty: 1,
                delivery_difficulty_new: 1,
                delivery_difficulty_old: 1,
                prepayment: null,
                pump: false,
                pump_vehicle_id: null,
                pump_price: null,
                pump_cost_type: null,
                comment_pump_driver: null,
                mixers: [],
                seller_company_id: null,
                type_delivery: 'withoutInterval',
                type_mixers_count: 'many',
                mix_ids: [],
                mixer_ids: [],
                mixer_max_volume: null,
                axle: null,
                custom_mixers_enabled: false,
                max_load: false,
                max_remainder: 0.0,
                custom_mixers:[]
            },
            orderGroups:[],
            timezone: 'Europe/Moscow',
            pagination: {
                total: 0,
            },
            summary: {
                orders_count: 0,
                volume_for_period: 0,
                delivery_count: 0,
                running_cars_count: 0,
                plant_loading: 0,
                unallocated: 0,
            },
            filters: {
                'filter[date]': moment().format('YYYY-MM-DD'),
                'filter[mix_ids]': [],
                'filter[buyer_ids]': [],
                'filter[status]': [],
            },
            address:[],
            companyManagers:[],
        }
    },
    getters: {
        getOrderGroups: (state) => state.orderGroups,
        getTimezone: (state) => state.timezone,
        getPagination: (state) => state.pagination,
        getSummary: (state) => state.summary,
        getFilters: (state) => state.filters,
        getDate: (state) => moment(state.filters['filter[date]']).utcOffset(0, true),
        getOrderGroupCreateForm: (state) => state.orderGroupCreateForm,
        getAddress: (state) => state.address,
        getCompanyManagers: (state) => state.companyManagers,
    },
    actions: {
        setOrderGroupCreateFormData(form){
            this.$patch({ orderGroupCreateForm: form})
        },
        clearOrderGroupCreateFormData(){
            this.$patch({ orderGroupCreateForm: orderGroupCreateForm})
        },
        pullAddress(companyId){
            httpService().post(API_ROUTES.company.getAttribute, {
                'companyId': companyId,
                'attribute': 'addresses',
            }).then((response) => {
                const {attribute} = response.data
                this.address = attribute
            })
        },
        pullCompanyManagers(companyId) {
            httpService().post(API_ROUTES.company.getAttribute, {
                'companyId': companyId,
                'attribute': 'managers',
            }).then((response) => {
                const {attribute} = response.data
                this.companyManagers = attribute
            })
        },
        pullOrderGroups(accountModulesId, moduleKey, filter) {
            return httpService().post(API_ROUTES.dispatch.orderGroups.getOrderGroups, {
                    account_modules_id: accountModulesId,
                    module_key: moduleKey,
                    params: filter
                }).then((response) => {

                const {data, total, timezone} = response.data

                this.setOrderGroups(data)
                this.setPaginationTotal(total)
                this.setTimezone(timezone)
            })
        },
        // //не используется
        // mergeNewOrderGroups(accountModulesId, filter){
        //     return httpService().post(API_ROUTES.dispatch.orderGroups.getOrderGroups, {
        //             account_modules_id: accountModulesId,
        //             params: filter
        //         }).then((response) => {
        //
        //         const { data } = response.data
        //
        //         const uniqueGroups = data.filter(newGroup => {
        //             return !this.orderGroups.some(existingGroup => existingGroup.id === newGroup.id);
        //         });
        //
        //         this.orderGroups = [...this.orderGroups, ...uniqueGroups];
        //     })
        // },
        changeOrderGroup(newOrderGroup){
            const index = this.orderGroups.findIndex(orderGroup => orderGroup.id === newOrderGroup.id);
            if (index !== -1) {
                this.orderGroups.splice(index, 1, newOrderGroup);
            }
        },
        pullSummary(accountModulesId, filter){
            httpService().post(API_ROUTES.dispatch.getSummary,
                {
                    account_modules_id: accountModulesId,
                    params: filter
                }
            ).then(({data}) => {
                this.setSummary(data)
            })
        },
        setSummary(summary){
            this.$patch({ summary: {...summary}})
        },
        setOrderGroups(orderGroups) {
            this.$patch({ orderGroups: orderGroups})
        },
        setPaginationTotal(total) {
            this.$patch({ pagination: {
                total: total
            }})
        },
        setTimezone(timezone) {
            this.$patch({ timezone: timezone })
        }
    }
})
