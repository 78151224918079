<template>
    <BaseErrorComponent :title="'Нет графика работы заводов на этот день'">
        <span>Для расчёта учитывается работа заводов с 00:00 до 23:59</span>
    </BaseErrorComponent>
</template>
<script>
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue"

export default {
    name: "NoMixesAvailable",
    components:{
        BaseErrorComponent
    }
}
</script>

<style scoped>

</style>
