<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Создание взвешивания"/>

            <el-form ref="form" :model="createWeighingForm" size="small" label-position="top">

                <FormSelectGoods
                    v-model="createWeighingForm.good_id"
                    :goods="goods"
                />

                <FormSelect
                    v-model="createWeighingForm.weighing_operation_id"
                    label="Тип операции"
                    placeholder="Выберите тип операции"
                    :items="operations"
                />

                <FormNumber
                    v-model="createWeighingForm.netto"
                    label="Нетто"
                    placeholder="Н-р: 9000"
                />

                <FormSelect
                    v-model="createWeighingForm.sender_company_id"
                    label="Отправитель"
                    placeholder="Отправитель груза"
                    newItemPath="CompanyCreate"
                    :items="companies"
                    new-item="company"
                    :new-item-data="createWeighingForm.sender_company_id"
                />

                <FormSelect
                    v-model="createWeighingForm.recipient_company_id"
                    label="Получатель"
                    placeholder="Получатель груза"
                    newItemPath="CompanyCreate"
                    :items="companies"
                    new-item="company"
                    :new-item-data="createWeighingForm.recipient_company_id"
                />

                <FormDateTimePicker
                    v-model="createWeighingForm.date_create"
                    placeholder="Дата взвешивания"
                    label="Выберите дату"
                    type="datetime"
                />

                <FormExpandButton
                    v-show="!expandForm"
                    @action="() => expandForm = !expandForm"
                />

                <div v-show="expandForm">

                    <FormNumber
                        v-model="createWeighingForm.tara"
                        label="Тара"
                        placeholder="Н-р: 12000"
                    />

                    <FormNumber
                        v-model="createWeighingForm.brutto"
                        label="Брутто"
                        placeholder="Н-р: 13000"
                    />

                    <FormInput
                        v-model="createWeighingForm.doc_number"
                        label="№ документа"
                        placeholder="Номер ТТН или другой"
                    />

                    <FormSelect
                        v-model="createWeighingForm.weighing_vehicle_id"
                        label="ТС"
                        placeholder="Транспортное средство"
                        newItemPath="SettingsVehicleIndex"
                        :items="vehicles"
                    />

                    <FormSelect
                        v-model="createWeighingForm.weighing_driver_id"
                        label="Водитель"
                        placeholder="Выберите водителя"
                        :items="drivers"
                    />

                </div>

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Создать взвешивание" @action="createWeighing"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import FormSelectGoods from '@/views/components/Form/FormSelectGoods'
import FormNumber from '@/views/components/Form/FormNumber'
import FormExpandButton from '@/views/components/Form/FormExpandButton'
import FormSelect from '@/views/components/Form/FormSelect'
import FormInput from '@/views/components/Form/FormInput'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import getTimezone from '@/mixins/getTimezone'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    components: {
        FormDateTimePicker,
        FormInput,
        FormSelect,
        FormExpandButton,
        FormNumber, FormSelectGoods, DrawerHeadTitle, DrawerCreateButton, DrawerFramework
    },
    props: {
        moduleId: String,
        operations: Array,
    },
    name: 'WeighingCreate',
    data() {
        return {
            loading: true,
            expandForm: false,
            createWeighingForm: {
                tara: null,
                brutto: null,
                netto: null,
                weighing_operation_id: null,
                weighing_vehicle_id: null,
                weighing_driver_id: null,
                doc_number: null,
                sender_company_id: null,
                recipient_company_id: null,
                good_id: null,
                date_create: null,
            },
            drivers: [],
            vehicles: []
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        getTimezone
    ],
    created() {
        this.getDrivers()
        this.getVehicles()
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
        })
    },
    methods: {
        createWeighing() {
            this.loading = true
            this.loadingButton = true

            httpService().post(this.api.weighingValue.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                module: this.createWeighingForm,
                timezone: this.getTimezone()
            }).then(() => {
                this.$emit('closeDrawerNewValue')
                this.$emit('updateValues')
                this.$message({
                    message: 'Взвешивание добавлено',
                    showClose: true,
                    type: 'success'
                })

                this.createWeighingForm = [{
                    tara: null,
                    brutto: null,
                    netto: null,
                    search: null,
                    weighing_operation_id: null,
                    weighing_vehicle_id: null,
                    weighing_driver_id: null,
                    doc_number: null,
                    sender_company_id: null,
                    recipient_company_id: null,
                    good_id: null,
                    date_create: null,
                }]
                this.$emit('moduleGet')
                this.loading = false
                this.loadingButton = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        getDrivers() {
            httpService().post(this.api.weighing.getDrivers, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.drivers = data.drivers

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        getVehicles() {
            httpService().post(this.api.settings.getVehicles, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.data
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        }
    },
}
</script>
