<template>
    <el-table
        empty-text="Информация не найдена"
        ref="table"
        :data="service?.childs"
        class="mt-15 w-100">
        <el-table-column
            label="Название"
            prop="name">
            <template v-slot="scope">
                <span class="textSM textMedium neutral900">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            label="Тип оплаты"
            prop="price_type">
            <template v-slot="scope">
                {{ getInfo(scope.row.price_type, priceTypes) }}
            </template>
        </el-table-column>
        <el-table-column
            label="Цена (без НДС)"
            prop="price"/>
        <el-table-column
            label="Расстояние ОТ"
            prop="distance_from"/>
        <el-table-column
            label="Расстояние ДО"
            prop="distance_to"/>
        <el-table-column
            label="Объем бетона ОТ"
            prop="volume_min"/>
        <el-table-column
            label="Объем бетона ДО"
            prop="volume_max"/>
    </el-table>
</template>

<script>
import serviceInfo from "@/mixins/serviceInfo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "ServiceDetailsMixDistance",
    props: ['service'],
    mixins: [serviceInfo, functionsMixin]
}
</script>

<style scoped>

</style>
