<template>
    <Auth title="Модули" :reloadNav="reloadNav">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    :loading="loading"
                    disable-filter="true"
                    disable-search="true"
                    disable-update-button="true"
                    create-button-label="Добавить модуль"
                    @openCreateValueDrawer="$router.push({name: 'ModuleCreate'})"
                    @moduleGet="moduleGet"
                />

                <MobileTableFramework v-for="(item, index) in modules" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Тип модуля"
                            :body="item.modules.name"
                        />

                        <MobileTableInfo
                            title="Объект"
                            :body="item.factory_id ? getInfo(item.factory_id, factories, 'name') : '-'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Настроить"
                            @action="$router.push({name: 'ModuleEdit', params: { id: item.id }})"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    v-if="!mobile"
                    :data="modules"
                    style="width: 100%">
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                    </el-table-column>
                    <el-table-column
                        prop="modules.name"
                        label="Тип модуля"
                        sortable>
                    </el-table-column>
                    <el-table-column
                        prop="factory_id"
                        label="Объект">
                        <template v-slot="scope">
                            <span v-if="!scope.row.factory_id">-</span>
                            <span v-else>{{ getInfo(scope.row.factory_id, factories, 'name') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="$router.push({name: 'ModuleEdit', params: { id: scope.row.id }})">Настроить
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-drawer
            v-model="drawerCreate"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ModuleCreate
                @moduleGet="moduleGet"
                @reloadNav="doReloadNav"
            />
        </el-drawer>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import ModuleCreate from '../../components/SettingsPage/Modules/ModuleCreate'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import functionsMixin from '@/mixins/functionsMixin'
import timezonesMixin from '@/mixins/timezonesMixin'
import { httpService } from '@/services/http.service'

export default {
    name: 'ModulesIndex',
    data() {
        return {
            modules: [],
            factories: [],
            loading: true,
            drawerCreate: false,
            reloadNav: false,
        }
    },
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth,
        ModuleCreate
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, functionsMixin, timezonesMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {

            httpService().post(this.api.settings.getFactories).then((response) => {
                let data = response.data

                if (data.success)
                    this.factories = data.factories
            })

            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.modules = data.modules

            })
        },
        openDrawer() {
            this.drawerCreate = !this.drawerCreate
        },
        doReloadNav() {
            this.reloadNav = !this.reloadNav
        }
    }
}
</script>

<style scoped>

</style>
