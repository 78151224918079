export default {
    data() {
        return {
            mixOrderGroupStatuses: [{
                id: 'unconfirmed',
                name: 'Неподтвержденный'
            }, {
                id: 'confirmed',
                name: 'Подтвержденный'
            }, {
                id: 'work',
                name: 'В работе'
            }, {
                id: 'unallocated',
                name: 'Нераспределенный'
            }, {
                id: 'laboratory',
                name: 'Лаборатория'
            }, {
                id: 'partially_distributed',
                name: 'Частично распределенный'
            }, {
                id: 'distributed',
                name: 'Распределенный'
            }, {
                id: 'done',
                name: 'Выполнен'
            }, {
                id: 'completed',
                name: 'Завершен'
            }, {
                id: 'failed',
                name: 'Отменен'
            }, {
                id: 'pause',
                name: 'Пауза'
            }],
        }
    },
}
