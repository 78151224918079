<template>
    <div v-if="logs.length > 0">
        <p v-for="(item, index) in logs" :key="index">
            <span class="neutral300">{{ index + 1 }}.</span> <span
            class="primary600 textBold">{{ item.date }}</span> - {{
                getLabel(item.type)
            }} <span class="neutral300" v-if="item.comment">
            ({{ item.comment }})
        </span>
        </p>
    </div>
    <div v-else>
        <span v-if="!loading" class="neutral300">Значения не найдены</span>
    </div>
</template>

<script>

import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'MixPageOrderDetailsHistory',
    props: ['id', 'tab'],
    inject: ['api'],
    mixins: [functionsMixin],
    data() {
        return {
            loading: true,
            logs: []
        }
    },
    methods: {
        getHistory() {
            this.loading = true

            httpService().post(this.api.userLog.getMixOrderHistory, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                id: this.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.logs = data.values
                    this.loading = false
                }
            })
        },
        getLabel(type) {
            if (type === 'createMixOrder') {
                return 'Отгрузка создана'
            } else if (type === 'editMixOrder') {
                return 'Отгрузка изменена'
            } else if (type === 'createAddress') {
                return 'Создан адрес доставки'
            } else if (type === 'editAddress') {
                return 'Изменен адрес доставки'
            } else if (type === 'updateMixOrderTime') {
                return 'Обновление записей #' + this.id
            }

            return null
        }
    },
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'history') this.getHistory()
            },
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
