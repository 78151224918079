<template>
    <Auth title="Смены" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    create-button-label="Добавить смену"
                    @moduleGet="moduleGet()"
                    @openCreateValueDrawer="openCreateShiftDrawer()"
                />

                <el-table
                    empty-text="Информация не найдена"
                    v-loading="loading"
                    v-if="displayData.length > 0"
                    :data="displayData"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="members"
                        label="Участники">
                        <template v-slot="scope">
                            <template v-if="scope.row.members.length > 0">Участники:
                                <span v-for="(member, index) in scope.row.members" :key="index">{{ member.name }}<span
                                    v-if="index !== scope.row.members.length - 1">, </span></span>
                            </template>
                            <template v-else>-</template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button :loading="loading" @click="openEditShiftDrawer(scope.row)"
                                       class="orderGroupItemDelete" :icon="icons.edit" size="small"></el-button>
                            <el-button :loading="loading" @click="deleteShift(scope.row.id)"
                                       class="orderGroupItemDelete" :icon="icons.delete" size="small"></el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <EmptyState
                    :values="displayData"
                    :search="search"
                    :loading="loading"
                    list-types="смен"
                />
            </div>
        </div>
        <el-drawer
            title=""
            v-model="createShiftDrawer"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ProductionShiftCreate @moduleGet="moduleGet" @closeDrawer="openCreateShiftDrawer()"/>
        </el-drawer>

        <el-drawer
            title=""
            v-model="editShiftDrawer"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ProductionShiftEdit @moduleGet="moduleGet" @closeDrawer="closeEditShiftDrawer()" :shift="selectedShift"/>
        </el-drawer>
    </Auth>
</template>

<script>
import {ElMessage, ElMessageBox} from 'element-plus'
import ProductionShiftCreate from './ProductionShiftCreate.vue'
import ProductionShiftEdit from './ProductionShiftEdit.vue'
import tableMixin from '../../../../mixins/tableMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import {httpService} from '@/services/http.service'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import iconsMixin from "@/mixins/iconsMixin";
import EmptyState from "@/views/components/EmptyState.vue";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionShiftsTab',
    components: {Auth, EmptyState, TableHeadBlock, ProductionShiftEdit, ProductionShiftCreate},
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, functionsMixin, mobileCheckMixin, iconsMixin, productionTabs
    ],
    data() {
        return {
            createShiftDrawer: false,
            editShiftDrawer: false,
            selectedShift: null,
            loading: false,
            users: [],
            activeShift: null
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.productionShift.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        openCreateShiftDrawer() {
            this.createShiftDrawer = !this.createShiftDrawer
        },
        openEditShiftDrawer(shift) {
            this.selectedShift = shift
            this.editShiftDrawer = true
        },
        closeEditShiftDrawer() {
            this.editShiftDrawer = false
            this.selectedShift = null
        },
        returnShiftWorkStatus(shiftId) {
            if (this.activeShift) {
                if (this.activeShift.shift) {
                    if (shiftId === this.activeShift.shift.id) {
                        return 'работает с ' + this.getDateFromUnix(this.activeShift.date_start, 'HH:mm DD.MM') + ' по ' + this.getDateFromUnix(this.activeShift.date_end, 'HH:mm DD.MM')
                    }
                }
            }

            return 'не работает'
        },
        deleteShift(shiftId) {
            ElMessageBox.confirm(
                'Удалить производственную бригаду и все связанные с ней планы/смены?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionShift.remove, {
                        shiftId: shiftId,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)

                            ElMessage({
                                type: 'error',
                                message: 'Бригада удалена',
                            })
                        this.moduleGet()
                    }).catch((error) => {
                        ElMessage({
                            type: 'error',
                            message: error.response.data.message,
                        })
                    })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: 'Удаление отменено',
                    })
                })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
