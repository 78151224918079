<template>
  <div class="uploader">
      <div class="top">
          <div class="input">
              <input id="file-uploader-input" type="file" accept="image/*" @change="handleUpload" />
              <span @click="handleClick">
                  <Unload />
                  {{ !file && !preview ? 'Загрузить' : 'Загрузить новый' }}
              </span>
          </div>
          <span v-if="file || preview" class="clear" @click="handleClear">
              <LinkOff />
              Удалить
          </span>
      </div>
      <div v-if="preview" class="preview">
          <img style="width: 100%; object-fit: cover" :src="preview" />
      </div>
  </div>
</template>

<script setup>
//name: 'FileUploader',
import {defineProps, defineEmits, ref, onMounted, watch} from 'vue';
import Unload from "@/assets/icons/Unload.vue";
import LinkOff from "@/assets/icons/LinkOff.vue";

const props = defineProps(['modelValue', 'preview']);
const emits = defineEmits(['update:modelValue']);

const file = ref(null);
const preview = ref('');
const is_delete_image = ref(0);

const handleClick = () => document.getElementById('file-uploader-input').click();

const handleUpload = (e) => {
    if (e.target.files) {
        file.value = e.target.files[0];
        emits('update:modelValue', file.value);
        if (file.value) {
            preview.value = URL.createObjectURL(e.target.files[0]);
            is_delete_image.value = 0;
        }
    }
}
const handleClear = () => {
    document.getElementById('file-uploader-input').value = "";
    file.value = null;
    emits('update:modelValue', file.value);
    preview.value = '';
    is_delete_image.value = 1;
}

watch(file, () => {
    emits('update:modelValue', file.value);
})
watch(is_delete_image, () => {
    emits('setIsDeleteImage', is_delete_image.value);
})

onMounted(() => {
    preview.value = props.preview;
})
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.uploader {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .top {
        display: flex;
        align-items: center;
        gap: 37px;
    }

    span {
        display: flex;
        align-items: center;
        gap: 4px;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

  .input {
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
        cursor: pointer;
    }

      span {
          color: $primary600;
          cursor: pointer;
      }
  }

    .clear {
        color: $neutral700;
        cursor: pointer;
    }

    .preview {
        width: 224px;
        padding: 5px;
        border-radius: 8px;
        border: 1px solid $neutral50;
        background-color: $white;
    }
}
</style>
