<template>
    <div style="width: 44px">
        <el-tooltip effect="dark" placement="top"
                    :disabled="checkActionButton(order.date)">
            <template #content>
                До времени начала отгрузки более 2-х часов<br/><br/>
                Для запуска в работу свяжитесь с диспетчером<br/>или обновите
                страницу
            </template>
            <el-button
                class="operatorOrderButton"
                v-if="order.confirm && (order.status === 'new' || order.status === 'loading')"
                :disabled="!checkActionButton(order.date)"
                :type="order.status === 'new' ? 'primary' : 'success'"
                :icon="order.status === 'new' ? icons.videoPlay : icons.check"
                @click="this.$emit('openMixOrderAction',order)"
            />
        </el-tooltip>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import moment from "moment/moment";

export default {
    name: "OperatorBaseFactory",
    props: ['order', 'operatorCheckTimeStart'],
    components: {},
    mixins: [iconsMixin],
    data() {
        return {}
    },
    created() {
    },
    methods: {
        checkActionButton(timestamp) {
            if (!this.operatorCheckTimeStart) {
                const date = moment.unix(timestamp);
                const currentMoment = moment();

                let diffInMinutes = currentMoment.diff(date, 'minutes')
                diffInMinutes = -diffInMinutes
                return diffInMinutes < 120;
            }

            return true
        },
    },
}
</script>

<style scoped>

</style>
