<template>
    <Auth :title="'Заказ № ' + $route.params.groupId" :tabs="tabs">
        <MixOrderGroupDetails
            :group-id="$route.params.groupId"
            :crm="true"
        />
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import MixOrderGroupDetails from "@/views/components/MixOrderGroup/MixOrderGroupDetails.vue";
import CRMTabs from "@/mixins/tabs/CRMTabs";

export default {
    name: 'CRMOrderDetails',
    components: {MixOrderGroupDetails,Auth},
    mixins: [CRMTabs]
}
</script>

<style scoped>

</style>
