<template>
<DrawerFramework>
    <template
        v-if="details"
        v-slot:body
    >
        <DrawerHeadTitle title="Подробности заправки"/>

        <DrawerSubTitle
            :sub-title="getDateFromUnix(details.date)"/>

        <DrawerTextLineItem
            name="Заправлено"
            :desc="formatNumber(details.count) + ' л.'"
        />

        <DrawerTextLineItem
            name="Топливо"
            :desc="details.fuel ?? '-'"
        />

        <DrawerTextLineItem
            name="Цена за литр"
            :desc="details.fuel_price ? (details.fuel_price + ' руб./л.') : 'Не указана'"
        />

        <DrawerTextLineItem
            v-if="details.fuel_price"
            name="Общая стоимость"
            :desc="(details.fuel_price * details.count) + ' руб.'"
        />

        <DrawerTextLineItem
            v-if="details.mileage_indicator"
            name="Пробег на момент заправки"
            :desc="details.mileage_indicator"
        />

        <DrawerTextLineItem
            v-if="details.total"
            name="Было до заправки"
            :desc="(details.total - details.count) + ' л.'"
        />

        <DrawerTextLineItem
            v-if="details.total"
            name="Стало после заправки"
            :desc="(details.total) + ' л.'"
        />
    </template>
    <template v-slot:footer>
        <DrawerDeleteButton @action="deleteFuel()"/>
    </template>
</DrawerFramework>
</template>

<script>
// Техника -> Заправки -> Подробности одной заправки

import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerSubTitle from '@/views/components/Drawer/DrawerSubTitle'
import functionsMixin from '@/mixins/functionsMixin'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import DrawerDeleteButton from "@/views/components/Drawer/Buttons/DrawerDeleteButton";
import {httpService} from "@/services/http.service";

export default {
    name: 'TechFuelDetailsDrawer',
    props: ['details'],
    components: {DrawerDeleteButton, DrawerTextLineItem, DrawerSubTitle, DrawerHeadTitle, DrawerFramework},
    mixins: [functionsMixin],
    inject: ['api'],
    methods: {
        deleteFuel() {
            this.$confirm(
                'Вы действительно хотите удалить заправку и пересчитать остаток топлива?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.tech.removeTechOneFuel, {
                        id: this.details.id,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.loading = true
                        this.$message({
                            message: 'Заправка удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('closeDrawer')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
