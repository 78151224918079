<template>
    <Auth>
        <div class="card">
            <div class="card-body">
                <div id="emptyState">
                    <div class="text-center">
                        <ReportLoader/>
                    </div>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import {httpService} from '@/services/http.service'
import {mapActions, mapStores} from 'pinia';
import {useCommonStore} from '@/store/common';
import {useMixStore} from "@/store/mix";
import moment from "moment/moment";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'MixIndex',
    data() {
        return {}
    },
    components: {
        ReportLoader,
        Auth
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    computed: {
        ...mapStores(useMixStore)
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setMix'
        ]),
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getModule, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.moduleId = data.moduleId
                    this.moduleName = data.module.name
                    this.tz = data.module.factory.tz
                    this.mode = data.mode
                    this.setMix(data)
                    if (this.mode === 'dispatch') {
                        this.$router.push({name: 'MixOrdersGroups', params: {id: this.$route.params.id}})
                    } else {
                        this.$router.push({name: 'MixInfo', params: {id: this.$route.params.id}})
                    }
                }

                this.integrations = data.integrations
                this.relations = data.relations
                this.loading = false
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.moduleGet()
            }

            if (!to.fullPath.includes('/mix')) {
                this.mixStore.setFilterDate(moment().startOf('day'));
            }
        }
    },
}
</script>

<style scoped>
</style>
