<template>
    <div v-if="loading" class="text-center mt-30">
        <ReportLoader/>
        <h4 class="neutral700">Загрузка..</h4>
    </div>

    <template v-else-if="!loading && service?.id">
        <el-form label-position="top">
            <FormInput
                v-model="service.name"
                label="Название"
                placeholder="Введите название"
            />

            <el-row :gutter="15">
                <el-col :sm="12">
                    <FormSelect
                        v-model="service.type"
                        label="Тип тарифа"
                        placeholder="Выберите тип"
                        :items="serviceTypes"
                        :disabled="true"
                    />
                </el-col>
                <el-col :sm="12">
                    <FormSelect
                        v-model="service.depends_on"
                        label="Модель расчета"
                        placeholder="Выберите модель"
                        :items="dependsTypes"
                        :disabled="true"
                    />
                </el-col>
            </el-row>

            <FormSelect
                v-model="service.good_id"
                label="Соответствующая номенклатура в 1С"
                placeholder="Выберите номенклатуру"
                new-item="good"
            />
        </el-form>

        <el-button @click="edit()" type="primary">Сохранить</el-button>
    </template>
</template>

<script>
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import serviceInfo from "@/mixins/serviceInfo";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "ServiceTariffEditDialog",
    props: ['id'],
    inject: ['api'],
    components: {ReportLoader},
    mixins: [serviceInfo, functionsMixin],
    data() {
        return {
            loading: true,
            service: {
                id: null,
                name: null,
                company_id: null,
                integration_unit_id: null,
                type: null,
                depends_on: null,
                good_id: null
            },
        }
    },
    created() {
        this.getOne()
    },
    methods: {
        getOne() {
            if (this.id) {
                this.loading = true
                httpService().post(this.api.serviceTariff.getOne, {
                    id: this.id
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.service = data.service
                        this.loading = false
                    }

                }).catch(() => {
                    this.$message({
                        message: 'Ошибка ответа от сервера',
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            }
        },
        edit() {
            this.loading = true

            httpService().post(this.api.serviceTariff.edit + '/' + this.id, {
                form: this.service,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Тариф обновлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('update')
                    window.setTimeout(() => {
                        this.$emit('close')
                    }, 150)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
<script setup>
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
</script>
