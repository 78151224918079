<template>
    <div class="techDetailsInfoTopBlock" v-if="techDetails">
        <div class="techDetailsInfoTopBlockBody">
            <TechImage :type="techDetails.type"/>

            <div class="techDetailsInfoTopBlockLines">
                <div class="techDetailsInfoHead">
                    <div>
                        <h2>{{
                                techDetails.brand ? techDetails.brand.name + ' ' : ''
                            }}{{ techDetails.name }}</h2>
                    </div>
                    <div class="techDetailsInfoHeadRightBlock">
                        <TechStatusButton
                            v-if="!onlyInfo"
                            @openChangeStatus="openChangeStatus()"
                            :status="techDetails ? techDetails.status : null"
                        />

                        <DrawerButton
                            v-if="!onlyInfo"
                            text="Редактировать"
                            icon="edit"
                            size="small"
                            @action="editDetailsDrawer=true"
                        />

                        <DrawerButton
                            v-if="!onlyInfo"
                            text="Удалить"
                            icon="delete"
                            size="small"
                            @action="removeTechVehicle()"
                        />
                    </div>
                </div>

                <DrawerTextLineItem
                    name="Номер"
                    :desc="techDetails.number ?? 'Не указан'"
                />

                <DrawerTextLineItem
                    name="VIN"
                    :desc="techDetails.vin ?? 'Не указан'"
                />

                <DrawerTextLineItem
                    name="СТС"
                    :desc="techDetails.ctc ?? 'Не указан'"
                />

                <DrawerTextLineItem
                    name="Объем бака"
                    :desc="techDetails.fuel_tank ? techDetails.fuel_tank + ' л.' : 'Не указан'"
                />

                <DrawerTextLineItem
                    name="Пробег в км."
                    :desc="techDetails.mileage ? formatNumber(techDetails.mileage) + ' км.' : 'Не указан'"
                />

                <DrawerTextLineItem
                    name="Пробег в мтч."
                    :desc="techDetails.engine_hours ? formatNumber(techDetails.engine_hours) + ' мтч.' : 'Не указан'"
                />

            </div>
        </div>
    </div>

    <el-dialog
        v-model="changeStatusDialog"
        title="Изменить статус для транспорта"
        :width="drawerSize"
        :destroy-on-close="true"
    >

        <TechChangeStatus
            :tech="techDetails"
            @closeDialog="closeChangeStatusDialog"
        />

    </el-dialog>
    <el-drawer
        v-model="editDetailsDrawer"
        title=""
        direction="rtl"
        :destroy-on-close="true">

        <VehicleCreate
            :id="techDetails.vehicleId"
            @closeDrawer="editDetailsDrawer=false"
            @moduleGet="$emit('getTechDetails')"
        />

    </el-drawer>
</template>

<script>
import TechImage from '@/views/components/TechPage/Garage/TechDetails/TechImage'
import TechStatusButton from '@/views/components/TechPage/Garage/TechDetails/TechStatusButton'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import TechChangeStatus from '@/views/components/TechPage/Garage/TechChangeStatus'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import functionsMixin from "@/mixins/functionsMixin";
import DrawerButton from "@/views/components/Drawer/Buttons/DrawerButton";
import {httpService} from "@/services/http.service";
import VehicleCreate from "@/views/components/SettingsPage/VehiclePage/VehicleCreate.vue";

export default {
    name: 'TechDetailsTopBlock',
    components: {
        VehicleCreate,
        DrawerButton, TechChangeStatus, DrawerTextLineItem, TechStatusButton, TechImage},
    props: ['techDetails', 'onlyInfo'],
    inject: ['api'],
    mixins: [mobileCheckMixin, functionsMixin],
    data() {
        return {
            changeStatusDialog: false,
            editDetailsDrawer: false,
        }
    },
    methods: {
        openChangeStatus() {
            this.changeStatusDialog = true
        },
        closeChangeStatusDialog() {
            this.changeStatusDialog = false
            this.$emit('getTechDetails')
            this.$emit('moduleGet')
        },
        removeTechVehicle() {
            this.$confirm(
                'Вы действительно хотите удалить техническую единицу?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.techVehicle.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                        techVehicleId: this.techDetails.id
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.loading = true
                        this.$message({
                            message: 'Техническая единица удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'tech.index', params: {id: this.$route.params.id, tab: 'garage'}})
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
