<template>
    <div class="production">
        <h3 class="mt-0 mb-0">{{ production.production_name }}</h3>

        <el-row :gutter="15">

            <el-col :sm="12">

                <p>Сделано
                    <AnimateNumber :count="doneProductionSum" :fraction="2"/>
                    из {{ formatNumber(production.production_total, 2) }}
                    {{ production.production_unit }} <span class="color-grey" v-if="needProductionSum > 0">(осталось
            {{ formatNumber(needProductionSum, 2) }}
                {{ production.production_unit }})</span>
                </p>
                <p>Отработано {{ dayDone }} из {{ plan.plan_work_days }} {{ !mobile ? 'запланированных' : 'заплан.' }}
                    дней</p>

                <template v-if="!plan.plan_date_fact">
                    <p v-if="speedProd > 0">{{ !mobile ? 'Необходимо делать' : 'Необходимо' }}
                        {{ formatNumber(speedProd, 2) }}
                        {{ production.production_unit }} в день</p>
                    <p>{{ !mobile ? 'Текущая скорость производства' : 'Тек. скор. производства' }} <span
                        v-bind:class="{ 'color-success': factSpeedProd >= speedProd, 'color-error': factSpeedProd < speedProd }">{{
                            formatNumber(factSpeedProd, 2)
                        }} {{ production.production_unit }}</span> в
                        день</p>
                    <el-button class="mt-30" :icon="icons.plus" @click="$emit('openProductionHistoryDialog')"
                               type="primary">Добавить
                        значение
                    </el-button>
                </template>
            </el-col>
            <el-col :sm="12">
                <template v-if="daysProductions.length > 0">
                    <hr v-if="mobile" class="mt-30"/>
                    <p>Статистика по дням - <span v-for="(item, index) in daysProductions"
                                                  :key="index"><span
                        class="color-grey">{{ item.day }}:</span> {{
                            formatNumber(item.value, 2)
                        }} {{ production.production_unit }}<span
                            v-if="index !== daysProductions.length - 1">, </span></span></p>

                    <el-button class="pt-0" @click="openHistoryDialog" type="text">Посмотреть все значения</el-button>
                </template>
            </el-col>

        </el-row>
    </div>

    <el-dialog
        v-model="historyDialog"
        title="История значений"
        :width="drawerSize"
        :destroy-on-close="true"
    >
        <template v-if="productionHistories.length > 0">
            <MobileTableFramework v-for="(item, index) in productionHistories" :key="index">
                <template v-slot:body>

                    <MobileTableInfo
                        title="Дата"
                        :body="getDateFromUnix(item.production_history_date, 'DD.MM.YY')"
                    />

                    <MobileTableInfo
                        title="Значение"
                        :no-border="true"
                        :body="formatNumber(Number(item.production_history_value), 2)"
                    />

                    <MobileTableButton
                        title="Удалить"
                        @action="removeProductionHistory(item.id)"
                    />

                </template>
            </MobileTableFramework>

            <el-table
                v-if="!mobile"
                :data="productionHistories"
                style="width: 100%">
                <el-table-column
                    prop="production_history_date"
                    label="Дата"
                    width="200px">
                    <template v-slot="scope">
                        {{ getDateFromUnix(scope.row.production_history_date, 'DD.MM.YY') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="production_history_value"
                    label="Значение">
                    <template v-slot="scope">
                        {{ formatNumber(Number(scope.row.production_history_value), 2) }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label=""
                    width="130">
                    <template v-slot="scope">
                        <el-button
                            size="mini"
                            @click="removeProductionHistory(scope.row.id)">Удалить
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </template>
        <template v-else>
            <p class="text-center mt-30">Значений не найдено</p>
        </template>

    </el-dialog>
</template>

<script>
import moment from "moment";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import AnimateNumber from "@/views/components/AnimateNumber";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";

export default {
    name: 'RubezhPlanOnePlanProduction',
    components: {MobileTableButton, MobileTableInfo, MobileTableFramework, AnimateNumber},
    props: ['production', 'plan', 'productionHistories'],
    mixins: [iconsMixin, functionsMixin, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            doneProductionSum: 0,
            needProductionSum: 0,
            speedProd: 0,
            factSpeedProd: 0,
            dayDone: 0,
            daysProductions: [],
            historyDialog: false
        }
    },
    methods: {
        openHistoryDialog() {
            this.historyDialog = true;
        },
        removeProductionHistory(id) {
            this.loadingButton = true

            this.$confirm(
                'Вы действительно хотите удалить значение?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: id,
                        action: 'removeProductionHistory'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        reMake() {
            let sum = 0
            this.productionHistories.forEach(el => sum = sum + Number(el.production_history_value))
            this.doneProductionSum = sum

            this.needProductionSum = Number(this.production.production_total) - sum

            let days = []
            let dayToday = moment().format('DD')
            this.productionHistories.forEach(el => days.push(moment.unix(el.production_history_date).format('DD')))

            days = days.filter(el => el !== dayToday)

            days = days.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            })

            this.dayDone = days.length

            this.daysProductions = []

            this.productionHistories.forEach((el) => {
                let date = moment.unix(el.production_history_date)

                this.daysProductions.push({
                    day: date.format('DD.MM'),
                    timestamp: el.production_history_date,
                    value: Number(el.production_history_value)
                })
            })

            const resultObject = {}

            for (let item of this.daysProductions) {
                const resultValue = resultObject[item.day]
                if (resultValue) {
                    // у нас такой уже есть, плюсуем
                    resultValue.value += Number(item.value)
                } else {
                    // такого еще нету - создаем новый
                    resultObject[item.day] = {...item}
                }
            }

            this.daysProductions = Object.values(resultObject)

            this.daysGraph = []
            this.valuesGraph = []

            this.daysProductions.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1).forEach((el) => {
                this.daysGraph.push(el.day)
                this.valuesGraph.push(el.value)
            })

            if (this.valuesGraph.length > 0) {
                this.factSpeedProd = this.valuesGraph.reduce((a, b) => a + b, 0) / this.daysGraph.length
            } else {
                this.factSpeedProd = 0
            }

            this.speedProd = this.needProductionSum / (this.plan.plan_work_days - this.dayDone)

        }
    },
    watch: {
        'productionHistories': {
            handler: function () {
                this.reMake()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
