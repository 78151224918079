<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="(vehicle ?? id) ? form.name : 'Добавление единицы техники'"/>

            <el-form ref="form" :model="form" label-width="120px" size="medium" label-position="top">

                <FormInput
                    v-model.vehicleNumber="form.number"
                    label="Регистрационный номер"
                    placeholder="Пр.: Е290КХ750"
                    :required="true"
                />
                <FormSelect
                    v-model="form.vehicle_brand_id"
                    label="Марка"
                    placeholder="Выберите марку"
                    :items="brands"
                    :required="true"
                />

                <FormInput
                    v-model="form.name"
                    label="Дополнительное название"
                    placeholder="Н-р: 320"
                    :required="true"
                />

                <FormSelect
                    v-model="form.type"
                    label="Тип"
                    placeholder="Выберите тип"
                    :items="vehicleTypes"
                />

                <template v-if="form.type === 'mix'">

                    <FormSelect
                        v-model="form.driver_id"
                        label="Водитель по умолчанию"
                        placeholder="Выберите водителя"
                        :items="users.filter(el => el.roleKey === 'mixDriver')"
                    />

                    <FormNumber
                        v-model="form.volume"
                        label="Объем миксера"
                        placeholder="8"
                    />

                    <FormInput
                        label="Название для ТН"
                        placeholder="Экскаватор CAT 330"
                        v-model="form.info.nameForDoc"
                    />

                    <el-form-item class='driverFormItem' label="Количество осей">
                        <el-select v-model="form.axle" clearable @clear="form.axle = null"
                            placeholder="Выберите, если необходимо">
                            <el-option
                                v-for="axle in axleAmount"
                                :key="axle"
                                :label="axle"
                                :value="axle">
                                <span style="float: left">{{ axle }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Есть гидролоток">
                        <el-switch v-model="form.gidrolotok" size="large" style="height: 38px"/>
                    </el-form-item>

                    <FormInput
                        label="ФИО водителя для ТН"
                        placeholder="Иванов Иван Иванович"
                        v-model="form.info.driverFIO"
                    />

                    <el-form-item label="GPS">
                        <el-switch v-model="form.gps_status" size="large" style="height: 38px"/>
                    </el-form-item>

                    <FormNumber
                        v-model="form.gps_imei"
                        label="GPS идентификатор"
                        placeholder="Пр.: 845793845783877"
                        :required="true"
                        :step="1"
                        :disabled='!form.gps_status'
                    />

                </template>
                <template v-else-if="form.type === 'pump'">

                    <el-form-item label="Арендное ТС">
                        <el-switch v-model="form.rent" label="Арендованный ТС" size="large"/>
                    </el-form-item>

                    <FormSelect
                        v-if="form.rent"
                        v-model="form.rent_company_id"
                        label="Контрагент"
                        placeholder="Выберите контрагента"
                        :items="companies"
                        new-item="company"
                        :required="true"
                    />

                    <FormNumber
                        label="Длина насоса (м)"
                        placeholder="Пр.: 15"
                        v-model="form.pump_length"
                        :required="true"
                    />

                    <el-form-item label="Тип стоимости" :required="true">
                        <el-select
                            label="Тип стоимости"
                            placeholder="Выберите тип стоимости"
                            v-model="form.cost_type"
                        >
                            <el-option value="by-hour" label="За час"/>
                            <el-option value="by-delivery" label="За отгрузку"/>
                        </el-select>
                    </el-form-item>

                    <FormNumber
                        label="Стоимость насоса (₽, с НДС)"
                        placeholder="Пр.: 15 000"
                        v-model="form.pump_price"
                    />

                    <FormNumber
                        v-if="form.rent"
                        label="Себестоимость насоса (₽, с НДС)"
                        placeholder="Пр.: 10 000"
                        v-model="form.pump_cost_price"
                    />

                    <FormInput
                        v-if="form.rent"
                        label="Имя водителя насоса"
                        placeholder="Пр.: Иванов Иван Иванович"
                        v-model="form.info.driverFIO"
                    />

                    <FormInput
                        v-if="form.rent"
                        label="Телефон водителя"
                        placeholder="Пр.: +7 926 357-75-56"
                        inputMask="+7 ### ###-##-##"
                        v-model="form.info.driverPhone"
                    />

                    <FormSelect
                        v-if="!form.rent"
                        v-model="form.driver_id"
                        label="Водитель по умолчанию"
                        placeholder="Выберите водителя"
                        :items="users.filter(el => el.roleKey === (form.type === 'mix' ? 'mixDriver' : 'pumpDriver'))"
                    />

                </template>

                <FormNumber
                    v-model="form.mileage"
                    label="Пробег текущий (км)"
                    placeholder="Укажите пробег"
                />

                <FormNumber
                    v-model="form.engine_hours"
                    label="Моточасы текущие (мтч)"
                    placeholder="Укажите пробег"
                />


                <FormNumber
                    v-model="form.fuel_tank"
                    label="Объем топливного бака"
                    placeholder="Укажите максимальный объем"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton :disabled="!form.number" title="Сохранить" @action="doCreateVehicle"/>
        </template>
    </DrawerFramework>
</template>

<script>
import {httpService} from '@/services/http.service'
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import vehicleTypes from "@/mixins/vehicleTypes";

export default {
    components: {FormNumber, FormSelect, FormInput, DrawerCreateButton, DrawerHeadTitle, DrawerFramework},
    props: [
        'vehicle', 'id'
    ],
    mixins: [vehicleTypes],
    data() {
        return {
            loading: false,
            loadingButton: false,
            form: {
                id: null,
                name: null,
                number: null,
                vehicle_brand_id: null,
                type: null,
                driver_id: null,
                volume: null,
                info: {
                    nameForDoc: null,
                    driverFIO: null,
                    driverPhone: null,
                },
                rent: false,
                pump_length: null,
                cost_type: null,
                pump_price: null,
                pump_cost_price: null,
                rent_company_id: null,
                mileage: null,
                engine_hours: null,
                fuel_tank: null,
                gidrolotok: null,
                axle: null,
                gps_imei: null,
                gps_status: false
            },
            users: [],
            brands: [],
            axleAmount: [2, 3, 4, 5]
        }
    },
    inject: [
        'api'
    ],
    name: 'VehicleCreate',
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        })
    },
    created() {
        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users

        })

        httpService().post(this.api.settings.getBrands).then((response) => {
            let data = response.data

            if (data.success)
                this.brands = data.brands

        })

        if (this.vehicle) {
            this.form = {
                ...this.vehicle, info: {
                    nameForDoc: this.vehicle.info ? (typeof this.vehicle.info.nameForDoc !== 'undefined') ? this.vehicle.info.nameForDoc : null : null,
                    driverFIO: this.vehicle.info ? (typeof this.vehicle.info.driverFIO !== 'undefined') ? this.vehicle.info.driverFIO : null : null,
                    driverPhone: this.vehicle.info ? (typeof this.vehicle.info.driverPhone !== 'undefined') ? this.vehicle.info.driverPhone : null : null,
                },
            };
        }

        if (this.id) {
            httpService().post(this.api.vehicle.getOne, {
                id: this.id
            }).then((response) => {
                let data = response.data

                if (data.success) {

                    this.form = data.vehicle
                    this.form = {
                        ...data.vehicle, info: {
                            nameForDoc: data.vehicle.info ? (typeof data.vehicle.info.nameForDoc !== 'undefined') ? data.vehicle.info.nameForDoc : null : null,
                            driverFIO: data.vehicle.info ? (typeof data.vehicle.info.driverFIO !== 'undefined') ? data.vehicle.info.driverFIO : null : null,
                            driverPhone: data.vehicle.info ? (typeof data.vehicle.info.driverPhone !== 'undefined') ? data.vehicle.info.driverPhone : null : null,
                        }
                    };

                }
            }).catch(() => {
                this.$message({
                    message: 'Единица техники не найдена',
                    showClose: true,
                    type: 'error'
                })
            })
        }

        // if (this.vehicle) this.form = {
        //     id: this.vehicle.id,
        //     name: this.vehicle.name,
        //     number: this.vehicle.number,
        //     type: this.vehicle.type,
        //     driver_id: this.vehicle.driver_id,
        //     volume: this.vehicle.volume,
        //     info: {
        //         nameForDoc: this.vehicle.info ? (typeof this.vehicle.info.nameForDoc !== 'undefined') ? this.vehicle.info.nameForDoc : null : null,
        //         driverFIO: this.vehicle.info ? (typeof this.vehicle.info.driverFIO !== 'undefined') ? this.vehicle.info.driverFIO : null : null,
        //         driverPhone: this.vehicle.info ? (typeof this.vehicle.info.driverPhone !== 'undefined') ? this.vehicle.info.driverPhone : null : null,
        //     },
        //     rent: this.vehicle.rent
        // }
    },
    methods: {
        doCreateVehicle() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.settings.createOrUpdateVehicle,
            {
                ...this.form,
                number: this.form.number.replace(/\s/g, '')
            })
            .then(() => {
                this.form = {
                    id: null,
                    name: null,
                    number: null,
                    type: null,
                    driver_id: null,
                    volume: null,
                    info: {
                        nameForDoc: null,
                        driverFIO: null,
                        driverPhone: null,
                    },
                    rent: false
                }
                this.$message({
                    message: 'Сохранено',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeEditDrawer')
                window.setTimeout(() => {
                    this.$emit('moduleGet')
                }, 50)
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            })
            this.loading = false
            this.loadingButton = false
        },
    }
}
</script>

<style scoped>

</style>
