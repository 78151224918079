import { useCommonStore } from '@/store/common';
import router from '..';

export default function guest({ next }) {
    const commonStore = useCommonStore()

    if (commonStore.isAuth) {
        router.push('/dashboard');
        return;
    }

    return next()
}
