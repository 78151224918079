import axios  from 'axios'

export function httpService() {
    const injectToken = (config) => {
        try {
            const token = localStorage.getItem('api_token')

            if (token !== null) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        } catch (error) {
            throw new Error(error)
        }
    }

    const http = axios.create({
        baseURL: process.env.VUE_APP_API_URL
    })

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error))

    return http
}
