<template>
    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="2" rx="1" fill="white"/>
        <rect x="5.5" y="6" width="16.5" height="2" rx="1" fill="white"/>
        <rect y="12" width="22" height="2" rx="1" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "MenuIcon"
}
</script>

<style scoped>

</style>
