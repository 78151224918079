<template>
    <Auth :title="$route.params.specGoodId ? 'Редактирование спецификации' : 'Новая спецификация'" :tabs="tabs">
        <div class="card" v-loading="loading">
            <div class="card-body">
                <el-form label-position="top">
                    <FormInput
                        v-model="form.name"
                        label="Название спецификации"
                        :placeholder="$route.params.specGoodId ? 'Загрузка...' : 'Например, БСТ В12,5П3 F50W2'"
                        :required="true"
                        :disabled="loading"
                        :validation-message="validationMessages.name"
                    />

                    <el-tabs v-model="tab" style="min-height: 450px;">
                        <el-tab-pane label="Продукция" name="goods">
                            <p class="textXS textRegular neutral400">
                                Продукция, к которой относится спецификация:
                            </p>

                            <el-row :gutter="15" class="mb-5">
                                <el-col :sm="22">
                                    <span class="textXS neutral700 textMedium mr-15">Продукция</span>
                                    <el-button :disabled="loading" :icon="icons.plus" type="text"
                                               class="textMedium primary600"
                                               @click.prevent="addNewRow" plain>добавить
                                    </el-button>
                                </el-col>
                            </el-row>
                            <div v-for="(good) in form.goods" :key="good.key">
                                <el-row :gutter="15">
                                    <el-col :sm="22">
                                        <FormSelect
                                            @clearAction="resetGood(good)"
                                            @getChangedValue="handleChangeGood"
                                            v-model="good.id"
                                            placeholder="Выберите продукцию"
                                            new-item="good"
                                            goodType="good"
                                            :disabled="loading"
                                        />
                                    </el-col>
                                    <el-col :sm="2">
                                        <el-form-item label-width="0" class="d-flex d-flex-end">
                                            <el-button type="danger" :icon="icons.delete"
                                                       @click.prevent="deleteRow(index, good)"></el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="good.id" class="labGoodsCreateSpec__default-row" :gutter="15">
                                    <el-col class="labGoodsCreateSpec__default-checkbox">
                                        <el-checkbox @change="(val) => handleCheckboxChange(good, val)" v-model="good.default_spec" :key="key" :label="good.default_spec">
                                            Спецификация по умолчанию
                                        </el-checkbox>
                                    </el-col>
                                    <el-col class="labGoodsCreateSpec__default-text" v-if="good.default_spec_info">
                                        <p class="m-0 textRegular neutral400">{{getDefaultSpecLabel(good)}}</p>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Рецепты" name="mixGoods">
                            <p class="textXS textRegular neutral400">
                                Рецепты с оборудования, к которым относится спецификация:
                            </p>

                            <div v-for="(good, index) in form.mixGoods" :key="index">
                                <el-row :gutter="15">
                                    <el-col :sm="5">
                                        <div class="cutOverflow" style="padding-top: 8px">
                                            <IntegrationLogo
                                                :integration-unit-id="good.integration_unit_id"
                                            />
                                        </div>
                                    </el-col>
                                    <el-col :sm="17">
                                        <FormSelect
                                            v-model="good.id"
                                            label=""
                                            placeholder="Выберите рецепт"
                                            new-item="good"
                                            goodType="mix"
                                            :disabled="loading"
                                            :integration-unit-id="good.integration_unit_id"
                                        />
                                    </el-col>
                                    <el-col :sm="2">
                                        <el-form-item label-width="0" class="d-flex d-flex-end">
                                            <el-button :disabled="loading || !good.id" type="danger"
                                                       :icon="icons.delete"
                                                       @click="() => good.id = null"></el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Параметры качества" name="info">
                            <el-row :gutter="20">
                                <el-col :sm="8">

                                    <FormSelect
                                        label="Марка бетона (М)"
                                        placeholder="М150"
                                        v-model="form.info.brand"
                                        :items="brands"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Подвижность бетона (П)"
                                        placeholder="П4"
                                        v-model="form.info.mobility"
                                        :items="mobility"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс материалов по уд. эфф. акт. естеств. радионуклидов"
                                        placeholder="I"
                                        v-model="form.info.classRadio"
                                        :items="classRadio"
                                        :disabled="loading"
                                    />

                                </el-col>
                                <el-col :sm="8">
                                    <FormInput
                                        label="Значение Аэфф (БК/л)"
                                        placeholder="370"
                                        v-model="form.info.aeff"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Добавка"
                                        placeholder="Название добавки"
                                        v-model="form.info.additiveName"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Масса добавки"
                                        placeholder="12"
                                        v-model="form.info.additiveWeight"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="№ номинального состава бетонной смеси"
                                        placeholder="6"
                                        v-model="form.info.numberComposition"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Сохр. удобоукл. и других показателей, (ч-мин)"
                                        placeholder="02:00"
                                        v-model="form.info.workabilityTime"
                                        :disabled="loading"
                                    />
                                </el-col>
                                <el-col :sm="8">
                                    <FormInput
                                        label="Наибольшая крупность заполнителя (мм)"
                                        placeholder="20"
                                        v-model="form.info.aggregateSize"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона, 28 суток (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class28d"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность, 28 суток (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength28d"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона, 7 суток (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class7d"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность, 7 суток (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength7d"
                                        :disabled="loading"
                                    />
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>

                <div class="mt-15">
                    <el-button type="primary" :disabled="loading" size="large" @click="createGood()">
                        {{ $route.params.specGoodId ? 'Сохранить' : 'Создать' }}
                    </el-button>
                    <el-button v-if="$route.params.specGoodId" @click="doRemoveGood()" :icon="icons.delete"/>
                </div>
            </div>
        </div>

        <el-dialog class="labGoodsCreateSpec__dialog"
            v-model="approvalDialog"
            title="Вы действительно хотите назначить другую спецификацию по умолчанию?"
            :width="drawerSize"
            :destroy-on-close="true">
            <p class="textMediumSM deep-blue900 labGoodsCreateSpec__dialog-label">
                Cпецификация по умолчанию "{{chosenGood?.default_spec_info?.name}}" {{chosenGood?.name && `для ${chosenGood?.name}`}} изменится на текущую
            </p>
            <template #footer>
               <el-button class="w-50" type="primary" @click="approveChange">Да</el-button>
               <el-button class="w-50" @click="declineChange">Нет</el-button>
            </template>
        </el-dialog>

    </Auth>
</template>


<script>
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import {httpService} from "@/services/http.service";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import Auth from "@/views/layouts/Auth.vue";
import LabTabs from "@/mixins/tabs/labTabs";
import concreteInfo from "@/mixins/concreteInfo";

export default {
    name: "LabGoodsCreateSpec",
    components: {Auth, IntegrationLogo, FormSelect, FormInput},
    mixins: [iconsMixin, mobileCheckMixin, LabTabs, concreteInfo],
    inject: ['api'],
    data() {
        return {
            loading: false,
            tab: 'goods',
            chosenGood: null,
            approvalDialog: false,
            previousGoodsMap: new Map(),
            form: {
                id: null,
                name: null,
                goods: [],
                mixGoods: [],
                info: {
                    class: null,
                    brand: null,
                    mobility: null,
                    strength: null,
                    classRadio: null,
                    aeff: null,
                    additiveName: null,
                    additiveWeight: null,
                    numberComposition: null,
                    workabilityTime: null,
                    aggregateSize: null,
                    class7d: null,
                    strength7d: null,
                    class28d: null,
                    strength28d: null,
                }
            },
            validationMessages: {
                name: null,
            },
            isFormValid: false
        }
    },
    created() {
        if (this.$route.params.specGoodId) {
            this.getSpecGood()
        } else {
            this.addNewRow()
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
    },
    methods: {
        getDefaultSpec(goodId, goodName) {
            // получаем данные о дефолтной спецификации по умолчанию для товара
            this.loading = true

            httpService().post(this.api.lab.getDefaultSpec, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                goodId: goodId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    const formGood = this.form.goods.find((el) => el.id === goodId)
                    formGood.default_spec_info = data.specGood;
                    if(goodName) formGood.name = goodName;
                }
            }).catch(() => {
                this.$message({
                    message: 'Операция завершилась неудачей',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
        resetGood(good) {
            good.default_spec = false
            good.default_spec_info = null
            good.id = null
            this.chosenGood = null
        },
        handleChangeGood(good) {
            good.default_spec = false;
            good.default_spec_info = null;
            if(good.id) this.getDefaultSpec(good.id, good.name)
        },
        handleCheckboxChange(good, value) {
            this.chosenGood = good
            if(good.default_spec_info?.id) {
                this.form.id !== good.default_spec_info?.id && value ? this.approvalDialog = true : ''
            }
        },
        approveChange() {
            this.approvalDialog = false;
            setTimeout(() => {
                this.chosenGood = null;
            }, 150)
        },
        declineChange() {
            this.approvalDialog = false;
            const good = this.form.goods.find((good) => good.id === this.chosenGood.id);
            setTimeout(() => {
                good.default_spec = false;
                this.chosenGood = null;
            }, 150)
        },
        getDefaultSpecLabel(good) {
            const isSpecDefault = this.form.id === good.default_spec_info?.id;
            if(isSpecDefault) good.default_spec = true;
            return isSpecDefault ? '(Текущая спецификация)' : `(Спецификация по умолчанию: ${good.default_spec_info?.name})`
        },
        createGood() {
            this.validate()
            if(!this.isFormValid) return
            this.doCreate()
            if(this.$route.params.specGoodId) {
                setTimeout(() => {
                    this.getSpecGood();
                }, 200);
            }
        },
        getSpecGood() {
            this.loading = true

            httpService().post(this.api.lab.getSpecGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                specGoodId: this.$route.params.specGoodId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.form = {
                        ...data.specGood,
                        goods: data.specGood.goods.map((el) => {
                        return {
                            ...el,
                            key: this.generateId() // уникальный id для корректного обновления списка
                        }
                    })}
                    this.loading = false

                }
            }).catch(() => {
                this.$message({
                    message: 'Операция завершилась неудачей',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        addNewRow() {
            this.form.goods.unshift({
                id: null,
                key: this.generateId(),
                default_spec: false,
                default_spec_info: null
            })
        },
        deleteRow(index, good) {
            var idx = this.form.goods.indexOf(good)
            if (idx > -1) {
                this.form.goods.splice(idx, 1)
            }
        },
        doRemoveGood() {
            this.$confirm(
                'Вы действительно хотите удалить спецификацию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.good.remove, {
                        goodId: this.$route.params.specGoodId
                    }).then(() => {
                        this.$message({
                            message: 'Спецификация удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'LabGoods', params: {id: this.$route.params.id}})
                    }).catch(() => {
                        this.$message({
                            message: 'Произошла ошибка при удалении спецификации',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        doCreate() {
            this.loading = true

            httpService().post(this.api.lab.createSpecGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: {
                    ...this.form,
                    goods: this.form.goods.map((el) => {
                        return {
                            id: el.id,
                            default_spec: el.default_spec
                        }
                    }),
                }
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    let message = 'Спецификация создана'
                    if (this.$route.params.specGoodId) {
                        message = 'Спецификация обновлена'
                    }
                    this.$message({
                        message: message,
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false
                    if (!this.$route.params.specGoodId) {
                        // this.$router.push({ name: 'LabGoods', params: { id: this.$route.params.id } })
                        this.$router.push({ name: 'LabGoodsEditSpec', params: { id: this.$route.params.id, specGoodId: data.id, } })
                        this.form.id = data.id
                    }
                }
            }).catch((error) => {
                this.setErrorMessage('name', error.response.data.message)
                this.loading = false
            })
        },
        generateId () {
            Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
        },
        validate() {
            const fields = {
                name: 'Укажите название спецификации',
            };

            this.validationMessages = Object.keys(fields).reduce((messages, field) => {
              if (!this.form[field]?.length || !this.form[field]) {
                messages[field] = fields[field];
              }
              return messages;
            },
            { ...this.validationMessages });

            if(this.validationMessages.name) {
                this.isFormValid = false
            } else {
                this.isFormValid = true
            }
        },
        setErrorMessage(key, message) {
            this.validationMessages[key] = message
        }
    },
    watch: {
        integrations: {
            handler() {
                if (this.integrations.length) {
                    if (!this.$route.params.specGoodId) {
                        this.integrations.forEach((integration) => {
                            if (integration.integration.key === 'SmartMix') {
                                this.form.mixGoods.push({
                                    id: null,
                                    integration_unit_id: integration.id
                                })
                            }
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.specGoodId': {
            handler() {
                if (this.$route.params.specGoodId && !this.isFormValid) {
                    this.getSpecGood()
                }
            },
            deep: false,
            immediate: false
        },
        'form.name': {
            handler(val, prevVal) {
                if(val && !prevVal && val.length || (val.length !== prevVal.length)) {
                    this.validationMessages = {
                        ...this.validationMessages,
                        name: null
                    }
                }
            }
        },
    }
}
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.labGoodsCreateSpec {
    &__dialog {
        text-align: center;

        &-label {
            max-width: 70%;
            margin-inline: auto;
        }

        &.el-dialog__header {
            text-align: center;
        }

        & footer {
            display: flex;
        }
    }
    &__default {
        &-row {
            margin-top: -16px;
            margin-bottom: 10px;
        }
        &-checkbox {
            max-width: fit-content !important;
            padding-right: 0 !important;

            & .el-checkbox {
                font-weight: 400 !important;
            }
        }
        &-text {
            max-width: fit-content !important;
            padding-top: 5px;

            & p {
                font-size: 14px;
                font-weight: 500;
                color: $grey-dark;
            }
        }
    }

}
</style>
