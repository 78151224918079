<template>
    <div class="faqCard">
        <h3 @click="$router.push({name: 'FaqView', params: { module: module }})">
            <SettingsIcon v-if="icon === 'settings'"/>
            <FlashIcon v-if="icon === 'flash'"/>
            <CashboxIcon v-if="icon === 'cashbox'"/>
            <WeighingIcon v-if="icon === 'weighing'"/>
            <IntegrationsIcon v-if="icon === 'integrations'"/>
            <RelationsIcon v-if="icon === 'relations'"/>
            <MixIcon v-if="icon === 'mix'"/>
            {{ title }}
        </h3>
        <el-button
            class="faqQuestion"
            v-for="(question, index) in questions.slice(0, limit ?? 3)"
            @click="$router.push({name: 'FaqViewQuestion', params: { module: question.module, key: question.key }})"
            :key="index"
        >
            {{ index + 1 }}. {{ question.title }}
        </el-button>

        <el-button
            type="text"
            class="pl-0 ml-0"
            @click="$router.push({name: 'FaqView', params: { module: module }})"
            v-if="!unlimited && questions.length > 3"
        >
            + Еще {{ questions.length - 3 }} вопроса
        </el-button>
    </div>
</template>

<script>
import FlashIcon from '@/views/components/Icons/FlashIcon'
import CashboxIcon from '@/views/components/Icons/CashboxIcon'
import WeighingIcon from '@/views/components/Icons/WeighingIcon'
import IntegrationsIcon from '@/views/components/Icons/IntegrationsIcon'
import RelationsIcon from '@/views/components/Icons/RelationsIcon'
import SettingsIcon from "@/views/components/Icons/SettingsIcon";
import MixIcon from "@/views/components/Icons/MixIcon";

export default {
    name: 'FaqCard',
    props: ['title', 'icon', 'questions', 'unlimited', 'module', 'limit'],
    components: {MixIcon, SettingsIcon, RelationsIcon, IntegrationsIcon, WeighingIcon, CashboxIcon, FlashIcon},
}
</script>

<style scoped>

</style>
