/**
 * Ключ для продуктов
 */

export default {
    data() {
        return {
            TTSProducts: [
                {
                    id: 'SmartMix',
                    name: 'SmartMix'
                },
                {
                    id: 'Elkon',
                    name: 'Elkon'
                },
                {
                    id: 'MEKA',
                    name: 'MEKA'
                },
                {
                    id: 'Dorner',
                    name: 'Dorner'
                },
                {
                    id: 'PromServ',
                    name: 'ПромСервис'
                }
            ],
        }
    },
}
