<template>
    <div class="loader"></div>
</template>

<script>
export default {
    name: "Loader",
};
</script>

<style scoped>
.loader {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #f3f3f3;
    border-left: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
