<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <rect width="48" height="48" fill="url(#a)" rx="24"/>
        <path fill="#00D0A3" d="M32.5 22.19h-2.89c-2.37 0-4.3-1.93-4.3-4.3V15c0-.55-.45-1-1-1h-4.24c-3.08 0-5.57 2-5.57 5.57v8.86C14.5 32 16.99 34 20.07 34h7.86c3.08 0 5.57-2 5.57-5.57v-5.24c0-.55-.45-1-1-1Z" opacity=".4"/>
        <path fill="#00D0A3" d="M27.8 14.21c-.41-.41-1.12-.13-1.12.44v3.49c0 1.46 1.24 2.67 2.75 2.67.95.01 2.27.01 3.4.01.57 0 .87-.67.47-1.07-1.44-1.45-4.02-4.06-5.5-5.54Zm-3.52 12.51a.754.754 0 0 0-1.06 0l-.72.72v-4.19c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.19l-.72-.72a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l2 2c.01.01.02.01.02.02.06.06.14.11.22.15.1.03.19.05.29.05.1 0 .19-.02.28-.06.09-.04.17-.09.25-.16l2-2c.29-.29.29-.77 0-1.06Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="48" y1="48" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#96ECD9"/>
                <stop offset="1" stop-color="#96ECD9" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "InvoiceSuccessIcon"
}
</script>

<style scoped>

</style>
