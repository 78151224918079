<template>
    <div>
        <div class="textMedium textSM neutral900 mb-5">Отмена подтверждения</div>
    </div>
</template>

<script>
export default {
    name: "MixOrderDetailsGroupLogUnConfirm"
}
</script>

<style scoped>

</style>
