<template>
    <Auth title="Отдел технического контроля" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-search="true"
                    :disable-create-button="true"
                    @moduleGet="getAll()"
                    :new="true"
                />

                <div class="orderGroupsTable">

                    <template v-if="!selectedGood.good">
                        <template v-if="values.length > 0">
                            <div class="orderGroupsTableItem" v-for="(item, index) in values" :key="index">
                                <span class="textMD textMedium neutral900">{{ getData(item.goodId, 'good') }}</span>
                                <div class="orderGroupsTableItemTask" @click="selectGood(item, task)"
                                     v-for="(task, index) in item.tasksByDate" :key="index">
                                    <span class="textBold">{{ getDateFromUnix(task.date, 'DD.MM') }}</span> <span
                                    class="neutral400">- {{ task.total }}
                        {{ getData(item.goodId, 'good', 'unit') }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="orderGroupsTableItem text-center pt-30 pb-30 mt-30">
                                <span class="textMD textBold neutral100">Нет доступных заданий</span>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="orderGroupsTableItem">
                            <span class="textMD textMedium neutral900">{{
                                    getData(selectedGood.good.goodId, 'good')
                                }}</span>
                            <div class="widgetControlGoodsSelected">
                                <el-button class="w-100" @click="resetGood()" :icon="icons.arrowLeft">вернуться к
                                    заданиям
                                </el-button>
                                <div class="mt-30">
                                    <div class="w-100 d-block mb-10">
                                <span class="textBold">Партия от {{
                                        getDateFromUnix(selectedGood.task.date, 'DD.MM')
                                    }}</span> <span class="neutral400">- {{ selectedGood.task.total }}
                        {{ getData(selectedGood.good.goodId, 'good', 'unit') }}</span>
                                    </div>
                                    <el-form label-position="top" v-for="(unit, index) in selectedGood.units"
                                             :key="index">
                                        <FormSelect
                                            label="Выберите внутренний склад"
                                            placeholder="Выберите склад"
                                            v-model="unit.unitId"
                                            value-key="module_reserve_id"
                                            :items="selectedGood.storehouseUnits"
                                        />

                                        <el-form-item label="">
                                            <el-input-number
                                                :disabled="!unit.unitId"
                                                class="w-100"
                                                v-model="unit.total"
                                                :min="0"
                                                :max="selectedGood.task.total"
                                            />
                                        </el-form-item>
                                        <hr/>
                                    </el-form>
                                    <el-link class="pt-10 pb-10 w-100"
                                             @click="selectedGood.units.push({unitId: null, total: null})">+
                                        Добавить сорт
                                    </el-link>
                                    <hr/>
                                    <el-button
                                        @click="doStep(selectedGood)"
                                        :disabled="getSum() !== selectedGood.task.total"
                                        class="w-100 mt-15"
                                        type="primary">
                                        <template v-if="getSum() !== selectedGood.task.total">
                                            Распределено: {{ getSum() }} из {{ selectedGood.task.total }}
                                        </template>
                                        <template v-else>Подтвердить</template>
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import FormSelect from '@/views/components/Form/FormSelect.vue'
import iconsMixin from '@/mixins/iconsMixin'
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionControlIndex',
    components: {Auth, FormSelect, TableHeadBlock},
    mixins: [mobileCheckMixin, tableMixin, functionsMixin, iconsMixin, productionTabs],
    inject: ['api'],
    data() {
        return {
            loading: false,
            modules: [],
            selectedGood: {
                type: 'control',
                good: null,
                task: null,
                storehouseUnits: [],
                units: [{
                    unitId: null,
                    total: null
                }]
            },
        }
    },
    created() {
        this.getModules()
        this.getAll()
    },
    methods: {
        selectGood(good, task) {
            this.selectedGood.good = good
            this.selectedGood.task = task
            if (this.modules.length > 0) this.selectedGood.storehouseUnits = this.modules.find(el => el.module_reserve_id === this.selectedGood.good.storehouseId && el.module_key === 'storehouse').units
        },
        resetGood() {
            this.selectedGood = {
                type: 'control',
                good: null,
                task: null,
                storehouseUnits: [],
                units: [{
                    unitId: null,
                    total: null
                }]
            }
        },
        doStep(value) {
            this.$confirm(
                'Вы подтверждаете выполнение задания?',
                'Подтвердите выполнение',
                {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionOrderStep.do, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        value: value
                    }).then((response) => {
                        let data = response.data
                        if (data.success) {
                            this.$message({
                                message: 'Задание выполнено',
                                showClose: true,
                                type: 'success'
                            })
                            this.resetGood()
                            this.getAll()
                        }
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Выполнение отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getSum() {
            let sum = 0
            this.selectedGood.units.forEach((el) => {
                if (Number(el.total) !== 0) {
                    sum = (Math.round(sum * 10) + Math.round(Number(el.total) * 10)) / 10
                }
            })
            return sum
        },
        getAll() {
            this.loading = true

            httpService().post(this.api.production.getControlTasks, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getStorehouseUnits(storehouseId) {
            let el = this.modules.find(el => el.module_reserve_id === storehouseId && el.module_key === 'storehouse')
            if (el) {
                return el.units
            } else {
                return []
            }
        },
        getModules() {
            httpService().post(this.api.production.getInfoForGoodCreate, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.modules = data.modules
                }

            })
        },
        create() {
            this.loading = true

            httpService().post(this.api.productionPost.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Пост добавлен',
                        showClose: true,
                        type: 'success'
                    })

                this.getAll()
                this.postCreateDialog = false
                this.form.name = null
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values
            this.valueLength = this.filtered.length
            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
