<template>
    <svg viewBox="0 0 1057 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M59.4 1.73327C59.8 2.66661 68.4667 18.1333 78.8667 35.9999L97.6667 68.6666L58.0667 69.0666L18.3334 69.3333L9.40004 85.0666L0.333374 100.667L62.7334 101.067C104.067 101.333 126.6 100.933 129.933 99.9999C136.6 98.1333 140.333 93.0666 140.333 85.9999C140.333 81.1999 137.4 75.3333 120.067 45.1999C109 25.8666 98.4667 7.73327 96.7334 4.93327L93.6667 -5.90753e-05H76.2C62.4667 -5.90753e-05 58.8667 0.399941 59.4 1.73327Z"
            fill="#3045CA"/>
        <path
            d="M112.333 1.06665C112.333 4.13332 153.4 72.8 156.733 75.2C162.467 79.4667 170.067 78.9333 174.867 74C178.467 70.4 200.333 34.2667 200.333 32C200.333 30.9333 185 4.13332 183.4 2.53332C182.733 1.86665 179.133 6.93332 175.4 13.7333C164.333 33.2 166.2 33.0667 156.2 15.7333L147.533 0.666654L129.933 0.266654C120.2 0.133321 112.333 0.399988 112.333 1.06665Z"
            fill="#3045CA"/>
        <path
            d="M199.533 63.5999C191.533 77.4666 190.333 80.1333 190.333 86.1333C190.333 94.2666 193 97.9999 200.2 99.9999C205.933 101.6 240.733 101.867 240.067 100.267C238.6 96.3999 210.2 47.9999 209.4 47.9999C209 47.9999 204.467 55.0666 199.533 63.5999Z"
            fill="#3045CA"/>
        <path
            d="M522.733 49.3332C511.4 51.3332 503.267 54.6666 496.867 60.3999C487.267 68.9332 487 69.9999 487 107.733C487 140.533 487 140.933 490.333 147.6C494.333 155.467 500.333 160.533 510.2 164.4C518.867 167.867 550.067 169.2 561.533 166.667C577.533 163.067 589.267 153.6 592.2 142C594.067 134.4 594.067 79.7332 592.2 72.9332C589.133 61.5999 575.4 52.2666 557.667 49.4666C547.8 47.8666 532.067 47.8666 522.733 49.3332ZM557.133 77.1999C559.933 78.9332 561.533 81.1999 562.2 83.9999C563.667 91.4666 563 128.4 561.267 133.333C558.733 140.8 553 142.933 537.8 142.4C525.667 141.867 525.267 141.733 521.533 137.6L517.667 133.333V108.267C517.667 75.0666 517.8 74.9332 540.333 74.7999C550.467 74.6666 553.8 75.1999 557.133 77.1999Z"
            fill="#3045CA"/>
        <path
            d="M888.467 50C869.667 54.2667 859 62.6667 856.2 75.2C855.267 79.6 854.867 92.9334 855.267 112C855.667 139.733 855.933 142.4 858.6 147.467C862.6 154.933 869.267 160.4 878.6 164.133C887.533 167.6 910.867 168.933 934.6 167.333L948.333 166.4V153.067C948.333 139.867 948.333 139.733 945.4 140.533C938.467 142.4 900.867 143.067 895.933 141.467C887.4 138.533 887 137.2 887 107.733C887 78 887.267 77.0667 897.133 75.2C902.6 74.1334 940.467 75.4667 945.4 76.8C948.333 77.6 948.333 77.4667 948.333 64.4V51.0667L941.4 50.1334C924.733 48.1334 897 48 888.467 50Z"
            fill="#3045CA"/>
        <path
            d="M1000.07 49.3333C983 52.4 971.8 58.8 966.733 68.2667C963.8 73.8667 963.667 75.2 963.667 108C963.667 141.733 963.667 142 967 148C971.4 155.733 979.8 162 990.467 165.333C997.533 167.467 1003 168 1017.67 168C1027.67 168 1040.47 167.6 1046.07 167.2L1056.33 166.267V152.933C1056.33 139.867 1056.33 139.733 1053.4 140.533C1046.6 142.4 1009 143.067 1003.93 141.467C995.4 138.533 995 137.2 995 107.733C995 78 995.267 77.0667 1005.13 75.2C1010.6 74.1333 1048.47 75.4667 1053.4 76.8C1056.33 77.6 1056.33 77.4667 1056.33 64.4V51.0667L1049.4 50.1333C1036.87 48.6667 1006.47 48.1333 1000.07 49.3333Z"
            fill="#3045CA"/>
        <path
            d="M293.667 108.667V166.667H309H324.333V121.333V76.0001H349H373.667V63.3334V50.6667H333.667H293.667V108.667Z"
            fill="#3045CA"/>
        <path
            d="M383.933 108.667L364.6 166.667H380.067H395.667L408.333 126.667C415.133 104.667 421.267 86.6667 421.667 86.6667C422.067 86.6667 428.2 104.667 435 126.667L447.667 166.667H463.267H478.733L459.4 108.667L440.067 50.6667H421.667H403.267L383.933 108.667Z"
            fill="#3045CA"/>
        <path
            d="M612.333 108.667V166.667H627.667H643L643.267 143.067L643.667 119.333H663H682.333L682.733 142.933L683 166.667H698.334H713.667V108.667V50.6667H698.334H683V71.3334V92.0001H663H643V71.3334V50.6667H627.667H612.333V108.667Z"
            fill="#3045CA"/>
        <path
            d="M769.8 53.8667C763.533 70.1334 726.6 164.933 726.067 165.6C725.8 166.267 732.6 166.667 741.267 166.667H757L760.733 156.667L764.467 146.667L789.4 146.933L814.2 147.333L817.8 156.667L821.267 166L837 166.4C851.533 166.667 852.6 166.533 851.8 164.4C851.267 163.067 841.133 136.933 829.267 106.4L807.667 50.6667H789.267C771.267 50.6667 770.867 50.6667 769.8 53.8667ZM797.533 103.733C801.267 113.6 804.333 121.867 804.333 122.133C804.333 122.4 797.8 122.667 789.667 122.667C779.133 122.667 775 122.267 775 121.067C775 117.733 788.867 84.0001 789.8 84.9334C790.333 85.4667 793.8 94.0001 797.533 103.733Z"
            fill="#3045CA"/>
        <path
            d="M2.99998 120.933C4.59998 123.733 8.59998 130.667 11.9333 136.267L17.9333 146.667H57.8C79.6666 146.667 97.6666 147.067 97.6666 147.467C97.6666 148 89.6666 162.267 79.8 179.2C70.0666 196.133 61.4 211.333 60.4666 212.933L58.8666 216H76.7333H94.6L117.533 176.4C137.933 140.8 140.333 136.133 140.333 130.667C140.333 125.467 139.667 123.867 136.2 120.533L132.2 116.667L66.0666 116.267L0.0666504 115.867L2.99998 120.933Z"
            fill="#3045CA"/>
        <path
            d="M196.733 118.667C186.6 124.8 187.267 132.267 199.8 153.6C206.867 165.733 209.267 168.933 210.467 167.6C211.533 166.4 228.867 136.667 239.933 116.933C240.2 116.4 231.667 116 220.733 116C203.533 116 200.467 116.4 196.733 118.667Z"
            fill="#3045CA"/>
        <path
            d="M155.667 142.933C152.067 146.4 112.333 212.8 112.333 215.333C112.333 215.733 120.333 216 130.067 216H147.8L156.2 201.333C160.733 193.2 165 186.933 165.667 187.333C166.2 187.733 170.067 194 174.333 201.333C178.467 208.667 182.333 214.667 182.867 214.667C183.4 214.667 187.667 208 192.333 199.733L200.733 184.8L197.667 179.467C182.2 152.267 175.933 142.533 172.333 140.667C166.067 137.467 160.867 138.133 155.667 142.933Z"
            fill="#3045CA"/>
    </svg>
</template>

<script>
export default {
    name: "GlonassSoftIcon"
}
</script>

<style scoped>

</style>
