<template>
    <CashboxInfoStatusBlock
        v-model="period"
        :loading="loading"
        @moduleGet="moduleGet"
    />

    <el-row :gutter="25">

        <el-col :sm="12">

            <CashboxInfoBalance
                v-model="accountId"
                :accounts="accounts"
                :total-accounts="totalAccounts"
            />

        </el-col>

        <el-col :sm="12">

            <CashboxInfoGraph
                :total-diff="totalDiff"
                :total-income="totalIncome"
                :info-graph="infoGraph"
                :total-expenditure="totalExpenditure"
            />

        </el-col>

    </el-row>

    <el-row :gutter="25" style="margin-top: 25px">

        <el-col :sm="12">

            <CashboxInfoTopBlock
                :values="topIncome"
                :categories="operationTypes"
                type="income"
            />

        </el-col>

        <el-col :sm="12">

            <CashboxInfoTopBlock
                :values="topExpenditure"
                :categories="operationTypes"
                type="expenditure"
            />

        </el-col>

    </el-row>
</template>

<script>
import {httpService} from "@/services/http.service";
import getTimezone from "@/mixins/getTimezone";
import CashboxInfoStatusBlock from "@/views/components/CashboxPage/Info/CashboxInfoStatusBlock";
import CashboxInfoBalance from "@/views/components/CashboxPage/Info/CashboxInfoBalance";
import CashboxInfoGraph from "@/views/components/CashboxPage/Info/CashboxInfoGraph";
import CashboxInfoTopBlock from "@/views/components/CashboxPage/Info/CashboxInfoTopBlock";

export default {
    name: 'CashboxInfoTab',
    components: {
        CashboxInfoTopBlock,
        CashboxInfoGraph, CashboxInfoBalance, CashboxInfoStatusBlock
    },
    props: ['tab'],
    mixins: [getTimezone],
    inject: ['api'],
    data() {
        return {
            loading: false,
            topIncome: [],
            topExpenditure: [],
            operationTypes: [],
            accounts: [],
            totalAccounts: null,
            totalIncome: null,
            totalExpenditure: null,
            totalDiff: null,
            infoGraph: [{
                name: 'Доходы',
                value: 0
            }, {
                name: 'Расходы',
                value: 0
            }],
            period: 'week',
            accountId: null,
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.cashbox.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
                tab: this.tab,
                timezone: this.getTimezone(),
                period: this.period,
                accountId: this.accountId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.topIncome = data.info.topIncome
                this.topExpenditure = data.info.topExpenditure
                this.operationTypes = data.types

                this.totalIncome = data.info.totalIncome
                this.totalExpenditure = data.info.totalExpenditure
                this.totalDiff = data.info.totalDiff
                this.totalAccounts = data.info.totalAccounts
                this.infoGraph = data.info.infoGraph
                this.accounts = data.accounts
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    },
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'info') {
                    this.moduleGet()
                }
            },
            deep: true,
            immediate: false
        },
        'period': {
            handler: function () {
                if (this.tab === 'info') {
                    this.moduleGet()
                }
            },
            deep: false,
            immediate: false
        },
        'accountId': {
            handler: function () {
                if (this.tab === 'info') {
                    this.moduleGet()
                }
            },
            deep: false,
            immediate: false
        },
        $route(to, from) {
            if (to.name === from.name) if (this.tab === 'info') {
                this.period = 'week'
                this.accountId = null
                this.moduleGet()
            }
        }
    }
}
</script>

<style scoped>

</style>
