<template>
    <div class="info" v-show="filterShow">
        <el-form size="small" label-position="top"
                 label-width="120px">
            <el-row :gutter="15">

                <template v-for="(filterItem, index) in filter" :key="index">
                    <FilterDateStart
                        v-if="filterItem.type === 'date'"
                        label="Дата"
                        type="date"
                        v-model="filterItem.value"
                    />
                    <FilterDateStart
                        v-if="filterItem.type === 'dateStart'"
                        v-model="filterItem.value"
                    />
                    <FilterDateEnd
                        v-if="filterItem.type === 'dateEnd'"
                        v-model="filterItem.value"
                    />
                    <FilterSelect
                        v-if="filterItem.type === 'select'"
                        v-model="filterItem.value"
                        :items="getItems(filterItem.items)"
                        :label="filterItem.label"
                        :type="filterItem.typeSelect"
                        :placeholder="filterItem.placeholder"
                    />
                </template>

                <FilterResetButton
                    :loading=loading
                    :hide-label=hideFilter
                    @resetFilter=resetFilter
                />

            </el-row>
        </el-form>
    </div>
</template>

<script>
import FilterDateStart from './FilterDateStart'
import FilterDateEnd from './FilterDateEnd'
import FilterSelect from './FilterSelect'
import FilterResetButton from './FilterResetButton'

export default {
    name: "Filter",
    components: {FilterResetButton, FilterSelect, FilterDateEnd, FilterDateStart},
    props: [
        'filterShow',
        'loading',
        'filter',
        'filterItems1',
        'filterItems2',
        'filterItems3',
        'filterItems4'
    ],
    data() {
        return {
            hideFilter: false
        }
    },
    methods: {
        getItems(type) {
            if (type === '1') {
                return this.filterItems1
            }
            if (type === '2') {
                return this.filterItems2
            }
            if (type === '3') {
                return this.filterItems3
            }
            if (type === '4') {
                return this.filterItems4
            }
        },
        resetFilter() {
            // сбрасывает фильтр
            for (var key in this.filter) {
                // eslint-disable-next-line vue/no-mutating-props
                this.filter[key].value = null
            }
            this.search = null
        }
    },
    watch: {
        'filter': {
            handler: function () {
                this.$emit('update:filter', this.filter)
                if (this.filter) {
                    if (Object.keys(this.filter).length !== 4) this.hideFilter = 'show'
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.el-input__wrapper {
    height: 38px !important;
}
</style>
