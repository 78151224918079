<template>
    <el-form-item
        :label="label"
    >
        <el-autocomplete
            :disabled="disabled"
            v-model="inn"
            :label="label"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            clearable
            value-key="name"
            class="w-100"
            :placeholder="placeholder"
            @select="handleSelect"
        />
    </el-form-item>
</template>
<script>
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'

export default {
    components: {},
    mixins: [functionsMixin],
    inject: ["api"],
    name: "FormDadataINN",
    props: ['placeholder', 'label', 'modelValue', 'disabled'],
    data() {
        return {
            inn: null,
            innItems: [],
            test: null,
        };
    },
    methods: {
        async querySearch(queryString, cb) {
            this.findInn(queryString)

            const results = queryString ? this.innItems : this.innItems

            cb(results)
        },

        createFilter(queryString) {
            return (inn) => {
                return inn.name.toLowerCase().includes(queryString.toLowerCase())
            }
        },

        handleSelect(item) {
            this.$emit('update:modelValue', item.value)
            this.$emit('action')
            this.$emit('info', item.info)
        },
        findInn(value) {
            httpService().post(this.api.account.suggestionINNByDaData, {
                query: value,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.innItems = data.items
                    return this.innItems
                }
            })

            return []
        }
    },
    mounted() {
    },
    watch: {
        inn(newValue) {
            this.findInn(newValue)
        },
        'modelValue': {
            handler: function () {
                this.inn = this.modelValue
            },
            deep: true,
            immediate: true
        }
    },
}
</script>
