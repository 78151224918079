<template>
    <div>
        <el-form v-loading="loading" ref="form" :model="createMixOrderForm" size="small" label-position="top">

            <el-row :gutter="15">

                <el-col :sm="8">

                    <FormNumber
                        v-model="createMixOrderForm.doc"
                        label="Номер замеса"
                        placeholder="1"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormSelect
                        v-model="createMixOrderForm.goodId"
                        label="Рецепт"
                        placeholder="Выберите рецепт"
                        :items="mixSelectGoods"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormNumber
                        v-model="createMixOrderForm.total"
                        label="Объем"
                        placeholder="25"
                    />

                </el-col>

                <el-col :sm="8" v-show="!mix">

                    <FormDateTimePicker
                        v-model="createMixOrderForm.date_start"
                        label="Время начала"
                        type="datetime"
                    />

                </el-col>

                <el-col :sm="8" v-show="!mix">

                    <FormDateTimePicker
                        v-model="createMixOrderForm.date_end"
                        label="Время окончания"
                        type="datetime"
                    />

                </el-col>

                <el-col :sm="8" v-show="details">

                    <FormNumber
                        v-model="createMixOrderForm.temp"
                        label="Температура"
                        placeholder="70 °C"
                    />

                </el-col>

                <el-col :sm="8" v-show="!details">

                    <el-button
                        class="color-grey"
                        v-bind:class="{ 'mt-30': !mobile }"
                        @click="detailsToTrue" type="text">
                        + Дополнительные настройки
                    </el-button>

                </el-col>

                <el-col :sm="8" v-show="details">

                    <FormNumber
                        v-model="createMixOrderForm.moistureFormula"
                        label="Влажность рецептурная"
                        placeholder="70 %"
                    />

                </el-col>

                <el-col :sm="8" v-show="details">

                    <FormNumber
                        v-model="createMixOrderForm.moistureFact"
                        label="Влажность фактическая"
                        placeholder="69 %"
                    />

                </el-col>

                <el-col :sm="8">

                    <FormNumber
                        v-show="details"
                        v-model="createMixOrderForm.amperage"
                        label="Ток смесителя"
                        placeholder="60 A"
                    />

                </el-col>

            </el-row>

            <h4>Расход материалов</h4>

            <MixPageOrderMixCreateMaterials
                v-model="createMixOrderForm.materials"
            />

        </el-form>
    </div>
</template>

<script>
import FormNumber from '@/views/components/Form/FormNumber'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import MixPageOrderMixCreateMaterials from '@/views/components/MixPage/Orders/OrderMix/MixPageOrderMixCreateMaterials'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormSelect from '@/views/components/Form/FormSelect'
import { httpService } from '@/services/http.service'

export default {
    name: 'MixPageOrderMixCreate',
    components: { FormSelect, MixPageOrderMixCreateMaterials, FormDateTimePicker, FormNumber },
    props: [
        'orderId',
        'createMix',
        'mix'
    ],
    mixins: [
        mobileCheckMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.loading = true

        httpService().post(this.api.mix.getGoods, {
            account_module_reserve_id: this.$route.params.id,
            module_key: 'mix'
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.mixSelectGoods = data.values
            this.loading = false
        })
    },
    data() {
        return {
            loading: false,
            details: false,
            mixSelectGoods: [],
            createMixOrderForm: {
                doc: null,
                total: null,
                temp: null,
                moistureFormula: null,
                moistureFact: null,
                amperage: null,
                date_start: null,
                date_end: null,
                materials: [{
                    'good_id': null,
                    'totalFormula': null,
                    'totalFact': null,
                    'inManual': null,
                    'adjustmentMoisture': null,
                    'adjustmentAuto': null,
                    'adjustmentManual': null,
                    'errorAuto': null,
                    'errorManual': null,
                    'details': null,
                }],
            }
        }
    },
    methods: {
        detailsToTrue() {
            this.details = true;
        },
        createOrderMix() {
            this.loading = true

            httpService().post(this.api.mix.createOrderBatch, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.createMixOrderForm,
                orderId: this.orderId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Замес добавлен',
                        showClose: true,
                        type: 'success'
                    })

                this.$emit('moduleGet')
                this.$emit('closeDialog')
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.$emit('reset')
            })
        }
    },
    watch: {
        'createMix': {
            handler: function () {
                if (this.createMix) this.createOrderMix()
                this.$emit('reset')
            },
            deep: true,
            immediate: true
        },
        'mix': {
            handler: function () {
                if (this.mix) this.createMixOrderForm = this.mix
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
