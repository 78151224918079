<template>
    <svg @mouseover="isHover=true" @mouseout="isHover=false" height="32" viewBox="0 0 98 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="logoImg" fill-rule="evenodd" clip-rule="evenodd" d="M11.8328 0.472353C12.86 -0.15745 14.1255 -0.157452 15.1527 0.472353L25.3255 6.70951C26.3527 7.33931 26.9855 8.50324 26.9855 9.76285V22.2372C26.9855 23.4968 26.3527 24.6607 25.3255 25.2905L15.1527 31.5276C14.1255 32.1575 12.86 32.1575 11.8328 31.5276L1.65999 25.2905C0.632787 24.6607 0 23.4968 0 22.2372V9.76285C0 8.50324 0.632784 7.33931 1.65999 6.70951L11.8328 0.472353ZM14.5381 4.98087C13.8967 4.58758 13.0888 4.58758 12.4474 4.98087L4.93858 9.58467C4.3455 9.9483 3.98398 10.594 3.98398 11.2897V20.7103C3.98398 21.406 4.3455 22.0517 4.93858 22.4153L12.4474 27.0191C13.0888 27.4124 13.8967 27.4124 14.5382 27.0191L22.0469 22.4153C22.64 22.0517 23.0015 21.406 23.0015 20.7103V11.2897C23.0015 10.594 22.64 9.9483 22.0469 9.58467L14.5381 4.98087Z" fill="url(#paint0_linear_730_13626)"/>
        <path v-if="!onlyLogo" class="naviHide" d="M48.4035 21.1826V26.4988H46.6935V22.7556H36.0157V8.53902H37.8053V21.1627H44.9039V8.53902H46.6736V21.1826H48.4035Z" fill="white"/>
        <path v-if="!onlyLogo" class="naviHide" d="M51.7681 18.5941C51.7681 18.7136 51.7615 18.8862 51.7482 19.1118C51.7482 19.3242 51.7416 19.5565 51.7283 19.8087C51.7151 20.0476 51.7018 20.2799 51.6886 20.5056C51.6753 20.718 51.6621 20.8905 51.6488 21.0233L57.3555 12.0832H59.503V22.7556H57.8725V16.4636C57.8725 16.2513 57.8725 15.9725 57.8725 15.6274C57.8858 15.2822 57.899 14.9438 57.9123 14.6119C57.9255 14.2668 57.9388 14.0079 57.9521 13.8354L52.2851 22.7556H50.1177V12.0832H51.7681V18.5941Z" fill="white"/>
        <path v-if="!onlyLogo" class="naviHide" d="M68.86 11.9239C69.9205 12.0168 70.8418 12.2956 71.6239 12.7602C72.4193 13.2248 73.0357 13.8487 73.4731 14.6318C73.9106 15.415 74.1293 16.3442 74.1293 17.4194C74.1293 18.4946 73.9172 19.4304 73.493 20.2268C73.0688 21.01 72.4657 21.6339 71.6836 22.0985C70.9015 22.5498 69.9603 22.8219 68.86 22.9148V27.5342H67.15V22.9148C66.1028 22.8219 65.1815 22.5498 64.3861 22.0985C63.5907 21.6339 62.9743 21.01 62.5369 20.2268C62.0994 19.4304 61.8807 18.4946 61.8807 17.4194C61.8807 16.3309 62.0928 15.3951 62.517 14.6119C62.9412 13.8155 63.551 13.1916 64.3463 12.7403C65.1417 12.2757 66.0961 12.0036 67.2096 11.9239V7.62311H68.86V11.9239ZM67.2096 13.3774C66.401 13.4571 65.7382 13.6628 65.2212 13.9947C64.7043 14.3265 64.3198 14.7778 64.068 15.3486C63.8294 15.9194 63.7101 16.6097 63.7101 17.4194C63.7101 18.2158 63.8294 18.9061 64.068 19.4901C64.3198 20.0609 64.7043 20.5189 65.2212 20.864C65.7382 21.1959 66.401 21.4016 67.2096 21.4812V13.3774ZM68.86 21.4812C69.6554 21.3883 70.3049 21.1759 70.8087 20.8441C71.3256 20.5122 71.7034 20.0609 71.9421 19.4901C72.1939 18.9061 72.3198 18.2158 72.3198 17.4194C72.3198 16.6229 72.1939 15.9393 71.9421 15.3685C71.6902 14.7977 71.3058 14.3464 70.7888 14.0146C70.285 13.6827 69.6421 13.477 68.86 13.3973V21.4812Z" fill="white"/>
        <path v-if="!onlyLogo" class="naviHide" d="M81.578 11.8841C82.8904 11.8841 83.9443 12.342 84.7396 13.258C85.5482 14.1739 85.9525 15.5544 85.9525 17.3995C85.9525 18.6074 85.767 19.6295 85.3958 20.4658C85.0379 21.2888 84.5275 21.9126 83.8647 22.3374C83.2152 22.7489 82.4463 22.9547 81.5582 22.9547C81.0147 22.9547 80.5308 22.8817 80.1066 22.7356C79.6824 22.5896 79.3179 22.4038 79.013 22.1781C78.7214 21.9392 78.4695 21.6804 78.2574 21.4016H78.1381C78.1646 21.6273 78.1911 21.9127 78.2176 22.2578C78.2442 22.6029 78.2574 22.9016 78.2574 23.1538V27.5342H76.5076V12.0832H77.9393L78.1779 13.5367H78.2574C78.4695 13.2314 78.7214 12.9526 79.013 12.7004C79.3179 12.4482 79.6758 12.2491 80.0867 12.1031C80.5109 11.9571 81.008 11.8841 81.578 11.8841ZM81.2599 13.3575C80.5441 13.3575 79.9674 13.4969 79.53 13.7756C79.0925 14.0411 78.7744 14.446 78.5756 14.9902C78.3767 15.5345 78.2707 16.2247 78.2574 17.061V17.3995C78.2574 18.2756 78.3502 19.0189 78.5358 19.6295C78.7214 20.2401 79.0329 20.7047 79.4703 21.0233C79.921 21.3419 80.5308 21.5012 81.2997 21.5012C81.9492 21.5012 82.4795 21.322 82.8904 20.9636C83.3146 20.6052 83.6261 20.1207 83.8249 19.51C84.037 18.8862 84.1431 18.176 84.1431 17.3796C84.1431 16.1583 83.9045 15.1827 83.4273 14.4526C82.9633 13.7225 82.2409 13.3575 81.2599 13.3575Z" fill="white"/>
        <path v-if="!onlyLogo" class="naviHide" d="M92.3798 11.904C93.6788 11.904 94.6399 12.1894 95.2629 12.7602C95.886 13.331 96.1975 14.2402 96.1975 15.488V22.7556H94.9249L94.5869 21.2423H94.5073C94.2025 21.6273 93.8843 21.9525 93.5529 22.218C93.2348 22.4702 92.8636 22.656 92.4394 22.7755C92.0285 22.8949 91.5247 22.9547 90.9282 22.9547C90.2919 22.9547 89.7153 22.8418 89.1983 22.6162C88.6946 22.3905 88.2969 22.0454 88.0053 21.5808C87.7136 21.1029 87.5678 20.5056 87.5678 19.7888C87.5678 18.7269 87.9854 17.9105 88.8205 17.3397C89.6556 16.7557 90.9415 16.4371 92.678 16.384L94.4875 16.3243V15.6871C94.4875 14.7977 94.2953 14.1805 93.9108 13.8354C93.5264 13.4902 92.9829 13.3177 92.2803 13.3177C91.7236 13.3177 91.1933 13.404 90.6896 13.5765C90.1859 13.7358 89.7153 13.9217 89.2778 14.134L88.741 12.8199C89.2049 12.5677 89.7551 12.3553 90.3913 12.1828C91.0276 11.9969 91.6904 11.904 92.3798 11.904ZM92.8967 17.5986C91.5711 17.6517 90.6498 17.8641 90.1329 18.2357C89.6291 18.6074 89.3773 19.1317 89.3773 19.8087C89.3773 20.406 89.5562 20.8441 89.9141 21.1228C90.2853 21.4016 90.7559 21.541 91.3259 21.541C92.2273 21.541 92.9763 21.2954 93.5728 20.8043C94.1693 20.2999 94.4676 19.53 94.4676 18.4946V17.5388L92.8967 17.5986Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_730_13626" x1="6.96259" y1="-0.220008" x2="23.4612" y2="31.0847" gradientUnits="userSpaceOnUse">
                <stop offset="0.143464" :stop-color="isHover ? '#2d84d3' : '#B8DDFE'"/>
                <stop offset="0.848241" :stop-color="isHover ? '#96c0e8' : '#1984E6'"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "DGLightLogo",
    props: ['onlyLogo'],
    data() {
        return {
            isHover: false
        }
    }
}
</script>

<style scoped>
svg:hover {
    cursor: pointer;
}

#paint0_linear_730_13626 stop {
    transition: all .3s;
}
</style>
