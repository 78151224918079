<template>
    <div>
        <el-row :gutter="16">
            <el-col :md="24" :xl="24">
                <AnalyticsMainBlock
                    title="Прибыль"
                    type="money"
                    :data="info.indicators.profit"
                    :emptyChart="true"
                    :loading="loading"
                />
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :md="8">
                <AnalyticsMainBlock
                    title="Выручка"
                    type="money"
                    :data="info.indicators.revenue"
                    :emptyChart="true"
                    :loading="loading"
                />
            </el-col>
            <el-col :sm="8">
                <AnalyticsMainBlock
                    title="Расходы"
                    type="money"
                    :data="info.indicators.costs"
                    :emptyChart="true"
                    :loading="loading"
                />
            </el-col>
            <el-col :sm="8">
                <AnalyticsMainBlock
                    title="Средн. себес. ед. продукции"
                    type="money"
                    :data="info.indicators.costPriceProduct"
                    :emptyChart="true"
                    :loading="loading"
                />
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :sm="12">
                <AnalyticsMainBlock
                    title="Произведено"
                    :data="info.indicators.totalDone"
                    :big="true"
                    unit="м3."
                    :loading="loading"
                />
            </el-col>
            <el-col :sm="12">
                <AnalyticsMainBlock
                    title="Скорость производства"
                    :data="info.indicators.speedProduction"
                    type="smena"
                    unit="м3."
                    :big="true"
                    :loading="loading"
                />
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :sm="12">
                <AnalyticsMainBlock
                    title="Прогноз производства на конец периода"
                    :big="true"
                    :data="info.indicators.futureProduction"
                    unit="м3."
                    :loading="loading"
                />
            </el-col>
            <el-col :sm="12">
                <AnalyticsMainBlock
                    title="Отхождение от рецептуры"
                    :big="true"
                    :data="info.indicators.percentDiscrepancy"
                    unit="%"
                    :loading="loading"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import AnalyticsMainBlock from "@/views/components/AnalyticsPage/AnalyticsBlocks/AnalyticsMainBlock.vue";

export default {
    name: 'AnalyticsMixModule',
    components: {
        AnalyticsMainBlock
    },
    props: ['moduleData'],
    data() {
        return {
            loading: true,
            info: {
                score: 0,
                indicators: {
                    totalDone: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        chartData: null,
                        noData: []
                    },
                    speedProduction: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    revenue: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    costs: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    profit: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    costPriceProduct: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    percentDiscrepancy: {
                        count: 0,
                        countPercent: 0,
                        planPercent: null,
                        noData: []
                    },
                    futureProduction: {
                        count: 0,
                        countPercent: 0,
                        planPercent: 0,
                        chartData: null,
                        noData: []
                    }
                }
            }
        }
    },
    watch: {
        'moduleData': {
            handler: function () {
                if (this.moduleData) {
                    this.info = this.moduleData
                    this.loading = false
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
