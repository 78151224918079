<template>
    <el-card shadow="never">
        <div class="plan-header">
            <div class="text-muted-extra-small">
                Тариф "{{ tariff.name }}"
            </div>
            <Badge v-if="tariff.price > 0 && availablePaymentPeriods[paymentPeriod].discount > 0">
                <template #default>
                    {{ availablePaymentPeriods[paymentPeriod].discount }}% скидка
                </template>
            </Badge>
        </div>
        <div class="plan-price-and-period-wrapper">
            <div class="plan-price-per-month">
                {{ formatNumber(headerPrice) }}₽ / мес
            </div>

            <div v-if="tariff.price > 0" class="plan-period-selector-row">
                <span>При оплате за</span>
                <DropdownSelector v-model="paymentPeriod" :options="availablePaymentPeriods"/>
            </div>
        </div>

        <div class="plan-includes">
            <div class="plan-includes-header">
                {{ tariff.price === 0 ? 'Что входит в тариф:' : "Подробнее о тарифе:" }}
            </div>

            <div class="plan-includes-item" v-if="tariff.price > 0">
                <div class="plan-includes-item-label">
                    <div class="plan-includes-item-label-text">
                        Стоимость тарифа
                    </div>
                </div>

                <div class="plan-includes-item-value plan-includes-item-value__blue">
                    {{ formatNumber(headerPrice) }}₽ / мес
                </div>
            </div>

            <div class="plan-includes-item" v-for="(include, index) in tariff.includes" :key="index">
                <div class="plan-includes-item-label">
                    <div class="plan-includes-item-label-text">
                        {{ include.label }}
                    </div>
                    <div v-if="include.infoText" class="plan-includes-item-label-icon">
                        <FaqIcon2 @click="openInfoModal(include.infoText, include.label)"/>
                    </div>
                </div>

                <div class="plan-includes-item-value"
                     :class="{'plan-includes-item-value__blue': !include.defaultColor}">
                    {{ include.value }}
                </div>
            </div>
        </div>

        <div v-if="tariff.extra_modules_price > 0" class="muted-warning">
            * Дополнительные модули оплачиваются отдельно по цене {{ formatNumber(extraModulePrice) }} рублей в месяц за
            модуль
        </div>

        <div v-else-if="tariff.mutedWarning" class="muted-warning">
            {{ tariff.mutedWarning }}
        </div>

        <template v-if="tariff.extras">
            <div v-for="(extra, key) in tariff.extras" class="plan-includes extra-includes" :key="key">
                <div class="plan-includes-header">
                    {{ extra.title }}
                </div>

                <div class="plan-includes-item" v-for="(extraInclude, index) in extra.items" :key="index">
                    <div class="plan-includes-item-label">
                        <div class="plan-includes-item-label-text">
                            {{ extraInclude.label }}
                        </div>
                        <div v-if="extraInclude.infoText" class="plan-includes-item-label-icon">
                            <FaqIcon2/>
                        </div>
                    </div>

                    <div class="plan-includes-item-value"
                         :class="{'plan-includes-item-value__blue': !extraInclude.defaultColor}">
                        {{ extraInclude.value }}
                    </div>
                </div>
            </div>
        </template>


        <WarningNotification v-if="tariff.warningNotificationText">
            <template #default>
                {{ tariff.warningNotificationText }}
            </template>
        </WarningNotification>

        <div class="plan-footer">
            <el-button @click.prevent="setTariff" :disabled="tariff.isSelected" type="primary">
                {{ tariff.isSelected ? 'Это ваш текущий тариф' : 'Подключить тариф' }}
            </el-button>
        </div>
    </el-card>
</template>

<script>
import FaqIcon2 from "@/views/components/Icons/FaqIcon2.vue";
import WarningNotification from "@/views/components/WarningNotification.vue";
import functionsMixin from "@/mixins/functionsMixin";
import Badge from "@/views/components/Badge.vue";
import DropdownSelector from "@/views/components/DropdownSelector.vue";
import {httpService} from "@/services/http.service";
import {mapActions} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: "PaymentPlanCard",
    mixins: [functionsMixin],
    inject: ['api'],
    props: {
        tariff: {
            type: Object,
        },
        modelValue: {
            type: [Number, String],
            default: 6,
        }
    },
    components: {
        DropdownSelector,
        Badge,
        FaqIcon2,
        WarningNotification
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.paymentPeriod = this.modelValue
            },
            deep: true,
            immediate: true
        },
        paymentPeriod: {
            handler: function () {
                this.$emit('update:modelValue', this.paymentPeriod)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            paymentPeriod: this.modelValue,
            availablePaymentPeriods: {
                1: {
                    value: 1,
                    discount: 0,
                    label: '1 месяц'
                },
                3: {
                    value: 3,
                    discount: 5,
                    label: '3 месяца'
                },
                6: {
                    value: 6,
                    discount: 10,
                    label: '6 месяцев'
                },
                12: {
                    value: 12,
                    discount: 15,
                    label: '12 месяцев'
                },
            }
        }
    },

    computed: {
        headerPrice() {
            let discount = this.availablePaymentPeriods[this.paymentPeriod].discount;
            let price = this.tariff.price;
            return discount > 0 && price !== 0 ? price - (price * discount / 100) : price;
        },
        extraModulePrice() {
            let discount = this.availablePaymentPeriods[this.paymentPeriod].discount;
            let price = this.tariff.extra_modules_price;
            return discount > 0 && price !== 0 ? price - (price * discount / 100) : price;
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setPaymentModalModalStatus',
            'setPaymentModalModalAmount'
        ]),
        openInfoModal(text, label = 'Information') {
            this.$alert(text, label);
        },
        setTariff() {

            if (this.tariff.isSelected) return;

            this.$emit('setLoadingStatus', true);
            httpService().post(this.api.settings.setTariff, {
                tariffId: this.tariff.id,
                periodInMonth: this.paymentPeriod
            }).then(response => {
                this.$emit('updateTariffs', response.data.data);
            }).catch(error => {
                if (error.response.data.error === 'balance') {
                    this.setPaymentModalModalAmount(error.response.data.amount)
                    this.setPaymentModalModalStatus(true);
                } else {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    });
                }
                this.$emit('setLoadingStatus', false);
            });
        }
    }
}
</script>

<style scoped>

</style>
