<template>
    <Auth title="Создание нового заказа">
        <el-form :action="api.cashboxOperation.create" label-width="120px" size="medium">
            <div class="card">
                <div class="card-body">

                    <el-form-item label="Название">
                        <el-input type="text" id="name" name="name" v-model="name"
                                  placeholder="Н-р: Договор на поставку щебня 20/09/22"></el-input>
                    </el-form-item>
                    <el-form-item label="Описание">
                        <el-input type="textarea" :rows="2" id="comment" name="comment" v-model="description"
                                  placeholder="Дополнительное описание"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :md="8">
                            <el-form-item label="Дата начала">
                                <el-date-picker
                                    v-model="dateStart"
                                    type="date"
                                    format="DD.MM.YYYY"
                                    placeholder="Выберите дату">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :md="1">
                        </el-col>
                        <el-col :md="8">
                            <el-form-item label="Дата окончания">
                                <el-date-picker
                                    v-model="dateEnd"
                                    type="date"
                                    format="DD.MM.YYYY"
                                    placeholder="Выберите дату">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="Контрагент">
                        <el-select v-model="companyId" placeholder="Выберите компанию">
                            <NewItemOption path="CompanyCreate"/>
                            <el-option
                                v-for="company in companies"
                                :key="company.id"
                                :label="company.name"
                                :value="company.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <el-row v-for="(contractGood, index) in contractGoods" :key="index" :gutter="20">
                        <el-col :md="12">
                            <el-form-item label="">
                                <el-select v-model="contractGood.good_id" placeholder="Выберите товар/груз">
                                    <NewItemOption path="goods"/>
                                    <el-option
                                        v-for="good in goods"
                                        :key="good.id"
                                        :label="good.name"
                                        :value="good.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5">
                            <el-form-item :label-width="0">
                                <el-input type="number" v-model="contractGood.total"
                                          placeholder="Количество"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="5">
                            <el-form-item :label-width="0">
                                <el-input type="number" v-model="contractGood.price"
                                          placeholder="Цена за шт."></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :md="1">
                            <el-form-item :label-width="0">
                                <el-button type="danger" icon="el-icon-delete"
                                           @click.prevent="deleteRow(index, contractGood)"></el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="" class="mb-0">
                        <el-button :loading="loadingButton" icon="el-icon-plus" type="info"
                                   @click.prevent="addNewRow" plain>Добавить элемент
                        </el-button>
                    </el-form-item>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <el-form-item label="" class="mb-0">
                        <el-button :loading="loadingButton" icon="el-icon-check" type="primary"
                                   @click.prevent="createContract">Создать контракт
                        </el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import NewItemOption from '../components/NewItemOption'
import { httpService } from '@/services/http.service'

export default {
    name: 'orderCreate',
    components: {
        NewItemOption,
        Auth
    },
    data() {
        return {
            loading: true,
            loadingButton: true,
            dateStart: null,
            dateEnd: null,
            name: null,
            description: null,
            companyId: null,
            companies: [],
            goods: [],
            contractGoods: [{
                good_id: '',
                total: '',
                price: '',
            }],
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.getCompanies()
        this.getGoods()
    },
    methods: {
        getCompanies() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.company.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.companies = data.companies
            })
        },
        getGoods() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.good.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.goods = data.goods
            })
        },
        createContract() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.contract.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'contract',
                company_id: this.companyId,
                contract_id: this.$route.params.id,
                name: this.name,
                description: this.description,
                date_start: this.dateStart,
                date_end: this.dateEnd,
                contractGoods: this.contractGoods
            }).then(() => {
                this.companyId = null
                this.name = null
                this.description = null
                this.dateStart = null
                this.dateEnd = null
                this.contractGoods = [{
                    good_id: '',
                    total: '',
                    price: '',
                }]
                this.$message({
                    message: 'Контракт создан',
                    type: 'success',
                    showClose: true,
                })
                this.loading = false
                this.loadingButton = false


            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        deleteRow(index, contractGood) {
            var idx = this.contractGoods.indexOf(contractGood)

            if (idx > -1) {
                this.contractGoods.splice(idx, 1)
            }
        },
        addNewRow() {
            this.contractGoods.push({
                good_id: '',
                total: '',
                price: '',
            })
        }
    }
}
</script>

<style scoped>

</style>
