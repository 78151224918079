<template>
    <Auth :title="moduleName" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <template v-if="loadContent">

                    <RubezhPlanIndex v-if="$route.params.key === 'rubezhPlan'"/>

                </template>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import {httpService} from '@/services/http.service'
import RubezhPlanIndex from "@/views/custom/rubezhPlan/RubezhPlanIndex";

export default {
    name: 'CustomShow',
    data() {
        return {
            moduleName: null,
            integrations: [],
            relations: [],
            loadContent: false,
            data: null
        }
    },
    components: {RubezhPlanIndex, Auth},
    inject: ['api'],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.custom_module.getModule, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loadContent = true
                this.moduleName = data.module.name
                this.integrations = data.integrations
                this.relations = data.relations

                document.title = this.moduleName
            })
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>
