<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99995 22H15.9999C20.0199 22 20.7399 20.39 20.9499 18.43L21.6999 10.43C21.9699 7.99 21.2699 6 16.9999 6H6.99995C2.72995 6 2.02995 7.99 2.29995 10.43L3.04995 18.43C3.25995 20.39 3.97995 22 7.99995 22Z"
            stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
            stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.62 11.27C4.87 12.81 7.41 13.74 10 14.03" stroke-width="1.5" stroke-miterlimit="10"
              stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "CompaniesIcon"
}
</script>

<style scoped>

</style>
