<template>
    <div class="topCard">
        <h3>Объем поставок контрагентам</h3>

        <div class="topCardScroll">
            <el-row class="stickyTop">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="text-500">
                    Контрагент
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7" class="text-500">
                    Объем
                </el-col>
            </el-row>
            <el-row v-for="(company, index) in topCompanies"
                    :key="index">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    {{ company.id ? getData(company.id, 'company') : '-' }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7">
                    {{ company.total ? formatNumber(company.total) : '-' }}
                    <good-unit :unit="company.total ? getUnitFromIds(company.units) : ''"/>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import GoodUnit from '@/views/components/goodUnit'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'

/**
 * Отображает топ контрагентов на сводке бетонного завода и весовой
 **/
export default {
    name: "InfoTopCompaniesBlock",
    components: {GoodUnit},
    props: [
        'topCompanies'
    ],
    mixins: [
        functionsMixin, getUnitFromGoodsMixin
    ]
}
</script>

<style scoped>
    .topCard {
        padding: 20px;
    }
</style>
