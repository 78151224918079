<template>
    <Auth title="Добавление модуля" :reloadNav="reloadNav">
        <div class="card create-module">
            <div class="card-body">
                <el-form ref="form" :model="moduleForm" size="small" label-position="top">
                    <el-row gutter="20">
                        <el-col :sm="18">
                            <h3>Настройки модуля</h3>

                            <el-row :gutter="15">
                                <el-col :sm="12">

                                    <FormInput
                                        v-model="moduleForm.name"
                                        label="Название для модуля и ссылки в меню:"
                                        placeholder="Н-р: Денежный учет"
                                        inputMask="$$$$$$$$$$$$$$$$$$$$$$$"
                                        maskTokens="$:[\s\S]:"
                                    />

                                </el-col>
                                <el-col :sm="8">

                                    <FormSelect
                                        v-model="moduleForm.factory"
                                        label="Объект"
                                        placeholder="Выберите объект"
                                        :items="factories"
                                    />

                                </el-col>
                            </el-row>

                            <el-form-item label="Тип модуля:" class="modulesList">
                                <el-radio @change="doNameForModule(module.name)" v-model="moduleForm.key"
                                          v-for="(module, index) in modules" :key="index"
                                          :label="module.key" :disabled="module.status === 'soon'" border>
                                    <el-tag v-if="module.status === 'soon'" type="info">Скоро</el-tag>
                                    <el-tag v-if="module.status === 'new'" type="success">Новинка</el-tag>
                                    <div class="icon">
                                        <CashboxIcon v-if="module.key === 'cashbox'"/>
                                        <ContractIcon v-if="module.key === 'contract'"/>
                                        <WeighingIcon v-if="module.key === 'weighing'"/>
                                        <StorehouseIcon v-if="module.key === 'storehouse'"/>
                                        <ProductionIcon v-if="module.key === 'production'"/>
                                        <OrderIcon v-if="module.key === 'order'"/>
                                        <TechIcon v-if="module.key === 'tech'"/>
                                        <AdminIcon v-if="module.key === 'godmode'"/>
                                        <TTSIcon v-if="module.key === 'tts'"/>
                                        <MixIcon v-if="module.key === 'mix'"/>
                                        <CustomModuleIcon v-if="module.custom"/>
                                        <LogisticsIcon v-if="module.key === 'logistics'"/>
                                        <FuelIcon v-if="module.key === 'fuel'"/>
                                        <DispatchIcon v-if="module.key === 'dispatch'"/>
                                        <CRMIcon v-if="module.key === 'crm'"/>
                                        <LabIcon v-if="module.key === 'lab'"/>
                                    </div>
                                    <span class="title">{{ module.name }}</span>
                                    <span class="description">{{ module.description }}</span>
                                </el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="6">
                            <h3>Доступ пользователям</h3>
                            <el-form-item label="Пользователи, которым доступен модуль:">
                                <el-checkbox-group class="inlineCheckbox" v-model="moduleForm.users" size="large">
                                    <el-checkbox v-for="user in users" :key="user.id" :label="user.id">{{
                                            user.name
                                        }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="24">
                            <el-form-item>
                                <el-button @click="doCreateModule" size="large" :loading="loadingButton" :icon="icons.plus"
                                           type="primary">Создать модуль
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import CashboxIcon from '../../components/Icons/CashboxIcon'
import ContractIcon from '../../components/Icons/ContractIcon'
import ProductionIcon from '../../components/Icons/ProductionIcon'
import WeighingIcon from '../../components/Icons/WeighingIcon'
import StorehouseIcon from '../../components/Icons/StorehouseIcon'
import OrderIcon from '../../components/Icons/OrderIcon'
import TechIcon from '../../components/Icons/TechIcon'
import AdminIcon from '../../components/Icons/AdminIcon'
import TTSIcon from '../../components/Icons/TTSIcon'
import MixIcon from '../../components/Icons/MixIcon'
import FormInput from '@/views/components/Form/FormInput'
import FormSelect from '@/views/components/Form/FormSelect'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import CustomModuleIcon from "@/views/components/Icons/CustomModuleIcon";
import LogisticsIcon from "@/views/components/Icons/LogisticsIcon";
import FuelIcon from "@/views/components/Icons/FuelIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";
import LabIcon from "@/views/components/Icons/LabIcon.vue";

export default {
    name: 'ModulesCreate',
    data() {
        return {
            loading: true,
            loadingButton: true,
            modules: [],
            mixes: [],
            users: [],
            factories: [],
            moduleForm: {
                name: '',
                key: '',
                users: [],
                factory: null,
                mixes: [],
            },
            reloadNav: false,
        }
    },
    components: {
        LabIcon,
        CRMIcon,
        DispatchIcon,
        FuelIcon,
        LogisticsIcon,
        CustomModuleIcon,
        FormSelect,
        FormInput,
        MixIcon,
        TTSIcon,
        AdminIcon,
        Auth,
        TechIcon, OrderIcon, StorehouseIcon, WeighingIcon, CashboxIcon, ProductionIcon, ContractIcon
    },
    inject: [
        'api'
    ],
    mixins: [iconsMixin],
    created() {
        this.loading = true
        httpService().post(this.api.module.get).then((response) => {
            let data = response.data

            if (data.success)
                this.modules = data.modules

        })

      httpService().post(this.api.account_module.get).then((response) => {
        let data = response.data

        if (data.success) {
          this.mixes = data.modules.filter((module) => module?.module_key === 'mix').map((module) => ({id: module.id, name: module.name}))
        }
      })

        httpService().post(this.api.settings.getFactories).then((response) => {
            let data = response.data

            if (data.success)
                this.factories = data.factories
        })

        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users
            this.loading = false
            this.loadingButton = false

        })
    },
    methods: {
        doCreateModule() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.module.create, {
                module: this.moduleForm,
            }).then((response) => {
                let data = response.data
                this.$message({
                    message: data.message,
                    showClose: true,
                    type: 'success'
                })
                this.moduleForm = {
                    name: '',
                    key: '',
                    users: [],
                }

                this.reloadNav = !this.reloadNav
                this.$router.push({ name: 'ModulesIndex' })
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
                this.loadingButton = false
            })
        },
        doNameForModule(name) {
            if (!this.moduleForm.name) this.moduleForm.name = name
        }
    }
}
</script>
