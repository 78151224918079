<template>
    <div>

        <p>Ниже указаны параметры фильтрации продукции, компаний и типов операций, по условиям которых осуществляет
            отправка значений в производственный модуль.</p>

        <h4>1. Продукция</h4>

        <el-form-item label="">
            <el-select v-model="selectGood" @change="updateList($event, 'goods')" filterable
                       placeholder="Нажмите, чтобы выбрать">
                <el-option v-for="good in filters.goods" :key="good.id"
                           :label="good.name"
                           :disabled="good.disable"
                           :value="good.id"></el-option>
            </el-select>
        </el-form-item>

        <el-tag class="mr-10 mb-10" @close="deleteFromList(id, 'goods')"
                v-for="id in relationForm.filter.goods" :key="id" closable type="info">
            {{ getData(id, 'good') }}
        </el-tag>

        <h4>2. Компания отправитель</h4>

        <el-form-item label="">
            <el-select v-model="selectSender" @change="updateList($event,'senderCompanies')"
                       filterable
                       placeholder="Нажмите, чтобы выбрать">
                <el-option v-for="company in filters.senderCompanies" :key="company.id"
                           :label="company.name"
                           :disabled="company.disable"
                           :value="company.id"></el-option>
            </el-select>
        </el-form-item>
        <el-tag class="mr-10 mb-10" @close="deleteFromList(id, 'senderCompanies')"
                v-for="id in relationForm.filter.sender_companies" :key="id" closable
                type="info">
            {{ getData(id, 'company') }}
        </el-tag>

        <h4>3. Компания получатель</h4>

        <el-form-item label="">
            <el-select v-model="selectRecipient"
                       @change="updateList($event,'recipientCompanies')" filterable
                       placeholder="Нажмите, чтобы выбрать">
                <el-option v-for="company in filters.recipientCompanies" :key="company.id"
                           :label="company.name"
                           :disabled="company.disable"
                           :value="company.id"></el-option>
            </el-select>
        </el-form-item>

        <el-tag class="mr-10 mb-10" @close="deleteFromList(id, 'recipientCompanies')"
                v-for="id in relationForm.filter.recipient_companies" :key="id" closable
                type="info">
            {{ getData(id, 'company') }}
        </el-tag>

        <h4>4. Тип операции</h4>

        <el-form-item label="">
            <el-checkbox-group v-model="relationForm.filter.weighing_operations">
                <el-checkbox v-for="operation in filters.weighingOperations" :key="operation.id"
                             :label="operation.id">{{
                        operation.name
                    }}
                </el-checkbox>
            </el-checkbox-group>
        </el-form-item>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    name: 'WeighingProductionSettings',
    props: [
        'relationSettings'
    ],
    mixins: [
        functionsMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.loading = true

        httpService().post(this.api.settings.getOperations).then((response) => {
            let data = response.data

            if (data.success)
                this.filters.weighingOperations = data.operations

        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
        })

        httpService().post(this.api.good.get).then((response) => {
            let data = response.data

            if (data.success)
                this.filters.goods = data.goods

        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
        })

        httpService().post(this.api.company.get).then((response) => {
            let data = response.data

            if (data.success)
                this.filters.senderCompanies = data.companies
            this.filters.recipientCompanies = data.companies

        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
        })

        var settings = JSON.parse(this.relationSettings)

        this.relationForm = {
            filter: {
                goods: settings.goods,
                sender_companies: settings.sender_companies,
                recipient_companies: settings.recipient_companies,
                weighing_operations: settings.weighing_operations
            }
        }

        this.loading = false
    },
    data() {
        return {
            companies: [],
            weighingOperations: [],
            relationForm: {
                sender_id: null,
                recipient_id: null,
                filter: {
                    goods: [],
                    sender_companies: [],
                    recipient_companies: [],
                    weighing_operations: [],
                }
            },
            filters: {
                goods: [],
                senderCompanies: [],
                recipientCompanies: [],
            },
            selectGood: null,
            selectRecipient: null,
            selectSender: null,
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        })
    },
    methods: {
        updateList(id, type) {
            let arrList = []
            let arrForm = []

            if (type === 'goods') {
                arrList = this.filters.goods
                arrForm = this.relationForm.filter.goods
                this.selectGood = null
            }

            if (type === 'senderCompanies') {
                arrList = this.filters.senderCompanies
                arrForm = this.relationForm.filter.sender_companies
                this.selectSender = null
            }

            if (type === 'recipientCompanies') {
                arrList = this.filters.recipientCompanies
                arrForm = this.relationForm.filter.recipient_companies
                this.selectRecipient = null
            }

            // добавляем элемент в массив
            arrForm.push(id)

            // добавляем свойство disable для option в select
            arrForm.forEach(element => {
                const objIndex = arrList.findIndex((data => data.id === element))
                if (objIndex) arrList[objIndex].disable = true
            })
        },
        deleteFromList(id, type) {
            let arrList = 0
            let arrForm = 0

            if (type === 'goods') {
                arrList = this.filters.goods
                arrForm = this.relationForm.filter.goods
            }

            if (type === 'senderCompanies') {
                arrList = this.filters.senderCompanies
                arrForm = this.relationForm.filter.sender_companies
            }

            if (type === 'recipientCompanies') {
                arrList = this.filters.recipientCompanies
                arrForm = this.relationForm.filter.recipient_companies
            }

            const index = arrForm.indexOf(id)
            if (index > -1) {
                arrForm.splice(index, 1)

                // снимаем свойство disable
                const objIndex = arrList.findIndex((data => data.id === id))
                arrList[objIndex].disable = false
            }
        }
    },
    watch: {
        'relationForm': {
            handler: function () {
                this.$emit('update:modelValue', this.relationForm)
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
