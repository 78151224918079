<template>
    <div class="root addressDeliveryModalForm">
        <div class="firm textXS textMedium neutral500" v-if="companyName">
            Для «{{ companyName }}»
        </div>

        <FormDadataAddressV2
            v-model="address"
            label="Фактический адрес"
            placeholder="г.Ярославль, ул.Ленина, д.3"
            @infoAddress="handleSelectedAddress"
            @onClear="handleClear"
        />
        <div class="map" :style="!address ? 'opacity: 0.2' : 'opacity: 1'">
            <YandexMap
                :coords="mapCenter"
                :zoom="mapZoom"
                :drag-off="false"
                @onChangeCenterPosition="handlePosition"
                @onChangeZoom="handleZoom"
                @loadingAction="loadingAction"
            />
        </div>
        <span class="text textXS textRegular neutral400">
            Переместите отметку на карте для точного определения координат
        </span>
        <div>
            <el-row :gutter="15">
                <el-col :sm="12">
                    <FormInput
                        :disabled="!latitude"
                        v-model="name"
                        label="Введите удобное для вас название адреса"
                        placeholder="Введите название"
                    />
                </el-col>
                <el-col :sm="6">
                    <FormInput
                        label="Широта"
                        v-model="latitude"
                        placeholder="Широта"
                        class="input"
                    />
                </el-col>
                <el-col :sm="6">
                    <FormInput
                        label="Долгота"
                        v-model="longitude"
                        placeholder="Долгота"
                        class="input"
                    />
                </el-col>
            </el-row>
        </div>
        <hr class="mt-15 mb-15"/>
        <FormAddButton
            :loading="loading"
            class="button"
            title="Добавить адрес"
            size="large"
            :disable="!latitude && !longitude"
            @action="handleCreate()"
        />
    </div>
</template>

<script>

import YandexMap from '@/views/widgets/map/YandexMap';
import FormDadataAddressV2 from "@/views/components/Form/FormDadataAddressV2.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormAddButton from "@/views/components/Form/FormAddButton.vue";
import { httpService } from '@/services/http.service';
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";


const INITIAL_ZOOM = 10;

export default {
    name: "AddressDeliveryModalCreate",
    components: {
        YandexMap,
        FormInput,
        FormDadataAddressV2,
        FormAddButton,
    },
    inject: ['api'],
    data() {
        return {
            mapZoom: INITIAL_ZOOM,
            loading: false,
            address: null,
            latitude: null,
            longitude: null,
            name: null
        }
    },
    props: [
      'companyId',
      'companyName',
    ],
    computed: {
        mapCenter() {
            if (!this.latitude || !this.longitude) {

                if (this.modules.length > 0) {
                    let key = this.$route.meta.moduleKey
                    let id = this.$route.params.id
                    let module = this.modules.find((m) => m.id == id)

                    if (key === 'dispatch' || key === 'crm') {
                        module = this.modules.find((m) => m.accountModuleId == id)
                    }

                    if (module) {
                        if (module.factory) {
                            return [parseFloat(module.factory.latitude), parseFloat(module.factory.longitude)];
                        }
                    }
                }

                return [55.75165053565251, 37.61758420869977];
            }

            return [parseFloat(this.latitude), parseFloat(this.longitude)];
        },
        ...mapState(useCommonStore, {
            modules: 'getModules'
        })
    },
    methods: {
        handleSelectedAddress(info) {
            if (info) {
                this.longitude = info.data.geo_lon;
                this.latitude = info.data.geo_lat;
                this.mapZoom = 15;
            }
        },
        loadingAction(loading) {
            this.loading = loading
        },
        handlePosition({ lat, lng, address }) {
            this.longitude = lng;
            this.latitude = lat;
            this.address = address;
            this.name = address;
        },
        handleZoom(value) {
            this.mapZoom = value;
        },
        handleClear() {
          this.longitude = null;
          this.latitude = null;
        },
        async handleCreate() {
            try {
                this.loading = true;
                const { data } = await httpService().post(this.api.company.createCompanyAddress, {
                    companyId: this.companyId,
                    form: {
                        address: this.address,
                        latitude: this.latitude,
                        longitude: this.longitude,
                        name: this.name
                    }
                });

                if (data.success) {
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    });

                    const output = {
                        values: data.items,
                        addressId: data.address.id,
                        address: this.address
                    };
                    this.$emit('onAdd', output);
                } else {
                    this.$message({
                        message: 'Не удалось найти координаты. Введите более точный адрес',
                        showClose: true,
                        type: 'error'
                    })
                    this.address = null
                    this.latitude = null
                    this.longitude = null
                    this.name = null
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 350px;
}
.text {
    display: inline-block;
    padding: 10px 0 16px;
}
.firm {
    margin: 0 0 5px
}
.button {
    margin: 0;
}
</style>
