<template>
    <div class="mobileTableInfo d-flex" v-bind:class="{ noBorder: noBorder }">
        <div class="mobileTableInfoTitle cutOverflow">
            {{ title }}
            <slot v-if="!title" name="title"></slot>
        </div>
        <div class="mobileTableInfoBody">
            {{body }}
            <slot v-if="!body" name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileTableInfo",
    props: [
        'title',
        'body',
        'noBorder'
    ],
}
</script>

<style scoped>

</style>
