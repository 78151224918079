<template>
    <div class="button-group">
        <div
            v-for="(option, key) in options"
            @click="setButton(option.key)"
            :key="key"
            :class="{ 'selected': selected === option.key }"
        >{{ option.label }}</div>
    </div>
</template>

<script>
export default {
    name: "ButtonGroup",
    props: [
        'options',
        'selected'
    ],
    methods: {
        setButton(key) {
            this.$emit('changeSelect', key);
        }
    }
}
</script>

<style lang="scss">
.button-group {
    display: flex;
    align-items: center;
    color: #393F4A;
    font-size: 12px;
    font-weight: 500;

    div {
        border: 1px solid #C9CCD0;
        border-left: none;
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            background-color: #f6f6f6;
        }

        &:first-child {
            border-left: 1px solid #C9CCD0;
            border-radius: 8px 0 0 8px;
        }

        &:last-child {
            border-radius: 0 8px 8px 0;
        }

        &.selected {
            background-color: #EEEEF0 !important;
        }
    }
}
</style>
