<template>
    <svg class="reportIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            :fill="!notActive ? primary : primarySecond"/>
        <path
            d="M15.7101 15.9298C15.5801 15.9298 15.4501 15.8998 15.3301 15.8198L12.2301 13.9698C11.4601 13.5098 10.8901 12.4998 10.8901 11.6098V7.50977C10.8901 7.09977 11.2301 6.75977 11.6401 6.75977C12.0501 6.75977 12.3901 7.09977 12.3901 7.50977V11.6098C12.3901 11.9698 12.6901 12.4998 13.0001 12.6798L16.1001 14.5298C16.4601 14.7398 16.5701 15.1998 16.3601 15.5598C16.2101 15.7998 15.9601 15.9298 15.7101 15.9298Z"
            :fill="!notActive ? sub : subSecond"/>
    </svg>

</template>

<script>

export default {
    name: 'ReportTimeIcon',
    props: ['notActive'],
    data() {
        return {
            primary: '#009272',
            sub: '#F4D16C',
            primarySecond: '#8a909a',
            subSecond: '#c9ccd0'
        }
    }
}
</script>

<style scoped>

</style>
