<template>
    <div class="text-container">
        {{ inputText }}
    </div>
</template>

<script>
export default {
    name: 'Truncate',
    props: {
        inputText: {
            default: 'default',
            type: String
        },
    },
    data() {
        return {
            truncatedText: "",
            isTruncated: false,
        };
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
 .text-container {
     overflow: hidden;
     position: relative;
     -webkit-line-clamp: 3;
     -webkit-box-orient: vertical;
     display: -webkit-box;
 }
</style>
