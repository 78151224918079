<template>
    <svg :height="height ?? '256'" viewBox="0 0 240 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path v-if="!nonCircle" d="M120 240C186.274 240 240 186.274 240 120C240 53.7258 186.274 0 120 0C53.7258 0 0 53.7258 0 120C0 186.274 53.7258 240 120 240Z" fill="#F7F7F7"/>
        <g filter="url(#filter0_d_2054_1362)">
            <path d="M191.667 64H48.3333C43.731 64 40 67.426 40 71.6522V232.348C40 236.574 43.731 240 48.3333 240H191.667C196.269 240 200 236.574 200 232.348V71.6522C200 67.426 196.269 64 191.667 64Z" fill="white"/>
        </g>
        <path d="M191.667 4H48.3333C43.731 4 40 6.68629 40 10V46C40 49.3137 43.731 52 48.3333 52H191.667C196.269 52 200 49.3137 200 46V10C200 6.68629 196.269 4 191.667 4Z" fill="#171A34" fill-opacity="0.8"/>
        <rect x="64" y="96" width="48" height="3.2" rx="1.6" fill="#424242"/>
        <rect x="64" y="102.4" width="80" height="3.2" rx="1.6" fill="#ACAFC6"/>
        <rect x="64" y="23.2002" width="48" height="3.2" rx="1.6" fill="white"/>
        <rect x="64" y="29.6001" width="80" height="3.2" rx="1.6" fill="#ECEEF4"/>
        <rect x="64" y="121.6" width="48" height="3.2" rx="1.6" fill="#424242"/>
        <rect x="64" y="128" width="80" height="3.2" rx="1.6" fill="#ACAFC6"/>
        <rect x="64" y="147.2" width="48" height="3.2" rx="1.6" fill="#424242"/>
        <rect x="64" y="153.6" width="80" height="3.2" rx="1.6" fill="#ACAFC6"/>
        <rect x="64" y="172.8" width="48" height="3.2" rx="1.6" fill="#424242"/>
        <rect x="64" y="179.2" width="80" height="3.2" rx="1.6" fill="#ACAFC6"/>
        <rect x="64" y="198.4" width="48" height="3.2" rx="1.6" fill="#424242"/>
        <rect x="64" y="204.8" width="80" height="3.2" rx="1.6" fill="#ACAFC6"/>
        <defs>
            <filter id="filter0_d_2054_1362" x="24" y="48" width="192" height="208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="8"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2054_1362"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2054_1362" result="shape"/>
            </filter>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'EmptyListIcon',
    props: ['height', 'nonCircle']
}
</script>

<style scoped>

</style>
