<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M12 21a9 9 0 0 0 0-19 9 9 0 0 0 0 19Z"/>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.95 15.45h.1v.1h-.1v-.1Z"/>
        <path d="M12.0001 11.36V11.15C12.0001 10.47 12.4201 10.11 12.8401 9.82001C13.2501 9.54001 13.66 9.18002 13.66 8.52002C13.66 7.60002 12.9201 6.85999 12.0001 6.85999C11.0801 6.85999 10.3401 7.60002 10.3401 8.52002" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "FaqIcon2"
}
</script>

<style scoped>

</style>
