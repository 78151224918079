<template>
    <p>Текущий остаток -
        <AnimateNumber :count="storeDetails.total"/>
        {{ storeDetails.id ? getData(storeDetails.good_id, 'good', 'unit') : 'ед.' }}
    </p>

    <el-row :gutter="15">
        <el-col :sm="8">

            <h4>Изменение количества</h4>

            <el-form label-position="top" class="mt-30">

                <FormNumber
                    v-model="form.total"
                    label="Введите количество"
                    placeholder="Н-р: 5"
                />

                <el-button
                    @click="changeStoreTotal('plus')"
                    :disabled="!form.total"
                    :icon="icons.plus"
                    class="w-100 ml-0 mb-5"
                    type="success"
                >
                    Добавить
                </el-button>

                <el-button
                    @click="changeStoreTotal('minus')"
                    :disabled="!form.total"
                    :icon="icons.minus"
                    class="w-100 ml-0"
                    type="danger"
                >
                    Списать
                </el-button>

            </el-form>

        </el-col>
        <el-col :sm="16">

            <h4>История изменений</h4>

            <el-table
                :data="displayData"
                class="w-100"
                v-loading="loading"
            >
                <el-table-column
                    prop="date"
                    label="Дата"
                >
                    <template v-slot="scope">
                        {{ getDateFromUnix(scope.row.date) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="Количество"
                >
                    <template v-slot="scope">
                            <span v-if="scope.row.action === 1" class="typeAddition">+ {{
                                    formatNumber(scope.row.total)
                                }} {{ getData(scope.row.goodId, 'good', 'unit') }}</span>
                        <span v-if="scope.row.action === 2" class="typeSubtraction">- {{
                                formatNumber(scope.row.total)
                            }} {{ getData(scope.row.goodId, 'good', 'unit') }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                :loading="loading"
                @action="setPage"
            />

        </el-col>
    </el-row>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from '@/mixins/iconsMixin'
import FormNumber from '@/views/components/Form/FormNumber'
import {httpService} from '@/services/http.service'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import AnimateNumber from "@/views/components/AnimateNumber";

export default {
    name: 'TechChangeStoreTotal',
    components: {AnimateNumber, TablePagination, FormNumber},
    props: ['details'],
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin, tableMixin],
    data() {
        return {
            loading: false,
            storeDetails: {
                id: null,
                total: null,
            },
            form: {
                action: null,
                total: null
            },
            pageSize: 5,
        }
    },
    created() {
        this.getValues()
    },
    methods: {
        getValues() {
            this.loading = true
            httpService().post(this.api.tech.getTechStoreValues, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                id: this.storeDetails.id,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
                this.storeDetails.total = data.total

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        changeStoreTotal(action) {
            this.loading = true
            this.form.action = action
            httpService().post(this.api.tech.changeTechStoreTotal, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                id: this.storeDetails.id,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.form.action = null
                this.form.total = null
                this.$emit('moduleGet')
                this.getValues()

                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'details': {
            handler: function () {
                if (this.details) this.storeDetails = this.details
            },
            deep: true,
            immediate: true
        }
    }

}
</script>

<style scoped>

</style>
