<template>
    <ModuleSettingsRow>
        <template v-slot:info>
            <h3>Ежемесячные расходы</h3>
            <p>Укажите ежемесячные расходы на бетонный завод</p>
        </template>
        <template v-slot:content>
            <el-row v-for="(item, index) in moduleEconomy"
                    :key="index" class="tableRow">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    {{ item.name }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7">
                    <el-button @click="openCreateValueDialog(item)" class="p-0 h-auto" type="text">{{ formatNumber(item.value) }} руб.</el-button>
                </el-col>
            </el-row>
            <el-row class="tableRow">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    Итого
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7" class="text-500">
                    {{ formatNumber(getSum(moduleEconomy)) }} руб.
                </el-col>
            </el-row>
        </template>
    </ModuleSettingsRow>

    <el-dialog
        v-model="createValueDialog"
        :title="item.name"
        :width="mobile ? '100%' : '40%'"
        :destroy-on-close="true"
    >

        <el-form v-loading="loading" ref="form" size="small" label-position="top">

            <FormNumber
                v-model="newValue"
                label="Введите актуальное значение"
                :placeholder="item.value"
            />

        </el-form>

        <el-button
            @click="createValue()"
            type="primary"
            class="w-100">
            Добавить значение
        </el-button>

    </el-dialog>
</template>

<script>
import {httpService} from '@/services/http.service'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormNumber from '@/views/components/Form/FormNumber'
import functionsMixin from '@/mixins/functionsMixin'
import ModuleSettingsRow from "@/views/components/SettingsPage/Modules/ModuleSettingsRow.vue";

export default {
    name: 'ModuleEconomyEdit',
    components: {ModuleSettingsRow, FormNumber},
    inject: ['api'],
    mixins: [mobileCheckMixin, functionsMixin],
    data() {
        return {
            loading: false,
            moduleEconomy: [],
            createValueDialog: false,
            item: {
                name: null,
                key: null,
                type: null,
                value: null,
                lastUpdate: null
            },
            newValue: null,
        }
    },
    created() {
        this.getEconomy()
    },
    methods: {
        getEconomy() {
            httpService().post(this.api.settings.getModuleEconomySettings, {
                moduleId: this.$route.params.id
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.moduleEconomy = data.moduleEconomy

            })
        },
        openCreateValueDialog(item) {
            this.item = item
            this.createValueDialog = true
        },
        createValue() {
            this.loading = true

            httpService().post(this.api.moduleEconomy.createValue, {
                moduleId: this.$route.params.id,
                fieldKey: this.item.key,
                value: this.newValue
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Значение добавлено',
                        showClose: true,
                        type: 'success'
                    })

                this.createValueDialog = false
                this.item = {
                    name: null,
                    key: null,
                    type: null,
                    value: null,
                    lastUpdate: null
                }
                this.newValue = null
                this.getEconomy()
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        getSum(arr) {
            let sum = 0
            arr.forEach(el => sum = sum + el.value)
            return sum
        }
    }
}
</script>

<style scoped>

</style>
