import { useCommonStore } from '@/store/common';
import router from '..';

export default function moduleIdAvailable({to, next}) {
    const commonStore = useCommonStore()

    if (commonStore.getUserModuleAccess.find(module => module.account_module_id == to.params?.id && module.module_key == to.meta.moduleKey)) {
        return next();
    }

    router.push('/dashboard');
}
