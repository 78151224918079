<template>
    <div class="chart-block">
        <VueEcharts
            :option="option"
        />
    </div>
</template>

<script>
import VueEcharts from "vue-echarts";

export default {
    name: "SummaryMixOrdersCountPie",
    components: {VueEcharts},
    props: {
        statuses: Array
    },
    computed: {
        values() {
            return this.statuses.map(status => ({ ...status, name: `${status.name} ${status.count} (${status.value} м³)` }));
        },
        option() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}'
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false
                        },
                        emphasis: {
                            label: {
                                show: false
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.values
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
.chart-block {
    width: 70px;
    height: 70px;
    margin-bottom: -15px;
    margin-top: -12.5px;

    @media screen and (max-width: 1433px) {
        width: 60px;
        height: 60px;
    }

    @media screen and (max-width: 1300px) {
        width: 48px;
        height: 48px;
    }

    @media screen and (max-width: 460px) {
        width: 42px;
        height: 42px;
    }

    @media screen and (max-width: 360px) {
        width: 30px;
        height: 30px;
    }
}
</style>
