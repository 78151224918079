<template>
    <div class="techDetailsInfoBlock">
        <span class="techDetailsInfoBlockTopText" v-if="topText">{{ topText }}</span>
        <p class="techDetailsInfoBlockMainText" v-if="mainText">{{ mainText }}</p>
        <span class="techDetailsInfoBlockBottomText" v-if="bottomText">{{ bottomText }}</span>
        <slot v-if="!bottomText" name="bottomText"></slot>
    </div>
</template>

<script>
export default {
    name: 'TechServiceDetailsInfoBlock',
    props: ['topText', 'mainText', 'bottomText']
}
</script>

<style scoped>

</style>
