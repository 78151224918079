<template>
    <Auth :title="'Заказ № ' + $route.params.groupId" :tabs="tabs">
        <MixOrderGroupDetails
            :group-id="$route.params.groupId"
        />
    </Auth>
</template>

<script>
import Auth from "@/views/layouts/Auth.vue";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import MixOrderGroupDetails from "@/views/components/MixOrderGroup/MixOrderGroupDetails.vue";

export default {
    name: "DispatchOrderGroupsDetails",
    components: {MixOrderGroupDetails, Auth},
    mixins: [dispatchTabs]
}
</script>

<style scoped>

</style>
