<template>
    <TechVehicleHeadInfo :tech="tech"/>
    <div class="techChangeStatusItems" v-if="tech">
        <el-button
            :disabled="tech.status === 'active' || loading"
            @click="changeStatus('active')"
        >
            Активно
        </el-button>
        <el-button
            :disabled="tech.status === 'repair' || loading"
            @click="changeStatus('repair')"
        >
            Ремонт
        </el-button>
        <el-button
            :disabled="tech.status === 'unused' || loading"
            @click="changeStatus('unused')"
        >
            Не используется
        </el-button>
    </div>
</template>

<script>
import {httpService} from "@/services/http.service";
import TechVehicleHeadInfo from "@/views/components/TechPage/Garage/TechVehicleHeadInfo";

export default {
    name: 'TechChangeStatus',
    components: {TechVehicleHeadInfo},
    props: ['tech'],
    inject: ['api'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        changeStatus(status) {
            this.loading = true
            httpService().post(this.api.tech.changeTechStatus, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.tech.id,
                status: status
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.$emit('closeDialog')

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
