export default {
    data() {
        return {
            addressesItems: [{
                id: 1,
                level: 1,
                name: 'Ярославль'
            }, {
                id: 2,
                level: 1,
                name: 'Ярославская область'
            }, {
                id: 3,
                level: 2,
                parentId: 2,
                name: 'Ярославский р-н',
                items: ['Прусово', 'Григорьевское', 'Пестрецово', 'Спас-Виталий', 'Сарафоново', 'Ивняки', 'Кузнечиха', 'Красный бор', 'Карабиха', 'Нагорный', 'Глебовское', 'Ярославка', 'Толбухино', 'Курба', 'Козьмодемьянск', 'Мордвиново', 'Ширинье', 'Михайловский', 'Мокеевское', 'Туношна']
            }]
        }
    },
}
