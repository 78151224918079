<template>
    <div class="bar-echarts">
        <VueEcharts
            :option="bar"
        />
    </div>
</template>

<script type="text/babel">
import VueEcharts from "vue-echarts";

const setUnit = (value, unit) => {
    let _value = parseFloat(value).toFixed(2)
    _value = _value.replace('.', ',')
    if (unit) {
        return `${value + (_value === 'м3.' ? ' м<sup>3</sup>' : unit)}`
    } else {
        return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value)
    }
}

const getBar = (dataset, unit) => {
    return {
        dataset: dataset,
        tooltip: {
            trigger: 'axis',
                borderWidth: 0,
                padding: [0, 10],
                axisPointer: {
                type: "shadow"
            },
            formatter: function (params) {
                const colorSpan = (color) => `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color: ${color}"></span>`;
                let finalContent = '';

                params.forEach(item => {
                    let content = `<div><p style="margin: 10px 0">${item.data.day}</p><p style="white-space: nowrap;margin: 10px 0">${colorSpan(item.color)}<b style="margin-left: 3px">${setUnit(item.data.efficient, unit)}</b></p></div>`
                    finalContent += content;
                });

                return finalContent;
            }
        },
        xAxis: {
            show: false,
                type: "category",
        },
        yAxis: {
            show: false,
        },
        grid: {
            top: 0,
                bottom: 0,
                left: 0,
                right: 0
        },
        series: [
            {
                name: 'placeholder',
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: '#c8ccd0'
                    }
                },
                silent: true,
                barWidth: 6,
                barGap: '-100%',
                barMinHeight: 2,
                tooltip: {
                    show: false
                }
            },
            {
                name: 'chart',
                type: "bar",
                barCategoryGap: "4px",
                barWidth: "6px",
                itemStyle: {
                    borderRadius: 5,
                    color: (val) => {
                        return val.data.fall ? "#F74539" : "#00D0A3"
                    },
                },
            }
        ],
    }
}

export default {
    name: 'ChartBar',
    components: {
        VueEcharts,
    },
    props: [
        'data',
        'unit'
    ],
    data: () => ({
        bar: {}
    }),
    watch: {
        'data': {
            handler: function () {
                if (this.data) {
                    this.bar = getBar(this.data, this.unit);
                }
            },
            deep: false,
            immediate: true
        }
    },
};
</script>

<style scoped>
.bar-echarts {
    width: 84px;
    height: 70px;
}
</style>
