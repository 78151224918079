<template>
    <h3 class="p-0">{{ title }}</h3>
</template>

<script>
export default {
    name: "PageH1",
    props: [
        'title'
    ]
}
</script>

<style scoped>

</style>
