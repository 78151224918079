export default {
    data() {
        return {
            tabs: [{
                route: 'LabOrders',
                name: 'Заказы'
            }, {
                route: 'LabGoods',
                name: 'Спецификации'
            }],
        }
    }
}
