export default {
    data() {
        return {
            vehicleTypes: [
                {
                    id: 'mix',
                    name: 'Миксер'
                },
                {
                    id: 'pump',
                    name: 'Насос'
                },
                {
                    id: 'semi',
                    name: 'Тягач'
                },
                {
                    id: 'truck',
                    name: 'Самосвал'
                },
                {
                    id: 'excavator',
                    name: 'Экскаватор'
                },
                {
                    id: 'loader',
                    name: 'Погрузчик'
                },
                {
                    id: 'crane',
                    name: 'Кран'
                },
                {
                    id: 'car',
                    name: 'Легковой автомобиль'
                }
            ],
        }
    },
}
