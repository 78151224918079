<template>
    <el-button class="reset-button"
               v-if="isVisible"
               :icon="icons.refreshLeft"
               size="large"
               @click="$emit('resetFilter')" />
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "ResetFormButton",
    props: ['isVisible'],
    mixins: [
        iconsMixin,
    ],
};
</script>

<style lang="scss" scoped>
    @import "@/sass/_variables.scss";
    .reset-button {

        height: 46px !important;
        border-radius: 8px;

        &:hover {
            &:deep(.el-icon) {
                transition: 0.3s transform;
                cursor: pointer;
                transform: rotate(-90deg);
            }
        }

        &:deep(.el-icon) {
            width: 20px;
            height: 20px;

            & svg {
                width: 20px;
                height: 20px;
            }
        }
    }
</style>
