<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Редактирование операции"/>

            <el-form ref="form" :model="form" size="small" label-position="top">

                <FormInput
                    v-model="form.name"
                    label="Наименование"
                    placeholder="Название операции"
                />

                <FormSelect
                    v-model="form.income"
                    label="Тип операции"
                    placeholder="Выберите тип"
                    :items="types"
                    :no-clearable="true"
                />

                <FormNumber
                    v-model="form.value"
                    label="Сумма"
                    placeholder="Например, 12500"
                />

                <FormSelect
                    v-model="form.accountId"
                    label="Счет"
                    placeholder="Выберите счет"
                    :items="accounts"
                />

                <FormSelect
                    v-model="form.typeId"
                    label="Категория"
                    placeholder="Введите название"
                    :items="getOperationTypes()"
                    :allow-create="true"
                />

                <FormSelect
                    v-model="form.companyId"
                    :items="companies"
                    :label="(operation.income ? 'Отправитель' : 'Получатель') + ' (контрагент)'"
                    property="name"
                    new-item="company"
                    :new-item-data="form.companyId"
                    placeholder="Введите название"
                />

                <template
                    v-if="form?.companyId && companyCheck && companyCheck !== 'noCompany'">
                    <div class="pb-30">
                        <DrawerTextLineItem
                        name="ИНН"
                        :desc="company.inn ?? 'Не указан'"
                        />

                        <DrawerTextLineItem
                        name="КПП"
                        :desc="company.kpp ?? 'Не указан'"
                        />

                        <DrawerTextLineItem
                        name="E-mail"
                        :desc="company.email ?? 'Не указан'"
                        />

                        <DrawerTextLineItem
                        name="Телефон"
                        :desc="company.phone ?? 'Не указан'"
                        />
                    </div>
                </template>

                <FormTextarea
                    v-model="form.comment"
                    label="Комментарий"
                    placeholder="Дополнительное описание"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Сохранить изменения" @action="doEditOperation"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import FormInput from '@/views/components/Form/FormInput'
import FormNumber from '@/views/components/Form/FormNumber'
import FormSelect from '@/views/components/Form/FormSelect'
import FormTextarea from '@/views/components/Form/FormTextarea'
import {httpService} from '@/services/http.service'
import {mapState} from "pinia/dist/pinia";
import {useCommonStore} from "@/store/common";
import DrawerTextLineItem from "@/views/components/Drawer/DrawerTextLineItem";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    components: {
        FormTextarea,
        FormSelect,
        FormNumber,
        FormInput,
        DrawerCreateButton,
        DrawerHeadTitle,
        DrawerFramework,
        DrawerTextLineItem
    },
    props: ['operation', 'operationTypes', 'accounts'],
    mixins: [functionsMixin],
    name: 'CashboxEdit',
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        })
    },
    data() {
        return {
            form: {
                id: this.operation.id,
                name: this.operation.name,
                value: this.operation.value,
                comment: this.operation.comment,
                typeId: this.operation.typeId,
                companyId: this.operation.companyId,
                accountId: this.operation.accountId,
                income: this.operation.income,
            },
            company: {
                kpp: null,
                inn: null,
                email: null,
                phone: null,
            },
            loading: false,
            companyCheck: null,
            types: [{
                id: true,
                name: 'Доход'
            }, {
                id: false,
                name: 'Расход'
            }]
        }
    },
    inject: [
        'api'
    ],
    methods: {
        doEditOperation() {
            this.loading = true
            httpService().post(this.api.cashboxOperation.update, {
                operation: this.form,
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.drawer = false
                    this.$message({
                        message: 'Операция изменена',
                        showClose: true,
                        type: 'success'
                    })
                    if (data.accountUpdate) {

                        window.setTimeout(() => {
                            this.$message({
                                message: 'Остаток счета обновлен',
                                type: 'success',
                                showClose: true,
                            })
                        }, 450)

                    }
                    this.form = {
                        id: this.operation.id,
                        name: this.operation.name,
                        value: this.operation.value,
                        comment: this.operation.comment,
                        typeId: this.operation.typeId,
                        companyId: this.operation.companyId,
                        accountId: this.operation.accountId,
                        income: this.operation.income
                    }
                    this.loading = false
                    this.$emit('moduleGet')
                    this.$emit('closeEditDrawer')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getOperationTypes() {
            return this.operationTypes.filter(el => el.income === this.form.income)
        }
    },
        watch: {
        "form.companyId": {
            handler: function () {
                // ищем компанию по названию, которое ввел пользователь
                // если нашли, то выводим данные
                // если нет, то показываем инпуты

                if (this.form.companyId) {
                    let object = this.companies.find(
                        (el) => el.id === this.form.companyId
                    );

                    if(object) {
                        this.getData(object.id, "company", "inn").then(
                            (response) => {
                                this.company.inn = response.inn;
                            }
                        );

                        this.getData(object.id, "company", "kpp").then(
                            (response) => {
                                this.company.kpp = response.kpp;
                            }
                        );

                        this.getData(object.id, "company", "email").then(
                            (response) => {
                                this.company.email = response.email;
                            }
                        );

                        this.getData(object.id, "company", "phone").then(
                            (response) => {
                                this.company.phone = response.phone;
                            }
                        );
                    }
                    setTimeout(() => {
                        if (object) {
                            this.companyCheck = object.id;

                        } else {
                            this.companyCheck = "noCompany";
                        }
                    }, 500);
                } else {
                    this.companyCheck = null;
                }
            },
            deep: false,
            immediate: true,
        },
    },
}
</script>

<style scoped>

</style>
