export default {
    data() {
        return {
            fuelTypes: [{
                id: 1,
                name: 'Бензин 80'
            }, {
                id: 2,
                name: 'Бензин 92'
            }, {
                id: 3,
                name: 'Бензин 95'
            }, {
                id: 4,
                name: 'Бензин 100'
            }, {
                id: 5,
                name: 'ДТ'
            }, {
                id: 6,
                name: 'Пропан'
            }, {
                id: 7,
                name: 'Метан'
            }, {
                id: 8,
                name: 'Электричество'
            }],
        }
    },
}
