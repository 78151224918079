export default {
    data() {
        return {
            tabs: [{
                route: 'techVehicles',
                name: 'Автопарк'
            }, {
                route: 'techService',
                name: 'Сервис'
            }, {
                route: 'techTires',
                name: 'Шины'
            }, {
                route: 'techGoods',
                name: 'Склад запчастей'
            }]
        }
    }
}
