<template>
    <h3>Добавление нового модуля</h3>
    <el-form ref="form" :model="moduleForm" size="small" label-position="top">
        <el-form-item label="Название для модуля и ссылки в меню">
            <el-input v-model="moduleForm.name" type="text" placeholder="Н-р: Денежный учет"></el-input>
        </el-form-item>
        <el-form-item label="Тип модуля">
            <el-select v-model="moduleForm.id" placeholder="Выберите тип модуля">
                <el-option v-for="(module, index) in modules" :key="index" :label="module.name"
                           :value="module.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <p>Пользователи, которым доступен модуль:</p>
            <div v-for="user in users" :key="user.id">
                <input type="checkbox" v-model="moduleForm.users" :value="user.id"/>
                <label>{{ user.name }}</label>
            </div>
        </el-form-item>
    </el-form>
    <div class="footer">
        <el-button :loading="loadingButton" type="primary" icon="el-icon-plus" class="w-100"
                   @click.prevent="doCreateModule">Создать модуль
        </el-button>
    </div>
</template>

<script>
import { httpService } from '@/services/http.service'

export default {
    name: 'ModuleCreate',
    data() {
        return {
            loading: true,
            loadingButton: true,
            modules: [],
            users: [],
            moduleForm: {
                name: '',
                id: '',
                users: [],
            },
        }
    },
    inject: [
        'api'
    ],
    created() {
        httpService().post(this.api.module.get).then((response) => {
            let data = response.data

            if (data.success)
                this.modules = data.modules

        })

        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users
            this.loading = false
            this.loadingButton = false

        })
    },
    methods: {
        doCreateModule() {
            httpService().post(this.api.module.create, {
                module: this.moduleForm,
            }).then((response) => {
                let data = response.data
                this.$message({
                    message: data.message,
                    showClose: true,
                    type: 'success'
                })
                this.$emit('moduleGet')
                this.moduleForm = {
                    name: '',
                    id: '',
                }
            }).finally(() => {
                this.$emit('reloadNav')
            })
        }
    }
}
</script>

<style scoped>

</style>
