<template>
    <div style="width: 140px;">
        <el-tooltip class="w-fit ml-auto" :content="tooltip" placement="top" :disabled="!tooltip">
            <div v-if="order.doc" class="d-flex-end f-direction-column gap-4">
            В АСУ - #{{ order.doc }}
            <div >
                <template v-if="order.status === 'new'">
                    <el-button
                        class="operatorOrderButton"
                        type="primary"
                        :icon="icons.videoPlay"
                        @click="this.$emit('openMixOrderAction', order)"
                    />
                </template>
                <template v-else-if="order.status === 'loading'">
                    <div class="d-flex-end">
                        <SmallLoader class="mr-5"/>
                        <span class="group-orders-status-text group-orders-status-text-unconfirmed">
                        Загрузка
                    </span>
                    </div>
                </template>
                <template v-else>
                    <span class="group-orders-status-text group-orders-status-text-done">
                        Выполнена
                    </span>
                </template>
            </div>
        </div>
        <div v-else class="d-flex-end f-direction-column gap-4 w-fit ml-auto">
            <template v-if="order.confirm">Не отправлена в АСУ</template>
            <div style="width: 44px">
                <el-button class="operatorOrderButton" disabled
                           type="primary"
                           :icon=icons.videoPlay
                />
            </div>
        </div>
    </el-tooltip>
    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import SmallLoader from "@/views/components/Loaders/SmallLoader.vue";

export default {
    name: "OperatorElcon",
    props: ['order'],
    components: {SmallLoader},
    mixins: [iconsMixin],
    data() {
        return {}
    },
    created() {
    },
    computed: {
        tooltip() {
            let content = ''
            if(!this.order.confirm
            || this.order.status === 'done'
            || this.order.status === 'delivery'
            || this.order.doc && this.order.status === 'new') {
                return content
            }
            if(this.order.status === 'loading') {
                content = 'Ожидание завершения от АСУ'
            }
            else if(!this.order.doc) {
                content = 'Идет отправка данных'
            } else if(this.order.doc) {
                content = 'Ожидание начала загрузки'
            }
        return content
        }
    },
}
</script>

<style scoped>

</style>
