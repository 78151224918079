<template>
    <el-form v-model="form" ref="form" size="small" label-position="top">

        <template v-if="step === 1">
            <FormInputSuggestions
                v-if="!form.no_number"
                v-model="form.number"
                label="Гос номер ТС"
                placeholder="Введите номер"
                :items="vehicles"
                property="number"
                value-key="number"
            />

            <el-form-item label="" v-if="!form.number">
                <el-checkbox v-model="form.no_number" label="У техники нет номера"/>
            </el-form-item>

            <FormSelect
                v-model="form.vehicle_brand_id"
                label="Бренд"
                placeholder="Выберите бренд"
                :items="brands"
                :required="true"
            />

            <FormInput
                v-model="form.name"
                label="Дополнительное название"
                placeholder="Н-р: 320"
                :required="true"
            />

            <FormSelect
                v-model="form.type"
                label="Тип"
                placeholder="Выберите тип"
                :items="vehicleTypes"
            />
        </template>
        <template v-else-if="step === 2">
            <FormNumber
                v-model="form.mileage"
                label="Текущий пробег в км."
                placeholder="0"
            />

            <FormNumber
                v-model="form.engine_hours"
                label="Текущий пробег в мтч."
                placeholder="0"
            />

            <FormNumber
                v-model="form.fuel_tank"
                label="Объем топливного бака (л.)"
                placeholder="Н-р: 250"
            />
        </template>

        <el-button type="primary" v-if="step === 1" :disabled="!this.form.number && !this.form.no_number" size="large"
                   class="w-100" @click="nextStep()">Следующий шаг
        </el-button>
        <div class="d-flex-full-stroke" v-else>
            <el-button type="info" plain size="large" :icon="icons.arrowLeft" @click="previousStep()"/>
            <el-button type="primary" v-if="step !== 2" size="large" class="w-100" @click="nextStep()">Следующий шаг
            </el-button>
            <el-button :loading="loading" type="primary" v-else size="large" class="w-100" @click="createNewTech">Добавить технику
            </el-button>
        </div>
    </el-form>


</template>

<script>
import FormInput from '@/views/components/Form/FormInput'
import {httpService} from '@/services/http.service'
import FormInputSuggestions from '@/views/components/Form/FormInputSuggestions'
import FormNumber from "@/views/components/Form/FormNumber";
import iconsMixin from "@/mixins/iconsMixin";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import vehicleTypes from "@/mixins/vehicleTypes";

export default {
    name: 'TechCreateVehicle',
    components: {FormSelect, FormNumber, FormInputSuggestions, FormInput},
    mixins: [iconsMixin, vehicleTypes],
    data() {
        return {
            loading: false,
            step: 1,
            form: {
                number: null,
                no_number: false,
                name: null,
                vehicle_brand_id: null,
                mileage: null,
                mileage_start: null,
                fuel_total: null,
                metric: null,
                vin: null,
                ctc: null,
                status: null,
                fuel_tank: null,
                type: null
            },
            vehicles: [],
            brands: []
        }
    },
    inject: [
        'api'
    ],
    created() {
        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
        })
        httpService().post(this.api.settings.getBrands).then((response) => {
            let data = response.data

            if (data.success)
                this.brands = data.brands

        })
    },
    methods: {
        changeUnit(metric) {
            this.form.metric = metric
        },
        checkVehicleByNumber() {
            let vehicle = this.vehicles.find(el => el.number === this.form.number)

            return !!vehicle
        },
        nextStep() {
            this.step++
        },
        previousStep() {
            if (this.step !== 1) this.step--
        },
        createNewTech() {
            this.loading = true
            httpService().post(this.api.techVehicle.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                module_id: this.$route.params.id,
                vehicle: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.form = {
                        number: null,
                        name: null,
                        model: null,
                        mileage: null,
                        mileage_start: null,
                        fuel_total: null,
                        metric: null,
                        vin: null,
                        ctc: null,
                        status: null,
                        fuel_tank: null
                    }
                this.$message({
                    message: 'Техника добавлена',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        beforeAvatarUpload(file) {

            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('This picture must be a JPG!');
            }
            if (!isLt2M) {
                this.$message.error('This image is bigger than 2MB!');
            }


            let formData = new FormData();
            formData.append('image', file);

            httpService().post(this.api.techVehicle.uploadImage, formData).then((response) => {
                let data = response.data

                if (data.success)
                    this.form.imageUrl = data.imageUrl
                this.$message({
                    message: 'Изображение загружено',
                    showClose: true,
                    type: 'success'
                })

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
            return isLt2M && isJPG;
        }
    },
    watch: {
        'form.number': {
            handler: function () {
                if (this.step === 1 && !this.form.no_number) {
                    let vehicle = this.vehicles.find(el => el.number === this.form.number)
                    if (vehicle) this.form = vehicle
                }
            },
            deep: true,
            immediate: false
        }
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
