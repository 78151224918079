<template>
    <div class="cashboxStatusBlock">
        <span>Показывать сводку за <span class="cashboxStatusBlockChange">
            <span style="padding-right: 0" @click="toggleShowChangeList">{{ getPeriodName() }}</span>
            <div class="cashboxStatusBlockList"
                 v-show="showChangeList">
            <el-button
                type="text"
                v-for="(item, index) in periodNames"
                @click="changePeriod(item.key)"
                :key="index">
                {{ item.name }}
            </el-button>
        </div>
        </span>
        </span>
        <el-button class="cashboxStatusBlockReload" :loading="loading" @click="$emit('moduleGet')" :icon="icons.refresh"
                   circle></el-button>


    </div>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'

export default {
    name: 'CashboxInfoStatusBlock',
    props: ['loading', 'modelValue'],
    mixins: [iconsMixin],
    data () {
        return {
            periodNames: [{
                key: 'today',
                name: 'день'
            }, {
                key: 'week',
                name: 'неделю',
            }, {
                key: 'month',
                name: 'месяц'
            }],
            showChangeList: false,
        }
    },
    methods: {
        toggleShowChangeList() {
            this.showChangeList = !this.showChangeList;
        },
        getPeriodName () {
            return this.periodNames.find(el => el.key === this.modelValue).name
        },
        changePeriod (period) {
            this.showChangeList = false
            this.$emit('update:modelValue', period)
        }
    }
}
</script>

<style scoped>

</style>
