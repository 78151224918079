<template>
    <span
        class="techChangeStatus"
        @click="$emit('openChangeStatus')"
        v-if="status === 'active'">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#00d0a3"/>
        </svg>
        <span class="techChangeStatusText">Активно</span>
    </span>

    <span
        class="techChangeStatus"
        @click="$emit('openChangeStatus')"
        v-if="status === 'repair'">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#f74539"/>
        </svg>
        <span class="techChangeStatusText">Ремонт</span>
    </span>

    <span
        class="techChangeStatus"
        @click="$emit('openChangeStatus')"
        v-if="status === 'unused'">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#8a909a"/>
        </svg>
        <span class="techChangeStatusText">Не используется</span>
    </span>
</template>

<script>
export default {
    name: 'TechStatusButton',
    props: ['status']
}
</script>

<style scoped>

</style>
