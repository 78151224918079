export default {
    methods: {
        declension(number, word) {
            if (Number.isInteger(number) && number >= 0) {
                if (number === 0) {
                    if (word === 'отгрузка') {
                        return `отгрузок`;
                    } else {
                        return `${word}`;
                    }
                } else {
                    let remainder = number % 100;
                    let lastDigit = remainder % 10;

                    if (remainder >= 11 && remainder <= 14) {
                        return `${word}`;
                    } else {
                        switch (lastDigit) {
                            case 1:
                                if (word === 'доставка') {
                                    return `доставка`;
                                } else {
                                    return `${word}`;
                                }
                            case 2:
                            case 3:
                            case 4:
                                if (word === 'доставка') {
                                    return `доставки`;
                                } else if (word === 'отгрузка') {
                                    return `отгрузки`;
                                } else {
                                    return `${word}а`;
                                }
                            default:
                                if (word === 'доставка') {
                                    return `доставок`;
                                } else if (word === 'отгрузка') {
                                    return `отгрузок`;
                                }  else {
                                    return `${word}ов`;
                                }
                        }
                    }
                }
            } else {
                return 'Ошибка: Недопустимое число';
            }
        },
    }
}
