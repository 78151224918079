<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        disable-create-button="true"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
    />

    <Filter
        :filter="filter"
        :filter-items1="objects"
        :loading="loading"
        :filter-show="filterShow"
    />

    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
        <template v-slot:body>

            <MobileTableDate
                :date="item.date"
            />

            <MobileTableInfo
                title="Объект"
                :body="item.object"
            />

            <MobileTableInfo
                title="Метод"
                :body="item.method"
            />

            <MobileTableInfo
                title="Массив"
                :no-border="true"
                :body="item.data"
            />

            <MobileTableButton
                @action="openDrawer(item)"
            />

        </template>
    </MobileTableFramework>

    <el-table
        v-loading="loading"
        :data="displayData"
        v-if="!mobile"
        ref="table"
        empty-text="Нет информации"
        class="mt-15 w-100">
        <el-table-column
            prop="date"
            label="Дата"
            width="160px"
        >
            <template v-slot="scope">
                {{ getDateFromUnix(scope.row.date) }}
            </template>
        </el-table-column>
        <el-table-column
            prop="data"
            label="Массив"
            width="430"
        />
        <el-table-column
            prop="method"
            label="Метод"
            width="150"
        />
        <el-table-column
            prop="object"
            label="Объект"
        />
        <el-table-column
            fixed="right"
            label=""
            width="130">
            <template v-slot="scope">
                <el-button
                    size="mini"
                    @click="openDrawer(scope.row)">Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <el-drawer
        title=""
        v-model="dataDetailsDrawer"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <TtsDataDetails :data="dataDetails"/>

    </el-drawer>
</template>

<script>
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import TablePagination from '@/views/components/Table/TablePagination'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import TtsDataDetails from '@/views/components/TTS/TtsDataDetails'
import { httpService } from '@/services/http.service'
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableDate from "@/views/components/Mobile/Table/MobileTableDate";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";
import Filter from "@/views/components/Table/Filter/Filter";

export default {
    name: 'TtsData',
    components: {
        Filter,
        MobileTableButton,
        MobileTableInfo, MobileTableDate, MobileTableFramework, TtsDataDetails, TablePagination, TableHeadBlock },
    mixins: [tableMixin, functionsMixin, mobileCheckMixin],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()

        httpService().post(this.api.tts.objects, {
            account_module_reserve_id: this.$route.params.id,
            module_key: this.$route.meta.moduleKey,
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.loading = false
            this.objects = data.values
        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
        })
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    data() {
        return {
            loading: true,
            dataDetailsDrawer: false,
            dataDetails: null,
            objects: [],
            filter: {
                object: {
                    value: null,
                    type: 'select',
                    label: 'Объект',
                    placeholder: 'Выберите объект',
                    items: '1'
                },
            },
        }
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.tts.data, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openDrawer(row) {
            this.dataDetails = row
            this.dataDetailsDrawer = true
        },
        toogleExpand(row) {
            let $table = this.$refs.table
            $table.toggleRowExpansion(row)
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.object.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.object.value) this.filtered = this.filtered.filter(data => data.objectID === this.filter.object.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
