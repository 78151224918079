import { useCommonStore } from '@/store/common';
import router from '..';

export default function analyticsAvailable({next}) {
    const commonStore = useCommonStore()

    if (commonStore.getAnalyticsAvailable) {
        return next();
    }

    router.push('/dashboard');
}
