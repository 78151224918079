<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :show-date-filter="true"
                    :disable-search="true"
                    create-button-label="Добавить заказ"
                    :current-date="filter.date.value"
                    @moduleGet="getAll()"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateDialog()"
                    @changeDate="changeDate"
                    :new="true"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <div v-if="ordersFailed.length > 0" class="productionOrdersFailed">
                    <div class="d-flex">
                        <div>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.5264 18.5L12.8661 3.5C12.4812 2.83333 11.519 2.83333 11.1341 3.5L2.47385 18.5C2.08895 19.1667 2.57007 20 3.33987 20H20.6604C21.4302 20 21.9113 19.1667 21.5264 18.5Z"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.9502 16H12.0502V16.1H11.9502V16Z" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M12 9V13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div>
                            <p class="neutral700 textSM textBold mt-0 mb-5">Незавершенные заказы:</p>
                            <div class="neutral500 textSM textRegular" v-for="(order, index) in ordersFailed"
                                 :key="index">
                                - {{ getData(order.good.good_id, 'good') }} ({{ order.done }}/{{ order.total }}) от
                                {{ getDateFormDB(order.date, 'shortDate') }}
                            </div>
                        </div>
                    </div>
                </div>

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="заказов"
                />

                <div class="productionOrders">
                    <div class="productionOrder" v-for="(orderByGood, index) in values" :key="index">
                        <div class="productionOrderHead">
                            <div class="neutral800 textMD textMedium">
                                {{ getData(orderByGood.goodId, 'good') }} {{ orderByGood.done }} / {{
                                    orderByGood.total
                                }} {{ getData(orderByGood.goodId, 'good', 'unit') }}
                            </div>
                        </div>
                        <div class="productionOrderBody">
                            <template v-if="!mobile">
                                <el-table :data="orderByGood.orders" style="width: 100%">
                                    <el-table-column prop="doc" label="Заказ" width="150">
                                        <template v-slot="scope">
                                            <div class="cutOverflow">
                                                №{{ scope.row.doc }}
                                                <template v-if="scope.row.companyId">для {{
                                                        getData(scope.row.companyId, 'company')
                                                    }}
                                                </template>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="done" label="" width="100">
                                        <template v-slot="scope">
                                            <div class="text-center">{{ scope.row.done }} / {{ scope.row.total }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-for="(step, index) in orderByGood.steps" :key="index">
                                        <template #header>
                                            {{ step.name }}
                                        </template>
                                        <template v-slot="scope">
                                            <div class="productionOrderSlot"
                                                 v-if="scope.row.steps.find(el => el.id === step.id).total">
                                                <div
                                                    :class="'productionOrderSlotCount ' + 'productionOrderSlotCount' + getBGcolor(step.id)">
                                                    <span>{{
                                                            scope.row.steps.find(el => el.id === step.id).total
                                                        }}</span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="" width="70">
                                        <template v-slot="scope">
                                            <el-button :loading="loading" @click="openEditDialog(scope.row)"
                                                       class="orderGroupItemDelete" :icon="icons.edit"
                                                       size="small"></el-button>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="" width="70">
                                        <template v-slot="scope">
                                            <el-button :loading="loading" @click="remove(scope.row.id)"
                                                       class="orderGroupItemDelete"
                                                       :icon="icons.delete" size="small"></el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                            <template v-else>
                                <div style="padding: 15px 20px">

                                    <div v-for="(order, index) in orderByGood.orders" :key="index">
                                        <div class="w-100 mb-15">
                                            <div class="cutOverflow">
                                                №{{ order.doc }}
                                                <template v-if="order.companyId">для {{
                                                        getData(order.companyId, 'company')
                                                    }}
                                                </template>
                                                {{ order.done }} / {{ order.total }}
                                                {{ getData(order.goodId, 'good', 'unit') }}
                                            </div>
                                        </div>
                                        <div v-for="(step, index) in orderByGood.steps" :key="index">
                                            <div class="d-flex-full mb-5">
                                                <div class="cutOverflow neutral400">
                                                    {{ step.name }}
                                                </div>
                                                <div
                                                    class="textMedium neutral900"
                                                    v-if="order.steps.find(el => el.id === step.id).total">
                                                    {{ order.steps.find(el => el.id === step.id).total }}
                                                </div>
                                                <div class="neutral400" v-else>
                                                    -
                                                </div>
                                            </div>

                                        </div>
                                        <hr/>

                                        <div>
                                            <el-button :loading="loading" @click="openEditDialog(order)"
                                                       class="w-100 mb-10" size="small">Редактировать
                                            </el-button>
                                        </div>
                                        <div>
                                            <el-button :loading="loading" @click="remove(order.id)" class="w-100"
                                                       size="small">Удалить
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="orderCreateDialog"
            title="Новый заказ"
            :width="mobile ? '100%' : '65%'"
            :destroy-on-close="true"
        >
            <el-form label-position="top">

                <FormSelect
                    label="Продукция"
                    placeholder="Выберите продукцию"
                    v-model="form.productionGoodId"
                    :items="productionGoods.sort((a, b) => a.name.localeCompare(b.name))"
                    :required="true"
                />

                <FormNumber
                    label="Количество (партий продукции)"
                    placeholder="8"
                    v-model="form.total"
                    :required="true"
                />

                <FormDateTimePicker
                    label="Дата выполнения заказа"
                    placeholder="Выберите дату"
                    v-model="form.date"
                    :required="true"
                />

                <FormSelect
                    label="Смена"
                    placeholder="Выберите смену"
                    v-model="form.shiftId"
                    :items="shifts"
                />

                <FormSelect
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    v-model="form.companyId"
                    :items="companies"
                    new-item="company"
                    :new-item-data="form.companyId"
                />

                <el-button type="primary" @click="create()" class="w-100"
                           :disabled="!form.productionGoodId || !form.total || !form.date">Создать заказ
                </el-button>

            </el-form>
        </el-dialog>

        <el-dialog
            v-model="orderEditDialog"
            title="Редактировать заказ"
            :width="mobile ? '100%' : '45%'"
            :destroy-on-close="true"
        >
            <p class="mt-0 textRegular textSM">Вы можете изменить количество задач в каждом этапе:</p>
            <el-row :gutter="15" class="mb-15" v-for="(step, index) in selectedOrder.steps" :key="index">
                <el-col :sm="4">
                    <div :class="'textMedium textMD neutral800 ' + (mobile ? 'mb-10' : '')"
                         style="position: relative; top: 4px">{{ step.name }}
                    </div>
                </el-col>
                <el-col :sm="12">
                    <el-input-number class="w-100" v-model="step.changeTotal" :min="0" :max="step.total"/>
                </el-col>
            </el-row>

            <el-button @click="editOrder()" :class="'mt-30 ' + (mobile ? 'w-100' : '')" type="primary">Сохранить
                изменения
            </el-button>
        </el-dialog>
    </Auth>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormSelect from '@/views/components/Form/FormSelect.vue'
import FormNumber from '@/views/components/Form/FormNumber.vue'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker.vue'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import Filter from "@/views/components/Table/Filter/Filter.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionOrdersIndex',
    components: {Auth, EmptyState, Filter, FormDateTimePicker, FormNumber, FormSelect, TableHeadBlock},
    mixins: [Filter, tableMixin, mobileCheckMixin, iconsMixin, functionsMixin, productionTabs],
    inject: ['api'],
    data() {
        return {
            loading: false,
            orderCreateDialog: false,
            productionGoods: [],
            orderEditDialog: false,
            filter: {
                date: {
                    value: null,
                    type: 'date'
                }
            },
            selectedOrder: {
                id: null,
                steps: []
            },
            form: {
                productionGoodId: null,
                companyId: null,
                date: null,
                total: null,
                shiftId: null
            },
            shifts: [],
            ordersFailed: []
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        })
    },
    created() {
        this.getProductionGoodsAll()
        this.getShifts()
        this.getAll()
    },
    methods: {
        getShifts() {
            this.loading = true

            httpService().post(this.api.productionShift.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.shifts = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getProductionGoodsAll() {
            this.loading = true

            httpService().post(this.api.productionGood.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.productionGoods = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        editOrder() {

            httpService().post(this.api.productionOrder.edit, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                order: this.selectedOrder
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.orderEditDialog = false
                    this.getAll()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openEditDialog(order) {
            order.steps.forEach(el => el.changeTotal = el.total)
            this.selectedOrder = order
            this.orderEditDialog = true
        },
        remove(id) {
            this.$confirm(
                'Вы действительно хотите удалить данный заказ?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionOrder.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        id: id
                    }).then(() => {
                        this.$message({
                            message: 'Заказ удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getAll() {
            this.loading = true

            httpService().post(this.api.productionOrder.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                date: this.filter.date.value
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.ordersFailed = data.ordersFailed
                    this.form.shiftId = data.shiftId
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getBGcolor(stepId) {
            if (stepId === 0) return 'new'
            else if (stepId === 999999) return 'done'
            else return 'work'
        },
        openCreateDialog() {
            this.getProductionGoodsAll()
            this.getShifts()
            this.form.date = this.filter.date.value
            this.orderCreateDialog = true
        },
        create() {
            this.loading = true

            httpService().post(this.api.productionOrder.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Заказ добавлен',
                        showClose: true,
                        type: 'success'
                    })

                this.getAll()
                this.orderCreateDialog = false
                this.form = {
                    productionGoodId: null,
                    companyId: null,
                    total: null
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        changeDate(value) {
            this.filter.date.value = value
            this.form.date = value
        },
        filterValues() {
            this.filtered = this.values
            this.valueLength = this.filtered.length
            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'filter': {
            handler: function () {
                if (this.filter.date.value) {
                    this.form.date = this.filter.date.value
                    this.getAll()
                }
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
