<template>
    <el-tooltip placement="bottom" effect="light" :disabled="!orders.length" :show-after="100">
        <template #content>
            <div style="width: 350px">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th>
                            <div class="cell textXS textRegular neutral200 pb-15 pl-5">
                                Отгрузки
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Кол-во
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15 pt-5">
                                Объем
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(status, index) in statuses" :key="index" class="hover-row">
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 no-wrap pl-5">
                                <span :style="{ 'background-color': status.color }" class="status-dot"></span>
                                {{ status.name }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5">
                                {{ status.count }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium success400 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(status.value, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                Итого
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ statuses.reduce((acc, status) => acc + status.count, 0) }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(allTotal, 2) }} м³
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="mt-20 w-100" v-if="unallocatedTotal > 0">
                    <h3 class="neutral900 textMedium textSM mb-10">Нераспределённый объем</h3>
                    <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                        <thead>
                        <tr>
                            <th>
                                <div class="cell textXS textRegular neutral200 pt-2-5 pb-2-5 pl-5">
                                    Заказ
                                </div>
                            </th>
                            <th style="width: 120px">
                                <div class="cell textXS textRegular neutral200 pt-2-5 pb-2-5 pr-5">
                                    Объем
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(status, index) in unallocatedOrders" class="hover-row" :key="index">
                            <td>
                                <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 no-wrap pl-5">
                                    {{ status.name }}
                                </div>
                            </td>
                            <td>
                                <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 pr-5">
                                    {{ formatNumber(status.value, 2) }} м³ из {{ formatNumber(status.total, 2) }} м³
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                    Итого нераспределено
                                </div>
                            </td>
                            <td>
                                <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(unallocatedTotal, 2) }} м³
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <div class='d-flex content-center pl-10 pr-10 summary-order-groups-container'>
            <div :style="`min-width: ${mobile ? 'max-content' : '130px'}`">
                <div class="order-groups">
                    <img v-if="unallocatedTotal > 0" :src="require('@/assets/warning-2.svg')" class="order-groups__icon"/>
                    <img v-else :src="require('@/assets/box.svg')" class="order-groups__icon"/>
                    <div class="order-groups__info">
                        <div class="order-groups__info__title">
                            <div class="d-flex">
                                <AnimateNumber :count="mixOrderCount" :fraction="2"/>
                                <span class="ml-5">м³</span>
                            </div>
                        </div>
                        <div class="order-groups__info__desc" :class="{ 'order-groups__info__desc--yellow': unallocatedTotal > 0 }">
                            Объем на день
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'opacity-0-1': mixOrderCount === 0 }" :style="{ opacity: mixOrderCount > 0 ? 1 : 0.2 }"
                 class="transition-opacity duration-500">
                <SummaryMixOrdersCountPie :statuses="statuses"/>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import SummaryMixOrdersCountPie from "@/views/dispatch/components/summaryBlocks/SummaryMixOrdersCountPie.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "SummaryMixOrdersCount",
    components: {SummaryMixOrdersCountPie, AnimateNumber},
    mixins: [functionsMixin, mobileCheckMixin],
    props: {
        orders: Array
    },
    computed: {
        mixOrderCount() {
            return this.orders.reduce((total, order) => total + order.total, 0)
        },
        allTotal () {
            return this.statuses.reduce((acc, status) => acc + status.value, 0)
        },
        unallocatedTotal() {
            const ordersTotal = this.orders.reduce((total, order) => total + order.total, 0)

            const mixOrdersTotal = this.orders.flatMap(order => order.orders)
                .reduce((total, order) => total + order.total, 0)

            return (ordersTotal - mixOrdersTotal) ?? 0
        },
        unallocatedOrders() {
            let unallocatedOrders = []

            this.orders.forEach(order => {
                let total = order.total
                let mixOrdersTotal = order.orders.reduce((total, order) => total + order.total, 0)
                if (total !== mixOrdersTotal) {
                    unallocatedOrders.push({
                        name: 'Заказ #' + order.id,
                        value: total - mixOrdersTotal,
                        total: total
                    })
                }
            })

            return unallocatedOrders
        },
        statuses() {
            const statusMap = {
                new: {name: 'Очередь', color: '#66b5fe'},
                loading: {name: 'Загрузка', color: '#1984e6'},
                delivery: {name: 'Доставка', color: '#00d0a3'},
                object: {name: 'На объекте', color: '#f4d16c'},
                pouring: {name: 'Заливка', color: '#ecaf02'},
                return: {name: 'Возвращается', color: '#d09cfe'},
                done: {name: 'Завершено', color: '#009272'},
            };

            const mixOrders = this.orders.flatMap(order => order.orders)

            const unconfirmedOrders = mixOrders.filter(order => !order.confirm);
            const confirmedOrders = mixOrders.filter(order => order.confirm);

            return Object.keys(statusMap).reduce((statusesData, status) => {
                const filteredOrders = confirmedOrders.filter(order => order.status === status);
                const count = filteredOrders.length;
                const total = filteredOrders.reduce((total, order) => total + order.total, 0);

                if (count > 0) {
                    statusesData.push({
                        name: statusMap[status].name,
                        value: total,
                        color: statusMap[status].color,
                        count: count,
                        itemStyle: {color: statusMap[status].color}
                    });
                }
                return statusesData;
            }, []).concat([{
                name: 'Ожидает подтверждения',
                value: unconfirmedOrders.reduce((total, order) => total + order.total, 0),
                color: '#c9ccd0',
                count: unconfirmedOrders.length,
                itemStyle: {color: '#c9ccd0'}
            }]);
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">


</style>
