<template>
    <div class="content">
        <div class="content__title">
            <span>Завод не найден</span>
        </div>
        <OrderTermsItem :termsItem="{
                message: message,
                status: false,
            }"
            style="color: #AD3028;"
        />
        <div class="content__desc">
            <span>Отредактируйте график работы заводов</span>
        </div>
    </div>
</template>
<script>
import OrderTermsItem from "@/views/dispatch/components/OrderTermsItem.vue";

export default {
    name: "MixNotFound",
    components: {OrderTermsItem},
    props:['message']
}
</script>
<style scoped lang="scss">
.content{
    &__title{
        color: #393F4A;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    &__desc{
        color: #49515F;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
