<template>
    <Auth title="Настройки автоматизации">
        <div class="card" v-loading="loading">
            <div class="card-body">
                <el-button
                    type="text"
                    :icon="icons.back"
                    class="color-grey mb-5"
                    @click="$router.push({name: 'RelationsIndex'})"
                >
                    Вернуться к списку автоматизаций
                </el-button>

                <el-row :gutter="15">

                    <el-col :sm="14">

                        <p><strong>Тип автоматизации:</strong> {{ relation ? relation.types.name : '-' }}</p>
                        <p><strong>Модуль отправитель:</strong> {{ relation ? relation.sender.name : '-' }}</p>
                        <p><strong>Модуль получатель:</strong> {{ relation ? relation.recipient.name : '-' }}</p>

                        <div v-if="relation">

                            <div v-if="relation.types.name === 'Весовой терминал - Контракты'">
                                <p>Для данной вида автоматизации нет особых настроек. При создании взвешивания с типом операции
                                    "реализация" система
                                    обращается в модуль "{{ relation ? relation.recipient.name : '-' }}", находит
                                    актуальный контракт с таким же грузом, как и в взвешивнии из модуля
                                    "{{ relation ? relation.sender.name : '-' }}".</p>

                                <p>Если контракт найден, то в него добавляется значение из модуля
                                    "{{ relation ? relation.sender.name : '-' }}".</p>
                            </div>

                            <div v-if="relation.types.name === 'Весовой терминал - Склад'">

                                <WeighingStorehouseSettings
                                    v-model="relation.settings"
                                    :relation-settings="relation.settings"
                                />

                            </div>

                            <div v-if="relation.types.name === 'Весовой терминал - Производство'">

                                <WeighingProductionSettings
                                    v-model="relation.settings"
                                    :relation-settings="relation.settings"
                                />

                            </div>

                        </div>
                    </el-col>

                    <el-col :sm="10">

                        <div class="card" style="background: #FAFAFA">
                            <div class="card-body">
                                <PageH1 title="Справка"/>

                                <p>{{ relation ? relation.types.description : '' }}</p>
                            </div>
                        </div>

                    </el-col>

                </el-row>


                <el-form-item class="mt-30">
                    <el-button @click="doEdit" :loading="loading" :icon="icons.check"
                               type="primary">Сохранить изменения
                    </el-button>
                    <el-button @click="doDelete($route.params.id)" type="danger" :icon="icons.delete"/>
                </el-form-item>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import PageH1 from '@/views/components/PageH1'
import functionsMixin from '@/mixins/functionsMixin'
import WeighingProductionSettings
    from '@/views/components/SettingsPage/RelationsPage/RelationSettings/WeighingProductionSettings'
import { httpService } from '@/services/http.service'
import WeighingStorehouseSettings
    from "@/views/components/SettingsPage/RelationsPage/RelationSettings/WeighingStorehouseSettings";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'RelationEdit',
    data() {
        return {
            loading: true,
            relation: null
        }
    },
    components: {
        WeighingStorehouseSettings,
        WeighingProductionSettings,
        PageH1,
        Auth
    },
    mixins: [functionsMixin, iconsMixin],
    inject: ['api'],
    created() {
        httpService().post(this.api.settings.getOneRelation, {
            id: this.$route.params.id
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.relation = data.relation

        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
        }).finally(() => {
            this.loading = false
        })
    },
    methods: {
        doEdit() {
            this.loading = true

            httpService().post(this.api.settings.updateRelation, {
                relationId: this.relation.id,
                settings: this.relation.settings
            }).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                this.$router.push({name: 'RelationsIndex'})

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            }).finally(() => {
                this.reloadNav = !this.reloadNav
            })
        },
        doDelete() {
            this.$confirm(
                'Вы действительно хотите удалить данную автоматизацию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.settings.removeRelation, {
                        relationId: this.relation.id
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('closeDrawer')
                        this.$message({
                            message: 'Автоматизация удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({ name: 'RelationsIndex' })
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        }
    }
}
</script>


<style scoped>

</style>
