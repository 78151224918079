<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
        <rect width="36" height="36" fill="url(#a)" rx="18"/>
        <path fill="#49A7FD" d="M24.667 16v4.973c0 1.527-1.24 2.76-2.767 2.76h-7.8a2.763 2.763 0 0 1-2.767-2.76V16h13.334Z" opacity=".4"/>
        <path fill="#49A7FD" d="M24.667 15.027V16H11.333v-.973c0-1.527 1.24-2.76 2.767-2.76h7.8a2.763 2.763 0 0 1 2.767 2.76ZM15.333 21.5H14a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h1.333c.274 0 .5.227.5.5s-.226.5-.5.5Zm4.334 0H17a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h2.667c.273 0 .5.227.5.5s-.227.5-.5.5Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="36" y1="36" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#B8DDFE"/>
                <stop offset="1" stop-color="#B8DDFE" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "PaymentCardIcon"
}
</script>

<style scoped>

</style>
