<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#AFB3BA" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.9668 10.3H8.03346V10.3666H7.9668V10.3Z" stroke="#AFB3BA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.9623 8.57874C7.7906 8.57874 7.66062 8.41726 7.67607 8.24626C7.69299 8.05902 7.72445 7.90131 7.77046 7.77312C7.83939 7.58106 7.93735 7.42555 8.06434 7.30658C8.19132 7.1876 8.3437 7.07798 8.52148 6.9777C8.62851 6.91652 8.72465 6.84428 8.80991 6.761C8.89518 6.67602 8.9623 6.57829 9.01128 6.46782C9.06207 6.35734 9.08747 6.23497 9.08747 6.1007C9.08747 5.93414 9.04574 5.78967 8.9623 5.6673C8.87885 5.54493 8.76728 5.4506 8.6276 5.38431C8.48792 5.31803 8.33282 5.28489 8.1623 5.28489C8.01354 5.28489 7.87023 5.31378 7.73236 5.37157C7.5945 5.42935 7.4793 5.52028 7.38679 5.64436C7.34862 5.69554 7.31709 5.75323 7.2922 5.81744C7.22662 5.98658 7.08577 6.13129 6.90436 6.13129C6.70644 6.13129 6.54349 5.96309 6.59798 5.77282C6.63829 5.63204 6.69896 5.50423 6.77998 5.38941C6.92692 5.18376 7.12012 5.02655 7.35957 4.91777C7.60084 4.809 7.86842 4.75461 8.1623 4.75461C8.48157 4.75461 8.75912 4.81409 8.99495 4.93307C9.23259 5.05204 9.41581 5.2152 9.54461 5.42256C9.67522 5.62991 9.74053 5.86615 9.74053 6.13129C9.74053 6.31825 9.70969 6.48736 9.64801 6.63863C9.58815 6.78989 9.50107 6.92501 9.38679 7.04399C9.27431 7.16296 9.13826 7.26834 8.97862 7.36011C8.81899 7.45359 8.69109 7.55217 8.59495 7.65585C8.4988 7.75782 8.42896 7.87935 8.38543 8.02041C8.36235 8.09519 8.34488 8.17976 8.33301 8.27412C8.31344 8.42979 8.19746 8.56053 8.04143 8.57702C8.03059 8.57816 8.0197 8.57874 8.0088 8.57874C7.99673 8.57874 7.97949 8.57874 7.9623 8.57874Z" fill="#AFB3BA" stroke="#AFB3BA" stroke-width="0.500002"/>
    </svg>
</template>

<script>
export default {
    name: "FaqIcon3",
    props: [
        'size'
    ]
}
</script>

<style scoped>
    svg {
        width: v-bind(size);
    }
</style>
