<template>
    <div class="analyticsCard" v-bind:class="{ 'analyticsCardBig': big }" v-if="!data.noData.length">
        <div class="analyticsCardBody">
            <span class="analyticsCardTopTitle textXS textXSRegular neutral300">{{ title }}</span>

            <div class="analyticsCardMainBlock">
                <div class="headingXS headingRegular">
                    <el-skeleton :loading="loading" animated>
                        <template #template>
                            <el-skeleton-item variant="h3" style="width: 180px; height: 27px"/>
                        </template>
                        <template #default>
                            <template v-if="type === 'money'">
                                <AnimateNumber :count="count ?? indicator.count"/>
                                руб.
                            </template>
                            <template v-else-if="type === 'smena'">
                                <AnimateNumber :count="count ?? indicator.count"/>
                                <good-unit :unit="unit"/>
                                /смена
                            </template>
                            <template v-else>
                                <AnimateNumber :count="count ?? indicator.count"/>
                                <good-unit :unit="unit"/>
                            </template>
                            <template v-if="indicator.count && indicator.oldCount">
                                <el-tooltip
                                    effect="customized"
                                    raw-content
                                    placement="top"
                                >
                                    <template #content>
                                        <span>
                                            За аналогичный период прошлого месяца было {{ formatNumber(indicator.oldCount) }}
                                        </span>
                                    </template>
                                    <span v-if="indicator.oldCount"
                                          class="analyticsCardMainBlockTop textXS textXSRegular"
                                          v-bind:class="{ 'success300': getPercent(indicator.count, indicator.oldCount) < 0, 'danger300 topDown': getPercent(indicator.count, indicator.oldCount) >= 0}">
                                    <Top/> {{ Math.abs(getPercent(indicator.count, indicator.oldCount)) }}%</span>
                                </el-tooltip>
                            </template>
                        </template>
                    </el-skeleton>
                </div>
                <span v-if="data.planPercent"
                      class="textXS textXSRegular neutral200">
                    <template v-if="data.planPercent.type === 'futurePlan'">
                        <template v-if="+data.planPercent.value">Из планируемых {{ formatNumber(data.planPercent.value) }} <good-unit unit="м3."/></template>
                        <template v-if="+data.planPercent.value === 0"><a class="linkAnalyticNoData" :href="'/settings/modules/edit/' + this.$route.params.moduleId" target="_blank">Укажите план производства</a></template>
                    </template>
                    <template v-else-if="data.planPercent.type === 'norma'">
                        Норма - {{ formatNumber(data.planPercent.value) }}%
                    </template>
                    <template v-else>
                        <template v-if="+data.planPercent?.planCount">{{ formatNumber(data.planPercent.value) }}% от плана</template>
                        <template v-if="+data.planPercent?.planCount === 0"><a class="linkAnalyticNoData" :href="'/settings/modules/edit/' + this.$route.params.moduleId" target="_blank">Укажите план производства</a></template>
                    </template>
                </span>
            </div>
            <div class="analyticsCardMainBlockRight">
                <ChartBar v-if="data.chartData?.chartType === 'bar' && (+data.planPercent?.planCount !== 0 || emptyChart)" :data="data.chartData" :unit="unit"/>
                <BarEmpty v-if="data.chartData?.chartType === 'bar' && +data.planPercent?.planCount === 0 && !emptyChart" :data="data.chartData" :unit="unit"/>
                <ChartLine v-if="data.chartData?.chartType === 'line' && (+data.planPercent?.planCount !== 0 || emptyChart)" :data="data.chartData" :unit="unit"/>
                <LineEmpty v-if="data.chartData?.chartType === 'line' && +data.planPercent?.planCount === 0 && !emptyChart" :data="data.chartData" :unit="unit"/>
                <ChartProgress v-if="data.chartData?.chartType === 'progress' && +data.planPercent.value !== 0" :data="data.chartData" :unit="unit"/>
                <ProgressEmpty v-if="data.chartData?.chartType === 'progress' && +data.planPercent.value === 0" :data="data.chartData" :unit="unit"/>
            </div>
        </div>
    </div>

    <AnalyticsEmptyBlock :data="data" :title="title" v-if="data.noData.length"/>
</template>

<script>
import {Top} from '@element-plus/icons-vue'
import AnimateNumber from '@/views/components/AnimateNumber.vue'
import GoodUnit from "@/views/components/goodUnit.vue";
import functionsMixin from "@/mixins/functionsMixin";
import ChartBar from '../Charts/Bar.vue';
import ChartLine from '../Charts/Line.vue';
import ChartProgress from '../Charts/Progress.vue';
import AnalyticsEmptyBlock from "@/views/components/AnalyticsPage/AnalyticsBlocks/AnalyticsEmptyBlock.vue";
import getPercent from "@/mixins/getPercent";
import BarEmpty from '@/views/components/AnalyticsPage/Charts/BarEmpty.vue';
import LineEmpty from '@/views/components/AnalyticsPage/Charts/LineEmpty.vue';
import ProgressEmpty from '@/views/components/AnalyticsPage/Charts/ProgressEmpty.vue';

export default {
    name: 'AnalyticsMainBlock',
    components: {
        ProgressEmpty,
        LineEmpty,
        BarEmpty, AnalyticsEmptyBlock, GoodUnit, AnimateNumber, Top, ChartBar, ChartLine, ChartProgress },
    mixins: [functionsMixin, getPercent],
    props: [
        'loading', // переменная загрузки
        'data', // объект индикатора
        'title', // заголовок
        'count', // числовое значение
        'big', // блок с большей высотой
        'unit', // единица измерения
        'type', // тип данных (money и тд)
        'countPercent', // процент изменений
        'countPercentRound', // если true, то процент изменений при меньше 0 будет иметь зеленый цвет
        'planPercent', // процент разницы отхождения от плана
        'emptyChart'
    ],
    data() {
        return {
            indicator: {
                count: 0,
                oldCount: 0,
                planPercent: {
                    value: 0,
                    planCount: 0,
                    type: null
                },
                noData: []
            }
        }
    },
    watch: {
        'data': {
            handler: function () {
                if (this.data) this.indicator = this.data
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
