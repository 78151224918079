export default {
    data() {
        return {
            goodTypes: [
                {
                    key: 'good',
                    name: 'Обычный товар'
                },
                {
                    key: 'spec',
                    name: 'Спецификация'
                },
                {
                    key: 'mix',
                    name: 'Рецепт'
                },
                {
                    key: 'doc',
                    name: 'Бухгалтерский'
                }
            ],
        }
    },
    methods: {
        getTypeName(type) {
            return this.goodTypes.find(el => el.key === type)?.name ?? '-'
        },
    }
}
