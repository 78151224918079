<template>
    <div></div>
</template>

<script>

export default {
    name: 'TechIndex',
    components: {

    },
    data() {
        return {

        }
    },
    created() {
        this.$router.push({ name: 'techVehicles', params: { id: this.$route.params.id } })
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
