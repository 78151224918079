<template>
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 13.125C3 11.3991 5.23858 10 8 10C10.7614 10 13 11.3991 13 13.125" stroke="#1984E6" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 8.125C9.72589 8.125 11.125 6.72589 11.125 5C11.125 3.27411 9.72589 1.875 8 1.875C6.27411 1.875 4.875 3.27411 4.875 5C4.875 6.72589 6.27411 8.125 8 8.125Z" stroke="#1984E6" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "UserProfileBoldIcon",
    props: ['width', 'height']
}
</script>

<style scoped>

</style>
