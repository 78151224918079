<template>
    <Auth title="Шины" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    create-button-label="Добавить шину"
                    @moduleGet="getAll()"
                    @openCreateValueDrawer="setCreateDrawerOpen()"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-button
                            v-if="multipleSelection.length > 0"
                            @click="remove(multipleSelection, true)"
                            class="ci-btn ci-btn_white"
                            :icon="icons.delete"
                        />
                    </template>
                </TableHeadBlock>

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Артикул"
                            :body="item.sku ?? '-'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Удалить"
                            @action="remove(item.id, false)"
                        />

                    </template>
                </MobileTableFramework>

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="шин"
                />

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile && displayData.length > 0"
                    @selection-change="handleSelectionChange"
                    class="w-100 mt-15">
                    <el-table-column type="selection" width="40" />
                    <el-table-column
                        prop="sku"
                        label="Артикул"
                        width="220px">
                        <template v-slot="scope">
                            <span :class="'textMedium ' + (scope.row.sku ? 'primary600' : 'neutral300')">{{ scope.row.sku ? '№' + scope.row.sku : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mileage"
                        label="Пробег"
                        width="220px">
                        <template v-slot="scope">
                            {{ scope.row.mileage ? formatNumber(scope.row.mileage) + ' км.' : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="80px">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                :icon="icons.delete"
                                @click="remove(scope.row.id)"
                            />
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>



        <el-drawer v-model="drawerCreate"
                   title=""
                   direction="rtl"
                   :size="drawerSize"
                   :destroy-on-close="true">
            <TireCreate
                @getAll="getAll()"
                @closeDrawer="setCreateDrawerOpen()"
            />
        </el-drawer>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState.vue";
import TireCreate from "@/views/components/SettingsPage/TiresPage/TireCreate.vue";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'SettingsVehicleIndex',
    data() {
        return {
            loading: true,
            drawerCreate: false,
            tabs: [{
                route: 'vehicles',
                name: 'Техника'
            }, {
                route: 'TiresIndex',
                name: 'Шины'
            }]
        }
    },
    components: {
        TireCreate,
        EmptyState,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, iconsMixin, functionsMixin
    ],
    created() {
        this.getAll()
    },
    methods: {
        getAll() {
            this.loading = true
            this.multipleSelection = []
            httpService().post(this.api.tires.getAll).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate
        },
        remove(ids, mass) {
            this.$confirm(
                'Вы действительно хотите произвести удаление?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    let idsDelete = []
                    ids.forEach(el => idsDelete.push(el.id))

                    httpService().post(this.api.tires.remove, {
                        ids: mass ? idsDelete : [ids]
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        '$route.params.openDrawer': {
            handler: function (openDrawer) {
                if (openDrawer) this.setCreateDrawerOpen()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
