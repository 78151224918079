<template>
    <svg :class="[{'currentColor' : currentColor}]"
         xmlns="http://www.w3.org/2000/svg" width="16" height="16"
         viewBox="0 0 16 16"
         fill="none">
        <path class="fill"
            d="M5.91969 3.06667C5.38636 0.760002 1.91302 0.753335 1.37969 3.06667C1.06636 4.42 1.92636 5.56667 2.67302 6.28C3.21969 6.79334 4.07969 6.79334 4.62636 6.28C5.37302 5.56667 6.22636 4.42 5.91969 3.06667ZM3.67302 4.13334C3.30636 4.13334 3.00636 3.83333 3.00636 3.46667C3.00636 3.1 3.29969 2.8 3.66636 2.8H3.67302C4.04636 2.8 4.33969 3.1 4.33969 3.46667C4.33969 3.83333 4.04636 4.13334 3.67302 4.13334Z"
            fill="#8a909a"/>
        <path
            d="M4.34017 3.46665C4.34017 3.83332 4.04684 4.13332 3.6735 4.13332C3.30684 4.13332 3.00684 3.83332 3.00684 3.46665C3.00684 3.09999 3.30017 2.79999 3.66684 2.79999H3.6735C4.04684 2.79999 4.34017 3.09999 4.34017 3.46665Z"
            fill="white"/>
        <path  class="fill"
            d="M14.6067 11.0667C14.0733 8.76 10.5867 8.75334 10.0467 11.0667C9.73333 12.42 10.5933 13.5667 11.3467 14.28C11.8933 14.7933 12.76 14.7933 13.3067 14.28C14.06 13.5667 14.92 12.42 14.6067 11.0667ZM12.3533 12.1333C11.9867 12.1333 11.6867 11.8333 11.6867 11.4667C11.6867 11.1 11.98 10.8 12.3467 10.8H12.3533C12.72 10.8 13.02 11.1 13.02 11.4667C13.02 11.8333 12.72 12.1333 12.3533 12.1333Z"
            fill="#8a909a"/>
        <path
            d="M13.0199 11.4667C13.0199 11.8333 12.7199 12.1333 12.3532 12.1333C11.9865 12.1333 11.6865 11.8333 11.6865 11.4667C11.6865 11.1 11.9799 10.8 12.3465 10.8H12.3532C12.7199 10.8 13.0199 11.1 13.0199 11.4667Z"
            fill="white"/>
        <path  class="fill"
            d="M7.99963 13.1666H6.21297C5.43963 13.1666 4.7663 12.7 4.49963 11.98C4.2263 11.26 4.4263 10.4666 5.0063 9.95331L10.333 5.29331C10.653 5.01331 10.6596 4.63331 10.5663 4.37331C10.4663 4.11331 10.213 3.83331 9.7863 3.83331H7.99963C7.7263 3.83331 7.49963 3.60665 7.49963 3.33331C7.49963 3.05998 7.7263 2.83331 7.99963 2.83331H9.7863C10.5596 2.83331 11.233 3.29998 11.4996 4.01998C11.773 4.73998 11.573 5.53331 10.993 6.04665L5.6663 10.7066C5.3463 10.9866 5.33963 11.3666 5.43297 11.6266C5.53297 11.8866 5.7863 12.1666 6.21297 12.1666H7.99963C8.27297 12.1666 8.49963 12.3933 8.49963 12.6666C8.49963 12.94 8.27297 13.1666 7.99963 13.1666Z"
            fill="#8a909a"/>
    </svg>
</template>

<script>
export default {
    name: "RouteIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        .fill {
            fill: currentColor;
        }
    }
</style>
