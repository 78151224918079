<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавление нового значения"/>

            <el-form ref="form" :model="createValueForm" label-position="top">

                <FormSelect
                    label="Продукция"
                    placeholder="Выберите"
                    v-model="createValueForm.good_id"
                    :items="productionGoods.sort((a, b) => a.name.localeCompare(b.name))"
                />

                <FormSelect
                    :disabled="!createValueForm.good_id || !storehouseUnits.length"
                    label="Сорт"
                    placeholder="Выберите"
                    v-model="createValueForm.unit_id"
                    :items="storehouseUnits"
                />

                <FormNumber
                    v-model="createValueForm.value"
                    label="Количество"
                    placeholder="Например, 12 500"
                />

                <FormSelect
                    v-model="createValueForm.shift_id"
                    label="Ответственная смена"
                    placeholder="Выберите смену"
                    :items="shifts"
                />

                <FormDateTimePicker
                    label="Дата"
                    v-model="createValueForm.date_create"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Добавить значение" @action="createValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '../../Drawer/DrawerFramework.vue'
import DrawerHeadTitle from '../../Drawer/DrawerHeadTitle.vue'
import DrawerCreateButton from '../../Drawer/Buttons/DrawerCreateButton.vue'
import FormSelect from '../../Form/FormSelect.vue'
import FormDateTimePicker from '../../Form/FormDateTimePicker.vue'
import FormNumber from '../../Form/FormNumber.vue'
import {httpService} from '@/services/http.service'

export default {
    name: 'ProductionValueCreate',
    components: {
        FormNumber,
        FormDateTimePicker,
        FormSelect,
        DrawerCreateButton,
        DrawerHeadTitle, DrawerFramework
    },
    created() {
        this.getProductionGoods()
        this.getProductionShifts()

        window.setTimeout(() => {
            this.loading = false
        }, 200)
    },
    data() {
        return {
            loading: true,
            productionGoods: [],
            shifts: [],
            createValueForm: {
                good_id: null,
                unit_id: null,
                value: null,
                shift_id: null,
                date_create: null,
            },
            storehouseUnits: []
        }
    },
    inject: [
        'api'
    ],
    methods: {
        createValue() {
            this.loading = true

            httpService().post(this.api.productionValue.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.createValueForm
            }).then(() => {
                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
        },
        getProductionGoods() {
            httpService().post(this.api.productionGood.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.productionGoods = data.values
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        getProductionShifts() {
            httpService().post(this.api.productionShift.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.shifts = data.values
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
    },
    watch: {
        'createValueForm.good_id': {
            handler: function() {
                let storehouseId = null
                this.storehouseUnits = []

                if (this.createValueForm.good_id) {
                    let good = this.productionGoods.find(el => el.id === this.createValueForm.good_id)

                    if (good) {
                        let controlStep = good.steps.find(el => el.type === 'control')

                        if (controlStep) {
                            storehouseId = controlStep.storehouseId

                            if (storehouseId) {
                                httpService().post(this.api.storehouse.getUnits, {
                                    account_module_reserve_id: storehouseId,
                                    module_key: 'storehouse'
                                }).then((response) => {
                                    let data = response.data

                                    if (data.success) {
                                        this.storehouseUnits = data.units
                                    }
                                }).catch((error) => {
                                    this.$message({
                                        message: error.response.data.message,
                                        showClose: true,
                                        type: 'error'
                                    })
                                })
                            }
                        }
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
