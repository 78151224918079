<template>
    <svg
        width="16" height="16" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
            stroke-width="1.8" stroke-linecap="round"
            stroke-linejoin="round"/>
        <path
            d="M20 18C21.26 16.33 22 14.25 22 12C22 9.75 21.26 7.67 20 6"
            stroke-width="1.8" stroke-linecap="round"
            stroke-linejoin="round"/>
        <path d="M4 6C2.74 7.67 2 9.75 2 12C2 14.25 2.74 16.33 4 18"
              stroke-width="1.8" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path
            d="M16.8 15.6C17.55 14.6 18.0001 13.35 18.0001 12C18.0001 10.65 17.55 9.40002 16.8 8.40002"
            stroke-width="1.8" stroke-linecap="round"
            stroke-linejoin="round"/>
        <path
            d="M7.20001 8.40002C6.45001 9.40002 6 10.65 6 12C6 13.35 6.45001 14.6 7.20001 15.6"
            stroke-width="1.8" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "SignalIcon"
}
</script>
