<template>
    <div class="topCard cashboxInfoGraph">
        <h3>Доходы и расходы</h3>

        <div class="cashboxInfoGraphFlex">
            <div>
                <h2 :class="diffIncome ? 'color-success' : 'color-error'">
                    {{ diffIncome ? '+ ' : '- ' }}
                    <animate-number
                        :count="total"
                    />
                    руб.
                </h2>

                <div class="cashboxInfoGraphIndicators">
                <p>Доходы</p>
                <span><animate-number :count="income"/> руб.</span>

                <p>Расходы</p>
                <span><animate-number :count="expenditure"/> руб.</span>
                </div>
            </div>
            <div class="cashboxInfoGraphChart" v-if="!mobile">
                <v-chart v-model="chart" class="chart" :option="option" autoresize/>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from 'vue-echarts'
import functionsMixin from '@/mixins/functionsMixin'
import AnimateNumber from "@/views/components/AnimateNumber";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'CashboxInfoGraph',
    components: {VChart, AnimateNumber},
    props: ['totalDiff', 'totalIncome', 'totalExpenditure', 'infoGraph'],
    mixins: [functionsMixin, mobileCheckMixin],
    data() {
        return {
            total: 0,
            diffIncome: true,
            chart: 1,
            income: 0,
            expenditure: 0,
        }
    },
    watch: {
        'totalDiff': {
            handler: function () {
                if (this.totalDiff < 0) {
                    this.total = Math.abs(this.totalDiff)
                    this.diffIncome = false
                } else {
                    this.total = this.totalDiff
                    this.diffIncome = true
                }
            },
            deep: true,
            immediate: true
        },
        'totalIncome': {
            handler: function () {
                if (this.totalIncome) {
                    this.income = this.totalIncome
                } else {
                    this.income = 0
                }
            },
            deep: true,
            immediate: true
        },
        'totalExpenditure': {
            handler: function () {
                if (this.totalExpenditure) {
                    this.expenditure = this.totalExpenditure
                } else {
                    this.expenditure = 0
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        option() {
            return {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        color: ['#00cdc4', '#ff5253'],
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.infoGraph
                    }
                ]
            }
        }
    },
}
</script>

<style scoped>

</style>
