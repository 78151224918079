<template>
    <el-row :gutter="16" style="margin-bottom: -28px">
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Произведено продукции"
                :count="graph.indicators.countProd.value"
                :count-old="graph.indicators.countProd.oldValue"
                :unit="getUnitFromIds(graph.units)"
                :period-name="periodName"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Средняя скорость производства"
                :count="graph.indicators.speed.value"
                :count-old="graph.indicators.speed.oldValue"
                :unit="getUnitFromIds(graph.units)"
                type="speedHour"
                :period-name="periodName"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Выполнено заявок"
                :count="graph.indicators.countOrders.value"
                :count-old="graph.indicators.countOrders.oldValue"
                :period-name="periodName"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsDateTimeBlock
                title-unit="отгрузки"
                :date="graph.indicators.lastDate"
                :tz="tz"
                :period="period"
            />
        </el-col>
    </el-row>

    <div id="scrollDiv" class="mix-scroll-x">
        <div class="bar-echarts" v-show="graph.indicators.countProd.value > 0">
            <span
                class="analyticsCardTopTitle textXS textXSRegular neutral300"
            >Производство продукции за {{ periodName }}</span>
            <div class="chart">
                <v-chart
                    v-model="chart"
                    :option="option"
                    autoresize
                />
            </div>
        </div>

        <div class="bar-echarts" v-show="graph.indicators.countProd.value === 0">
            <div class="emptyData">
                Нет данных
            </div>
            <svg width="100%" viewBox="0 0 1084 176" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 20px; margin-top: 56px">
                <path d="M36 133C36 126.373 41.3726 121 48 121H148C154.627 121 160 126.373 160 133V176H36V133Z" fill="#EEEEF0"/>
                <path d="M184 80C184 73.3726 189.373 68 196 68H296C302.627 68 308 73.3726 308 80V176H184V80Z" fill="#EEEEF0"/>
                <path d="M332 122C332 115.373 337.373 110 344 110H444C450.627 110 456 115.373 456 122V176H332V122Z" fill="#EEEEF0"/>
                <path d="M480 83C480 76.3726 485.373 71 492 71H592C598.627 71 604 76.3726 604 83V176H480V83Z" fill="#EEEEF0"/>
                <path d="M628 119C628 112.373 633.373 107 640 107H740C746.627 107 752 112.373 752 119V176H628V119Z" fill="#EEEEF0"/>
                <path d="M776 133C776 126.373 781.373 121 788 121H888C894.627 121 900 126.373 900 133V176H776V133Z" fill="#EEEEF0"/>
                <path d="M924 152C924 145.373 929.373 140 936 140H1036C1042.63 140 1048 145.373 1048 152V176H924V152Z" fill="#EEEEF0"/>
            </svg>

        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import VChart from "vue-echarts";
import getUnitFromGoodsMixin from "@/mixins/getUnitFromGoodsMixin";
import InfoAnalyticsCounterBlock from "@/views/components/Blocks/InfoAnalyticsCounterBlock.vue";
import InfoAnalyticsDateTimeBlock from "@/views/components/Blocks/InfoAnalyticsDateTimeBlock.vue";

export default {
    name: 'MixInfoGraph',
    props: [
        'graph',
        'period',
        'periodName',
        'tz',
    ],
    mixins: [
        functionsMixin,
        getUnitFromGoodsMixin
    ],
    components: {
        InfoAnalyticsDateTimeBlock,
        InfoAnalyticsCounterBlock,
        VChart
    },
    data() {
        return {

        }
    },
    computed: {
        option() {
            return {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    textStyle: {
                        color: '#3a3a3a',
                    },
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: function (params) {
                        const formatNumber = (value) => {
                            // Функция добавляет тысячные пробелы
                            var fractionNumber = 2
                            const f = x => ((x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0))

                            if (f(value) === 0) {
                                let val = (value / 1).toFixed(0).replace('.', ',')
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            } else {
                                let val = (value / 1).toFixed(fractionNumber).replace('.', ',')
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            }
                        }
                        const colorSpan = (color) => `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color: ${color}"></span>`;
                        let finalContent = '';

                        params.forEach(item => {
                            let _value = formatNumber(item.data)
                            let content = `<div><p style="margin: 0">${item.name}</p><p style="margin: 0">${colorSpan(item.color)}Кол-во: <b style="margin-right: 3px">${_value}</b></p></div>`
                            finalContent += content;
                        });

                        return finalContent;
                    }
                },
                grid: {
                    left: 8,
                    right: 0,
                    bottom: 0,
                    top: 10,
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.graph.labels,
                        shadowColor: '#F9F9F9',
                        borderColor: 'transparent',
                        axisLabel: {
                            margin: 16,
                            textStyle: {
                                color: (val, index) => {
                                    const date = new Date();
                                    const periodMonth = this.period === 'month';
                                    const periodToday = this.period === 'today';
                                    const periodYear = this.period === 'year';
                                    const periodWeek = this.period === 'week';

                                    const month = (option =  'long') => date.toLocaleString('ru', {
                                        month: option
                                    });

                                    const weekday = (option = 'short') => date.toLocaleString('ru', {
                                        weekday: option
                                    });

                                    const day = date.toLocaleString('ru', {
                                        day: '2-digit'
                                    });

                                    const hour = date.toLocaleString('ru', {
                                        hour: '2-digit'
                                    });

                                    const notNowHour = hour.split(':')[0] < val.split(':')[0];
                                    const notThisDay = day < val;

                                    if (
                                        val.toLowerCase() === month()
                                        || val.toLowerCase() === weekday()
                                        || val.split(':')[0] === hour.split(':')[0] && !periodMonth
                                        || val === day && periodMonth
                                    ) {
                                        return '#00D0A3'
                                    } else if (
                                        notNowHour && periodToday
                                        || notThisDay && periodMonth
                                        || month('numeric') - 1 <= index && periodYear
                                        || date.getDay() - 1 < index && periodWeek
                                    ) {
                                        return '#C9CCD0'
                                    } else {
                                        return '#8A909A'
                                    }
                                },
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#a2a5bf',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        show: true,
                        axisLabel: {
                            textStyle: {
                                color: '#8A909A',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: '#F9F9F9',
                            },
                        }
                    },
                ],
                series: [
                    {
                        name: 'Кол-во3',
                        type: 'bar',
                        data: this.graph.total,
                        itemStyle: {
                            color: '#2BD8B3',
                            borderRadius: [12, 12, 0, 0]
                        }
                    },
                ],
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.el-popper {
    &.is-customized,
    .el-popper__arrow::before {
        max-width: 215px;
        color: #3a3a3a;
        background: #fff;
        filter: drop-shadow(0px 0px 10px rgba(30, 30, 30, 0.1));
        line-height: 1.6;
    }
}

.bar-echarts {
    border: 1px solid #EEEEF0;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 16px;

    .chart {
        height: 320px;
    }

    .emptyData {
        color: #22252C;
        text-align: center;
        font-size: 18px;
        position: relative;
        top: 1em;
    }
}
</style>
