<template>
    <div>
        <TableHeadBlock
            v-model="search"
            :loading="loading"
            :disable-filter="true"
            :disable-create-button="true"
            @moduleGet="moduleGet"
            @openCreateValueDrawer="setCreateDrawerOpen"
        >
            <template v-slot:afterSearchAndFilter>
                <el-select
                    v-model="filters.goodType"
                    placeholder="Тип товара"
                    size="large"
                    class="filterSelect"
                    style="width: 180px"
                    @change="moduleGet"
                    clearable
                >
                    <el-option
                        v-for="item in goodTypes"
                        :key="item.key"
                        :label="item.name"
                        :value="item.key"
                    />
                </el-select>
                <TableIntegrationFilter
                    v-model="filters.integrationUnitId"
                    @moduleGet="moduleGet"
                    :table="true"
                />
            </template>
        </TableHeadBlock>

        <MobileTableFramework v-for="(item, index) in displayData" :key="index">
            <template v-slot:body>

                <MobileTableHR/>

                <MobileTableInfo
                    title="Название"
                    :body="item.name ?? '-'"
                />

                <MobileTableButton
                    title="Выбрать"
                    @action="createRelation(item.id)"
                />

            </template>
        </MobileTableFramework>

        <el-table
            empty-text="Информация не найдена"
            v-loading="loading"
            v-if="!mobile"
            :data="values"
            class="w-100 mt-15">
            <el-table-column
                prop="id"
                label="#"
                width="95"
                sortable>
                <template v-slot="scope">
                    <span class="textSM textMedium neutral200 cutOverflow">#{{ formatNumber(scope.row.id) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Название"
                sortable>
                <template v-slot="scope">
                <span :class="'textMedium textSM ' + (scope.row.child ? 'neutral400' : 'neutral900')">{{
                        scope.row.name ?? '-'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="type"
                label="Тип товара"
                width="180px">
                <template v-slot="scope">
                    <span class="textRegular textSM neutral200 cutOverflow">{{ getTypeName(scope.row.type) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="integration"
                label="Интеграция"
                width="220px">
                <template v-slot="scope">
                    <template v-if="scope.row.integration_unit_id">
                        <IntegrationLogo
                            :integration-unit-id="scope.row.integration_unit_id"
                        />
                    </template>
                    <template v-else>
                        <span class="textRegular textSM neutral200">-</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label=""
                width="260">
                <template v-slot="scope">
                    <el-button
                        size="small"
                        @click="createRelationAndParent(scope.row.id)"
                    >Объединить
                    </el-button>
                    <el-button
                        size="small"
                        type="primary"
                        @click="createRelation(scope.row.id)"
                    >Добавить в группу
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import functionsMixin from '@/mixins/functionsMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework.vue'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR.vue'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo.vue'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton.vue'
import TablePagination from '@/views/components/Table/TablePagination.vue'
import {mapActions} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import goodTypes from "@/mixins/goodTypes";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";


export default {
    name: 'GoodCreateRelationDialog',
    data() {
        return {
            drawerCreate: false,
            filters: {
                goodType: null,
                integrationUnitId: null
            },
        }
    },
    props: ['goodId'],
    components: {
        TableIntegrationFilter,
        IntegrationLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, basicMixin, functionsMixin, goodTypes
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        getTypeName(type) {
            return this.goodTypes.find(el => el.key === type).name
        },
        moduleGet() {
            this.loading = true
            httpService().post(this.api.good.get, {
                allGoods: false,
                allData: true,
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                filter: this.filters,
                search: this.search,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.goods.data.sort((a, b) => a.name > b.name ? 1 : -1).filter(el => el.id !== this.goodId)
                    this.valueLength = data.goods.total
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        updateGoods() {
            this.pullGoods()
        },
        setPage(val) {
            this.page = val
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate
        },
        closeDrawer() {
            this.drawerCreate = false
        },
        createRelationAndParent(id) {
            this.$confirm(
                'Вы действительно хотите образовать связь между ' + this.getData(this.goodId, "good") + ' (#' + this.goodId + ') и ' + this.getData(id, "good") + ' (#' + id + ')?',
                'Подтвердите связь',
                {
                    confirmButtonText: 'Подтверждаю',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.$emit('createRelationAndParent', id)
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        createRelation(id) {
            this.$confirm(
                'Вы действительно хотите образовать связь между ' + this.getData(this.goodId, "good") + ' (#' + this.goodId + ') и ' + this.getData(id, "good") + ' (#' + id + ')?',
                'Подтвердите связь',
                {
                    confirmButtonText: 'Подтверждаю',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.$emit('createRelation', id)
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {

        }
    },
    watch: {
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        }
    }
}
</script>

<style scoped>

</style>
