<template>
    <el-form-item :label="label" v-bind:class="{ 'is-required': required }">
        <el-autocomplete
            class="w-100"
            v-model="itemValue"
            @input="$emit('update:modelValue', itemValue)"
            :fetch-suggestions="querySearch"
            :placeholder="placeholder"
            :value-key="valueKey ?? 'name'"
            clearable
            :disabled="disabled"
            @change="$emit('changeValue', itemValue)"
        />
    </el-form-item>
</template>

<script>
export default {
    name: 'FormInputSuggestions',
    data() {
        return {
            links: [],
            state1: '',
            state2: '',
            itemValue: this.modelValue
        };
    },
    props: ['modelValue', 'label', 'placeholder', 'items', 'property', 'disabled', 'valueKey', 'required'],
    methods: {
        querySearch(queryString, cb) {
            let param = this.property ?? 'name'
            var links = this.links
            var results = queryString ? links.filter(data => !queryString || data[param].toLowerCase().includes(queryString.toLowerCase())) : links;
            // call callback function to return suggestions
            cb(results);
        },
        loadAll() {
            return this.items;
        },
    },
    mounted() {
        this.links = this.loadAll();
    },
    watch: {
        'itemValue': {
            handler: function () {
                this.$emit('update:modelValue', this.itemValue)
            },
            deep: true,
            immediate: true
        },
        'items': {
            handler: function () {
                if (this.items) this.links = this.items
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
