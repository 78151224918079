export default {
    methods: {
        getPercent: function (newValue, oldValue) {
            let count = newValue ?? this.count
            let countOld = oldValue ?? this.countOld
            if (count && countOld) {
                return Math.round(100 - (count / countOld * 100))
            } else {
                if (!count && countOld !== 0) {
                    return 100
                } else if (count !== 0 && !countOld) {
                    return 100
                } else {
                    return 0
                }
            }
        }
    }
}
