<template>
    <PageH1 title="Учет топлива"/>

    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-search="true"
        create-button-label="Добавить заправку"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="addFuelDialog=true"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
    />

    <el-table
        v-if="!smallMobile"
        v-loading="loading"
        :data="displayData"
        class="mt-15"
        style="width: 100%">
        <el-table-column
            prop="date"
            label="Дата"
            width="85px"
            sortable
            fixed>
            <template v-slot="scope">
                <div class="double">
                    <p>{{ getDateFromUnix(scope.row.date, 'HH:mm') }}</p>
                    <span>{{ getDateFromUnix(scope.row.date, 'DD.MM.YY') }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="fuel"
            label="Топливо"
        >
            <template v-slot="scope">
                {{ scope.row.fuel ?? '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="count"
            label="Кол-во"
        >
            <template v-slot="scope">
                {{ scope.row.count ? formatNumber(scope.row.count) : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="fuel_price"
            label="Цена за литр"
        >
            <template v-slot="scope">
                {{ scope.row.fuel_price ? (scope.row.fuel_price + ' руб./л.') : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="fuel_price"
            label="Стоимость"
        >
            <template v-slot="scope">
                <span class="colored">{{ scope.row.fuel_price ? formatNumber(scope.row.count * scope.row.fuel_price) + ' руб' : '-' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            label=""
            width="130px"
        >
            <template v-slot="scope">
                <el-button
                    size="mini"
                    @click="openFuelDetailsDrawer(scope.row)">Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <el-dialog
        v-model="addFuelDialog"
        title="Изменить значение топлива и пробега"
        :width="drawerSize"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
    >
        <TechChangeFuel
            :vehicleId="vehicleId"
            @closeDialog="closeMileageDialog"
            @moduleGet="moduleGet"
        />
    </el-dialog>

    <el-drawer
        title=""
        v-model="drawerDetails"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <TechFuelDetailsDrawer
            :details="fuelDetails"
            @closeDrawer="closeFuelDetailsDrawer"
            @moduleGet="moduleGet"
        />

    </el-drawer>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import Filter from "@/views/components/Table/Filter/Filter";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import TechChangeFuel from "@/views/components/TechPage/Garage/TechChangeFuel";
import TechFuelDetailsDrawer from "@/views/components/TechPage/Garage/TechDetails/TechFuelDetailsDrawer";
import moment from "moment";
import PageH1 from "@/views/components/PageH1";

export default {
    name: 'TechDetailsFuel',
    components: {PageH1, TechFuelDetailsDrawer, TechChangeFuel, TablePagination, Filter, TableHeadBlock},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [mobileCheckMixin, tableMixin, functionsMixin],
    data() {
        return {
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
            },
            loading: false,
            addFuelDialog: false,
            drawerDetails: false,
            fuelDetails: null,
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.tech.getTechFuel, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        closeMileageDialog() {
            this.addMileageDialog = false
            this.moduleGet()
            this.$emit('moduleGet')
        },
        openFuelDetailsDrawer(details) {
            this.fuelDetails = details
            this.drawerDetails = true
        },
        closeFuelDetailsDrawer() {
            this.fuelDetails = null
            this.drawerDetails = false
            this.moduleGet()
            this.$emit('moduleGet')
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.date >= moment(this.filter.dateStart.value).add(3, 'hours').add(this.getTimezone(), 'hours').unix())
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.date <= moment(this.filter.dateEnd.value).add(3, 'hours').add(this.getTimezone(), 'hours').unix())

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }}
}
</script>

<style scoped>

</style>
