<template>
    <svg viewBox="0 0 113 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6.76923C0 6.76923 8 4 20 4C32 4 40 6.76923 40 6.76923V25.2308C40 25.2308 32 28 20 28C8 28 0 25.2308 0 25.2308V6.76923Z" fill="#2772B9"/>
        <path d="M11.56 21L8.508 16.282L10.342 15.176L14.136 21H11.56ZM5.19 21V11.2H7.444V21H5.19ZM6.786 17.052V15.162H10.202V17.052H6.786ZM10.552 16.352L8.466 16.1L11.644 11.2H14.038L10.552 16.352ZM15.1985 21V11.2H17.4525V17.584L22.1285 11.2H24.2565V21H22.0165V14.63L17.3405 21H15.1985ZM26.6965 21V11.2H35.6285V21H33.3745V12.53L33.8925 13.048H28.4325L28.9505 12.53V21H26.6965Z" fill="white"/>
        <path d="M51.524 22.09C50.588 22.09 49.718 21.934 48.914 21.622C48.122 21.298 47.432 20.848 46.844 20.272C46.256 19.696 45.8 19.024 45.476 18.256C45.152 17.476 44.99 16.624 44.99 15.7C44.99 14.776 45.152 13.93 45.476 13.162C45.8 12.382 46.256 11.704 46.844 11.128C47.444 10.552 48.14 10.108 48.932 9.796C49.724 9.472 50.588 9.31 51.524 9.31C52.412 9.31 53.24 9.454 54.008 9.742C54.788 10.03 55.454 10.468 56.006 11.056L55.43 11.65C54.89 11.122 54.29 10.744 53.63 10.516C52.982 10.276 52.292 10.156 51.56 10.156C50.756 10.156 50.006 10.294 49.31 10.57C48.626 10.846 48.026 11.236 47.51 11.74C47.006 12.244 46.61 12.832 46.322 13.504C46.046 14.176 45.908 14.908 45.908 15.7C45.908 16.492 46.046 17.224 46.322 17.896C46.61 18.568 47.006 19.156 47.51 19.66C48.026 20.164 48.626 20.554 49.31 20.83C50.006 21.106 50.756 21.244 51.56 21.244C52.292 21.244 52.982 21.13 53.63 20.902C54.29 20.662 54.89 20.272 55.43 19.732L56.006 20.326C55.454 20.914 54.788 21.358 54.008 21.658C53.24 21.946 52.412 22.09 51.524 22.09Z" fill="#2772B9"/>
        <path d="M60.9453 22.072C60.0453 22.072 59.2413 21.868 58.5333 21.46C57.8373 21.052 57.2793 20.488 56.8593 19.768C56.4513 19.048 56.2473 18.226 56.2473 17.302C56.2473 16.366 56.4513 15.544 56.8593 14.836C57.2793 14.116 57.8373 13.552 58.5333 13.144C59.2413 12.736 60.0453 12.532 60.9453 12.532C61.8453 12.532 62.6493 12.736 63.3573 13.144C64.0653 13.552 64.6233 14.116 65.0313 14.836C65.4393 15.544 65.6433 16.366 65.6433 17.302C65.6433 18.226 65.4393 19.048 65.0313 19.768C64.6233 20.488 64.0653 21.052 63.3573 21.46C62.6493 21.868 61.8453 22.072 60.9453 22.072ZM60.9453 21.262C61.6653 21.262 62.3133 21.1 62.8893 20.776C63.4653 20.44 63.9153 19.972 64.2393 19.372C64.5633 18.772 64.7253 18.082 64.7253 17.302C64.7253 16.51 64.5633 15.82 64.2393 15.232C63.9153 14.632 63.4653 14.17 62.8893 13.846C62.3133 13.51 61.6653 13.342 60.9453 13.342C60.2253 13.342 59.5773 13.51 59.0013 13.846C58.4373 14.17 57.9873 14.632 57.6513 15.232C57.3273 15.82 57.1653 16.51 57.1653 17.302C57.1653 18.082 57.3273 18.772 57.6513 19.372C57.9873 19.972 58.4373 20.44 59.0013 20.776C59.5773 21.1 60.2253 21.262 60.9453 21.262Z" fill="#2772B9"/>
        <path d="M71.9454 12.532C72.7134 12.532 73.3794 12.682 73.9434 12.982C74.5194 13.27 74.9634 13.714 75.2754 14.314C75.5994 14.902 75.7614 15.634 75.7614 16.51V22H74.8614V16.582C74.8614 15.526 74.5914 14.722 74.0514 14.17C73.5234 13.618 72.7794 13.342 71.8194 13.342C71.0874 13.342 70.4514 13.492 69.9114 13.792C69.3714 14.092 68.9574 14.512 68.6694 15.052C68.3934 15.592 68.2554 16.24 68.2554 16.996V22H67.3554V12.604H68.2194V15.214L68.0934 14.926C68.3814 14.182 68.8554 13.6 69.5154 13.18C70.1754 12.748 70.9854 12.532 71.9454 12.532Z" fill="#2772B9"/>
        <path d="M82.1128 22.072C81.2008 22.072 80.3848 21.868 79.6648 21.46C78.9568 21.052 78.3927 20.488 77.9728 19.768C77.5648 19.048 77.3608 18.226 77.3608 17.302C77.3608 16.366 77.5648 15.538 77.9728 14.818C78.3927 14.098 78.9568 13.54 79.6648 13.144C80.3848 12.736 81.2008 12.532 82.1128 12.532C82.8688 12.532 83.5528 12.676 84.1648 12.964C84.7888 13.252 85.2928 13.684 85.6768 14.26L85.0108 14.746C84.6748 14.266 84.2548 13.912 83.7508 13.684C83.2467 13.456 82.7008 13.342 82.1128 13.342C81.3808 13.342 80.7208 13.51 80.1328 13.846C79.5568 14.17 79.1007 14.632 78.7648 15.232C78.4408 15.82 78.2788 16.51 78.2788 17.302C78.2788 18.094 78.4408 18.79 78.7648 19.39C79.1007 19.978 79.5568 20.44 80.1328 20.776C80.7208 21.1 81.3808 21.262 82.1128 21.262C82.7008 21.262 83.2467 21.148 83.7508 20.92C84.2548 20.692 84.6748 20.338 85.0108 19.858L85.6768 20.344C85.2928 20.92 84.7888 21.352 84.1648 21.64C83.5528 21.928 82.8688 22.072 82.1128 22.072Z" fill="#2772B9"/>
        <path d="M90.9489 22.072C90.0009 22.072 89.1609 21.868 88.4289 21.46C87.7089 21.052 87.1389 20.488 86.7189 19.768C86.3109 19.048 86.1069 18.226 86.1069 17.302C86.1069 16.366 86.2989 15.544 86.6829 14.836C87.0789 14.116 87.6189 13.552 88.3029 13.144C88.9869 12.736 89.7549 12.532 90.6069 12.532C91.4589 12.532 92.2209 12.73 92.8929 13.126C93.5769 13.522 94.1109 14.08 94.4949 14.8C94.8909 15.508 95.0889 16.33 95.0889 17.266C95.0889 17.302 95.0829 17.344 95.0709 17.392C95.0709 17.428 95.0709 17.47 95.0709 17.518H86.7729V16.816H94.5849L94.2249 17.158C94.2369 16.426 94.0809 15.772 93.7569 15.196C93.4449 14.608 93.0189 14.152 92.4789 13.828C91.9389 13.492 91.3149 13.324 90.6069 13.324C89.9109 13.324 89.2869 13.492 88.7349 13.828C88.1949 14.152 87.7689 14.608 87.4569 15.196C87.1449 15.772 86.9889 16.432 86.9889 17.176V17.338C86.9889 18.106 87.1569 18.79 87.4929 19.39C87.8409 19.978 88.3149 20.44 88.9149 20.776C89.5149 21.1 90.1989 21.262 90.9669 21.262C91.5669 21.262 92.1249 21.154 92.6409 20.938C93.1689 20.722 93.6129 20.392 93.9729 19.948L94.4949 20.542C94.0869 21.046 93.5709 21.43 92.9469 21.694C92.3349 21.946 91.6689 22.072 90.9489 22.072Z" fill="#2772B9"/>
        <path d="M101.509 22.072C100.681 22.072 99.9312 21.88 99.2592 21.496C98.5992 21.112 98.0712 20.566 97.6752 19.858C97.2912 19.138 97.0992 18.286 97.0992 17.302C97.0992 16.318 97.2912 15.472 97.6752 14.764C98.0712 14.044 98.5992 13.492 99.2592 13.108C99.9312 12.724 100.681 12.532 101.509 12.532C102.397 12.532 103.189 12.736 103.885 13.144C104.593 13.54 105.145 14.098 105.541 14.818C105.949 15.538 106.153 16.366 106.153 17.302C106.153 18.238 105.949 19.066 105.541 19.786C105.145 20.506 104.593 21.07 103.885 21.478C103.189 21.874 102.397 22.072 101.509 22.072ZM96.7932 25.492V12.604H97.6572V15.682L97.5132 17.32L97.6932 18.958V25.492H96.7932ZM101.455 21.262C102.175 21.262 102.823 21.1 103.399 20.776C103.975 20.44 104.425 19.972 104.749 19.372C105.085 18.772 105.253 18.082 105.253 17.302C105.253 16.51 105.085 15.82 104.749 15.232C104.425 14.632 103.975 14.17 103.399 13.846C102.823 13.51 102.175 13.342 101.455 13.342C100.735 13.342 100.087 13.51 99.5112 13.846C98.9472 14.17 98.4972 14.632 98.1612 15.232C97.8372 15.82 97.6752 16.51 97.6752 17.302C97.6752 18.082 97.8372 18.772 98.1612 19.372C98.4972 19.972 98.9472 20.44 99.5112 20.776C100.087 21.1 100.735 21.262 101.455 21.262Z" fill="#2772B9"/>
        <path d="M110.596 22.072C109.768 22.072 109.132 21.844 108.688 21.388C108.256 20.932 108.04 20.308 108.04 19.516V0H108.94V19.426C108.94 20.026 109.09 20.488 109.39 20.812C109.69 21.136 110.122 21.298 110.686 21.298C111.274 21.298 111.76 21.124 112.144 20.776L112.522 21.406C112.282 21.634 111.988 21.802 111.64 21.91C111.304 22.018 110.956 22.072 110.596 22.072ZM106.312 13.378V12.604H111.928V13.378H106.312Z" fill="#2772B9"/>
    </svg>
</template>

<script>
export default {
    name: "KIPConceptLogo"
}
</script>

<style scoped>

</style>
