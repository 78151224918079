<template>
    <div class="textMedium neutral900 mb-15" @click="moduleGet()">
        Возвращение миксеров
        <el-tooltip placement="bottom">
            <template #content> Здесь отображаются миксера,<br />которые скоро вернутся на завод<br /><br />Вы можете заранее замешать<br />первый куб к их приезду </template>
            <span class="tooltip-icon"><FaqIcon3/></span>
        </el-tooltip>
    </div>
    <div v-if="mixers.length === 0" class="operator-mixers-empty neutral400 textRegular">
        Нет информации
    </div>
    <div v-else>
        <div v-for="(mixer, index) in mixers" :key="index">
            <div class="d-flex-full">
                <div class="textMedium textSM neutral900">
                    {{ getVehicleNumber(mixer.vehicle_name) }}
                </div>
                <div>
                    <span class="textMedium textSM primary600">{{ getDateFromUnix(mixer.return_at, 'HH:mm', tz) }}</span>
                    <span class="textRegular textSM neutral900"> - {{ getDiff(mixer.return_at) }} мин</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FaqIcon3 from "@/views/components/Icons/FaqIcon3.vue";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: "OperatorMixers",
    components: {FaqIcon3},
    inject: ['api'],
    props: ['mixId', 'tz'],
    mixins: [functionsMixin],
    data () {
        return {
            mixers: []
        }
    },
    created() {
        this.moduleGet()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        async moduleGet() {
            this.loading = true

            try {
                const response = await httpService().post(this.api.operator.getMixers);

                let data = response.data;
                if (data.success) {
                    this.mixers = data.mixers
                    this.loading = false;
                }

            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
        getDiff(date) {
            return moment.unix(date).diff(moment(),'minutes')
        }
    }
}
</script>

<style scoped>
.tooltip-icon {
    position: relative;
    top: 2px;
    margin-left: 10px;
}
</style>
