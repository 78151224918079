<template>
    <Guest>
        <div class="card forgot">
            <div class="card-body">
                <div class="auth-block text-center">
                    <h1>Забыли пароль?</h1>
                    <p>Введите свой адрес электронной почты или номер телефона и нажмите кнопку восстановить, чтобы
                        получить
                        новый пароль</p>
                </div>
                <div class="p-20">
                    <el-form :action="api.account.forgot" method="post" id="login-form" label-position="top"
                             v-loading="loading">
                        <el-form-item label="E-mail или телефон">
                            <el-input type="text" placeholder="Ваш e-mail или телефон" id="email" name="email"
                                      v-model="email"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="w-100 guest-btn" @click.prevent="doForgot">Восстановить пароль
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <p class="text-center text-light mt-30">
                        <el-link type="primary" href="/login">Авторизация</el-link>
                    </p>
                </div>
            </div>
        </div>
    </Guest>
</template>

<script>

import axios from 'axios'
import Guest from './layouts/Guest'

export default {
    name: "Forgot",
    components: {
        Guest
    },
    data() {
        return {
            email: null,
        }
    },
    inject: [
        'api'
    ],
    methods: {
        doForgot() {
            this.$root.loadingState()

            axios.post(this.api.account.forgot, {
                email: this.email
            }).then(() => {
                this.$message({
                    message: 'Письмо с восстановлением пароля отправлено',
                    type: 'success'
                })
                this.email = null
            }).catch((error) => {
                var textError = error.response.data.message
                this.$message({
                    message: textError,
                    type: 'error'
                })
            }).finally(() => {
                this.$root.loadingState(false)
            })
        }
    }
}
</script>
