<template>
    <Auth title="Компании">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :filterShow="filterShow"
                    create-button-label="Добавить компанию"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="$router.push({name: 'CompanyCreate'})"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                    :filter-items1="individuals"
                    :filter-items2="types"
                />

                <MobileTableFramework v-for="(item, index) in values" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.short_name ? item.short_name : item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="$router.push({name: 'CompanyEdit', params: { id: item.id }})"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        width="400"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{ scope.row.short_name ? scope.row.short_name : scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="inn"
                        label="ИНН"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.inn ? 'primary600' : 'neutral400')">{{ scope.row.inn ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="fact_address"
                        label="Факт.адрес"
                    >
                        <template v-slot="scope">
                            <span class="textRegular neutral300 cutOverflow">{{ scope.row.fact_address ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="individual"
                        label="Тип компании"
                        width="150"
                    >
                        <template v-slot="scope">
                            <span class="textMedium success400 cutOverflow">{{ scope.row.individual ? 'Физ.лицо' : 'Юр.лицо' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="scope.row.integration_unit_id"
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="$router.push({name: 'CompanyEdit', params: { id: scope.row.id }})">Редактировать
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import { httpService } from '@/services/http.service'
import Filter from '@/views/components/Table/Filter/Filter.vue'
import functionsMixin from "@/mixins/functionsMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";

export default {
    name: 'CompaniesIndex',
    data() {
        return {
            loading: true,
            filter: {
                individual: {
                    label: 'Тип организации',
                    placeholder: 'Выберите тип',
                    value: null,
                    type: 'select',
                    items: '1'
                },
                type: {
                    label: 'Тип компании',
                    placeholder: 'Выберите тип',
                    value: null,
                    type: 'select',
                    items: '2'
                },
            },
            individuals: [{
                'id': false,
                'name': 'Юр.лицо'
            }, {
                'id': true,
                'name': 'Физ.лицо'
            }],
            types: [{
                'id': 'client',
                'name': 'Клиент'
            }, {
                'id': 'seller',
                'name': 'Поставщик'
            }, {
                'id': 'carrier',
                'name': 'Перевозчик'
            }]
        }
    },
    components: {
        IntegrationLogo,
        Filter,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.company.get, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.values = data.companies.data
                    this.valueLength = data.companies.total
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        filterValues() {

        }
    },
    computed: {},
    watch: {
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        }
    }
}
</script>

<style scoped>

</style>
