export default {
    data() {
        return {
            tabs: [{
                route: 'ProductionOrders',
                name: 'Заказы'
            }, {
                route: 'ProductionControl',
                name: 'ОТК'
            }, {
                route: 'ProductionValues',
                name: 'Произведено'
            }, {
                route: 'ProductionReport',
                name: 'Отчеты'
            }, {
                route: 'ProductionGoods',
                name: 'Продукция'
            }, {
                route: 'ProductionShifts',
                name: 'Смены'
            }, {
                route: 'ProductionPosts',
                name: 'Посты'
            }]
        }
    }
}
