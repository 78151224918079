<template>
    <div>
        <template v-if="loadingData">
            <TableDataLoader text="тарифов" />
        </template>
        <template v-else>
            <template v-if="!createTariffDialog">
                <TableHeadBlock v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            create-button-label="Прикрепить тариф"
                            @moduleGet="getData()"
                            @openCreateValueDrawer="() => createTariffDialog = true" />
                <el-table empty-text="Информация не найдена"
                          class="mt-15 w-100 defaultTableHeight"
                          v-loading="loadingTable"
                          @current-change="handleCurrentChange"
                          :data="tariffData">
                    <el-table-column prop="id"
                                     label="#"
                                     width="100"
                                     class-name="pointer"
                                     sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium"
                                  :class="[{ neutral200: scope.row.id !== itemId }, { primary600: scope.row.id === itemId }]">
                                #{{ formatNumber(scope.row.id) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="Название"
                                     class-name="pointer"
                                     sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="integration"
                                     label="Тип"
                                     class-name="pointer"
                                     width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.depends_on">
                                <span class="textRegular textSM neutral400">{{getInfo(scope.row.depends_on, dependsTypes)}}</span>
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <TablePagination v-model="pageSize"
                             :total="valueLength"
                             :loading="loading"
                             @action="setPage" />
            </template>
            <template v-else>
                <ServiceTariffCreateDialog
                    :defaultType="type"
                    @create="handleAddServiceTariff"
                    @close="() => createTariffDialog = false"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { httpService } from "@/services/http.service";

import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import ServiceTariffCreateDialog from "@/views/settings/serviceTariffs/ServiceTariffCreateDialog";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import serviceInfo from "@/mixins/serviceInfo";

export default {
    name: 'SelectTariffModal',
    props: ['modelValue', 'type', 'addedTariffs'],
    components: {
        TableDataLoader, TableHeadBlock, TablePagination, ServiceTariffCreateDialog
    },
    mixins: [tableMixin, functionsMixin, serviceInfo],
    inject: ['api'],
    data() {
        return {
            loadingData: false,
            createTariffDialog: false,
            search: '',
            tariffData: [],
            loading: false,
            loadingTable: false,
            pageSize: 10,
            itemId: null,
            goodTypes: []
        };
    },
    methods: {
        getData() {
            this.loadingTable = true
            httpService().post(this.api.serviceTariff.getAll, {
                paginate: false,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                type: this.type,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    // const filtered
                    if(!this.addedTariffs.length) {
                        this.tariffData = data.items
                        this.valueLength = data.items.length
                        return
                    }
                    // исключаем выбранные варинты
                    const filtered= data.items.filter(item =>
                        !this.addedTariffs.some(tariff => item.id === tariff.id)
                    );
                    this.tariffData = filtered
                    this.valueLength = filtered.length
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loadingTable = false
            })
        },
        handleCurrentChange(val) {
            this.itemId = val.id;
            this.$emit('add-new-row', val)
        },
        filterValues() {
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleAddServiceTariff(output) {
            this.createTariffDialog = false;
            this.$emit('add-new-row', output)
        },
    },
    watch: {
        modelValue: {
            handler() {
                this.companyCreateFormShow = this.modelValue
            }
        },
        pageSize() {
            this.getData()
        },
        page() {
            this.getData()
        },
        search() {
            this.getData()
        },
    },
    mounted() {
        this.getData()
    }
};
</script>
