<template>
  <svg width="74" height="48" viewBox="0 0 74 48" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="74" height="48" fill="white"/>
    <rect width="6" height="8" rx="3" transform="matrix(1 0 0 -1 4 44)" fill="#EEEEF0"/>
    <rect width="6" height="19" rx="3" transform="matrix(1 0 0 -1 14 44)" fill="#EEEEF0"/>
    <rect x="24" y="7" width="6" height="37" rx="3" fill="#EEEEF0"/>
    <rect x="34" y="25" width="6" height="19" rx="3" fill="#EEEEF0"/>
    <rect x="44" y="10" width="6" height="34" rx="3" fill="#EEEEF0"/>
    <rect x="54" y="25" width="6" height="19" rx="3" fill="#EEEEF0"/>
    <rect x="64" y="32" width="6" height="12" rx="3" fill="#EEEEF0"/>
  </svg>
</template>
<script>
export default {
  name: 'BarEmpty',
  props: {
    data: {}
  }
};
</script>
