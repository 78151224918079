<template>
    <el-option
        value="newItemOption"
        label="newItemOption"
        class="newItemOption"
        @click="openPage"
    >
        <AddRoundIcon/>
        <span>Создать новый</span>
    </el-option>
</template>

<script>
import AddRoundIcon from './Icons/AddRoundIcon'

export default {
    props: [
        'path',
    ],
    name: "NewItemOption",
    components: {AddRoundIcon},
    methods: {
        openPage() {
            this.$router.push({ name: this.path, params: { openDrawer: 'open' } })
        }
    }
}
</script>

<style scoped>

</style>
