<template>
    <Auth title="Посты" :tabs="tabs">
        <div class="card">
            <div class="card-body">
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-filter="true"
        :disable-search="true"
        create-button-label="Добавить пост"
        @moduleGet="getAll()"
        @openCreateValueDrawer="postCreateDialog=true"
    />

    <div class="mt-15 orderGroupsTable">

        <el-tabs type="border-card">
            <el-tab-pane :label="post.name + ' (' + post.tasks.length + ')'" v-for="(post, index) in values" :key="index">

                <el-row :gutter="20">

                    <el-col :sm="8">
                        <h3>Очередь заданий</h3>

                        <div class="orderGroupItem" v-for="(task, index) in post.tasks.filter(el => el.status === 'new')" :key="index">
                            <h4>{{ getData(task.goodId, 'good') }}</h4>
                            <el-button @click="doStep(task.actualStep)">
                                <template v-if="task.actualStep.status === 'new'">Взять в работу</template>
                                <template v-if="task.actualStep.status === 'work'">Подтвердить выполнение</template>
                                <template v-if="task.actualStep.status === 'waitMoveStorehouse'">Переместить на склад</template>
                            </el-button>
                        </div>
                    </el-col>

                    <el-col :sm="16">
                        <h3>Задания в работе</h3>

                        <div class="orderGroupItem" v-for="(task, index) in post.tasks.filter(el => el.status !== 'new')" :key="index">
                            <h4>{{ getData(task.goodId, 'good') }}</h4>
                            <el-button type="primary" @click="doStep(task.actualStep)">
                                <template v-if="task.actualStep.status === 'new'">Взять в работу</template>
                                <template v-if="task.actualStep.status === 'work'">Подтвердить выполнение</template>
                                <template v-if="task.actualStep.status === 'waitMoveStorehouse'">Переместить на склад</template>
                            </el-button>
                        </div>
                    </el-col>

                </el-row>
            </el-tab-pane>
        </el-tabs>

    </div>
            </div>
        </div>

    <el-dialog
        v-model="postCreateDialog"
        title="Новый производственный пост"
        :width="mobile ? '100%' : '65%'"
        :destroy-on-close="true"
    >
        <el-form label-position="top">

            <FormInput
                label="Название поста"
                placeholder="Введите название"
                v-model="form.name"
            />

            <el-button type="primary" @click="create()" class="w-100" :disabled="!form.name">Создать пост</el-button>

        </el-form>
    </el-dialog>
    </Auth>

</template>

<script>
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import FormInput from '@/views/components/Form/FormInput.vue'
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionPostsIndex',
    components: {Auth, FormInput, TableHeadBlock},
    mixins: [mobileCheckMixin, tableMixin, functionsMixin, productionTabs],
    inject: ['api'],
    props: ['tab'],
    data() {
        return {
            loading: false,
            postCreateDialog: false,
            form: {
                name: null
            }
        }
    },
    created() {
        this.getAll()
    },
    methods: {
        getAll() {
            this.loading = true

            httpService().post(this.api.productionPost.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        remove(id) {
            this.$confirm(
                'Вы действительно хотите удалить данный пост?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionPost.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        id: id
                    }).then(() => {
                        this.$message({
                            message: 'Пост удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        doStep(value) {
            this.$confirm(
                'Вы подтверждаете выполнение задания?',
                'Подтвердите выполнение',
                {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionOrderStep.do, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        value: value
                    }).then(() => {
                        this.$message({
                            message: 'Задание выполнено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Выполнение отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        create() {
            this.loading = true

            httpService().post(this.api.productionPost.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Пост добавлен',
                        showClose: true,
                        type: 'success'
                    })

                this.getAll()
                this.postCreateDialog = false
                this.form.name = null
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values
            this.valueLength = this.filtered.length
            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        tab() {
            if (this.tab === 'posts') this.getAll()
        },
    }
}
</script>

<style scoped>

</style>
