<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="'Производственная бригада - ' + editShiftForm.name"/>

            <el-form ref="form" :model="editShiftForm" size="small" label-position="top">

                <FormInput
                    v-model="editShiftForm.name"
                    label="Название бригады"
                    placeholder="Бригада Сменова И.В."
                />

                <FormSelectMultiRow
                    v-model="editShiftForm.members"
                    label="Участники"
                    placeholder="Выберите пользователя"
                    new-item-path="UsersCreate"
                    :items="users"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton :loading="loading" title="Сохранить изменения" @action="createValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import FormInput from '../../Form/FormInput.vue'
import DrawerFramework from '../../Drawer/DrawerFramework.vue'
import DrawerCreateButton from '../../Drawer/Buttons/DrawerCreateButton.vue'
import DrawerHeadTitle from '../../Drawer/DrawerHeadTitle.vue'
import FormSelectMultiRow from '../../Form/FormSelectMultiRow.vue'
import { httpService } from '@/services/http.service'

export default {
    components: { FormSelectMultiRow, DrawerHeadTitle, DrawerCreateButton, DrawerFramework, FormInput },
    props: [
        'shift',
    ],
    name: 'ProductionShiftEdit',
    created() {
        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users
        })
        this.editShiftForm = this.shift
    },
    data() {
        return {
            loading: false,
            editShiftForm: {
                name: '',
                users: [{
                    'id': null
                }],
            },
            users: []
        }
    },
    inject: [
        'api'
    ],
    methods: {
        createValue() {
            this.loading = true

            httpService().post(this.api.productionShift.create, {
                shift: this.editShiftForm,
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then(() => {
                this.$message({
                    message: 'Изменения сохранены',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>

</style>
