<template>
    <el-row :gutter="20" class="mt-30">
        <el-col :sm="8">
            <div class="moduleSettingsInfoRow">
                <slot name="info"></slot>
            </div>
        </el-col>
        <el-col :sm="16" class="moduleSettingsContentRow">
            <slot name="content"></slot>
        </el-col>
    </el-row>
    <hr/>
</template>

<script>
export default {
    name: "ModuleSettingsRow"
}
</script>

<style scoped>

</style>
