export default {
    data() {
        return {
            timezones: [{
                id: -1,
                name: 'GMT+2 (Калининград)'
            }, {
                id: 0,
                name: 'GMT+3 (Москва)'
            }, {
                id: 1,
                name: 'GMT+4 (Самара)'
            }, {
                id: 2,
                name: 'GMT+5 (Екатеринбург)'
            }, {
                id: 3,
                name: 'GMT+6 (Омск)'
            }, {
                id: 4,
                name: 'GMT+7 (Красноярск)'
            }, {
                id: 5,
                name: 'GMT+8 (Иркутск)'
            }, {
                id: 6,
                name: 'GMT+9 (Якутск)'
            }, {
                id: 7,
                name: 'GMT+10 (Владивосток)'
            }, {
                id: 8,
                name: 'GMT+11 (Магадан)'
            }, {
                id: 9,
                name: 'GMT+12 (Камчатка)'
            }],
        }
    },
}
