<template>
    <el-tooltip effect="dark" content="Удалить" placement="top">
        <el-button
            size="mini"
            @click="doAction"
            :icon="icons.delete"
            :loading="loading"/>
    </el-tooltip>
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "DrawerDeleteButton",
    props: [
        'loading',
    ],
    mixins: [
        iconsMixin
    ],
    methods: {
        doAction() {
            this.$emit('action')
        }
    }
}
</script>

<style scoped>

</style>
