<template>
    <template v-if="!loading">
        <TechDetailsTopBlock
            :tech-details="techDetails"
            @getTechDetails="getTechDetails()"
            @moduleGet="$emit('moduleGet')"
        />
        <div class="techDetailsInfo" v-if="techDetails">

            <div class="techDetailsInfoIndicators">

                <div class="techDetailsInfoIndicatorsBlock">
                    <div class="item gps">
                        <div class="item-body">
                            <span class="topText">Местоположение</span>
                            <span class="no-service">
                            <p>Нет подключения к GPS мониторингу</p>
                            <el-button type="info" plain disabled>Настроить</el-button>
                        </span>
                        </div>
                    </div>
                </div>
                <div class="techDetailsInfoIndicatorsBlock">

                    <div class="d-flex mb-20">

                        <div class="item fuel pointer" @click="addFuelDialog=true" v-show="false">
                            <div class="item-body">
                                <span class="topText">Топливо</span>
                                <span class="mainText">
                    <AnimateNumber :count="techDetails.fuel_total"/>
                    л
                    <el-progress :stroke-width="12" :percentage="fuelPercentage()" color="#FF5C5E"></el-progress>
                </span>
                                <span
                                    class="bottomText">В среднем <strong>8 л.</strong> в день<br/>Хватит примерно на <strong>7 дней</strong></span>
                            </div>
                        </div>

                        <div class="item mileage pointer" @click="addMileageDialog=true">
                            <div class="item-body">
                                <span class="topText">Пробег</span>
                                <div class="mainText">
                                    <template v-if="techDetails.mileage">
                                        <AnimateNumber
                                            :count="techDetails.mileage"
                                        />
                                        <span> км.</span>
                                    </template>
                                    <template v-if="techDetails.engine_hours">
                                        <span v-if="techDetails.mileage"> / </span>
                                        <AnimateNumber
                                            :count="techDetails.engine_hours"
                                        />
                                        <span> мтч.</span>
                                    </template>
                                </div>
                                <span class="bottomText">{{
                                        getAverageMileage()
                                    }} км. в день в среднем</span>
                            </div>
                        </div>
                    </div>

                    <div class="item service pointer" @click="$emit('serviceActive')">
                        <div class="item-body">
                            <span class="topText">Сервис</span>
                            <span class="mainText">
                                    -
                                </span>
                            <span class="bottomText">Технические работы не обнаружены</span>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </template>
    <template v-else>
        <div id="emptyState">
            <div class="text-center">
                <ReportLoader/>
            </div>
        </div>
    </template>
    <el-dialog
        v-model="addMileageDialog"
        title="Изменить значения пробега для транспорта"
        :width="drawerSize"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
    >
        <TechChangeMileage
            :tech="techDetails"
            @closeDialog="closeDialog()"
            @moduleGet="getTechDetails()"
        />
    </el-dialog>

    <el-dialog
        v-model="addFuelDialog"
        title="Изменить значение топлива и пробега"
        :width="drawerSize"
        custom-class="changeTechDialog"
        :destroy-on-close="true"
    >
        <TechChangeFuel
            :vehicle-id="techDetails.id"
            @closeDialog="closeDialog()"
            @moduleGet="getTechDetails()"
        />
    </el-dialog>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import AnimateNumber from "@/views/components/AnimateNumber";
import TechDetailsTopBlock from "@/views/components/TechPage/Garage/TechDetails/TechDetailsTopBlock";
import TechChangeMileage from "@/views/components/TechPage/Garage/TechChangeMileage";
import TechChangeFuel from "@/views/components/TechPage/Garage/TechChangeFuel";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'TechDetailsInfo',
    components: {ReportLoader, TechChangeFuel, TechChangeMileage, TechDetailsTopBlock, AnimateNumber},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [mobileCheckMixin],
    data() {
        return {
            techDetails: null,
            addMileageDialog: false,
            addFuelDialog: false,
            mileageHistory: [],
            loading: true
        }
    },
    created() {
        this.getTechDetails()
    },
    methods: {
        getTechDetails() {
            this.loading = true
            httpService().post(this.api.tech.getTechDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.techDetails = data.details
                    this.mileageHistory = data.mileageHistory
                    this.loading = false
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        fuelPercentage() {
            if (this.techDetails.fuel_total && this.techDetails.fuel_tank) {
                return Math.round(this.techDetails.fuel_total / this.techDetails.fuel_tank * 100)
            } else {
                return 0
            }
        },
        closeDialog() {
            this.addFuelDialog = false
            this.addMileageDialog = false
            this.getTechDetails()
        },
        getAverageMileage() {
            let result = this.mileageHistory.reduce(function (sum, elem) {
                return sum + elem
            }, 0);

            return Math.round(result / 6)
        }
    }
}
</script>

<style scoped>

</style>
