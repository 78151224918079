<template>
    <div class="map-container">
        <div v-if="isLoading" class="text-center mt-30">
            <ReportLoader/>
            <h3>Загрузка данных</h3>
        </div>
        <div :class="isLoading ?  'hideMap' : ''">
            <div class="p-relative">
                <div id="map" style="height: 70vh; width: 100%; border-radius: 15px"></div>
                <div class="pointInfo">
                    <div class="pointInfoBody" v-if="pointInfo">
                        <div class="textXS neutral200">
                            Время
                        </div>
                        <div class="textSM textMedium primary600">
                            {{ moment(pointInfo.date).format('HH:mm') }}
                        </div>
                        <hr/>
                        <div class="textXS neutral200">
                            Скорость
                        </div>
                        <div class="textSM textMedium neutral900">
                            {{ formatNumber(pointInfo.speed) }} км/ч
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100" style="display: flex; justify-content: center;">
                <div style="width: 80%">
                    <el-slider
                        class="mt-5 mb-5"
                        v-model="checkpoint"
                        :max="points.length ? points.length - 1 : 100"
                        :marks="marks"
                        :show-tooltip="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<style>
.hideMap {
    opacity: 0;
    position: absolute;
    top: -10000px;
    left: -10000px;
}

.map-container {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>


<script>
/* eslint-disable */
// import {fromLonLat} from "ol/proj";
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import {GoogleMap, Marker, Polyline} from "vue3-google-map";
import moment from "moment/moment";


export default {
    name: "LogisticsInfoMapFull",
    props: ['order', 'accountModuleReserveId', 'title', 'height', 'color'],
    emits: ['hideWindow'],
    mixins: [functionsMixin],
    components: {GoogleMap, Marker, Polyline, ReportLoader},
    data() {
        return {
            glonass_active: true,
            isLoading: false,
            initialCenter: {
                lat: 55.7522200,
                lng: 37.6155600
            },
            map: null,
            checkpoint: 0,
            points: [],
            directionsService: null,
            directionsDisplay: null,
            googleMapApiKey: "AIzaSyDkcV88IudVAW2DdCBkCl7Ilk9ddKRxpQQ",
            coorLatFrom: 0,
            coorLonFrom: 0,
            coorLatTo: 0,
            coorLonTo: 0,
            vehicleNumber: '',
            orderStartDate: null,
            orderEndDate: null,
            vehicle: {
                id: null,
                number: null,
                lat: 0,
                lon: 0,
                marker: null,
                route_points: []
            },
            marks: {}
        }
    },
    computed: {
        moment() {
            return moment
        },
        getFromPosition() {
            return {lat: Number(this.coorLatFrom), lng: Number(this.coorLonFrom)}
        },
        getToPosition() {
            return {lat: Number(this.coorLatTo), lng: Number(this.coorLonTo)}
        },
        getVehiclePosition() {
            return {
                lat: Number(this.vehicle.lat),
                lng: Number(this.vehicle.lon)
            }
        },
        getGooleRoutePolyline() {
            return {
                path: this.route_points,
                geodesic: true,
                strokeColor: "#0818ef",
                strokeOpacity: 1.0,
                strokeWeight: 4,
            };
        },
        pointInfo() {
            return this.points[this.checkpoint]
        }
    },
    async mounted() {
        this.isLoading = true
        this.scriptElement = document.createElement('script');
        this.scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapApiKey}&language=ru&callback=initMap`;
        this.scriptElement.defer = true;
        this.scriptElement.async = true;
        document.head.appendChild(this.scriptElement);
        window.initMap = this.initMapCallback;
    },
    methods: {
        initMapCallback() {
            // eslint-disable-next-line no-undef
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.initialCenter,
                zoom: 6,
                disableDoubleClickZoom: true,
                fullscreenControl: false,
                rotateControl: false,
                panControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                language: "ru",
                region: "RU",
            });

            // eslint-disable-next-line no-undef
            this.directionsService = new google.maps.DirectionsService();
            // eslint-disable-next-line no-undef
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                polylineOptions: {
                    strokeOpacity: 0.8,
                    strokeColor: '#8a909a',
                    strokeWeight: 5,
                },
            });
            this.directionsDisplay.setMap(this.map);
            this.initOrderData()
        },
        initOrderData() {
            httpService().post(this.api.mix.getOneOrder, {
                account_module_reserve_id: this.accountModuleReserveId,
                module_key: "mix",
                orderId: this.order.id,
                // with_google_route: true
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.coorLatFrom = data.order.factory.latitude
                    this.coorLonFrom = data.order.factory.longitude

                    this.vehicle.number = data.order.vehicle.number
                    this.vehicle.id = data.order.vehicle.id

                    const startDate = data.order?.timeline?.find(el => el.status === "loading")
                    if (startDate) this.orderStartDate = startDate?.dateDB

                    const endDate = data.order?.timeline?.find(el => el.status === "done")
                    if (endDate) this.orderEndDate = endDate?.dateDB

                    if (data.order.deliveryAddress) {
                        this.coorLatTo = data.order.deliveryAddress.latitude
                        this.coorLonTo = data.order.deliveryAddress.longitude
                        this.drawRouteTwoPoints(this.getFromPosition, this.getToPosition)
                    }
                    this.initVehicle()
                } else {
                    this.$message({
                        message: "Для данного заказа не указан адрес назначения и/или адрес отправки",
                        type: 'error'
                    })
                    this.$emit('hideWindow')
                }

            })
        },
        initVehicle() {
            httpService().post(this.api.mix.getOneMixerGlonassData, {
                account_module_reserve_id: this.accountModuleReserveId,
                vehicleId: this.vehicle?.id,
                module_key: 'mix',
                from_date: this.orderStartDate,
                to_date: this.orderEndDate,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    if (data.glonass_active) {

                        if (data.glonass_route_points.length) {

                            this.vehicle.route_points = data.glonass_route_points.map((point) => ({
                                lat: point.latitude,
                                lng: point.longitude
                            }));
                            this.points = data.glonass_route_points ?? []
                            let count = data.glonass_route_points.length
                            let elemNumber = count - 1
                            this.checkpoint = elemNumber

                            if (this.order.status === 'delivery' || this.order.status === 'object' || this.order.status === 'pouring' || this.order.status === 'return' || this.order.status === 'done') {
                                let closestIndex = this.findPoint(this.order.load_at);

                                if (closestIndex) {
                                    this.marks = {
                                        ...this.marks,
                                        [closestIndex]: 'Доставка'
                                    }
                                }
                            }
                            if (this.order.status === 'object' || this.order.status === 'pouring' || this.order.status === 'return' || this.order.status === 'done') {
                                let closestIndex = this.findPoint(this.order.arrive_at);

                                if (closestIndex) {
                                    this.marks = {
                                        ...this.marks,
                                        [closestIndex]: 'На объекте'
                                    }
                                }
                            }
                            if (this.order.status === 'return' || this.order.status === 'done') {
                                let closestIndex = this.findPoint(this.order.unload_at);

                                if (closestIndex) {
                                    this.marks = {
                                        ...this.marks,
                                        [closestIndex]: 'Возвращение'
                                    }
                                }
                            }
                            if (this.order.status === 'done') {
                                let closestIndex = this.findPoint(this.order.return_at);

                                if (closestIndex) {
                                    this.marks = {
                                        ...this.marks,
                                        [closestIndex]: 'На заводе'
                                    }
                                }
                            }
                            this.drawVehicleRoute()
                        }

                        this.vehicle.lat = data.latitude
                        this.vehicle.lon = data.longitude
                        this.drawVehicle()
                    } else {
                        this.glonass_active = false
                    }
                } else {
                    this.$message({
                        message: 'Возникла ошибка получения данных по GPS. Возможно отсутствует сигнал на ТС',
                        type: 'error',
                        showClose: true,
                    })
                    setTimeout(() => {
                        this.$emit('hideWindow')
                    }, 150)
                }
                this.isLoading = false
            }).catch(() => {
                this.$message({
                    message: 'Возникла ошибка получения данных по GPS. Возможно отсутствует сигнал на ТС',
                    type: 'error',
                    showClose: true,
                })
                setTimeout(() => {
                    this.$emit('hideWindow')
                }, 150)
            })
        },
        drawVehicle() {
            const markerIcon = require('@/assets/vehicle.svg');
            const marker = new google.maps.Marker({
                position: this.getVehiclePosition,
                map: this.map,
                icon: markerIcon,
            });
            this.vehicle.marker = marker
        },
        findPoint(date) {
            const targetDate = moment.utc(date);
            let closestIndex = null;

            if (this.points) {
                closestIndex = Object.keys(this.points).reduce((a, b) => {
                    const dateA = moment(this.points[a].date);
                    const dateB = moment(this.points[b].date);
                    const diffA = Math.abs(dateA.diff(targetDate));
                    const diffB = Math.abs(dateB.diff(targetDate));
                    return diffA < diffB ? a : b;
                });
            }
            return closestIndex;
        },
        drawVehicleRoute() {
            if (this.order.status === 'return' || this.order.status === 'done') {
                const closestIndex = this.findPoint(this.order.arrive_at);
                const routePath = this.vehicle.route_points.map((point) => new google.maps.LatLng(point.lat, point.lng));

                const firstHalf = routePath.slice(0, closestIndex);
                const secondHalf = routePath.slice(closestIndex);

                const firstHalfPolyline = new google.maps.Polyline({
                    path: firstHalf,
                    geodesic: true,
                    strokeColor: '#49a7fd', // Красный цвет линии
                    strokeOpacity: 0.6,
                    strokeWeight: 4,
                });

                firstHalfPolyline.setMap(this.map);

                const secondHalfPolyline = new google.maps.Polyline({
                    path: secondHalf,
                    geodesic: true,
                    strokeColor: '#1984e6', // Зеленый цвет линии
                    strokeOpacity: 0.8,
                    strokeWeight: 6,
                });

                secondHalfPolyline.setMap(this.map);
            } else {
                const routePath = this.vehicle.route_points.map((point) => new google.maps.LatLng(point.lat, point.lng));

                const routePolyline = new google.maps.Polyline({
                    path: routePath,
                    geodesic: true,
                    strokeColor: '#1984e6', // Красный цвет линии
                    strokeOpacity: 0.8, // Прозрачность линии
                    strokeWeight: 6, // Толщина линии
                });

                routePolyline.setMap(this.map);
            }
        },
        clearVehicle() {

        },
        clearVehicleRoute() {
            const mapPolylines = this.map.getPolylines();
            mapPolylines.forEach((polyline) => {
                polyline.setMap(null);
            });
        },
        drawRouteTwoPoints(startPoint, endPoint) {
            const request = {
                origin: startPoint,
                destination: endPoint,
                travelMode: 'DRIVING',
            };

            this.directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    this.directionsDisplay.setDirections(result);
                } else {
                    console.error('Ошибка: ' + status);
                }
            });
        }
    },
    watch: {
        checkpoint: function (val) {
            if (val) {
                let point = this.points[val]
                if (point) {
                    const newPosition = new google.maps.LatLng(point.latitude, point.longitude);
                    this.vehicle.marker.setPosition(newPosition);
                }

            }
        },
    }
}
</script>

<style scoped>

</style>
