<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <DistributeOrderGroup
                    :orders="distributeOrders"
                    :metrics="metrics"
                    @create="createOrderGroup"
                    @update="updateOrderGroup"
                />
            </div>
        </div>
    </Auth>
</template>
<script>
import {httpService} from "@/services/http.service";
import Auth from "@/views/layouts/Auth.vue";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import DistributeOrderGroup from "@/views/dispatch/DistributeOrderGroup.vue";

export default {
    name: "ReDistributeOrderGroup",
    mixins: [dispatchTabs],
    inject: ['api'],
    components: {
        DistributeOrderGroup,
        Auth,
    },
    data() {
        return {
            distributeOrders: [],
            metrics: [],
            routerLoading: true,
        }
    },
    async created() {
        await this.getSchedules()
        await this.getDistribute()
    },
    methods: {
        createOrderGroup() {
            this.routerLoading = false

            httpService().post(this.api.dispatch.orderGroups.distributeSaveOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            })

            setTimeout(() => this.$router.push({
                name: 'DispatchOrderGroups',
                params: {id: this.$route.params.id}
            }), 1000)
        },
        updateOrderGroup() {
            this.$router.push(
                {
                    name: 'DispatchOrderGroupsUpdate',
                    params: {
                        groupId: this.$route.params.groupId,
                    }
                }
            );
        },
        async getDistribute() {
            httpService().post(this.api.dispatch.orderGroups.distributeNotSaveOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then(({data}) => {
                if (data.success) {
                    this.distributeOrders = data.orders;
                    this.metrics = data.metrics;
                }
            })
        },
        async getSchedules() {
            await httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.schedules = response.data
                }
            })
        },
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
        }),
    },
}
</script>
<style scoped lang="scss">

</style>
