<template>
    <span
        class="goodUnit"
        v-html="goodUnit"
        v-bind:class="{ paddingUnit: unit === 'м3.' && paddingUnit }"
    ></span>
</template>

<script>
export default {
    name: 'goodUnit',
    props: [
        'unit',
        'paddingUnit'
    ],
    data() {
        return {
            goodUnit: null
        }
    },
    watch: {
        'unit': {
            handler: function () {
                if (this.unit === 'м3.') {
                    this.goodUnit = 'м³'
                } else {
                    this.goodUnit = this.unit
                }
                if (!this.unit) this.goodUnit = 'ед.'
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.goodUnit {
    position: relative;
}
.paddingUnit {
    padding-right: 7px;
}
</style>
