<template>
    <Auth title="Тарифы">
        <div class="card">
            <div class="card-body" v-loading="loading">
                <div class="plans">
                    <el-row :gutter="24">
                        <el-col :class="{'mb-30': mobile}" :span="mobile ? 24 : 8" v-for="(plan, index) in plans" :key="index">
                            <PaymentPlanCard
                                v-model="selectedPeriod"
                                :tariff="plan"
                                @updateTariffs="updateTariffs"
                                @setLoadingStatus="setLoadingStatus"
                            />
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from "@/views/layouts/Auth.vue";
import PaymentPlanCard from "@/views/components/SettingsPage/PaymentAndPlansPage/PaymentPlanCard.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "PaymentPlansIndex",
    components: {
        PaymentPlanCard,
        Auth
    },
    inject: ['api'],
    mixins: [functionsMixin, mobileCheckMixin],
    data() {
        return {
            loading: true,
            plans: [],
            selectedPeriod: 6
        }
    },
    mounted() {
        this.getTariffs();
    },
    methods: {
        getTariffs() {
            httpService().post(this.api.settings.getTariffs).then((response) => {
                let data = response.data;
                this.updateTariffs(data.data);
            })
        },

        updateTariffs(data) {
            this.plans = data.plans;
            this.selectedPeriod = data.current_period
            this.loading = false;
        },

        setLoadingStatus(status) {
            this.loading = status;
        }
    }

}
</script>

<style scoped>

</style>
