<template>
    <h3>{{ details.good ? details.good.name : 'Подробности взвешивания' }}</h3>
    <el-form ref="form" v-loading="loading" :model="editWeighingForm" size="small" label-position="top"
             label-width="120px">
        <el-form-item label="Груз">
            <el-select v-model="editWeighingForm.good_id" filterable clearable placeholder="Груз/продукция">
                <el-option v-for="(good, index) in goods" :key="index" :label="good.name"
                           :value="good.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Тара">
            <el-input v-model="editWeighingForm.tara" type="number" placeholder="Н-р: 12000"></el-input>
        </el-form-item>
        <el-form-item label="Брутто">
            <el-input v-model="editWeighingForm.brutto" type="number" placeholder="Н-р: 21000"></el-input>
        </el-form-item>
        <el-form-item label="Нетто">
            <el-input v-model="editWeighingForm.netto" type="number" placeholder="Н-р: 9000"></el-input>
        </el-form-item>
        <el-form-item label="Тип операции">
            <el-select v-model="editWeighingForm.weighing_operation_id" filterable clearable placeholder="Тип операции">
                <el-option v-for="(operation, index) in operations" :key="index" :label="operation.name"
                           :value="operation.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Отправитель">
            <el-select v-model="editWeighingForm.sender_company_id" filterable clearable
                       placeholder="Отправитель груза">
                <NewItemOption path="CompanyCreate"/>
                <el-option v-for="(company, index) in companies" :key="index" :label="company.name"
                           :value="company.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Получатель">
            <el-select v-model="editWeighingForm.recipient_company_id" filterable clearable
                       placeholder="Получатель груза">
                <NewItemOption path="CompanyCreate"/>
                <el-option v-for="(company, index) in companies" :key="index" :label="company.name"
                           :value="company.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="№ документа">
            <el-input v-model="editWeighingForm.doc_number" placeholder="Номер ТТН или другой"></el-input>
        </el-form-item>
        <el-form-item label="ТС">
            <el-select v-model="editWeighingForm.weighing_vehicle_id" filterable clearable
                       placeholder="Транспортное средство">
                <NewItemOption path="SettingsVehicleIndex"/>
                <el-option v-for="(vehicle, index) in vehicles" :key="index" :label="vehicle.number"
                           :value="vehicle.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Водитель">
            <el-select v-model="editWeighingForm.weighing_driver_id" filterable clearable placeholder="Водитель">
                <el-option v-for="(driver, index) in drivers" :key="index" :label="driver.name"
                           :value="driver.id"></el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <div class="footer">
        <el-button :loading="loading" type="primary" icon="el-icon-plus" class="w-100"
                   @click.prevent="editWeighing">Сохранить изменения
        </el-button>
    </div>
</template>

<script>
import NewItemOption from '../NewItemOption'
import getTimezone from '@/mixins/getTimezone'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    components: { NewItemOption },
    props: {
        operations: Array,
        details: Array
    },
    name: 'WeighingEdit',
    data() {
        return {
            loadingButton: false,
            loading: true,
            vehicles: [],
            drivers: [],
            editWeighingForm: {
                id: +this.details.id,
                tara: null,
                brutto: null,
                netto: null,
                weighing_operation_id: null,
                weighing_vehicle_id: null,
                weighing_driver_id: null,
                doc_number: null,
                sender_company_id: null,
                recipient_company_id: null,
                good_id: null,
                date_create: null,
            },
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        getTimezone
    ],
    created() {
        this.getValue()
        this.getDrivers()
        this.getVehicles()
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
        })
    },
    methods: {
        getValue() {
            httpService().post(this.api.weighingValue.getOne, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                valueId: this.details.id,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.editWeighingForm.tara = data.value.tara
                this.editWeighingForm.brutto = data.value.brutto
                this.editWeighingForm.netto = data.value.netto
                this.editWeighingForm.weighing_operation_id = data.value.operation ? data.value.operation.id : null
                this.editWeighingForm.weighing_vehicle_id = data.value.vehicle ? data.value.vehicle.id : null
                this.editWeighingForm.weighing_driver_id = data.value.driver ? data.value.driver.id : null
                this.editWeighingForm.doc_number = data.value.doc_number
                this.editWeighingForm.sender_company_id = data.value.sender ?? null
                this.editWeighingForm.recipient_company_id = data.value.recipient ?? null
                this.editWeighingForm.good_id = data.value.good ?? null
                this.editWeighingForm.date_create = data.value.created_at

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                // this.$router.push({name: 'logout'})
            })
        },
        editWeighing() {
            this.loading = true
            this.loadingButton = true

            httpService().post(this.api.weighingValue.update, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                module: this.editWeighingForm,
                weighing_value_id: this.weighing_value_id
            }).then(() => {
                this.$message({
                    message: 'Взвешивание обновлено',
                    showClose: true,
                    type: 'success'
                })

                this.editWeighingForm = {
                    tara: '',
                    brutto: '',
                    netto: '',
                    weighing_operation_id: '',
                    weighing_vehicle_id: '',
                    weighing_driver_id: '',
                    doc_number: '',
                    sender_company_id: '',
                    recipient_company_id: '',
                    good_id: '',
                    date_create: '',
                }
                this.$emit('moduleGet')
                this.$emit('closeEdit')
                this.loading = false
                this.loadingButton = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        getDrivers() {
            httpService().post(this.api.weighing.getDrivers, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.drivers = data.drivers

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        getVehicles() {
            httpService().post(this.api.settings.getVehicles, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.data
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        }
    },
    watch: {
        details() {
            this.weighing_value_id = this.details.id
        },
        immediate: true,
        deep: true
    }
}
</script>

<style scoped>

</style>
