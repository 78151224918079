export default {
    data() {
        return {
            windowWidth: null,
            mobile: false,
            smallMobile: false,
            mobileNav: false,
            labelPosition: 'left',
            drawerSize: '30%',
            dialogSize: '100%'
        }
    },
    created() {
        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth)
            this.getWindowWidth()
        })
    },
    methods: {
        getWindowWidth() {
            // проверяет текущую ширину экрана
            this.windowWidth = document.documentElement.clientWidth
            this.mobile = this.windowWidth <= 1024
            this.smallMobile = this.windowWidth <= 520

            if (this.windowWidth >= 1500) {
                this.mobileNav = false
                this.labelPosition = 'left'
                this.drawerSize = '30%'
                this.dialogSize = '65%'
            }

            if (this.windowWidth > 1024 && this.windowWidth < 1500) {
                this.mobileNav = false
                this.labelPosition = 'left'
                this.drawerSize = '30%'
                this.dialogSize = '95%'
            }

            if (this.windowWidth > 1024 && this.windowWidth <= 1280) {
                this.drawerSize = '45%'
            }

            if (this.windowWidth > 1281) {
                this.drawerSize = '40%'
            }

            if (this.windowWidth > 765 && this.windowWidth <= 1024) {
                this.labelPosition = 'left'
                this.drawerSize = '50%'
                this.dialogSize = '100%'
            }

            if (this.windowWidth <= 765) {
                this.labelPosition = 'top'
                this.drawerSize = '100%'
                this.dialogSize = '100%'
            }
        },
    }
}
