<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.16004 22C3.98004 22 3.14004 19.47 4.50004 16.39L8.75004 6.74H8.45004C7.80004 6.74 7.20004 6.48 6.77004 6.05C6.33004 5.62 6.07004 5.02 6.07004 4.37C6.07004 3.07 7.13004 2 8.44004 2H15.55C16.21 2 16.8 2.27 17.23 2.7C17.79 3.26 18.07 4.08 17.86 4.95C17.59 6.03 16.55 6.74 15.44 6.74H15.28L19.5 16.4C20.85 19.48 19.97 22 15.83 22H8.16004Z"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M5.93994 13.1201C5.93994 13.1201 8.99994 13.0001 11.9999 14.0001C14.9999 15.0001 17.8299 13.1101 17.8299 13.1101"
            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "LabIcon"
}
</script>

<style scoped>

</style>
