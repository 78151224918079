<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle title="Создание операции" />

            <el-form size="medium" v-model="form" label-position="top">
                <el-form-item v-if="!form.income" label="Выберите вид операции">
                    <el-button
                        @click="form.income = true"
                        :icon="icons.plus"
                        class="cashboxSelectAddition"
                    >
                        Поступление
                    </el-button>
                    <el-button
                        @click="form.income = false"
                        :icon="icons.minus"
                        class="cashboxSelectSubtraction"
                    >
                        Списание
                    </el-button>
                </el-form-item>

                <template v-if="form.income !== null">
                    <FormInput
                        v-model="form.name"
                        label="Наименование"
                        placeholder="Оплата за электричество"
                        :required="true"
                    />

                    <FormNumber
                        v-model="form.value"
                        :label="
                            'Сумма ' +
                            (form.income ? 'поступлений' : 'списаний')
                        "
                        placeholder="Сумма"
                        :required="true"
                    />

                    <template v-if="!operationDetails">
                        <el-form-item>
                            <el-button
                                @click="operationDetails = true"
                                type="text"
                                :icon="icons.plus"
                                >Указать подробности (категория, контрагент,
                                счет и тд)
                            </el-button>
                        </el-form-item>
                    </template>

                    <template v-if="operationDetails">
                        <FormSelect
                            v-model="form.accountId"
                            label="Счет"
                            :noClearable="true"
                            placeholder="Выберите счет"
                            :items="accounts"
                        />

                        <FormSelect
                            v-model="form.typeId"
                            label="Категория"
                            placeholder="Введите название"
                            :items="getOperationTypes()"
                            :allow-create="true"
                        />

                        <FormInputSuggestions
                            v-model="form.company.name"
                            :items="companies"
                            :label="
                                (form.income ? 'Отправитель' : 'Получатель') +
                                ' (контрагент)'
                            "
                            property="name"
                            new-item="company"
                            :new-item-data="form.companyId"
                            placeholder="Введите название"
                        />

                        <template
                            v-if="form?.company?.name &&
                                companyCheck &&
                                companyCheck !== 'noCompany'">
                            <div class="pb-30">
                                <DrawerTextLineItem
                                    name="ИНН"
                                    :desc="form.company.inn ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="КПП"
                                    :desc="form.company.kpp ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="E-mail"
                                    :desc="form.company.email ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="Телефон"
                                    :desc="form.company.phone ?? 'Не указан'"
                                />
                            </div>
                        </template>

                        <template
                            v-if="
                                form?.company?.name &&
                                companyCheck &&
                                companyCheck === 'noCompany'
                            "
                        >
                            <FormInput label="ИНН" placeholder="Введите ИНН" />

                            <FormInput
                                label="E-mail"
                                placeholder="Контактный e-mail"
                            />
                        </template>

                        <FormTextarea
                            v-model="form.comment"
                            label="Комментарий"
                            placeholder="Оплатили на 2 месяца вперед, чек в бухгалтерии"
                        />
                    </template>
                </template>
            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton
                :disabled="checkDisabled()"
                title="Создать операцию"
                @action="operationCreate"
            />
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import FormInput from "@/views/components/Form/FormInput";
import FormTextarea from "@/views/components/Form/FormTextarea";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import FormInputSuggestions from "@/views/components/Form/FormInputSuggestions";
import { mapState } from "pinia/dist/pinia";
import { useCommonStore } from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";
import DrawerTextLineItem from "@/views/components/Drawer/DrawerTextLineItem";
import FormSelect from "@/views/components/Form/FormSelect";
import iconsMixin from "@/mixins/iconsMixin";
import { httpService } from "@/services/http.service";
import FormNumber from "@/views/components/Form/FormNumber";

export default {
    name: "CashboxOperationCreate",
    components: {
        FormNumber,
        FormSelect,
        DrawerTextLineItem,
        FormInputSuggestions,
        DrawerCreateButton,
        DrawerHeadTitle,
        FormTextarea,
        FormInput,
        DrawerFramework,
    },
    inject: ["api"],
    props: ["operationTypes", "recipients", "accounts"],
    mixins: [functionsMixin, iconsMixin],
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
        }),
    },
    data() {
        return {
            form: {
                name: null,
                typeId: null,
                value: null,
                comment: null,
                accountId: "default",
                income: null,
                company: {
                    inn: null,
                    kpp: null,
                    email: null,
                    phone: null,
                },
            },
            companyCheck: null,
            operationDetails: false,
        };
    },
    methods: {
        operationCreate() {
            this.loading = true;
            this.loadingButton = true;
            httpService()
                .post(this.api.cashboxOperation.create, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: "cashbox",
                    operation: this.form,
                })
                .then((response) => {
                    let data = response.data;

                    if (data.success) {
                        this.$message({
                            message: "Операция добавлена",
                            type: "success",
                            showClose: true,
                        });

                        if (data.accountUpdate) {
                            this.$message({
                                message: "Остаток счета обновлен",
                                type: "success",
                                showClose: true,
                            });
                        }

                        this.$emit("moduleGet");
                        this.$emit("closeDrawer");
                    }
                })
                .catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        type: "error",
                        showClose: true,
                    });
                    this.loading = false;
                    this.loadingButton = false;
                });
        },
        checkDisabled() {
            return !this.form.name && !this.form.value;
        },
        getOperationTypes() {
            return this.operationTypes.filter(
                (el) => el.income === this.form.income
            );
        },
    },
    watch: {
        "form.company.name": {
            handler: function () {
                // ищем компанию по названию, которое ввел пользователь
                // если нашли, то выводим данные
                // если нет, то показываем инпуты

                if (this.form.company.name) {
                    let object = this.companies.find(
                        (el) => el.name === this.form.company.name
                    );

                    if(object) {
                        this.getData(object.id, "company", "inn").then(
                            (response) => {
                                this.form.company.inn = response.inn;
                            }
                        );

                        this.getData(object.id, "company", "kpp").then(
                            (response) => {
                                this.form.company.kpp = response.kpp;
                            }
                        );

                        this.getData(object.id, "company", "email").then(
                            (response) => {
                                this.form.company.email = response.email;
                            }
                        );

                        this.getData(object.id, "company", "phone").then(
                            (response) => {
                                this.form.company.phone = response.phone;
                            }
                        );
                    }
                    setTimeout(() => {
                        if (object) {
                            this.companyCheck = object.id;

                        } else {
                            this.companyCheck = "noCompany";
                        }
                    }, 500);
                } else {
                    this.companyCheck = null;
                }
            },
            deep: false,
            immediate: false,
        },
    },
};
</script>

<style scoped></style>
