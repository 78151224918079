<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="url(#paint0_linear_1252_18098)" fill-opacity="0.5"/>
        <path opacity="0.4"
              d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5Z"
              fill="#1B91FD"/>
        <path
            d="M18 19.3125C18.3075 19.3125 18.5625 19.0575 18.5625 18.75V15C18.5625 14.6925 18.3075 14.4375 18 14.4375C17.6925 14.4375 17.4375 14.6925 17.4375 15V18.75C17.4375 19.0575 17.6925 19.3125 18 19.3125Z"
            fill="#1B91FD"/>
        <path
            d="M18.69 20.715C18.6525 20.625 18.6 20.5425 18.5325 20.4675C18.4575 20.4 18.375 20.3475 18.285 20.31C18.105 20.235 17.895 20.235 17.715 20.31C17.625 20.3475 17.5425 20.4 17.4675 20.4675C17.4 20.5425 17.3475 20.625 17.31 20.715C17.2725 20.805 17.25 20.9025 17.25 21C17.25 21.0975 17.2725 21.195 17.31 21.285C17.3475 21.3825 17.4 21.4575 17.4675 21.5325C17.5425 21.6 17.625 21.6525 17.715 21.69C17.805 21.7275 17.9025 21.75 18 21.75C18.0975 21.75 18.195 21.7275 18.285 21.69C18.375 21.6525 18.4575 21.6 18.5325 21.5325C18.6 21.4575 18.6525 21.3825 18.69 21.285C18.7275 21.195 18.75 21.0975 18.75 21C18.75 20.9025 18.7275 20.805 18.69 20.715Z"
            fill="#1B91FD"/>
        <defs>
            <linearGradient id="paint0_linear_1252_18098" x1="-8.35698e-08" y1="17.8686" x2="17.8686" y2="-8.35698e-08"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#1B91FD"/>
                <stop offset="1" stop-color="#1B91FD" stop-opacity="0.5"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "InfoIcon"
}
</script>
