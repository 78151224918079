<template>
    <Auth title="Рецепты" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    create-button-label="Добавить рецепт"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateMixGoodDrawer"
                    :new="true"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="categories"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="рецептов"
                />

                <div v-if="displayData.length > 0">

                    <div v-if="displayData">

                        <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                            <template v-slot:body>

                                <MobileTableHR/>

                                <MobileTableInfo
                                    title="Рецепт"
                                    :body="item.name"
                                />

                                <MobileTableInfo
                                    title="Категория"
                                    :body="item.category ? getInfo(item.category, categories) : 'Без категории'"
                                />

                                <MobileTableInfo
                                    title="Сырье"
                                    :no-border="true"
                                >
                                    <template v-slot:body>
                <span v-for="(good, index) in item.goods" :key="index">{{ getData(good.goodId, 'good') }} ({{
                        formatNumber(good.total, 2)
                    }} <good-unit :padding-unit="true"
                                  :unit="good.goodId ? getData(good.goodId, 'good', 'unit') : ''"/>)<span
                        v-if="index !== item.goods.length - 1">, </span></span>
                                        <span v-if="item.goods.length === 0">-</span>
                                    </template>
                                </MobileTableInfo>

                                <MobileTableButton
                                    @action="openMixGoodDetails(index, item)"
                                />

                            </template>
                        </MobileTableFramework>
                    </div>

                    <el-table
                        v-loading="loading"
                        v-if="!smallMobile"
                        :data="displayData"
                        empty-text="Нет информации"
                        class="mt-15 w-100">
                        <el-table-column
                            prop="name"
                            label="Наименование"
                            width="320px"
                        >
                            <template v-slot="scope">
                                <span class="textSM textMedium neutral900">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="category"
                            label="Категория"
                            width="200px"
                        >
                            <template v-slot="scope">
                                {{ scope.row.category ? getInfo(scope.row.category, categories) : 'Без категории' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="value"
                            label="Сырье"
                        >
                            <template v-slot="scope">
                <span v-for="(good, index) in scope.row.goods" :key="index">{{
                        getData(good.goodId, 'good')
                    }} ({{
                        formatNumber(good.total, 2)
                    }} <good-unit :padding-unit="true"
                                  :unit="good.goodId ? getData(good.goodId, 'good', 'unit') : ''"/>)<span
                        v-if="index !== scope.row.goods.length - 1">, </span></span>
                                <span v-if="scope.row.goods.length === 0">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label=""
                            width="130px"
                        >
                            <template v-slot="scope">
                                <el-button
                                    size="small"
                                    @click="openMixGoodDetails(scope.$index, scope.row)">Подробнее
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <TablePagination
                        v-model="pageSize"
                        :total="valueLength"
                        @action="setPage"
                    />
                </div>

                <el-drawer
                    title=""
                    v-model="createMixGoodDrawer"
                    direction="rtl"
                    :size="drawerSize"
                    :destroy-on-close="true">
                    <MixPageGoodCreate
                        @moduleGet="moduleGet"
                        @closeDrawer="createMixGoodDrawerToFalse"
                        :categories="categories"
                        :goods="goods"/>
                </el-drawer>

                <el-drawer
                    title=""
                    v-model="goodDetailsDrawer"
                    direction="rtl"
                    :size="drawerSize"
                    :destroy-on-close="true">
                    <MixGoodDetails
                        :good="goodDetails"
                        :categories="categories"
                        @moduleGet="moduleGet"
                        @closeDrawer="goodDetailsDrawerToFalse"
                    />
                </el-drawer>
            </div>
        </div>
    </Auth>
</template>

<script>
import TablePagination from '../../Table/TablePagination'
import Filter from '../../Table/Filter/Filter'
import TableHeadBlock from '../../Table/TableHeadBlock'
import tableMixin from '../../../../mixins/tableMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import MixPageGoodCreate from './MixPageGoodCreate'
import functionsMixin from '../../../../mixins/functionsMixin'
import MixGoodDetails from '@/views/components/MixPage/Goods/MixGoodDetails'
import GoodUnit from '@/views/components/goodUnit'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState.vue";
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'MixPageGoods',
    components: {
        Auth,
        EmptyState,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        GoodUnit,
        MixGoodDetails,
        MixPageGoodCreate,
        TablePagination,
        Filter,
        TableHeadBlock,
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            createMixGoodDrawer: false,
            goodDetailsDrawer: false,
            goodDetails: null,
            values: [],
            filter: {
                category: {
                    value: null,
                    type: 'select',
                    label: 'Категория',
                    placeholder: 'Выберите категорию',
                    items: '1'
                },
            },
            categories: [],
            tabs: [],
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        })
    },
    created() {
        this.moduleGet()
        if (this.$route.name === 'MixGroupGoods') {
            this.tabs = [{
                route: 'MixOrdersGroups',
                name: 'Заказы'
            }, {
                route: 'MixOrdersGroupsOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixOrdersMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGroupGoods',
                name: 'Рецепты'
            }]
        } else {
            this.tabs = [{
                route: 'MixInfo',
                name: 'Сводка'
            }, {
                route: 'MixOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGoods',
                name: 'Рецепты'
            }]
        }
    },
    methods: {
        createMixGoodDrawerToFalse() {
            this.createMixGoodDrawer = false;
        },
        goodDetailsDrawerToFalse() {
            this.goodDetailsDrawer = false;
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data

                if (data.success)
                this.values = data.values
                this.categories = data.categories
                this.loading = false
            })

        },
        openCreateMixGoodDrawer() {
            this.createMixGoodDrawer = true
        },
        openMixGoodDetails(index, row) {
            this.goodDetails = []
            this.goodDetailsDrawer = true
            this.goodDetails = row
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.category.value) this.filtered = this.filtered.filter(data => data.category === this.filter.category.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {

    }
}
</script>

<style scoped>

</style>
