<template>
    <svg
        :width="width ?? '16'"
        :height="height ?? '16'"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="[{'currentColor': currentColor}]"
    >
        <path
            d="M14 8C14 11.3137 11.3137 14 8 14C6.81929 14 5.71824 13.659 4.78998 13.07L2 14L2.92999 11.21C2.34104 10.2818 2 9.18071 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
            stroke="#1B91FD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.6665 8H10.6678V8.00133H10.6665V8Z"
            stroke="#1B91FD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 8H8.00133V8.00133H8V8Z"
            stroke="#1B91FD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.3335 8H5.33483V8.00133H5.3335V8Z"
            stroke="#1B91FD"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "ChatCircleDotsIcon",
    props: ['currentColor', 'width', 'height']
};
</script>

<style scoped lang="scss">
.currentColor {
    &:deep(path) {
        stroke: currentColor;
    }
};
</style>
