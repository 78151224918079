<template>

    <el-row :gutter="16">
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Отгружено продукции"
                :count="graph.indicators.countProd.value"
                :count-old="graph.indicators.countProd.oldValue"
                :unit="getUnitFromIds(graph.units)"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Средняя скорость отгрузок"
                :count="graph.indicators.speed.value"
                :count-old="graph.indicators.speed.oldValue"
                :unit="getUnitFromIds(graph.units)"
                type="speedHour"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsCounterBlock
                title="Количетво отгрузок"
                :count="graph.indicators.countWeighings.value"
                :count-old="graph.indicators.countWeighings.oldValue"
            />
        </el-col>
        <el-col :md="6" :xl="6">
            <InfoAnalyticsDateTimeBlock
                title-unit="отгрузки"
                :date="graph.indicators.lastDate"
                period="today"
            />
        </el-col>
    </el-row>

    <div class="mixGraph">
        <p class="topText">Объем отгрузок</p>

        <div class="bar-echarts">
            <div class="chart">
                <v-chart
                    v-model="chart"
                    :option="option"
                    autoresize
                />
            </div>
        </div>

    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import VChart from 'vue-echarts'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import InfoAnalyticsCounterBlock from "@/views/components/Blocks/InfoAnalyticsCounterBlock.vue";
import InfoAnalyticsDateTimeBlock from "@/views/components/Blocks/InfoAnalyticsDateTimeBlock.vue";

export default {
    name: "WeighingInfoGraph",
    props: [
        'graph'
    ],
    mixins: [
        functionsMixin, getUnitFromGoodsMixin
    ],
    components: {
        InfoAnalyticsDateTimeBlock,
        InfoAnalyticsCounterBlock,
        VChart
    },
    data() {
        return {
            hours: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00"
            ],
        }
    },
    methods: {

    },
    computed: {
        option() {
            return {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#ffffff',
                    borderColor: '#ffffff',
                    textStyle: {
                        color: '#3a3a3a',
                    },
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                grid: {
                    left: 8,
                    right: 0,
                    bottom: 0,
                    top: 10,
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.hours,
                        shadowColor: 'rgba(172,34,34,0.3)',
                        borderColor: 'transparent',
                        axisLabel: {
                            margin: 16,
                            textStyle: {
                                color: '#8A909A'
                            }
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                type: 'dashed',
                                color: '#a2a5bf',
                            },
                        },
                    }
                ],
                yAxis: [
                    {
                        show: true,
                        axisLabel: {
                            textStyle: {
                                color: '#8A909A',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: '#F9F9F9',
                            },
                        }
                    },

                ],

                series: [
                    {
                        name: 'Кол-во',
                        type: 'bar',
                        data: this.graph.total,
                        itemStyle: {
                            color: '#2BD8B3',
                            borderRadius: [12, 12, 0, 0]
                        },
                    },
                ],
            }
        }
    },
}
</script>

<style lang="scss">
.el-popper {
    &.is-customized,
    .el-popper__arrow::before {
        max-width: 215px;
        color: #3a3a3a;
        background: #fff;
        filter: drop-shadow(0px 0px 10px rgba(30, 30, 30, 0.1));
        line-height: 1.6;
    }
}

.bar-echarts {
    margin-top: 30px;
    margin-bottom: 16px;

    .chart {
        height: 320px;
    }

    .emptyData {
        color: #22252C;
        text-align: center;
        font-size: 18px;
        position: relative;
        top: 1em;
    }
}
</style>
