<template>
    <Auth title="Справка">
        <div class="card" id="faq">
            <div class="card-body">

                <h2 class="mt-30">10 популярных вопросов</h2>

                <FaqCard
                    title="Популярные вопросы"
                    icon="flash"
                    :unlimited="true"
                    limit="10"
                    :questions="faqQuestions.filter(el => el.popular)"
                />

                <h2 class="mt-30">Основная документация</h2>

                <div class="faqModules">

                    <FaqCard
                        title="Общие вопросы"
                        icon="settings"
                        module="settings"
                        :questions="faqQuestions.filter(el => el.module === 'settings')"
                    />

                    <FaqCard
                        title="Денежный учет"
                        icon="cashbox"
                        module="cashbox"
                        :questions="faqQuestions.filter(el => el.module === 'cashbox')"
                    />

                    <FaqCard
                        title="Бетонный завод"
                        icon="mix"
                        module="mix"
                        :questions="faqQuestions.filter(el => el.module === 'mix')"
                    />

                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import faqQuestions from '@/mixins/faqQuestions'
import FaqCard from '@/views/components/FaqPage/FaqCard'

export default {
    name: 'FAQ',
    components: {
        FaqCard,
        Auth,
    },
    mixins: [
        faqQuestions
    ],
    computed: {
        ...mapState(useCommonStore, {
            links: 'getNav'
        })
    },
    methods: {
        checkAccess(type) {
            // проверяем, есть ли доступ у пользователя к тому ли иному разделу
            // если есть, то ему доступна справка по этим разделам
            if (type !== 'main') {
                return !!this.links.some(data => data.type === type)
            } else {
                return true
            }
        },
    },
}
</script>

<style scoped>

</style>
