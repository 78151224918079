<template>
    <div class="neutral900 textMD textMedium mt-15 mb-10">Стратегия алгоритма</div>
    <div class="neutral400 textXS mb-10">Выберите подходящую стратегию оптимизации алгоритма:</div>
    <el-radio-group class="algorithmGoalButtons" v-model="form.algorithm_goal">
        <el-radio value="minimum_vehicles" size="large" border>
            <span class="title">Меньше доставок</span>
            <span class="description">Подбор миксеров от большего к меньшему</span>
            <span class="schema">12 м³ -> 10 м³ -> 8 м³</span>
        </el-radio>
        <el-radio value="evenly" size="large" border disabled>
            <span class="title">Усредненно</span>
            <span class="description">Подбор миксеров с похожим объемом</span>
            <span class="schema">12 м³ -> 10 м³ -> 12 м³</span>
        </el-radio>
        <el-radio value="equal_volume" size="large" border disabled>
            <span class="title">Равный объем</span>
            <span class="description">Разделение заказа на равные объемы</span>
            <span class="schema">10.7 м³ -> 10.7 м³ -> 10.7 м³</span>
        </el-radio>
    </el-radio-group>

    <div class="neutral900 textMD textMedium mt-15 mb-10">Расширенные настройки</div>

    <el-row :gutter="30">
        <el-col :sm="windowWidth < 1600 ? 12 : 8">

            <FormNumber
                v-if="form.delivery_type === 'delivery'"
                label="Макс. объём каждого миксера"
                v-model="form.mixer_max_volume"
                placeholder="Укажите объем"
                precision="2"
                step="1"
            />

            <FormNumber v-show="false"
                v-if="form.delivery_type === 'delivery'"
                v-model="form.axle"
                label="Макс. осей миксера"
                placeholder="Укажите числом, н-р: 3"
                precision="0"
                step="1"
                min="2"
                max="4"
            />

            <el-form-item v-show="false"
                v-if="form.delivery_type === 'delivery'"
                label="Распределять остаток"
            >
                <el-switch size="large" v-model="form.max_load"/>
            </el-form-item>

            <template v-if="form.max_load">
                <FormNumber
                    v-model="form.max_remainder"
                    label="Максимальный остаток"
                    placeholder="0.5"
                    precision="2"
                />
            </template>
        </el-col>
        <el-col :sm="windowWidth < 1600 ? 12 : 8">
            <FormNumber
                v-if="form.algorithm_goal === 'evenly'"
                label="Средний объем миксеров"
                v-model="form.evenly_total"
                placeholder="Укажите объем"
                precision="2"
                step="1"
                :required="true"
            />
            <FormNumber
                v-if="form.algorithm_goal === 'equal_volume'"
                label="Равный объем миксеров"
                v-model="form.equal_volume_total"
                placeholder="Укажите объем"
                precision="2"
                step="1"
                :required="true"
            />
        </el-col>
    </el-row>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "MixOrderGroupCreateAlgorithmBlock",
    components: {FormNumber},
    props: ['modelValue'],
    mixins: [mobileCheckMixin],
    data () {
        return {
            form: this.modelValue
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.form = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
