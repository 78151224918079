export default {
    data() {
        return {
            companyTypes: [
                {
                    id: 'client',
                    name: 'Покупатель'
                },
                {
                    id: 'seller',
                    name: 'Поставщик'
                },
                {
                    id: 'carrier',
                    name: 'Перевозчик'
                }
            ],
        }
    }
}
