<template>

    <div class="mb-5" v-if="!hideHeader">
        <el-button size="small" :disabled="loading" @click="() => createTaskDialog = true" :icon="icons.plus">Добавить
            задачу
        </el-button>
        <el-button size="small" :disabled="loading" @click="() => createCommentDialog = true" :icon="icons.edit">
            Оставить комментарий
        </el-button>
    </div>

    <el-scrollbar :height="hideHeader ? '75vh' : '65vh'" v-if="events.length">
        <div v-for="(event, index) in events" :key="index" :class="'crm-event ' + (event.type === 'task' ? 'crm-event-' + event.data.status : '')">
            <div class="textXS textRegular neutral400 mb-15">
                {{ getDateFromUnix(event.date, 'DD.MM.YY в HH:mm') }}
            </div>
            <template v-if="event.type === 'task'">
                <div class="textSM textRegular neutral900 mb-5">
                    <span class="textMedium">Задача: </span>
                    {{ event.data.name }}
                </div>
                <div class="textSM textRegular neutral900 mb-5">
                    <span class="textMedium">Срок выполнения: </span>
                    {{ getDateFormDB(event.data.date_done, 'date') }}
                </div>
                <div class="textSM textRegular neutral900 mb-10">
                    <span class="textMedium">Ответственный: </span>
                    {{ getInfo(event.data.user_id, users) }}
                </div>
                <div class="textSM textRegular neutral900 mb-10" v-if="hideHeader && event.data.mix_order_group_id">
                    <span class="textMedium">Относится к: </span>
                    <el-link class="textRegular textSM primary600" style="position: relative; top: -1px;" @click="$router.push({name: 'CRMOrderDetails', params: { groupId: event.data.mix_order_group_id }})">Заказ № {{ event.data.mix_order_group_id }}</el-link>
                </div>
                <div v-if="event.data.description" class="textSM textRegular neutral500 mb-10">
                    {{ event.data.description }}
                </div>
                <el-button @click="openTaskDetailsDialog(event)" size="small">Подробности</el-button>
            </template>
            <template v-else>
                <div class="textSM textRegular neutral900 mb-5">
                    <span class="textMedium">Комментарий: </span>
                    {{ event.data.text }}
                </div>
                <div class="textSM textRegular neutral900">
                    <span class="textMedium">Автор: </span>
                    {{ getInfo(event.data.author_id, users) }}
                </div>
            </template>
        </div>
    </el-scrollbar>

    <div v-else-if="!events.length && !hideHeader" style="height: 65vh">
        <template v-if="loading">
            <div class="text-center w-100 mt-30 mb-30">
                <ReportLoader/>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100 mt-30 mb-30 textXS textRegular neutral200">
                Комментарии и задачи по заказу не найдены
            </div>
        </template>
    </div>

    <el-dialog
        v-model="createTaskDialog"
        title="Добавить задачу"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <el-form ref="form" label-position="top">
            <FormInput
                v-model="task.name"
                label="Название задачи"
                placeholder="Введите название"
            />
            <FormTextarea
                v-model="task.description"
                label="Описание"
                placeholder="Опишите подробности"
                :rows="4"
            />
            <FormSelect
                v-model="task.user_id"
                label="Ответственный"
                placeholder="Выберите"
                :items="users"
            />
            <FormDateTimePicker
                v-model="task.date_done"
                label="Дата выполнения"
                placeholder="Выберите"
            />
            <hr class="mb-15"/>
            <el-button :disabled="loadingButton || (!task.name || !task.user_id || !task.date_done)" type="primary" @click="createTask">Создать задачу</el-button>
        </el-form>
    </el-dialog>

    <el-dialog
        v-model="taskDetailsDialog"
        title="Подробности задачи"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <div class="textXS textRegular neutral400 mb-15">
            {{ getDateFromUnix(taskDetails.date, 'DD.MM.YY в HH:mm') }}
        </div>
        <div class="textSM textRegular neutral900 mb-5">
            <span class="textMedium">Задача: </span>
            {{ taskDetails.data.name }}
        </div>
        <div class="textSM textRegular neutral900 mb-5">
            <span class="textMedium">Срок выполнения: </span>
            {{ getDateFormDB(taskDetails.data.date_done, 'date') }}
        </div>
        <div class="textSM textRegular neutral900 mb-10">
            <span class="textMedium">Ответственный: </span>
            {{ getInfo(taskDetails.data.user_id, users) }}
        </div>
        <div v-if="taskDetails.data.description" class="textSM textRegular neutral500 mb-10">
            {{ taskDetails.data.description }}
        </div>
        <hr class="mb-15"/>
        <div v-if="taskDetails.data.status !== 'done'">
            <el-button :disabled="loadingButton" type="primary" @click="changeTaskStatus('done')">Завершить задачу</el-button>
            <el-button :disabled="loadingButton" @click="changeTaskStatus('canceled')">Отменить</el-button>
        </div>
        <div v-else>
            <el-button :disabled="loadingButton" type="primary" @click="removeTask">Удалить задачу</el-button>
        </div>
    </el-dialog>

    <el-dialog
        v-model="createCommentDialog"
        title="Оставить комментарий"
        :width="mobile ? '100%' : '60%'"
        :destroy-on-close="true"
    >
        <el-form ref="form" label-position="top">
            <FormTextarea
                v-model="comment.text"
                label="Описание"
                placeholder="Опишите подробности"
                :rows="4"
            />
            <hr class="mb-15"/>
            <el-button :disabled="loadingButton || !comment.text" type="primary" @click="createComment">Создать комментарий</el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormTextarea from "@/views/components/Form/FormTextarea.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import functionsMixin from "@/mixins/functionsMixin";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'OrderEvents',
    components: {ReportLoader, FormDateTimePicker, FormSelect, FormTextarea, FormInput},
    props: ['events', 'loading', 'hideHeader', 'openDrawer'],
    inject: ['api'],
    mixins: [iconsMixin, mobileCheckMixin, functionsMixin],
    data() {
        return {
            loadingButton: false,
            createTaskDialog: false,
            createCommentDialog: false,
            taskDetailsDialog: false,
            taskDetails: null,
            users: [],
            task: {
                name: null,
                description: null,
                date_done: null,
                user_id: null
            },
            comment: {
                text: null
            }
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        getUsers() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.users = data.users

            }).finally(() => {
                this.$root.loadingState(false)
            })
        },
        openTaskDetailsDialog(task) {
            this.taskDetails = task
            this.taskDetailsDialog = true
        },
        createTask() {
            this.loadingButton = true

            httpService().post(this.api.crmTasks.store, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'crm',
                orderId: this.$route.params.groupId,
                task: this.task
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$emit('closeDrawer')
                    this.$emit('moduleGet')
                    this.createTaskDialog = false
                    this.$message({
                        message: 'Задача создана',
                        showClose: true,
                        type: 'success'
                    })
                    this.loadingButton = false
                    this.task = {
                        name: null,
                        description: null,
                        date_done: null,
                        user_id: null
                    }
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        removeTask() {
            this.loadingButton = true

            httpService().post(this.api.crmTasks.remove, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'crm',
                taskId: this.taskDetails.data.id,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$emit('moduleGet')
                    this.taskDetailsDialog = false
                    this.$message({
                        message: 'Задача удалена',
                        showClose: true,
                        type: 'success'
                    })
                    this.loadingButton = false
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        createComment() {
            this.loadingButton = true

            httpService().post(this.api.crmComments.store, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'crm',
                orderId: this.$route.params.groupId,
                comment: this.comment
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.createCommentDialog = false
                    this.$message({
                        message: 'Комментарий добавлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.comment = {
                        text: null
                    }
                    this.loadingButton = false
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        changeTaskStatus(status) {
            this.loadingButton = true

            httpService().post(this.api.crmTasks.changeStatus, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'crm',
                taskId: this.taskDetails.data.id,
                status: status
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.taskDetailsDialog = false
                    this.$emit('moduleGet')
                    this.$message({
                        message: 'Статус задачи обновлен',
                        showClose: true,
                        type: 'success'
                    })
                    this.loadingButton = false
                    this.$emit('update')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
    },
    watch: {
        'openDrawer': {
            handler: function () {
                if (this.openDrawer) this.createTaskDialog = true
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
