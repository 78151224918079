<template>
    <el-button
        class="expandButton"
        type="text"
        @click="$emit('action')">
        Дополнительные настройки
    </el-button>
</template>

<script>
export default {
    name: "FormExpandButton"
}
</script>

<style scoped>

</style>
