<template>
    <div class="form-radio-button-group">
        <div
            v-for="(option, index) in options"
            :key="index"
            class="form-radio-button"
            :class="{'form-radio-button__selected': value === option[valueKey]}"
            @click="set(option[valueKey])"
        >
            <div class="radi-col">
                <div class="radio radio__selected"></div>
            </div>
            <div class="text-col">
                <div class="title">{{ deepFind(option, titleKey) }}</div>
                <div class="desc"><slot :option="option"></slot>{{ deepFind(option, descriptionKey) }}</div>
            </div>
            <div class="icon-col">
                <component :is="option.icon"/>
            </div>
            <Badge v-if="option.badge">
                <template #default>
                    {{ option.badge }}
                </template>
            </Badge>
        </div>
    </div>
</template>

<script>
import Badge from "@/views/components/Badge.vue";
import PaymentCardIcon from "@/views/components/Icons/PaymentCardIcon.vue";
import PaymentBillIcon from "@/views/components/Icons/PaymentBillIcon.vue";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "FormRadioButtonGroup",
    components: {PaymentBillIcon, PaymentCardIcon, Badge},
    mixins: [functionsMixin],
    props: {
        modelValue: {
            default: false,
            type: Boolean
        },
        valueKey: {
            default: 'value',
            type: String
        },

        titleKey: {
            default: 'title',
            type: String,
        },
        descriptionKey: {
            default: 'description',
            type: String,
        },
        options: {
            default: null,
            type: [Array, Object]
        }
    },
    data() {
        return {
            value: this.modelValue,
        }
    },
    watch: {
        modelValue: {
            handler: function () {
                this.$nextTick(() => {
                    this.value = this.modelValue;
                })
            },
            deep: true,
            immediate: true
        },
        'value': {
            handler: function () {
                this.$emit('update:modelValue', this.value)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        set(value) {
            this.value = value;
        }
    },
}
</script>

<style scoped>
.radio {
    width: 18px;
    height: 18px;
    border: 1px solid #AFB3BA;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
}

.form-radio-button__selected .radio {
    border-color: #1984E6;
}

.form-radio-button__selected .radio::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #1984E6;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.form-radio-button {
    display: flex;
    align-items: center;
    position: relative;
    padding: 14px 22px;
    border: 1px solid #EEEEF0;
    border-radius: 12px;
    cursor: pointer;
}

.form-radio-button:not(:last-child) {
    margin-bottom: 16px;
}

.form-radio-button__selected {
    border-color: #49A7FD;
    background: #E8F4FF;
}

.form-radio-button .badge {
    position: absolute;
    top: 0;
    right: 16px;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.text-col {
    margin: 0 12px;
    flex-grow: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: #393F4A;
}

.text-col .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #49515F;
}

/*.icon-col {*/
/*    width: 36px;*/
/*}*/

.icon-col,
.radi-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
