<template>
    <TechVehicleHeadInfo v-if="techDetails" :tech="techDetails"/>

    <div v-if="!mode" class="techChangeMode">

        <el-button @click="mode='simple'">
            <div class="d-flex">
                <div>
                    <TechMileageIcon/>
                </div>
                <div class="techChangeModeInfo">
                    <h3>Указать расходы топлива</h3>
                    <p>Указывается только остаток топлива и показания счетчика</p>
                </div>
            </div>
        </el-button>

        <el-button @click="mode='full'">
            <div class="d-flex">
                <div>
                    <TechFuelIcon/>
                </div>
                <div class="techChangeModeInfo">
                    <h3>Заправить транспорт</h3>
                    <p>Указывается количество литров, вид топлива, стоимость и тд.</p>
                </div>
            </div>
        </el-button>

    </div>

    <div v-if="mode === 'simple'">

            <el-form label-position="top">
                <FormNumber
                    v-model="form.mileage"
                    label="Показания счетчика"
                    placeholder="Укажите значение счетчика"
                    :required="true"
                />

                <p class="mt-0 mb-15 color-grey text-small">Текущее значение должно быть не менее
                    <AnimateNumber :count="techDetails.mileage"/>
                    {{ techDetails.metric === 'mileage' ? 'км.' : 'мтч.' }}
                </p>

                <FormNumber
                    v-model="form.fuel"
                    label="Остаток топлива"
                    placeholder="Укажите кол-во литров"
                    :required="true"
                />

                <FormAddButton
                    :disable="!form.mileage || !form.fuel"
                    :w100="true"
                    title="Сохранить изменения"
                    @action="changeFuel"
                />
            </el-form>

    </div>
    <div v-if="mode === 'full'">

        <el-form label-position="top">
            <FormDateTimePicker
                v-model="form.date"
                type="datetime"
                label="Дата заправки"
            />

            <FormSelect
                v-model="form.fuelType"
                :items="fuelTypes"
                label="Вид топлива"
                placeholder="Выберите вид топлива"
            />

            <FormNumber
                v-model="form.count"
                label="Количество, л."
                placeholder="Введите количество литров"
                :required="true"
            />

            <FormNumber
                v-model="form.fuelPrice"
                label="Цена за 1 л"
                placeholder="Стоимость одного литра"
            />

            <FormNumber
                v-model="form.mileage"
                label="Показания счетчика"
                placeholder="Укажите значение счетчика"
            />

            <p class="mt-0 mb-15 color-grey text-small">Текущее значение должно быть не менее
                <AnimateNumber :count="techDetails.mileage"/>
                {{ techDetails.metric === 'mileage' ? 'км.' : 'мтч.' }}
            </p>

            <FormAddButton
                :disable="!form.count"
                :w100="true"
                title="Сохранить изменения"
                @action="changeFuel"
            />
        </el-form>

    </div>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber";
import FormAddButton from "@/views/components/Form/FormAddButton";
import TechVehicleHeadInfo from "@/views/components/TechPage/Garage/TechVehicleHeadInfo";
import AnimateNumber from "@/views/components/AnimateNumber";
import {httpService} from "@/services/http.service";
import fuelTypes from "@/mixins/fuelTypes";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";
import FormSelect from "@/views/components/Form/FormSelect";
import moment from "moment";
import getTimezone from "@/mixins/getTimezone";
import TechMileageIcon from "@/views/components/Icons/TechMileageIcon";
import TechFuelIcon from "@/views/components/Icons/TechFuelIcon";

export default {
    name: 'TechChangeFuel',
    components: {
        TechFuelIcon,
        TechMileageIcon,
        FormSelect, FormDateTimePicker, AnimateNumber, TechVehicleHeadInfo, FormAddButton, FormNumber},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [fuelTypes, getTimezone],
    data() {
        return {
            loading: false,
            mode: null,
            form: {
                fuel: null,
                mileage: null,
                date: moment(),
                count: null,
                fuelPrice: null,
                fuelType: null,
            },
            techDetails: null
        }
    },
    created() {
        this.getTechDetails()
    },
    methods: {
        getTechDetails() {
            this.loading = true
            httpService().post(this.api.tech.getTechDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.techDetails = data.details

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        changeFuel() {
            this.loading = true
            httpService().post(this.api.tech.changeTechFuel, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
                form: this.form,
                mode: this.mode,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.$emit('moduleGet')
                this.form = {
                    fuel: null,
                    mileage: null,
                    date: moment(),
                    count: null,
                    fuelPrice: null,
                    fuelType: null,
                }
                this.getTechDetails()

                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
