<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">Отгрузка подтверждена</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5" v-for="(value, index) in values" :key="index">
                <span class="neutral300 mr-5">{{ value.name }}:</span>
                <span class="text-line-through mr-5 neutral100" v-if="value.oldData">{{ value.oldData }}</span>
                <span>{{ value.data }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderDetailsGroupLogConfirm",
    props: ['log'],
    mixins: [functionsMixin],
    computed: {
        values() {
            let elems = []

            this.log.values.filter(el => el.new).forEach(el => {
                let name = null
                let data = null
                let oldData = null

                let oldTime = null

                switch (el.key) {
                    case 'vehicle':
                        name = 'ТС';
                        data = el.vehicle?.number;
                        oldData = this.log.values.find(e => e.key === 'vehicle' && !e.new)?.vehicle?.number
                        break;
                    case 'driver':
                        name = 'Водитель';
                        data = el.driver?.name;
                        oldData = this.log.values.find(e => e.key === 'driver' && !e.new)?.driver?.name
                        break;
                    case 'total':
                        name = 'Объем';
                        data = el.value;
                        oldData = this.log.values.find(e => e.key === 'total' && !e.new)?.value
                        break;
                    case 'good':
                        name = 'Рецепт';
                        data = el.good?.name;
                        oldData = this.log.values.find(e => e.key === 'good' && !e.new)?.good?.name
                        break;
                    case 'mix':
                        name = 'Бетонный завод';
                        data = el.mix?.module?.name;
                        oldData = this.log.values.find(e => e.key === 'mix' && !e.new)?.mix?.module?.name
                        break;
                    case 'mixer':
                        name = 'Пост';
                        data = el.mixer?.name;
                        oldData = this.log.values.find(e => e.key === 'mixer' && !e.new)?.mixer?.name
                        break;
                    case 'start_at':
                        oldTime = this.log.values.find(e => e.key === 'start_at' && !e.new)?.date ?? null;

                        name = 'Время загрузки';
                        data = this.dateTimeAddTimezone(el.date, this.moduleTimezone,'DD.MM HH:mm');
                        oldData = oldTime ? this.dateTimeAddTimezone(oldTime, this.moduleTimezone,'DD.MM HH:mm') : null;
                        break;
                }

                if (name) {
                    elems.push({
                        name: name,
                        data: data ?? '-',
                        oldData: oldData ?? null
                    })
                }
            })

            return elems
        }
    }
}
</script>

<style scoped>

</style>
