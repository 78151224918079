<template>
    <Auth title="Шины" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-create-button="true"
                    create-button-label="Добавить шину"
                    @moduleGet="getAll()"
                    @openCreateValueDrawer="setCreateDrawerOpen()"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-button
                            v-if="multipleSelection.length > 0"
                            @click="remove(multipleSelection, true)"
                            class="ci-btn ci-btn_white"
                            :icon="icons.delete"
                        />
                    </template>
                </TableHeadBlock>

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Артикул"
                            :body="item.sku ?? '-'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Удалить"
                            @action="remove(item.id, false)"
                        />

                    </template>
                </MobileTableFramework>

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="шин"
                />

                <el-table
                    v-loading="loading"
                    :data="displayData.sort((a, b) => a.wear < b.wear ? 1 : -1)"
                    v-if="!mobile && displayData.length > 0"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="sku"
                        label="Артикул"
                        width="180px">
                        <template v-slot="scope">
                            <span :class="'textMedium ' + (scope.row.sku ? 'primary600' : 'neutral300')">{{
                                    scope.row.sku ? '№' + scope.row.sku : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="vehicle"
                        label="Техника"
                        sortable>
                        <template v-slot="scope">
                            <span
                                @click="$router.push({ name: 'garageDetails', params: { vehicleId: scope.row.techVehicleId } })"
                                class="textMedium textSM success400 pointer">{{ scope.row.vehicle ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mileage"
                        width="200px"
                        label="Пробег (общий)">
                        <template v-slot="scope">
                            {{ scope.row.mileage ? formatNumber(scope.row.mileage) + ' км.' : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mileageVehicle"
                        width="200px"
                        label="Пробег (на оси)">
                        <template v-slot="scope">
                            {{ scope.row.mileageVehicle ? formatNumber(scope.row.mileageVehicle) + ' км.' : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="wear"
                        label="Износ"
                        width="120px">
                        <template v-slot="scope">
                            <template v-if="scope.row.wear === 0">
                                <span class="textMedium textSM neutral300">0%</span>
                            </template>
                            <template v-else-if="scope.row.wear < 100">
                                <span class="textMedium textSM success400">{{ scope.row.wear }}%</span>
                            </template>
                            <template v-else>
                                <span class="textMedium textSM danger300">{{ scope.row.wear }}%</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width="50px"
                    >
                        <template v-slot="scope">
                            <div class="text-right">
                                <el-tooltip class="item" effect="dark" content="Подробности" placement="top">
                                    <el-button @click="setTireEditDialog(scope.row)" plain size="small"
                                               :icon="icons.operation"/>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
        <el-dialog
            v-model="tireEditDialog"
            :title="tireDetails ? tireDetails.name : 'Шина'"
            :width="mobile ? '100%' : '45%'"
            :destroy-on-close="true"
        >
            <div class="textRegular textSM neutral300 mb-10">Пробег (общий): <span class="textMedium textSM success400">{{
                    tireDetails.mileage ? formatNumber(tireDetails.mileage) : 0
                }} км.</span></div>
            <div class="textRegular textSM neutral300 mb-10">Пробег (на оси): <span
                class="textMedium textSM warning300">{{
                    tireDetails.mileageVehicle ? formatNumber(tireDetails.mileageVehicle) : 0
                }} км.</span></div>
            <el-table
                v-loading="loading"
                ref="table"
                empty-text="Нет информации"
                :data="tireDetails.history"
                class="w-100">
                <el-table-column
                    prop="date"
                    label="Дата"
                    width="150"
                    sortable
                >
                    <template v-slot="scope">
                            <span class="textMedium textSM neutral300">{{
                                    getDateFormDB(scope.row.date, 'shortDate')
                                }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="value"
                    label="Значение"
                    sortable
                >
                    <template v-slot="scope">
                            <span class="textMedium textSM primary600">+ {{
                                    formatNumber(scope.row.value)
                                }} км.</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="value"
                    label=""
                    width="100px"
                    fixed="right"
                >
                    <template v-slot="scope">
                        <el-button @click="removeTireHistory(scope.row.id, scope.row.tire_id)" :icon="icons.delete"/>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from "@/views/layouts/Auth.vue";
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState.vue";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import techTabs from "@/mixins/techTabs";

export default {
    name: 'TechTiresIndex',
    data() {
        return {
            loading: true,
            drawerCreate: false,
            tireDetails: null,
            tireEditDialog: null
        }
    },
    components: {
        EmptyState,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, iconsMixin, functionsMixin, mobileCheckMixin, techTabs
    ],
    created() {
        this.getAll()
    },
    methods: {
        getAll() {
            this.loading = true
            this.multipleSelection = []
            httpService().post(this.api.tech.getTires, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getTireHistory(id) {
            httpService().post(this.api.tech.getTireHistory, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                id: id
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.tireDetails.history = data.values

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate
        },
        setTireEditDialog(tire) {
            if (tire) {
                this.tireDetails = tire
                this.getTireHistory(tire.tireId)
            }
            this.tireEditDialog = !this.tireEditDialog
        },
        remove(ids, mass) {
            this.$confirm(
                'Вы действительно хотите произвести удаление?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    let idsDelete = []
                    ids.forEach(el => idsDelete.push(el.id))

                    httpService().post(this.api.tires.remove, {
                        ids: mass ? idsDelete : [ids]
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        removeTireHistory(id, tireId) {
            this.$confirm(
                'Вы действительно хотите произвести удаление?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.tech.removeTireHistory, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                        id: id,
                        tireId: tireId
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                        this.tireEditDialog = !this.tireEditDialog
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || (data.name.toLowerCase().includes(this.search.toLowerCase()) || data.sku.toLowerCase().includes(this.search.toLowerCase()) || data.vehicle.toLowerCase().includes(this.search.toLowerCase())))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        '$route.params.openDrawer': {
            handler: function (openDrawer) {
                if (openDrawer) this.setCreateDrawerOpen()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
