<template>
    <div id="rubezhPlan">


        <el-tabs v-model="activeTab">
            <el-tab-pane label="Планы и задачи" name="plans">

                <RubezhPlanPlansTable
                    @getModule="getModuleData"
                    :loading="loading"
                    :plans="plans"
                    :tasks="tasks"
                    :productions="productions"
                    :production-histories="productionHistories"
                />

            </el-tab-pane>
            <el-tab-pane label="Отчеты о работе" name="reports">

                <RubezhPlanReportTable
                    @getModule="getModuleData"
                    :loading="loading"
                    :reports="reports"
                />

            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import {httpService} from '@/services/http.service'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import RubezhPlanReportTable from "@/views/custom/rubezhPlan/components/RubezhPlanReportTable";
import RubezhPlanPlansTable from "@/views/custom/rubezhPlan/components/RubezhPlanPlansTable";

export default {
    name: 'RubezhPlanIndex',
    components: {RubezhPlanPlansTable, RubezhPlanReportTable},
    inject: ['api'],
    mixins: [functionsMixin, mobileCheckMixin],
    data() {
        return {
            activeTab: 'plans',
            loading: false,
            plans: [],
            tasks: [],
            reports: [],
            productions: [],
            productionHistories: [],
            newReportDrawer: false,
            newPlanDrawer: false
        }
    },
    created() {
        this.getModuleData()
    },
    methods: {
        getModuleData() {
            this.loading = true

            httpService().post(this.api.custom_module.getModuleData, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
                type: 'all'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.plans = data.data.plans
                this.tasks = data.data.tasks
                this.reports = data.data.reports
                this.productions = data.data.productions
                this.productionHistories = data.data.productionHistories
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
