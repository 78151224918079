<template>
    <div class="topCard">
        <h3>{{ type === 'income' ? 'Доходы' : 'Расходы' }} по <span class="topCardLink"
                                                                    @click="toggleTypeCompany">
            {{ typeCompany ? 'контрагентам' : 'категориям' }}
        </span></h3>

        <div class="topCardScroll">
            <el-row class="stickyTop">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="text-500">
                    {{ typeCompany ? 'Контрагент' : 'Категория' }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7" class="text-500">
                    Итог
                </el-col>
            </el-row>
            <template v-for="(item, index) in topValues" :key="index">
                <template v-if="index <= 7">
                    <el-row>
                        <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                            <template v-if="typeCompany">
                                {{
                                    item.id ? (getData(item.id, 'company') ?? 'Контрагент не указан') : (item.total ? 'Контрагент не указан' : '-')
                                }}
                            </template>
                            <template v-else>
                                {{
                                    item.id ? (getInfo(item.id, categories) ?? 'Категория не выбрана') : (item.total ? 'Категория не выбрана' : '-')
                                }}
                            </template>
                        </el-col>
                        <el-col :xl="5" :md="5" :sm="5" :xs="7">
                            <template v-if="item.total">
                        <span style="white-space: nowrap" :class="type === 'income' ? 'color-success' : 'color-error'">{{
                                type === 'income' ? '+' : '-'
                            }} {{ formatNumber(item.total, 2) }} руб.</span>
                            </template>
                            <template v-else>-</template>
                        </el-col>
                    </el-row>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'

export default {
    name: 'CashboxInfoTopBlock',
    props: ['values', 'categories', 'type'],
    mixins: [functionsMixin],
    data() {
        return {
            topValues: [{
                'id': null,
                'total': null
            }],
            typeCompany: true
        }
    },
    methods: {
        toggleTypeCompany() {
            this.typeCompany = !this.typeCompany;
        },
        changeValues() {
            if (this.values.byCompany && this.values.byCategory) {
                let arr = this.values
                if (this.typeCompany) {
                    arr = this.values.byCompany
                } else {
                    arr = this.values.byCategory
                }
                this.topValues = arr
                if (arr.length !== 8) {
                    var i = 0
                    var end = 8 - arr.length
                    for (; i < end; i++) {
                        this.topValues.push([{
                            'id': null,
                            'total': null
                        }])
                    }
                }
            }
        }
    },
    watch: {
        'values': {
            handler: function () {
                this.changeValues()
            },
            deep: true,
            immediate: true
        },
        'typeCompany': {
            handler: function () {
                this.changeValues()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
