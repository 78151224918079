<template>
    <Auth title="Информация о технике">
        <div class="card">
            <div class="card-body">
                <template v-if="loading">
                    <div class="text-center mt-30">
                        <ReportLoader/>

                        <h3>Загрузка данных о технике</h3>
                    </div>
                </template>

                <template v-else>
                    <h2 class="headingBold headingSM neutral900">{{ form.number }}</h2>
                    <hr class="mb-30"/>
                    <el-form ref="form" label-position="top">

                        <el-row :gutter="30">

                            <el-col :sm="8">
                                <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Общая информация</h4>
                                <p class="textRegular textSM neutral500">Укажите общие данные о технике</p>
                            </el-col>
                            <el-col :sm="16">
                                <el-row :gutter="20">
                                    <el-col :sm="8">
                                        <FormInput
                                            v-model.vehicleNumber="form.number"
                                            label="Регистрационный номер"
                                            placeholder="Пр.: Е290КХ750"
                                            :required="true"
                                        />
                                        <FormInput
                                            v-model="form.name"
                                            label="Дополнительное название"
                                            placeholder="Н-р: 320"
                                        />

                                        <FormSelect
                                            v-model="form.type"
                                            label="Тип"
                                            placeholder="Выберите тип"
                                            :items="vehicleTypes"
                                        />

                                    </el-col>
                                    <el-col :sm="8">

                                        <FormSelect
                                            v-model="form.account_module_id"
                                            label="Модуль, к которому привязан"
                                            placeholder="Выберите, если необходимо"
                                            :items="accountModules"
                                        />

                                        <FormSelect
                                            v-model="form.integration_unit_id"
                                            label="Интеграция, к которой привязан"
                                            placeholder="Выберите, если необходимо"
                                            :items="integrations"
                                        />

                                        <FormSelect
                                            v-model="form.vehicle_brand_id"
                                            label="Марка"
                                            placeholder="Выберите марку"
                                            :items="brands"
                                        />
                                    </el-col>
                                    <el-col :sm="8">

                                        <FormInput
                                            v-model="form.soft_id"
                                            v-if="form.integration_unit_id"
                                            label="ID в программе интеграции"
                                            placeholder="ID"
                                        />
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                        <hr class="mb-30"/>

                        <el-row :gutter="30">

                            <el-col :sm="8">
                                <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Связь техники</h4>
                                <p class="textRegular textSM neutral500">Другие технические единицы, с которым связана
                                    данная
                                    техника</p>
                            </el-col>
                            <el-col :sm="16">
                                <RelationGroupBlock
                                    :id="form.id"
                                    type="vehicle"
                                    :integrations="integrations"
                                    :account-modules="accountModules"
                                />
                            </el-col>
                        </el-row>

                        <hr class="mb-30"/>

                        <el-row :gutter="30">

                            <el-col :sm="8">
                                <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Дополнительная
                                    информация</h4>
                                <p class="textRegular textSM neutral500">Информация для формирования документов и
                                    прочее</p>
                            </el-col>
                            <el-col :sm="16">

                                <el-row :gutter="20">

                                    <el-col :sm="8">
                                        <FormNumber
                                            v-model="form.mileage"
                                            label="Пробег текущий (км)"
                                            placeholder="Укажите пробег"
                                        />

                                        <template v-if="form.type === 'mix' || form.type === 'pump'">

                                            <FormSelect
                                                v-model="form.driver_id"
                                                label="Водитель по умолчанию"
                                                placeholder="Выберите водителя"
                                                :items="users.filter(el => el.roleKey === (form.type === 'mix' ? 'mixDriver' : 'pumpDriver'))"
                                            />

                                            <FormNumber
                                                v-model="form.volume"
                                                :label="'Объем ' + (form.type === 'mix' ? 'миксера' : 'насоса')"
                                                placeholder="8"
                                            />

                                            <FormNumber
                                                v-model="form.max_volume"
                                                :label="'Максимальный объем ' + (form.type === 'mix' ? 'миксера' : 'насоса')"
                                                placeholder="8"
                                            />

                                        </template>

                                        <template v-if="form.type === 'pump'">

                                            <FormSelect
                                                v-if="form.rent"
                                                v-model="form.rent_company_id"
                                                label="Контрагент"
                                                placeholder="Выберите контрагента"
                                                :items="companies"
                                                new-item="company"
                                                :required="true"
                                            />

                                            <FormNumber
                                                label="Длина насоса (м)"
                                                placeholder="Пр.: 15"
                                                v-model="form.pump_length"
                                                :required="true"
                                            />

                                            <el-form-item label="Тип стоимости" :required="true">
                                                <el-select
                                                    label="Тип стоимости"
                                                    placeholder="Выберите тип стоимости"
                                                    v-model="form.cost_type"
                                                >
                                                    <el-option value="by-hour" label="За час"/>
                                                    <el-option value="by-delivery" label="За отгрузку"/>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="Арендное ТС">
                                                <el-switch v-model="form.rent" label="Арендованный ТС" size="large"/>
                                            </el-form-item>
                                        </template>
                                    </el-col>
                                    <el-col :sm="8">
                                        <FormNumber
                                            v-model="form.engine_hours"
                                            label="Моточасы текущие (мтч)"
                                            placeholder="Укажите пробег"
                                        />
                                        <template v-if="form.type === 'mix'">
                                            <FormInput
                                                label="Название для ТН"
                                                placeholder="Экскаватор CAT 330"
                                                v-model="form.info.nameForDoc"
                                            />

                                            <FormInput
                                                label="ФИО водителя для ТН"
                                                placeholder="Иванов Иван Иванович"
                                                v-model="form.info.driverFIO"
                                            />
                                        </template>

                                        <template v-if="form.type === 'pump'">
                                            <FormNumber
                                                label="Стоимость насоса (₽, с НДС)"
                                                placeholder="Пр.: 15 000"
                                                v-model="form.pump_price"
                                                :required="true"
                                            />

                                            <FormNumber
                                                v-if="form.rent"
                                                label="Себестоимость насоса (₽, с НДС)"
                                                placeholder="Пр.: 10 000"
                                                v-model="form.pump_cost_price"
                                                :required="true"
                                            />

                                            <FormInput
                                                v-if="form.rent"
                                                label="Имя водителя насоса"
                                                placeholder="Пр.: Иванов Иван Иванович"
                                                v-model="form.info.driverFIO"
                                            />
                                        </template>

                                        <FormNumber
                                            v-model="form.fuel_tank"
                                            label="Объем топливного бака"
                                            placeholder="Укажите максимальный объем"
                                        />
                                    </el-col>
                                    <el-col :sm="8">
                                        <el-form-item label="Количество осей" class='driverFormItem'>
                                            <el-select v-model="form.axle" clearable @clear="form.axle = null"
                                                       placeholder="Выберите, если необходимо">
                                                <el-option
                                                    v-for="axle in axleAmount"
                                                    :key="axle"
                                                    :label="axle"
                                                    :value="axle">
                                                    <span style="float: left">{{ axle }}</span>
                                                </el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label="Есть гидролоток">
                                            <el-switch v-model="form.gidrolotok" size="large" style="height: 38px"/>
                                        </el-form-item>

                                        <el-form-item label="GPS">
                                            <el-switch v-model="form.gps_status" size="large" style="height: 38px"/>
                                        </el-form-item>

                                        <FormNumber
                                            v-model="form.gps_imei"
                                            label="GPS идентификатор"
                                            placeholder="Пр.: 845793845783877"
                                            :required="true"
                                            :step="1"
                                            :disabled='!form.gps_status'
                                        />

                                        <template v-if="form.type === 'pump'">
                                            <FormInput
                                                v-if="form.rent"
                                                label="Телефон водителя"
                                                placeholder="Пр.: +7 926 357-75-56"
                                                inputMask="+7 ### ###-##-##"
                                                v-model="form.info.driverPhone"
                                            />

                                            <FormSelect
                                                v-if="!form.rent"
                                                v-model="form.driver_id"
                                                label="Водитель по умолчанию"
                                                placeholder="Выберите водителя"
                                                :items="users.filter(el => el.roleKey === (form.type === 'mix' ? 'mixDriver' : 'pumpDriver'))"
                                            />
                                        </template>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>

                    </el-form>

                    <div class="mb-30">
                        <el-button type="primary" @click="doCreateVehicle" :disabled="loadingButton">Сохранить
                            изменения
                        </el-button>
                        <el-button :icon="icons.delete" @click="doRemoveVehicle" :disabled="loadingButton"/>
                    </div>
                </template>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from "@/views/layouts/Auth.vue";
import {httpService} from "@/services/http.service";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import vehicleTypes from "@/mixins/vehicleTypes";
import RelationGroupBlock from "@/views/components/RelationGroupBlock/RelationGroupBlock.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "SettingsVehicleDetails",
    components: {FormNumber, RelationGroupBlock, FormInput, FormSelect, ReportLoader, Auth},
    inject: ['api'],
    mixins: [mobileCheckMixin, vehicleTypes, iconsMixin, functionsMixin],
    data() {
        return {
            form: {
                id: null,
                name: null,
                number: null,
                vehicle_brand_id: null,
                type: null,
                driver_id: null,
                volume: null,
                info: {
                    nameForDoc: null,
                    driverFIO: null,
                    driverPhone: null,
                },
                rent: false,
                pump_length: null,
                cost_type: null,
                pump_price: null,
                pump_cost_price: null,
                rent_company_id: null,
                mileage: null,
                engine_hours: null,
                fuel_tank: null,
                account_module_id: null,
                integration_unit_id: null,
                soft_id: null,
                gidrolotok: null,
                axle: null,
                gps_imei: null,
                gps_status: false,
            },

            loading: true,
            loadingButton: false,
            brands: [],
            users: [],
            integrations: [],
            accountModules: [],
            axleAmount: [2, 3, 4, 5]
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        })
    },
    created() {
        this.getOne()

        httpService().post(this.api.settings.getBrands).then((response) => {
            let data = response.data

            if (data.success)
                this.brands = data.brands

        })

        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users

        })
    },
    methods: {
        getOne() {
            this.loading = true
            httpService().post(this.api.vehicle.getOne, {
                id: this.$route.params.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.form = data.vehicle;
                    this.form = {
                        ...data.vehicle,
                        info: {
                            nameForDoc: data.vehicle.info ? (typeof data.vehicle.info.nameForDoc !== 'undefined') ? data.vehicle.info.nameForDoc : null : null,
                            driverFIO: data.vehicle.info ? (typeof data.vehicle.info.driverFIO !== 'undefined') ? data.vehicle.info.driverFIO : null : null,
                            driverPhone: data.vehicle.info ? (typeof data.vehicle.info.driverPhone !== 'undefined') ? data.vehicle.info.driverPhone : null : null,
                        },
                        gidrolotok: data ? (typeof data.vehicle.gidrolotok !== 'undefined') ? data.vehicle.gidrolotok : null : null,
                        gps_imei: data ? (typeof data.vehicle.gps_imei !== 'undefined') ? data.vehicle.gps_imei : null : null,
                        axle: data ? (typeof data.vehicle.axle !== 'undefined') ? data.vehicle.axle : null : null,
                        gps_status: data ? (typeof data.vehicle.gps_imei !== 'undefined') ? !!data.vehicle.gps_imei : null : null,
                        number: this.getVehicleNumber(data.vehicle.number)
                    };
                    this.accountModules = data.accountModules
                    this.integrations = data.integrations
                    this.loading = false
                }

            }).catch(() => {
                this.$message({
                    message: 'Единица техники не найдена',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'vehicles'})
            })
        },
        doCreateVehicle() {
            this.loadingButton = true
            httpService().post(this.api.settings.createOrUpdateVehicle, {
                ...this.form,
                number: this.form.number.replace(/\s/g, '')
            }).then(() => {
                this.$message({
                    message: 'Сохранено',
                    showClose: true,
                    type: 'success'
                })
                this.getOne()
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            })
            this.loadingButton = false
        },
        doRemoveVehicle() {
            this.$confirm(
                'Вы действительно хотите удалить данную единицу техники?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loadingButton = true
                    httpService().post(this.api.settings.removeVehicle, {
                        id: this.form.id
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'vehicles'})
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    })
                    this.loadingButton = false
                })
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.getOne()
            }
        }
    },
}
</script>

<style scoped>

</style>
