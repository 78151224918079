<template>
    <Auth title="Справка">
        <div class="card">
            <div class="card-body">

                <el-button
                    type="text"
                    :icon="icons.back"
                    class="color-grey mb-15"
                    @click="$router.push({ name: 'FaqIndex' })">Вернуться в основной раздел справки
                </el-button>

                <div class="w-100">

                    <el-row gutter="20">
                        <el-col :sm="6">
                            <div class="faqMenu">
                                <h4 @click="$router.push({name: 'FaqView', params: { module: $route.params.module }})">
                                    <FlashIcon v-if="$route.params.module === 'main'"/>
                                    <CashboxIcon v-if="$route.params.module === 'cashbox'"/>
                                    <WeighingIcon v-if="$route.params.module === 'weighing'"/>
                                    <IntegrationsIcon v-if="$route.params.module === 'integrations'"/>
                                    <RelationsIcon v-if="$route.params.module === 'relations'"/>
                                    <SettingsIcon v-if="$route.params.module === 'settings'"/>
                                    {{ getTitle() }}
                                </h4>
                                <el-button
                                    v-for="(question, index) in faqQuestions.filter(el => el.module === $route.params.module)"
                                    :key="index"
                                    v-bind:class="{ active: question.key === $route.params.key }"
                                    @click="$router.push({name: 'FaqViewQuestion', params: { module: $route.params.module, key: question.key }})">{{ question.title }}
                                </el-button>
                            </div>
                        </el-col>
                        <el-col :sm="18" id="faq-details">

                            <template v-if="$route.params.key">

                                <FaqRouter
                                    :component="$route.params.key"
                                />

                            </template>
                            <template v-else>

                                <SettingsFaq v-if="$route.params.module === 'settings'"/>
                                <CashboxFaq v-if="$route.params.module === 'cashbox'"/>

                            </template>

                        </el-col>
                    </el-row>

                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth'
import FaqRouter from "@/views/components/FaqPage/FaqRouter";
import iconsMixin from "@/mixins/iconsMixin";
import faqQuestions from "@/mixins/faqQuestions";
import FlashIcon from "@/views/components/Icons/FlashIcon";
import CashboxIcon from "@/views/components/Icons/CashboxIcon";
import WeighingIcon from "@/views/components/Icons/WeighingIcon";
import IntegrationsIcon from "@/views/components/Icons/IntegrationsIcon";
import RelationsIcon from "@/views/components/Icons/RelationsIcon";
import CashboxFaq from "@/views/components/FaqPage/cashbox/CashboxFaq";
import SettingsIcon from "@/views/components/Icons/SettingsIcon";
import SettingsFaq from "@/views/components/FaqPage/settings/SettingsFaq";

export default {
    name: 'FaqView',
    components: {
        SettingsFaq,
        SettingsIcon,
        CashboxFaq, RelationsIcon, IntegrationsIcon, WeighingIcon, CashboxIcon, FlashIcon, FaqRouter, Auth},
    mixins: [iconsMixin, faqQuestions],
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName]
            var top = element.offsetTop

            window.scrollTo({
                top: top,
                behavior: 'smooth'
            })
        },
        getTitle() {
            let moduleName = this.$route.params.module

            if (moduleName === 'cashbox') return 'Денежный учет'
            if (moduleName === 'settings') return 'Общие вопросы'
        }
    }
}
</script>

<style scoped>

</style>
