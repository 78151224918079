<template>
    <el-tooltip effect="dark" :content="text" placement="top">
        <el-button
            :size="size ?? 'mini'"
            @click="doAction"
            :icon="icons[icon]"
            :disabled="disabled"
            :loading="loading"/>
    </el-tooltip>
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "DrawerButton",
    props: [
        'loading',
        'icon',
        'text',
        'disabled',
        'size'
    ],
    mixins: [
        iconsMixin
    ],
    methods: {
        doAction() {
            this.$emit('action')
        }
    }
}
</script>

<style scoped>

</style>
