<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="getData(valueDetails.good, 'good')"/>

            <DrawerSubTitle
                :sub-title="getDateByTz(valueDetails.date, 'DD.MM.YY в HH:mm', this.tz)"/>

            <DrawerTextLineItem name="Количество">
                <template v-slot:desc>
                    {{ formatNumber(valueDetails.value) }}
                    <good-unit :unit="getData(valueDetails.good, 'good', 'unit')"/>
                </template>
            </DrawerTextLineItem>

            <el-collapse class="mt-30">

                <DrawerCollapseItem :title="getData(valueDetails.good, 'good')" subtitle="Продукция">

                    <template v-slot:body>
                        <DrawerTextLineItem
                            :desc="getData(valueDetails.good, 'good', 'price') ? formatNumber(getData(valueDetails.good, 'good', 'price')) + ' руб.' : 'Не указана'">
                            <template v-slot:name>
                                Стоимость {{
                                    getData(valueDetails.good, 'good', 'price_count') ? 'за ' + getData(valueDetails.good, 'good', 'price_count') : ''
                                }} {{
                                    getData(valueDetails.good, 'good', 'price_count') ? getData(valueDetails.good, 'good', 'unit') : ''
                                }}
                            </template>
                        </DrawerTextLineItem>

                        <DrawerTextLineItem name="Артикул"
                                            :desc="getData(valueDetails.good, 'good', 'sku') ?? 'Не указан'"/>
                    </template>
                </DrawerCollapseItem>

            </el-collapse>
        </template>
        <template v-slot:footer>
            <DrawerDeleteButton :loading="loading" @action="doRemoveValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '../Drawer/DrawerFramework'
import basicMixin from '../../../mixins/basicMixin'
import functionsMixin from '../../../mixins/functionsMixin'
import DrawerHeadTitle from '../Drawer/DrawerHeadTitle'
import DrawerSubTitle from '../Drawer/DrawerSubTitle'
import DrawerTextLineItem from '../Drawer/DrawerTextLineItem'
import DrawerCollapseItem from '../Drawer/DrawerCollapseItem'
import DrawerDeleteButton from '../Drawer/Buttons/DrawerDeleteButton'
import GoodUnit from '@/views/components/goodUnit'
import { httpService } from '@/services/http.service'

export default {
    components: {
        GoodUnit,
        DrawerDeleteButton,
        DrawerCollapseItem, DrawerTextLineItem, DrawerSubTitle, DrawerHeadTitle, DrawerFramework
    },
    props: [
        'valueDetails',
        'tz'
    ],
    name: 'ContractValueDetails',
    data() {
        return {
            loading: false
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        basicMixin, functionsMixin
    ],
    methods: {
        doRemoveValue() {
            this.$confirm(
                'Удалить значение и изменить итоговое значение в контракте по данной продукции?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true
                    httpService().post(this.api.contractValue.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'contract',
                        unitId: this.$route.params.unitId,
                        valueId: this.valueDetails.id
                    }).then(() => {
                        this.$message({
                            message: 'Значение удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('closeDrawer')
                        this.$emit('moduleGet')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>

</style>
