<template>
    <Auth title="ТТС.Контроль">
        <div class="card">
            <div class="card-body">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Объекты" name="objects">

                        <TtsObjects/>

                    </el-tab-pane>
                    <el-tab-pane label="Обмен данных" name="data">

                        <TtsData/>

                    </el-tab-pane>
                    <el-tab-pane label="Пользователи" name="users">

                        <TtsInfo/>

                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import TtsInfo from '@/views/components/TTS/TtsInfo'
import TtsObjects from "@/views/components/TTS/TtsObjects";
import TtsData from "@/views/components/TTS/TtsData";

export default {
    name: "TTSIndex",
    data() {
        return {
            activeTab: 'objects',
            loading: false,
        }
    },
    components: {
        TtsData,
        TtsObjects,
        TtsInfo,
        Auth
    },
}
</script>

<style scoped>

</style>
