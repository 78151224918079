<template>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32.0001" cy="32.0005" r="20.48" fill="url(#paint0_linear_2054_1118)" fill-opacity="0.2"/>
        <circle cx="32" cy="32" r="32" fill="url(#paint1_linear_2054_1118)" fill-opacity="0.05"/>
        <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M30.1369 22.4476C30.0009 22.477 29.7265 22.5834 29.5273 22.6841C29.1801 22.8597 29.0843 22.9427 27.2213 24.684C26.1523 25.6831 25.1665 26.6053 25.0309 26.7333C24.7539 26.9946 24.5126 27.4054 24.3933 27.8185C24.3238 28.0595 24.3201 28.3593 24.3201 33.7552C24.3201 39.0578 24.3248 39.4551 24.3903 39.6919C24.6286 40.5536 25.283 41.2215 26.1752 41.5139C26.3834 41.5821 26.6743 41.5864 31.8389 41.5967C36.5454 41.6062 37.3266 41.5997 37.6039 41.549C38.5576 41.3746 39.3464 40.6443 39.6099 39.6919C39.6757 39.4538 39.6801 38.978 39.6801 31.9976C39.6801 25.0173 39.6757 24.5415 39.6099 24.3033C39.3715 23.4415 38.7149 22.7716 37.8249 22.4818C37.6196 22.415 37.3736 22.4099 33.9949 22.4023C31.0678 22.3958 30.3374 22.4044 30.1369 22.4476ZM33.9749 27.8554C35.2238 28.1506 36.2864 29.0068 36.8448 30.1677C37.0842 30.6653 37.3325 31.6124 37.2675 31.7798C37.1818 32.0004 37.2083 31.9976 35.2208 31.9976C33.2351 31.9976 33.259 32.0001 33.0808 31.7783C32.9991 31.6766 32.9975 31.6405 32.9975 29.9002C32.9975 28.6934 33.0114 28.0985 33.0411 28.0405C33.179 27.771 33.4177 27.7237 33.9749 27.8554ZM31.2506 29.4661C31.3117 29.508 31.3768 29.6034 31.4 29.6851C31.4254 29.7745 31.4412 30.5041 31.4413 31.592C31.4415 33.3196 31.4432 33.3561 31.5248 33.4578C31.7028 33.6793 31.6811 33.6771 33.6438 33.6772C35.3659 33.6774 35.4571 33.681 35.5788 33.7536C35.8654 33.9247 35.8863 34.1633 35.6713 34.8075C35.1525 36.3615 33.7902 37.5208 32.1626 37.7933C31.2675 37.9431 30.2634 37.8083 29.5066 37.4367C29.074 37.2243 28.8181 37.0452 28.4425 36.6921C27.0654 35.3975 26.7132 33.4361 27.5526 31.7375C28.1397 30.5497 29.3488 29.645 30.6685 29.4061C30.982 29.3494 31.0977 29.3613 31.2506 29.4661Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_2054_1118" x1="32.0001" y1="11.5205" x2="32.0001" y2="52.4805" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2054_1118" x1="32" y1="0" x2="32" y2="64" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'DashboardFAQIcon'
}
</script>

<style scoped>

</style>
