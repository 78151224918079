<template>
    <svg :class="[{ 'currentColor': currentColor }]"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9448 10.548L11.8839 11.4883V10.1593V3.83646C11.8839 3.76139 11.9137 3.68939 11.9668 3.63631C12.0199 3.58323 12.0919 3.55341 12.167 3.55341C12.242 3.55341 12.314 3.58323 12.3671 3.63631C12.4202 3.68939 12.45 3.76139 12.45 3.83646V12.1627C12.4493 12.1989 12.4416 12.2346 12.4274 12.2679L12.4274 12.2678L12.4254 12.2726C12.3967 12.3417 12.3417 12.3967 12.2726 12.4254L12.2726 12.4254L12.2679 12.4274C12.2346 12.4416 12.1989 12.4493 12.1627 12.45H3.83646C3.76139 12.45 3.68939 12.4202 3.63631 12.3671C3.58323 12.314 3.55341 12.242 3.55341 12.167C3.55341 12.0919 3.58323 12.0199 3.63631 11.9668C3.68939 11.9137 3.76139 11.8839 3.83646 11.8839H10.1593H11.4883L10.548 10.9448L7.09082 7.49177L3.6339 4.03901C3.63388 4.03899 3.63386 4.03897 3.63383 4.03895C3.58016 3.98523 3.55 3.9124 3.55 3.83646C3.55 3.76048 3.58018 3.68762 3.6339 3.6339C3.68762 3.58018 3.76048 3.55 3.83646 3.55C3.9124 3.55 3.98523 3.58016 4.03895 3.63383C4.03897 3.63386 4.03899 3.63388 4.03901 3.6339L10.9448 10.548Z"
              stroke="#8A909A"
              :stroke-width="strokeWidth ? strokeWidth : '0.7'" />
    </svg>
</template>

<script>
export default {
    name: "ArrowDownRightIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    svg {
        min-width: fit-content;
    }

    .currentColor {
        path {
            fill: currentColor;
        }
    }
</style>
