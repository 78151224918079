export default {
    data() {
        return {
            techWorkTypes: [{
                id: 1,
                name: 'Осмотр'
            }, {
                id: 2,
                name: 'Обслуживание'
            }, {
                id: 3,
                name: 'Ремонт'
            }],
        }
    },
}
