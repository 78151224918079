<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle :title="details.name"/>

            <el-form label-position="top">

                <FormNumber
                    v-model="details.total"
                    label="Текущий остаток"
                    placeholder="Введите остаток"
                />

            </el-form>

        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Сохранить изменения" @action="editGood()"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import {httpService} from '@/services/http.service'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import FormNumber from '@/views/components/Form/FormNumber'

export default {
    name: 'StorehouseGoodEdit',
    components: {
        FormNumber,
        DrawerCreateButton,
        DrawerHeadTitle, DrawerFramework
    },
    props: ['detailGood'],
    inject: ['api'],
    data() {
        return {
            loading: true,
            details: {
                id: null,
                total: null
            }
        }
    },
    created() {
        if (this.detailGood) {
            let elem = this.detailGood.values.find(el => el.storehouseId === this.detailGood.storehouseUnitId)
            this.details = {
                id: elem.id,
                total: elem.total
            }
        }
    },
    methods: {
        editGood() {

            httpService().post(this.api.storehouseGood.edit, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse',
                details: this.details
            }).then(() => {
                this.$emit('moduleGet')
                this.$message({
                    message: 'Изменения приняты',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        }
    },
}
</script>

<style scoped>

</style>
