<template>
    <el-tooltip :placement="mobile ? 'left' : 'bottom'" effect="light" :show-after="100"
                :popper-class="`mix-order-group-table-icon__tooltip mix-order-group-table-icon__tooltip--${type}`">
        <template #content>
            <slot name="tooltip" />
        </template>
        <article :class="['mix-order-group-table-icon bg-neutral50',{
            'mix-order-group-table-icon--active': isActive,
            'mix-order-group-table-icon--waiting': isWaiting
        }]">
            <p v-if="letter" class="mix-order-group-table-icon__letter">{{ letter }}</p>

            <div class="mix-order-group-table-icon__icon" v-if="slots.icon">
                <slot name="icon" />
            </div>
        </article>
    </el-tooltip>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "MixOrderGroupTableIcon",
    props: ['letter', 'color', 'isActive', 'isWaiting', 'type'],
    mixins: [mobileCheckMixin],
    computed: {
        slots() {
            return this.$slots
        }
    },
    methods: {
    }
}
</script>
