<template>
    <div class="analyticsCard analyticsCardSummary">
        <div class="analyticsCardBody">
                    <span
                        class="analyticsCardTopTitle textXS textXSRegular neutral300"
                    >{{ period === 'today' ? 'Время' : 'Дата' }} последней {{ titleUnit }}</span>

            <div class="analyticsCardMainBlock">
                <div class="headingXS headingRegular">
                    <span v-if="!date">--:--</span>
                    <div v-if="date">
                        <div v-if="period === 'today'">
                            <span v-if="getDateFromUnix(date, 'k') < 10">0</span>
                            <number
                                ref="count"
                                :to="getDateFromUnix(date, 'k')"
                                :format="formatNumber"
                                :duration="1"
                                :delay="0"
                                easing="Power4.easeOut"/>:<span v-if="getDateFromUnix(date, 'm') < 10">0</span><number
                            ref="count"
                            :to="getDateFromUnix(date, 'm')"
                            :format="formatNumber"
                            :duration="1"
                            :delay="0"
                            easing="Power4.easeOut"/>
                        </div>
                        <div v-else>
                            <span v-if="getDateFromUnix(date, 'DD') < 10">0</span>
                            <number
                                ref="count"
                                :to="getDateFromUnix(date, 'DD')"
                                :format="formatNumber"
                                :duration="1"
                                :delay="0"
                                easing="Power4.easeOut"/>.<span v-if="getDateFromUnix(date, 'MM') < 10">0</span>
                            <number
                                ref="count"
                                :to="getDateFromUnix(date, 'MM')"
                                :format="formatNumber"
                                :duration="1"
                                :delay="0"
                                easing="Power4.easeOut"/>.<number
                            ref="count"
                            :to="getDateFromUnix(date, 'YY')"
                            :format="formatNumber"
                            :duration="1"
                            :delay="0"
                            easing="Power4.easeOut"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'

export default {
    name: "InfoAnalyticsDateTimeBlock",
    mixins: [functionsMixin],
    props: [
        'titleUnit', // отгрузки/отгрузки и тд
        'date', // дата
        'period', // период
    ]
}
</script>

<style scoped>

</style>
