<template>
    <div class="unpaid-alert-modal">
        <Modal
            v-if="notAvailableModuleAlertType == 'unpaid'"
            v-model="notAvailableModuleAlertStatus"
            @onClose="$router.push({name: 'dashboard'})"
        >
            <template #header-icon>
                <SadEmojiIcon/>
            </template>
            <template #header-title>
                <span>Оплаченный период завершен</span>
            </template>
            <template #header-description>
                Оплаченный период тарифа «{{ user.tariffName }}» подошел к концу.<br/>
                Продлите тариф чтобы продолжить пользоваться модулем
            </template>
            <template #body>
                <WarningNotification class="unpaid-alert-warning-notification">
                    <template #default>
                            <span>
                                Мы будем вынуждены удалить модуль со всеми данными {{ getDateFromUnix(removeDataDate, "D MMMM в HH:mm") }}.
                                Чтобы этого избежать, пожалуйста, улучшите текущий тариф.
                            </span>
                    </template>
                </WarningNotification>
            </template>
            <template #footer>
                <el-button
                    @click="redirectToPaymentPage"
                    type="primary"
                    class="pay-tariff-btn"
                >
                    Оплатить тариф и продолжить пользоваться
                </el-button>
            </template>
        </Modal>

        <Modal
            v-if="notAvailableModuleAlertType == 'tariff_limit'"
            v-model="notAvailableModuleAlertStatus"
            @onClose="$router.push({name: 'dashboard'})"
        >
            <template #header-icon>
                <SadEmojiIcon/>
            </template>
            <template #header-title>
                <span>Этот модуль недоступен по вашему текущему тарифу</span>
            </template>
            <template #header-description>
                Повысьте тариф чтобы пользоваться этим модулем
            </template>
            <template #footer>
                <el-button
                    @click="redirectToTariffPage"
                    type="primary"
                    class="pay-tariff-btn"
                >
                    Оплатить тариф и продолжить пользоваться
                </el-button>
            </template>
        </Modal>
    </div>
</template>

<script>
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import Modal from "@/views/components/Modal.vue";
import SadEmojiIcon from "@/views/components/Icons/SadEmojiIcon.vue";
import WarningNotification from "@/views/components/WarningNotification.vue";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "NotAvailableModuleAlert",
    components: {WarningNotification, SadEmojiIcon, Modal},
    mixins: [functionsMixin],
    computed: {
        ...mapState(useCommonStore, {
            notAvailableModuleAlertStatus: 'getNotAvailableModuleAlertStatus',
            notAvailableModuleAlertType: 'getNotAvailableModuleAlertType',
            removeDataDate: 'getRemoveDataDate',
            user: 'getUser'
        })
    },
    methods: {
        redirectToPaymentPage() {
            this.$router.push({name: 'PaymentIndex'});
        },

        redirectToTariffPage() {
            this.$router.push({name: 'PlansIndex'});
        }
    }
}
</script>

<style>
.unpaid-alert-warning-notification {
    margin-top: 24px;
}

.el-button.pay-tariff-btn {
    padding: 12px 24px !important;
    height: unset !important;
    border-radius: 8px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.unpaid-alert-modal #custom-modal-backdrop {
    margin-left: 64px;
    z-index: 10;
}

.unpaid-alert-modal .custom-modal {
    left: calc(50% - 130px);
    transform: translate(calc(-50% + 64px), -50%);
}
</style>
