<template>

    <FormInput
        v-model="itemValue.buyer_individual_address"
        v-if="itemValue.delivery_type==='delivery'"
        @click="dialog=true"
        class="pointer"
        label="Адрес доставки"
        placeholder="Некрасовское, ул.Советская, д.111"
    />

    <FormInput
        @focusout="createCompany()"
        v-model="itemValue.buyer_individual_name"
        label="Имя клиента"
        placeholder="Иван Андреевич"
    />

    <FormInput
        @focusout="createCompany()"
        v-model="itemValue.buyer_individual_phone"
        label="Телефон клиента"
        placeholder="89990001212"
        inputMask="+7 ### ###-##-##"
    />

    <el-dialog
        v-model="dialog"
        title="Добавить адрес"
        :width="mobile ? '100%' : '65%'"
        :destroy-on-close="true"
    >
        <el-form ref="form" size="small" label-position="top">
            <AddressDeliveryModalCreate
                @onAdd="handleAddAddress"
            />
        </el-form>
    </el-dialog>
</template>

<script>
import FormInput from "@/views/components/Form/FormInput.vue";
import {httpService} from "@/services/http.service";
import {mapActions} from "pinia";
import {useCommonStore} from "@/store/common";
import AddressDeliveryModalCreate from "@/views/components/AddressDeliveryModalCreate.vue";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'IndividualCreate',
    props: ['modelValue'],
    components: {AddressDeliveryModalCreate, FormInput},
    mixins: [mobileCheckMixin],
    data() {
        return {
            itemValue: this.modelValue,
            dialog: false
        }
    },
    inject: ['api'],
    methods: {
        ...mapActions(useCommonStore, [
            'pullCompanies'
        ]),
        createCompany() {
            if (this.itemValue.buyer_individual_phone) {

                httpService().post(this.api.company.createIndividual, {
                    companyId: this.itemValue.buyer_company_id,
                    name: this.itemValue.buyer_individual_name,
                    phone: this.itemValue.buyer_individual_phone
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.itemValue.buyer_company_id = data.company.id
                        this.pullCompanies()
                    } else {
                        this.$message({
                            message: data.message,
                            showClose: true,
                            type: 'error'
                        })
                    }
                }).catch(() => {
                    this.$message({
                        message: 'Ошибка при создании контрагента',
                        showClose: true,
                        type: 'error'
                    })
                })
            }
        },
        handleAddAddress({values, addressId, address}) {
            this.values = values
            this.itemValue.delivery_address_id = addressId
            this.itemValue.buyer_individual_address = address
            this.dialog = false
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
