<template>
    <Auth title="Единицы техники" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    create-button-label="Добавить технику"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="setCreateDrawerOpen"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                    :filter-items1="vehicleTypes"
                />

                <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name ?? '-'"
                        />

                        <MobileTableInfo
                            title="Номер"
                            :body="item.number ?? '-'"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Подробнее"
                            @action="$router.push({name: 'VehicleDetails', params: { id: item.id }})"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="number"
                        label="Номер">
                        <template v-slot="scope">
                            <span class="textSM textMedium success400">{{ getVehicleNumber(scope.row.number) ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="Тип">
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral300">{{ scope.row.type ? this.vehicleTypes.find(el => el.id === scope.row.type).name : '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration"
                        label="Интеграция"
                        width="150px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo
                                    :integration-unit-id="scope.row.integration_unit_id"
                                />
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150px">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="$router.push({name: 'VehicleDetails', params: { id: scope.row.id }})"
                            >Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

                <el-drawer v-model="drawerCreate"
                           title=""
                           direction="rtl"
                           :size="drawerSize"
                           :destroy-on-close="true">
                    <VehicleCreate
                        @closeEditDrawer="drawerCreate=false"
                        @moduleGet="moduleGet"/>
                </el-drawer>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import VehicleCreate from '../../components/SettingsPage/VehiclePage/VehicleCreate'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import { httpService } from '@/services/http.service'
import Filter from "@/views/components/Table/Filter/Filter.vue";
import vehicleTypes from "@/mixins/vehicleTypes";
import functionsMixin from "@/mixins/functionsMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";

export default {
    name: 'SettingsVehicleIndex',
    data() {
        return {
            values: [],
            loading: true,
            drawerCreate: false,
            aboutVehicle: [],
            filter: {
                type: {
                    value: null,
                    type: 'select',
                    label: 'Тип ТС',
                    placeholder: 'Выберите тип',
                    items: '1'
                },
            },
            tabs: [{
                route: 'vehicles',
                name: 'Техника'
            }, {
                route: 'TiresIndex',
                name: 'Шины'
            }]
        }
    },
    components: {
        IntegrationLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        VehicleCreate,
        Filter,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, vehicleTypes, functionsMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.settings.getVehicles).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.data.sort((a, b) => a.name > b.name ? 1 : -1)
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate
        },
        filterValues(values, search, filter) {

            let filtered = values
            if (search) filtered = filtered.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))
            if (filter.type.value) filtered = filtered.filter(data => data.type === filter.type.value)

            this.valueLength = filtered.length
            return filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        '$route.params.openDrawer': {
            handler: function (openDrawer) {
                if (openDrawer) this.setCreateDrawerOpen()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
