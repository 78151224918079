<template>
    <Auth title="Отчет по взвешиваниям">
        <div class="card">
            <div class="card-body">
                <el-form label-position="top">

                    <el-row gutter="15">

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateStart"
                                label="Начало периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateEnd"
                                label="Окончание периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="!showDetails">
                            <el-form-item label="settings" class="hideLabel">
                                <el-button :icon="icons.plus" class="color-grey" @click="() => showDetails = true" type="text">
                                    Дополнительные параметры
                                </el-button>
                            </el-form-item>
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.goods"
                                label="Грузы"
                                placeholder="Выберите грузы"
                                :multiple="true"
                                new-item="good"
                    :items="goods"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormInput
                                v-model="filter.doc_number"
                                label="Номер документа"
                                placeholder="Введите номер или его часть"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.sender"
                                label="Отправитель"
                                placeholder="Выберите компанию"
                                new-item="company"
                                :new-item-data="filter.sender"
                                :items="companies"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.recipient"
                                label="Получатель"
                                placeholder="Выберите компанию"
                                :items="companies"
                                new-item="company"
                                :new-item-data="filter.recipient"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.operation"
                                label="Тип операции"
                                placeholder="Выберите операцию"
                                :items="operations"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.driver"
                                label="Водитель"
                                placeholder="Выберите водителя"
                                :items="drivers"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="showDetails">
                            <FormSelect
                                v-model="filter.vehicle_number"
                                label="Номер ТС"
                                placeholder="Выберите ТС"
                                :items="vehicles"
                            />
                        </el-col>

                        <el-col :sm="24" v-if="showDetails">
                            <el-form-item label="Столбцы таблицы" class="mb-0">
                                <el-checkbox-group v-model="columns">
                                    <el-checkbox v-for="(column, index) in columnsData" :key="index" :label="column.id">
                                        {{ column.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>

                    </el-row>

                    <div class="w-100">
                        <el-button
                            class="color-grey"
                            v-if="showDetails"
                            @click="() => showDetails = false"
                            style="margin-top: 5px; margin-bottom: 15px;"
                            :icon="icons.top"
                            type="text">
                            Скрыть блок настроек
                        </el-button>
                    </div>

                    <div>
                        <el-button :loading="loading" type="primary"
                                   @click="moduleGet">Сформировать отчет
                        </el-button>

                        <el-button plain :icon="icons.back"
                                   @click="$router.push('/report')">Другой отчет
                        </el-button>
                    </div>

                </el-form>

                <hr style="margin-top: 20px"/>

                <div v-if="loading" class="text-center mt-30">
                    <ReportLoader/>

                    <h3>Формирование отчета</h3>
                </div>

                <div v-if="!loading">

                    <div class="reportHead">
                        <h2>Отчет по взвешиваниям</h2>

                        <el-button plain :icon="icons.download"
                                   :loading="loadingButton"
                                   @click="exportToExcel">Экспорт в XLSX
                        </el-button>
                    </div>

                    <p>Период: {{ dateStart }} - {{ dateEnd }}</p>
                    <p>Всего за период: {{ formatNumber(total) }}</p>

                    <el-table
                        empty-text="Информация не найдена"
                        class="mt-15 w-100"
                        v-loading="loading"
                        :data="displayData">

                        <el-table-column
                            v-if="checkColumn('date')"
                            prop="date"
                            label="Дата"
                            width="140px"
                            fixed/>

                        <el-table-column
                            v-if="checkColumn('doc_number')"
                            prop="doc_number"
                            label="Номер документа"/>

                        <el-table-column
                            v-if="checkColumn('good')"
                            prop="good.name"
                            label="Груз">
                            <template v-slot="scope">
                                {{ scope.row.good ? scope.row.good.name : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('operation')"
                            prop="operation"
                            label="Тип операции">
                            <template v-slot="scope">
                                {{ scope.row.operation ? scope.row.operation.name : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('tara')"
                            prop="tara"
                            label="Тара">
                            <template v-slot="scope">
                                {{ scope.row.tara ? formatNumber(scope.row.tara, 2) : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('brutto')"
                            prop="brutto"
                            label="Брутто">
                            <template v-slot="scope">
                                {{ scope.row.brutto ? formatNumber(scope.row.brutto, 2) : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('netto')"
                            prop="netto"
                            label="Нетто">
                            <template v-slot="scope">
                                {{ scope.row.netto ? formatNumber(scope.row.netto, 2) : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('sender_company')"
                            prop="sender_company"
                            label="Отправитель">
                            <template v-slot="scope">
                                {{ scope.row.sender_company ? scope.row.sender_company.name : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('recipient_company')"
                            prop="recipient_company"
                            label="Получатель">
                            <template v-slot="scope">
                                {{ scope.row.recipient_company ? scope.row.recipient_company.name : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('driver')"
                            prop="driver"
                            label="Водитель">
                            <template v-slot="scope">
                                {{ scope.row.driver ? scope.row.driver.name : '-' }}
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('vehicle_number')"
                            prop="vehicle_number"
                            label="Номер ТС">
                            <template v-slot="scope">
                                {{ scope.row.vehicle_number ? scope.row.vehicle_number.name : '-' }}
                            </template>
                        </el-table-column>

                    </el-table>

                    <TablePagination
                        v-model="pageSize"
                        :total="valueLength"
                        :loading="loading"
                        @action="setPage"
                    />
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import FormSelect from '@/views/components/Form/FormSelect'
import FormInput from '@/views/components/Form/FormInput'
import getTimezone from '@/mixins/getTimezone'
import functionsMixin from '@/mixins/functionsMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from '@/mixins/iconsMixin'
import * as dayjs from 'dayjs'
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'ReportWeighingValues',
    components: {Auth, FormInput, FormSelect, FormDateTimePicker, ReportLoader, TablePagination },
    props: ['settings'],
    inject: ['api'],
    mixins: [tableMixin, getTimezone, functionsMixin, iconsMixin],
    created() {
        this.getWeighingData()
        this.moduleGet()
    },
    data() {
        return {
            filter: {
                dateStart: this.$route.query.dateStart ? this.$route.query.dateStart : null,
                dateEnd: this.$route.query.dateEnd ? this.$route.query.dateEnd : null,
                recipient: this.$route.query.recipient ? this.$route.query.recipient : null,
                sender: this.$route.query.sender ? this.$route.query.sender : null,
                doc_number: this.$route.query.doc_number ? this.$route.query.doc_number : null,
                operation: this.$route.query.operation ? this.$route.query.operation : null,
                driver: this.$route.query.driver ? this.$route.query.driver : null,
                vehicle_number: this.$route.query.vehicle_number ? this.$route.query.vehicle_number : null,
                goods: this.$route.query.goods ? this.$route.query.goods : [],
            },
            pageSize: 50,
            total: null,
            goods: [],
            companies: [],
            operations: [],
            vehicles: [],
            drivers: [],
            showDetails: false,
            loading: true,
            loadingButton: false,
            columns: JSON.parse(localStorage.getItem('reportWeighingValues')) ?? ['date', 'good', 'netto', 'operation', 'recipient_company'],
            columnsData: [{
                id: 'date',
                name: 'Дата'
            }, {
                id: 'doc_number',
                name: 'Номер документа'
            }, {
                id: 'good',
                name: 'Груз'
            }, {
                id: 'tara',
                name: 'Тара'
            }, {
                id: 'brutto',
                name: 'Брутто'
            }, {
                id: 'netto',
                name: 'Нетто'
            }, {
                id: 'operation',
                name: 'Тип операции'
            }, {
                id: 'sender_company',
                name: 'Отправитель'
            }, {
                id: 'recipient_company',
                name: 'Получатель'
            }, {
                id: 'driver',
                name: 'Водитель'
            }, {
                id: 'vehicle_number',
                name: 'Номер ТС'
            }]
        }
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.showDetails = false
            this.updateRouterQuery();

            httpService().post(this.api.report.weighingValuesReport, {
                reportKey: this.$route.meta.key,
                settings: this.$route.query,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.total = data.total
                this.loading = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        exportToExcel() {
            this.loadingButton = true

            httpService().post(this.api.exportExcel.exportWeighingReport, {
                settings: this.$route.query,
                filter: this.filter,
                columns: this.columns,
                timezone: this.getTimezone()
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.xlsx')
                document.body.appendChild(link)
                link.click()

                let data = response.data

                if (data.success)
                    this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        getWeighingData() {
            httpService().post(this.api.report.getWeighingData, {
                reportKey: this.$route.meta.key,
                moduleId: this.$route.query.moduleId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.operations = data.operations
                this.vehicles = data.vehicles
                this.drivers = data.drivers
                this.goods = data.goods
                this.companies = data.companies
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        updateRouterQuery() {
            let updatedQuery = {...this.$route.query, ...this.filter};
            this.$router.push({ name: 'ReportWeighingValues', query: updatedQuery });
        },
        checkColumn(name) {
            return this.columns.find(el => el === name)
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    computed: {
        dateStart() {
            return dayjs(this.filter.dateStart).format('DD.MM.YYYY HH:mm')
        },
        dateEnd() {
            return dayjs(this.filter.dateEnd).format('DD.MM.YYYY HH:mm')
        }
    },
    watch: {
        'columns': {
            handler () {
                localStorage.setItem('reportWeighingValues', JSON.stringify(this.columns));
            }
        }
    }
}
</script>
