<template>
    <div class="serviceSaveTemplateSwitch">

        <el-form-item label="" class="mb-0">

            <el-switch
                v-model="periodSettings.status"
                active-color="#4e92f7"
                inactive-color="#a2a5bf">
            </el-switch>

        </el-form-item>
        <div class="serviceSaveTemplateSwitchInfo">
            <p class="color-primary">Задать периодическое повторение</p>
            <span
                class="color-primary">Заказ-наряд будет автоматически создаваться в системе с определенным интервалом</span>

            <div v-if="periodSettings.saveSettings">
                <p class="color-blue" v-if="periodSettings.mileage.countPeriod && periodSettings.type==='mileage'">

                    <span>Заказ-наряд будет повторяться каждые {{
                            formatNumber(periodSettings.mileage.countPeriod)
                        }} {{ unit }}</span>
                    <span v-if="periodSettings.mileage.start">, начиная отсчет пробега с {{
                            formatNumber(periodSettings.mileage.start)
                        }} {{ unit }}</span>
                    <span v-if="periodSettings.mileage.countPeriod">.</span>
                    <span v-if="periodSettings.mileage.remember"> Напомним о ТО за {{
                            formatNumber(periodSettings.mileage.remember)
                        }} {{ unit }} до начала.</span>

                </p>

                <p class="color-blue" v-if="periodSettings.calendar.type && periodSettings.type==='calendar'">

                    <span
                        v-if="periodSettings.calendar.type === 'week' && periodSettings.calendar.weekDaysList.length > 0">
                        При указанных настройках заказ-наряд будет повторяться еженедельно в <span
                        v-for="(day, index) in periodSettings.calendar.weekDaysList"
                        :key="index">{{ getInfo(day, weekDays, 'shortName') }}<span
                        v-if="index !== periodSettings.calendar.weekDaysList.length - 1">, </span></span>
                    </span>

                    <span
                        v-if="periodSettings.calendar.type === 'month' && periodSettings.calendar.monthDaysList.length > 0">
                        При указанных настройках заказ-наряд будет повторяться ежемесячно <span
                        v-for="(day, index) in periodSettings.calendar.monthDaysList.sort((a, b) => a - b)"
                        :key="index">{{ day }}<span
                        v-if="index !== periodSettings.calendar.monthDaysList.length - 1">, </span></span> числа
                    </span>

                    <span
                        v-if="periodSettings.calendar.type === 'year' && periodSettings.calendar.yearDay">
                        При указанных настройках заказ-наряд будет повторяться ежегодно {{ getYearDay() }}
                    </span>

                </p>
            </div>
        </div>
    </div>

    <el-dialog
        v-model="openDialog"
        title="Настройка периодичности повторения"
        :width="!mobile ? '50%' : '100%'"
        :destroy-on-close="true"
        :before-close="checkEndSetup"
    >

        <ServicePeriodDialog
            v-model="periodSettings"
            @closeDialog="closeDialog()"
            :teches="teches"
            :tech-id="techId"
            :startDate="startDate"
            :mileage="mileage"
        />

    </el-dialog>
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import ServicePeriodDialog from '@/views/components/TechPage/Service/ServiceCreateWork/ServicePeriodDialog'
import functionsMixin from '@/mixins/functionsMixin'
import moment from 'moment'

export default {
    name: 'ServicePeriodSettings',
    components: {ServicePeriodDialog},
    props: ['modelValue', 'teches', 'techId', 'startDate', 'mileage', 'type'],
    mixins: [mobileCheckMixin, functionsMixin],
    data() {
        return {
            periodSettings: this.modelValue,
            openDialog: false,
            unit: this.type === 'mileage' ? 'км.' : 'мтч.',
            weekDays: [{
                id: 1,
                name: 'Понедельник',
                shortName: 'пн'
            }, {
                id: 2,
                name: 'Вторник',
                shortName: 'вт'
            }, {
                id: 3,
                name: 'Среда',
                shortName: 'ср'
            }, {
                id: 4,
                name: 'Четверг',
                shortName: 'чт'
            }, {
                id: 5,
                name: 'Пятница',
                shortName: 'пт'
            }, {
                id: 6,
                name: 'Суббота',
                shortName: 'сб'
            }, {
                id: 7,
                name: 'Воскресенье',
                shortName: 'вс'
            }],
        }
    },
    methods: {
        closeDialog() {
            this.periodSettings.saveSettings = true
            window.setTimeout(() => {
                this.openDialog = false
            }, 50)
        },
        checkEndSetup() {
            if (!this.periodSettings.type || !this.periodSettings.saveSettings) {
                this.$emit('resetPeriodSettings')
            }

            this.openDialog = false
        },
        getYearDay() {
            return moment(this.periodSettings.calendar.yearDay).format('DD.MM')
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.periodSettings = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'periodSettings': {
            handler: function () {
                this.$emit('update:modelValue', this.periodSettings)

                if (this.periodSettings.status) this.openDialog = true
                if (!this.periodSettings.status && this.periodSettings.saveSettings) this.periodSettings.saveSettings = false
            },
            deep: true,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
