<template>
  <svg width="74" height="55" viewBox="0 0 74 55" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="74" height="52" fill="white"/>
    <rect width="1" height="44" rx="0.5" transform="matrix(1 0 0 -1 4 48)" fill="#EEEEF0"/>
    <rect x="14.8334" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <rect x="25.6666" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <rect x="36.5" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <rect x="47.3334" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <rect x="58.1666" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <rect x="69" y="4" width="1" height="44" rx="0.5" fill="#EEEEF0"/>
    <path
        d="M4.83325 28.4999C4.83325 28.4999 11.3333 14 17.8333 10.4999C24.3333 6.99979 28.3333 12.9999 32.8333 16.4999C37.3333 19.9999 42.3333 24.9999 46.3333 26.9999C50.3333 28.9999 69.8333 28.9999 69.8333 28.9999"
        stroke="#EEEEEE" stroke-width="2" stroke-linecap="round"/>
    <path
        d="M17.8333 10.4999C11.3333 14 4.83325 28.4999 4.83325 28.4999V54.5H69.8333V28.9999C69.8333 28.9999 50.3333 28.9999 46.3333 26.9999C42.3333 24.9999 37.3333 19.9999 32.8333 16.4999C28.3333 12.9999 24.3333 6.99981 17.8333 10.4999Z"
        fill="url(#paint0_linear_1524_19600)" fill-opacity="0.2"/>
    <defs>
      <linearGradient id="paint0_linear_1524_19600" x1="37.3333" y1="9.44043" x2="37.3333" y2="54.5"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#EEEEF0"/>
        <stop offset="1" stop-color="#858585" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'LineEmpty',
  props: {
    data: {}
  }
};
</script>
