<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <rect width="48" height="48" fill="url(#a)" rx="24"/>
        <path fill="#F74539" d="M34 21v6c0 3.5-2 5-5 5h-8.55c.35-.59.55-1.27.55-2 0-2.21-1.79-4-4-4-1.2 0-2.27.53-3 1.36V21c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5Z" opacity=".4"/>
        <path fill="#F74539" d="M24 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm6.5.25c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75ZM17 26c-1.2 0-2.27.53-3 1.36A4 4 0 0 0 13 30c0 .75.21 1.46.58 2.06A3.97 3.97 0 0 0 17 34c1.01 0 1.93-.37 2.63-1 .31-.26.58-.58.79-.94.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4Zm1.6 5.58c-.15.15-.34.22-.53.22s-.38-.07-.53-.22l-.53-.53-.55.55c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l.55-.55-.53-.53a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l.53.53.5-.5c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-.5.5.53.53c.29.29.29.76 0 1.06Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="48" y1="48" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCB3AE"/>
                <stop offset="1" stop-color="#FCB3AE" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "PayErrorIcon"
}
</script>

<style scoped>

</style>
