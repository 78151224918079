<template>
    <svg viewBox="0 0 493 462" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M210.609 455.582C207.428 455.582 204.79 452.944 204.79 449.764V294.922C204.79 291.742 207.428 289.104 210.609 289.104C213.789 289.104 216.427 291.742 216.427 294.922V449.764C216.427 453.022 213.789 455.582 210.609 455.582ZM6.66193 455.582C3.48133 455.582 0.84375 452.944 0.84375 449.764V55.4461C0.84375 46.2145 8.3686 38.6897 17.6001 38.6897H200.058C204.247 38.6897 208.126 40.3188 211.229 43.4218C214.487 46.68 216.504 51.4121 216.504 55.4461V246.282C216.504 249.463 213.867 252.101 210.686 252.101C207.506 252.101 204.868 249.463 204.868 246.282V55.4461C204.676 54.0105 204.023 52.6762 203.006 51.6448C202.633 51.2327 202.178 50.9027 201.671 50.6756C201.163 50.4486 200.614 50.3296 200.058 50.3261H17.6001C16.2422 50.3261 14.9399 50.8655 13.9797 51.8257C13.0195 52.7859 12.4801 54.0882 12.4801 55.4461V449.764C12.4801 453.022 9.84254 455.582 6.66193 455.582Z" fill="black"/>
        <path d="M210.919 455.582C210.221 455.582 209.445 455.427 208.747 455.116L4.41222 371.257C2.31768 370.404 0.921312 368.387 0.843736 366.137C0.76616 363.887 1.9298 361.793 3.94677 360.707L158.633 279.562C159.314 279.167 160.068 278.915 160.849 278.819C161.63 278.724 162.423 278.789 163.179 279.009C163.934 279.229 164.638 279.6 165.246 280.099C165.854 280.599 166.355 281.217 166.717 281.916C167.08 282.614 167.297 283.379 167.355 284.164C167.413 284.949 167.312 285.738 167.056 286.482C166.801 287.227 166.397 287.912 165.869 288.496C165.341 289.08 164.701 289.551 163.986 289.88L20.3928 365.284L213.091 444.411C214.349 444.907 215.393 445.827 216.043 447.012C216.693 448.197 216.908 449.573 216.65 450.899C216.392 452.226 215.677 453.421 214.63 454.276C213.584 455.132 212.271 455.593 210.919 455.582ZM159.719 237.749C158.77 237.748 157.836 237.508 157.004 237.051L122.327 218.821C121.612 218.491 120.972 218.021 120.444 217.437C119.916 216.853 119.512 216.168 119.257 215.423C119.001 214.679 118.9 213.89 118.958 213.105C119.016 212.32 119.233 211.555 119.596 210.856C119.958 210.157 120.459 209.54 121.067 209.04C121.675 208.54 122.378 208.169 123.134 207.949C123.89 207.729 124.683 207.665 125.464 207.76C126.245 207.855 126.999 208.108 127.68 208.503L162.356 226.733C165.227 228.207 166.313 231.776 164.839 234.568C163.83 236.585 161.813 237.749 159.719 237.749Z" fill="black"/>
        <path d="M6.66193 455.582C4.33466 455.582 2.16254 454.263 1.30921 452.013C0.0679929 449.065 1.54193 445.652 4.48981 444.411L197.188 365.284L3.94678 263.892C2.00739 262.884 0.84375 260.944 0.84375 258.772C0.84375 256.6 2.00739 254.661 3.94678 253.652L69.0328 219.519C71.9031 218.045 75.3941 219.131 76.868 222.001C78.3419 224.872 77.2559 228.362 74.3856 229.836L19.1516 258.772L213.556 360.784C215.573 361.87 216.737 363.965 216.66 366.215C216.582 368.464 215.186 370.481 213.091 371.335L8.83405 455.194C8.13587 455.504 7.36011 455.582 6.66193 455.582Z" fill="black"/>
        <path d="M222.943 303.456C221.547 303.456 220.073 303.301 218.677 302.99L79.8935 270.486C72.1359 268.702 66.7832 262.341 66.7832 254.971V208.503C66.7832 205.322 69.4208 202.685 72.6014 202.685C75.782 202.685 78.4196 205.322 78.4196 208.503V254.971C78.4196 256.833 80.1262 258.539 82.5311 259.16L221.237 291.664C224.34 292.362 227.83 290.811 228.606 288.406C228.839 287.553 228.761 286.699 228.296 285.846C227.598 284.605 226.279 283.752 224.65 283.364L94.5553 252.876C93.2757 252.58 92.1342 251.859 91.317 250.83C90.4999 249.802 90.0554 248.527 90.0559 247.213V213.313C90.0559 210.132 92.6935 207.495 95.8741 207.495C99.0547 207.495 101.692 210.132 101.692 213.313V242.559L227.287 272.038C232.02 273.124 236.131 276.072 238.381 280.028C240.475 283.674 240.941 287.863 239.777 291.819C237.605 298.646 230.701 303.456 222.943 303.456Z" fill="black"/>
        <path d="M131.404 218.355H85.4788C73.2219 218.355 63.9128 211.839 63.9128 203.228V195.16L4.87763 176.619C3.6919 176.239 2.65628 175.495 1.91811 174.492C1.17994 173.489 0.776823 172.279 0.766113 171.034V55.4461C0.766113 46.2145 8.29096 38.6897 17.5225 38.6897H199.438C208.669 38.6897 216.116 46.137 216.194 55.3685L216.659 171.112C216.659 173.672 215.03 175.921 212.548 176.697L152.892 195.238V203.228C152.97 211.839 143.738 218.355 131.404 218.355ZM12.4801 166.845L71.5152 185.385C73.9201 186.161 75.6267 188.411 75.6267 190.971V203.305C75.8594 204.081 79.1952 206.796 85.5564 206.796H131.481C137.842 206.796 141.178 204.159 141.411 203.305V191.048C141.411 188.178 143.506 185.851 146.221 185.308L205.178 166.922L204.713 55.5236C204.713 52.7309 202.386 50.4036 199.593 50.4036H17.6001C16.2421 50.4036 14.9399 50.9431 13.9797 51.9032C13.0195 52.8634 12.4801 54.1657 12.4801 55.5236V166.845Z" fill="black"/>
        <path d="M178.027 73.5988H75.7043C72.5237 73.5988 69.8862 70.9612 69.8862 67.7806C69.8862 64.6 72.5237 61.9624 75.7043 61.9624H178.027C181.207 61.9624 183.845 64.6 183.845 67.7806C183.845 70.9612 181.207 73.5988 178.027 73.5988ZM29.9346 98.2679C26.754 98.2679 24.1165 95.6303 24.1165 92.4497V67.7806C24.1165 64.6 26.754 61.9624 29.9346 61.9624H35.4425C38.6231 61.9624 41.2607 64.6 41.2607 67.7806C41.2607 70.8836 38.7783 73.4436 35.7528 73.5988V92.4497C35.7528 95.6303 33.1152 98.2679 29.9346 98.2679ZM144.979 50.3261H72.5237C69.3431 50.3261 66.7055 47.6885 66.7055 44.5079V25.0364C66.7055 11.7709 77.4886 0.910309 90.8316 0.910309H126.672C139.937 0.910309 150.798 11.6933 150.798 25.0364V44.5079C150.798 47.6885 148.238 50.3261 144.979 50.3261ZM78.3419 38.6897H139.161V25.0364C139.161 18.1321 133.576 12.5467 126.672 12.5467H90.8316C83.9274 12.5467 78.3419 18.1321 78.3419 25.0364V38.6897ZM400.98 438.205H371.811C368.63 438.205 365.993 435.567 365.993 432.387C365.993 429.206 368.63 426.568 371.811 426.568H395.161V403.063H251.491V426.568H262.119C265.299 426.568 267.937 429.206 267.937 432.387C267.937 435.567 265.299 438.205 262.119 438.205H245.673C242.492 438.205 239.855 435.567 239.855 432.387V397.245C239.855 394.064 242.492 391.427 245.673 391.427H400.98C404.16 391.427 406.798 394.064 406.798 397.245V432.387C406.798 435.567 404.16 438.205 400.98 438.205Z" fill="black"/>
        <path d="M326.041 438.205H307.966C304.786 438.205 302.148 435.567 302.148 432.387C302.148 429.206 304.786 426.568 307.966 426.568H326.041C329.222 426.568 331.86 429.206 331.86 432.387C331.86 435.567 329.222 438.205 326.041 438.205ZM486.623 438.205H462.807C459.627 438.205 456.989 435.567 456.989 432.387C456.989 429.206 459.627 426.568 462.807 426.568H480.805V397.245C480.805 394.064 483.443 391.427 486.623 391.427C489.804 391.427 492.441 394.064 492.441 397.245V432.387C492.441 435.567 489.804 438.205 486.623 438.205ZM416.96 438.205H400.98C397.799 438.205 395.161 435.567 395.161 432.387C395.161 429.206 397.799 426.568 400.98 426.568H416.96C420.141 426.568 422.778 429.206 422.778 432.387C422.778 435.567 420.141 438.205 416.96 438.205ZM341.944 375.213C332.092 375.213 322.007 373.196 312.388 369.162L278.177 354.888C277.563 354.647 277.007 354.277 276.548 353.802L237.76 318.738C236.86 317.929 236.234 316.858 235.97 315.677C235.705 314.495 235.816 313.26 236.286 312.144L260.102 255.204C261.033 252.954 263.36 251.558 265.765 251.635L317.974 254.583C318.594 254.583 319.292 254.738 319.913 255.048L354.124 269.322C370.104 275.994 382.982 287.32 390.507 301.284C398.264 315.635 399.428 330.995 393.765 344.571C388.102 358.147 376.388 368.076 360.718 372.653C354.667 374.36 348.383 375.213 341.944 375.213ZM283.53 344.493L316.81 358.379C330.308 364.042 344.737 365.051 357.382 361.405C369.561 357.836 378.638 350.234 382.982 339.994C387.326 329.754 386.318 317.962 380.267 306.714C373.983 295.078 363.123 285.613 349.624 279.95L316.344 266.064L269.256 263.427L248.621 312.842L283.53 344.493ZM245.673 403.063C242.492 403.063 239.855 400.425 239.855 397.245V369.395C239.855 366.215 242.492 363.577 245.673 363.577C248.853 363.577 251.491 366.215 251.491 369.395V397.245C251.491 400.425 248.853 403.063 245.673 403.063Z" fill="black"/>
        <path d="M285.004 461.09C269.178 461.09 256.301 448.212 256.301 432.387C256.301 416.561 269.178 403.684 285.004 403.684C300.829 403.684 313.707 416.561 313.707 432.387C313.784 448.212 300.907 461.09 285.004 461.09ZM285.004 415.242C275.617 415.242 267.937 422.922 267.937 432.309C267.937 441.696 275.617 449.376 285.004 449.376C294.39 449.376 302.07 441.696 302.07 432.309C302.148 422.922 294.468 415.242 285.004 415.242ZM348.926 461.09C333.101 461.09 320.223 448.212 320.223 432.387C320.223 416.561 333.101 403.684 348.926 403.684C364.752 403.684 377.629 416.561 377.629 432.387C377.629 448.212 364.752 461.09 348.926 461.09ZM348.926 415.242C339.54 415.242 331.86 422.922 331.86 432.309C331.86 441.696 339.54 449.376 348.926 449.376C358.313 449.376 365.993 441.696 365.993 432.309C365.993 422.922 358.313 415.242 348.926 415.242ZM439.923 461.09C424.097 461.09 411.22 448.212 411.22 432.387C411.22 416.561 424.097 403.684 439.923 403.684C455.748 403.684 468.626 416.561 468.626 432.387C468.626 448.212 455.748 461.09 439.923 461.09ZM439.923 415.242C430.536 415.242 422.856 422.922 422.856 432.309C422.856 441.696 430.536 449.376 439.923 449.376C449.309 449.376 456.989 441.696 456.989 432.309C456.989 422.922 449.309 415.242 439.923 415.242ZM363.355 403.063H311.457C308.277 403.063 305.639 400.425 305.639 397.245V369.395C305.639 366.215 308.277 363.577 311.457 363.577H363.355C366.536 363.577 369.174 366.215 369.174 369.395V397.245C369.174 400.425 366.536 403.063 363.355 403.063ZM317.275 391.427H357.537V375.213H317.275V391.427ZM486.856 403.063H406.255C403.074 403.063 400.437 400.425 400.437 397.245V326.806C400.437 320.522 405.557 315.48 411.763 315.48H481.348C487.632 315.48 492.674 320.6 492.674 326.806V397.245C492.674 400.425 490.114 403.063 486.856 403.063ZM411.995 391.427H481.038V326.806L411.763 327.116L411.995 391.427Z" fill="black"/>
        <path d="M463.757 105.225H309.232C302.61 105.225 298.195 100.81 298.195 94.1875C298.195 87.565 302.61 83.15 309.232 83.15H437.267L411.881 57.7638C407.466 53.3488 407.466 46.7263 411.881 42.3113C416.296 37.8962 422.919 37.8962 427.334 42.3113L471.484 86.4613C474.795 89.7725 475.899 94.1875 473.691 98.6025C472.587 103.018 468.172 105.225 463.757 105.225ZM309.232 193.525C305.921 193.525 303.714 192.421 301.506 190.214L257.356 146.064C254.045 142.753 252.941 138.338 255.148 133.923C257.356 129.508 260.667 127.3 265.082 127.3H419.607C426.23 127.3 430.645 131.715 430.645 138.338C430.645 144.96 426.23 149.375 419.607 149.375H291.572L316.959 174.761C321.374 179.176 321.374 185.799 316.959 190.214C314.751 192.421 312.544 193.525 309.232 193.525Z" fill="#1984E6"/>
    </svg>

</template>

<script>
export default {
    name: "TTSSmartMixLogo"
}
</script>

<style scoped>

</style>
