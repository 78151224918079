<template>
    <el-row gutter="20">

        <el-col :md="10">
            <div class="widgetSettings h-100">
                <div class="card-body">
                    <h4 class="mt-0">Виджеты слева</h4>
                    <WidgetCardSettings
                        :modules="modules"
                        :widgets="widgets"
                        :widget="widget"
                        :index="index"
                        position="left"
                        :length="leftWidgets.length"
                        @deleteWidget="deleteWidget"
                        @changeSort="changeSort"
                        v-for="(widget, index) in leftWidgets" :key="widget"/>
                    <el-button class="w-100" @click="openModal('left','left')" icon="el-icon-plus">Добавить виджет
                    </el-button>
                </div>
            </div>
        </el-col>

        <el-col :md="14">
            <div class="widgetSettings mb-10">
                <div class="card-body">
                    <h4 class="mt-0">Виджеты справа сверху</h4>
                    <WidgetCardSettings
                        :modules="modules"
                        :widgets="widgets"
                        :widget="widget"
                        :index="index"
                        :length="rightTopWidgets.length"
                        position="right-top"
                        @deleteWidget="deleteWidget"
                        @changeSort="changeSort"
                        v-for="(widget, index) in rightTopWidgets" :key="widget"/>
                    <el-button class="w-100" @click="openModal('right','right-top')" icon="el-icon-plus">Добавить
                        виджет
                    </el-button>
                </div>
            </div>
            <div class="widgetSettings">
                <div class="card-body">
                    <h4 class="mt-0">Виджеты справа снизу</h4>
                    <WidgetCardSettings
                        :modules="modules"
                        :widgets="widgets"
                        :widget="widget"
                        :index="index"
                        :length="rightBottomWidgets.length"
                        position="right-bottom"
                        @deleteWidget="deleteWidget"
                        @changeSort="changeSort"
                        v-for="(widget, index) in rightBottomWidgets" :key="widget"/>
                    <el-button class="w-100" @click="openModal('right', 'right-bottom')" icon="el-icon-plus">Добавить
                        виджет
                    </el-button>
                </div>
            </div>
        </el-col>

    </el-row>

    <el-dialog
        v-model="modal.visible"
        title="Добавление виджета"
        :width="!mobile ? '70%' : '100%'"
        :before-close="closeModal">

        <div v-if="modules.length > 0">
            <div v-if="modules.length > 0">
                <div v-if="modal.type === 'left'">
                    <div v-if="filterModules.left.length > 0">
                        <p class="mt-0">Выберите модуль, который будет отдавать информацию для выбранного виджета:</p>
                        <el-radio class="mb-5 ml-0" @change="filterWidgets" v-model="modal.module" v-for="(module, index) in filterModules.left"
                                  :key="index"
                                  :label="module" border>
                            {{ module.name }}
                        </el-radio>
                    </div>
                    <div v-else>
                        <div class="text-center">
                            <NoDataIcon/>
                            <p>Нет доступных виджетов</p>
                        </div>
                    </div>
                </div>
                <div v-if="modal.type === 'right'">
                    <div v-if="filterModules.left.length > 0">
                        <p class="mt-0">Выберите модуль, который будет отдавать информацию для выбранного виджета:</p>
                        <el-radio class="mb-5 ml-0" v-model="modal.module" @change="filterWidgets"
                                  v-for="(module, index) in filterModules.right"
                                  :key="index"
                                  :label="module" border>
                            {{ module.name }}
                        </el-radio>
                    </div>
                    <div v-else>
                        <div class="text-center">
                            <NoDataIcon/>
                            <p>Нет доступных виджетов</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center">
                <NoDataIcon/>
                <p>Нет доступных виджетов</p>
            </div>
        </div>

        <div v-if="modal.module">
            <p>Выберите виджет:</p>
            <div class="widgetSettings__SelectWidgets">
                <el-radio class="mb-5 ml-0" v-model="modal.widgetId" :disabled="checkExistingWidget(widget)"
                          v-for="(widget, index) in modal.widgets" :key="index"
                          :label="widget.id" border>
                    <div class="imgBlock"
                         :style="'background-image: url(' + require(`@/assets/widgets/${widget.key}@2x.png`) + ')'"></div>
                    <div class="SelectWidgetsBody color-primary">
                        <h4>{{ widget.name }}</h4>
                        <p>{{ widget.description }}</p>
                    </div>
                </el-radio>
            </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeModal">Отмена</el-button>
            <el-button
                :disabled="!modal.widgetId"
                type="primary"
                @click="addWidget()">Добавить виджет</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
import WidgetCardSettings from './WidgetCardSettings'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import NoDataIcon from "@/views/components/NoDataIcon";

export default {
    name: "WidgetSettings",
    components: {NoDataIcon, WidgetCardSettings},
    props: [
        'modules',
        'widgets'
    ],
    mixins: [
        mobileCheckMixin
    ],
    data() {
        return {
            leftWidgets: [],
            rightTopWidgets: [],
            rightBottomWidgets: [],
            setupComplete: false,
            modal: {
                visible: false,
                widgets: [],
                modules: [],
                widgetId: null,
                module: null,
                moduleKey: null,
                type: null,
                position: null,
            },
            filterModules: {
                left: [],
                right: [],
            }
        }
    },
    mounted() {
        this.leftWidgets = this.userWidgets.filter(el => el.position === 'left').sort((a, b) => (a.sort < b.sort) ? 1 : ((b.sort < a.sort) ? -1 : 0))
        this.rightTopWidgets = this.userWidgets.filter(el => el.position === 'right-top').sort((a, b) => (a.sort < b.sort) ? 1 : ((b.sort < a.sort) ? -1 : 0))
        this.rightBottomWidgets = this.userWidgets.filter(el => el.position === 'right-bottom').sort((a, b) => (a.sort < b.sort) ? 1 : ((b.sort < a.sort) ? -1 : 0))
        this.setupComplete = true

    },
    created() {

    },
    computed: {
        ...mapState(useCommonStore, {
            userWidgets: 'getWidgets'
        })
    },
    methods: {
        returnArrByPosition(position) {
            // отдаем массив виджетов, исходя из переданного параметра позиции
            if (position === 'left') return this.leftWidgets
            if (position === 'right-top') return this.rightTopWidgets
            if (position === 'right-bottom') return this.rightBottomWidgets
        },
        deleteWidget({position, index}) {
            // удаляет виджет из массива
            this.returnArrByPosition(position).splice(index, 1)
        },
        checkExistingWidget(widget) {
            // проверяет, есть ли уже такая связка виджет + модуль
            let position = this.modal.position
            let arr = this.returnArrByPosition(position)
            let key = this.widgets.find(el => el.id === widget.id).key

            let allWidgetsByPosition = arr.filter(el => el.key === key)
            let checkWidget = allWidgetsByPosition.find(el => el.moduleId === this.modal.module.id)
            return !!checkWidget
        },
        openModal(type, position) {
            this.modal.visible = true
            this.modal.type = type
            this.modal.position = position
        },
        getModalModules() {
            let moduleKey = this.modal.widgets.find(el => el.id === this.modal.widgetId).module_key
            this.modal.modules = this.modules.filter(el => el.module_key === moduleKey)
        },
        closeModal() {
            this.modal = {
                visible: false,
                widgets: [],
                modules: [],
                widgetId: null,
                module: null
            }
        },
        filterWidgets() {
            if (this.modal.module) {
                this.modal.widgets = this.widgets.filter(el => el.type === this.modal.type)
                this.modal.widgets = this.modal.widgets.filter(el => el.module_key === this.modal.module.module_key)
            }
        },
        addWidget() {
            let widget
            let firstEl
            let sort = 0

            let position = this.modal.position
            let arr = this.returnArrByPosition(position)

            arr.sort(function (a, b) {
                if (a.sort < b.sort) {
                    return -1
                }
                if (a.sort < b.sort) {
                    return 1
                }
                // a должно быть равным b
                return 0
            })

            firstEl = arr.find(x => x !== undefined)
            if (firstEl) sort = firstEl.sort - 1

            widget = {
                id: null,
                key: this.widgets.find(el => el.id === this.modal.widgetId).key,
                moduleId: this.modal.module.id,
                position: position,
                sort: sort,
            }

            arr.push(widget)

            arr.sort(function (a, b) {
                if (a.sort > b.sort) {
                    return -1
                }
                if (a.sort < b.sort) {
                    return 1
                }
                // a должно быть равным b
                return 0
            })

            this.closeModal()
        },
        changeSort({position, index, type}) {
            // меняет позицию виджета
            // сортировка работает по принципу от большего к меньшему
            // т.е. чем больше sort, тем выше виджет в списке
            let arr = this.returnArrByPosition(position)

            if (arr) {
                let changeElem = arr[index]

                if (type === 'up') {
                    // сортируем массив, чтобы при методе find найти
                    // первым элементом тот, у которого ближайшее значение сортировки
                    arr.sort(function (a, b) {
                        if (a.sort > b.sort) {
                            return 1
                        }
                        if (a.sort < b.sort) {
                            return -1
                        }
                        return 0
                    })
                    let otherElem = arr.find(el => el.sort > changeElem.sort)

                    changeElem.sort = changeElem.sort + 1
                    if (otherElem) otherElem.sort = changeElem.sort - 1
                }

                if (type === 'down') {
                    // сортируем массив, чтобы при методе find найти
                    // первым элементом тот, у которого ближайшее значение сортировки
                    arr.sort(function (a, b) {
                        if (a.sort > b.sort) {
                            return -1
                        }
                        if (a.sort < b.sort) {
                            return 1
                        }
                        return 0
                    })
                    let otherElem = arr.find(el => el.sort < changeElem.sort)

                    changeElem.sort = changeElem.sort - 1
                    if (otherElem) otherElem.sort = changeElem.sort + 1
                }

                arr.sort(function (a, b) {
                    if (a.sort > b.sort) {
                        return -1
                    }
                    if (a.sort < b.sort) {
                        return 1
                    }
                    // a должно быть равным b
                    return 0
                })
            }
        },
        filterModulesWidgets() {
            this.widgets.forEach((widget) => {
                let modulesFind = this.modules.filter(el => el.module_key === widget.module_key)
                if (modulesFind) {


                    modulesFind.forEach((object) => {

                        if (widget.type === 'left') {
                            let checkLeft = this.filterModules.left.find((el => el.id === object.id))
                            if (!checkLeft) this.filterModules.left.push(object)
                        } else {
                            let checkRight = this.filterModules.right.find((el => el.id === object.id))
                            if (!checkRight) this.filterModules.right.push(object)
                        }

                    })
                }
            })
        }
    },
    watch: {
        'leftWidgets': {
            handler: function () {
                if (this.setupComplete) {
                    this.$emit('userWidgets', {widgets: this.leftWidgets.concat(this.rightTopWidgets, this.rightBottomWidgets)})
                }
            },
            deep: true,
            immediate: true
        },
        'rightTopWidgets': {
            handler: function () {
                if (this.setupComplete) {
                    this.$emit('userWidgets', {widgets: this.leftWidgets.concat(this.rightTopWidgets, this.rightBottomWidgets)})
                }
            },
            deep: true,
            immediate: true
        },
        'rightBottomWidgets': {
            handler: function () {
                if (this.setupComplete) {
                    this.$emit('userWidgets', {widgets: this.leftWidgets.concat(this.rightTopWidgets, this.rightBottomWidgets)})
                }
            },
            deep: true,
            immediate: true
        },
        'widgets': {
            handler: function () {
                if (this.widgets) this.filterModulesWidgets()
            },
            deep: false,
            immediate: false
        },
        'modules': {
            handler: function () {
                if (this.modules) this.filterModulesWidgets()
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
