<script setup>

</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M8.88003 4.59997C8.08003 1.13997 2.87002 1.12997 2.07002 4.59997C1.60002 6.62997 2.89003 8.34997 4.01003 9.41997C4.83003 10.19 6.12003 10.19 6.94003 9.41997C8.06003 8.34997 9.34003 6.62997 8.88003 4.59997ZM5.51003 6.19997C4.96003 6.19997 4.51003 5.74997 4.51003 5.19997C4.51003 4.64997 4.95003 4.19997 5.50003 4.19997H5.51003C6.07003 4.19997 6.51003 4.64997 6.51003 5.19997C6.51003 5.74997 6.07003 6.19997 5.51003 6.19997Z" fill="#1984E6"/>
        <path d="M6.50977 5.19995C6.50977 5.74995 6.06977 6.19995 5.50977 6.19995C4.95977 6.19995 4.50977 5.74995 4.50977 5.19995C4.50977 4.64995 4.94977 4.19995 5.49977 4.19995H5.50977C6.06977 4.19995 6.50977 4.64995 6.50977 5.19995Z" fill="#1984E6"/>
        <path opacity="0.4" d="M21.91 16.6C21.11 13.14 15.88 13.13 15.07 16.6C14.6 18.63 15.89 20.35 17.02 21.42C17.84 22.19 19.14 22.19 19.96 21.42C21.09 20.35 22.38 18.63 21.91 16.6ZM18.53 18.2C17.98 18.2 17.53 17.75 17.53 17.2C17.53 16.65 17.97 16.2 18.52 16.2H18.53C19.08 16.2 19.53 16.65 19.53 17.2C19.53 17.75 19.08 18.2 18.53 18.2Z" fill="#1984E6"/>
        <path d="M19.5298 17.2C19.5298 17.75 19.0798 18.2 18.5298 18.2C17.9798 18.2 17.5298 17.75 17.5298 17.2C17.5298 16.65 17.9698 16.2 18.5198 16.2H18.5298C19.0798 16.2 19.5298 16.65 19.5298 17.2Z" fill="#1984E6"/>
        <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill="#1984E6"/>
    </svg>
</template>

<style scoped>

</style>
