<template>
    <el-form-item
        class='formInput'
        :label="label"
        v-bind:class="{ 'is-required': required }"
    >
        <el-input
            :type="type ?? 'text'"
            v-model="itemValue"
            @input="emitValue"
            :placeholder="placeholder"
            :show-password="type === 'password'"
            :disabled="disabled"
            :readonly="readonly"
            v-maska
            :data-maska="inputMask"
            :data-maska-tokens="maskTokens"
            @change="$emit('changeAction')"
            @focusout="$emit('focusoutAction')"
        />
        <p
            v-if='validationMessage'
            class="formInput__validation-message textXS m-0">
            {{validationMessage}}
        </p>
    </el-form-item>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin.js";
import {vMaska} from "maska";

export default {
    name: "FormInput",
    props: [
        'modelModifiers',
        'modelValue',
        'label',
        'placeholder',
        'type',
        'disabled',
        'readonly',
        'required',
        'inputMask',
        'maskTokens',
        'validationMessage'
    ],
    data () {
        return {
            itemValue: this.modelValue
        }
    },
    mixins: [functionsMixin],
    directives: {
        maska: vMaska
    },
    methods: {
         emitValue() {
            if(this.modelModifiers?.vehicleNumber) {
                this.itemValue = this.getFormattedVehicleNumber(this.itemValue) ? this.getFormattedVehicleNumber(this.itemValue) : ""
            }
            this.$emit('update:modelValue', this.itemValue)
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
    }
}
</script>
