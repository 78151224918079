<template>
    <div id="emptyState" v-if="values.length === 0 && (emptyFilter || !emptyFilter)">
        <div v-if="values.length === 0 && emptyFilter && !loading">
            <EmptyListIcon/>
            <h3 class="mb-0 mt-0 neutral900">Список {{ listTypes }} пуст</h3>
            <p class="mt-0 textRegular textSM neutral400">{{ actionText ?? 'Создайте первую запись' }}</p>
        </div>
        <div v-else>
            <template v-if="!loading">
                <h3 class="mb-10 mt-0 neutral900">Не удалось найти ни одной записи, удовлетворяющих критериям поиска</h3>
                <p class="mt-0 textRegular textSM neutral400">Попробуйте изменить значения фильтров. Так же возможно, что такой записи не существует</p>
            </template>
            <template v-else>
                <div class="text-center">
                    <ReportLoader/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: 'EmptyState',
    components: {ReportLoader, EmptyListIcon},
    props: ['values', 'filter', 'search', 'loading', 'listTypes', 'actionText'],
    data() {
        return {
            firstLoad: true,
            emptyFilter: true
        }
    },
    watch: {
        'values': {
            handler: function () {
                if (this.values && this.filter) {
                    this.emptyFilter = true

                    if (this.search) {
                        this.emptyFilter = false
                    } else {

                        let filterValues = Object.values(this.filter)

                        filterValues.forEach((element) => {
                            if (element.value) {
                                this.emptyFilter = false
                            }
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'filter': {
            handler: function () {
                if (this.values && this.filter) {
                    this.emptyFilter = true

                    if (this.search) {
                        this.emptyFilter = false
                    } else {

                        let filterValues = Object.values(this.filter)

                        filterValues.forEach((element) => {
                            if (element.value) {
                                this.emptyFilter = false
                            }
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'search': {
            handler: function () {
                if (this.values && this.filter) {
                    this.emptyFilter = true

                    if (this.search) {
                        this.emptyFilter = false
                    } else {

                        let filterValues = Object.values(this.filter)

                        filterValues.forEach((element) => {
                            if (element.value) {
                                this.emptyFilter = false
                            }
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) this.emptyFilter = true
        }
    }
}
</script>

<style scoped>

</style>
