<template>
    <Auth :title="title">
        <div class="card">
            <div class="card-title">
                <el-row>
                    <el-col :sm="12">
                        Информация о контракте
                    </el-col>
                    <el-col :sm="12" style="display: flex; justify-content: flex-end;">
                        <el-button :loading="loadingButton" @click="deleteContract" size="mini"
                                   style="position: relative; margin-top: -11px; top: 6px;" plain>Удалить
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="card-body">
                <el-row gutter="20">
                    <el-col :sm="5">
                        <el-progress type="dashboard" :percentage="contract.percent" :color="customColors"/>
                    </el-col>
                    <el-col :sm="8">
                        <p><span class="color-grey">Статус контракта:</span> {{ contract.status.name ?? '-' }}</p>
                        <p><span class="color-grey">Срок выполнения:</span>
                            {{ getDateByTz(contract.date_start, 'DD.MM.YY', this.tz) }}
                            - {{ getDateByTz(contract.date_end, 'DD.MM.YY', this.tz) }}</p>
                        <p><span class="color-grey">Контрагент:</span>
                            {{ contract.companyId ? getData(contract.companyId, 'company') : '-' }} (ИНН
                            {{ contract.companyId ? getData(contract.companyId, 'company', 'inn') : '-' }})</p>
                    </el-col>
                    <el-col :sm="8">
                        <p><span class="color-grey">Необходимо:</span>
                            {{ contract.total ? formatNumber(contract.total) : 0 }}
                            <good-unit :unit="contract.countUnit"/>
                        </p>
                        <p><span class="color-grey">Выполнено:</span>
                            {{ contract.done ? formatNumber(contract.done) : 0 }}
                            <good-unit :unit="contract.countUnit"/>
                        </p>
                    </el-col>
                </el-row>

                <div v-if="contract">

                    <MobileTableFramework v-for="(item, index) in contract.goods" :key="index">
                        <template v-slot:body>

                            <MobileTableProgressBar
                                title="Прогресс"
                                :progress="Math.round(item.done / item.total * 100)"
                            />

                            <MobileTableInfo
                                title="Продукция"
                                :body="item.good_id ? getData(item.good_id, 'good') : '-'"
                            />

                            <MobileTableInfo
                                title="Необходимо"
                                :body="formatNumber(item.total) + ' ' + (item.good_id ? getData(item.good_id, 'good', 'unit') : 'ед.')"
                            />

                            <MobileTableInfo
                                title="Выполнено"
                                :no-border="true"
                                :body="formatNumber(item.done) + ' ' + (item.good_id ? getData(item.good_id, 'good', 'unit') : 'ед.')"
                            />

                        </template>
                    </MobileTableFramework>

                </div>

                <el-table
                    empty-text="Информация не найдена"
                    v-loading="loading"
                    v-if="!smallMobile"
                    :data="contract.goods"
                    class="w-100">
                    <el-table-column
                        prop="good"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            {{ getData(scope.row.good_id, 'good') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width="150px"
                    >
                        <template v-slot="scope">
                            <el-progress :color="customColors"
                                         :percentage="Math.round(scope.row.done / scope.row.total * 100)"></el-progress>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Необходимо"
                        width="150px"
                    >
                        <template v-slot="scope">
                            {{ formatNumber(scope.row.total) }}
                            <good-unit :unit="getData(scope.row.good_id, 'good', 'unit')"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="done"
                        label="Выполнено"
                        width="150px"
                    >
                        <template v-slot="scope">
                            {{ formatNumber(scope.row.done) }}
                            <good-unit :unit="getData(scope.row.good_id, 'good', 'unit')"/>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    v-model="search"
                    :disable-search="true"
                    :loading="loadingButton"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openDrawer"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="goods"
                    :loading="loadingButton"
                    :filter-show="filterShow"
                />

                <div v-if="displayData">

                    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                        <template v-slot:body>

                            <MobileTableDate
                                :date="item.date"
                            />

                            <MobileTableInfo
                                title="Продукция"
                                :body="item.good ? getData(item.good, 'good') : '-'"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :no-border="true"
                                :body="formatNumber(item.value) + ' ' + (item.good ? getData(item.good, 'good', 'unit') : 'ед.')"
                            />

                            <MobileTableButton
                                @action="openDrawerValueDetails(item)"
                            />

                        </template>
                    </MobileTableFramework>

                </div>

                <el-table
                    empty-text="Информация не найдена"
                    ref="table"
                    class="mt-15 w-100"
                    v-if="!smallMobile"
                    v-loading="loading"
                    :data="displayData"
                    :summary-method="getSummaries"
                    show-summary>
                    <el-table-column
                        label="Дата"
                        width="130px"
                        prop="date">
                        <template v-slot="scope">
                            {{ getDateByTz(scope.row.date, 'DD.MM.YY в HH:mm', this.tz) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Продукция"
                        prop="good">
                        <template v-slot="scope">
                            {{ getData(scope.row.good, 'good') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Кол-во"
                        width="150px"
                        class-name="colored"
                        prop="value">
                        <template v-slot="scope">
                            {{ formatNumber(scope.row.value) }}
                            <good-unit :unit="getData(scope.row.good, 'good', 'unit')"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width="130">
                        <template v-slot="scope">
                            <el-button size="mini" @click="openDrawerValueDetails(scope.row)">Подробнее</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            v-model="drawer"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ContractValueCreate
                :goods="goods"
                @closeDrawer="() => drawer = false"
                @moduleGet="moduleGet"
            />
        </el-drawer>

        <el-drawer
            v-model="drawerValueDetails"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ContractValueDetails
                :valueDetails="valueDetails"
                :tz="tz"
                @closeDrawer="() => drawerValueDetails = false"
                @moduleGet="moduleGet"
            />
        </el-drawer>

    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import basicMixin from '../../mixins/basicMixin'
import tableMixin from '../../mixins/tableMixin'
import functionsMixin from '../../mixins/functionsMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import moment from 'moment'
import TableHeadBlock from '../components/Table/TableHeadBlock'
import Filter from '../components/Table/Filter/Filter'
import TablePagination from '../components/Table/TablePagination'
import ContractValueCreate from '../components/ContractPage/ContractValueCreate'
import ContractValueDetails from '../components/ContractPage/ContractValueDetails'
import GoodUnit from '@/views/components/goodUnit'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableProgressBar from '@/views/components/Mobile/Table/MobileTableProgressBar'
import { httpService } from '@/services/http.service'

export default {
    name: 'ContractShow',
    data() {
        return {
            contract: {
                goods: null,
                name: null,
                date_start: null,
                date_end: null,
                company: {
                    name: null,
                    inn: null,
                },
                status: {
                    name: null,
                },
                percent: 0,
                countUnit: '',
                total: 0,
                done: 0,
            },
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
                goodId: {
                    value: null,
                    type: 'select',
                    typeSelect: 'goods',
                    items: '1'
                },
            },
            searchText: null,
            drawer: false,
            contractGood: null,
            title: false,
            createValueForm: {
                goodId: null,
                value: null,
                date: null,
            },
            customColors: [
                { color: '#f56c6c', percentage: 20 },
                { color: '#e6a23c', percentage: 40 },
                { color: '#7fc354', percentage: 60 },
                { color: '#4e92f7', percentage: 80 },
                { color: '#00cdc4', percentage: 100 }
            ],
            statuses: [],
            drawerValueDetails: false,
            valueDetails: null,
            tz: null
        }
    },
    components: {
        MobileTableProgressBar,
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate,
        MobileTableFramework,
        GoodUnit,
        ContractValueDetails,
        ContractValueCreate,
        TablePagination,
        Filter,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        basicMixin, tableMixin, functionsMixin, mobileCheckMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.contract.getOne, {
                contract_id: this.$route.params.unitId,
                account_module_reserve_id: this.$route.params.id,
                module_key: 'contract'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.contract = data.contract
                this.title = data.contract.name
                this.values = data.values
                this.goods = data.goods
                this.contract.countUnit = data.count_unit
                this.tz = data.timeZone
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        openDrawer() {
            this.drawer = true
        },
        deleteContract() {
            this.$confirm(
                'Вы действительно хотите удалить контракт и все его значения?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.contract.remove, {
                        contract_id: this.$route.params.id,
                        contract_unit_id: this.$route.params.unitId,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'contract'
                    }).then(() => {
                        this.$router.push({ name: 'ContractIndex', params: { id: this.$route.params.id } })
                        this.$message({
                            message: 'Контракт удален',
                            showClose: true,
                            type: 'success'
                        })
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        openDrawerValueDetails(row) {
            this.drawerValueDetails = true
            this.valueDetails = row
        },
        getSummaries(param) {
            const { columns } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 2) {
                    let total = 0
                    this.displayData.forEach(function (value) {
                        total = total + Number(value.value)
                    })
                    sums[index] = total.toFixed(0) + ' ' + this.contract.countUnit
                    return
                }
            })

            return sums
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.good.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.date >= moment(this.filter.dateStart.value).add(3, 'hours').add(this.getTimezone(), 'hours').unix())
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.date <= moment(this.filter.dateEnd.value).add(3, 'hours').add(this.getTimezone(), 'hours').unix())
            if (this.filter.goodId.value) this.filtered = this.filtered.filter(data => data.good === this.filter.goodId.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
