import moment from 'moment';

const isSameDate = (date1, date2) => {
    const date1Format = moment(date1).format('DD.MM.YYYY');
    const date2Format = moment(date2).format('DD.MM.YYYY');
    return date1Format === date2Format;
};

const toServerIsoDate = (momentDate) => moment(momentDate).format('YYYY-MM-DD HH:mm:ss');

export {
    isSameDate,
    toServerIsoDate
};
