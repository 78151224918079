<template>
    <div class="integrationLogos" v-if="unit">
        <template v-if="unit.integration.key === 'SmartMix'">
            <span class="textMedium textSM neutral900 cutOverflow d-block"><span class="primary600" :class="[
                { 'mekaColor': unit.product === 'MEKA' },
                { 'elkonColor': unit.product === 'Elkon' },
                { 'smartMixColor': unit.product === 'SmartMix' }
            ]">{{ unit.product ? getInfo(unit.product, TTSProducts) : 'БСУ' }}:</span> {{ getLabel() }}</span>
        </template>
        <template v-else-if="unit.integration.key === '1C'">
            <span class="textMedium textSM neutral900 cutOverflow d-block"><span style="color: #da121a">1C:</span> {{ getLabel() }}</span>
        </template>
        <VesySoftLogo v-if="unit.integration.key === 'VesySoft'"/>
        <TTSSmartWeightLogo
            v-if="unit.integration.key === 'SmartWeight'"/>
        <KIPConceptLogo v-if="unit.integration.key === 'ConceptBCY'"/>
        <WialonLogo v-if="unit.integration.key === 'WialonMix'"/>
        <GlonassSoftIcon
            v-if="unit.integration.key === 'GlonassSoft'"/>
        <WhatsAppLogo v-if="unit.integration.key === 'Wappi'"/>
        <h3 v-if="unit.integration.key === 'Trekerserver'"
            style="text-transform:uppercase;">Trekerserver</h3>
    </div>
</template>


<script>
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import TTSSmartWeightLogo from "@/views/components/Logo/TTSSmartWeightLogo.vue";
import WialonLogo from "@/views/components/Icons/WialonLogo.vue";
import VesySoftLogo from "@/views/components/Logo/VesySoftLogo.vue";
import GlonassSoftIcon from "@/views/components/Icons/GlonassSoftIcon.vue";
import WhatsAppLogo from "@/views/components/Logo/WhatsAppLogo.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";
import TTSProducts from "@/mixins/TTSProducts";

export default {
    name: "IntegrationLogo",
    components: {
        WhatsAppLogo,
        GlonassSoftIcon,
        VesySoftLogo,
        WialonLogo,
        TTSSmartWeightLogo,
        KIPConceptLogo,
    },
    props: ['integrationUnitId'],
    mixins: [functionsMixin, TTSProducts],
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
        unit() {
            return this.integrations.find(integration => integration.id === this.integrationUnitId)
        }
    },
    methods: {
        getLabel() {
            let label = this.unit.integration.name
            if (this.unit.integration.key === 'SmartMix') {
                if (this.unit.account_module) {
                    label = this.unit.account_module.name
                }
            } else if (this.unit.integration.key === '1C') {
                if (this.unit.company) {
                    label = this.unit.company.short_name ?? this.unit.company.name
                } else {
                    label = 'интеграция с Бухаглтерией'
                }
            }
            return label
        }
    }
}
</script>

<style scoped>

</style>
