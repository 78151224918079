<template>
    <h2>Общие вопросы</h2>
    <p>В данном разделе представлены общие вопросы, касающиеся настроек и других аспектов работы с сервисом.</p>
</template>

<script>
export default {
    name: "SettingsFaq"
}
</script>

<style scoped>

</style>
