<template>
    <div>
        <p class="textXS textRegular neutral400 mt-0">Связанные значения</p>
        <el-table
            empty-text="Значений нет"
            :data="values"
            v-loading="loading"
            border
            :show-header="false"
            class="w-100 mt-15">
            <el-table-column
                prop="id"
                width="80"
                label="#">
                <template v-slot="scope">
                    <span class="neutral300">#{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="id"
                label="#">
                <template v-slot="scope">
                    <div
                        @click="$router.push({ name: getRoutePath(), params: { id: scope.row.id } })"
                        class="textSM textMedium primary600 pointer">
                        {{ scope.row.name }}
                        <template v-if="scope.row.parent_id">
                            <span class="neutral700">({{ scope.row.parent?.name }})</span>
                        </template>
                        <template v-if="parentId && scope.row.id === parentId">
                            <span class="neutral700">(Основной)</span>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="type"
                v-if="type === 'good'"
                label="#">
                <template v-slot="scope">
                    <div class="textSM neutral400">{{
                            getTypeName(scope.row.type)
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="integration_unit_id"
                label="#">
                <template v-slot="scope">
                    <IntegrationLogo
                        :integration-unit-id="scope.row.integration_unit_id"
                    />
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label=""
                width="66">
                <template v-slot="scope">
                    <el-button
                        size="small"
                        :icon="icons.delete"
                        @click="remove(scope.row.id)"
                    />
                </template>
            </el-table-column>
        </el-table>

        <el-button @click="openNewItemDialog" class="mt-5" type="text" :icon="icons.plus">
            Добавить новую связь
        </el-button>
    </div>

    <el-dialog
        v-model="newItemDialog"
        title="Добавление новой связи"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <GoodCreateRelationDialog
            :good-id="id"
            v-if="type === 'good'"
            @closeDialog="() => newItemDialog = false"
            @createRelationAndParent="createAndParent"
            @createRelation="create"
            @moduleGet="get"
        />
        <CompanyCreateRelationDialog
            :company-id="id"
            v-if="type === 'company'"
            @closeDialog="() => newItemDialog = false"
            @createRelation="create"
            @moduleGet="get"
            :account-modules="accountModules"
        />
        <VehicleCreateRelationDialog
            :vehicle-id="id"
            v-if="type === 'vehicle'"
            @closeDialog="() => newItemDialog = false"
            @createRelation="create"
            @moduleGet="get"
            :account-modules="accountModules"
        />
    </el-dialog>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import functionsMixin from '@/mixins/functionsMixin'
import GoodCreateRelationDialog from '@/views/components/RelationGroupBlock/GoodCreateRelationDialog.vue'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import {httpService} from "@/services/http.service";
import CompanyCreateRelationDialog from "@/views/components/RelationGroupBlock/CompanyCreateRelationDialog.vue";
import VehicleCreateRelationDialog from "@/views/components/RelationGroupBlock/VehicleCreateRelationDialog.vue";
import goodTypes from "@/mixins/goodTypes";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";

export default {
    name: 'RelationGroupBlock',
    components: {IntegrationLogo, VehicleCreateRelationDialog, CompanyCreateRelationDialog, GoodCreateRelationDialog},
    props: ['id', 'type', 'accountModules', 'parentId'],
    inject: ['api'],
    mixins: [iconsMixin, functionsMixin, mobileCheckMixin, goodTypes],
    data() {
        return {
            loading: true,
            values: [],
            newItemDialog: false,
            vehicles: [],
            parent: false,
        }
    },
    created() {
        this.get()

        if (this.type === 'vehicle') {
            this.getVehicles()
        }
    },
    methods: {
        get() {
            this.loading = true
            httpService().post(this.api.relationGroup.get, {
                id: this.id,
                type: this.type
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        createAndParent(id) {
            this.parent = true
            this.create(id)
        },
        create(id) {
            this.loading = true
            httpService().post(this.api.relationGroup.create, {
                id: this.id,
                newItemId: id,
                type: this.type,
                parent: this.parent
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Связь образована',
                        showClose: true,
                        type: 'success'
                    })
                    this.get()
                    this.newItemDialog = false
                    this.parent = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm(
                'Вы действительно удалить данную связь?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Подтверждаю',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true
                    httpService().post(this.api.relationGroup.remove, {
                        id: this.id,
                        newItemId: id,
                        type: this.type
                    }).then((response) => {
                        let data = response.data

                        if (data.success) {
                            this.$message({
                                message: 'Связь удалена',
                                showClose: true,
                                type: 'success'
                            })
                            this.get()
                        }
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        openNewItemDialog() {
            this.newItemDialog = true
        },
        getRoutePath() {
            let path = 'GoodDetails'
            if (this.type === 'company') {
                path = 'CompanyEdit'
            } else if (this.type === 'vehicle') {
                path = 'VehicleDetails'
            }
            return path
        },
        getVehicles() {
            httpService().post(this.api.settings.getVehicles).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.data.sort((a, b) => a.name > b.name ? 1 : -1)
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
    },
    watch: {
        'id': {
            handler: function () {
                this.get()
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
