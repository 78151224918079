<template>
    <el-col :sm="6">

        <FormSelect
            v-model="itemModel"
            :label="itemLabel"
            :placeholder="itemPlaceholder"
            :items="arr ?? items"
        />

    </el-col>
</template>

<script>
import FormSelect from '../../Form/FormSelect'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'

export default {
    name: 'FilterSelect',
    components: { FormSelect },
    props: [
        'modelValue',
        'items',
        'label',
        'placeholder',
        'type'
    ],
    data() {
        return {
            itemModel: null,
            itemLabel: this.label ?? 'Выберите',
            itemPlaceholder: this.placeholder ?? 'Выберите',
            arr: null,
        }
    },
    created() {
        if (this.type === 'goods') {
            this.itemLabel = 'Продукция'
            this.itemPlaceholder = 'Груз или товар'
            this.arr = this.goods
        }
        if (this.type === 'companies') {
            this.itemLabel = 'Контрагент'
            this.itemPlaceholder = 'Выберите компанию'
            this.arr = this.companies
        }
        if (this.type === 'shifts') {
            this.itemLabel = 'Ответственная бригада'
            this.itemPlaceholder = 'Производственная бригада'
        }
        if (this.type === 'users') {
            this.itemLabel = 'Ответственный сотрудник'
            this.itemPlaceholder = 'Сотрудник'
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
        })
    },
    watch: {
        'itemModel': {
            handler: function () {
                this.$emit('update:modelValue', this.itemModel)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.itemModel = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
