<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Создание производственной смены"/>

            <el-form ref="form" :model="createShiftForm" size="small" label-position="top">

                <FormInput
                    v-model="createShiftForm.name"
                    label="Название смена"
                    placeholder="Смена бригадира Сменова И.В."
                />

                <hr/>

                <p class="textRegular textXS neutral400">Добавление участников необязательно. Новых пользователей вы можете создать в настройках</p>

                <FormSelectMultiRow
                    v-model="createShiftForm.members"
                    label="Участники"
                    placeholder="Выберите пользователя"
                    new-item-path="UsersCreate"
                    :items="users"
                    button-label="участника"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Создать бригаду" @action="createValue"/>
        </template>
    </DrawerFramework>
</template>

<script>
import FormInput from '../../Form/FormInput.vue'
import DrawerFramework from '../../Drawer/DrawerFramework.vue'
import DrawerCreateButton from '../../Drawer/Buttons/DrawerCreateButton.vue'
import DrawerHeadTitle from '../../Drawer/DrawerHeadTitle.vue'
import FormSelectMultiRow from '../../Form/FormSelectMultiRow.vue'
import { httpService } from '@/services/http.service'

export default {
    components: { FormSelectMultiRow, DrawerHeadTitle, DrawerCreateButton, DrawerFramework, FormInput },
    name: 'ProductionShiftCreate',
    data() {
        return {
            loading: false,
            createShiftForm: {
                name: '',
                members: [{
                    'id': null
                }],
            },
            users: []
        }
    },
    inject: [
        'api'
    ],
    created() {
        httpService().post(this.api.users.get).then((response) => {
            let data = response.data

            if (data.success)
                this.users = data.users
        })
    },
    methods: {
        createValue() {
            this.loading = true

            httpService().post(this.api.productionShift.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                shift: this.createShiftForm,
            }).then(() => {
                this.$message({
                    message: 'Бригада добавлена',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>

</style>
