<template>
    <template v-if="modelValue && !autoEditFalse">
        <el-form-item :label="label" v-if="!edit" :required="required">
            <el-row :gutter="16" class="w-100">
                <el-col :span="1">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                         style="position: relative; top: 1px"
                         @click="toggleEdit" class="pointer">
                        <path d="M2.66602 13.3334H13.3327" stroke="#1984E6" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                            d="M2.66602 13.3333H5.33268L12.8613 5.80468C13.1216 5.54433 13.1216 5.12222 12.8613 4.86187L11.1374 3.13801C10.8771 2.87766 10.455 2.87766 10.1946 3.13801L2.66602 10.6666V13.3333Z"
                            stroke="#1984E6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </el-col>
                <el-col :span="23">
                    <div @click="toggleEdit" class="textSM textRegular neutral400 pointer">
                        <slot name="info"/>
                    </div>
                </el-col>
            </el-row>
        </el-form-item>
        <slot name="edit" v-else/>
    </template>
    <slot name="edit" v-else/>
</template>

<script>

export default {
    name: 'FormEditShortData',
    props: ['label', 'required', 'modelValue', 'autoEditFalse'],
    data() {
        return {
            edit: false
        }
    },
    methods: {
        toggleEdit() {
            this.edit = !this.edit;
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                if (this.modelValue && !this.autoEditFalse) this.edit = false
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
