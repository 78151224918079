<template>
    <div class="statusBlock">
        <div v-if="mobile" class="statusBlockMobile">

            <MixFactoryPause class="statusBlockIcon" v-if="status === 0"/>
            <span v-if="status === 0">Не работает</span>


            <MixFactoryWork class="statusBlockIcon" v-if="status !== 0"/>
            <span v-if="status !== 0">Работает</span>
        </div>
        <div v-if="!mobile">
        <span v-if="status === 0" style=" position: relative; padding-left: 45px; ">
            <MixFactoryPause class="statusBlockIcon"/>
            Весовая не работает
        </span>
        <span v-else style=" position: relative; padding-left: 45px; ">
            <MixFactoryWork class="statusBlockIcon"/>
            Весовая работает
        </span>
            <el-button :loading="loading" @click="$emit('moduleGet')" :icon="icons.refresh"
                       circle></el-button>
        </div>
        <div v-if="!mobile" class="statusBlockPeriod">
            Показана сводка за сегодня
        </div>
    </div>
</template>

<script>
import MixFactoryPause from '@/views/components/Icons/MixFactoryPause'
import MixFactoryWork from '@/views/components/Icons/MixFactoryWork'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "WeighingInfoStatusBlock",
    components: {MixFactoryWork, MixFactoryPause},
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    props: [
        'status',
        'loading'
    ],
}
</script>

<style scoped>

</style>
