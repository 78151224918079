<template>
    <div class="topBreadcrumb">
        <div class="topBreadcrumbHead">{{ title ? title : 'Настройки пользователя' }}</div>
        <el-breadcrumb separator="/">
            <template v-for="(item, index) in crumbs" :key="index">
                <el-breadcrumb-item class="topBreadcrumbLink" v-if="item.text" :to="item.to" >
                    {{ item.text }}
                </el-breadcrumb-item>
            </template>
        </el-breadcrumb>
    </div>
</template>

<script type="text/babel">

export default {
    name: 'Breadcrumb',
    props: [
        'title'
    ],
    computed: {
        crumbs: function () {
            let pathArray = this.$route.path.split("/")
            pathArray.shift()
            return pathArray.reduce((breadcrumbArray, path, idx) => {
                breadcrumbArray.push({
                    path: path,
                    to: breadcrumbArray[idx - 1]
                        ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                        : "/" + path,
                    text: this.$route.matched[idx]?.meta.title || '',
                });
                return breadcrumbArray;
            }, []);
        }
    }
};
</script>
