<template>
    <div id="guest">
        <slot/>
    </div>
</template>

<script>

export default {
    name: "Guest",
    beforeCreate: function() {
        document.body.className = 'dark-bg';
    },
    beforeUnmount: function () {
        document.body.className = '';
    },
}
</script>
