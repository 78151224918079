import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { API_ROUTES } from './const'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ru from 'element-plus/dist/locale/ru.mjs'
import VueNumber from 'vue-number-animation'
import numeral from 'numeral'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import 'echarts'
import { createPinia } from 'pinia'
import './sass/app.scss'
import { authService } from '@/services/auth.service'
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import 'ol'
import { initYandexMetrika } from 'yandex-metrika-vue3'
import 'dayjs/locale/ru';
import YmapPlugin from 'vue-yandex-maps';

const app = createApp(App)

const settings = {
    apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
}

app.use(createPinia())

app.config.warnHandler = function () {
    return null
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        createApp,
        dsn: 'https://1df7d6cedbe15ce9f5b4618d14a3bd05@o4505741873577984.ingest.sentry.io/4505803761975296',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ['app.cifra.ai', /^\//],
            }),
        ],
        tracesSampleRate: 1.0,
    })
}

import Echo from "laravel-echo";
window.io = require('socket.io-client');

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_SOCKET_HOST,
});

/* eslint-disable no-const-assign */
/** не нравится что в sass есть info, но в window его нет, если проверять на фронте */
window.info = function(message) {
    console.log(message);
};
/* eslint-enable no-const-assign */

authService().initApp().finally(() => {
    app.use(router)
    app.use(VueNumber)
    app.use(numeral)
    app.use(OpenLayersMap)
    app.use(ElementPlus, {
        locale: ru
    })
    app.use(initYandexMetrika, {
        id: 92358125,
        router: router,
        env: process.env.NODE_ENV,
        options: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        }
    })
    app.directive('click-outside', {
        updated(el, binding) {
            el.clickOutsideEvent = (event) => {
                if (!(el == event.target || el.contains(event.target))) {
                    binding.value();
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        }
    });
    app.provide('api', API_ROUTES)
    app.mount('#app')
    app.use(YmapPlugin, settings);
})
