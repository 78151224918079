import moment from 'moment-timezone'
import getTimezone from './getTimezone'
import getUserTz from './getTimezone'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import axios from "axios";
import {httpService} from "@/services/http.service";

export default {
    mixins: [
        getTimezone,
        getUserTz
    ],
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
            units: 'getUnits',
            pullGoods: 'pullGoods',
            pullCompanies: 'pullCompanies',
            modules: 'getModules'
        }),
        moduleTimezone() {
            let moduleId = null

            // забираем из стора временную зону
            // eslint-disable-next-line no-prototype-builtins
            if (this.$route.params.id) moduleId = this.$route.params.id

            if (moduleId) {
                let numericId = parseInt(moduleId)
                let moduleTimezone = 'Europe/Moscow'
                if (this.$route.meta.moduleKey === 'dispatch' || this.$route.meta.moduleKey === 'crm') {
                    moduleTimezone = this.modules.find((item) => item.accountModuleId === numericId)?.timezone
                } else {
                    moduleTimezone = this.modules.find((item) => item.id === numericId)?.timezone
                }
                return moduleTimezone
            }

            return 'Europe/Moscow'
        },
    },
    inject: ['api'],
    methods: {
        checkArray(arr) {
            if (!Array.isArray(arr)) {
                return false;
            }
            if (arr.length === 0) {
                return false;
            }
            return typeof arr[0] === 'object';
        },
        timeAddTimezone(dateString, timezone = 'Europe/Moscow') {
            if (dateString == null) {
                return '-'
            }

            timezone = this.moduleTimezone

            let currentDateUtc = moment().utc();
            let offsetMinutes = moment.tz(currentDateUtc, timezone).utcOffset();
            let utcMomentWithDate = moment(currentDateUtc.format('YYYY-MM-DD') + ' ' + dateString, 'YYYY-MM-DD HH:mm:ss');
            let newTzMoment = utcMomentWithDate.add(offsetMinutes, 'minutes');
            let newTzTime = newTzMoment.format('HH:mm');

            return newTzTime;
        },
        dateTimeAddTimezone(dateTimeString, timezone = 'Europe/Moscow', format = 'YYYY-MM-DD HH:mm:ss') {
            if (dateTimeString == null) {
                return '-';
            }

            timezone = this.moduleTimezone

            let utcMomentWithDateTime = moment.utc(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
            let offsetMinutes = moment.tz(utcMomentWithDateTime, timezone).utcOffset();
            let newTzMoment = utcMomentWithDateTime.add(offsetMinutes, 'minutes');
            let newTzDateTime = newTzMoment.format(format);

            return newTzDateTime;
        },
        dateTimeToUtc(dateTimeString, timezone = 'Europe/Moscow', format = 'YYYY-MM-DD HH:mm:ss') {
            if (dateTimeString == null) {
                return '-';
            }
            let momentWithDateTime = moment.tz(dateTimeString, format, timezone);
            let utcDateTime = momentWithDateTime.utc().format(format);
            return utcDateTime;
        },
        getVehicleNumber(number) {
            if (/^[А-ЯЁ]\d{3}[А-ЯЁ]{2}\d{2,3}$/.test(number)) {
                return number.replace(/^([А-ЯЁ])(\d{3})([А-ЯЁ]{2})(\d{2,3})/, '$1 $2 $3 $4');
            } else {
                return number
            }
        },
        getFormattedVehicleNumber(input) {
            if(!input) return ''
            const regex = /[^А-ЯЁа-яё0-9]/g;

            input = input.toUpperCase().replace(regex, '');
            let formatted = '';

            if (input.length >= 1) {
                formatted += input[0] + ' ';
            }

            if (input.length >= 2) {
                formatted += input.substring(1, 4)
                if (input.length > 3) {
                    formatted += ' ';
                }
            }

            if (input.length >= 5) {
                formatted += input.substring(4, 6)
                if (input.length > 5) {
                    formatted += ' ';
                }
            }

            if (input.length >= 7) {
                formatted += input.substring(6, 9)
            }

            return formatted.trim();
        },
        formatName(name) {
            // Разбиваем имя на слова
            const words = name.split(' ');

            // Проверяем, что имя состоит из трех слов
            if (words.length !== 3) {
                return name;
            }

            // Форматируем имя к виду Фамилия И.О.
            const formattedName = `${words[0]} ${words[1][0]}.${words[2][0]}.`;

            return formattedName;
        },
        formatNumber(value, fraction) {
            if (!value) return '0';

            // Функция добавляет тысячные пробелы
            const fractionNumber = fraction ?? 0
            const f = (x) => (x.toString().includes('.') ? x.toString().split('.').pop().length : 0)

            if (f(value) === 0) {
                let val = (value / 1).toFixed(0).toString().replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            } else {
                let val = (value / 1).toFixed(fractionNumber).toString().replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
        },
        deepFind(obj, path) {
            let paths = path.split('.')
                , current = obj
                , i;

            for (i = 0; i < paths.length; ++i) {
                if (current[paths[i]] == undefined) {
                    return undefined;
                } else {
                    current = current[paths[i]];
                }
            }
            return current;
        },
        getDateFormDB(date, format) {
            // работает с датой в формате из базы (2022-02-04T18:55:12)
            // основная функция - отдавать дату в нужном формате без переделки под timezone
            var day = date.substring(8, 10)
            var month = date.substring(5, 7)
            var year = date.substring(2, 4)

            var hour = date.substring(11, 13)
            var minute = date.substring(14, 16)

            if (format === 'datetime') {
                return day + '.' + month + '.' + year + ' ' + hour + ':' + minute
            }

            if (format === 'datetimeIn') {
                return day + '.' + month + '.' + year + ' в ' + hour + ':' + minute
            }

            if (format === 'date') {
                return day + '.' + month + '.' + year
            }

            if (format === 'shortDate') {
                return day + '.' + month
            }

            if (format === 'time') {
                return hour + ':' + minute
            }
        },
        getDateFromUnix(timestamp, format, timezone = 'Europe/Moscow') {
            // переделывает timestamp в нужный формат, например 'HH:mm DD.MM.YY'

            timezone = timezone ?? this.moduleTimezone

            if (!format) format = 'DD.MM.YY в HH:mm'

            if (format.includes('DD.MM')) {
                var given = moment(moment.unix(timestamp).tz(timezone ?? 'Europe/Moscow'), 'YYYY-MM-DD')
                var current = moment().tz(timezone ?? 'Europe/Moscow').startOf('day')
                var diff = moment.duration(given.diff(current)).asDays()

                if (diff >= 0 && diff < 1) {
                    if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Сегодня'
                    if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Сегодня в HH:mm'
                }

                if (diff >= -1 && diff < 0) {
                    if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Вчера'
                    if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Вчера в HH:mm'
                }
            }

            // использовалась функция this.getTimezone() для определения таймзоны пользователя, нужно проверить позже ее необходимость
            return moment.unix(timestamp).tz(timezone ?? 'Europe/Moscow').locale('ru').format(format)
        },
        getDateByTz(timestamp, format, timezone) {
            if (!timestamp || !timezone) {
                return ''
            }

            // переделывает timestamp в нужный формат, например 'HH:mm DD.MM.YY'
            if (!format) format = 'DD.MM.YY в HH:mm'

            if (format.includes('DD.MM')) {
                var given = moment(moment.unix(timestamp).tz(timezone), 'YYYY-MM-DD')
                var current = moment().tz(timezone).startOf('day')
                var diff = moment.duration(given.diff(current)).asDays()

                if (diff >= 0 && diff < 1) {
                    if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Сегодня'
                    if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Сегодня в HH:mm'
                }

                if (diff >= -1 && diff < 0) {
                    if (format === 'DD.MM.YY' || format === 'DD.MM') format = 'Вчера'
                    if (format === 'DD.MM.YY в HH:mm' || format === 'DD.MM.YY HH:mm') format = 'Вчера в HH:mm'
                }
            }

            return moment.unix(timestamp).tz(timezone).locale('ru').format(format)
        },
        getDateMoment(date, format) {
            return moment(date).add(3,'hours').format(format)
        },
        getGoodId(id, accountModuleId) {
            let goodId = id
            let relationGroupId = this.getData(goodId, 'good', 'relation_group_id')

            if (relationGroupId) {
                if (accountModuleId) {
                    let newGood = this.goods.find(el => el.relation_group_id === relationGroupId && el.account_module_id === accountModuleId)
                    if (newGood) goodId = newGood.id
                }
            }
            return goodId
        },
        getData(id, type, param, once) {
            const commonStore = useCommonStore()

            if (!param) {
                if (type === 'unit') {
                    param = 'short_name'
                } else {
                    param = 'name'
                }
            }

            const objects = {
                'good': this.goods,
                'company': this.companies,
                'unit': this.units
            }

            const data = objects[type]

            if (data && id) {
                const entity = data.find(obj => obj.id === id)

                if (entity) {
                    if (type === 'company' && param !== 'name') {
                        let result = httpService().post(this.api.company.getAttribute, {
                            attribute: param,
                            companyId: id
                        }).then((response) => {
                            let data = response.data

                            if (data.success) {
                                return data.attribute
                            }
                        })
                        return result
                    } else {
                        if (entity[param]) {
                            return entity[param]
                        } else {
                            if (param === 'unit' || type === 'unit') {
                                return 'ед.'
                            }
                        }
                    }
                } else if (!once && (!commonStore.goods || !commonStore.companies)) {
                    const pulls = {
                        'good': this.pullGoods,
                        'company': this.pullCompanies
                    }

                    return pulls[type]().then(() => {
                        return this.getData(id, type, param, true)
                    })
                }
            }

            return null
        },
        getInfo(id, arr, param) {
            // получает значение свойства объекта из общего массива подобных объектов
            // например, если нужно получить имя товара по goodId, то
            // нужно отправить в эту функцию goodId, goods, 'name'
            // по умолчанию ищет 'name'

            if (this.isArray(arr)) {
                if (arr.length > 0) {
                    if (!param) param = 'name'
                    const object = arr.find((el => el.id === id))
                    if (object) {
                        if (param in object) {
                            if (object[param]) {
                                return object[param]
                            } else {
                                if (param === 'unit') return 'ед.'
                                return null
                            }
                        } else {
                            return null
                        }
                    }

                } else {
                    return null
                }
            } else {
                return null
            }
        },
        isArray(obj) {
            return Object.prototype.toString.call(obj) === '[object Array]'
        },
        findInn(query) {
            if (this.source) {
                this.source.cancel();
            }
            this.innLoading = true;
            const CancelToken = axios.CancelToken;
            this.source = CancelToken.source();
            this.root.style.setProperty(
                "--inn-selector-max-width",
                this.$refs.innSelector.$refs.select.$el.clientWidth + "px"
            );
            this.innCompanyNamesPerInnValue = {};
            httpService()
                .post(
                    this.api.account.suggestionByDaData,
                    {
                        query,
                    },
                    {
                        cancelToken: this.source.token,
                    }
                )
                .then((response) => {
                    this.innItems = response.data.innItems;
                    this.innCompanyNamesPerInnValue =
                        response.data.innCompanyNamesPerInnValue;
                    this.innLoading = false;
                });
        },
        blockScrollWhileModalOpened(modalValue) {
            if (modalValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        formatPhoneNumber(phone, returnValue) {
            if(!phone) return returnValue ? returnValue : '';

            let cleaned = phone.replace(/\D/g, '');

            if (cleaned.startsWith('8')) {
              cleaned = '7' + cleaned.slice(1);
            } else if (!cleaned.startsWith('7')) {
              cleaned = '7' + cleaned;
            }

            return cleaned.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
          }
    }
}
