<template>
    <div id="y-map" class="y-map"></div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, watch} from "vue";
import {loadYmap} from "vue-yandex-maps";

const props = defineProps({
    coords: {
        default: () => [55.74392087344522, 37.61348980866149],
        type: Array,
    },
    zoom: {
        default: 14,
        type: Number,
    },
    dragOff: {
        default: true,
        type: Boolean
    }
})

const emit = defineEmits(['onChangeCenterPosition', 'onChangeZoom']);

let marker = null;
let map = null;

const debounce = (fn, wait) => {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, wait);
    }
};

const geolocate = async ({coords}) => {
    if (!props.dragOff) {
        try {
            const settings = {
                apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            };
            let result = [];
            await loadYmap({...settings, debug: true});
            // eslint-disable-next-line no-undef
            await ymaps.geocode(coords, {
                // eslint-disable-next-line consistent-return
            }).then((res) => {
                result = res.geoObjects.toArray();
                if (result.length < 1) {
                    return Promise.resolve([]);
                }
                result = result.map((city) => ({
                    value: city.getAddressLine(),
                }));
                return result;
            });

            return Promise.resolve(result);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

const handleDrag = async (location) => {
    if (!props.dragOff) {
        emit('loadingAction', true)
        const address = await geolocate({coords: [location.center[1], location.center[0]]});
        marker.update({
            coordinates: location.center
        });
        emit('onChangeCenterPosition', {
            lat: location.center[1],
            lng: location.center[0],
            address: address[0].value
        });
        emit('loadingAction', false)
    }
}

const handleZoom = debounce(async (location) => {

    if (!props.dragOff) {
        emit('loadingAction', true)
        emit('onChangeZoom', map.zoom);
        const address = await geolocate({coords: [location.center[1], location.center[0]]});
        marker.update({
            coordinates: location.center
        })

        emit('onChangeCenterPosition', {lat: location.center[1], lng: location.center[0], address: address[0].value});
        emit('loadingAction', false)
    }
}, 1000);

const onUpdate = async ({location}) => {
    marker.update({
        coordinates: location.center
    })
}

const init = async () => {
    // eslint-disable-next-line no-undef
    const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');
    // eslint-disable-next-line no-undef
    // const { YMapZoomControl } = await ymaps3.import('@yandex/ymaps3-controls@0.0.1');

    // eslint-disable-next-line no-undef
    await ymaps3.ready;

    // eslint-disable-next-line no-undef
    map = new ymaps3.YMap(document.getElementById('y-map'), {
        location: {
            center: [...props.coords].reverse(),
            zoom: props.zoom
        }
    });

    marker = new YMapDefaultMarker({
        coordinates: [...props.coords].reverse(),
        color: '#1984E6',
    })

    // eslint-disable-next-line no-undef
    const mapListener = new ymaps3.YMapListener({
        layer: 'any',
        onActionEnd: ({type, location}) => {
            if (!props.dragOff) {
                type === 'drag' ? handleDrag(location) : handleZoom(location);
            }
        },
        onUpdate: (event) => {
            if (!props.dragOff) {
                onUpdate(event);
            }
        },
    });

    // // eslint-disable-next-line no-undef
    // const controls = new ymaps3.YMapControls({ position: 'left' }).addChild(
    //     // eslint-disable-next-line no-undef
    //     new YMapZoomControl({})
    // )

    // eslint-disable-next-line no-undef
    // const controls = new ymaps3.YMapControls({position: 'right'});
    // const zoomControl = new YMapZoomControl();

    // controls.addChild(zoomControl);
    // map.addChild(controls);

    // eslint-disable-next-line no-undef
    map.addChild(new ymaps3.YMapDefaultSchemeLayer());
    // eslint-disable-next-line no-undef
    map.addChild(new ymaps3.YMapDefaultFeaturesLayer());
    // eslint-disable-next-line no-undef
    map.addChild(marker);
    map.addChild(mapListener);
    // eslint-disable-next-line no-undef
    // map.addChild(new ymaps3.YMapControls({ position: 'left' }).addChild(new YMapZoomControl()));
}

watch(() => props.coords, (oldValue, newValue) => {
    if (map && newValue !== map.center) {
        map.setLocation({
            center: [...props.coords].reverse(),
            zoom: props.zoom,
        });
        marker.update({
            coordinates: [...props.coords].reverse()
        })
    }
})

onMounted(() => init());
</script>

<style lang="scss" scoped>
.y-map {
    height: 100%;
    width: 100%;
}
</style>

<style lang="scss">
.yandex-container {
    width: 100%;
    height: 100%;
}
</style>
