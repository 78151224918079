<template>
    <div :id="id" v-if="show" ref="modalContent" class="custom-modal" :class="{'custom-modal-show': show}">
        <div class="custom-modal-content">
            <div @click="closeModal" class="close-button">
                <CloseIcon />
            </div>
            <div class="header-icon">
                <slot name="header-icon"></slot>
            </div>
            <div v-loading="loading">
                <div class="custom-modal-header">
                    <slot name="header">
                        <div class="custom-modal-header-title">
                            <slot name="header-title"></slot>
                        </div>
                        <div class="custom-modal-header-description">
                            <slot name="header-description"></slot>
                        </div>
                    </slot>
                </div>

                <div class="custom-modal-body">
                    <slot name="body"></slot>
                </div>

                <div class="custom-modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
    <div @click="closeModal" v-show="show" id="custom-modal-backdrop"></div>
</template>

<script>
import CloseIcon from "@/views/components/Icons/CloseIcon.vue";

export default {
    name: "Modal",
    components: {CloseIcon},
    props: {
        modelValue: {
            default: false,
            type: Boolean
        },
        loading: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            id: null,
            show: this.modelValue,
        }
    },
    mounted() {
        this.id = 'modal_' + this.$.uid
    },
    watch: {
        modelValue: {
            handler: function (show) {
                this.$nextTick(() => {
                    this.show = this.modelValue;
                    document.body.style.overflow = show ? 'hidden' : 'unset';
                })
            },
            deep: true,
            immediate: true
        },
        'show': {
            handler: function () {
                this.$emit('update:modelValue', this.show)
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        closeModal() {
            this.$emit('onClose');
            this.show = false
        }
    }
}
</script>

<style scoped>
#custom-modal-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(11, 13, 23, 0.4);
    backdrop-filter: blur(20px);
    z-index: 50;
}
.custom-modal {
    background-color: #fff;
    color: #393F4A;
    position: fixed;
    z-index: 51;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    max-height: 100vh;
}
.custom-modal-content {
    padding: 36px;
    position: relative;
    width: calc(520px - 72px);
    max-height: calc(100vh - 72px);
    overflow: scroll;
}

.close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.close-button svg {
    stroke: #656566;
}

.header-icon {
    margin-bottom: 24px;
    display: flex;
}

.custom-modal-header {
    margin-bottom: 24px;
}

.custom-modal-header-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.custom-modal-header-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737A86;
    margin-top: 8px;
}

.custom-modal-body {
    margin: 24px 0;
}

.custom-modal-footer {
    margin-top: 24px;
}

.close-button:hover svg,
.close-button:focus svg {
    stroke: #1B91FD;
}
</style>
