<template>
    <svg
        :class="currentColor ? 'currentColor' : ''"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_18004_36779)">
            <path
                d="M11.9027 13C11.5777 13 11.2866 12.7974 11.1771 12.4914C11.0695 12.191 10.9662 11.8949 10.8671 11.6032C10.7125 11.1376 10.5528 10.6667 10.3879 10.1905H5.53478L4.73214 12.5063C4.62973 12.8018 4.35133 13 4.03859 13C3.53183 13 3.17753 12.4986 3.348 12.0214C3.63151 11.2278 3.90191 10.4799 4.1592 9.77778C4.51984 8.78307 4.87017 7.84127 5.2102 6.95238C5.56054 6.06349 5.90572 5.21693 6.24575 4.4127C6.49688 3.81092 6.75644 3.21492 7.02443 2.62468C7.19791 2.24259 7.58036 2 8 2C8.41964 2 8.80209 2.24259 8.97557 2.62468C9.24356 3.21491 9.50312 3.81092 9.75425 4.4127C10.0943 5.21693 10.4343 6.06349 10.7743 6.95238C11.1247 7.84127 11.4802 8.78307 11.8408 9.77778C12.0921 10.4635 12.3559 11.193 12.6322 11.9661C12.8124 12.4703 12.4381 13 11.9027 13ZM9.95518 8.92064C9.62545 8 9.29572 7.11111 8.966 6.25397C8.64657 5.38624 8.3117 4.55556 7.96136 3.7619C7.60072 4.55556 7.25554 5.38624 6.92581 6.25397C6.60639 7.11111 6.28697 8 5.96754 8.92064H9.95518Z"
                fill="#505968"
            />
        </g>
        <defs>
            <clipPath id="clip0_18004_36779">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "RentIcon",
    props: ["currentColor"],
};
</script>

<style scoped lang="scss">
.currentColor {
    path {
        fill: currentColor;
    }
}
</style>
