<template>
    <div class="progress-echarts">
        <VueEcharts
            :option="progress"
        />
    </div>
</template>

<script type="text/babel">
import VueEcharts from "vue-echarts";
import {graphic} from "echarts"

const getParts = (number) => Array.from({ length: Math.ceil(number / 100) }, (_, i) => {
    const leftover = number - i * 100

    return leftover > 100
        ? { value: 100 }
        : { value: leftover };
});

const setUnit = (value, unit) => {
    let _value = parseFloat(value).toFixed(2)
    _value = _value.replace('.', ',')
    if (unit) {
        return `${_value + (unit === 'м3.' ? ' м<sup>3</sup>' : unit)}`
    } else {
        return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value)
    }
}

const getProgressBar = (value, reverseColors, unit) => {
    if (!value) return;

    const sourceValue = value[0].value;
    const sourceStart = value[0].start;
    const sourceEnd = value[0].end;
    let data = getParts(sourceValue);
    let zeroValue = false;

    if (!data.length) {
        data = [{value: 0}, {value: 0}]
        zeroValue = true
    }

    // Ограничение на отрисовку 2 кругов
    if (data.length > 2) {
        data.length = 2
    }

    if (zeroValue) {
        data.length = 1
    }

    let seriesObjs = [];
    let r = 40;
    let placeHolderStyle = {
        normal: {
            label: {
                show: false
            },
            emphasis: {
                label: {
                    show: false
                }
            },
            color: data[1] ? "rgba(0, 0, 0, 0)" : "#eeeeee",
            borderColor: "rgba(0, 0, 0, 0)",
            borderWidth: 0
        }
    };

    let colors = [];

    if (!reverseColors) {
        if (sourceValue < 100) {
            colors = { a: '#8DC7FE', b: '#1B91FD' }
        } else {
            colors = { a: '#B2F1E3', b: '#0ED2A8' }
        }
    } else {
        if (sourceValue < 100) {
            colors = { a: '#B2F1E3', b: '#0ED2A8' }
        } else {
            colors = { a: '#FEE5E3', b: '#F74539' }
        }
    }

    for (let i = 0; i < data.length; i++) {
        // отрисовываем только до 125 второй круг и останавливаемся
        if (data[1]) {
            data[1].value = 25
        }

        let seriesObj = {
            type: "pie",
            clockWise: true,
            radius: [r - 3 - i * 8, r - i * 8],
            emphasis: {
                disabled: true
            },
            itemStyle: {
                normal: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: colors.a },
                        { offset: 1, color: colors.b }
                    ]),
                    borderWidth: 1,
                    borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: colors.a },
                        { offset: 1, color: colors.b }
                    ]),
                    borderCap: "round"
                }
            },
            label: {
                show: true,
                position: 'center',
                color: colors.b,
                fontSize: 14
            },
            labelLine: {
                show: false
            },
            data: [
                {
                    value: data[1]?.value
                        ? data[1].value
                        : data[0].value,
                    name: sourceValue + '%'
                },
                {
                    value: data[1]?.value
                        ? (data[0].value * 3) / 3 - data[i].value
                        : 100 - data[0].value,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    itemStyle: placeHolderStyle
                }
            ]
        };

        seriesObjs.push(seriesObj);
    }
    return {
        title: {
            show: false
        },
        tooltip: {
            borderWidth: 0,
            padding: [0, 10],
            axisPointer: {
                type: "shadow"
            },
            formatter: () => `<p style="white-space: nowrap;margin: 10px 0"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color: ${colors.b}"></span>${setUnit(sourceStart, unit)}</p><p style="white-space: nowrap;margin: 10px 0">из ${setUnit(sourceEnd, unit)}</p>`
        },
        series: seriesObjs
    };
}

export default {
    name: 'ChartProgress',
    components: {
        VueEcharts,
    },
    props: [
        'data',
        'unit'
    ],
    data: () => ({
        progress: {},
        reverseColors: false
    }),
    watch: {
        'data': {
            handler: function () {
                // eslint-disable-next-line no-prototype-builtins
                if (this.data.hasOwnProperty('reverse')) this.reverseColors = this.data.reverse
                if (this.data) {
                    this.progress = getProgressBar(this.data.source, this.reverseColors, this.unit);
                }
            },
            deep: false,
            immediate: true
        }
    }
};
</script>

<style scoped>
.progress-echarts {
    width: 82px;
    height: 82px;
}
</style>
