<template>
    <div class="NoWidget">
        <div class="NoWidget__body">
            <p>
                <NoDataIcon/>
            </p>
            <p>Здесь может быть полезный виджет.<br/>Добавьте его в настройках рабочего стола!</p>
            <el-button @click="$router.push({ name: 'UsersEdit', params: { id: user.id, settingsWidgets: 'true' } })">
                Добавить виджет
            </el-button>
        </div>
    </div>
</template>

<script>
import NoDataIcon from './NoDataIcon'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'

export default {
    name: 'NoRightWidget',
    components: { NoDataIcon },
    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser'
        })
    }
}
</script>
