<template>

        <div class="reportItem" v-if="filteredReports.length">
            <p class="textLG textBold neutral900 mt-0">
                <MixIcon v-if="moduleKey === 'mix'"/>
                <CashboxIcon v-else-if="moduleKey === 'cashbox'"/>
                <WeighingIcon v-else-if="moduleKey === 'weighing'"/>
                <ProductionIcon v-else-if="moduleKey === 'production'"/>
                <StorehouseIcon v-else-if="moduleKey === 'storehouse'"/>
                <DispatchIcon v-else-if="moduleKey === 'dispatch'"/>
                <CRMIcon v-else-if="moduleKey === 'crm'"/>
                {{ getLabel(moduleKey) }}
            </p>

            <div class="reportFlex">
                <div class="reportSelect" v-for="(report, index) in filteredReports" :key="index">

                    <div class="reportSelectImage">
                        <el-image
                            :src="require(`@/assets/systemReports/${report.report_key}.png`)"
                            fit="contain"
                        />
                    </div>

                    <div class="reportSelectBody">
                        <h2 class="neutral900">{{ report.name }}</h2>
                        <p>{{ report.description }}</p>
                        <el-button class="w-100" :loading="loading" type="primary"
                                   @click="$emit('doSelectReport', report)">Сформировать
                        </el-button>
                    </div>

                </div>
            </div>

            <hr class="mt-15 mb-15"/>
        </div>

</template>

<script>
import MixIcon from "@/views/components/Icons/MixIcon.vue";
import CashboxIcon from "@/views/components/Icons/CashboxIcon.vue";
import WeighingIcon from "@/views/components/Icons/WeighingIcon.vue";
import ProductionIcon from "@/views/components/Icons/ProductionIcon.vue";
import StorehouseIcon from "@/views/components/Icons/StorehouseIcon.vue";
import DispatchIcon from "@/views/components/Icons/DispatchIcon.vue";
import CRMIcon from "@/views/components/Icons/CRMIcon.vue";

export default {
    name: 'ReportMenu',
    props:['reports','moduleKey','loading','modules'],
    data() {
        return {
            filteredReports: [],
        }
    },
    components: {
        CRMIcon,
        DispatchIcon,
        StorehouseIcon,
        ProductionIcon,
        WeighingIcon,
        CashboxIcon,
        MixIcon,
    },
    created() {
        this.filterReports();
    },
    computed: {
    },
    methods: {
        filterReports() {
            return this.filteredReports = this.reports.filter(report => report.module_key === this.moduleKey && report.status)
        },
        getLabel(key) {
            if (key === 'mix') return 'Бетонный завод';
            if (key === 'dispatch') return 'Диспетчерская';
            if (key === 'production') return 'Производство';
            if (key === 'cashbox') return 'Денежный учет';
            if (key === 'weighing') return 'Весовая';
            if (key === 'crm') return 'CRM-система';
            if (key === 'storehouse') return 'Складской учет';
            return '';
        },
    },
}
</script>

<style scoped>

</style>
