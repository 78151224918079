<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавление шины"/>

            <el-form ref="form" :model="form" label-width="120px" label-position="top">

                <FormSelect
                    v-model="form.brand_id"
                    label="Бренд"
                    placeholder="Выберите производителя"
                    :items="brands"
                    :required="true"
                />

                <el-row :gutter="10">

                    <el-col :sm="12">
                        <FormInput
                            v-model="form.sku"
                            label="Уникальный номер (артикул)"
                            placeholder="Введите номер"
                            :required="true"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <FormInput
                            v-model="form.name"
                            label="Дополнительное название"
                            placeholder="Н-р: pilot sport"
                        />
                    </el-col>

                </el-row>

                <FormSelect
                    v-model="form.season"
                    label="Сезонность"
                    placeholder="Выберите сезонность"
                    :items="seasons"
                />

                <el-row :gutter="10">

                    <el-col :sm="12">
                        <FormNumber
                            v-model="form.mileage"
                            label="Текущий пробег"
                            placeholder="Введите пробег"
                            :required="true"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <FormNumber
                            v-model="form.mileage_max"
                            label="Максимальный пробег"
                            placeholder="Введите пробег"
                            :required="true"
                        />
                    </el-col>

                </el-row>

                <el-row :gutter="10">

                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.radius"
                            label="Радиус (число)"
                            placeholder="18"
                            :precision="1"
                            :step="1"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.width"
                            label="Ширина"
                            placeholder="220"
                            :precision="0"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <FormNumber
                            v-model="form.height"
                            label="Высота"
                            placeholder="65"
                            :precision="0"
                        />
                    </el-col>
                </el-row>

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton :disabled="!form.sku || !form.brand_id || !form.mileage || !form.mileage_max" title="Добавить" @action="create()"/>
        </template>
    </DrawerFramework>
</template>

<script>
import {httpService} from '@/services/http.service'
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";

export default {
    components: {FormNumber, FormSelect, FormInput, DrawerCreateButton, DrawerHeadTitle, DrawerFramework},
    props: [
        'vehicle'
    ],
    data() {
        return {
            loading: false,
            form: {
                name: null,
                sku: null,
                brand_id: null,
                radius: null,
                width: null,
                height: null,
                season: 'all-season',
                mileage: null,
                mileage_max: null
            },
            brands: [],
            seasons: [{
                id: 'all-season',
                name: 'Всесезонная'
            }, {
                id: 'summer',
                name: 'Летняя'
            }, {
                id: 'winter',
                name: 'Зимняя'
            }]
        }
    },
    inject: [
        'api'
    ],
    name: 'TireCreate',
    created() {
        this.getBrands()
    },
    methods: {
        getBrands() {
            this.loading = true

            httpService().post(this.api.tires.getBrands).then((response) => {
                let data = response.data

                if (data.success) {
                    this.brands = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        create() {
            this.loading = true

            httpService().post(this.api.tires.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                form: this.form,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    window.setTimeout(() => {
                        this.$emit('getAll')
                    }, 50)
                    window.setTimeout(() => {
                        this.$emit('closeDrawer')
                    }, 150)
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
