/**
 * Переводит статусы заявок бетонного завода (mix_orders -> status)
 * Переводит типы заявок (mix_orders -> type)
 */

export default {
    data() {
        return {
            statuses: [
                {
                    key: 'new',
                    name: 'Очередь'
                },
                {
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    key: 'pouring',
                    name: 'Заливка'
                },
                {
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    key: 'done',
                    name: 'Завершена'
                }
            ],
            types: [
                {
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    key: 'take-away',
                    name: 'Самовывоз'
                },
                {
                    key: 'production',
                    name: 'Производство'
                },
            ]
        }
    },
    methods: {
        getStatusLabel(status) {
            let statusLabel = null
            this.statuses.forEach((element) => {
                if (element.key === status) {
                    statusLabel = element.name
                }
            })
            return statusLabel
        },
        getTypeLabel(type) {
            let typeLabel = null
            this.types.forEach((element) => {
                if (element.key === type) {
                    typeLabel = element.name
                }
            })
            return typeLabel
        },
        getGoodName(order, accountModuleId) {
            // получаем или имя спецификации или товар из заказа или из отгрузки
            let goodName = null
            let goodId = null

            goodId = order.specGoodId ?? order.orderGoodId
            goodId = this.getGoodId(goodId, accountModuleId)
            goodName = this.getData(goodId, 'good')

            if (!goodName && order.good) {
                goodName = order.good
            }
            return goodName
        },
    }
}
