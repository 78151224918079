<template>
    <el-tabs v-model="form.type">
        <el-tab-pane label="Пробег" name="mileage">
            <p class="textSM textRegular neutral300">Пробег транспорта за последние 7 дней</p>
        </el-tab-pane>
        <el-tab-pane label="Моточасы" name="hours">
            <p class="textSM textRegular neutral300">Моточасы транспорта за последние 7 дней</p>
        </el-tab-pane>
    </el-tabs>

    <v-chart v-model="chart" :option="option" autoresize class="chartMileage"/>

    <el-form label-position="top">

        <FormDateTimePicker
            v-model="form.date"
            :label="form.type === 'mileage' ? 'Дата пробега' : 'Дата наработки моточасов'"
            placeholder="Выберите дату"
            :required="true"
        />

        <FormNumber
            v-model="form.count"
            :label="form.type === 'mileage' ? 'Пробег за день' : 'Моточасы за день'"
            placeholder="Укажите значение"
            :required="true"
        />

        <FormAddButton
            :disable="!form.count || !form.date"
            :w100="true"
            title="Добавить значение"
            @action="changeMileage"
        />
    </el-form>

</template>

<script>
import VChart from 'vue-echarts'
import {httpService} from '@/services/http.service'
import FormNumber from '@/views/components/Form/FormNumber'
import FormAddButton from '@/views/components/Form/FormAddButton'
import functionsMixin from '@/mixins/functionsMixin'
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import moment from "moment/moment";

export default {
    name: 'TechChangeMileage',
    components: {FormDateTimePicker, FormAddButton, FormNumber, VChart},
    props: ['tech'],
    inject: ['api'],
    mixins: [functionsMixin],
    data() {
        return {
            loading: false,
            form: {
                date: moment(),
                count: null,
                type: 'mileage'
            },
            days: [],
            chart: 1,
            techDetails: this.tech,
            mileageHistory: [],
            hoursHistory: []
        }
    },
    created() {
        this.getTechDetails()
    },
    methods: {
        getTechDetails() {
            this.loading = true
            httpService().post(this.api.tech.getTechDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.tech.id,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.days = data.days
                this.mileageHistory = data.mileageHistory
                this.hoursHistory = data.hoursHistory
                this.techDetails = data.details

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        changeMileage() {
            this.loading = true
            httpService().post(this.api.tech.changeTechMileage, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.tech.id,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.$emit('moduleGet')
                    this.form.count = null
                    this.$emit('closeDialog')

                    this.$message({
                        message: 'Значение добавлено',
                        showClose: true,
                        type: 'success'
                    })
                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
    },
    computed: {
        values() {
            if (this.form.type === 'mileage') {
                return this.mileageHistory
            } else {
                return this.hoursHistory
            }
        },
        option() {
            return {
                color: this.form.type === 'mileage' ? '#1984e6' : '#009272',
                xAxis: {
                    type: 'category',
                    data: this.days
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} ' + (this.form.type === 'mileage' ? 'км' : 'мтч')
                    },
                    lineStyle: {
                        color: 'red',
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: '{c} ' + (this.form.type === 'mileage' ? 'км' : 'мтч'),
                    textStyle: {
                        fontWeight: 'bold'
                    }
                },
                series: [
                    {
                        data: this.values,
                        type: 'line',
                        smooth: true,
                    }
                ]
            }
        }
    },
}
</script>

<style scoped>

</style>
