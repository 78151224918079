<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
        <path :stroke="color ?? ''" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M26 26 14 14m12 0L14 26"/>
    </svg>
</template>

<script>
export default {
    name: "CloseIcon",
    props: ['color']
}
</script>

<style scoped>

</style>
