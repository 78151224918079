<template>
    <div class="orderGroupsTableScrollItem">
        <div :class="'ordersHeading ' + 'ordersHeading' + status">
            <span class="neutral800 textSM textBold">{{ statusName }}</span>
            <div :class="'ordersHeadingCount ' + 'ordersHeadingCount' + status">
                <span class="color-white textSM textBold">{{ values.length }}</span>
            </div>
        </div>

        <div :class="'orderGroupItem ' + 'orderGroupItem' + status + ' orderGroupItemCall' + order.status_call"
             v-for="(order, index) in values" :key="index">
            <div class="neutral300 textXS textRegular orderGroupItemMargin w-100">
                <div class="d-flex-full">
                    <div class="d-flex">
                        <div class="cutOverflow" style="max-width: 130px; margin-right: 5px">
                            {{ getInfo(order.good_id, mixGoods) }}
                        </div>
                        | {{ formatNumber(getTotalOrders(order)) }} руб.
                    </div>
                    <div>
                        <el-dropdown class="pointer" trigger="click">
        <span class="el-dropdown-link">
          <svg style=" position: relative; right: -10px; " width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
<path
    d="M17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12Z"
    stroke="#8A909A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path
    d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z"
    stroke="#8A909A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12Z"
      stroke="#8A909A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-if="order.status === 'wait'"
                                                      @click="openOrder(order.id, order.status)">Открыть
                                    </el-dropdown-item>
                                    <el-dropdown-item disabled>Скопировать</el-dropdown-item>
                                    <el-dropdown-item @click="deleteOrder(order.id)">Удалить</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>
            <div class="neutral800 textMD textMedium cutOverflow">
                <template v-if="order.buyer_company_id">№{{ order.doc }} для {{ getData(order.buyer_company_id, 'company') }}</template>
            </div>
            <div class="neutral300 textSM textRegular orderGroupItemMargin cutOverflow">
                <template v-if="order.delivery_type === 'delivery'">
                    {{ order.delivery_address ? order.delivery_address.name : 'Адрес не указан' }}
                </template>
                <template v-else-if="order.delivery_type === 'take-away'">
                    Самовывоз
                </template>
            </div>
            <div class="neutral300 textSM textRegular">
                <span class="neutral900">{{ formatNumber(order.total) }} <good-unit
                    :unit="getData(getInfo(order.good_id, mixGoods, 'unit'), 'unit')"/></span>
                <template v-if="order.status !== 'wait' && order.status !== 'failed'"> | <span
                    class="primary600">{{
                        order.orders.filter(el => el.status === 'done').length
                    }} / {{ order.orders.length }} </span></template>
<!--                <template v-if="order.orders.filter(el => el.status === 'done').length !== order.orders.length"> | <span-->
<!--                    class="neutral300">{{ getNextStartTime(order.orders) }}</span></template>-->
            </div>
        </div>
    </div>
</template>

<script>
import functionsMixin from "@/mixins/functionsMixin";
import GoodUnit from "@/views/components/goodUnit.vue";
import iconsMixin from "@/mixins/iconsMixin";
import {httpService} from "@/services/http.service";

export default {
    name: "MixPageOrdersGroupCol",
    components: {GoodUnit},
    props: ['values', 'status', 'statusName', 'mixGoods'],
    mixins: [functionsMixin, iconsMixin],
    inject: ['api'],
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        openOrder(id, status) {
            if (status === 'wait' || status === 'failed') {
                this.$emit('openDialog', id)
            } else {
                this.$router.push({name: 'MixOrderGroupEdit', params: {id: this.$route.params.id, orderId: id}})
            }
        },
        deleteOrder(id) {
            this.$confirm(
                "Вы действительно хотите удалить заказ?",
                "Подтвердите удаление",
                {
                    confirmButtonText: "Удалить",
                    cancelButtonText: "Отмена",
                    type: "warning",
                }
            )
                .then(() => {
                    this.loading = true;

                    httpService()
                        .post(this.api.mix.removeOrderGroup, {
                            account_module_reserve_id: this.$route.params.id,
                            module_key: "mix",
                            orderId: id,
                        })
                        .then((response) => {
                            let data = response.data;

                            if (data.success)
                                this.$message({
                                    message: "Заказ удален",
                                    showClose: true,
                                    type: "success",
                                });

                            this.$emit("moduleGet");
                            this.loading = false;
                        });
                })
                .catch(() => {
                    this.$message({
                        message: "Удаление отменено",
                        showClose: true,
                        type: "info",
                    });
                    this.loading = false;
                });
        },
        getTotalOrders(order) {
            if (order.total_product_price) {
                return Math.floor(order.total_product_price) + Math.floor(order.total_delivery_price)
            } else {
                let sum = 0

                order.orders.forEach((el) => {
                    sum += el.product_price * el.total
                    sum += Math.floor(el.delivery_price)
                })

                return sum
            }
        },
        getNextStartTime(orders) {
            let date = null

            orders.filter(el => el.status === 'new').forEach((el) => {
                let orderDate = el.timeline.find(t => t.status === 'loading')
                if (!date) {
                    date = orderDate.date
                } else {
                    if (date > orderDate.date) date = orderDate.date
                }
            })

            return this.getDateFromUnix(date, 'HH:mm')
        }
    }
}
</script>

<style scoped>

</style>
