<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-filter="true"
        :disable-update-button="true"
        :disable-create-button="true"
        create-button-label="Добавить продукцию"
        @moduleGet="getGoodsData()"
    >
        <template v-slot:afterSearchAndFilter>
            <el-select
                v-model="filters.goodType"
                placeholder="Тип товара"
                size="large"
                class="filterSelect"
                style="width: 180px"
                @change="getGoodsData()"
                clearable
            >
                <el-option
                    v-for="item in goodTypes"
                    :key="item.key"
                    :label="item.name"
                    :value="item.key"
                />
            </el-select>
            <TableIntegrationFilter
                v-model="filters.integrationUnitId"
                @moduleGet="getGoodsData"
                :table="true"
            />
        </template>
    </TableHeadBlock>
    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 defaultTableHeight"
        v-loading="loadingTable"
        @current-change="handleCurrentChange"
        :data="goodsData">
        <el-table-column
            prop="id"
            label="#"
            width="100"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="Тип"
            width="150"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                            <span class="textSM textRegular neutral400 cutOverflow">{{
                                    getTypeName(scope.row.type)
                                }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="integration"
            label="Интеграция"
            class-name="pointer"
            width="220px">
            <template v-slot="scope">
                <template v-if="scope.row.integration_unit_id">
                    <IntegrationLogo
                        :integration-unit-id="scope.row.integration_unit_id"
                    />
                </template>
                <template v-else>
                    <span class="textRegular textSM neutral200">-</span>
                </template>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import goodTypes from "@/mixins/goodTypes";

export default {
    name: "EditSelectGoods",
    components: {IntegrationLogo, TableIntegrationFilter, TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin, goodTypes],
    props: ['itemId'],
    inject: ['api'],
    data() {
        return {
            loading: true,
            loadingTable: true,
            filters: {
                goodType: null,
                integrationUnitId: null
            },
            goodsData: [],
        }
    },
    created() {
        this.getGoodsData()
    },
    methods: {
        getGoodsData() {
            this.loadingTable = true
            httpService().post(this.api.good.get, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filters,
                allData: true
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.goodsData = data.goods.data
                    this.valueLength = data.goods.total
                    this.loadingData = false
                    this.loadingTable = false
                }
            })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                this.selectItem(val.id)
            }
        },
        selectItem(goodId) {
            this.$emit('update', goodId)
            this.$emit('close')
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            this.getGoodsData()
        },
        page() {
            this.getGoodsData()
        },
        search() {
            this.getGoodsData()
        },
    }
}
</script>
