<template>
    <Auth :title="moduleName" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    v-model="search"
                    :loading="loadingButton"
                    create-button-label="Добавить значение"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openDrawerNewValue(null)"
                    :disable-filter="true"
                    :new="true"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="units"
                    :loading="loadingButton"
                    :filter-show="filterShow"
                />

                <div v-if="displayData">

                    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                        <template v-slot:body>

                            <MobileTableHR/>

                            <MobileTableInfo
                                title="Продукция"
                                :body="item.good_id ? getData(item.good_id, 'good') : '-'"
                            />

                            <MobileTableInfo
                                title="Кол-во"
                                :body="formatNumber(item.total, 2) + ' ' + (item.good_id ? getData(item.good_id, 'good', 'unit') : 'ед.')"
                            />

                            <MobileTableInfo
                                title="Склад"
                                :no-border="true"
                                :body="item.storehouse ? item.storehouse.name : '-'"
                            />

                            <MobileTableButton
                                title="Добавить/Списать"
                                :no-margin="true"
                                type="primary"
                                @action="openDrawerNewValue(item, item.storehouseId)"
                            />

                            <MobileTableButton
                                @action="openDrawerDetails(item)"
                            />

                        </template>
                    </MobileTableFramework>

                </div>

                <el-table
                    empty-text="Информация не найдена"
                    v-if="!smallMobile"
                    v-loading="loading"
                    :default-sort="{prop: 'name', order: 'ascending'}"
                    :data="displayData"
                    class="w-100 mt-15"
                    :summary-method="getSummaries"
                    show-summary>
                    <el-table-column
                        prop="name"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            <span class="textSM neutral900">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Количество"
                        width="170px"
                        sort-by="total"
                        sortable>
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.total !== 0 ? (scope.row.total > 0 ? 'primary600' : 'danger300') : 'neutral400')">{{ scope.row.total ? formatNumber(scope.row.total, 2) : 0 }} <good-unit :unit="scope.row.unit"/></span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="storehouse"
                        label="Внутренние склады">
                        <template v-slot="scope">
                            <template v-for="(value, index) in scope.row.values" :key="index">
                                <div class="textXS textRegular">
                                    <span class="textMedium neutral700">{{ value.storehouse ? value.storehouse.name : 'Склад по умолчанию' }}:</span> {{ value.total ? formatNumber(value.total, 2) : 0 }} <good-unit :unit="scope.row.unit"/>
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="170">
                        <template v-slot="scope">
                            <el-button
                                size="mini" type="primary" plain
                                @click="openDrawerNewValue(scope.row.good_id, scope.row.storehouseId)">Добавить/Списать
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="130">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="openDrawerDetails(scope.row)">Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            v-model="viewDetails"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <StorehouseGoodDetails
                :detail-good="detailGood"
                :units="units"
                @moduleGet="moduleGet()"
                @openEditDetails="openEditDetails"
                @openCreateDrawer="openCreateDrawerFromDetails()"
                @closeDrawerDetails="closeDrawerDetails()"
            />

        </el-drawer>

        <el-drawer
            v-model="editDetailsDrawer"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">

            <StorehouseGoodEdit
                @moduleGet="moduleGet()"
                @closeDrawer="editDetailsDrawer=false"
                :detail-good="detailGood"
            />

        </el-drawer>

        <el-drawer
            v-model="createGood"
            title=""
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <StorehouseCreateGood
                :goods="storehouseGoods"
                :units="units"
                :goodId="createGoodForm.good_id"
                :unitId="createGoodForm.unit_id"
                :formTypes="formTypes"
                @moduleGet="moduleGet()"
                @closeDrawerCreateGood="closeDrawerCreateGood"
            />
        </el-drawer>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import StorehouseCreateGood from '../components/StorehousePage/StorehouseCreateGood'
import tableMixin from '../../mixins/tableMixin'
import functionsMixin from '../../mixins/functionsMixin'
import basicMixin from '../../mixins/basicMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import Filter from '@/views/components/Table/Filter/Filter'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import StorehouseGoodDetails from "@/views/components/StorehousePage/StorehouseGoodDetails";
import StorehouseGoodEdit from "@/views/components/StorehousePage/StorehouseGoodEdit";
import GoodUnit from "@/views/components/goodUnit.vue";

export default {
    name: 'StorehouseIndex',
    components: {
        GoodUnit,
        StorehouseGoodEdit,
        StorehouseGoodDetails,
        TablePagination,
        MobileTableHR,
        MobileTableButton,
        MobileTableInfo,
        MobileTableFramework,
        Filter,
        TableHeadBlock,
        Auth,
        StorehouseCreateGood
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, functionsMixin, basicMixin, mobileCheckMixin, iconsMixin
    ],
    data() {
        return {
            module: null,
            moduleName: null,
            values: [],
            valuesByGoods: [],
            formTypes: [],
            filter: {
                goodId: {
                    value: null,
                    type: 'select',
                    typeSelect: 'goods',
                }
            },
            editDetailsDrawer: false,
            storehouseGoods: [],
            units: [],
            viewDetails: false,
            createGood: false,
            detailGood: null,
            valuesGood: [],
            createGoodForm: {
                good_id: null,
                unit_id: null,
                action_id: null,
                value: null
            },
            loadingValueDetails: false,
            integrations: [],
            relations: [],
            pageSize: 30
        }
    },
    created() {
        this.moduleGet()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        moduleGet() {
            this.loadingButton = true
            httpService().post(this.api.storehouse.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.module = data.module
                this.moduleName = data.module.name
                this.values = data.module.values
                this.valueLength = this.values.length
                this.formTypes = data.module.formTypes
                this.units = data.module.units
                this.drivers = data.drivers
                this.companies = data.companies
                this.storehouseGoods = data.goods
                this.integrations = data.integrations
                this.relations = data.relations
                if (this.detailGood) this.detailGood = this.values.find(el => el.id === this.detailGood.id)
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        openDrawerDetails(row) {
            this.detailGood = row
            this.viewDetails = true
        },
        closeDrawerDetails() {
            this.viewDetails = false;
        },
        openCreateDrawerFromDetails() {
            this.viewDetails = false
            this.openDrawerNewValue(this.detailGood.good_id, this.detailGood.storehouseId)
        },
        openDrawerNewValue(goodId, storehouseId) {
            if (goodId) {
                this.createGoodForm.good_id = goodId
            } else {
                this.createGoodForm.good_id = ''
            }

            if (storehouseId) {
                this.createGoodForm.unit_id = storehouseId
            } else {
                this.createGoodForm.unit_id = null
            }
            this.createGood = true
        },
        closeDrawerCreateGood() {
            this.createGood = false
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 1) {
                    let total = 0
                    this.displayData.forEach(function (value) {
                        total = total + Number(value.total)
                    })
                    sums[index] = this.formatNumber(total, 2) + '  ед.'
                    return
                }
            })

            return sums
        },
        openEditDetails(details) {
            this.viewDetails = false
            this.detailGood = details
            this.editDetailsDrawer = true
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) this.moduleGet()
        }
    },
}
</script>

<style scoped>

</style>
