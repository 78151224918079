<template>
    <Auth title="Уведомления">
        <div class="card">
            <div class="card-body">

                <div class="notifications-head">

                    <div class="tabs-head">

                        <div v-for="(tab, index) in tabs"
                             :key="index"
                             class="tab-head"
                             :class="{ active : activeTab.value === tab.value }"
                             @click="toggleTab(tab)">
                            {{ tab.name }}
                        </div>

                    </div>

                    <div v-if="notifications['new'] && Object.keys(notifications['new']).length && activeTab.value === 'new'"
                        class="read-notifications"
                         :class="{disabled: !notifications && !Object.keys(notifications).length}"
                         @click="readNotifications">
                        Отметить как прочитанные
                    </div>

                </div>

                <div class="notifications-body">

                    <div class="tabs-body">

                        <div v-for="(tab, index) in tabs"
                             :key="index"
                             v-show="activeTab.value === tab.value"
                             class="tab-body">

                            <div v-if="notifications[tab.value] && Object.keys(notifications[tab.value]).length" class="notifications">

                                <div v-for="(notification, index) in notifications[tab.value]"
                                     :key="index"
                                     class="notification">

                                    <div class="icon">
                                        <InfoIcon v-if="notification.type === 'info'"/>
                                        <WarningIcon v-if="notification.type === 'warning'"/>
                                        <ErrorIcon v-if="notification.type === 'error'"/>
                                    </div>

                                    <div class="date">
                                        {{ getDateByTz(notification.timestamp, 'DD.MM.YY', getUserTz()) }}
                                        <span class="time">{{ getDateByTz(notification.timestamp, 'HH:mm', getUserTz()) }}</span>
                                    </div>

                                    <div class="content">
                                        <div class="title">{{ notification.title }}</div>
                                        <div class="body">{{ notification.body }}</div>
                                    </div>

                                    <a v-if="notification.link"
                                                 :href="notification.link"
                                                 @click.prevent="onClick(notification)"
                                                 class="btn">Подробнее</a>

                                </div>

                            </div>

                            <div v-else class="notifications-empty">

                                <EmptyIcon/>

                                <span>Нет уведомлений</span>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue';
import {httpService} from '@/services/http.service';
import EmptyIcon from "../components/Icons/Notifications/EmptyIcon.vue";
import InfoIcon from "../components/Icons/Notifications/InfoIcon.vue";
import WarningIcon from "../components/Icons/Notifications/WarningIcon.vue";
import ErrorIcon from "../components/Icons/Notifications/ErrorIcon.vue";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'NotificationsIndex',
    components: {ErrorIcon, WarningIcon, InfoIcon, EmptyIcon, Auth},
    mixins: [functionsMixin, ],
    data() {
        let tabs = [
            {
                name: 'Новые',
                value: 'new'
            },
            {
                name: 'Архив',
                value: 'archive'
            }
        ];
        let activeTab = tabs[0];

        return {
            tabs,
            activeTab,
            notifications: [],
            loading: false,

            type: '',
            title: '',
            body: '',
            link: ''
        }
    },
    inject: [
        'api'
    ],
    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
        onClick(notification) {
            if (!notification.read_at) {
                httpService().post(this.api.notifications.all + '/' + notification.id + '/read');
            }

            if (notification.link) {
                window.location.href = notification.link
            }
        },
        getNotifications() {
            httpService().post(this.api.notifications.all)
                .then((response) => {
                    this.notifications = response.data.notifications;
                    this.loading = true;
                })
        },
        readNotifications() {
            httpService().post(this.api.notifications.readAll)
                .then((response) => {
                    this.notifications = response.data.notifications;
                })
        },
        // sendNotification() {
        //     httpService().post(this.api.notifications.send, {
        //         type: this.type,
        //         title: this.title,
        //         body: this.body,
        //         link: this.link
        //     }).then(() => {
        //
        //     })
        // }
    },
    async mounted() {
        await this.getNotifications();
    }
}
</script>

<style scoped>

</style>
