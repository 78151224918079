export default {
    data() {
        return {
            techStatuses: [{
                id: 'active',
                name: 'Активно'
            }, {
                id: 'repair',
                name: 'В ремонте'
            }, {
                id: 'unused',
                name: 'Не используется'
            }],
        }
    },
}
