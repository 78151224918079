<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.668 12.5L17.5013 12.5" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 12.5H4.16667" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.2513 14.5837C7.4019 14.5837 8.33464 13.6509 8.33464 12.5003C8.33464 11.3497 7.4019 10.417 6.2513 10.417C5.10071 10.417 4.16797 11.3497 4.16797 12.5003C4.16797 13.6509 5.10071 14.5837 6.2513 14.5837Z" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.668 7.5H17.5013" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 7.5H8.33333" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.7513 9.58366C14.9019 9.58366 15.8346 8.65092 15.8346 7.50033C15.8346 6.34973 14.9019 5.41699 13.7513 5.41699C12.6007 5.41699 11.668 6.34973 11.668 7.50033C11.668 8.65092 12.6007 9.58366 13.7513 9.58366Z" stroke="#1984E6" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
