<template>
    <Auth title="CRM" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :loading="loading"
                    :disable-search="true"
                    @moduleGet="moduleGet()"
                    create-button-label="Добавить задачу"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openDrawerNew"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="statuses"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="задач"
                />

                <OrderEvents
                    :events="displayData"
                    :loading="loading"
                    :hide-header="true"
                    :open-drawer="openDrawer"
                    @moduleGet="moduleGet"
                    @closeDrawer="() => openDrawer = false"
                />

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import CRMTabs from "@/mixins/tabs/CRMTabs";
import {httpService} from "@/services/http.service";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import tableMixin from "@/mixins/tableMixin";
import OrderEvents from "@/views/components/MixOrderGroup/components/order/OrderEvents.vue";
import Filter from "@/views/components/Table/Filter/Filter.vue";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'CRMTasks',
    mixins: [CRMTabs, tableMixin, functionsMixin],
    data() {
        return {
            loading: false,
            openDrawer: false,
            filter: {
                status: {
                    value: null,
                    type: 'select',
                    label: 'Статус',
                    placeholder: 'Выберите статус',
                    items: '1'
                },
            },
            statuses: [{
                id: 'new',
                name: 'Новый'
            }, {
                id: 'done',
                name: 'Завершенные'
            }, {
                id: 'canceled',
                name: 'Отмененные'
            }]
        }
    },
    components: {
        OrderEvents,
        EmptyState, TableHeadBlock,
        Auth, Filter
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        openDrawerNew () {
            this.openDrawer = !this.openDrawer
            this.moduleGet()
        },
        moduleGet() {
            this.loading = true
            httpService().post(this.api.crmTasks.index, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'crm',
                date: null,
                filter: this.filter
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }

            })
        },
        filterValues() {
            this.filtered = this.values
            this.valueLength = this.filtered.length
            return this.filtered
        }
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this.moduleGet()
            }
        }
    }
}
</script>

<style scoped>

</style>
