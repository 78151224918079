<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card dispatch-order-groups-create">
            <div class="card-body">

                <DistributeOrderGroup
                    v-if="distributing"
                    :orders="distributeOrders"
                    :metrics="metrics"
                    @create="createOrderGroup"
                    @update="updateOrderGroup"
                />

                <div v-show="!distributing">
                    <div class="primary500 textLG textMedium mt-15 mb-15">
                        Новый заказ
                    </div>

                    <MixOrderGroupCreateForm
                        v-loading="loadingButtons.create"
                        :default-form-data="defaultFormData"
                        v-model="form"
                        :mixers="mixers"
                        :disable-form="disableForm"
                        :buyerCompanyId="buyerCompanyId"
                        @getOrderGroupInfoCreate="getOrderGroupInfoCreate"
                        @updateBuyerCompanyId="updateBuyerCompanyId"
                    />

                    <hr class="mb-30"/>

                    <div>
                        <el-button
                            :disabled="loadingButtons.create"
                            type="primary"
                            @click="createOrderGroup"
                        >
                            Создать заказ
                        </el-button>

                        <el-button
                            v-if="form.delivery_type === 'delivery'"
                            :disabled="loadingButtons.distribute"
                            @click="distributeOrder"
                        >
                            Распределить заказ
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </Auth>
</template>
<script>
import {mapState, mapStores} from 'pinia';
import {useCommonStore} from "@/store/common";
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import Auth from '@/views/layouts/Auth.vue'
import {useMixStore} from '@/store/mix';
import {httpService} from "@/services/http.service";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupFormData from "@/mixins/mixOrderGroupFormData";
import MixOrderGroupCreateForm from "@/views/components/MixOrderGroup/MixOrderGroupCreateForm.vue";
import DistributeOrderGroup from "@/views/dispatch/DistributeOrderGroup.vue";

export default {
    name: 'DispatchOrderGroupsCreate',
    mixins: [tableMixin, mixOrderGroupFormData],
    inject: ['api'],
    components: {
        MixOrderGroupCreateForm,
        DistributeOrderGroup,
        Auth,
    },
    data() {
        return {
            mixers: [],
            loadingButtons: {
                create: false,
                distribute: false,
            },
            distributing: false,
            metrics: [],
            distributeOrders: [],
            timeInfo: {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            },
            disableForm: false,
            buyerCompanyId: null,
            loadingInfo: false,
            defaultFormData: {},
        }
    },
    created() {
        this.form.delivery_price_manual_edit = false
        this.form.payment_method = null
        this.form.delivery_price = null
        this.form.time_free_unloading = null
        this.form.time_unloading = null
        this.form.gidrolotok = false
        this.form.good_price = null
        this.form.invoice_id = null
        this.form.contract_id = null
        this.form.constructive = null
        this.form.lab = false
        this.form.spec_good_id = null
        this.form.good_id = null
        this.form.manager_id = null
        this.form.carrier_company_id = null
        this.form.delivery_type = 'delivery'
        this.form.individual = false
        this.form.buyer_company_id = null
        this.form.total = null
        this.form.comment = null
        this.form.orders_count = 0
        this.form.delivery_address_id = null
        this.form.company_manager_id = null
        this.form.delivery_difficulty = 1
        this.form.delivery_difficulty_new = 1
        this.form.delivery_difficulty_old = 1
        this.form.prepayment = null
        this.form.pump = false
        this.form.pump_vehicle_id = null
        this.form.pump_price = null
        this.form.pump_cost_type = null
        this.form.comment_pump_driver = null
        this.form.mixers = []
        this.form.seller_company_id = null
        this.form.type_delivery = 'withoutInterval'
        this.form.type_mixers_count = 'many'
        this.form.mix_ids = []
        this.form.mixer_ids = []
        this.form.mixer_max_volume = null
        this.form.axle = null
        this.form.custom_mixers_enabled = false
        this.form.max_load = false
        this.form.max_remainder = 0.0
        this.form.custom_mixers = []
        this.form.algorithm_goal = 'minimum_vehicles'
        this.form.date_shipment = this.filters['filter[date]']

        this.getSchedules()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            goods: 'getGoods',
        }),
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
        }),
        ...mapStores(useMixStore),
    },
    methods: {
        createOrderGroup() {
            let formData = {...this.form}

            if (formData.delivery_type !== 'delivery') {
                formData.delivery_address_id = null
                formData.company_manager_id = null
                formData.first_order_time_delivery = null
                formData.type_delivery = 'withoutInterval'
                formData.time_unloading = null
                formData.axle = null
                formData.max_load = false
                formData.pump = false
                formData.pump_vehicle_id = null
                formData.pump_price = null
                formData.pump_cost_type = null
                formData.custom_mixers_enabled = false
            }

            this.loadingButtons.create = true

            if (!formData.pump) {
                delete formData.pumpInfo;
            }

            formData.status = 'confirmed';

            httpService().post(this.api.dispatch.orderGroups.createNewOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: formData
            }).then(() => {
                this.$message({
                    message: 'Заказ создан',
                    showClose: true,
                    type: 'success'
                })

                this.filters['filter[date]'] = moment(formData.date_shipment).format('YYYY-MM-DD')

                this.$router.push({
                    name: 'DispatchOrderGroups',
                    params: {id: this.$route.params.id}
                })
            }).catch((error) => {
                const {errors} = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            }).finally(() => this.loadingButtons.create = false)
        },
        distributeOrder() {
            let formData = {...this.form};

            this.loadingButtons.distribute = true
            this.distributing = true

            httpService().post(this.api.dispatch.orderGroups.distributeNewOrderGroup, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: formData
            }).then(({data}) => {
                this.distributeOrders = data.orders;
                this.metrics = data.metrics;

            }).catch((error) => {
                this.distributing = false

                const {errors} = error.response.data

                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
            }).finally(() => this.loadingButtons.distribute = false)
        },
        getSchedules() {
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    if (!this.$route.params?.change) {
                        this.form.mixer_ids = response.data.mixer_ids
                        this.form.mix_ids = response.data.account_module_mix_ids
                        this.form.account_module_ids = response.data.account_module_mix_ids
                    }
                }
            })
        },
        getOrderGroupInfoCreate(buyerCompanyId) {
            if (!this.loadingInfo) {
                this.loadingInfo = true
                this.disableForm = true
                const free_unload_time = this.companies.find((c) => c.id == this.form.buyer_company_id)?.free_unload_time

                httpService().post(this.api.dispatch.getLast, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    buyer_company_id: buyerCompanyId
                }).then((response) => {
                    let {data} = response.data
                    if (data && !this.form.good_id) {
                        this.form.good_id = data.good_id
                        this.form.good = data.good
                        this.form.companyGoods = data.goods
                        if (data.good_price && data.good_price !== 0) this.form.good_price = data.good_price
                        if (data.delivery_price && data.delivery_price !== 0) this.form.delivery_price = data.delivery_price
                        this.form.payment_method = data.payment_method
                        this.form.delivery_address_id = data.delivery_address_id
                        this.form.company_manager_id = data.company_manager_id
                        this.form.seller_company_id =  this.form.seller_company_id ?? data.seller_company_id
                        this.form.carrier_company_id =  this.form.carrier_company_id ?? data.carrier_company_id
                        this.form.time_unloading = data.time_unloading
                        this.form.invoice_id = data.invoice_id
                        this.form.contract_id = data.contract_id
                        this.form.mix_service_tariff_id = data.mix_service_tariff_id
                        this.form.pump_service_tariff_id = data.pump_service_tariff_id
                        this.form.downtime_service_tariff_id = data.downtime_service_tariff_id
                        this.defaultFormData = {...data};
                    }
                }).finally(() => {
                    this.form.time_unloading = (this.form.time_unloading ?? free_unload_time) ?? 40
                    this.disableForm = false
                    this.loadingInfo = false
                })
            }
        },
        updateOrderGroup() {
            this.distributing = false
            this.distributeOrders = []
        },
        updateBuyerCompanyId(value) {
            this.buyerCompanyId = value
        },
    },
}
</script>
<style scoped lang="scss">

</style>

