<template>
    <div class="progress-bar">
        <div
            class="progress-bar-section"
            :style="{ width: `${firstSectionPercent}%`, backgroundColor: firstColor }"
            @animationend="onAnimationEnd"
        ></div>
        <div
            class="progress-bar-section"
            :style="{ width: `${secondSectionPercent}%`, backgroundColor: secondColor }"
            @animationend="onAnimationEnd"
        ></div>
        <div
            class="progress-bar-section"
            :style="{ width: `${thirdSectionPercent}%`, backgroundColor: thirdColor }"
            @animationend="onAnimationEnd"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressMultiBar',
    props: {
        percent1: {
            type: Number,
            required: true,
        },
        percent2: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            firstSectionPercent: 0,
            secondSectionPercent: 0,
            thirdSectionPercent: 0,
            firstColor: '#1677FF',
            secondColor: '#00D0A3',
            thirdColor: '#F0F0F0',
        };
    },
    watch: {
        'percent1': {
            handler: function () {
                this.animateFirstSection(this.percent1);
                this.animateSecondSection(this.percent2);
                this.animateThirdSection();
            },
            deep: true,
            immediate: true
        },
        'percent2': {
            handler: function () {
                this.animateFirstSection(this.percent1);
                this.animateSecondSection(this.percent2);
                this.animateThirdSection();
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        animateFirstSection(percent) {
            this.firstSectionPercent = percent;
        },
        animateSecondSection(percent) {
            this.secondSectionPercent = percent - this.percent1;
        },
        animateThirdSection() {
            this.thirdSectionPercent = 100 - this.percent2;
        },
        onAnimationEnd() {
            if (
                this.firstSectionPercent >= this.percent1 &&
                this.secondSectionPercent >= this.percent2 - this.percent1
            ) {
                this.thirdSectionPercent = 100 - this.percent2;
            }
        },
    },
    mounted() {
        this.animateFirstSection(this.percent1);
        this.animateSecondSection(this.percent2);
        this.animateThirdSection();
    },
};
</script>

<style scoped>
.progress-bar {
    width: 100%;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
}

.progress-bar-section {
    height: 100%;
    display: inline-block;
    transition: width 0.5s ease;
}
</style>
