<template>
    <svg :class="[{'currentColor' : currentColor}]"
         xmlns="http://www.w3.org/2000/svg" width="16" height="16"
         viewBox="0 0 24 24"
         fill="none">
        <path d="M6 2H18C19.1 2 20 2.9 20 4V8.32001H4V4C4 2.9 4.9 2 6 2Z"
              stroke="#8a909a"
              :stroke-width="strokeWidth ? strokeWidth : '1.5'" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M4 8.32007V11.8801C4 12.9601 4.58003 13.9601 5.53003 14.4901L8.48999 16.16C9.11999 16.51 9.51001 17.1801 9.51001 17.9001V20.0001C9.51001 21.1001 10.41 22.0001 11.51 22.0001H12.51C13.61 22.0001 14.51 21.1001 14.51 20.0001V17.9001C14.51 17.1801 14.9 16.51 15.53 16.16L18.49 14.4901C19.43 13.9601 20.02 12.9601 20.02 11.8801V8.32007H4Z"
            stroke="#8a909a" :stroke-width="strokeWidth ? strokeWidth : '1.5'" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "RecipeIcon",
    props: ['currentColor', 'strokeWidth']
}
</script>

<style scoped>
    .currentColor {
        path {
            stroke: currentColor;
        }
    }
</style>
