<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-filter="true"
        :disable-create-button="true"
        @moduleGet="moduleGet"
        @openCreateValueDrawer="setCreateDrawerOpen"
    >
        <template v-slot:afterSearchAndFilter>
            <TableIntegrationFilter
                v-model="filters.integrationUnitId"
                @moduleGet="moduleGet"
                :table="true"
            />
        </template>
    </TableHeadBlock>

    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
        <template v-slot:body>

            <MobileTableHR/>

            <MobileTableInfo
                title="Название"
                :body="item.name ?? '-'"
            />

            <MobileTableButton
                title="Выбрать"
                @action="createRelation(item.id)"
            />

        </template>
    </MobileTableFramework>

    <el-table
        empty-text="Информация не найдена"
        v-loading="loading"
        v-if="!mobile"
        :data="values"
        class="w-100 mt-15">
        <el-table-column
            prop="id"
            label="#"
            width="100"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
            sortable>
            <template v-slot="scope">
                <span :class="'textMedium textSM ' + (scope.row.child ? 'neutral400' : 'neutral900')">{{
                        scope.row.name ?? '-'
                    }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="integration"
            label="Интеграция"
            width="150px">
            <template v-slot="scope">
                <template v-if="scope.row.integration_unit_id">
                    <IntegrationLogo
                        :integration-unit-id="scope.row.integration_unit_id"
                    />
                </template>
                <template v-else>
                    <span class="textRegular textSM neutral200">-</span>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="150">
            <template v-slot="scope">
                <el-button
                    size="small"
                    @click="createRelation(scope.row.id)"
                >Выбрать
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import functionsMixin from '@/mixins/functionsMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework.vue'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR.vue'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo.vue'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton.vue'
import TablePagination from '@/views/components/Table/TablePagination.vue'
import {httpService} from '@/services/http.service'
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";


export default {
    name: 'CompanyCreateRelationDialog',
    data() {
        return {
            drawerCreate: false,
            filters: {
                integrationUnitId: null
            },
        }
    },
    props: ['companyId', 'integrations', 'accountModules'],
    components: {
        TableIntegrationFilter,
        IntegrationLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, basicMixin, functionsMixin
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.company.get, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filters,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.companies.data
                    this.valueLength = data.companies.total
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        setPage(val) {
            this.page = val
        },
        setCreateDrawerOpen() {
            this.drawerCreate = !this.drawerCreate
        },
        closeDrawer() {
            this.drawerCreate = false
        },
        createRelation(id) {
            this.$confirm(
                'Вы действительно хотите образовать связь между ' + this.getData(this.companyId, "company") + ' (#' + this.companyId +') и ' + this.getData(id, "company") + ' (#' + id + ')?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Подтверждаю',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.$emit('createRelation', id)
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {

        }
    },
    watch: {
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        }
    }
}
</script>

<style scoped>

</style>
