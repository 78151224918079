<template>
    <el-table
        empty-text="Информация не найдена"
        v-if="!mobile"
        :data="data"
        v-loading="loading"
        :show-header="false"
        class="w-100">
        <el-table-column
            prop="date"
            label="Дата">
            <template v-slot="scope">
                {{ getDateFromUnix(scope.row.date, isGrouped ? dateFormat : null, tz) }}
            </template>
        </el-table-column>
        <el-table-column
            prop="value"
            label="Значение">
            <template v-slot="scope">
                            <span v-if="scope.row.action_id === 1" class="typeAddition">+ {{
                                    formatNumber(scope.row.value, 2)
                                }} {{ scope.row.unit ? scope.row.unit.short_name : 'ед.' }}</span>
                <span v-if="scope.row.action_id === 2" class="typeSubtraction">- {{
                        formatNumber(scope.row.value, 2)
                    }} {{ scope.row.unit ? scope.row.unit.short_name : 'ед.' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="value"
            fixed="right"
            v-if="!isGrouped"
            width="70px"
            label="">
            <template v-slot="scope">
                <el-button :loading="loading" type="info" size="mini" plain :icon="icons.delete"
                           @click.prevent="doRemoveValue(scope.row.id)"></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "StorehouseGoodDetailsValuesTable",
    props: [
        'data',
        'isGrouped',
        'loading',
        'dateFormat',
        'tz'
    ],
    mixins: [functionsMixin, mobileCheckMixin, iconsMixin, tableMixin],
    methods: {
        filterValues() {
            return this.data;
        },

        doRemoveValue(id) {
            this.$emit('doRemoveValue', id);
        }
    }
}
</script>

<style scoped>

</style>
