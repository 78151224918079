<template>
    <el-steps :active="active" class="mb-30">
        <el-step title="Отчет"></el-step>
        <el-step title="Работы"></el-step>
        <el-step title="Запчасти"></el-step>
    </el-steps>

    <div v-if="active===1">

        <h3 class="color-primary text-500">Напишите отчет о проделанных работах</h3>
        <el-form label-position="top">

            <FormTextarea
                v-model="form.report"
                label="Отчет о работе"
                placeholder="Все ли выполнено в рамках работ? Какие возникли трудности?"
                :rows="4"
            />

        </el-form>

    </div>

    <div v-if="active===2">

        <h3 class="color-primary text-500">Все ли работы выполнены?</h3>

        <ServiceCreateWorks
            v-model="form.serviceWorks"
            :disable-heading="true"
        />
    </div>

    <div v-if="active===3">

        <h3 class="color-primary text-500">Какие запчасти были использованы?</h3>

        <ServiceCreateGoods
            v-model="form.serviceGoods"
            :disable-heading="true"
        />
    </div>


    <el-button v-if="active!==3" type="primary" @click="active++">Продолжить</el-button>
    <el-button v-if="active===3" type="primary" @click="endWork">
        Завершить
    </el-button>
    <el-button v-if="active!==1" @click="active--" plain>Вернуться назад</el-button>
</template>

<script>
import FormTextarea from '@/views/components/Form/FormTextarea'
import ServiceCreateWorks from "@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateWorks";
import ServiceCreateGoods from "@/views/components/TechPage/Service/ServiceCreateWork/ServiceCreateGoods";
import {httpService} from "@/services/http.service";

export default {
    name: 'TechServiceEndWork',
    components: {ServiceCreateGoods, ServiceCreateWorks, FormTextarea},
    props: ['serviceDetails'],
    inject: ['api'],
    data() {
        return {
            active: 1,
            form: {
                report: null,
                serviceWorks: this.serviceDetails.serviceWorks,
                serviceGoods: this.serviceDetails.serviceGoods
            }
        }
    },
    methods: {
        endWork() {
            httpService().post(this.api.techVehicle.updateStatus, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                workId: this.serviceDetails.id,
                statusId: 4,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
                this.$emit('closeDialog')
                this.$emit('moduleGet')

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        }
    }
}
</script>

<style scoped>

</style>
