<template>
    <div>
        <el-button v-if="goodsType" size="small" @click="openAddGoodDialog()" :disabled="loading">
            Добавить продукцию
        </el-button>
        <el-button v-else size="small" @click="openAddServiceDialog()" :disabled="loading">
            Добавить услугу
        </el-button>
    </div>

    <el-table
        v-loading="loading"
        :data="paginate ? displayData : servicesData"
        empty-text="Услуг не найдено"
        class="w-100 mt-15 serviceTable">
        <el-table-column
            prop="type"
            label="Тип"
            width="55"
            v-if="windowWidth > 1300"
        >
            <template v-slot="scope">
                <template v-if="scope.row.type === 'pump'">
                    <el-tooltip :content="scope.row.confirm ? 'Подтвержденная услуга насоса' : 'Услуги насоса'"
                                placement="left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :class="{ serviceIconConfirm: scope.row.confirm }"
                             width="28" height="28" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                             class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="21" cy="46" r="3" fill="" opacity="1"></circle><circle
                            cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="11" cy="46" r="3" fill=""
                                                                                       opacity="1"></circle><path
                            d="m25 39 4 6h14" fill="" opacity="1"></path><path
                            d="M47 39V21h6.353a3 3 0 0 1 2.531 1.39l5.647 8.873A3 3 0 0 1 62 32.874V43.5a1.5 1.5 0 0 1-1.5 1.5h-2.669a1 1 0 0 1-.972-.767A7.276 7.276 0 0 0 52 39H8l-4 5H2V33l45-3"
                            fill="" opacity="1"></path><path
                            d="M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M25.27 31.45 5.52 24.14a.969.969 0 0 1-.66-.71l-.76-2.16a.857.857 0 0 1-.05-.27.662.662 0 0 1 .01-.28l.71-3.12a.987.987 0 0 1 .9-.68L50.97 15a1.029 1.029 0 0 1 .85.42 1.054 1.054 0 0 1 .11.96L50.52 21M7 21h40M4.05 21H7"
                            fill="" opacity="1"></path><path d="m8 21 25.466 5.877a1.5 1.5 0 0 1 .863.562l2.431 3.241"
                                                             fill="" opacity="1"></path></g></g></svg>
                    </el-tooltip>
                </template>
                <template v-if="scope.row.type === 'mix'">
                    <el-tooltip
                        :content="scope.row.confirm ? 'Подтвержденная услуга доставки бетона' : 'Услуга доставки бетона'"
                        placement="left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :class="{ serviceIconConfirm: scope.row.confirm }"
                             width="28" height="28" x="0" y="0" viewBox="0 0 64 64" xml:space="preserve"
                             class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="19" cy="46" r="3" fill="" opacity="1"></circle><circle
                            cx="50" cy="46" r="3" fill="" opacity="1"></circle><circle cx="9" cy="46" r="3" fill=""
                                                                                       opacity="1"></circle><path
                            d="m2 44 4-5h46a7.274 7.274 0 0 1 4.859 5.233 1 1 0 0 0 .972.767H60.5a1.5 1.5 0 0 0 1.5-1.5V32.873a3 3 0 0 0-.469-1.61l-5.647-8.874A3 3 0 0 0 53.353 21H47"
                            fill="" opacity="1"></path><path
                            d="m22 39 5 6h16M47 39V17.621a1.5 1.5 0 0 0-.44-1.061L45 15M61.87 32H52a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5.55M62 37h-2M39.1 39l3.7-5.9a1.345 1.345 0 0 0-.125-1.6l-6.734-7.9-14.77-7.7-9.671-.893a1.649 1.649 0 0 0-1.423.571L5.23 23.106a1.426 1.426 0 0 0 .119 1.711l6.319 7.442L26.438 39"
                            fill="" opacity="1"></path><circle cx="44" cy="27" r="3" fill="" opacity="1"></circle><path
                            d="M35.933 23.593 27.493 39M21.163 15.889l-9.495 16.37M8 39V27.94" fill=""
                            opacity="1"></path></g></g>
                        </svg>
                    </el-tooltip>
                </template>
                <template v-if="scope.row.type === 'downtime'">
                    <el-tooltip
                        :content="scope.row.confirm ? 'Подтвержденная услуга простоя миксера на объекте' : 'Простой миксера на объекте'"
                        placement="left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :class="{ serviceIconConfirm: scope.row.confirm }"
                             width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
                             xml:space="preserve" class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="30" cy="38" r="24" fill="" opacity="1"></circle><path
                            d="M24 2h12v5H24zM30 7v7M50.59 16.41l-4.13 4.13M47.181 13 54 19.819" fill=""
                            opacity="1"></path><circle cx="30" cy="38" r="4" fill="" opacity="1"></circle><path
                            d="M30 34V22M30 42v2M44 38h2M16 38h-2M30 52v2" fill="" opacity="1"></path></g></g>
                        </svg>
                    </el-tooltip>
                </template>
                <template v-if="scope.row.type === 'good'">
                    <el-tooltip
                        :content="scope.row.confirm ? 'Подтвержденная продукция' : 'Продукция'"
                        placement="left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :class="{ serviceIconConfirm: scope.row.confirm }"
                             width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
                             xml:space="preserve" class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M2 5a3 3 0 0 1 3-3h54a3 3 0 0 1 3 3v13H2zM5 23v36a3 3 0 0 0 3 3h48a3 3 0 0 0 3-3V23" fill="" opacity="1"></path><path
                            d="M20 27h24v10H20z" fill="" opacity="1"></path></g></g></svg>
                    </el-tooltip>
                </template>
                <template v-if="scope.row.type === 'other'">
                    <el-tooltip :content="scope.row.confirm ? 'Подтвержденная услуга' : 'Услуга'"
                                placement="left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             :class="{ serviceIconConfirm: scope.row.confirm }"
                             width="28" height="28" x="0" y="0" viewBox="0 0 64 64"
                             xml:space="preserve" class="serviceIcon"><g><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10 21v39a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H24L10 16h12a2 2 0 0 0 2-2V2M20 51h24M20 39h24M20 27h24M33 15h11" fill="" opacity="1"></path></g></g>
                        </svg>
                    </el-tooltip>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            :label="goodsType ? 'Продукция' : 'Услуга'"
        >
            <template v-slot="scope">
                <span class="cutOverflow textSM textMedium" :class="[
                    { neutral200: !scope.row.confirm },
                    { neutral900: scope.row.confirm }
                ]">{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="mix_order_id"
            label="Отгрузка"
            width="120"
        >
            <template v-slot="scope">
                <template v-if="scope.row.mix_order_id">
                    <div>
                        <div @click="openOrderDetails(scope.row.mix_order_id)" class="textMedium textSM pointer">
                            <span
                                :class="[
                    { neutral200: !scope.row.confirm },
                    { warning300: scope.row.confirm }
                ]">#{{
                                    formatNumber(scope.row.mix_order_id)
                                }}</span>
                            <span
                                class="ml-5 textRegular"
                                :class="[
                    { neutral200: !scope.row.confirm },
                    { neutral400: scope.row.confirm }
                ]">{{ getOrderData(scope.row.mix_order_id, 'time') }}</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    -
                </template>
            </template>
        </el-table-column>
        <el-table-column
            prop="vehicle_id"
            label="ТС"
            width="120"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: !scope.row.confirm },
                    { primary600: scope.row.confirm }
                ]"
                    class="textMedium textSM cutOverflow">{{
                        scope.row.vehicle ? getVehicleNumber(scope.row.vehicle?.number) : '-'
                    }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="price"
            label="Цена (за ед.)"
            width="115"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: !scope.row.confirm },
                    { success400: scope.row.confirm }
                ]"
                    class="textMedium textSM">{{ formatNumber(scope.row.price, 2) }} руб.</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="Кол-во"
            width="65"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: !scope.row.confirm },
                    { neutral900: scope.row.confirm }
                ]"
                    class="textMedium textSM">{{ formatNumber(scope.row.total, 2) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="vat"
            label="НДС (%)"
            width="70"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: !scope.row.confirm },
                    { neutral900: scope.row.confirm }
                ]"
                    class="textRegular textSM">{{ formatNumber(scope.row.vat ?? 0) }}%</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="full_price"
            label="Итого"
            width="130"
        >
            <template v-slot="scope">
                <span
                    :class="[
                    { neutral200: !scope.row.confirm },
                    { primary600: scope.row.confirm }
                ]"
                    class="textMedium textSM">{{ formatNumber(scope.row.full_price, 2) }} руб.</span>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="150"
            v-if="windowWidth > 1300">
            <template v-slot="scope">
                <el-button
                    size="small"
                    v-if="!scope.row.confirm"
                    :disabled="checkConfirmMixOrder(scope.row.mix_order_id)"
                    @click="confirmService(scope.row.id, true)"
                    :icon="icons.check"
                />
                <el-button
                    size="small"
                    v-if="scope.row.confirm"
                    @click="confirmService(scope.row.id, false)"
                    :icon="icons.remove"
                />
                <el-button
                    size="small"
                    :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                    @click="editOrderDetails(scope.row)"
                    :icon="icons.edit"
                />
                <el-button
                    size="small"
                    :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                    @click="removeService(scope.row.id)"
                    :icon="icons.delete"
                />
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="60"
            v-if="windowWidth <= 1300">
            <template v-slot="scope">
                <el-dropdown trigger="click">
                    <el-button size="small" :icon="icons.operation"/>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-if="!scope.row.confirm"
                                :disabled="checkConfirmMixOrder(scope.row.mix_order_id)"
                                @click="confirmService(scope.row.id, true)"
                                :icon="icons.check"
                            >Подтвердить
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="scope.row.confirm"
                                @click="confirmService(scope.row.id, false)"
                                :icon="icons.remove"
                            >Снять подтверждение
                            </el-dropdown-item>
                            <el-dropdown-item
                                :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                @click="editOrderDetails(scope.row)"
                                :icon="icons.edit"
                            >Подробнее
                            </el-dropdown-item>
                            <el-dropdown-item
                                :disabled="!scope.row.confirm && ((scope.row.type === 'mix' || scope.row.type === 'good') && checkConfirmMixOrder(scope.row.mix_order_id))"
                                @click="removeService(scope.row.id)"
                                :icon="icons.delete"
                            >Удалить
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-if="paginate"
        v-model="pageSize"
        :total="valueLength"
        :disable-size="true"
        @action="setPage"
    />

    <div class="w-100 mt-15 mb-15">
        <div class="textSM textRegular neutral300 mb-10">
            Итого (все): {{ formatNumber(sumAll) }} руб.
        </div>
        <div class="textSM textMedium neutral900">
            Итого (подтвержденные): <span class="primary600">{{ formatNumber(sumConfirm) }} руб.</span>
        </div>
    </div>

    <el-dialog
        v-model="addServiceDialog"
        title="Добавить услугу"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <MixOrderGroupCreateService
            :group-id="groupId ?? $route.params.groupId"
            :orders="orders"
            @update="updateOrder()"
        />
    </el-dialog>


    <el-dialog
        v-model="addGoodDialog"
        title="Добавить продукцию"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <MixOrderGroupCreateService
            :group-id="groupId ?? $route.params.groupId"
            :orders="orders"
            :service="selectService"
            @update="updateOrder()"
        />
    </el-dialog>


    <el-drawer
        title=""
        v-model="orderDetailsDrawer"
        direction="rtl"
        :size="!mobile ? '60%' : '100%'"
        :destroy-on-close="true">

        <MixPageOrderDetails
            :order-id="selectOrderId"
        />

    </el-drawer>

    <el-dialog
        v-model="editServiceDialog"
        title="Редактировать услугу"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <MixOrderGroupCreateService
            :group-id="groupId ?? $route.params.groupId"
            :orders="orders"
            :service="selectService"
            @update="updateOrder()"
        />
    </el-dialog>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import serviceInfo from "@/mixins/serviceInfo";
import moment from "moment";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import MixPageOrderDetails from "@/views/components/MixPage/Orders/OrderDetails/MixPageOrderDetails.vue";
import MixOrderGroupCreateService from "@/views/components/MixOrderGroup/MixOrderGroupCreateService.vue";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";

export default {
    name: "OrderServices",
    computed: {
        moment() {
            return moment
        },
        servicesData() {
            let data = this.services
            return data.sort((a, b) => {
                if (a.confirm === b.confirm) {
                    return 0;
                } else if (a.confirm) {
                    return -1;
                } else {
                    return 1;
                }
            })
        },
        sumConfirm() {
            return this.services
                .filter((service) => service.confirm)
                .reduce((total, service) => total + Math.abs(service.full_price), 0)
        },
        sumAll() {
            return this.services
                .reduce((total, service) => total + Math.abs(service.full_price), 0)
        }
    },
    components: {TablePagination, MixOrderGroupCreateService, MixPageOrderDetails},
    mixins: [mobileCheckMixin, serviceInfo, functionsMixin, iconsMixin, tableMixin],
    props: ['loading', 'services', 'orders', 'groupId', 'goodsType', 'paginate'],
    inject: ['api'],
    data() {
        return {
            loadingButton: false,
            orderDetailsDrawer: false,
            selectOrderId: null,
            selectService: null,
            addServiceDialog: false,
            editServiceDialog: false,
            addGoodDialog: false,
            pageSize: 5
        }
    },
    methods: {
        openOrderDetails(id) {
            this.selectOrderId = id
            this.orderDetailsDrawer = true
        },
        editOrderDetails(service) {
            this.selectOrderId = service.id
            this.selectService = service
            this.editServiceDialog = true
        },
        removeService(id) {
            this.$confirm(
                'Вы действительно хотите удалить услугу?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loadingButton = true

                    httpService().post(this.api.mixOrderGroup.removeService, {
                        account_modules_id: this.$route.params.id,
                        module_key: this.$route.meta.moduleKey,
                        orderId: this.groupId ?? this.$route.params.groupId,
                        serviceId: id
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Услуга удалена',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('update')
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loadingButton = false
            })
        },
        confirmService(id, confirm) {
            let message = 'Вы действительно хотите подтвердить услугу?'
            if (!confirm) message = 'Вы действительно хотите снять подтверждение с услуги?'
            this.$confirm(
                message,
                'Подтвердите действие',
                {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loadingButton = true

                    httpService().post(this.api.mixOrderGroup.confirmService, {
                        account_modules_id: this.$route.params.id,
                        module_key: this.$route.meta.moduleKey,
                        orderId: this.groupId ?? this.$route.params.groupId,
                        serviceId: id,
                        confirm: confirm
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Сохранено',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('update')
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loadingButton = false
            })
        },
        updateOrder() {
            this.addServiceDialog = false
            this.editServiceDialog = false
            this.addGoodDialog = false
            this.selectOrderId = null
            this.selectService = null
            this.$emit('update')
        },
        getOrderData(id, data) {
            let order = this.orders.find(el => el.id === id)

            if (order) {
                if (data === 'vehicle') {
                    return order.vehicle?.number
                } else if (data === 'time') {
                    return moment(order.start_at).format('HH:mm')
                }
            }

            return null
        },
        checkConfirmMixOrder(id) {
            let order = this.orders.find(el => el.id === id)
            if (order) {
                if (!order.confirm) {
                    return true
                }
            }

            return false
        },
        openAddServiceDialog() {
            this.addServiceDialog = true
        },
        filterValues() {
            if (this.paginate) {
                this.filtered = this.servicesData

                this.valueLength = this.filtered.length
                return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
            }
        },
        openAddGoodDialog() {
            this.selectService = {
                id: null,
                name: null,
                type: 'good',
                depends_on: null,
                vat: 0,
                vat_in_price: false,
                price: null,
                full_price: null,
                price_type: null,
                volume_min: null,
                volume_max: null,
                distance_from: null,
                distance_to: null,
                manual: true,
                tariff_id: null,
                good_id: null
            }
            this.addGoodDialog = true
        }
    },

}
</script>

<style scoped>

</style>
