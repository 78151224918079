<template>
    <PageH1 title="Периодические работы"/>

    <TableHeadBlock
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-search="true"
        @moduleGet="getTechPeriodicityWorks()"
        @openFilterBlock="openFilterBlock"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
        :filter-items1="workStatuses"
    />

    <el-table
        :data="displayData"
        class="mt-15"
        v-loading="loading">
        <el-table-column
            prop="name"
            label="Название"
        >
            <template v-slot="scope">
                <div class="double">
                    <p>{{ scope.row.name }}</p>
                    <span v-if="scope.row.work_type === 1">Осмотр</span>
                    <span v-if="scope.row.work_type === 2">Обслуживание</span>
                    <span v-if="scope.row.work_type === 3">Ремонт</span>
                </div>
            </template>
        </el-table-column>

        <el-table-column
            prop="periodicity"
            label="Правила периодичности"
        >
            <template v-slot="scope">
                <div class="double">

                    <template v-if="scope.row.periodicity_settings.type === 'mileage'">
                        Каждые {{ formatNumber(scope.row.periodicity_settings.mileage_count_period) }} {{ unit }}
                    </template>

                    <template v-if="scope.row.periodicity_settings.type === 'calendar'">

                        <template v-if="scope.row.periodicity_settings.calendar_type === 'week'">
                            Еженедельно в <span
                            v-for="(day, index) in JSON.parse(scope.row.periodicity_settings.calendar_week_days)"
                            :key="index">{{ getInfo(day, weekDays, 'shortName') }}<span
                            v-if="index !== JSON.parse(scope.row.periodicity_settings.calendar_week_days).length - 1">, </span></span>
                        </template>

                        <template v-if="scope.row.periodicity_settings.calendar_type === 'month'">
                            Ежемесячно <span
                            v-for="(day, index) in JSON.parse(scope.row.periodicity_settings.calendar_month_days).sort((a, b) => a - b)"
                            :key="index">{{ day }}<span
                            v-if="index !== JSON.parse(scope.row.periodicity_settings.calendar_month_days).length - 1">, </span></span>
                            числа
                        </template>

                        <template v-if="scope.row.periodicity_settings.calendar_type === 'year'">
                            Ежегодно {{ getYearDay(scope.row.periodicity_settings.calendar_year_day) }}
                        </template>

                    </template>

                </div>
            </template>
        </el-table-column>

        <el-table-column
            property="periodicity_settings.active"
            label="Активно"
            width="120"
        >
            <template v-slot="scope">
                <el-switch
                    v-model="scope.row.periodicity_settings.active"
                    @change="changeStatusWork(scope.row.id)"
                    active-color="#00cdc4"
                    inactive-color="#ff5253">
                </el-switch>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="125">
            <template #default>
                <el-button size="mini"
                           @click="notAvaliableFunction()">
                    Настроить
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

</template>

<script>
import PageH1 from '@/views/components/PageH1'
import {httpService} from '@/services/http.service'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock";
import Filter from "@/views/components/Table/Filter/Filter";
import moment from "moment";
import TablePagination from "@/views/components/Table/TablePagination";

export default {
    name: 'TechDetailsPeriodicityWorks',
    components: {TablePagination, Filter, TableHeadBlock, PageH1},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [tableMixin, functionsMixin],
    data() {
        return {
            loading: false,
            unit: 'мтч',
            weekDays: [{
                id: 1,
                name: 'Понедельник',
                shortName: 'пн'
            }, {
                id: 2,
                name: 'Вторник',
                shortName: 'вт'
            }, {
                id: 3,
                name: 'Среда',
                shortName: 'ср'
            }, {
                id: 4,
                name: 'Четверг',
                shortName: 'чт'
            }, {
                id: 5,
                name: 'Пятница',
                shortName: 'пт'
            }, {
                id: 6,
                name: 'Суббота',
                shortName: 'сб'
            }, {
                id: 7,
                name: 'Воскресенье',
                shortName: 'вс'
            }],
        }
    },
    created() {
        this.getTechPeriodicityWorks()
    },
    methods: {
        getTechPeriodicityWorks() {
            this.loading = true
            httpService().post(this.api.tech.getTechPeriodicityWorks, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.values
                    this.unit = data.unit
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        notAvaliableFunction() {
            this.$message({
                message: 'Функция в разработке',
                showClose: true,
                type: 'info'
            })
        },
        getYearDay(day) {
            return moment(day).format('DD.MM')
        },
        changeStatusWork(workId) {
            httpService().post(this.api.techVehicle.changeStatusPeriodicityWork, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                workId: workId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.getTechPeriodicityWorks()
                    this.$message({
                        message: 'Статус изменен',
                        showClose: true,
                        type: 'success'
                    })
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.getTechPeriodicityWorks()
            })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
