<template>
    <el-dropdown ref="selector" v-if="options" :popper-class="'dropdown-selector-popper'" trigger="click">
        <span class="dropdown-selector">
            <div class="selected-label">{{ options?.[itemValue]?.label ?? 'Select item' }}</div>
            <div class="selected-icon"><ArrowDown /></div>
        </span>
        <template #dropdown >
            <div class="dropdown-selector-menu">
                <div v-for="(option, key) in options" :key="key" @click.prevent="selectItem(option, key)" class="dropdown-selector-menu-item">
                    {{ option.label }}
                </div>
            </div>
        </template>
    </el-dropdown>
</template>

<script>
import {ArrowDown} from "@element-plus/icons-vue";

export default {
    name: "DropdownSelector",
    components: {ArrowDown},
    props: {
        options: {
            type: [Array, Object],
            default: null
        },
        label: {
            type: String,
            default: 'label'
        },
        value: {
            type: String,
            default: 'value'
        },
        modelValue: {
            type: [String, Number],
            default: null
        }
    },
    data() {
        return {
            itemValue: this.modelValue,
            item: null,
            selectKey: null
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        selectItem(item, key) {
            this.selectKey = key;
            this.item = item;

            this.$emit('update:modelValue', key)

            this.$refs.selector.handleClose();
        },
    }
}
</script>

<style>

</style>
