<template>
    <el-tooltip placement="bottom" effect="light" :disabled="!orders.length" :show-after="100">
        <template #content>
            <div style="width: 380px">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th>
                            <div class="cell textXS textRegular neutral200 pb-15 pl-5">
                                Заказы
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Кол-во
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Объем
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15 pr-5">
                                Отгрузки
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(status, index) in statuses" :key="index" class="hover-row">
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 pl-5">
                                <span :style="{ 'background-color': status.color }" class="status-dot"></span>
                                {{ status.name }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5">
                                {{ status.count }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium success400 pt-2-5 pb-2-5">
                                {{ formatNumber(status.value, 2) }} м³
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium warning300 pt-2-5 pb-2-5 pr-5">
                                {{ status.totalMixOrders }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                Итого
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ statuses.reduce((acc, status) => acc + status.count, 0) }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ formatNumber(allTotal, 2) }} м³
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ statuses.reduce((acc, status) => acc + status.totalMixOrders, 0) }}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <div class='d-flex content-center pl-10 pr-10 summary-order-groups-container'>
            <div :style="`min-width: ${mobile ? 'max-content' : '130px'}`">
                <div class="order-groups">
                    <img :src="require('@/assets/open_box.svg')" class="order-groups__icon"/>
                    <div class="order-groups__info">
                        <div class="order-groups__info__title">
                            <div class="d-flex">
                                <AnimateNumber :count="ordersCount"/>
                                <span class="ml-5">{{
                                        declension(ordersCount ?? 0, ['заказ', 'заказа', 'заказов'])
                                    }}</span>
                            </div>
                        </div>
                        <div class="order-groups__info__desc">
                            Всего
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'opacity-0-1': ordersCount === 0 }" :style="{ opacity: ordersCount > 0 ? 1 : 0.2 }"
                 class="transition-opacity duration-500">
                <SummaryOrdersCountPie :statuses="statuses"/>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import SummaryOrdersCountPie from "@/views/dispatch/components/summaryBlocks/SummaryOrdersCountPie.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: "SummaryOrdersCount",
    components: {SummaryOrdersCountPie, AnimateNumber},
    mixins: [functionsMixin, mobileCheckMixin],
    props: {
        orders: Array
    },
    computed: {
        ordersCount() {
            return this.orders.length
        },
        allTotal () {
            return this.statuses.reduce((acc, status) => acc + status.value, 0)
        },
        statuses() {
            const statusMap = {
                confirmed: { name: 'Подтвержденный', color: '#66b5fe' },
                work: { name: 'В работе', color: '#1984e6' },
                done: { name: 'Выполнен', color: '#00d0a3' },
                completed: { name: 'Завершен', color: '#009272' },
                pause: { name: 'На паузе', color: '#ecaf02' },
                failed: { name: 'Отменен', color: '#f74539' },
            };

            return Object.keys(statusMap).reduce((statuses, status) => {
                const filteredOrders = this.orders.filter(order => order.status === status);
                const count = filteredOrders.length;
                const total = filteredOrders.reduce((total, order) => total + order.total, 0);
                const totalMixOrders = filteredOrders.reduce((total, order) => total + order.orders.length, 0);

                if (count > 0) {
                    statuses.push({
                        name: statusMap[status].name,
                        value: total,
                        totalMixOrders: totalMixOrders,
                        color: statusMap[status].color,
                        count: count,
                        itemStyle: { color: statusMap[status].color }
                    });
                }
                return statuses;
            }, []);
        }
    },
    methods: {
        declension(number, words) {
            number = Math.abs(number) % 100;
            const n1 = number % 10;
            if (number > 10 && number < 20) {
                return words[2];
            }
            if (n1 > 1 && n1 < 5) {
                return words[1];
            }
            if (n1 == 1) {
                return words[0];
            }
            return words[2];
        }
    }
}
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
</style>
