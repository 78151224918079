<script>
export default {
    name: "DispatchIcon"
}
</script>

<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 16V6.5C19 5.4 18.1 4.5 17 4.5H12.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 2L12 4.5L15 7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 9V16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.25 8.5C7.04493 8.5 8.5 7.04493 8.5 5.25C8.5 3.45507 7.04493 2 5.25 2C3.45507 2 2 3.45507 2 5.25C2 7.04493 3.45507 8.5 5.25 8.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 22C6.65685 22 8 20.6569 8 19C8 17.3431 6.65685 16 5 16C3.34315 16 2 17.3431 2 19C2 20.6569 3.34315 22 5 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 22C20.6569 22 22 20.6569 22 19C22 17.3431 20.6569 16 19 16C17.3431 16 16 17.3431 16 19C16 20.6569 17.3431 22 19 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

</template>

<style scoped>

</style>
