<template>
    <div>
        <template v-if="loadingData">
            <TableDataLoader text="адресов"/>
        </template>
        <template v-else>
            <template v-if="!companyCreateFormShow">
                <el-row :gutter="15">
                    <el-col :sm="16">
                        <TableHeadBlock
                            v-model="search"
                            :loading="loading"
                            :disable-filter="true"
                            :disable-update-button="true"
                            create-button-label="Добавить адрес"
                            @moduleGet="getData()"
                            @openCreateValueDrawer="() => companyCreateFormShow = true"
                        />
                        <el-table
                            empty-text="Информация не найдена"
                            class="mt-15 w-100 defaultTableHeight"
                            v-loading="loadingTable"
                            @current-change="handleCurrentChange"
                            @cellMouseEnter="hoverAddress"
                            :data="displayData">

                            <el-table-column
                                prop="id"
                                label="#"
                                width="100"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Название"
                                class-name="pointer"
                                sortable>
                                <template v-slot="scope">
                                    <span class="textSM textMedium neutral900">{{
                                            scope.row.name ?? '-'
                                        }}</span>
                                </template>
                            </el-table-column>
                        </el-table>

                        <TablePagination
                            v-model="pageSize"
                            :total="valueLength"
                            :loading="loading"
                            @action="setPage"
                        />
                    </el-col>
                    <el-col :sm="8">
                        <div class="logisticsInfoMainMap">
                            <div class="map">
                                <YandexMap
                                    :drag-off="true"
                                    :coords="coords"
                                />
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-else>
                <el-form label-position="top">
                    <AddressDeliveryModalCreate
                        :company-id="companyId"
                        :company-name="companyName"
                        @onAdd="handleAddAddress"
                    />
                </el-form>
            </template>
        </template>
    </div>
</template>

<script>
import { httpService } from "@/services/http.service";

import {useCommonStore} from "@/store/common";
import {mapState} from "pinia";

import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import AddressDeliveryModalCreate from "@/views/components/AddressDeliveryModalCreate";
import YandexMap from '@/views/widgets/map/YandexMap';
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";


export default {
    name: 'SelectAddressModal',
    props: ['companyId', 'companyName', 'addedAddresses', 'modelValue'],
    components: {
        TableDataLoader, TableHeadBlock, TablePagination, YandexMap, AddressDeliveryModalCreate
    },
    mixins: [tableMixin, functionsMixin],
    inject: ['api'],
    data() {
        return {
            loadingData: false,
            companyCreateFormShow: false,
            search: '',
            values: [],
            loading: false,
            loadingTable: false,
            pageSize: 10,
            page: 1,
            itemId: null,
            longitude: null,
            latitude: null,
        };
    },
    methods: {
        getData() {
            this.loadingTable = true
            httpService().post(this.api.company.getCompanyAddresses, {
                companyId: this.companyId,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                full: true
            }).then((response) => {

                let data = response.data

                if (data.success) {
                    this.loadingData = false
                    this.loadingTable = false
                }

                if(!this.addedAddresses.length) {
                        this.values = data.items
                        this.valueLength = data.items.length
                        return
                    }

                // исключаем выбранные варинты
                const filtered = data.items.filter(item =>
                    !this.addedAddresses.some(address => item.id === address.id)
                );
                this.values = filtered
                this.valueLength = filtered.length
            })
        },
        handleCurrentChange(val) {
            this.itemId = val.id;
            this.$emit('add-new-row', val)
        },
        filterValues() {
            if (this.search) {
                this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            }

            this.filtered = this.values;
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleAddAddress(output) {
            this.companyCreateFormShow = false;
            const newRow = output.values.find(item => item.id === output.addressId)
            this.$emit('add-new-row', newRow)
        },
        hoverAddress(e) {
            if (e.latitude && e.longitude) {
                this.latitude = e.latitude
                this.longitude = e.longitude
            }
        },
    },
    computed: {
        ...mapState(useCommonStore, {
            modules: 'getModules'
        }),
        coords() {
                if (!this.latitude || !this.longitude) {
                    if (this.modules.length > 0) {
                        let key = this.$route.meta.moduleKey
                        let id = this.$route.params.id
                        let module = this.modules.find((m) => m.id == id)

                        if (key === 'dispatch' || key === 'crm') {
                            module = this.modules.find((m) => m.accountModuleId == id)
                        }

                        if (module) {
                            if (module.factory) {
                                return [parseFloat(module.factory.latitude), parseFloat(module.factory.longitude)];
                            }
                        }
                    }
                    return [55.75165053565251, 37.61758420869977];
                }

                return [parseFloat(this.latitude), parseFloat(this.longitude)];
        },
        getAddressName() {
            if (this.itemId) {
                return this.getInfo(this.itemId, this.values)
            } else {
                return null
            }
        },
    },
    watch: {
        modelValue: {
            handler() {
                this.companyCreateFormShow = this.modelValue
            }
        },
        pageSize() {
            this.getData()
        },
        page() {
            this.getData()
        },
        search() {
            this.getData()
        },
    },
    mounted() {
        this.getData()
    }
};
</script>
