<template>
    <div class="topCard cashboxInfoBalance">
        <h3>{{ textTop }}</h3>

        <div class="cashboxInfoBalanceBody">
            <h2><animate-number :count="total"/> руб.</h2>
            <p>{{ text }}</p>
        </div>

        <div class="cashboxInfoBalanceControls">
            <el-button @click="prev" :icon="icons.arrowLeft"/>
            <el-button @click="next" :icon="icons.arrowRight"/>
        </div>
    </div>
</template>

<script>
import AnimateNumber from '@/views/components/AnimateNumber'
import iconsMixin from '@/mixins/iconsMixin'
export default {
    name: 'CashboxInfoBalance',
    components: {AnimateNumber},
    props: ['accounts', 'totalAccounts', 'modelValue'],
    mixins: [iconsMixin],
    data () {
        return {
            total: this.totalAccounts,
            text: 'Всего денежных средств на счетах',
            textTop: 'Общая сводка по всем счетам',
            allAccounts: [{
                id: 'all',
                total: this.totalAccounts,
                name: null
            }],
            active: 0,
        }
    },
    created() {
        this.allAccounts = this.allAccounts.concat(this.accounts)
        this.changeInfo()
    },
    methods: {
        next() {
            let length = this.allAccounts.length - 1

            if (this.active < length) {
                this.active++
            } else if (this.active === length) {
                this.active = 0
            }

            this.changeInfo()
        },
        prev() {
            let length = this.allAccounts.length - 1

            if (this.active > 0) {
                this.active--
            } else if (this.active === 0) {
                this.active = length
            }

            this.changeInfo()
        },
        changeInfo() {
            let account = this.allAccounts[this.active]

            this.total = account.total

            if (account.id === 'all') {
                this.text = 'Всего денежных средств на счетах'
                this.textTop = 'Общая сводка по всем счетам'
                this.$emit('update:modelValue', null)
            } else if (account.id === null) {
                this.text = 'Остаток средств на счету по умолчанию'
                this.textTop = 'Сводка по виртуальном счету'
                this.$emit('update:modelValue', 'default')
            } else {
                this.text = 'Остаток средств на счете "' + account.name + '"'
                this.textTop = 'Сводка по счету "' + account.name + '"'
                this.$emit('update:modelValue', account.id)
            }
        }
    },
    watch: {
        'accounts': {
            handler: function () {
                this.allAccounts = [{
                    id: 'all',
                    total: this.totalAccounts,
                    name: null
                }]
                this.allAccounts = this.allAccounts.concat(this.accounts)
                this.changeInfo()
            },
            immediate: false
        },
        'totalAccounts': {
            handler: function () {
                this.allAccounts = [{
                    id: 'all',
                    total: this.totalAccounts,
                    name: null
                }]
                this.allAccounts = this.allAccounts.concat(this.accounts)
                this.changeInfo()
            },
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) this.active = 0
        }
    }
}
</script>

<style scoped>

</style>
