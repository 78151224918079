<template>

    <div class="logisticsInfoMain">
        <div class="logisticsInfoMainBlock">

            <div class="logisticsInfoMainMap">
                <LogisticsInfoMap
                    :key="coor1"
                    :coor1="coor1"
                    :coor2="coor2"
                    :title="title.toString()"
                />
            </div>

            <div class="logisticsInfoMainItems">
                <div class="logisticsItem"
                     v-bind:class="{ active: activeItem === index }"
                     @click="change(item, index)"
                     v-for="(item, index) in items"
                     :key="index">
                    <div class="logisticsItemBody">
                        <TechImage :image="item.image ?? null"/>

                        <div class="logisticsItemInfo">
                            <div class="logisticsItemHead">
                                <h2>#{{ index + 1 }} {{ item.name }}</h2>
                                <span
                                    class="logisticsItemHeadStatus"
                                    @click="$emit('openChangeStatus')">
        <svg width="6" height="6" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" :fill="item.status === 'active' ? '#00cdc4' : '#ff5253'"/>
        </svg>
        <span class="logisticsItemHeadStatusText">{{ item.status === 'active' ? 'В пути' : 'Не работает' }}</span>
    </span>
                            </div>
                            <div class="logisticsItemDetails">
                            <span>
                                {{ item.number }}
                            </span>
                                <span>
                                {{ item.id }} проблем
                            </span>
                                <span>
                                Штрафов нет
                            </span>
                            </div>
                        </div>
                    </div>

                    <div class="logisticsItemActiveBody" v-if="item.status === 'active'">
                        <hr/>

                        <div class="logisticsItemActiveBodyInfo">
                            <div>
                                <span>Водитель</span>
                                <p>Семенов И.А.</p>
                            </div>
                            <div>
                                <span>Тек. скорость</span>
                                <p><AnimateNumber :count="item.id * 9"/> км/ч</p>
                            </div>
                            <div>
                                <span>Время в пути</span>
                                <p>2 ч 45 мин</p>
                            </div>
                            <div>
                                <span>Расстояние</span>
                                <p><AnimateNumber :count="item.id * 15"/> км</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import LogisticsInfoMap from "@/views/components/LogisticsPage/Info/LogisticsInfoMap";
import TechImage from "@/views/components/TechPage/Garage/TechDetails/TechImage";
import AnimateNumber from "@/views/components/AnimateNumber";

export default {
    name: "LogisticsInfo",
    components: {AnimateNumber, TechImage, LogisticsInfoMap},
    data() {
        return {
            activeItem: 0,
            coor1: 0,
            coor2: 0,
            title: 1,
            items: [
                {
                    "id": 9,
                    "coor1": 48.969796,
                    "coor2": 55.836907,
                    "vehicleId": 745,
                    "name": "Scania",
                    "status": "active",
                    "fuel_total": "54",
                    "fuel_tank": "400",
                    "metric": "hours",
                    "mileage": "56900",
                    "mileage_start": "0",
                    "number": "С744ЕЕ790",
                    "vin": null,
                    "ctc": null,
                    "model": "Scania",
                    "image": "https://scanauto.ru/images/cms/thumbs/49022ef7c2624e6430daf798ae815122393adafd/Betonosmesitel-Scania-P380-B8X4NZ-XT-betonomeshalka-1_800_533_5_80.jpg"
                },
                {
                    "id": 6,
                    "coor1": 39.856687,
                    "coor2": 57.586427,
                    "vehicleId": 4557,
                    "name": "MAN",
                    "status": "not-active",
                    "fuel_total": "215",
                    "fuel_tank": "200",
                    "metric": "hours",
                    "mileage": "7580",
                    "mileage_start": "0",
                    "number": "Н142ЕЕ797",
                    "vin": null,
                    "ctc": null,
                    "model": "MAN",
                    "image": "https://upload.wikimedia.org/wikipedia/commons/1/11/Gruszka2_poznan.jpg"
                },
                {
                    "id": 7,
                    "coor1": 34.393900,
                    "coor2": 61.784243,
                    "vehicleId": 4555,
                    "name": "MAN",
                    "status": "not-active",
                    "fuel_total": "215",
                    "fuel_tank": "200",
                    "metric": "hours",
                    "mileage": "7580",
                    "mileage_start": "0",
                    "number": "T105HЕ797",
                    "vin": null,
                    "ctc": null,
                    "model": "MAN",
                    "image": "https://www.gruzovik.com/img/Stroitelnaya_tehnika_Avtobetonosmesitel_MAN_TGA_41_400-xxl-870/870_4559391424512.jpg"
                },
                {
                    "id": 8,
                    "coor1": 37.442822,
                    "coor2": 55.883567,
                    "vehicleId": 4555,
                    "name": "MAN",
                    "status": "not-active",
                    "fuel_total": "215",
                    "fuel_tank": "200",
                    "metric": "hours",
                    "mileage": "7580",
                    "mileage_start": "0",
                    "number": "М835ТР790",
                    "vin": null,
                    "ctc": null,
                    "model": "MAN",
                    "image": "https://urts.ru/wp-content/uploads/2016/12/MAN-TGS-41.400-6X4-B%D0%92-WW1.jpg"
                },
            ]
        }
    },
    created() {
        if (this.items.length > 0) {
            this.coor1 = this.items[0].coor1
            this.coor2 = this.items[0].coor2
        }
    },
    methods: {
        change (item, index) {
            this.activeItem = index
            this.coor1 = item.coor1
            this.coor2 = item.coor2
            this.title = index + 1
        }
    }
}
</script>

<style scoped>

</style>
