<template>
    <svg viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.631 25.1628L30.9606 22.5171L34.79 18.7222L35.6136 17.906L34.7906 17.0892L34.1266 16.4301L37.522 13.0649L39.0621 14.591L39.0622 14.5911L41.3658 16.8748L41.3663 16.8753L41.6804 17.1863L33.631 25.1628ZM27.1426 9.50814L26.3332 8.70645L25.5239 9.50828L21.6787 13.3181L19.0915 10.7541L27.1416 2.77558L28.259 3.88226L28.2596 3.88281L30.5614 6.16563L30.5618 6.16604L31.218 6.81613L27.8228 10.1819L27.1426 9.50814ZM13.3046 21.6177L9.68925 25.2027L8.85918 26.0258L9.69578 26.8423L9.80494 26.9488L14.4641 31.568L14.4641 31.5681L17.3361 34.4155L18.1456 35.2181L18.9553 34.4158L19.0766 34.2956L19.0767 34.2954L20.5834 32.8018L20.5835 32.8017L22.5865 30.8167L25.2569 33.4633L17.4425 41.2069L14.0721 37.8677L13.2532 37.0563L13.2322 37.0776L2.88148 26.8193L10.7174 19.0533L13.3046 21.6177Z" stroke-width="2.3"/>
        <path d="M21.0035 42.9665L29.6356 34.4097L30.4596 33.5929L29.6355 32.7762L23.0405 26.2402L26.4552 22.8548L33.0647 29.4052L33.8742 30.2075L34.6838 29.4052L43.2886 20.8763V26.9207L27.0998 42.9665H21.0035ZM17.3237 1.15H23.4204L14.815 9.68164L13.9912 10.4984L14.8151 11.3151L21.4105 17.8523L17.9943 21.2379L11.3847 14.6858L10.5751 13.8832L9.76547 14.6858L1.15 23.2266V17.1826L17.3237 1.15Z" stroke-width="2.3"/>
    </svg>
</template>

<script>
export default {
    name: "TTSIcon"
}
</script>

<style scoped>

</style>
