<template>
    <Auth title="Управление">
        <div class="card">
            <div class="card-body">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Пользователи" name="users">
                        <GodModeUsers/>
                    </el-tab-pane>
                    <el-tab-pane label="Функции" name="functions">
                        <GodModeFunctions/>
                    </el-tab-pane>
                    <el-tab-pane label="Смена владельца" name="chaneAccount">
                        <GodModeChangeAccountUser/>
                    </el-tab-pane>
                </el-tabs>


            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import GodModeChangeAccountUser from "@/views/components/GodModePage/GodModeChangeAccountUser";
import GodModeUsers from "@/views/components/GodModePage/GodModeUsers";
import GodModeFunctions from "@/views/components/GodModePage/GodModeFunctions.vue";

export default {
    name: 'GodModeIndex',
    data() {
        return {
            activeTab: 'users',
        }
    },
    components: {
        GodModeFunctions,
        GodModeUsers,
        GodModeChangeAccountUser,
        Auth
    },
}
</script>

<style scoped>

</style>
