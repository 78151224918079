<template>
    <div class="map-container">
        <ol-map :class="color ? '' : 'bw'" :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true" :style="height ? 'height:' + height + 'px' : 'height:530px'">

            <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" />

            <ol-tile-layer>
                <ol-source-osm />
            </ol-tile-layer>

            <ol-vector-layer>
                <ol-source-vector>
                    <ol-feature>
                        <ol-geom-point :coordinates="coordinate"></ol-geom-point>
                        <ol-style>
                            <ol-style-circle :radius="radius">
                                <ol-style-fill :color="fillColor"></ol-style-fill>
                                <ol-style-stroke :color="strokeColor" :width="strokeWidth"></ol-style-stroke>
                            </ol-style-circle>
                            <ol-style-text :text="title">
                                <ol-style-fill color="white"></ol-style-fill>
                            </ol-style-text>
                        </ol-style>
                    </ol-feature>

                </ol-source-vector>

            </ol-vector-layer>

        </ol-map>
    </div>
</template>

<script>
import {
    ref
} from 'vue'
import {fromLonLat} from "ol/proj";
export default {
    name: "LogisticsInfoMap",
    props: ['coorLatFrom', 'coorLonFrom', 'title', 'height', 'color'],
    data () {
        return {

        }
    },
    setup(props) {
        const center = ref(fromLonLat([props.coorLatFrom ?? 0, props.coorLonFrom ?? 0]))
        const projection = ref('EPSG:3857')
        const zoom = ref(13)
        const rotation = ref(0)
        const radius = ref(6)
        const strokeWidth = ref(5)
        const strokeColor = ref('#030d93')
        const fillColor = ref('#030d93')
        const coordinate = ref(fromLonLat([props.coorLatFrom ?? 0, props.coorLonFrom ?? 0]))
        const pixelRatio = ref(22)

        return {
            center,
            projection,
            zoom,
            rotation,
            radius,
            strokeWidth,
            strokeColor,
            fillColor,
            coordinate,
            pixelRatio
        }
    }
}
</script>

<style scoped>

</style>
