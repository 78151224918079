<template>
    <TableHeadBlock
        :new="true"
        v-model="search"
        :loading="loading"
        :disable-create-button="true"
        :disable-update-button="true"
        :disable-filter="true"
    />

    <el-table
        empty-text="Информация не найдена"
        class="mt-15 w-100 defaultTableHeight"
        @current-change="handleCurrentChange"
        :data="displayData">

        <el-table-column
            prop="id"
            label="#"
            class-name="pointer"
            width="100"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Название"
            class-name="pointer"
            sortable>
            <template v-slot="scope">
                <span class="textSM textMedium neutral900 cutOverflow">{{
                        scope.row.name ?? '-'
                    }}</span>
            </template>
        </el-table-column>

    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>

import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";

export default {
    name: "EditSelectDrivers",
    components: {TableHeadBlock, TablePagination},
    mixins: [tableMixin, functionsMixin],
    props: [
        'modelValue',
        'items'
    ],
    data() {
        return {
            itemValue: this.modelValue
        }
    },
    created() {
        this.values = this.items
    },
    methods: {
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            this.valueLength = this.filtered.length

            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            if (val) {
                this.selectItem(val.id)
            }
        },
        selectItem(id) {
            this.$emit('update:modelValue', id)
            this.$emit('close')
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
