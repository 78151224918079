<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <rect width="48" height="48" fill="url(#a)" rx="24"/>
        <path fill="#ECAF02" d="M28.19 14h-8.38C16.17 14 14 16.17 14 19.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C34 16.17 31.83 14 28.19 14Z" opacity=".4"/>
        <path fill="#ECAF02" d="M22.64 21.5c-.19 0-.38-.07-.53-.22-.71-.71-1.87-.71-2.58 0-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06c1.29-1.29 3.4-1.29 4.7 0 .29.29.29.77 0 1.06-.15.15-.34.22-.53.22Zm2.72 0c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06c1.29-1.29 3.4-1.29 4.7 0 .29.29.29.77 0 1.06-.29.29-.77.29-1.06 0-.71-.71-1.87-.71-2.58 0a.71.71 0 0 1-.53.22ZM24 23.92c-2.69 0-4.88 2.19-4.88 4.88 0 .7.57 1.28 1.27 1.28h7.2c.7 0 1.27-.57 1.27-1.28.02-2.69-2.17-4.88-4.86-4.88Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="48" y1="48" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7DE97"/>
                <stop offset="1" stop-color="#F7DE97" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "SadEmojiIcon"
}
</script>

<style scoped>

</style>
