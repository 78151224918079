import { useCommonStore } from '@/store/common';
import router from '..';

export default function auth({next}) {
    const commonStore = useCommonStore()

    if (commonStore.getToken === 'null' || commonStore.getToken === null) {
        router.push('/login');
        return;
    }

    if (commonStore.getUser.role_id === 1 && commonStore.getUser.setup !== 1) {
        return next({
            name: 'Setup'
        })
    }

    return next()
}
