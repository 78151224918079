<template>
    <Auth :title="name" :tabs="tabs">
        <div class="card">

            <div class="card-body">


                <div class="techPageDetails">

                    <el-row :gutter="20">

                        <el-col :sm="18" class="techPageDetailsMainCol">

                            <TechDetailsInfo
                                v-if="link === 'info'"
                                :vehicleId="vehicleId"
                                @serviceActive="setLink('service')"
                                @moduleGet="$emit('moduleGet')"
                            />

                            <TechDetailsFuel
                                v-if="link === 'fuel'"
                                :vehicleId="vehicleId"
                                @moduleGet="$emit('moduleGet')"
                            />

                            <TechDetailsService
                                v-if="link === 'service'"
                                :vehicleId="vehicleId"
                                @moduleGet="$emit('moduleGet')"
                            />

                            <TechDetailsPeriodicityWorks
                                v-if="link === 'periodicity'"
                                :vehicleId="vehicleId"
                                @moduleGet="$emit('moduleGet')"
                            />

                            <TechDetailsTires
                                v-if="link === 'tires'"
                                :vehicleId="vehicleId"
                                @moduleGet="$emit('moduleGet')"
                            />

                            <TechDetailsHistory
                                v-if="link === 'history'"
                                :vehicleId="vehicleId"
                                @moduleGet="$emit('moduleGet')"
                            />

                        </el-col>
                        <el-col :sm="1"></el-col>
                        <el-col :sm="5" class="techPageDetailsMenu">
                            <el-button @click="setLink('info')" v-bind:class="{ 'active': link === 'info' }" type="text">Общая информация</el-button>
                            <el-button v-show="false" @click="setLink('fuel')" v-bind:class="{ 'active': link === 'fuel' }" type="text">Заправки</el-button>
                            <el-button @click="setLink('service')" v-bind:class="{ 'active': link === 'service' }" type="text">Сервис</el-button>
                            <el-button @click="setLink('periodicity')" v-bind:class="{ 'active': link === 'periodicity' }" type="text">Период. работы (ТО)</el-button>
                            <el-button @click="setLink('tires')" v-bind:class="{ 'active': link === 'tires' }" type="text">Шины</el-button>
                            <el-button @click="setLink('history')" v-bind:class="{ 'active': link === 'history' }" type="text">События</el-button>
                        </el-col>
                    </el-row>
                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
// Страница одной единицы техники
import Auth from '@/views/layouts/Auth'
import TechDetailsInfo from "@/views/components/TechPage/Garage/TechDetails/TechDetailsInfo";
import TechDetailsFuel from "@/views/components/TechPage/Garage/TechDetails/TechDetailsFuel";
import TechDetailsService from "@/views/components/TechPage/Garage/TechDetails/TechDetailsService";
import iconsMixin from "@/mixins/iconsMixin";
import TechDetailsPeriodicityWorks from "@/views/components/TechPage/Garage/TechDetails/TechDetailsPeriodicityWorks";
import TechDetailsHistory from "@/views/components/TechPage/Garage/TechDetails/TechDetailsHistory";
import TechDetailsTires from "@/views/components/TechPage/Garage/TechDetails/TechDetailsTires.vue";
import techTabs from "@/mixins/techTabs";
import {httpService} from "@/services/http.service";

export default {
    name: 'TechGarageDetails',
    components: {
        TechDetailsTires,
        TechDetailsHistory,
        TechDetailsPeriodicityWorks, TechDetailsService, TechDetailsFuel, TechDetailsInfo, Auth},
    mixins: [iconsMixin, techTabs],
    inject: ['api'],
    data() {
        return {
            link: 'info',
            vehicleId: this.$route.params.vehicleId,
            name: 'Подробности',
            loading: false
        }
    },
    created() {
        this.getTechDetails()
    },
    methods: {
        setLink(value) {
            this.link = value;
        },
        getTechDetails() {
            this.loading = true
            httpService().post(this.api.tech.getTechDetails, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.$route.params.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.name = data.details.number ?? data.details.name
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
