<template>
    <div class="topCard">
        <h3>Объем произведенной продукции</h3>

        <div class="topCardScroll">
            <el-row class="stickyTop">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="text-500">
                    Продукт
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7" class="text-500">
                    Объем
                </el-col>
            </el-row>
            <el-row v-for="(good, index) in topGoods"
                    :key="index">
                <el-col :xl="19" :md="19" :sm="19" :xs="17" class="cutOverflow">
                    {{ good.id ? getData(good.id, 'good') : '-' }}
                </el-col>
                <el-col :xl="5" :md="5" :sm="5" :xs="7">
                    {{ good.id ? formatNumber(good.total) : '-' }}
                    <good-unit :unit="good.id ? getData(good.id, 'good', 'unit') : ''"/>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import GoodUnit from "@/views/components/goodUnit";

/**
 * Отображает топ товаров на сводке бетонного завода и весовой
 **/
export default {
    name: "InfoTopGoodsBlock",
    components: {GoodUnit},
    props: [
        'topGoods'
    ],
    mixins: [
        functionsMixin
    ]
}
</script>

<style scoped>
    .topCard {
        padding: 20px;
    }
</style>
