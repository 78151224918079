export default {
    data() {
        return {
            tabs: [{
                route: 'CRMOrders',
                name: 'Заказы'
            }, {
                route: 'CRMTasks',
                name: 'Задачи'
            }, {
                route: 'CRMContracts',
                name: 'Договора'
            }],
        }
    }
}
