<template>
    <Auth title="Заказы" :tabs="tabs">
        <MixOrderGroupUpdate/>
    </Auth>
</template>
<script>
import Auth from "@/views/layouts/Auth.vue";
import MixOrderGroupUpdate from "@/views/components/MixOrderGroup/MixOrderGroupUpdate.vue";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: "DispatchOrderGroupsUpdate",
    components: {MixOrderGroupUpdate, Auth},
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs, paymentMethods],
    inject: ['api'],
}
</script>
<style scoped lang="scss">
.selectedMixes {
    color: #393F4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.mix-action {
    align-items: center;
}

.mix-section {
    display: flex;
    justify-content: space-between;
}

.mix-load {
    color: #BD2626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: 14px;
}
</style>
