<template>
    <Auth title="Документы">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disableFilter="true"
                    create-button-label="Добавить документ"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="openDetailsDialog(null)">
                    <template v-slot:afterSearchAndFilter>
                        <FormSelectCompanies
                            @click="openCompaniesFilterDialog"
                            @clear="clearCompaniesFilter"
                            :companies="companiesCheckboxesActive" />
                        <el-select
                            v-model="filter.type.value"
                            :placeholder="filter.type.placeholder"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="filterByType"
                            clearable>
                            <el-option
                                    v-for="item in types"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"/>
                        </el-select>
                        <el-select
                            v-model="filter.status.value"
                            placeholder="Статус"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="moduleGet"
                            :disabled="!filter.type.value"
                            clearable>
                            <el-option
                                v-for="item in statuses[filter.type.value]"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"/>
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filter.integrations.value"
                            @moduleGet="moduleGet"
                            :table="true"
                        />
                        <ResetFormButton
                            :isVisible="isResetButtonActive"
                            @resetFilter="resetFilters" />
                    </template>
                    </TableHeadBlock>

                <MobileTableFramework v-for="(item, index) in values" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openDetailsDialog(item)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Название"
                        width="400"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral900">{{
                                    scope.row.short_name ? scope.row.short_name : scope.row.name
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="Тип"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span :class="'textMedium textSM ' + (scope.row.type ? 'primary600' : 'neutral400')">{{
                                    scope.row.type ? getInfo(scope.row.type, types) : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="140"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date ? getDateFormDB(scope.row.date, 'date') : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="company_id"
                        label="Контрагент"
                    >
                        <template v-slot="scope">
                            <span class="textRegular neutral300 cutOverflow">{{
                                    scope.row.company_id ? scope.row.company?.name : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="220px">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <div class="integrationLogos">
                                    <VesySoftLogo v-if="scope.row.integration_unit.integration.key === 'VesySoft'"/>
                                    <TTSSmartWeightLogo
                                        v-if="scope.row.integration_unit.integration.key === 'SmartWeight'"/>
                                    <TTSSmartMixLogo v-if="scope.row.integration_unit.integration.key === 'SmartMix'"/>
                                    <OneCBuhLogo v-if="scope.row.integration_unit.integration.key === '1C'"/>
                                    <KIPConceptLogo v-if="scope.row.integration_unit.integration.key === 'ConceptBCY'"/>
                                    <WialonLogo v-if="scope.row.integration_unit.integration.key === 'WialonMix'"/>
                                    <GlonassSoftIcon
                                        v-if="scope.row.integration_unit.integration.key === 'GlonassSoft'"/>
                                    <WhatsAppLogo v-if="scope.row.integration_unit.integration.key === 'Wappi'"/>
                                    <h3 v-if="scope.row.integration_unit.integration.key === 'Trekerserver'"
                                        style="text-transform:uppercase;">Trekerserver</h3>
                                </div>
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="180">
                        <template v-slot="scope">
                            <div>
                            <el-button
                                size="small"
                                @click="openDetailsDialog(scope.row)">Подробнее
                            </el-button>
                            <el-button
                                size="small"
                                @click="remove(scope.row.id)"
                                :icon="icons.delete"
                            />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
                <ModalWithScroll
                    @update-data="handleUpdate"
                    :companies-model="companiesCheckboxesActive"
                    v-model="companiesFilterDialog"
                />
            </div>
        </div>

        <el-dialog
            v-model="detailsDialog"
            :title="details.id ? 'Редактирование' : 'Создание'"
            :width="mobile ? '100%' : '60%'"
            :destroy-on-close="true"
        >
            <el-form label-position="top">
                <FormInput
                    v-model="details.name"
                    label="Название"
                    placeholder="Введите название"
                />
                <FormSelect
                    v-model="details.type"
                    label="Тип документа"
                    placeholder="Выберите тип"
                    :items="types"
                />
                <FormSelect
                    v-model="details.company_id"
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    new-item="company"
                    :new-item-data="details.company_id"
                    :items="[]"
                    size="large"
                />
                <el-button @click="create()" :loading="loadingButton" type="primary" size="large">Сохранить</el-button>
            </el-form>
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import KIPConceptLogo from "@/views/components/Icons/KIPConceptLogo.vue";
import WhatsAppLogo from "@/views/components/Logo/WhatsAppLogo.vue";
import OneCBuhLogo from "@/views/components/Logo/OneCBuhLogo.vue";
import GlonassSoftIcon from "@/views/components/Icons/GlonassSoftIcon.vue";
import TTSSmartWeightLogo from "@/views/components/Logo/TTSSmartWeightLogo.vue";
import VesySoftLogo from "@/views/components/Logo/VesySoftLogo.vue";
import TTSSmartMixLogo from "@/views/components/Logo/TTSSmartMixLogo.vue";
import WialonLogo from "@/views/components/Icons/WialonLogo.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import iconsMixin from "@/mixins/iconsMixin";
import {useCommonStore} from '@/store/common'
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import { mapState } from 'pinia'
import ModalWithScroll from "@/views/components/ModalWithScroll";
import ResetFormButton from '@/views/components/Form/ResetFormButton'
import FormSelectCompanies from '@/views/components/Form/FormSelectCompanies';

export default {
    name: 'DocumentsIndex',
    data() {
        return {
            loading: true,
            loadingButton: false,
            detailsDialog: false,
            details: {
                id: null,
                name: null,
                company_id: null,
                date: null,
                integration_unit_id: null,
                goods: [],
                type: null
            },
            filter: {
                companies: {
                    placeholder: 'Контрагент',
                    value: null,
                },
                type: {
                    placeholder: 'Тип документа',
                    value: null,

                },
                status: {
                    placeholder: 'Статус',
                    value: null,

                },
                integrations: {
                    placeholder: 'Интеграция',
                    value: null,
                },
            },
            types: [{
                'id': 'contract',
                'name': 'Договор'
            }, {
                'id': 'invoice',
                'name': 'Счет'
            }],
            statuses: {
                contract: [
                {
                    "id":"not-confirmed",
                    "name":"Не подтвержден"
                },
                {
                    "id":"confirmed",
                    "name":"Подтвержден"
                },
                {
                    "id":"done",
                    "name":"Завершен"
                },
                {
                    "id":"archived",
                    "name":"Архивный"
                }
            ],
            invoice: [
                 {
                    "id":"paid",
                    "name":"Оплачен"
                },
                {
                    "id":"not-paid",
                    "name":"Не оплачен"
                },
                {
                    "id":"partially-paid",
                    "name":"Оплачен частично"
                },
            ]
            },
            search: '',
            companiesFilterDialog: false,
            companiesCheckboxesActive: [], // все выбранные контрагенты
        }
    },
    components: {
        FormSelect,
        FormInput,
        FormSelectCompanies,
        WialonLogo,
        TTSSmartMixLogo,
        VesySoftLogo,
        TTSSmartWeightLogo,
        GlonassSoftIcon,
        OneCBuhLogo,
        WhatsAppLogo,
        KIPConceptLogo,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        ModalWithScroll,
        TableHeadBlock,
        Auth,
        TableIntegrationFilter,
        ResetFormButton
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, iconsMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.document.getAll, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                companies: this.filter.companies.value,
                status: this.filter.type.value === 'contract' ? this.filter.status.value : null,
                paymentStatus: this.filter.type.value === 'invoice' ? this.filter.status.value : null,
                type: this.filter.type.value,
                integrationUnitId: this.filter.integrations.value,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.values = data.items.data
                    this.valueLength = data.items.total
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        create() {
            this.loadingButton = true
            httpService().post(this.api.document.create, {
                details: this.details
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.detailsDialog = false
                    this.details = {
                        id: null,
                        name: null,
                        company_id: null,
                        integration_unit_id: null,
                        type: null
                    }
                    this.loadingButton = false
                    this.moduleGet()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm('Вы действительно удалить документ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.document.remove + '/' + id).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.$message({
                            message: 'Документ удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.moduleGet()
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
            })
        },
        openDetailsDialog(details) {
            if (details) {
                this.details = details
            } else {
                this.details = {
                    id: null,
                    name: null,
                    company_id: null,
                    integration_unit_id: null,
                    type: null
                }
            }
            this.detailsDialog = true
        },
        filterByType() {
            this.moduleGet();
            this.filter.status.value = null
        },
        clearCompaniesFilter() {
            this.companiesCheckboxesActive = [];
        },
        openCompaniesFilterDialog() {
            this.companiesFilterDialog = true;
        },
        handleUpdate(data) {
            this.companiesCheckboxesActive = data;
        },
        resetFilters() {
            this.filter.companies.value = null;
            this.filter.type.value = null;
            this.filter.status.value = null;
            this.filter.integrations.value = null;
            this.companiesFilterDialog = false;
            this.companiesCheckboxesActive = [];
            this.search = '';
            this.filter.status.value
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        }),
        isResetButtonActive() {
            return this.filter.companies.value.length
            || this.filter.type.value
            || this.filter.status.value
            || this.filter.status.value
            || this.filter.integrations.value
            || this.companiesCheckboxesActive.length
            || !!this.search
        }
    },
    watch: {
        companiesCheckboxesActive: {
            handler: function () {
                this.filter.companies.value = this.companiesCheckboxesActive.length ?
                this.companiesCheckboxesActive.map((el) => el.id) : []
                //tString = this.companiesCheckboxesActive.map((el) => el.name).join(", ");
                this.moduleGet();
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        }
    }
}
</script>

<style scoped>
.integrationLogos svg {
    height: 20px;
    position: relative;
    top: 3px;
}
</style>
