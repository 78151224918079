<template>
    <h2>Создание операций</h2>
    <p>Для создания операции укажите следующие данные:</p>
    <ul>
        <li>Наименование операции (обязательный пункт)</li>
        <li>Сумма (обязательный пункт, в рублях)</li>
        <li>Комментарий (необязательный пункт, пояснение в свободной форме)</li>
        <li>Тип операции (обязательный пункт)</li>
    </ul>
    <p>После ввода данных и нажатия на кнопку "Добавить операцию", в таблице операций появится
        новая строка, а баланс пересчитается в зависимости от выбранного типа операции.</p>

    <h2>Редактирование и удаление операций</h2>
    <el-row gutter="20">
        <el-col :sm="16">
            <p>Чтобы отредактировать или удалить операцию, для начала необходимо нажать на кнопку "Подробнее" в таблице
                операций.</p>
            <p>После нажатия появится панель, с подробностями операции, где вы можете увидеть тип операции, сумму, дату
                и комментарий. А также 2 кнопки справа внизу:</p>
            <ul>
                <li>Редатирование - синяя кнопка с карандашом</li>
                <li>Удаление - красная кнопка с корзиной</li>
            </ul>
            <p>При нажатии на кнопку "редактировать операцию" вы сможете внести изменения в операцию. Обращаем ваше
                внимание, что при изменении суммы и типа операции будет произведен пересчет баланса в модуле. Например,
                у вас была операция +100 рублей (доход), и вы изменили операцию на +40 рублей (доход). В этом случае от
                баланса отнимется 60 рублей.</p>
            <p>При нажатиии на кнопку "удалить операцию" система попросит вас подтвердить удаление. При удалении
                операции будет произведен пересчет баланса. Если вы удалите операцию -10 рублей (расход), то к балансу
                будет прибавлено 10 рублей. Удаленные операции восстановить невозможно.</p>
        </el-col>
        <el-col :sm="8">
            <el-image
                :src="require(`@/assets/faq/CashboxFaqHowCreateNewOperations-1.png`)"
                fit="contain"
                lazy
            ></el-image>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "CashboxFaqHowCreateNewOperations"
}
</script>

<style scoped>

</style>
