<template>
    <div v-loading="loading">
        <slot name="body"></slot>
    </div>
    <div>
        <slot name="footerOneButton" v-if="oneButton"></slot>
        <div class="footer" v-if="footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DrawerFramework",
    props: [
        'loading'
    ],
    data() {
        return {
            oneButton: this.$slots.footerOneButton,
            footer: this.$slots.footer
        }
    }
}
</script>

<style scoped>

</style>
