<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle :title="weighingInfo.operation ? weighingInfo.operation.name : 'Взвешивание'"/>

            <DrawerSubTitle
                v-if="weighingInfo.date"
                :sub-title="getDateFromUnix(weighingInfo.date)"/>

            <DrawerTextLineItem
                v-if="weighingInfo.tara"
                name="Тара"
                :desc="weighingInfo.tara + ' ' + (weighingInfo.good ? getData(weighingInfo.good, 'good','unit') : '')"
            />

            <DrawerTextLineItem
                v-if="weighingInfo.brutto"
                name="Брутто"
                :desc="weighingInfo.brutto + ' ' + (weighingInfo.good ? getData(weighingInfo.good, 'good','unit') : '')"
            />

            <DrawerTextLineItem
                v-if="weighingInfo.netto"
                name="Нетто"
                :desc="weighingInfo.netto + ' ' + (weighingInfo.good ? getData(weighingInfo.good, 'good','unit') : '')"
            />

            <p>Номер документа - {{ weighingInfo.doc_number ?? 'Не указан' }}</p>

            <el-collapse class="mt-30">

                <DrawerCollapseItem
                    v-if="weighingInfo.good"
                    :title="getData(weighingInfo.good, 'good')"
                    subtitle="Продукция"
                >

                    <template v-slot:body>

                        <p>Описание товара: {{
                                getData(weighingInfo.good, 'good', 'description') ?? 'отсутствует'
                            }}</p>

                        <DrawerTextLineItem name="Артикул"
                                            :desc="getData(weighingInfo.good, 'good','sku') ?? 'Не указан'"/>
                    </template>

                </DrawerCollapseItem>

                <DrawerCollapseItem
                    v-if="weighingInfo.recipient"
                    :title="getData(weighingInfo.recipient, 'company')"
                    subtitle="Получатель"
                >

                    <template v-slot:body>
                        <DrawerTextLineItem name="ИНН"
                                            :desc="getData(weighingInfo.recipient, 'company','inn') ?? 'Не указан'"/>
                        <DrawerTextLineItem name="КПП"
                                            :desc="getData(weighingInfo.recipient, 'company','kpp') ?? 'Не указан'"/>
                    </template>

                </DrawerCollapseItem>

                <DrawerCollapseItem
                    v-if="weighingInfo.sender"
                    :title="getData(weighingInfo.sender, 'company')"
                    subtitle="Отправитель"
                >

                    <template v-slot:body>
                        <DrawerTextLineItem name="ИНН"
                                            :desc="getData(weighingInfo.sender, 'company','inn') ?? 'Не указан'"/>
                        <DrawerTextLineItem name="КПП"
                                            :desc="getData(weighingInfo.sender, 'company','kpp') ?? 'Не указан'"/>
                    </template>

                </DrawerCollapseItem>

                <DrawerCollapseItem
                    v-if="weighingInfo.vehicle"
                    :title="weighingInfo.vehicle.name ?? 'Имя не указано'"
                    subtitle="Транспортное средство"
                >

                    <template v-slot:body>
                        <DrawerTextLineItem name="Номер автомобиля"
                                            :desc="weighingInfo.vehicle.number ?? 'Не указан'"/>
                    </template>

                </DrawerCollapseItem>

                <DrawerCollapseItem
                    v-if="weighingInfo.driver"
                    :title="weighingInfo.driver.name ?? 'Имя не указано'"
                    subtitle="Водитель"
                >

                    <template v-slot:body>
                        <DrawerTextLineItem name="Телефон"
                                            :desc="weighingInfo.driver.phone ?? 'Не указан'"/>
                    </template>

                </DrawerCollapseItem>

            </el-collapse>

        </template>
        <template v-slot:footer>
            <DrawerEditButton :loading="loading" @action="openEditForm"/>
            <DrawerDeleteButton :loading="loading" @action="deleteValue(weighingDetails.id)"/>
        </template>
    </DrawerFramework>

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import DrawerCollapseItem from '@/views/components/Drawer/DrawerCollapseItem'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import DrawerSubTitle from '@/views/components/Drawer/DrawerSubTitle'
import DrawerEditButton from '@/views/components/Drawer/Buttons/DrawerEditButton'
import { httpService } from '@/services/http.service'

export default {
    components: {
        DrawerEditButton,
        DrawerDeleteButton,
        DrawerCollapseItem, DrawerTextLineItem, DrawerSubTitle, DrawerHeadTitle, DrawerFramework
    },
    props: [
        'weighingDetails',
    ],
    name: 'WeighingDetails',
    data() {
        return {
            editValue: false,
            loading: true,
            weighingInfo: {
                tara: null,
                brutto: null,
                netto: null,
                operation: null,
                vehicle: null,
                driver: null,
                doc_number: null,
                sender: null,
                recipient: null,
                good: null,
                created_at: null,
                date: null,
            }
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        functionsMixin
    ],
    created() {
        this.getValue()
    },
    methods: {
        moduleGet() {

        },
        splitNumber: (num) => {
            let parts = num.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            return parts.join('.')
        },
        getValue() {
            httpService().post(this.api.weighingValue.getOne, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                valueId: this.weighingDetails.id
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.weighingInfo = data.value
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                // this.$router.push({name: 'logout'})
            })
        },
        deleteValue(id) {
            this.$confirm(
                'Вы действительно хотите удалить взвешивание?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.weighingValue.remove, {
                        id: id,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'weighing',
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.loading = true
                        this.$message({
                            message: 'Операция удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('closeDrawer')
                        this.$emit('moduleGet')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        openEditForm() {
            this.$emit('openEdit')
        }
    }
}
</script>

<style scoped>

</style>
