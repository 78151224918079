<template>
    <el-form-item :label="label"
                  v-bind:class="{ 'is-required': required }">
        <el-input
            v-model="itemValue"
            type="textarea"
            :rows="rows ?? 2"
            @input="$emit('update:modelValue', itemValue)"
            :placeholder="placeholder"
        />
    </el-form-item>
</template>

<script>

export default {
    name: "FormTextarea",
    props: [
        'modelValue',
        'label',
        'placeholder',
        'rows',
        'required'
    ],
    data () {
        return {
            itemValue: this.modelValue
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
