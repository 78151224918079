<template>

    <TableHeadBlock
        v-model="search"
        :loading="loadingButton"
        :disable-filter="true"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="drawerCreate=true"
        create-button-label="Создать счет"
    />

    <EmptyState
        :values="displayData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="счетов"
    />

    <div v-if="displayData.length > 0">

        <div v-if="displayData">

            <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                <template v-slot:body>

                    <MobileTableHR/>

                    <MobileTableInfo
                        title="Наименование"
                        :body="item.name ?? '-'"
                    />

                    <MobileTableInfo
                        title="Остаток"
                    >
                        <template v-slot:body>
                            <span :class="(item.total > 0 ? 'color-success' : 'color-error') + ' text-500'">{{ formatNumber(item.total, 2) }} руб.</span>
                        </template>
                    </MobileTableInfo>

                    <MobileTableInfo
                        title="Банк"
                        :no-border="true"
                    >
                        <template v-slot:body>
                            {{ item.bank ?? '-' }}
                        </template>
                    </MobileTableInfo>

                    <MobileTableButton
                        @action="openDrawerDetails(item)"
                    />

                </template>
            </MobileTableFramework>

        </div>

        <el-table
            v-if="!smallMobile"
            v-loading="loading"
            :data="displayData"
            :summary-method="getSummaries"
            show-summary
            class="mt-15"
            style="width: 100%">
            <el-table-column
                prop="name"
                label="Название"
                width="320px"
            />
            <el-table-column
                prop="value"
                label="Остаток"
                width="250px"
                sortable
            >
                <template v-slot="scope">
                    <span class="colored">{{ formatNumber(scope.row.total, 2) }} руб.</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="bank"
                label="Банк"
            >
                <template v-slot="scope">
                    {{ scope.row.bank ?? '-' }}
                </template>
            </el-table-column>
            <el-table-column
                label=""
                width="130px"
            >
                <template v-slot="scope">
                    <el-button
                        size="mini"
                        @click="openDrawerDetails(scope.row)">Подробнее
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>

    <el-drawer
        title=""
        v-model="drawerDetails"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">
        <CashboxAccountDetails
            :details="accountDetails"
            @moduleGet="moduleGet"
            @closeDrawer="drawerDetails=false"
            @doEdit="openEditDrawer"
        />
    </el-drawer>


    <el-drawer
        title=""
        v-model="drawerCreate"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <CashboxAccountCreate
            :details="accountDetails"
            @moduleGet="moduleGet"
            @closeDrawer="drawerCreate=false"
        />

    </el-drawer>

    <el-drawer
        title=""
        v-model="drawerEdit"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <CashboxAccountEdit
            :details="accountDetails"
            @moduleGet="moduleGet"
            @closeDrawer="drawerEdit=false"
        />

    </el-drawer>
</template>

<script>
import functionsMixin from '../../../mixins/functionsMixin'
import tableMixin from '../../../mixins/tableMixin'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import basicMixin from '../../../mixins/basicMixin'
import TableHeadBlock from '../Table/TableHeadBlock'
import TablePagination from '../Table/TablePagination'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState";
import {mapState} from "pinia/dist/pinia";
import {useCommonStore} from "@/store/common";
import CashboxAccountDetails from "@/views/components/CashboxPage/CashboxAccountDetails";
import CashboxAccountCreate from "@/views/components/CashboxPage/CashboxAccountCreate";
import CashboxAccountEdit from "@/views/components/CashboxPage/CashboxAccountEdit";
import MobileTableHR from "@/views/components/Mobile/Table/MobileTableHR";

export default {
    name: 'CashboxAccountsTab',
    data() {
        return {
            newOperationForm: {
                title: null,
                type: null,
                value: null,
                comment: null,
            },
            moduleId: null,
            drawerCreate: false,
            drawerDetails: false,
            drawerEdit: false,
            accountDetails: {
                id: null,
                bank: null,
                name: null,
                number: null,
                total: null
            },
            integrations: [],
            relations: [],
        }
    },
    props: ['tab'],
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        })
    },
    components: {
        MobileTableHR,
        CashboxAccountEdit,
        CashboxAccountCreate,
        CashboxAccountDetails,
        EmptyState,
        MobileTableButton,
        MobileTableInfo,
        MobileTableFramework,
        TablePagination,
        TableHeadBlock,
    },
    inject: ['api'],
    mixins: [functionsMixin, tableMixin, mobileCheckMixin, basicMixin,],
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            this.drawerDetails = false
            httpService().post(this.api.cashbox.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
                tab: this.tab
            }).then((response) => {
                let data = response.data

                if (data.success)
                this.values = data.accounts
                this.loading = false
                this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        openDrawerDetails(row) {
            this.drawerDetails = true
            this.accountDetails = row
        },
        openEditDrawer() {
            this.drawerDetails = false
            this.drawerEdit = true
        },
        closeEditDrawer() {
            this.drawerEdit = false
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 1) {
                    let total = 0
                    this.displayData.forEach(function (value) {
                        total = total + parseFloat(value.total)
                    })
                    sums[index] = this.formatNumber(total, 2) + ' руб.'
                    return
                }
            })

            return sums
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'accounts') {
                    this.moduleGet()
                    this.resetFilter()
                }
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) {
                this.moduleGet()
                this.resetFilter()
            }
        }
    },
}
</script>

<style scoped>

</style>
