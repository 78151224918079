<template>
    <div class="widget-card">
        <div class="widget-card-title">Текущие показатели
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" icon="el-icon-refresh"
                       circle></el-button>
        </div>
        <div class="widget-card-body">
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'id',
    ],
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>

<style scoped>
</style>
