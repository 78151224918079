<template>
    <Auth title="Отчет по заказам">
        <div class="card">
            <div class="card-body">
                <el-form label-position="top">

                    <el-row gutter="15">

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateStart"
                                label="Начало периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateEnd"
                                label="Окончание периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8" v-if="!showDetails">
                            <el-form-item label="settings" class="hideLabel">
                                <el-button :icon="icons.plus" class="color-grey" @click="setShowDetails(true)" type="text">
                                    Дополнительные параметры
                                </el-button>
                            </el-form-item>
                        </el-col>

                        <el-col :sm="24" v-if="showDetails">
                            <el-form-item label="Столбцы таблицы" class="mb-0">
                                <el-checkbox-group v-model="columns">
                                    <el-checkbox v-for="(column, index) in columnsData" :key="index" :label="column.id">
                                        {{ column.name }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>

                    </el-row>

                    <div class="w-100">
                        <el-button
                            class="color-grey"
                            v-if="showDetails"
                            @click="setShowDetails(false)"
                            style="margin-top: 5px; margin-bottom: 15px;"
                            :icon="icons.top"
                            type="text">
                            Скрыть блок настроек
                        </el-button>
                    </div>

                    <div>
                        <el-button :loading="loading" type="primary"
                                   @click="moduleGet">Сформировать отчет
                        </el-button>

                        <el-button plain :icon="icons.back"
                                   @click="$router.push('/report')">Другой отчет
                        </el-button>
                    </div>

                </el-form>

                <hr style="margin-top: 20px"/>

                <div v-if="loading" class="text-center mt-30">
                    <ReportLoader/>

                    <h3>Формирование отчета</h3>
                </div>

                <div v-if="!loading">

                    <div class="reportHead">
                        <h2>Отчет по заказам</h2>
                    </div>

                    <el-table
                        empty-text="Информация не найдена"
                        class="mt-15 w-100"
                        v-loading="loading"
                        :data="displayData">

                        <el-table-column
                            v-if="checkColumn('id')"
                            prop="id"
                            label="#"
                            width="100px"
                            fixed/>

                        <el-table-column
                            v-if="checkColumn('date')"
                            prop="date"
                            label="Дата"
                            width="140px"
                            fixed/>

                        <el-table-column
                            v-if="checkColumn('good')"
                            prop="good"
                            label="Товар">
                            <template v-slot="scope">
                                <span class="textMedium warning300">{{ scope.row.good ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('seller')"
                            prop="seller"
                            label="Продавец">
                            <template v-slot="scope">
                                <span class="textMedium neutral700">{{ scope.row.seller ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('buyer')"
                            prop="buyer"
                            label="Контрагент">
                            <template v-slot="scope">
                                <span class="textMedium primary600">{{ scope.row.buyer ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('total')"
                            prop="total"
                            label="Объем">
                            <template v-slot="scope">
                                <span class="textMedium primary600">{{ formatNumber(scope.row.total) ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('done')"
                            prop="done"
                            label="Выполнено">
                            <template v-slot="scope">
                                <span class="textMedium success400">{{ formatNumber(scope.row.done) ?? '-' }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            v-if="checkColumn('status')"
                            prop="status"
                            label="Статус"/>

                        <el-table-column
                            v-if="checkColumn('paymentMethod')"
                            prop="paymentMethod"
                            label="Способ оплаты"/>

                        <el-table-column
                            v-if="checkColumn('address')"
                            prop="address"
                            label="Адрес"/>

                        <el-table-column
                            v-if="checkColumn('failReason')"
                            prop="failReason"
                            label="Причина отказа"/>

                        <el-table-column
                            v-if="checkColumn('ordersTotalCount')"
                            prop="ordersTotalCount"
                            label="Кол-во заявок (план)"/>

                        <el-table-column
                            v-if="checkColumn('ordersDoneCount')"
                            prop="ordersDoneCount"
                            label="Кол-во заявок (выполнено)"/>

                        <el-table-column
                            v-if="checkColumn('deliveryType')"
                            prop="deliveryType"
                            label="Тип доставки"/>


                    </el-table>

                    <TablePagination
                        v-model="pageSize"
                        :total="valueLength"
                        :loading="loading"
                        @action="setPage"
                    />
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import getTimezone from '@/mixins/getTimezone'
import {httpService} from '@/services/http.service'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from '@/mixins/iconsMixin'
import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'ReportMixGroupOrders',
    components: {Auth, FormDateTimePicker, ReportLoader, TablePagination},
    props: ['settings'],
    inject: ['api'],
    mixins: [tableMixin, getTimezone, functionsMixin, iconsMixin],
    created() {
        this.moduleGet()
    },
    data() {
        return {
            filter: {
                dateStart: this.$route.query ? this.$route.query.dateStart : null,
                dateEnd: this.$route.query ? this.$route.query.dateEnd : null
            },
            pageSize: 50,
            total: null,
            dateStart: null,
            dateEnd: null,
            goods: [],
            companies: [],
            operations: [],
            vehicles: [],
            drivers: [],
            showDetails: false,
            loading: true,
            columns: JSON.parse(localStorage.getItem('reportMixGroupOrders')) ?? ['id', 'date', 'good', 'seller', 'buyer', 'total', 'done', 'status'],
            columnsData: [{
                id: 'id',
                name: 'ID'
            }, {
                id: 'date',
                name: 'Дата'
            }, {
                id: 'Товар',
                name: 'good'
            }, {
                id: 'total',
                name: 'Объем'
            }, {
                id: 'done',
                name: 'Выполнено'
            }, {
                id: 'status',
                name: 'Статус'
            }, {
                id: 'ordersTotalCount',
                name: 'Кол-во заявок (план)'
            }, {
                id: 'ordersDoneCount',
                name: 'Кол-во заявок (выполнено)'
            }, {
                id: 'paymentMethod',
                name: 'Способ оплаты'
            }, {
                id: 'address',
                name: 'Адрес доставки'
            }, {
                id: 'failReason',
                name: 'Причина отказа'
            }, {
                id: 'seller',
                name: 'Продавец'
            }, {
                id: 'buyer',
                name: 'Покупатель'
            }, {
                id: 'deliveryType',
                name: 'Тип доставки'
            }]
        }
    },
    methods: {
        setShowDetails(value) {
            this.showDetails = value;
        },
        moduleGet() {
            this.loading = true
            this.showDetails = false
            this.updateRouterQuery();

            httpService().post(this.api.report.mixGroupOrders, {
                reportKey: this.$route.meta.key,
                settings: this.$route.query,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.total = data.total
                this.dateStart = data.dateStart
                this.dateEnd = data.dateEnd
                this.loading = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        updateRouterQuery() {
            let updatedQuery = {...this.$route.query, ...this.filter};
            this.$router.push({ name: 'ReportMixGroupOrders', query: updatedQuery });
        },
        checkColumn(name) {
            return this.columns.find(el => el === name)
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        exportToExcel() {
            // this.loadingButton = true

            httpService().post(this.api.exportExcel.exportMixReport, {
                settings: this.$route.query,
                filter: this.filter,
                columns: this.columns,
                timezone: this.getTimezone()
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.xlsx')
                document.body.appendChild(link)
                link.click()

                let data = response.data

                if (data.success)
                    this.loadingButton = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        }
    },
    watch: {
        'columns': {
            handler () {
                localStorage.setItem('reportMixGroupOrders', JSON.stringify(this.columns));
            }
        }
    }
}
</script>

<style scoped>

</style>
