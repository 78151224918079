<template>
    <div class="techHeadInfo">
        <div class="techHeadInfoBody">
            <div class="techBlockBodyTopInfo">
                <span>{{ techDetails.number }}</span>
            </div>
            <div class="techBlockBodyHeading">
                <span v-if="techDetails.name">
                    {{ techDetails.name }}
                </span>
            </div>
            <div class="techHeadInfoIndicators">
                <div class="techHeadInfoIndicatorsItems">
                    <div class="techHeadInfoIndicatorsItem" style="width: 120px">
                        <TechFuelIcon/>
                        <span>{{ techDetails.fuel_total ? (formatNumber(techDetails.fuel_total) + ' л') : '-' }}</span>
                    </div>

                    <div class="techHeadInfoIndicatorsItem" style="width: 120px">
                        <TechMileageIcon/>
                        <span>{{ formatNumber(techDetails.mileage) }} {{ techDetails.metric === 'mileage' ? 'км' : 'мтч' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TechFuelIcon from '@/views/components/Icons/TechFuelIcon'
import TechMileageIcon from '@/views/components/Icons/TechMileageIcon'
import functionsMixin from '@/mixins/functionsMixin'
export default {
    name: 'TechVehicleHeadInfo',
    components: {TechMileageIcon, TechFuelIcon},
    props: ['tech'],
    mixins: [functionsMixin],
    data() {
        return {
            techDetails: null
        }
    },
    watch: {
        'tech': {
            handler: function () {
                if (this.tech) this.techDetails = this.tech
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
