<template>
    <router-view/>
</template>

<script>
import { defineComponent } from 'vue'
import moment from "moment";

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
})

export default defineComponent({
    name: 'App',
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        loadingState(state = true) {
            this.isLoading = state
        }
    }
})
</script>
