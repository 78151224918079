<template>
    <div class="line-echarts">
        <VueEcharts
            :option="line"
        />
    </div>
</template>

<script type="text/babel">
import VueEcharts from "vue-echarts";
import { graphic } from "echarts"

const setUnit = (value, unit) => {
    let _value = parseFloat(value).toFixed(2)
    _value = _value.replace('.', ',')
    if (unit) {
        return `${_value + (unit === 'м3.' ? ' м<sup>3</sup>' : unit)}`
    } else {
        return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(value)
    }
}

const getLine = (dataset, unit) => {
    return {
        dataset: dataset,
        xAxis: {
            type: 'category'
        },
        yAxis: {
            type: 'value',
            show: false
        },
        grid: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        tooltip: {
            trigger: 'axis',
            borderWidth: 0,
            padding: [0, 10],
            axisPointer: {
                type: "shadow"
            },
            formatter: function (params) {
                const colorSpan = (color) => `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color: ${color}"></span>`;
                let finalContent = '';

                params.forEach(item => {
                    let content = `<div><p style="margin: 10px 0">${item.data.day}</p><p style="white-space: nowrap;margin: 10px 0">${colorSpan('#00D0A3')} Кол-во:<b style="margin-left: 3px">${setUnit(item.data.efficient, unit)}</b></p></div>`
                    finalContent += content;
                });

                return finalContent;
            }
        },
        series: [
            {
                type: 'line',
                smooth: true,
                showSymbol: false,
                zlevel: 1,
                lineStyle: {
                    color: '#00D0A3',
                    width: 2
                },
                tooltip: {
                    show: true
                },
                itemStyle: {color: '#00D0A3'},
                areaStyle: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(0, 208, 163, 0.4)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(0, 208, 163, 0)'
                        }
                    ])
                }
            },
            {
                type: 'line',
                showSymbol: false,
                lineStyle: {
                    color: 'rgba(0, 0, 0, 0)',
                    width: 0
                },
                tooltip: {
                    show: false
                },
                markLine: {
                    symbol: 'none',
                    label: { show: false },
                    type: 'solid',
                    silent: true,
                    lineStyle: {
                        type: 'solid',
                        color: '#EEEEF0'
                    },
                    data: [
                        { xAxis: 0 },
                        { xAxis: 1 },
                        { xAxis: 2 },
                        { xAxis: 3 },
                        { xAxis: 4 },
                        { xAxis: 5 },
                        { xAxis: 6 }
                    ]
                }
            }
        ]
    }
}

export default {
    name: 'ChartLine',
    components: {
        VueEcharts,
    },
    props: [
        'data',
        'unit'
    ],
    data: () => ({
        line: {}
    }),
    watch: {
        'data': {
            handler: function () {
                if (this.data) {
                    this.line = getLine(this.data, this.unit);
                }
            },
            deep: false,
            immediate: true
        }
    },
    methods: {},
};
</script>

<style scoped>
.line-echarts {
    width: 84px;
    height: 70px;
}
</style>
