<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        disable-filter="true"
        disable-create-button="true"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
    />

    <el-table
        v-loading="loading"
        :data="displayData"
        ref="table"
        empty-text="Нет информации"
        class="mt-15 w-100">
        <el-table-column
            label=""
            width="65px"
        >
            <template v-slot="scope">
                <el-button :icon="icons.arrowDown" @click="toogleExpand(scope.row)"
                           circle></el-button>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Компания"
        >
            <template v-slot="scope">
                <div class="double">
                    <p>{{ scope.row.name }}</p>
                    <span>ИНН: {{ scope.row.inn ?? '-' }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="tariff"
            label="Тариф"
        />
        <el-table-column
            prop="created"
            label="Дата рег."
        >
            <template v-slot="scope">
                {{ getDateFromUnix(scope.row.created, 'DD.MM.YY') }}
            </template>
        </el-table-column>
        <el-table-column
            prop="id"
            label="Пользователи"
        >
            <template v-slot="scope">
                {{ scope.row.users ? scope.row.users.length : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="id"
            label="Модули"
        >
            <template v-slot="scope">
                {{ scope.row.modules ? scope.row.modules.length : '-' }}
            </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
            <template v-slot="props">

                <div style="padding-left: 75px; font-weight: 300;">

                    <p>Баланс: {{ formatNumber(props.row.balance) }}, доступ оплачен до
                        {{ getDateFromUnix(props.row.payday, 'DD.MM.YY') }}</p>

                    <p>Используемые интеграции: <span v-for="(integration, index) in props.row.integrations"
                                                      :key="index">{{ integration.key }} ({{
                            integration.object ?? '-'
                        }})<span
                            v-if="index !== props.row.integrations.length - 1">, </span></span></p>

                    <p>Используемые модули ({{ props.row.modules.length }}): <span
                        v-for="(module, index) in props.row.modules"
                        :key="index">{{ module.name }}<span
                        v-if="index !== props.row.modules.length - 1">, </span></span></p>

                    <h3 class="tableH3" data-v-1c0a609a="">Пользователи</h3>

                    <el-table
                        v-loading="loading"
                        :data="props.row.users"
                        @sortChange="sortChanged"
                        class="mt-15 w-100">
                        <el-table-column
                            prop="name"
                            label="ФИО"
                        />
                        <el-table-column
                            prop="role"
                            label="Роль"
                            sortable
                        />
                        <el-table-column
                            prop="email"
                            label="E-mail"
                        >
                            <template v-slot="scope">
                                {{ scope.row.email ?? '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="phone"
                            label="Телефон"
                        >
                            <template v-slot="scope">
                                {{ scope.row.phone ?? '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="activity_date"
                            label="Был в системе"
                            sortable
                        >
                            <template v-slot="scope">
                                {{ scope.row.activity_date ? getDateFromUnix(scope.row.activity_date) : '-' }}
                            </template>
                        </el-table-column>
                    </el-table>

                </div>

            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import TablePagination from '@/views/components/Table/TablePagination'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'TtsInfo',
    components: {TablePagination, TableHeadBlock},
    mixins: [tableMixin, functionsMixin, iconsMixin],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    data() {
        return {
            loading: true,
            orderBy: 'role',
            direction: 'descending',
        }
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.tts.info, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderBy: this.orderBy,
                direction: this.direction,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        toogleExpand(row) {
            let $table = this.$refs.table
            $table.toggleRowExpansion(row)
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        sortChanged({prop, order}) {
            this.orderBy = prop;
            this.direction = order;
            this.moduleGet();
        },
    },
}
</script>

<style scoped>

</style>
