<template>
    <div>
        <slot name="button"></slot>
        <h3>
            {{ title }}
            <slot name="title"></slot>
        </h3>
    </div>
</template>

<script>
export default {
    name: "DrawerHeadTitle",
    props: [
        'title',
    ]
}
</script>

<style scoped>

</style>
