<template>
    <el-row :gutter="20" v-if="itemValue.delivery_type==='delivery'">
        <el-col :sm="12">
            <el-form-item label="Тип доставки">
                <el-radio-group
                    v-model="itemValue.type_delivery"
                    @change="resetOrder()"
                >
                    <el-radio label="withoutInterval">Без интервала</el-radio>
                    <el-radio label="withInterval">С интервалом на
                        разгрузку
                    </el-radio>
                    <el-radio label="withIntervalClient">Интервал от клиента</el-radio>
                </el-radio-group>
            </el-form-item>

        </el-col>
        <el-col :sm="12">
            <el-form-item
                label="Тип распределения миксеров"
            >
                <el-radio-group
                    v-model="itemValue.type_mixers_count"
                    @change="resetOrder()"
                >
                    <el-radio label="many">Несколько машин</el-radio>
                    <el-radio label="one">Одной машиной</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>

    </el-row>
    <el-row :gutter="20">

        <el-col :sm="8">
            <el-form-item class="is-required" :label="itemValue.delivery_type==='delivery' ? 'Время первой доставки' : 'Время первой загрузки'">
                <el-input
                    v-model="itemValue.first_order_time_delivery"
                    @focusout="resetOrder()"
                    placeholder="00:00"
                    type="time"
                />
            </el-form-item>
        </el-col>
        <el-col :sm="8" v-if="itemValue.delivery_type==='delivery'">

            <FormNumber
                v-model="itemValue.time_unloading"
                label="Время на разгрузку каждого миксера"
                placeholder="Укажите время "
                precision="2"
                @focusoutAction="resetOrder()"
            />

        </el-col>
        <el-col :sm="8" v-if="itemValue.delivery_type==='delivery'">

            <FormNumber
                v-if="itemValue.type_delivery === 'withIntervalClient'"
                v-model="itemValue.time_interval_client"
                label="Интервал от клиента (мин)"
                placeholder="Укажите время"
                precision="2"
                @focusoutAction="resetOrder()"
            />

        </el-col>
    </el-row>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber.vue";

export default {
    name: "MixOrderGroupCreateDeliveryTime",
    components: {FormNumber},
    props: ['modelValue'],
    data() {
        return {
            itemValue: this.modelValue,
            timeDelivery: null,
        }
    },
    methods: {
        resetOrder() {
            this.$emit('resetOrder')
        },
        setTime() {
            this.itemValue.first_order_time_delivery = this.timeDelivery
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
