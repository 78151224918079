<template>
    <el-select
        v-model="itemValue"
        @change="$emit('update:modelValue', itemValue)"
        placeholder="Интеграция"
        :size="table ? 'large' : 'default'"
        :class="table ? 'filterSelect' : ''"
        :style="table ? 'width: 180px' : ''"
        clearable
    >
        <el-option
            v-for="unit in integrations"
            :key="unit.id"
            :label="getLabel(unit)"
            :value="unit.id"
        >
            <span style="float: left" class="textMedium neutral900">{{ getLabel(unit) }}</span>
            <span
                style="float: right;color: rgb(162, 165, 191);font-size: 13px;right: -15px;position: relative;">#{{
                    unit.id
                }}</span
            >
        </el-option>
        <el-option
            key="withoutIntegration"
            value="withoutIntegration"
            label="Без интеграции"
        >
            <span class="textMedium neutral900">Без интеграции</span>
        </el-option>
    </el-select>
</template>
<script>
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import TTSProducts from "@/mixins/TTSProducts";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "TableIntegrationFilter",
    components: {},
    props: ['modelValue', 'table'],
    mixins: [TTSProducts, functionsMixin],
    data () {
        return {
            itemValue: this.modelValue
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
    },
    methods: {
        getLabel(unit) {
            let label = unit.integration.name
            if (unit.integration.key === 'SmartMix') {
                if (unit.product) {
                    label = unit.product
                } else {
                    label = 'БСУ'
                }

                if (unit.account_module) {
                    label += ': ' + unit.account_module.name
                }
            } else if (unit.integration.key === '1C') {
                label = '1С: '
                if (unit.company) {
                    label += unit.company.short_name ?? unit.company.name
                } else {
                    label += 'интеграция с Бухгалтерией'
                }
            }
            return label
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemValue': {
            handler: function () {
                this.$emit('moduleGet')
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
