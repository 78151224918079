<template>
    <Auth title="CRM">
        <div></div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'

export default {
    name: 'CRMIndex',
    data() {
        return {

        }
    },
    components: {
        Auth,
    },
    created() {
        this.$router.push({ name: 'CRMOrders',  params:{ id: this.$route.params.id }})
    },
}
</script>

<style scoped>

</style>
