export default {
    methods: {
        getTimezone() {
            var timezone = (new Date().getTimezoneOffset() ? new Date().getTimezoneOffset() / 60 : 0) + 3
            timezone =- timezone
            return timezone
        },
        getTimezoneOffset() {
            return new Date().getTimezoneOffset();
        },
        getUserTz() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
    }
}
