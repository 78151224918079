<template>
    <PageH1 title="События"/>

    <el-table
        :data="displayData"
        class="mt-15"
        v-loading="loading">
        <el-table-column
            prop="created_at"
            label="Дата"
            width="100"
        >
            <template v-slot="scope">
                <span class="textMedium textSM neutral300">{{ getDateFormDB(scope.row.created_at, 'shortDate') }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="Событие"
        >
            <template v-slot="scope">
                <div class="double">
                    <span class="textMedium textSM neutral900">
                    <template v-if="scope.row.type === 1">
                        {{ scope.row.action === 1 ? 'Добавление' : 'Списание' }} топлива
                    </template>
                    <template v-if="scope.row.type === 2 || scope.row.type === 3">
                        Изменение пробега ({{ scope.row.type === 2 ? 'км.' : 'мтч.' }})
                    </template>
                    </span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            label="Изменение"
        >
            <template v-slot="scope">
                <div class="double">
                    <template v-if="scope.row.type === 1">
                        <template v-if="scope.row.action === 1">
                            <span class="textMedium textSM primary600">+ {{ formatNumber(scope.row.count) }} л.</span>
                        </template>
                        <template v-if="scope.row.action === 2">
                            <span class="textMedium textSM danger300">- {{ formatNumber(scope.row.count) }} л.</span>
                        </template>
                    </template>
                    <template v-if="scope.row.type === 2 || scope.row.type === 3">
                        <span :class="'textMedium textSM ' + (scope.row.type === 2 ? 'primary600' : 'success400')">+ {{
                                formatNumber(scope.row.count)
                            }} {{ scope.row.type === 2 ? 'км.' : 'мтч.' }}</span>
                    </template>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            label=""
            width="125">
            <template v-slot="scope">
                <div class="text-right">
                    <el-button size="small"
                               :icon="icons.delete"
                               @click="removeHistory(scope.row.id)"/>
                </div>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import PageH1 from '@/views/components/PageH1'
import {httpService} from "@/services/http.service";
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'TechDetailsHistory',
    components: {TablePagination, PageH1},
    props: ['vehicleId'],
    inject: ['api'],
    mixins: [tableMixin, functionsMixin, iconsMixin],
    data() {
        return {
            loading: false,
            unit: 'мтч',
        }
    },
    created() {
        this.getTechService()
    },
    methods: {
        getTechService() {
            this.loading = true
            httpService().post(this.api.techVehicle.getHistory, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                techId: this.vehicleId,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.values = data.values
                    this.unit = data.unit
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        removeHistory(id) {
            this.loading = true

            this.$confirm(
                'Вы действительно хотите удалить событие?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.techVehicle.removeHistory, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                        techId: this.vehicleId,
                        historyId: id
                    }).then((response) => {
                        let answer = response.data

                        if (answer.success)
                            this.$message({
                                message: 'Событие удалено',
                                showClose: true,
                                type: 'success'
                            })

                        this.getTechService()

                        this.loading = false
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
