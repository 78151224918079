<template>
    <div>
        <p class="textXS textRegular neutral400">Настройки стоимости:</p>
        <el-row :gutter="15">
            <el-col :sm="6">
                <FormSelect
                    v-model="form.price_type"
                    label="Тип оплаты"
                    placeholder="Выберите"
                    :items="priceTypesPump"
                />
            </el-col>
            <el-col :sm="6">
                <FormNumber
                    v-model="form.price"
                    :label="form.price_type === 'by_all' ? 'Стоимость (за всё., без НДС)' : 'Стоимость (за час., без НДС)'"
                    placeholder="0"
                    :precision="2"
                />
            </el-col>
            <el-col :sm="6">
                <el-form-item>
                    <template #label>
                        % НДС (<el-checkbox class="vatCheckbox" v-model="form.vat_in_price" label="Включен в цену" size="small" />)
                    </template>
                    <el-input
                        class="custom-input"
                        v-model="form.vat"
                        type="number"
                        placeholder="0"
                    />
                </el-form-item>
            </el-col>
            <el-col :sm="6">
                <FormNumber
                    v-model="form.full_price"
                    label="Итоговая стоимость"
                    placeholder="0"
                    :precision="2"
                />
            </el-col>
        </el-row>
        <hr/>

        <p class="textXS textRegular neutral400">Ограничения по объему:</p>

        <el-row :gutter="15">
            <el-col :sm="6">
                <FormNumber
                    v-model="form.volume_min"
                    label="Объем миксера ОТ (куб.м.)"
                    placeholder="0"
                    :precision="2"
                />
            </el-col>
            <el-col :sm="6">
                <FormNumber
                    v-model="form.volume_max"
                    label="Объем миксера ДО (куб.м.)"
                    placeholder="0"
                    :precision="2"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import serviceInfo from "@/mixins/serviceInfo";

export default {
    name: "ServiceTariffDowntimeOtherForm",
    components: {FormSelect, FormNumber},
    props: ['modelValue'],
    mixins: [serviceInfo],
    data() {
        return {
            form: this.modelValue
        }
    },
    created() {
        this.form.price_type = 'by_one'
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.form = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
