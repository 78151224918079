<template>
    <template v-if="serviceWorks.length === 0 || addWork">
        <el-form label-position="top">
            <el-row :gutter="20">
                <el-col :md="11">

                    <FormInput
                        v-model="newWork.name"
                        label="Наименование работы"
                        placeholder="Введите название работы"
                        :required="true"
                    />

                </el-col>
                <el-col :md="11">

                    <FormNumber
                        v-model="newWork.price"
                        label="Стоимость"
                        placeholder="Укажите стоимость "
                    />

                </el-col>
                <el-col :md="1">

                </el-col>
                <el-col :md="22">

                    <FormTextarea
                        v-model="newWork.description"
                        label="Комментарий к работе"
                        :rows="4"
                        placeholder="Добавьте дополнительную информацию о работе"
                    />

                </el-col>
            </el-row>
        </el-form>

        <el-form-item label="">
            <el-button :icon="icons.plus" type="primary" :disabled="!newWork.name" v-if="indexEditWork === null"
                       @click.prevent="addNewRowWorks" plain>Добавить работу
            </el-button>
            <el-button :icon="icons.check" type="primary" :disabled="!newWork.name" v-if="indexEditWork === 0"
                       @click.prevent="saveEditWork" plain>Сохранить работу
            </el-button>
            <el-button :icon="icons.arrowLeft" v-if="serviceWorks.length !== 0"
                       @click.prevent="cancelAddNewWork()" plain>Отмена
            </el-button>

            <el-button
                type="info"
                @click="saveWorks"
                v-if="serviceWorks.length === 0"
                plain
            >
                Оставить список работ пустым
            </el-button>
        </el-form-item>
    </template>
    <template v-else>
        <div class="techServiceList d-flex-full" v-for="(work, index) in serviceWorks" :key="index">
            <div>{{ work.name }}</div>
            <div>{{ work.price ? work.price + '  руб.' : '-' }}</div>
            <div>
                <el-button type="info" :icon="icons.edit"
                           @click.prevent="editRowWorks(index, work)"></el-button>
                <el-button type="danger" :icon="icons.delete"
                           @click.prevent="deleteRowWorks(index, work)"></el-button>
            </div>
        </div>

        <el-button @click="openAddWorkForm()" class="w-100 mb-30" :icon="icons.plus" plain>Добавить работу</el-button>

        <el-button type="primary" class="ml-0" :icon="icons.check" @click="saveWorks">Сохранить список работ</el-button>
    </template>
</template>

<script>
import FormInput from '@/views/components/Form/FormInput'
import FormTextarea from '@/views/components/Form/FormTextarea'
import FormNumber from '@/views/components/Form/FormNumber'
import iconsMixin from '@/mixins/iconsMixin'

export default {
    name: 'ServiceCreateWorksDialog',
    components: {FormNumber, FormTextarea, FormInput},
    mixins: [iconsMixin],
    props: ['modelValue', 'changeTitle'],
    data() {
        return {
            serviceWorks: JSON.parse(JSON.stringify(this.modelValue)),
            newWork: {
                name: null,
                description: null,
                price: null
            },
            addWork: false,
            indexEditWork: null,
        }
    },
    methods: {
        editRowWorks(index, work) {
            this.indexEditWork = this.serviceWorks.indexOf(work)
            this.newWork = {
                name: work.name,
                description: work.description,
                price: work.price
            }
            this.$emit('changeTitle', 'Изменение работы')
            this.addWork = true
        },
        saveEditWork() {
            this.serviceWorks[this.indexEditWork] = {
                name: this.newWork.name,
                description: this.newWork.description,
                price: this.newWork.price
            }

            this.newWork = {
                name: null,
                description: null,
                price: null
            }

            this.$emit('changeTitle', 'Список работ для проведения заказ-наряда')
            this.indexEditWork = null
            this.addWork = false
        },
        deleteRowWorks(index, work) {
            var idx = this.serviceWorks.indexOf(work)
            if (idx > -1) {
                this.serviceWorks.splice(idx, 1)
            }
            if (this.serviceWorks.length === 0) this.$emit('changeTitle', 'Добавление работы')
        },
        addNewRowWorks() {
            this.serviceWorks.push({
                name: this.newWork.name,
                description: this.newWork.description,
                price: this.newWork.price
            })

            this.newWork = {
                name: null,
                description: null,
                price: null
            }

            this.$emit('changeTitle', 'Список работ для проведения заказ-наряда')
            this.addWork = false
        },
        saveWorks() {
            this.$emit('update:modelValue', this.serviceWorks)
            this.$message({
                message: 'Список сохранен',
                showClose: true,
                type: 'success'
            })
            this.$emit('closeDialog')
        },
        openAddWorkForm() {
            this.$emit('changeTitle', 'Добавление работы')
            this.addWork = true
        },
        cancelAddNewWork() {
            this.$emit('changeTitle', 'Список работ для проведения заказ-наряда')
            this.addWork = false
            this.newWork = {
                name: null,
                description: null,
                price: null
            }
        }
    }
}
</script>

<style scoped>

</style>
