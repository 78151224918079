<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle title="Добавить запчасть на склад"/>

            <el-form label-position="top" v-loading="loading">

                <FormInputSuggestions
                    v-model="form.good"
                    label="Запчасть"
                    placeholder="Введите название запчасти"
                    new-item="good"
                    :items="goods"
                    property="name"
                />

                <FormNumber
                    v-model="form.total"
                    label="Текущее количество на складе"
                    placeholder="Введите количество"
                />

                <FormSelect
                    v-model="form.category"
                    label="Категория"
                    placeholder="Выберите категорию"
                    :items="storeCategories"
                />

                <FormInput
                    v-model="form.sku"
                    label="Артикул"
                    placeholder="Введите артикул"
                />

                <FormSelect
                    v-model="form.unit"
                    label="Единица измерения"
                    placeholder="Выберите ед. измерения"
                    :items="units"
                />

                <el-checkbox
                    v-model="bindToVehicle"
                    v-if="!bindToVehicle"
                    class="mb-15"
                    label="Привязать запчасть к определенной технике?"
                />

                <FormSelect
                    v-if="bindToVehicle"
                    v-model="form.tech"
                    label="Привязать к технике"
                    placeholder="Выберите технику"
                    :items="vehicles"
                />

            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton
                @action="addNewStore"
                title="Добавить на склад"
            />
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import FormNumber from "@/views/components/Form/FormNumber";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import { mapState, mapActions } from 'pinia'
import {useCommonStore} from "@/store/common";
import FormSelect from "@/views/components/Form/FormSelect";
import {httpService} from "@/services/http.service";
import techStoreCategoriesMixin from "@/mixins/techStoreCategoriesMixin";
import FormInput from "@/views/components/Form/FormInput";
import FormInputSuggestions from "@/views/components/Form/FormInputSuggestions";

export default {
    name: 'TechAddNewStore',
    components: {
        FormInputSuggestions,
        FormInput, FormSelect, DrawerCreateButton, FormNumber, DrawerHeadTitle, DrawerFramework},
    props: ['storeGoods', 'vehicles'],
    inject: ['api'],
    mixins: [techStoreCategoriesMixin],
    computed: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        })
    },
    data() {
        return {
            loading: false,
            form: {
                good: null,
                total: null,
                category: null,
                sku: null,
                unit: null,
                tech: null
            },
            bindToVehicle: false,
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        addNewStore() {
            this.loading = true
            httpService().post(this.api.tech.createTechStore, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                form: this.form,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.$message({
                    message: 'Запчасть добавлена',
                    showClose: true,
                    type: 'success'
                })
                this.pullGoods.then(() => {
                    this.$emit('moduleGet')
                    this.$emit('closeDrawer')
                })

            }).catch((error) => {
                this.loading = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
    },
    watch: {
        'form': {
            handler: function () {
                if (this.form.good) {
                    let goodName = this.form.good
                    let good = this.goods.find(el => el.name === goodName)
                    if (good) {
                        this.form.unit = good.unit_id
                        this.form.sku = good.sku
                    }
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
