<template>
    <div id="burger" :class="{ 'active' : status }" @click.prevent="$emit('toggle')">
        <button
            type="button"
            class="burger-button"
            title="Меню"
        >
            <span class="burger-bar burger-bar--1"></span>
            <span class="burger-bar burger-bar--2"></span>
            <span class="burger-bar burger-bar--3"></span>
        </button>
    </div>
</template>

<script>
export default {
    name: "Burger",
    props: [
        'status'
    ]
}
</script>

<style scoped>

</style>
