<template>
    <el-form-item>
        <el-button :loading="loading" :type="type ?? 'primary'" :icon="icon ?? icons.plus"
                   v-bind:class="{ 'w-100': mobile || w100 }"
                   :plain="plain"
                   :disabled="disable"
                   :size="size"
                   @click="doAction">{{ title ?? 'Создать' }}
        </el-button>
    </el-form-item>
</template>

<script>
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: "FormAddButton",
    props: [
        'loading',
        'title',
        'plain',
        'type',
        'icon',
        'disable',
        'w100',
        'size'
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    methods: {
        doAction() {
            this.$emit('action')
        }
    }
}
</script>

<style scoped>

</style>
