<template>
    <div class="analyticsCard analyticsCardEmpty">
        <div class="analyticsCardBody">
            <div>
                <span class="analyticsCardTopTitle textXS textXSRegular neutral300">{{ title }}</span>

                <div class="headingXS headingRegular">
                    Нет данных
                </div>

                <div>
                    <a class="linkAnalyticNoData" :href="'/settings/goods/' + this.data?.noData[0]" target="_blank">Укажите стоимость продукции</a>
                </div>
            </div>

            <ProgressEmpty v-if="data.chartData?.chartType === 'progress'"/>

            <BarEmpty v-if="data.chartData?.chartType === 'bar'"/>

            <LineEmpty v-if="data.chartData?.chartType === 'line'"/>
        </div>
    </div>
</template>

<script>
import LineEmpty from '@/views/components/AnalyticsPage/Charts/LineEmpty.vue';
import BarEmpty from '@/views/components/AnalyticsPage/Charts/BarEmpty.vue';
import ProgressEmpty from '@/views/components/AnalyticsPage/Charts/ProgressEmpty.vue';

export default {
    name: 'AnalyticsEmptyBlock',
    components: {ProgressEmpty, BarEmpty, LineEmpty},
    props: [
        'data',
        'title'
    ]
}
</script>

