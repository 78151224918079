import { useCommonStore } from '@/store/common'

export default function setup({next}) {
    const commonStore = useCommonStore()

    if (!commonStore.isAuth) {
        return next({
            name: 'login'
        })
    }

    if (commonStore.getUser.role_id !== 1 || commonStore.getUser.setup === 1) {
        return next({
            name: 'dashboard'
        })
    }

    return next()
}
