<template>
    <div class="warning-notification">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "WarningNotification"
}
</script>

<style scoped>
.warning-notification {
    padding: 11px 15px;
    background: #FDF7E6;
    border: 1px solid #ECAF02;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2C3139;
}
</style>
