<template>
    <svg class="reportIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.88003 4.60022C8.08003 1.14022 2.87002 1.13022 2.07002 4.60022C1.60002 6.63022 2.89003 8.35022 4.01003 9.42022C4.83003 10.1902 6.12003 10.1902 6.94003 9.42022C8.06003 8.35022 9.34003 6.63022 8.88003 4.60022ZM5.51003 6.20022C4.96003 6.20022 4.51003 5.75022 4.51003 5.20022C4.51003 4.65022 4.95003 4.20022 5.50003 4.20022H5.51003C6.07003 4.20022 6.51003 4.65022 6.51003 5.20022C6.51003 5.75022 6.07003 6.20022 5.51003 6.20022Z" :fill="!notActive ? primary : primarySecond"/>
        <path d="M6.50977 5.2002C6.50977 5.7502 6.06977 6.2002 5.50977 6.2002C4.95977 6.2002 4.50977 5.7502 4.50977 5.2002C4.50977 4.6502 4.94977 4.2002 5.49977 4.2002H5.50977C6.06977 4.2002 6.50977 4.6502 6.50977 5.2002Z" fill="white"/>
        <path d="M21.91 16.6002C21.11 13.1402 15.88 13.1302 15.07 16.6002C14.6 18.6302 15.89 20.3502 17.02 21.4202C17.84 22.1902 19.14 22.1902 19.96 21.4202C21.09 20.3502 22.38 18.6302 21.91 16.6002ZM18.53 18.2002C17.98 18.2002 17.53 17.7502 17.53 17.2002C17.53 16.6502 17.97 16.2002 18.52 16.2002H18.53C19.08 16.2002 19.53 16.6502 19.53 17.2002C19.53 17.7502 19.08 18.2002 18.53 18.2002Z" :fill="!notActive ? primary : primarySecond"/>
        <path d="M19.5298 17.2002C19.5298 17.7502 19.0798 18.2002 18.5298 18.2002C17.9798 18.2002 17.5298 17.7502 17.5298 17.2002C17.5298 16.6502 17.9698 16.2002 18.5198 16.2002H18.5298C19.0798 16.2002 19.5298 16.6502 19.5298 17.2002Z" fill="white"/>
        <path d="M11.9999 19.75H9.31994C8.15994 19.75 7.14994 19.05 6.74994 17.97C6.33994 16.89 6.63994 15.7 7.50994 14.93L15.4999 7.94C15.9799 7.52 15.9899 6.95 15.8499 6.56C15.6999 6.17 15.3199 5.75 14.6799 5.75H11.9999C11.5899 5.75 11.2499 5.41 11.2499 5C11.2499 4.59 11.5899 4.25 11.9999 4.25H14.6799C15.8399 4.25 16.8499 4.95 17.2499 6.03C17.6599 7.11 17.3599 8.3 16.4899 9.07L8.49994 16.06C8.01994 16.48 8.00994 17.05 8.14994 17.44C8.29994 17.83 8.67994 18.25 9.31994 18.25H11.9999C12.4099 18.25 12.7499 18.59 12.7499 19C12.7499 19.41 12.4099 19.75 11.9999 19.75Z" fill="#3F3F41"/>
    </svg>

</template>

<script>

export default {
    name: 'ReportDeliveriesIcon',
    props: ['notActive'],
    data() {
        return {
            primary: '#1984E6',
            sub: '#efbd2d',
            primarySecond: '#8a909a',
            subSecond: '#fa938c'
        }
    }
}

</script>

<style scoped>

</style>
