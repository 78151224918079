export default {
    data() {
        return {
            storeCategories: [{
                id: 1,
                name: 'Расходники'
            }, {
                id: 2,
                name: 'Масла'
            }, {
                id: 3,
                name: 'Фильтра'
            }, {
                id: 4,
                name: 'Элементы двигателя'
            }, {
                id: 5,
                name: 'Системы питания двигателя'
            }, {
                id: 6,
                name: 'Системы выпуска газов двигателя'
            }, {
                id: 7,
                name: 'Система охлаждения'
            }, {
                id: 8,
                name: 'Элементы кузова'
            }, {
                id: 9,
                name: 'Приборы и доп. оборудование'
            }, {
                id: 10,
                name: 'Электрооборудование'
            }, {
                id: 11,
                name: 'Тормоза'
            }, {
                id: 12,
                name: 'Рулевое управление'
            }, {
                id: 13,
                name: 'Колеса и ступицы'
            }, {
                id: 14,
                name: 'Элементы подвески'
            }, {
                id: 15,
                name: 'Элементы рамы'
            }, {
                id: 16,
                name: 'Коробка передач'
            }, {
                id: 17,
                name: 'Элементы сцепления'
            }, {
                id: 18,
                name: 'Пр. технические жидкости'
            }, {
                id: 19,
                name: 'Прочее'
            }],
        }
    },
}
