<template>
    <DrawerFramework>
        <template v-slot:body>

            <DrawerHeadTitle title="Создание счета"/>

            <el-form ref="form" v-loading="loading" :model="form" size="small" label-position="top">

                <FormInput
                    v-model="form.name"
                    label="Удобное название счета"
                    placeholder="Например, счет в сбербанке"
                    :required="true"
                />

                <FormNumber
                    v-model="form.total"
                    label="Текущий остаток"
                    placeholder="Например, 100000 рублей"
                />

                <FormInput
                    v-model="form.bank"
                    label="Банк, где находится счет"
                    placeholder="Например, Филиал Корпоративный ПАО Совкомбанк"
                />

                <FormNumber
                    v-model="form.number"
                    label="Р/С (номер)"
                    placeholder="Например, 40701810400030710647"
                />

                <FormSelect
                    v-model="form.payment_method"
                    label="Метод оплаты"
                    placeholder="Выберите метод"
                    :items="paymentMethods"
                />

            </el-form>

        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Создать счет" @action="createAccount"/>
        </template>
    </DrawerFramework>
</template>

<script>
// создание счета в денежном учете
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import FormInput from "@/views/components/Form/FormInput";
import FormNumber from "@/views/components/Form/FormNumber";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import {httpService} from "@/services/http.service";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: 'CashboxAccountCreate',
    mixins: [paymentMethods],
    inject: ['api'],
    components: {FormSelect, DrawerCreateButton, FormNumber, FormInput, DrawerHeadTitle, DrawerFramework},
    data() {
        return {
            loading: false,
            form: {
                name: null,
                payment_method: null,
                total: null,
                bank: null,
                number: null,
            }
        }
    },
    methods: {
        createAccount() {
            this.loading = true
            httpService().post(this.api.cashboxAccount.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
                form: this.form,
            }).then(() => {
                this.$message({
                    message: 'Счет создан',
                    type: 'success',
                    showClose: true,
                })
                this.$emit('moduleGet')
                this.$emit('closeDrawer')
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
