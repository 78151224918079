<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
        <rect width="48" height="48" fill="url(#a)" rx="24"/>
        <path fill="#00D0A3" d="M17 26c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm1 3.68c0 .61-.33 1.19-.85 1.5l-.76.46c-.12.07-.25.11-.38.11-.25 0-.5-.13-.64-.36-.21-.35-.1-.82.26-1.03l.76-.46c.07-.04.12-.13.12-.21v-.93c0-.41.34-.75.75-.75s.75.34.75.75v.92H18Z"/>
        <path fill="#00D0A3" d="M34 21v6c0 3.5-2 5-5 5h-8.55c.35-.59.55-1.27.55-2 0-2.21-1.79-4-4-4-1.2 0-2.27.53-3 1.36V21c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5Z" opacity=".4"/>
        <path fill="#00D0A3" d="M24 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm6.5.25c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75Z"/>
        <defs>
            <linearGradient id="a" x1="0" x2="48" y1="48" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#96ECD9"/>
                <stop offset="1" stop-color="#96ECD9" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "CardSuccessIcon"
}
</script>

<style scoped>

</style>
