<template>

    <TableHeadBlock
        v-model="search"
        :loading="loadingButton"
        createButtonLabel="Добавить операцию"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="drawerCreate=true"
    />

    <Filter
        :filter="filter"
        :filter-items1="operationIncomeList"
        :filter-items2="operationTypes"
        :filter-items3="companies"
        :filter-items4="accounts"
        :loading="loadingButton"
        :filter-show="filterShow"
    />

    <EmptyState
        :values="tableData"
        :filter="filter"
        :search="search"
        :loading="loading"
        list-types="денежных операций"
    />

    <div v-if="tableData.length > 0">

        <div v-if="tableData">

            <MobileTableFramework v-for="(item, index) in tableData" :key="index">
                <template v-slot:body>

                    <MobileTableDate
                        :date="item.date"
                    />

                    <MobileTableInfo
                        title="Наименование"
                        :body="item.name ?? '-'"
                    />

                    <MobileTableInfo
                        title="Сумма"
                    >
                        <template v-slot:body>
                                    <span v-if="item.income"
                                          class="typeAddition">+ {{ formatNumber(item.value) }} руб.</span>
                            <span v-else
                                  class="typeSubtraction">- {{ formatNumber(item.value) }} руб.</span>
                        </template>
                    </MobileTableInfo>

                    <MobileTableInfo
                        title="Контрагент"
                        :no-border="true"
                    >
                        <template v-slot:body>
                            <span v-if="item.companyId">{{ getData(item.companyId, 'company') }}</span>
                            <span v-else>-</span>
                        </template>
                    </MobileTableInfo>

                    <MobileTableButton
                        @action="openDrawerDetails(item)"
                    />

                </template>
            </MobileTableFramework>

        </div>

        <el-table
            v-if="!smallMobile"
            v-loading="loading"
            :data="tableData"
            :summary-method="getSummaries"
            show-summary
            class="mt-15"
            style="width: 100%">
            <el-table-column
                prop="date"
                label="Дата"
                width="100px"
            >
                <template v-slot="scope">
                    {{ getDateByTz(scope.row.date, 'DD.MM.YY', this.tz) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Операция"
            >
                <template v-slot="scope">
                    <div class="double">
                        <p class="cashboxOperationName">{{ scope.row.name }}</p>
                        <span>{{
                                scope.row.typeId ? getInfo(scope.row.typeId, operationTypes) : 'Тип операции не указан'
                            }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="companyId"
                label="Контрагент"
                width="260px"
            >
                <template v-slot="scope">
                    <span v-if="scope.row.companyId">{{ getData(scope.row.companyId, 'company') }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="Сумма"
                width="160px"
            >
                <template v-slot="scope">
                            <span v-if="scope.row.income"
                                  class="typeAddition">+ {{ formatNumber(scope.row.value, 2) }} руб.</span>
                    <span v-else
                          class="typeSubtraction">- {{ formatNumber(scope.row.value, 2) }} руб.</span>
                </template>
            </el-table-column>
            <el-table-column
                label=""
                width="130px"
            >
                <template v-slot="scope">
                    <el-button
                        size="mini"
                        @click="openDrawerDetails(scope.row)">Подробнее
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>

    <el-drawer
        title=""
        v-model="drawerDetails"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">
        <CashboxDetails
            :operation="operation"
            :loadingButton="loadingButton"
            :operation-types="operationTypes"
            :accounts="accounts"
            :tz="tz"
            @moduleGet="moduleGet"
            @doEditOperation="openEditDrawer"
        />
    </el-drawer>

    <el-drawer
        title=""
        v-model="drawerEdit"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <CashboxEdit
            :operation="operation"
            :operationTypes="operationTypes"
            :accounts="accounts"
            @moduleGet="moduleGet"
            @closeEditDrawer="closeEditDrawer"
        />

    </el-drawer>


    <el-drawer
        title=""
        v-model="drawerCreate"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <CashboxOperationCreate
            :operation-types="operationTypes"
            :accounts="accounts"
            @moduleGet="moduleGet"
            @closeDrawer="drawerCreate=false"
        />

    </el-drawer>
</template>

<script>
import CashboxDetails from './CashboxDetails'
import CashboxEdit from './CashboxEdit'
import functionsMixin from '../../../mixins/functionsMixin'
import tableMixin from '../../../mixins/tableMixin'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import basicMixin from '../../../mixins/basicMixin'
import TableHeadBlock from '../Table/TableHeadBlock'
import Filter from '../Table/Filter/Filter'
import TablePagination from '../Table/TablePagination'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import {httpService} from '@/services/http.service'
import EmptyState from '@/views/components/EmptyState'
import {mapState} from 'pinia/dist/pinia'
import {useCommonStore} from '@/store/common'
import CashboxOperationCreate from '@/views/components/CashboxPage/CashboxOperationCreate'
import getTimezone from "@/mixins/getTimezone";

export default {
    name: 'CashboxOperationsTab',
    data() {
        return {
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
                incomeId: {
                    value: null,
                    type: 'select',
                    label: 'Тип операции',
                    placeholder: 'Выберите тип',
                    items: '1'
                },
                operationId: {
                    value: null,
                    type: 'select',
                    label: 'Категория',
                    placeholder: 'Выберите категорию',
                    items: '2'
                },
                companyId: {
                    value: null,
                    type: 'select',
                    label: 'Контрагент',
                    placeholder: 'Выберите контрагента',
                    items: '3'
                },
                accountId: {
                    value: null,
                    type: 'select',
                    label: 'Счет',
                    placeholder: 'Выберите счет',
                    items: '4'
                },
            },
            operations: [],
            operationTypes: [],
            accounts: [],
            moduleId: null,
            drawerCreate: false,
            drawerDetails: false,
            drawerEdit: false,
            operation: {
                id: null,
                title: null,
                type: null,
                value: null,
                comment: null,
                date: null,
            },
            integrations: [],
            relations: [],
            tableData: [],
            operationIncomeList: [{
                id: 'income',
                name: 'Доход'
            }, {
                id: 'expenditure',
                name: 'Расход'
            }],
            tz: null
        }
    },
    props: ['tab'],
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        })
    },
    components: {
        CashboxOperationCreate,
        EmptyState,
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate,
        MobileTableFramework,
        TablePagination,
        Filter,
        TableHeadBlock,
        CashboxEdit,
        CashboxDetails,
    },
    inject: ['api'],
    mixins: [functionsMixin, tableMixin, mobileCheckMixin, basicMixin, getTimezone],
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            this.drawerDetails = false
            httpService().post(this.api.cashbox.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
                tab: this.tab,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.operationTypes = data.types
                this.accounts = data.accounts
                this.tableData = data.operations.data
                this.loading = false
                this.loadingButton = false
                this.valueLength = data.operations.total
                this.tz = data.timeZone
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        openDrawerDetails(row) {
            this.drawerDetails = true
            this.operation = row
        },
        openEditDrawer() {
            this.drawerDetails = false
            this.drawerEdit = true
        },
        closeEditDrawer() {
            this.drawerEdit = false
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 3) {
                    let total = 0
                    this.tableData.forEach(function (value) {
                        if (value.income) total = total + parseFloat(value.value)
                        if (!value.income) total = total - parseFloat(value.value)
                    })
                    sums[index] = this.formatNumber(total, 2) + ' руб.'
                    return
                }
            })

            return sums
        },
        filterValues() {

        }
    },
    watch: {
        'tab': {
            handler: function () {
                if (this.tab === 'operations') {
                    this.moduleGet()
                    this.resetFilter()
                }
            },
            deep: true,
            immediate: true
        },
        $route(to, from) {
            if (to.name === from.name) {
                if (this.tab === 'operations') {
                    this.moduleGet()
                    this.resetFilter()
                }
            }
        },
        pageSize() {
            if (this.tab === 'operations') this.moduleGet()
        },
        page() {
            if (this.tab === 'operations') this.moduleGet()
        },
        'filter': {
            handler: function () {
                if (this.tab === 'operations') this.moduleGet()
            },
            deep: true,
            immediate: false
        },
        search() {
            if (this.tab === 'operations') this.moduleGet()
        }
    },
}
</script>

<style scoped>

</style>
