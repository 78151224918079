<template>
    <Auth title="Сводка" :tabs="tabs" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">
                <MixInfoStatusBlock
                    :status="status"
                    :loading="loading"
                    @moduleGet="moduleGet"
                >
        <span class="cashboxStatusBlockChange">
            <span style="padding-right: 0" @click="toggleShowChangeList">{{ getPeriodName() }}</span>
            <div class="cashboxStatusBlockList"
                 v-show="showChangeList">
                <el-button
                    type="text"
                    v-for="(item, index) in periodNames"
                    @click="setPeriod(item.key)"
                    :key="index">
                    {{ item.name }}
                </el-button>
            </div>
        </span>
                </MixInfoStatusBlock>

                <MixInfoGraph
                    :graph="graph"
                    :period="period"
                    :periodName="getPeriodName()"
                    :tz="tz"
                />

                <el-row :gutter="25">
                    <el-col :sm="12">
                        <InfoTopCompaniesBlock
                            :top-companies="topCompanies"
                        />
                    </el-col>

                    <el-col :sm="12">
                        <InfoTopGoodsBlock
                            :top-goods="topGoods"
                        />
                    </el-col>
                </el-row>
            </div>
        </div>
    </Auth>
</template>

<script>
import MixInfoStatusBlock from '@/views/components/MixPage/Info/MixInfoStatusBlock'
import MixInfoGraph from '@/views/components/MixPage/Info/MixInfoGraph'
import moment from 'moment'
import {httpService} from '@/services/http.service'
import InfoTopCompaniesBlock from "@/views/components/Blocks/InfoTopCompaniesBlock.vue";
import InfoTopGoodsBlock from "@/views/components/Blocks/InfoTopGoodsBlock.vue";
import Auth from "@/views/layouts/Auth.vue";
import mixTabs from "@/mixins/tabs/mixTabs";

export default {
    name: 'MixPageInfo',
    props: [
        'tab',
        'tz',
    ],
    inject: [
        'api'
    ],
    components: {
        Auth,
        InfoTopGoodsBlock,
        InfoTopCompaniesBlock,
        MixInfoGraph,
        MixInfoStatusBlock,
    },
    mixins: [mixTabs],
    data() {
        return {
            periodNames: [{
                key: 'today',
                name: 'сегодня'
            }, {
                key: 'week',
                name: 'неделю',
            }, {
                key: 'month',
                name: 'месяц'
            }, {
                key: 'year',
                name: 'год'
            }],
            showChangeList: false,
            status: 0,
            loading: false,
            period: 'today',
            topCompanies: [],
            topGoods: [],
            graph: {
                unitId: null,
                indicators: {
                    countProd: {
                        value: 0,
                        oldValue: 0,
                    },
                    speed: {
                        value: 0,
                        oldValue: 0,
                    },
                    countOrders: {
                        value: 0,
                        oldValue: 0,
                    },
                    lastDate: null,
                },
                total: []
            },
            integrations: [],
            relations: [],
        }
    },
    created() {
        this.mixModuleGet()
        this.moduleGet()
        this.addLinesCompanies()
        this.addLinesGoods()
        this.counterInterval = setInterval(
            function () {
                this.moduleGet()
            }.bind(this), 30000)
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        toggleShowChangeList() {
            this.showChangeList = !this.showChangeList;
        },
        mixModuleGet() {
            httpService().post(this.api.mix.getModule, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
            }).then((response) => {
                let data = response.data

                this.integrations = data.integrations
                this.relations = data.relations
            })
        },
        setPeriod(period) {
            this.showChangeList = false
            this.period = period
            this.moduleGet()
        },
        getPeriodName() {
            return this.periodNames.find(el => el.key === this.period).name
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getInfo, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
                period: this.period
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.topGoods = data.topGoods
                this.topCompanies = data.topCompanies
                this.graph = data.graph
                this.addLinesGoods()
                this.addLinesCompanies()
                this.getStatus()
                window.setInterval(() => {
                    this.loading = false
                }, 200)
            })
        },
        getStatus() {
            var CurrentDate = moment().unix()
            var duration = moment.duration(moment(CurrentDate).diff(moment(this.graph.indicators.lastDate)))
            let time = Math.round(duration.asMinutes() * 1000)
            if (time <= 60) {
                this.status = 1
            } else {
                this.status = 0
            }
        },
        addLinesGoods() {
            if (this.topGoods.length !== 5) {
                var i = 0
                var end = 5 - this.topGoods.length
                for (; i < end; i++) {
                    this.topGoods.push([{
                        'id': null,
                        'total': null
                    }])
                }
            }
        },
        addLinesCompanies() {
            if (this.topCompanies.length !== 5) {
                var i = 0
                var end = 5 - this.topCompanies.length
                for (; i < end; i++) {
                    this.topCompanies.push([{
                        'id': null,
                        'total': null
                    }])
                }
            }
        }
    },
    watch: {

    }
}
</script>

<style scoped>

</style>
