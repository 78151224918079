<template>
    <svg width="160" height="150" viewBox="0 0 160 150" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41.9893 13H39.9385V8.73438H35.9395V13H33.8887V3.04688H35.9395V7.08008H39.9385V3.04688H41.9893V13ZM50.0554 8.68651H46.1179V11.3525H50.739V13H44.0671V3.04688H50.7253V4.70801H46.1179V7.08008H50.0554V8.68651ZM59.851 4.70801H56.8022V13H54.7514V4.70801H51.7436V3.04688H59.851V4.70801ZM73.8915 15.4473H71.9979V13H65.996V15.4404H64.0546L63.9452 11.3525H64.6971C65.1027 11.0153 65.4149 10.5208 65.6337 9.86913C65.857 9.21288 66.0074 8.40168 66.0848 7.43554L66.372 3.04688H72.8934V11.3525H74.0555L73.8915 15.4473ZM67.0487 11.3525H70.8427V4.70801H68.3202L68.1493 7.32617C68.0126 9.08073 67.6457 10.4229 67.0487 11.3525ZM81.1441 10.9492H77.5484L76.8648 13H74.6842L78.3893 3.04688H80.2896L84.0152 13H81.8346L81.1441 10.9492ZM78.1021 9.28808H80.5904L79.3395 5.56249L78.1021 9.28808ZM93.3255 13H91.2747V8.73438H87.2757V13H85.2249V3.04688H87.2757V7.08008H91.2747V3.04688H93.3255V13ZM103.504 13H101.453V8.73438H97.4541V13H95.4034V3.04688H97.4541V7.08008H101.453V3.04688H103.504V13ZM107.667 6.63573H109.266C110.438 6.63573 111.365 6.92056 112.049 7.49022C112.737 8.05533 113.081 8.81867 113.081 9.78026C113.081 10.7464 112.739 11.5234 112.055 12.1113C111.376 12.6947 110.472 12.9909 109.342 13H105.602V3.04688H107.667V6.63573ZM115.986 13H113.935V3.04688H115.986V13ZM107.667 8.29004V11.3525H109.301C109.825 11.3525 110.242 11.209 110.552 10.9219C110.866 10.6348 111.023 10.2497 111.023 9.76659C111.023 9.31542 110.873 8.95996 110.572 8.7002C110.276 8.43587 109.868 8.29915 109.348 8.29004H107.667ZM121.755 6.47851L123.622 3.04688H125.98L123.082 7.98242L126.055 13H123.669L121.755 9.51367L119.841 13H117.456L120.429 7.98242L117.531 3.04688H119.889L121.755 6.47851Z"
            fill="#8386A4" fill-opacity="0.2"/>
        <path opacity="0.2"
              d="M45 41H41C38.7909 41 37 42.7909 37 45V127C37 129.209 38.7909 131 41 131H45C47.2091 131 49 129.209 49 127V45C49 42.7909 47.2091 41 45 41Z"
              fill="#8386A4"/>
        <path opacity="0.2"
              d="M82 71H78C75.7909 71 74 72.7909 74 75V127C74 129.209 75.7909 131 78 131H82C84.2091 131 86 129.209 86 127V75C86 72.7909 84.2091 71 82 71Z"
              fill="#8386A4"/>
        <path opacity="0.2"
              d="M158 150C159.105 150 160 149.105 160 148C160 146.895 159.105 146 158 146H2C0.895432 146 0 146.895 0 148C0 149.105 0.895432 150 2 150H158Z"
              fill="#8386A4"/>
        <path opacity="0.2"
              d="M8 66H4C1.79086 66 0 67.7909 0 70V127C0 129.209 1.79086 131 4 131H8C10.2091 131 12 129.209 12 127V70C12 67.7909 10.2091 66 8 66Z"
              fill="#8386A4"/>
        <path opacity="0.2"
              d="M156 61H152C149.791 61 148 62.7909 148 65V127C148 129.209 149.791 131 152 131H156C158.209 131 160 129.209 160 127V65C160 62.7909 158.209 61 156 61Z"
              fill="#8386A4"/>
        <path opacity="0.2"
              d="M119 51H115C112.791 51 111 52.7909 111 55V127C111 129.209 112.791 131 115 131H119C121.209 131 123 129.209 123 127V55C123 52.7909 121.209 51 119 51Z"
              fill="#8386A4"/>
    </svg>
</template>

<script>
export default {
    name: "NoDataIcon"
}
</script>

<style scoped>

</style>
