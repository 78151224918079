<template>
    <Auth title="Группы пользователей">
        <div class="card">
            <div class="card-title">
                <el-row :gutter="15">
                    <el-col :sm="8">
                        <el-input
                            v-model="search"
                            prefix-icon="el-icon-search"
                            placeholder="Поиск" clearable/>
                    </el-col>
                    <el-col :sm="5">

                    </el-col>
                    <el-col :sm="11">
                        <div class="buttonsBlock">
                            <el-button @click="$router.push({name: 'UsersCreate', params: { id: $route.params.id },})"
                                       type="primary" icon="el-icon-plus" size="mini">
                                Добавить группу
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="card-body">
                <el-table
                    v-loading="loading"
                    ref="table"
                    :data="displayData"
                    style="width: 100%">
                    <el-table-column
                        prop="name"
                        label="Имя">
                    </el-table-column>
                    <el-table-column
                        prop="id"
                        label="Кол-во пользователей"
                        sortable>
                        <template v-slot="scope">
                            {{ scope.row.users.length }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="150">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                @click="openDrawer(scope.$index, scope.row)">Редактировать
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="valueLength > 10" layout="prev, pager, next" :total="valueLength"
                               @current-change="setPage">
                </el-pagination>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import { httpService } from '@/services/http.service'

export default {
    name: 'GroupsIndex',
    data() {
        return {
            values: [],
            loading: true,
            search: '',
            filtered: [],
            page: 1,
            pageSize: 10,
            valueLength: 0,
        }
    },
    components: {
        Auth
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.accountGroup.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.groups

            })
        },
        setPage(val) {
            this.page = val
        },
    },
    computed: {
        displayData() {
            if (this.search == null) return this.values
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
}
</script>

<style scoped>

</style>
