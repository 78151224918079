<template>
    <Auth title="Диспетчеризация">
        <div></div>
    </Auth>
</template>

<script>

import Auth from "@/views/layouts/Auth.vue";

export default {
    name: 'DispatchIndex',
    components: {
        Auth

    },
    created() {
        this.$router.push({ name: 'DispatchOrderGroups',  params:{ id: this.$route.params.id }})
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
