<template>
    <div class="content">
        <div class="content__title">
            <span>Миксер не найден</span>
        </div>
        <OrderTermsItem :termsItem="
            {
                message: message,
                status: false,
            }"
            style="color: #AD3028;"
        />
        <div class="content__desc">
            <span>Отредактируйте график работы авто или добавьте авто на день</span>
            <router-link
                class="content__desc__to-schedule"
                :to="{ name: 'DispatchMixers' }"
            >
                Перейти к графику
            </router-link>
        </div>
    </div>
</template>
<script>
import OrderTermsItem from "@/views/dispatch/components/OrderTermsItem.vue";

export default {
    name: "MixerNotFound",
    components: {OrderTermsItem},
    props:['message']
}
</script>
<style scoped lang="scss">
.content{
    &__title{
        color: #393F4A;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    &__desc{
        color: #49515F;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__to-schedule{
            display: flex;
            padding: 6px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #C9CCD0;
            background: #FFF;
            text-decoration: none;
            color: #49515F;
        }
    }
}
</style>
