<template>
    <div class="badge" :class="{dark: type === 'dark'}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Badge",
    props: {
        type: {
            default: 'default',
            type: String
        }
    }
}
</script>

<style scoped>
.badge {
    background: #F74539;
    color: #fff;
    padding: 2px 8px;
    border-radius: 100px;
}
</style>
