<template>
    <Auth :title="title">
        <div class="card">
            <div class="card-body">
                <template v-if="!loading">
                    <AnalyticsMixModule v-if="$route.params.moduleKey === 'mix' && moduleData.indicators"
                                        :module-data="moduleData"/>
                    <div class="no-data-block" v-if="!moduleData.indicators">
                        <NoDataIcon/>
                        <p style="color: #49515f !important;">
                            Идет анализ данных<br/>Будет готово через 5 минут
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import AnalyticsMixModule from "@/views/components/AnalyticsPage/AnalyticsMixModule.vue";
import {httpService} from "@/services/http.service";
import NoDataIcon from "@/views/components/NoDataIcon.vue";

export default {
    name: "AnalyticsModule",
    components: {NoDataIcon, AnalyticsMixModule, Auth},
    data() {
        return {
            loading: true,
            moduleData: null,
            title: 'Аналитика'
        }
    },
    created() {
        this.getAnalytics()
    },
    inject: ['api'],
    methods: {
        async getAnalytics() {
            const response = await httpService().post(this.api.analytics.get, {
                type: 'module',
                id: this.$route.params.moduleId
            })

            const data = response.data

            if (data.success) {
                this.loading = false
                this.loadingButton = false
                this.moduleData = data.data
                this.title = 'Аналитика - ' + data.data.name
            } else {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            }
        }
    }
}
</script>

<style scoped>

</style>
