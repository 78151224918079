<template>
    <h3 class="mb-0"><span class="color-success">+</span> Правила добавления значений</h3>
    <p class="mt-10">Прибавлять значение на складе по следующим правилам:</p>
    <ul class="highUl">
        <li>для грузов: <RelationButtonSelect v-model="rules.ruleAdd.goods" :items="goods" empty-text="любых"/></li>
        <li>от отправителей: <RelationButtonSelect v-model="rules.ruleAdd.senderCompany" :items="companies" empty-text="любых"/></li>
        <li>для получателей: <RelationButtonSelect v-model="rules.ruleAdd.recipientCompany" :items="companies" empty-text="любых"/></li>
        <li>оформлены с типом операции: <RelationButtonSelect v-model="rules.ruleAdd.weighingOperations" :items="operations" empty-text="любым"/></li>
        <li>привезены транспортным номер: <RelationButtonSelect v-model="rules.ruleAdd.weighingVehicles" :items="vehicles" empty-text="любым"/></li>
    </ul>
    <h3 class="mb-0"><span class="color-error">-</span> Правила списывания значений</h3>
    <p class="mt-10">Отнимать значение на складе по следующим правилам:</p>
    <ul class="highUl">
        <li>для грузов: <RelationButtonSelect v-model="rules.ruleRemove.goods" :items="goods" empty-text="любые добавленные грузы в складском модуле"/></li>
        <li>от отправителей: <RelationButtonSelect v-model="rules.ruleRemove.senderCompany" :items="companies" empty-text="любых"/></li>
        <li>для получателей: <RelationButtonSelect v-model="rules.ruleRemove.recipientCompany" :items="companies" empty-text="любых"/></li>
        <li>оформлены с типом операции: <RelationButtonSelect v-model="rules.ruleRemove.weighingOperations" :items="operations" empty-text="любым"/></li>
        <li>привезены транспортным номер: <RelationButtonSelect v-model="rules.ruleRemove.weighingVehicles" :items="vehicles" empty-text="любым"/></li>
    </ul>
</template>

<script>
import RelationButtonSelect from "@/views/components/SettingsPage/RelationsPage/RelationSettings/RelationButtonSelect";
import {httpService} from "@/services/http.service";
export default {
    name: 'WeighingStorehouseSettings',
    components: {RelationButtonSelect},
    props: ['modelValue'],
    inject: ['api'],
    data () {
        return {
            rules: JSON.parse(this.modelValue),
            vehicles: [],
            drivers: [],
            goods: [],
            companies: [],
            operations: [],
        }
    },
    created() {
        this.getWeighingData()
    },
    methods: {
        getWeighingData() {
            httpService().post(this.api.report.getWeighingData, {
                reportKey: this.$route.meta.key,
                moduleId: null
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.operations = data.operations
                this.vehicles = data.vehicles
                this.drivers = data.drivers
                this.goods = data.goods
                this.companies = data.companies
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.rules = this.modelValue
            },
            deep: true,
            immediate: false
        },
        'rules': {
            handler: function () {
                this.$emit('update:modelValue', this.rules)
            },
            deep: true,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
