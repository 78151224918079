import getTimezone from "@/mixins/getTimezone";

export default {
    mixins: [
        getTimezone
    ],
    data() {
        return {
            search: null,
            values: [],
            additional_values: [],
            filtered: [],
            page: 1,
            pageSize: 10,
            pageSizes: [
                10, 30, 50, 100, 200, 350
            ],
            valueLength: 0,
            filterShow: false,
            multipleSelection: []
        }
    },
    methods: {
        setPage(val) {
            // переключает страницу пагинации
            this.page = val
        },
        openFilterBlock() {
            // открывает/закрывает блок с фильтрами
            this.filterShow = !this.filterShow
        },
        resetFilter() {
            // сбрасывает фильтр
            for (let key in this.filter ) {
                this.filter[key].value = null
            }
            this.search = null
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    },
    computed: {
        displayData() {
            return this.filterValues(this.values, this.search, this.filter)
        },
        displayAdditionalData() {
            return this.filterValues(this.additional_values, this.search, this.filter)
        }
    },
}
