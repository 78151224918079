<template>
    <div class="widgetSettings__oneWidget">
        <h4 class="mb-5 mt-0">{{ widgetName }}</h4>
        <p class="mb-5">Модуль: {{ moduleName }}</p>
        <div>
            <el-row gutter="5" style="width: 195px">
                <el-col :span="8">
                    <el-button @click="changeSort(position, index, 'up')" :disabled="checkSortUp()"
                               :icon="icons.ArrowUp"></el-button>
                </el-col>
                <el-col :span="8">
                    <el-button @click="changeSort(position, index, 'down')" :disabled="checkSortDown()"
                               :icon="icons.ArrowDown"></el-button>
                </el-col>
                <el-col :span="8">
                    <el-button @click="deleteWidget(position, index)" :icon="icons.Delete"></el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { ArrowUp, ArrowDown, Delete } from '@element-plus/icons-vue'

export default {
    name: 'WidgetCardSettings',
    props: [
        'modules',
        'widgets',
        'widget',
        'index',
        'length',
        'position'
    ],
    data() {
        return {
            widgetName: null,
            moduleName: null,
            icons: {
                ArrowUp,
                ArrowDown,
                Delete
            }
        }
    },
    methods: {
        deleteWidget(position, index) {
            this.$emit('deleteWidget', { position: position, index: index })
        },
        changeSort(position, index, type) {
            this.$emit('changeSort', { position: position, index: index, type: type })
        },
        checkSortUp() {
            return this.index === 0
        },
        checkSortDown() {
            return (this.length - 1) === this.index
        }
    },
    watch: {
        'widgets': {
            handler: function (widgets) {
                if (widgets.length > 0) this.widgetName = this.widgets.find(el => el.key === this.widget.key).name
            },
            deep: true,
            immediate: true
        },
        'modules': {
            handler: function (modules) {
                if (modules.length > 0) this.moduleName = this.modules.find(el => el.id === this.widget.moduleId).name
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
