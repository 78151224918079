<template>
    <div :class="'ordersHeading ' + 'ordersHeading' + status">
        <span class="neutral800 textSM textBold">{{ statusName }}</span>
        <div :class="'ordersHeadingCount ' + 'ordersHeadingCount' + status">
            <span class="color-white textSM textBold">{{ values.length }}</span>
        </div>
    </div>
    <div :class="'orderGroupItem ' + 'orderGroupItem' + status" v-for="(order, index) in values" :key="index">
        <el-row class="pointer" :gutter="20" @click="$emit('openDetails', order)">
            <el-col :sm="16">

                <div class="neutral300 textXS textRegular orderGroupItemMargin">
                    <template v-if="order.doc">
                        Отгрузка №{{ order.doc }} <span class="primary500" style="margin-left: 12px"
                                                      v-if="order.numberOfThisOrder">{{
                            order.numberOfThisOrder
                        }} из {{ order.countOrders }}</span>
                    </template>
                    <template v-else>
                        Отгрузка отправлена на оборудование <span class="primary500" style="margin-left: 12px"
                                                                v-if="order.numberOfThisOrder">{{
                            order.numberOfThisOrder
                        }} из {{ order.countOrders }}</span>
                    </template>
                    <template v-if="order.status === 'done'"> | {{ getDateFromUnix(order.date, 'HH:mm', order.tz) }}</template>
                </div>
                <div class="neutral800 textMD textMedium">
                    Заказ {{ order.order ? '№' + order.order.id : '' }}
                    <template v-if="order.companyId">для {{ getData(order.companyId, 'company') }}</template>
                </div>
                <div class="neutral500 textSM textRegular orderGroupItemMargin" v-if="order.type === 'delivery'">
                    {{ order.deliveryAddress ?? 'Адрес не указан' }}
                </div>
                <template v-if="status === 'new'">
                    <span class="neutral300 textXS textRegular">{{ getData(order.goodId, 'good') }}
                        <template v-if="order.vehicle">
                         | {{ order.vehicle }}
                    </template>
                        <template v-else-if="order.type === 'take-away'">
                            | Самовывоз
                        </template>
                    </span>
                </template>

            </el-col>
            <el-col :sm="8">
                <div class="neutral300 textXS textRegular text-right" style=" margin-bottom: -16px; "
                     v-if="order.diffPlanFact > 3 && status==='new'">
                    Задержка на {{ order.diffPlanFact }} мин.
                </div>
                <div class="neutral900 textMD textMedium text-right" style="margin-top: 36px" v-if="status === 'new'">
                    {{ formatNumber(order.total, 2) }}
                    <good-unit :unit="getData(order.goodId,'good', 'unit')"/>
                    <template v-if="order.timeline.length > 0">
                        <span>
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Запланированное время загрузки"
                            placement="top"
                        >
        > {{ getDateFromUnix(order.timeline.find(el => el.status === 'loading').date, 'HH:mm', order.timeline.find(el => el.status === 'loading').tz) }}
      </el-tooltip>

                    </span>
                    </template>
                    <template v-else>

                    <span v-if="order.planDateStart">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Запланированное время загрузки"
                            placement="top"
                        >
        > {{ getDateFromUnix(order.planDateStart, 'HH:mm', order.tz) }}
      </el-tooltip>

                    </span>
                    </template>
                </div>
                <div class="text-right" v-if="status === 'work'">
                    <div class="neutral300 textXS textRegular orderGroupItemMargin">
                        <template v-if="order.diffPlanFact > 3">
                            Задержка на {{ order.diffPlanFact }} мин.
                        </template>
                        <template v-else>
                            Доставка по плану
                        </template>
                    </div>
                    <div class="neutral800 textMD textMedium">
                        <template v-if="order.type === 'delivery'">
                            {{ order.vehicle ?? 'Миксер не выбран' }}
                        </template>
                        <template v-else>
                            Самовывоз
                        </template>
                    </div>
                    <div class="neutral500 textSM textRegular orderGroupItemMargin" v-if="order.type === 'delivery'">
                        {{ order.driver ?? 'Водитель не выбран' }}
                    </div>
                </div>
            </el-col>
        </el-row>
        <div v-if="status === 'new' && order.pump" class="warning300 textXS textRegular" style="margin-top: 8px">Нужен
            насос
        </div>
        <div v-if="status === 'work' && order.pump" class="warning300 textXS textRegular orderGroupItemMargin">Нужен
            насос
        </div>
        <el-row :gutter="20" v-if="status === 'work'">
            <el-col class="pointer" :sm="16" @click="$emit('openDetails', order)">
                <div>
                    <span class="neutral900 textMD textMDMedium" style="margin-right: 4px">
                        <template v-if="order.status === 'loading'">
                            <template v-if="order.total !== order.done">
                                {{ formatNumber(order.done, 2) }} из {{ formatNumber(order.total, 2) }}
                            </template>
                            <template v-else>
                                {{ formatNumber(order.total, 2) }}
                            </template>
                        </template>
                        <template v-else>
                            {{ formatNumber(order.total, 2) }}
                        </template>
                            <good-unit
                                :unit="getData(order.goodId, 'good', 'unit')"/>
                    </span>
                    <span class="neutral300 textMD textRegular">{{ getData(order.goodId, 'good') }}</span>
                </div>
            </el-col>
            <el-col :sm="8" v-if="status !== 'done' && order.type === 'delivery'">
                <div @click="getMapInfo(order)" class="pointer primary600 textMD textMDRegular text-right"
                >
                    Показать на карте
                </div>
            </el-col>
        </el-row>
        <!-- v-if="status==='done'" -->
        <el-row v-if="status==='done'">
            <el-col class="pointer" :sm="24" @click="$emit('openDetails', order)">
                <div>
                        <span class="neutral900 textMD textMDMedium" style="margin-right: 4px">
                            <template v-if="order.status === 'loading'">
                                <template v-if="order.total !== order.done">
                                    {{ formatNumber(order.done, 2) }} из {{ formatNumber(order.total, 2) }}
                                </template>
                                <template v-else>
                                    {{ formatNumber(order.total, 2) }}
                                </template>
                            </template>
                            <template v-else>
                                {{ formatNumber(order.total, 2) }}
                            </template>
                             <good-unit
                                 :unit="getData(order.goodId, 'good', 'unit')"/>
                        </span>
                    <span class="neutral300 textMD textRegular">{{ getData(order.goodId, 'good') }}<template
                        v-if="order.vehicle && status === 'done'">
                         | {{ order.vehicle }}
                    </template></span>
                </div>
            </el-col>
        </el-row>

        <template v-if="status === 'work' && order.type === 'delivery'">
            <MixPageOrderTimeline :order="order" v-if="order.statusHistory.length > 0"/>
        </template>
    </div>

    <el-dialog
        v-model="showMap"
        title="Карта"
        :width="mobile ? '100%' : '65%'"
        :destroy-on-close="true"
    >
        <LogisticsInfoMapFull
            :orderId="currentOrder"
            :accountModuleReserveId="accountModuleReserveId"
            :color="true"
            @hideWindow="hideMap"
        />
    </el-dialog>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import GoodUnit from '@/views/components/goodUnit.vue'
import MixPageOrderTimeline from '@/views/components/MixPage/OrdersGroup/MixPageOrderTimeline.vue'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import LogisticsInfoMapFull from "@/views/components/LogisticsPage/Info/LogisticsInfoMapFull.vue";


export default {
    name: "MixPageOrdersGroupOrderCol",
    components: {LogisticsInfoMapFull, MixPageOrderTimeline, GoodUnit},
    props: ['values', 'status', 'statusName', 'mixGoods'],
    mixins: [functionsMixin, mobileCheckMixin],
    data() {
        return {
            showMap: false,
            currentOrder: null,
            accountModuleReserveId: null,
        }
    },
    methods: {
        getMapInfo(order) {
            this.currentOrder = order
            this.accountModuleReserveId = this.$route.params.id
            this.showMap = true
        },
        hideMap() {
            this.showMap = false
        }
    }
}
</script>

<style scoped>

</style>
