<template>
    <div class="textSM textRegular neutral400 mb-20" style="margin-top: -30px">
        Укажите продукцию и адрес, чтобы рассчитать стоимость
    </div>

    <el-form label-position="top">

        <el-row :gutter="30">

            <el-col :sm="10">

                <el-form-item label="Тип доставки">
                    <el-radio-group v-model="form.delivery_type" @change="getInfo()">
                        <el-radio label="delivery">Доставка</el-radio>
                        <el-radio label="take-away">Самовывоз</el-radio>
                    </el-radio-group>
                </el-form-item>

                <FormDadataAddressV2
                    v-if="form.delivery_type==='delivery'"
                    v-model="form.address"
                    label="Адрес доставки"
                    placeholder="Некрасовское, ул.Советская, д.111"
                    @action="getInfo()"
                    :required="true"
                />

                <el-row :gutter="16">
                    <el-col :sm="16">
                        <FormSelect
                            v-model="form.good_id"
                            label="Продукция"
                            placeholder="Не выбрано"
                            :items="mixGoods"
                            :required="true"
                        />
                    </el-col>
                    <el-col :sm="8">

                        <FormNumber
                            v-model="form.total"
                            label="Объем"
                            placeholder="30"
                            precision="2"
                            @focusoutAction="getInfo()"
                            :required="true"
                        />
                    </el-col>
                </el-row>

                <el-row :gutter="16">

                    <el-col :sm="16">

                        <FormDateTimePicker
                            v-model="form.date_shipment"
                            label="Дата доставки"
                            @changeAction="getInfo()"
                            :required="true"
                            :disabled="loading"
                        />

                    </el-col>
                    <el-col :sm="8">

                        <FormInput
                            v-model="form.first_order_time_delivery"
                            label="Время доставки"
                            placeholder="00:00"
                            type="time"
                            :disabled="!form.date_shipment || loading"
                            @focusout="getInfo()"
                        />
                    </el-col>

                </el-row>

            </el-col>

            <el-col :sm="14">

                <div v-if="loading && form.prices.length === 0" class="d-block w-100 text-center pt-30">
                    <ReportLoader/>
                </div>
                <template v-else>

                    <div v-if="form.delivery_type === 'delivery' && form.dist"
                         class="textXS textMedium neutral800 mb-15"
                         style="line-height: 20px;">До объекта: <span class="primary600">{{ form.time }} минут, {{
                            form.dist
                        }} км</span></div>

                    <el-table
                        v-if="form.prices.length > 0"
                        :data="form.prices"
                        style="width: 100%"
                        class="mixRequestPricesTable mb-15"
                        :summary-method="getSummaries"
                        show-summary
                        border>
                        <el-table-column prop="name" label="Показатель"/>
                        <el-table-column prop="price" label="Стоимость единицы">
                            <template v-slot="scope">
                                <div v-if="scope.row.name === 'Продукция'">
                                    {{ form.total }}
                                    <good-unit unit="м3." :padding-unit="true"/>
                                    по {{ form.product_price ? formatNumber(form.product_price) : 0 }}
                                </div>
                                <div v-else>
                                    {{ form.orders.length }} миксер. по
                                    {{ form.delivery_price ? formatNumber(form.delivery_price) : 0 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="priceWithoutNDS" label="Стоимость без НДС">
                            <template v-slot="scope">
                                {{ formatNumber(scope.row.priceWithoutNDS) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="priceWithNDS" label="Стоимость с НДС">
                            <template v-slot="scope">
                                {{ formatNumber(scope.row.priceWithNDS) }}
                            </template>
                        </el-table-column>
                    </el-table>

                    <template v-if="form.orders.length > 0 && form.date_shipment && form.first_order_time_delivery">
                        <p class="textXS textMedium neutral800">Возможное время
                            {{ form.delivery_type === 'delivery' ? 'доставки' : 'отгрузки' }}:</p>
                        <div class="d-flex requestTimeFlex mb-15">
                            <div v-for="(order, index) in form.orders" :key="index"
                                 class="textSM textRegular requestTimeItem">
                                <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="Top Center prompts info"
                                    placement="top"
                                >
                                    <template #content>{{ order.time_load }} - {{ order.time_done }}</template>
                                    <div>
                                        <span class="mr-15 neutral400">
                                            {{ index + 1 }}/{{ form.orders.length }}
                                        </span>
                                        <span class="mr-15 primary500 textMedium">
                                            {{
                                                form.delivery_type === 'delivery' ? order.time_object : order.time_load
                                            }}
                                        </span>
                                        <span class="neutral900">
                                            {{ order.total }} м3.
                                        </span>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>

                    </template>
                </template>
            </el-col>

        </el-row>

        <template v-if="addInfo">

            <hr/>

            <div class="textSM textMedium primary600 mt-15 mb-15">Заполните дополнительную информацию</div>

            <el-row :gutter="20">

                <el-col :sm="24">

                    <el-form-item label="">
                        <el-radio-group v-model="form.individual">
                            <el-radio :label="false">Юр.лицо</el-radio>
                            <el-radio :label="true">Физ.лицо</el-radio>
                        </el-radio-group>
                    </el-form-item>

                </el-col>

                <el-col :sm="12">

                    <FormInput
                        v-model="form.phone"
                        label=""
                        placeholder="Контактный телефон"
                        inputMask="+7 ### ###-##-##"
                        @focusoutAction="getInfo()"
                        :required="true"
                    />

                </el-col>

                <el-col :sm="12">

                    <FormInput
                        v-model="form.buyer_manager_name"
                        :placeholder="'Имя ' + (form.individual ? 'клиента' : 'представителя')"
                        label=""
                        @focusoutAction="getInfo()"
                    />

                </el-col>

                <el-col :sm="12" v-if="showFailComment">

                    <FormSelect
                        v-model="form.fail_reason"
                        label=""
                        placeholder="Причина отказа"
                        :items="failReasons"
                        :required="true"
                    />

                </el-col>

                <el-col :sm="12" v-if="!showFailComment">

                    <FormDateTimePicker
                        v-model="form.date_call"
                        label=""
                        placeholder="Когда позвонить для подтверждения"
                    />

                </el-col>

                <el-col :sm="12" v-if="!form.individual">

                    <FormSelect
                        v-model="form.buyer_company_id"
                        label=""
                        placeholder="Контрагент"
                        new-item="company"
                        :new-item-data="form.buyer_company_id"
                        :disabled="!form.phone || loading"
                        :items="companies"
                    />

                </el-col>

                <el-col :sm="12">

                    <FormSelect
                        v-model="form.payment_method"
                        placeholder="Метод оплаты"
                        :items="paymentMethods"
                    />

                </el-col>

                <el-col :sm="12">

                    <FormSelect
                        v-model="form.source"
                        placeholder="Рекламный источник"
                        :items="sources"
                    />

                </el-col>

                <el-col :sm="24">

                    <FormTextarea
                        v-model="form.comment"
                        label=""
                        placeholder="Комментарий"
                    />

                </el-col>

            </el-row>

        </template>
    </el-form>

    <div class="d-flex"
         :style="(!this.form.good_id || !this.form.total || !this.form.date_shipment) ? 'opacity: 0.2' : 'opacity: 1'">
        <template v-if="!addInfo">
            <button class="ci-btn ci-btn_blue mr-10" @click="doAction('save')">
                Сохранить запрос
            </button>
            <button class="ci-btn ci-btn_light mr-10" @click="doAction('createMixOrder')">
                Создать заказ
            </button>
            <button class="ci-btn ci-btn_default" @click="doAction('fail')">
                Отказ
            </button>
        </template>
        <template v-else>
            <button class="ci-btn ci-btn_blue mr-10" @click="doAction('create')">
                Сохранить запрос
            </button>
            <button v-if="form.id" class="ci-btn ci-btn_light mr-10"
                    @click="$router.push({ name: 'MixOrderGroupEdit', params: { id: $route.params.id, orderId: form.id } })">
                Перевести в работу
            </button>
            <button v-if="!showFailComment" class="ci-btn ci-btn_default" @click="doAction('failAfterCreate')">
                Отказ
            </button>
        </template>
    </div>
</template>

<script>
import FormInput from '@/views/components/Form/FormInput.vue'
import {httpService} from '@/services/http.service'
import FormSelect from '@/views/components/Form/FormSelect.vue'
import FormNumber from '@/views/components/Form/FormNumber.vue'
import FormDadataAddressV2 from "@/views/components/Form/FormDadataAddressV2.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import GoodUnit from "@/views/components/goodUnit.vue";
import functionsMixin from "@/mixins/functionsMixin";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import FormTextarea from "@/views/components/Form/FormTextarea.vue";

export default {
    name: 'MixPageRequestForm',
    components: {
        FormTextarea,
        ReportLoader, GoodUnit, FormDateTimePicker, FormDadataAddressV2, FormNumber, FormSelect, FormInput
    },
    mixins: [functionsMixin],
    props: ['id'],
    inject: ['api'],
    data() {
        return {
            addInfo: false,
            loading: false,
            form: {
                id: null,
                phone: null,
                delivery_type: 'delivery',
                good_id: null,
                total: null,
                address: null,
                date_shipment: null,
                buyer_company_id: null,
                buyer_manager_name: null,
                orders: [],
                dist: 0,
                time: 0,
                product_price: null,
                prices: [],
                delivery_price: null,
                first_order_time_delivery: '09:00',
                date_call: null,
                fail_reason: null,
                individual: false,
                comment: null,
                first_load: true,
                payment_method: null,
                source: null
            },
            action: null,
            mixGoods: [],
            showFailComment: false,
            failReasons: [{
                id: 'priceProduct',
                name: 'Высокая стомость продукции'
            }, {
                id: 'delivery',
                name: 'Условия и стоимость доставки'
            }, {
                id: 'product',
                name: 'Нет необходимой продукции'
            }, {
                id: 'payment',
                name: 'Способ оплаты'
            }, {
                id: 'pricePump',
                name: 'Стоимость насоса'
            }, {
                id: 'dateDelivery',
                name: 'Предложенная дата отгрузки'
            }, {
                id: 'competitor',
                name: 'Разведка конкурентов'
            }, {
                id: 'other',
                name: 'Другое'
            }],
            paymentMethods: [{
                id: 'bankWithVAT',
                name: 'Р/С с НДС'
            }, {
                id: 'bankWithoutVAT',
                name: 'Р/С без НДС'
            }, {
                id: 'cashbox',
                name: 'Через кассу'
            }, {
                id: 'cash',
                name: 'Физ.лицо'
            }, {
                id: 'transfer',
                name: 'Перевод'
            }],
            sources: [{
                id: 'avito',
                name: 'Авито'
            }, {
                id: 'yandexDirect',
                name: 'Яндекс.Директ'
            }, {
                id: 'vk',
                name: 'ВКонтакте'
            }, {
                id: 'site',
                name: 'Сайт компании'
            }, {
                id: 'advice',
                name: 'Посоветовали знакомые'
            }, {
                id: 'outdoor',
                name: 'Наружная реклама'
            }, {
                id: 'maps',
                name: 'Яндекс.Карты'
            }, {
                id: 'other',
                name: 'Другое'
            }]
        }
    },
    created() {
        this.moduleGet()
        this.getInfo()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        }),
    },
    methods: {
        doAction(action) {
            if (action === 'failAfterCreate') {
                // проработать отказ созданного запроса
                this.showFailComment = true
            } else {
                if (!this.form.phone && !this.form.good_id && !this.form.total && !this.form.date_shipment || (action === 'save' || action === 'fail')) {
                    if (!this.form.phone && !this.form.good_id && !this.form.total && !this.form.date_shipment) {
                        this.$message({
                            message: 'Заполните обязательные поля',
                            showClose: true,
                            type: 'error'
                        })
                    } else {
                        if (action === 'save' || action === 'fail') {
                            this.addInfo = true
                            if (action === 'fail') this.showFailComment = true
                            this.action = action
                        }
                    }

                } else {
                    this.loading = true
                    if (this.showFailComment) {
                        this.action = 'fail'
                    }

                    httpService().post(this.api.mix.saveRequest, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'mix',
                        form: this.form,
                        action: this.action,
                        id: this.id
                    }).then((response) => {
                        let data = response.data

                        if (data.success) {
                            this.pullCompanies()
                            if (action === 'createMixOrder') {
                                this.$router.push({
                                    name: 'MixOrderGroupEdit',
                                    params: {id: this.$route.params.id, orderId: data.id}
                                })
                            } else {
                                this.$emit('close')
                            }

                        } else {
                            this.$message({
                                message: data.message,
                                showClose: true,
                                type: 'error'
                            })
                        }
                        this.loading = false
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }
            }
        },
        moduleGet() {
            httpService().post(this.api.mix.getGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.mixGoods = data.values.filter(el => el.actual)
            })
        },
        getInfo() {
            if (!this.loading) {
                this.loading = true
                httpService().post(this.api.mix.getRequestInfo, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'mix',
                    form: this.form,
                    id: this.id
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        if (this.id) this.addInfo = true
                        this.form = data.form
                        this.loading = false
                    }
                })
            }
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 2) {
                    let total = 0
                    this.form.prices.forEach(function (value) {
                        total = total + Number(value.priceWithoutNDS)
                    })
                    sums[index] = this.formatNumber(total)
                    return
                }
                if (index === 3) {
                    let total = 0
                    this.form.prices.forEach(function (value) {
                        total = total + Number(value.priceWithNDS)
                    })
                    sums[index] = this.formatNumber(total)
                    return
                }
            })

            return sums
        },
    },
    watch: {
        'form.good_id': {
            handler: function () {
                this.getInfo()
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
