<template>
    <Auth :title="moduleName" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Сводка" name="info">
                        <LogisticsInfo/>
                    </el-tab-pane>
                    <el-tab-pane label="Маршруты" name="routes">
                        В разработке
                    </el-tab-pane>
                    <el-tab-pane label="Автопарк" name="carPark">
                        В разработке
                    </el-tab-pane>
                    <el-tab-pane label="Водители" name="drivers">
                        В разработке
                    </el-tab-pane>
                    <el-tab-pane label="Проблемы 5" name="problems">
                        В разработке
                    </el-tab-pane>
                    <el-tab-pane label="Штрафы 11" name="fines">
                        В разработке
                    </el-tab-pane>
                </el-tabs>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import {httpService} from "@/services/http.service";
import LogisticsInfo from '@/views/components/LogisticsPage/Info/LogisticsInfo'

export default {
    name: 'LogisticsIndex',
    data() {
        return {
            activeTab: 'info',
            moduleName: null,
            integrations: [],
            relations: [],
        }
    },
    components: {
        LogisticsInfo,
        Auth,
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.logistics.getModule, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'logistics',
            }).then((response) => {
                let data = response.data

                if (data.success)
                this.moduleName = data.module.name
                this.integrations = data.integrations
                this.relations = data.relations
                this.loading = false
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.moduleGet()
                this.activeTab = 'info'
            }
        }
    }
}
</script>

<style scoped>

</style>
