export default {
    data() {
        return {
            documentStatuses: [{
                id: 'not-confirmed',
                name: 'Не подтвержден'
            }, {
                id: 'confirmed',
                name: 'Подтвержден'
            }, {
                id: 'done',
                name: 'Завершен'
            }, {
                id: 'archived',
                name: 'Архивный'
            }]
        }
    },
    getForm(id){
        if(id === 'not-confirmed'){
            return [this.data().documentStatuses.find(item => item.id === 'not-confirmed'),
                    this.data().documentStatuses.find(item => item.id === 'confirmed'),
                    this.data().documentStatuses.find(item => item.id === 'archived')]
        } else if(id === 'confirmed'){
            return [this.data().documentStatuses.find(item => item.id === 'confirmed'),
                    this.data().documentStatuses.find(item => item.id === 'done'),
                    this.data().documentStatuses.find(item => item.id === 'archived')]
        } else if(id === 'done'){
                    return [this.data().documentStatuses.find(item => item.id === 'done'),
                    this.data().documentStatuses.find(item => item.id === 'archived')]
        } else{
            return [this.data().documentStatuses.find(item => item.id === 'not-confirmed'),
                    this.data().documentStatuses.find(item => item.id === 'done'),
                    this.data().documentStatuses.find(item => item.id === 'archived')]
        }
    }
}
