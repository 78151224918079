<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle :title="storeDetails ? getData(storeDetails.good_id, 'good') : 'Подробности'"/>

            <div class="techStoreDetails">
                <p class="techStoreDetailsTotal"><AnimateNumber :count="storeDetails.total"/> {{ getData(storeDetails.good_id, 'good', 'unit') }}</p>
                <p class="color-grey">Текущий остаток</p>
            </div>

            <DrawerTextLineItem
                name="Категория"
                :desc="storeDetails.category_id ? getInfo(storeDetails.category_id, storeCategories) : 'Не выбрана'"
            />

            <DrawerTextLineItem
                name="Артикул"
                :desc="getData(storeDetails.good_id, 'good', 'sku') ?? 'Не указан'"
            />

            <DrawerTextLineItem
                name="Техника"
                :desc="storeDetails.tech_vehicle_id ? getInfo(storeDetails.tech_vehicle_id, vehicles) : 'Не привязана'"
            />

            <el-button
                class="w-100 mt-15"
                @click="changeValueButton=true"
                v-if="!changeValueButton"
            >
                Добавить/списать
            </el-button>

            <el-form v-if="changeValueButton" label-position="top" class="mt-30">

                <FormNumber
                    v-model="form.total"
                    label="Введите количество"
                    placeholder="Н-р: 5"
                />

                <el-button
                    @click="changeStoreTotal('plus')"
                    :disabled="!form.total"
                    :icon="icons.plus"
                    class="w-100 ml-0 mb-5"
                    type="success"
                >
                    Добавить
                </el-button>

                <el-button
                    @click="changeStoreTotal('minus')"
                    :disabled="!form.total"
                    :icon="icons.minus"
                    class="w-100 ml-0"
                    type="danger"
                >
                    Списать
                </el-button>

            </el-form>

            <h4 class="mt-30">История изменений</h4>
            <el-table
                :data="displayData"
                class="w-100"
                v-loading="loading"
            >
                <el-table-column
                    prop="date"
                    label="Дата"
                >
                    <template v-slot="scope">
                        {{ getDateFromUnix(scope.row.date) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="Количество"
                >
                    <template v-slot="scope">
                            <span v-if="scope.row.action === 1" class="typeAddition">+ {{
                                    formatNumber(scope.row.total)
                                }} {{ getData(scope.row.goodId, 'good', 'unit') }}</span>
                        <span v-if="scope.row.action === 2" class="typeSubtraction">- {{
                                formatNumber(scope.row.total)
                            }} {{ getData(scope.row.goodId, 'good', 'unit') }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                :loading="loading"
                @action="setPage"
            />

        </template>
        <template v-slot:footer>
            <DrawerDeleteButton @action="deleteStore()"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import functionsMixin from '@/mixins/functionsMixin'
import DrawerDeleteButton from '@/views/components/Drawer/Buttons/DrawerDeleteButton'
import {httpService} from '@/services/http.service'
import techStoreCategoriesMixin from "@/mixins/techStoreCategoriesMixin";
import DrawerTextLineItem from "@/views/components/Drawer/DrawerTextLineItem";
import tableMixin from "@/mixins/tableMixin";
import FormNumber from "@/views/components/Form/FormNumber";
import iconsMixin from "@/mixins/iconsMixin";
import AnimateNumber from "@/views/components/AnimateNumber";

export default {
    name: 'TechStoreDetails',
    components: {AnimateNumber, FormNumber, DrawerTextLineItem, DrawerDeleteButton, DrawerHeadTitle, DrawerFramework},
    props: ['details', 'vehicles'],
    mixins: [functionsMixin, techStoreCategoriesMixin, tableMixin, iconsMixin],
    inject: ['api'],
    data() {
        return {
            loading: false,
            changeValueButton: false,
            storeDetails: this.details,
            form: {
                action: null,
                total: null
            },
            pageSize: 5,
        }
    },
    created() {
        this.getValues()
    },
    methods: {
        getValues() {
            this.loading = true
            httpService().post(this.api.tech.getTechStoreValues, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                id: this.details.id,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
                this.storeDetails.total = data.total

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        changeStoreTotal(action) {
            this.loading = true
            this.form.action = action
            httpService().post(this.api.tech.changeTechStoreTotal, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'tech',
                id: this.details.id,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.form.action = null
                this.form.total = null
                this.changeValueButton = false
                this.$emit('moduleGet')
                this.getValues()

                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        deleteStore() {
            this.$confirm(
                'Вы действительно хотите удалить запчасть?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.tech.removeTechStore, {
                        id: this.details.id,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'tech',
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.loading = true
                        this.$message({
                            message: 'Запчасть удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('closeDrawer')
                        this.$emit('moduleGet')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
