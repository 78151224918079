<template>
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
        d="M40.6667 2.5C45.5913 2.5 50.4677 3.46997 55.0174 5.35452C59.5671 7.23907 63.7011 10.0013 67.1833 13.4835C70.6655 16.9657 73.4277 21.0997 75.3122 25.6494C77.1968 30.1991 78.1667 35.0754 78.1667 40C78.1667 44.9246 77.1968 49.8009 75.3122 54.3506C73.4277 58.9003 70.6654 63.0343 67.1832 66.5165C63.701 69.9987 59.5671 72.7609 55.0174 74.6455C50.4677 76.53 45.5913 77.5 40.6667 77.5C35.7422 77.5 30.8658 76.53 26.3161 74.6455C21.7664 72.7609 17.6324 69.9987 14.1502 66.5165C10.668 63.0343 7.90581 58.9003 6.02126 54.3506C4.13671 49.8009 3.16675 44.9246 3.16675 40C3.16675 35.0754 4.13672 30.1991 6.02127 25.6494C7.90583 21.0996 10.6681 16.9657 14.1503 13.4835C17.6325 10.0013 21.7664 7.23906 26.3161 5.35451C30.8659 3.46996 35.7422 2.5 40.6668 2.5L40.6667 2.5Z"
        stroke="#EEEEEE" stroke-width="5" stroke-miterlimit="3.99933" stroke-linecap="round"/>
    <path
        d="M35.4747 35.298H36.8327V45H35.4747V40.52H30.7287V45H29.3707V35.298H30.7287V39.344H35.4747V35.298ZM39.0318 47.59H37.7018L42.4478 34.038H43.7498L39.0318 47.59ZM44.1026 45V47.59H42.8146V43.838H43.8366C44.3126 43.2967 44.6766 42.6993 44.9286 42.046C45.1899 41.3833 45.3766 40.688 45.4886 39.96C45.6099 39.2227 45.6799 38.462 45.6986 37.678C45.7266 36.894 45.7452 36.1007 45.7546 35.298H51.2426V43.838H52.4606V47.59H51.1726V45H44.1026ZM47.0846 36.46C47.0566 37.076 47.0239 37.706 46.9866 38.35C46.9586 38.9847 46.8886 39.6193 46.7766 40.254C46.6739 40.8793 46.5199 41.4953 46.3146 42.102C46.1092 42.7087 45.8199 43.2873 45.4466 43.838H49.8846V36.46H47.0846Z"
        fill="#CCCCCC"/>
  </svg>
</template>
<script>
export default {
  name: 'ProgressEmpty',
  props: {
    data: {}
  }
};
</script>
