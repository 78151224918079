<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Подробности"/>

            <DrawerSubTitle
                sub-title="Полученный массив"
            />

            <ul>
                <li v-for="(value, propertyName) in  JSON.parse(data.data)" :key="value">{{ propertyName }}: {{
                        value
                    }}
                </li>
            </ul>

            <DrawerSubTitle
                sub-title="Актуальное значение в системе"
            />

            <div v-if="!details.detailsAccount">
                <p>Значение не найдено</p>
            </div>
            <div v-else>

                <DrawerTextLineItem
                    name="ID аккаунта"
                    :desc="details.detailsAccount"
                />

                <DrawerTextLineItem
                    name="Название компании"
                    :desc="details.detailsCompany"
                />

                <ul v-if="details.detailsValue">
                    <li v-for="(value, propertyName) in  JSON.parse(details.detailsValue)" :key="value">{{
                            propertyName
                        }}: {{ value }}
                    </li>
                </ul>
            </div>

        </template>
    </DrawerFramework>

</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerSubTitle from '@/views/components/Drawer/DrawerSubTitle'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import { httpService } from '@/services/http.service'

export default {
    components: {
        DrawerTextLineItem,
        DrawerSubTitle,
        DrawerHeadTitle, DrawerFramework
    },
    props: [
        'data',
    ],
    name: 'TtsDataDetails',
    data() {
        return {
            loading: true,
            details: {
                detailsAccount: null,
                detailsCompany: null,
                detailsValue: null,
            },
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        functionsMixin
    ],
    created() {
        this.getValue()
    },
    methods: {
        getValue() {
            this.loading = true

            httpService().post(this.api.tts.dataGetOne, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                data: this.data
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.details = data.details
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
