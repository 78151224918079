<template>
    <div></div>
</template>

<script>

export default {
    name: 'ProductionIndex',
    components: {

    },
    data() {
        return {

        }
    },
    created() {
        this.$router.push({name: 'ProductionOrders', params: {id: this.$route.params.id}})
    }
}
</script>

<style scoped>

</style>
