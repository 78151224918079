<template>
    <Auth title="CRM" :tabs="tabs">
        <div class="card">
            <div class="card-body">

                Контрагенты

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import CRMTabs from "@/mixins/tabs/CRMTabs";

export default {
    name: 'CRMCompanies',
    mixins: [CRMTabs],
    data() {
        return {

        }
    },
    components: {
        Auth,
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {

        }
    }
}
</script>

<style scoped>

</style>
