<template>
    <div class="map">
        <YandexMap
            :coords="mapCenter"
            :zoom="mapZoom"
            @onChangeZoom="handleZoom"
        />
    </div>
</template>

<script>
import YandexMap from '@/views/widgets/map/YandexMap';

export default {
    name: "MixOrderGroupDetailsMap",
    props: ['latitude', 'longitude'],
    components: {YandexMap},
    data() {
        return {
            mapZoom: 10,
        }
    },
    computed: {
        mapCenter() {
            if (!this.latitude || !this.longitude) {
                return [55.75165053565251, 37.61758420869977];
            }

            return [parseFloat(this.latitude), parseFloat(this.longitude)];
        },
    },
    methods: {
        handleZoom(value) {
            this.mapZoom = value;
        },
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 220px;
}
</style>
