<template>
    <h2>Изменение значения остатка счета по умолчанию</h2>
    <p>Для изменения остатка счета по умолчанию вам необходимо перейти в настройки модуля. Это можно сделать двумя
        способами:</p>
    <ol>
        <li>Перейдите в необходимый модуль и нажмите на шестеренку возле названия модуля</li>
        <li>Перейдите в настройки > модули > нажмите кнопку "настроить" напротив интересующего вас модуля с типом
            "денежный учет"
        </li>
    </ol>
    <p>В разделе особых настроек вы сможете изменить остаток счета по умолчанию.</p>
    <el-image
        :src="require(`@/assets/faq/CashboxFaqHowEditDefaultAccount-1.png`)"
        fit="contain"
        lazy
    ></el-image>
    <p>Не забудьте нажатать кнопку "Сохранить изменения".</p>
    <p>Общий остаток во всем модуле, который выводится в виджетах на рабочем столе и сводке, складывается из суммы
        остатков всех счетов данного модуля.</p>
</template>

<script>
export default {
    name: "CashboxFaqHowEditDefaultAccount"
}
</script>

<style scoped>

</style>
