<template>
    <Auth title="Документы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disableFilter="true"
                    create-button-label="Создать договор"
                    @moduleGet="moduleGet"
                    @openCreateValueDrawer="openCreateDialog(null)"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-select
                            v-model="filter.status.value"
                            :placeholder="this.filter.status.placeholder"
                            size="large"
                            class="filterSelect"
                            style="width: 180px"
                            @change="moduleGet"
                            clearable>
                            <el-option
                                v-for="item in documentStatuses"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"/>
                        </el-select>
                        <TableIntegrationFilter
                            v-model="filter.integrations.value"
                            @moduleGet="moduleGet"
                            :table="true"
                        />
                        <FormSelectCompanies
                            @click="openCompaniesFilterDialog"
                            @clear="clearCompaniesFilter"
                            :companies="companiesCheckboxesActive" />
                        <ResetFormButton
                            :isVisible="isResetButtonActive"
                            @resetFilter="resetFilters" />
                    </template>
                </TableHeadBlock>

                <MobileTableFramework v-for="(item, index) in values" :key="index">
                    <template v-slot:body>

                        <MobileTableHR/>

                        <MobileTableInfo
                            title="Название"
                            :body="item.name"
                            :no-border="true"
                        />

                        <MobileTableButton
                            title="Редактировать"
                            @action="openDetailsDialog(item.id)"
                        />

                    </template>
                </MobileTableFramework>

                <el-table
                    v-loading="loading"
                    :data="values"
                    v-if="!mobile"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"
                        sortable>
                        <template v-slot="scope">
                            <span class="textSM textMedium neutral200">#{{ formatNumber(scope.row.id) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        prop="name"
                        label="Название"
                        sortable>
                        <template v-slot="scope">
                            <div class="group-orders-status-text group-orders-status-text-small mb-5"
                                 :class="'group-orders-status-text-' + scope.row?.status">
                                {{ getInfo(scope.row?.status, documentStatuses) }}
                            </div>
                            <div class="textSM textMedium neutral900 maxLines3">
                                <Truncate :input-text="scope.row.name + (scope.row.doc ? ' - ' + scope.row.doc : '')"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="120"
                    >
                        <template v-slot="scope">
                            <span class="textSM textRegular neutral400">{{
                                    scope.row.date ? getDateFormDB(scope.row.date, 'date') : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column

                        prop="company_id"
                        label=""
                    >
                     <template #header>
                            <span>Контрагенты</span>
                </template>
                        <template v-slot="scope">
                            <span class="textMedium neutral700 cutOverflow maxLines3 counterparty-row">{{
                                    scope.row.company_id ? scope.row.company?.name : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="total"
                        label="Сумма"
                        width="200"
                    >
                        <template v-slot="scope">
                            <span class="textMedium primary600 cutOverflow">{{
                                    scope.row.total ? formatNumber(scope.row.total) + ' руб.' : '-'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="integration_unit_id"
                        label="Интеграция"
                        width="190">
                        <template v-slot="scope">
                            <template v-if="scope.row.integration_unit_id">
                                <IntegrationLogo :integration-unit-id="scope.row.integration_unit_id"/>
                            </template>
                            <template v-else>
                                <span class="textRegular textSM neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="180">
                        <template v-slot="scope">
                            <div>
                                <el-button
                                    size="small"
                                    @click="openDetailsDialog(scope.row.id)">Подробнее
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="remove(scope.row.id)"
                                    :icon="icons.delete"
                                />
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-dialog
            title=""
            v-model="detailsDialog"
            :width="dialogSize"
            :destroy-on-close="true"
            @close="handleDetailsDialogClose"
        >

            <CRMContractDetails
                :id="selectedContractId"
                @remove="remove"
                @update="moduleGet"
                @edit="openEditDialog"
            />

        </el-dialog>

        <el-dialog
            class="crm-contracts-create-dialog"
            v-model="createDialog"
            :title='details.id ?  `Редактирование договора "${details.name}"` : "Создание договора"'
            :width="mobile ? '100%' : '80%'"
            :destroy-on-close="true"
            @close="handleCreateDialogClose"
        >
            <el-form label-position="top" class="crm-contracts-form">
                <el-row :gutter="15">
                    <el-col :span="12">
                        <FormDateTimePicker
                            class="pointer"
                            :required="true"
                            v-model="details.date"
                            label="Дата"
                            size="default"
                            :customIcon="true"
                        />
                    </el-col>
                    <el-col :span="12">
                          <FormSelect
                              v-model="details.seller_company_id"
                              label="Поставщик"
                              placeholder="Выберите поставщика"
                              company-type="seller"
                              new-item="company"
                              :new-item-data="details.seller_company_id"
                              size="large"
                          />
                    </el-col>
                </el-row>

                <el-row :gutter="15">
                    <el-col :span="12">
                      <FormInput
                        v-model="details.doc"
                        :required="true"
                        label="Номер договора"
                        placeholder="Введите номер"
                      />
                    </el-col>
                    <el-col :span="12">
                          <FormSelect
                              class="pointer"
                              v-model="details.manager_id"
                              label="Менеджер"
                              placeholder="Выберите менеджера"
                              size="large"
                              :items="users"
                              :required="true"
                          />
                    </el-col>
                </el-row>

                <el-row :gutter="15">
                    <el-col :span="12">
                        <FormInput
                            v-model="details.name"
                            label="Наименование"
                            placeholder="Введите название"
                            :required="true"
                        />
                    </el-col>
                    <el-col :span="12">
                        <FormDateTimePicker
                            class="pointer"
                            v-model="details.date_end"
                            label="Дата окончания"
                            size="default"
                            :customIcon="true"
                            :required="true"
                        />
                    </el-col>
                </el-row>

                <el-row :gutter="15">
                    <el-col :span="12">
                          <FormSelect
                            v-model="details.company_id"
                            label="Контрагент"
                            placeholder="Выберите контрагента"
                            new-item="company"
                            :new-item-data="details.company_id"
                            size="large"
                            :required="true"
                          />
                    </el-col>
                </el-row>
            </el-form>
            <el-tabs v-model="tab">
                <el-tab-pane label="Продукция" name="document_goods" />
                <el-tab-pane :disabled="!details.company_id" label="Объекты" name="addresses" />
                <el-tab-pane label="Доставка" name="mix" />
                <el-tab-pane label="Простой" name="downtime" />
                <el-tab-pane label="Насос" name="pump" />
            </el-tabs>
            <el-form label-position="top" class="crm-contracts-form">
                    <CRMTabsTable
                        :tab="tab"
                        :data="itemsForSelect"
                        :vat-in-price='details.vat_in_price'
                        @update:vatInPrice="(val) => details.vat_in_price = val"
                        @add-new-row="handleOpenAddRowDialog"
                        @delete-row="deleteRow"
                    />
            </el-form>
            <hr/>

            <div class="d-flex">
                <el-button class="crm-contracts-form__save-btn" @click="create()" :loading="loadingButton" type="primary" size="large">Сохранить</el-button>
                <CRMContractsAddRowButton
                    v-if="itemsForSelect.length"
                    @add-new-row="handleOpenAddRowDialog" :tab="tab" />
                <el-button
                    class="crm-contracts-form__delete-btn"
                    v-if="details.id"
                    @click="remove(details.id)"
                    :loading="loadingButton" size="large"
                    :icon="icons.circleClose"
                >
                    Удалить договор
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            v-model="selectDialog"
            :title="selectDialogTitle"
            :width="dialogSize"
            :destroy-on-close="true"
        >
            <SelectGoodModal
                v-if="tab === 'document_goods'"
                @add-new-row="addNewRow"
                :added-goods="details.document_goods"
            />

            <SelectTariffModal
                v-else-if="tab === 'downtime' || tab === 'pump' || tab === 'mix'"
                @add-new-row="addNewRow"
                :added-tariffs="details.service_tariffs"
                :type="tab"
            />

            <SelectAddressModal
                v-else-if="tab === 'addresses'"
                @add-new-row="addNewRow"
                :added-addresses="details.addresses"
                :company-id="details.company_id"
                :company-name="companies.find((el) => el.id === details.company_id).name"
            />
        </el-dialog>

        <ModalWithScroll
            @update-data="handleUpdate"
            @close="() => this.blockScrollWhileModalOpened(false)"
            :companies-model="companiesCheckboxesActive"
            v-model="companiesFilterDialog"
        />
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import tableMixin from '@/mixins/tableMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableHR from '@/views/components/Mobile/Table/MobileTableHR'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import functionsMixin from "@/mixins/functionsMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import iconsMixin from "@/mixins/iconsMixin";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import documentStatusMixin from "@/mixins/documentStatusMixin";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import Truncate from "@/views/components/Truncate.vue";
import CRMContractDetails from "@/views/crm/components/CRMContractDetails.vue";
import { mapState } from "pinia"
import { useCommonStore } from "@/store/common"
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";
import ModalWithScroll from "@/views/components/ModalWithScroll";
import ResetFormButton from '@/views/components/Form/ResetFormButton'
import FormSelectCompanies from '@/views/components/Form/FormSelectCompanies';
import CRMTabsTable from "@/views/crm/components/CRMTabsTable";
import CRMContractsAddRowButton from "@/views/crm/components/CRMContractsAddRowButton";
import SelectGoodModal from '@/views/components/SelectGoodModal.vue'
import SelectTariffModal from '@/views/components/SelectTariffModal.vue';
import SelectAddressModal from '@/views/components/SelectAddressModal.vue';

export default {
    name: 'CRMContracts',
    data() {
        return {
            loading: true,
            loadingButton: false,
            detailsDialog: false,
            valueLength: 0,
            tab: 'document_goods',
            createTariffDialog: false,
            selectDialog: false,
            details: {
                id: null,
                doc: null,
                name: null,
                company_id: null,
                date: null,
                goods: [],
                document_goods: [],
                service_tariffs: [], // id тарифов для обновления на сервере
                addresses: [], // id объектов для обновления на сервере
                type: 'contract',
                status: null,
                manager_id: null,
                date_end: null,
                vat_in_price: false,
            },
            filtered: [],
            filter: {
                companies: {
                    placeholder: 'Контрагент',
                    value: null,
                },
                type: {
                    placeholder: 'Тип документа',
                    value: null,
                },
                status: {
                    placeholder: 'Статус',
                    value: null,

                },
                integrations: {
                    placeholder: 'Интеграция',
                    value: null,
                },
            },
            search: '',
            createDialog: false,
            full_price: 0,
            companiesFilterDialog: false,
            selectedContractId: null,
            companiesCheckboxesActive: [], // все выбранные контрагенты
            users: null,
        }
    },
    components: {
        CRMContractDetails,
        Truncate,
        IntegrationLogo,
        FormDateTimePicker,
        FormSelect,
        FormInput,
        TablePagination,
        MobileTableButton,
        MobileTableInfo,
        MobileTableHR,
        MobileTableFramework,
        TableHeadBlock,
        Auth,
        TableIntegrationFilter,
        ModalWithScroll,
        ResetFormButton,
        FormSelectCompanies,
        CRMTabsTable,
        CRMContractsAddRowButton,
        SelectGoodModal,
        SelectTariffModal,
        SelectAddressModal
    },
    mixins: [
        mobileCheckMixin, tableMixin, functionsMixin, iconsMixin, CRMTabs, documentStatusMixin
    ],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet();
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: "getCompanies",
            user: 'getUser'
        }),
        isResetButtonActive() {
            return this.filter.companies.value.length
            || this.filter.type.value
            || this.filter.status.value
            || this.filter.integrations.value
            || this.companiesCheckboxesActive.length
            || !!this.search
        },
        itemsForSelect() {
            switch(this.tab) {
                case 'document_goods':
                    return this.details.document_goods
                case 'downtime':
                case 'pump':
                case 'mix':
                    return this.details.service_tariffs.filter((el) => el.type === this.tab)
                case 'addresses':
                    return this.details.addresses
                default:
                    return []
            }
        },
        selectDialogTitle() {
            const titles = {
                document_goods: 'Выберите продукцию',
                downtime: 'Выберите тариф на простой',
                pump: 'Выберите тариф на насос',
                mix: 'Выберите тариф на доставку',
                addresses: 'Выберите объект',
            }
            return titles[this.tab]
        }
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.document.getAll, {
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                companies: this.filter.companies.value,
                status: this.filter.status.value,
                integrationUnitId: this.filter.integrations.value,
                type: 'contract'
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                    this.loadingButton = false
                    this.values = data.items.data
                    this.valueLength = data.items.total
                }
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })

            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success) {
                    this.users = data.users.filter(user => ['Владелец', 'Администратор', 'Пользователь', 'Сотрудник'].includes(user.role))
                    if (this.user && !this.details.manager_id) this.details.manager_id = this.user.id
                }
            })
        },
        addNewRow(item) {
            switch(this.tab) {
                case 'document_goods':
                    this.pushGoods(item)
                    break
                case 'addresses':
                    this.pushAddresses(item)
                    break
                case 'downtime':
                case 'pump':
                case 'mix':
                    this.pushTariffs(item)
                    break
            }
            this.selectDialog = false
        },
        deleteRow(item) {
            const formItem = this.tab === 'document_goods'
                ? this.details.document_goods
                : this.tab === 'addresses'
                ? this.details.addresses
                : this.details.service_tariffs
            var idx = formItem.indexOf(item)
            formItem.splice(idx, 1)
        },
        create() {
            this.loadingButton = true
            const isValid = this.validateCreateForm();
            if (!isValid) {
                this.loadingButton = false
                return
            }

            if(!this.details.manager_id) this.details.manager_id = null
            httpService().post(this.api.document.create, {
                details: {
                    ...this.details,
                    service_tariffs: this.details.service_tariffs.map(el => el.id),
                    addresses: this.details.addresses.map(el => el.id)
                }
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Информация сохранена',
                        showClose: true,
                        type: 'success'
                    })
                    this.detailsDialog = false
                    this.tab = 'document_goods',
                    this.resetForm();
                    this.loadingButton = false
                    this.createDialog = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingButton = false
            })
        },
        remove(id) {
            this.$confirm('Вы действительно удалить документ?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                this.loading = true

                httpService().post(this.api.document.remove + '/' + id).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.detailsDialog = false
                        this.$message({
                            message: 'Документ удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.moduleGet()
                        this.selectedContractId = null
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                    this.loading = false
                })
                this.createDialog = false
                this.detailsDialog = false
            })
        },
        openDetailsDialog(id) {
            this.detailsDialog = false
            this.tab = 'document_goods',
            this.resetForm();
            this.loadingButton = false
            this.createDialog = false
            this.selectedContractId = id
            this.detailsDialog = true
        },
        openEditDialog(details){
            if (details) {
                this.details = details
                if (this.details) {
                    this.detailsDialog = false
                    this.createDialog = true
                }
            }
        },
        setPage(val) {
            // переключает страницу пагинации
            this.page = val
        },
        openCreateDialog() {
            this.tab = 'document_goods'
            this.resetForm();
            this.createDialog = true
        },
        // нужно будет со всех полей пересчитать
        changeFullPrice() {
            if (this.details?.document_goods?.length > 0) {
                this.details.document_goods.forEach((el) => {
                    if (el.price && el.total) {
                        let fullPrice = 0
                        fullPrice = Math.abs(el.price)
                        if (!this.details.vat_in_price) {
                            fullPrice = parseFloat(Math.abs(el.price) + (Math.abs(el.price) * (Math.abs(el.vat) / 100)));
                        }
                        el.full_price = Math.round((fullPrice * Math.abs(el.total)) * 100) / 100
                    }
                })
            }
            // this.details.total = this.details.document_goods.reduce((val, prevVal) => val + prevVal.full_price, 0)
        },
        clearCompaniesFilter() {
            this.companiesCheckboxesActive = [];
        },
        openCompaniesFilterDialog() {
            this.companiesFilterDialog = true;
            this.blockScrollWhileModalOpened(true)
        },
        handleUpdate(data) {
            this.companiesCheckboxesActive = data;
        },
        resetFilters() {
            this.filter.companies.value = null;
            this.filter.type.value = null;
            this.filter.status.value = null;
            this.filter.integrations.value = null;
            this.companiesFilterDialog = false;
            this.companiesCheckboxesActive = [];
            this.search = '';
        },
        resetForm() {
            this.details = {
                id: null,
                doc: null,
                name: null,
                company: null,
                company_id: null,
                date: null,
                integration_unit_id: null,
                document_goods: [],
                service_tariffs: [],
                addresses: [],
                type: 'contract',
                manager_id: this.user.id,
                date_end: null,
                status: 'not-confirmed',
                vat_in_price: false
            }
        },
        pushTariffs(tariff) {
            this.details.service_tariffs.push(tariff)
        },
        pushAddresses(address) {
            this.details.addresses.push(address)
        },
        pushGoods(good) {
            this.details.document_goods.push({
                good_id: good.id,
                name: good.name,
                total: null,
                price: null,
                vat: null,
                full_price: null,
            })
        },
        handleDialogClose() {
            this.loadingButton = false
            this.resetForm();
            this.moduleGet()
        },
        handleDetailsDialogClose() {
            this.detailsDialog = false
        },
        handleCreateDialogClose() {
            this.createDialog = false
            this.selectedContractId = null
            this.tab = 'document_goods',
            this.handleDialogClose()
        },
        handleOpenAddRowDialog() {
            this.selectDialog = true
            this.blockScrollWhileModalOpened(true)
        },
        validateCreateForm() {
            const validationMessages = [];
            if(!this.details.name) validationMessages.push('Укажите название документа')
            if(!this.details.company_id) validationMessages.push('Укажите контрагента')
            if(!this.details.date_end) validationMessages.push('Укажите дату окончания')
            if(!this.details.manager_id) validationMessages.push('Укажите менеджера')
            if(validationMessages.length) {
                validationMessages.forEach(message => {
                    this.$message({
                        message: message,
                        showClose: true,
                        type: 'error'
                    })
                })
                this.loadingButton = false
            }
            return !validationMessages.length
        },
    },
    watch: {
        'details.document_goods': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        'details.company_id': {
            handler: function (val, prevVal) {
                if(!val) {
                    this.details.addresses = []
                    if(this.tab === 'addresses') this.tab = 'document_goods'
                } else {
                    if((val && prevVal) && val !== prevVal) {
                        this.details.addresses = []
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'details.vat_in_price': {
            handler: function () {
                this.changeFullPrice()
            },
            deep: true,
            immediate: true
        },
        companiesCheckboxesActive: {
            handler: function () {
                this.filter.companies.value = this.companiesCheckboxesActive.length ?
                this.companiesCheckboxesActive.map((el) => el.id) : []
                this.moduleGet();
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            this.moduleGet()
        },
        page() {
            this.moduleGet()
        },
        search() {
            this.moduleGet()
        },
        createDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val)
            },
        },
        detailsDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val)
            },
        },
        companiesFilterDialog: {
            handler: function (val) {
                this.blockScrollWhileModalOpened(val)
            },
        }
    }
}
</script>

<style scoped>
.integrationLogos svg {
    height: 20px;
    position: relative;
    top: 3px;
}

.counterparty-row {
    line-height: 1.2;
}
</style>
