<template>
    <Auth :title="title" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <DispatchPostsTable v-if="this.$route.name === 'DispatchPosts'"/>
                <DispatchMixersTable v-else
                    :type="type"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import DispatchMixersTable from "@/views/dispatch/DispatchMixersTable.vue";
import DispatchPostsTable from "@/views/dispatch/DispatchPostsTable.vue";

export default {
    name: 'DispatchMixers',
    mixins: [dispatchTabs],
    data() {
        return {
            title: this.$route.meta.title,
            type: this.$route.name === 'DispatchMixers' ? 'mix' : 'pump'
        }
    },
    components: {
        DispatchMixersTable,
        DispatchPostsTable,
        Auth,
    },
    created() {

    },
    watch: {
        $route() {
            this.title = this.$route.meta.title
            this.type = this.$route.name === 'DispatchMixers' ? 'mix' : 'pump'
        }
    },
}
</script>

<style scoped>

</style>
