<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        disable-filter="true"
        disable-create-button="true"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
    />

    <el-table
        v-loading="loading"
        :data="displayData"
        ref="table"
        empty-text="Нет информации"
        @sortChange="sortChanged"
        class="mt-15 w-100">
        <el-table-column
            prop="name"
            label="Название"
            sortable
        />
        <el-table-column
            prop="product"
            label="Продукт"
            width="200px"
            sortable
        />
        <el-table-column
            prop="total"
            label="Произведено"
            width="200px"
            sortable
        >
            <template v-slot="scope">
                <el-button @click="openObjectDetailsModal(scope.row)" type="text" size="mini" style="padding-left: 0">
                    {{ scope.row.total }} ед.
                </el-button>
            </template>
        </el-table-column>
        <el-table-column
            prop="alreadyUse"
            label="Используется?"
            width="140px"
            sortable
        >
            <template v-slot="scope">
                {{ scope.row.alreadyUse ? 'Да' : 'Нет' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="timezone"
            label="Часовой пояс"
            width="200px"
        >
            <template v-slot="scope">
                <span v-if="scope.row.timezone !== 'no'">
                    {{ getInfo(scope.row.timezone, timezones) }}
                </span>
                <span v-else>

                    -

                </span>
            </template>
        </el-table-column>
    </el-table>


    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <el-dialog
        v-model="objectDetailsModal"
        title="Произведенная продукция"
        :width="drawerSize"
        :destroy-on-close="true"
    >
        <div class="d-flex-full" style="margin: 0 -5px;">
            <div class="mixOrderDetailsBlock w-100" style="flex: 1 1 auto; margin: 0 5px;">

                <p class="mixOrderDetailsBlockTitle">Произведено</p>

                <h3>{{ formatNumber(selectedObject.total) }} ед.</h3>

            </div>
            <div class="mixOrderDetailsBlock w-100" style="flex: 1 1 auto; margin: 0 5px;">

                <p class="mixOrderDetailsBlockTitle">Период</p>

                <h3>{{ selectedObject.date ?? '-' }}</h3>

            </div>
        </div>

        <el-table
            :data="selectedObject.goods"
            ref="table"
            empty-text="Нет информации"
            class="mt-15 w-100">

            <el-table-column
                prop="good"
                label="Продукция"
                sortable
            />

            <el-table-column
                prop="value"
                label="Кол-во"
                sortable
            />

        </el-table>
    </el-dialog>
</template>

<script>
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import TablePagination from '@/views/components/Table/TablePagination'
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import timezonesMixin from "@/mixins/timezonesMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'TtsObjects',
    components: {TablePagination, TableHeadBlock},
    mixins: [tableMixin, functionsMixin, timezonesMixin, mobileCheckMixin],
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    data() {
        return {
            loading: true,
            objectDetailsModal: false,
            orderBy: 'id',
            direction: 'descending',
            selectedObject: {
                goods: [],
                date: null,
                total: 0
            }
        }
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.tts.objects, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderBy: this.orderBy,
                direction: this.direction,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        toogleExpand(row) {
            let $table = this.$refs.table
            $table.toggleRowExpansion(row)
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        getGoodsSum(goods) {
            let sum = 0
            let arr = []

            const obj = JSON.parse(goods)
            for (var key in obj) {

                arr.push({
                    value: obj[key],
                    good: key
                })

                sum += obj[key]
            }
            return {
                total: Math.round(sum),
                goods: arr
            }
        },
        openObjectDetailsModal(object) {
            let goodsData = this.getGoodsSum(object.goods)
            this.selectedObject = {
                goods: goodsData.goods,
                date: object.dateAnalytics,
                total: goodsData.total
            }
            this.objectDetailsModal = true
        },
        sortChanged({prop, order}) {
            this.orderBy = prop;
            this.direction = order;
            this.moduleGet();
        },
    },
}
</script>

<style scoped>

</style>
