<template>
    <div v-if="step === 2">
        <FormRadioButtonGroup
            v-model="form.id"
            valueKey="id"
            titleKey="company_name"
            descriptionKey="inn"
            :options="bankRequisites"
            v-slot="item"
        >
            <span v-if="item.option.inn">ИНН </span>
        </FormRadioButtonGroup>

        <div class="add-new-company-form" v-if="form.id == -1 || !companyHasBankRequisites">
            <div class="form-title">
                Укажите {{ form.id == -1 ? 'ИНН компании и ' : '' }}банковские реквизиты:
            </div>
            <div class="form-item" v-if="form.id === -1">
                <div class="form-item-label">ИНН</div>
                <FormSelect
                    v-model="form.inn"
                    placeholder="Например, 0000000000"
                    :items="innItems"
                    :label-key="'name'"
                    :value-key="'key'"
                    ref="innSelector"
                    :is-remote="true"
                    :remote-method="findInn"
                    :popper-class="{'inn-selector-popper': true, 'inn-selector-popper__hide': Object.entries(innItems).length === 0}"
                />
                <div class="form-item-message">
                    Мы подгрузим всю остальную информацию самостоятельно
                </div>
            </div>

            <div
                v-if="(!selectedRequisite.bank_name && form.id > 0) || form.id === -1"
                class="form-item"
            >
                <div class="form-item-label">Название банка</div>
                <FormInput v-model="form.bank_name" placeholder="Cбербанк"/>
            </div>

            <div
                class="form-item"
                v-if="(!selectedRequisite.bic && form.id > 0) || form.id === -1"
            >
                <div class="form-item-label">БИК</div>
                <FormInput v-model="form.bic" inputMask="#########" placeholder="000000000"/>
            </div>

            <div
                class="form-item"
                v-if="(!selectedRequisite.bank_account_number && form.id > 0) || form.id === -1"
            >
                <div class="form-item-label">Расчетный счет</div>
                <FormInput v-model="form.bank_account_number" inputMask="####################"
                           placeholder="00000000000000000000"/>
            </div>
        </div>
    </div>

</template>

<script>
import FormRadioButtonGroup from "@/views/components/Form/FormRadioButtonGroup.vue";
import {mapActions, mapState} from "pinia";
import {useCommonStore} from "@/store/common";

import functionsMixin from "@/mixins/functionsMixin";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import {httpService} from "@/services/http.service";
import {authService} from "@/services/auth.service";

export default {
    name: "PaymentModalInvoiceMethod",
    props: ['step'],
    components: {FormInput, FormSelect, FormRadioButtonGroup},
    data() {
        return {
            form: {
                inn: null,
                bank_name: null,
                bic: null,
                bank_account_number: null,
                id: null
            },
            invoiceCreatedSuccess: true,
            invoiceUrl: null,
            selectedRequisite: {
                bank_name: null,
                bank_account_number: null,
                bic: null,
            },
            companyHasBankRequisites: true,
            innItems: [],
            root: document.querySelector(":root"),
            errorDescription: 'Что-то пошло не так, и мы не смогли сгенерировать для вас счет. ' +
                'Пожалуйста, попробуйте сформировать его еще раз. ' +
                'Если ошибка повторится, свяжитесь со службой поддержки по номеру телефона +7 999 999-99-99 ',
        }
    },
    mixins: [functionsMixin],
    inject: ['api'],
    watch: {
        'form.id': {
            handler: function (companyId) {
                if (companyId !== -1) {
                    this.userBankRequisites.map(item => {
                        if (item.id === companyId) {
                            this.selectedRequisite = {...item};
                            this.form.inn = item.inn;
                            this.form.bank_name = item.bank_name;
                            this.form.bank_account_number = item.bank_account_number;
                            this.form.bic = item.bic;
                            this.companyHasBankRequisites = item.bic && item.bank_account_number && item.bank_name
                        }
                    });
                } else {
                    this.selectedRequisite.bank_name = null;
                    this.selectedRequisite.bic = null;
                    this.selectedRequisite.bank_account_number = null;
                    this.form.inn = '';
                    this.form.bank_name = '';
                    this.form.bank_account_number = '';
                    this.form.bic = '';
                    this.companyHasBankRequisites = false
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.form.id = this.userBankRequisites.length ? this.userBankRequisites[0].id : -1;
        this.$emit('setHeaderDescription', 'Выберите юридическое лицо, которое будет производить оплату');
    },
    computed: {
        ...mapState(useCommonStore, {
            userBankRequisites: 'getBankRequisites'
        }),
        bankRequisites() {
            let bankRequisites = [...this.userBankRequisites];

            bankRequisites.push({id: -1, company_name: 'Указать новое юридическое лицо', inn: null})

            return bankRequisites;
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setBankRequisites',
            'setPaymentModalModalStatus',
        ]),
        pay(data) {
            this.invoiceUrl = null;
            this.$emit('setLoading', true);
            httpService().post(this.api.payment.pay, {
                ...this.form,
                ...data
            }).then(response => {
                this.setBankRequisites(response.data.requisites);
                let success = response.data.success;
                this.$emit('setStep', this.step + 1);
                this.$emit('setPayStatus', success);
                this.$emit('setButtonLabel', success ? 'Скачать счет' : 'Попробовать еще раз');
                this.$emit('setHeaderTitle', success ? 'Счет успешно сформирован!' : 'Не удалось сформировать счет');
                this.$emit('setHeaderDescription', success ? 'Все готово! Вы можете скачать счет к себе на устройство' : this.errorDescription);
                authService().getMe();
                this.$emit('setLoading', false);
                if (success) {
                    this.invoiceUrl = response.data.url;
                }
            }).catch((error) => {
                this.$emit('setLoading', false);
                this.$message({
                    message: error.response.data.message,
                    type: 'error'
                })
                authService().getMe();
            })
        },

        next() {
            if (this.step === 3) {
                if (this.invoiceUrl) {
                    window.open(this.invoiceUrl, '_blank').focus();
                } else {
                    this.$emit('setStep', 2);
                    this.$emit('setButtonLabel', 'Сформировать счет');
                    this.$emit('setHeaderTitle', 'Пополнение баланса');
                    this.$emit('setHeaderDescription', 'Выберите юридическое лицо, которое будет производить оплату');
                }
            }
        },
    }
}
</script>

<style scoped>
.add-new-company-form {
    margin: 24px 0;
}

.form-title {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #393F4A;
}

.form-item .el-form-item {
    margin: 4px 0;
}

.form-item {
    margin: 12px 0;
}

.form-item-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #393F4A;
}

.form-item-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #49515F;
}
</style>
