<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle title="Создание пользователя"/>
            <el-form
                ref="form"
                v-loading="loading"
                :model="createUserForm"
                size="small"
                label-position="top"
            >
                <FormInput
                    v-model="createUserForm.name"
                    label="Имя"
                    :required="true"
                    placeholder="Имя"
                />

                <FormInput
                    v-model="createUserForm.email"
                    label="E-mail"
                    :required="true"
                    placeholder="E-mail"
                />

                <FormInput
                    v-model="createUserForm.phone"
                    label="Контактный телефон"
                    :required="true"
                    placeholder="Например: +7 999 999-99-99"
                    inputMask="+7 ### ###-##-##"
                />

                <FormSelect
                    v-model="createUserForm.inn"
                    label="ИНН"
                    placeholder="ИНН"
                    :items="innItems"
                    :label-key="'name'"
                    :value-key="'key'"
                    ref="innSelector"
                    :loading="innLoading"
                    :is-remote="true"
                    :required="true"
                    :remote-method="findInn"
                    :popper-class="{'inn-selector-popper': true, 'inn-selector-popper__hide': Object.entries(innItems).length === 0}"
                />

                <FormInput
                    v-model="createUserForm.company"
                    :required="true"
                    label="Компания"
                    placeholder="Компания"
                />

                <FormSelect
                    v-model="createUserForm.timezone"
                    label="Часовой пояс"
                    placeholder="Выберите часовой пояс"
                    :required="true"
                    :items="timezones"
                />

                <FormSelect
                    v-model="createUserForm.status"
                    label="Статус"
                    placeholder="Статус"
                    :items="statuses"
                />

                <FormInput
                    v-model="createUserForm.partner"
                    label="Партнер"
                    placeholder="Партнер"
                />

                <FormSelect
                    v-model="createUserForm.integrationKey"
                    label="Интеграция"
                    placeholder="Интеграция"
                    :items="integrations"
                    :label-key="'name'"
                    :value-key="'key'"
                />

                <FormInput
                    v-model="createUserForm.objectId"
                    label="ID Объекта"
                    placeholder="ID Объекта"
                />

                <FormInput
                    v-model="createUserForm.password"
                    label="Введите пароль:"
                    placeholder="Введите пароль"
                    :required="true"
                    type="password"
                />
            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton
                :loading="loading"
                title="Создать пользователя"
                @action="createUser"
            />
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from "@/views/components/Drawer/DrawerFramework.vue";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle.vue";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import {httpService} from "@/services/http.service";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import timezonesMixin from "@/mixins/timezonesMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "GodModeUserCreateDrawer",
    data: () => ({
        createUserForm: {
            name: null,
            email: null,
            phone: null,
            company: null,
            inn: null,
            timezone: null,
            status: "wait",
            partner: null,
            integrationKey: null,
            objectId: null,
            password: null,
        },
        loading: false,
        innLoading: false,
        integrations: null,
        innItems: [],
        root: document.querySelector(":root"),
        innCompanyNamesPerInnValue: {},
        source: null,
        statuses: [
            {
                id: "active",
                name: "Активный",
            },
            {
                id: "wait",
                name: "Модерация",
            },
        ],
    }),
    components: {
        FormSelect,
        FormInput,
        DrawerCreateButton,
        DrawerHeadTitle,
        DrawerFramework,
    },
    inject: ["api"],
    mixins: [timezonesMixin, functionsMixin],
    watch: {
        "createUserForm.inn"(newValue) {
            this.createUserForm.company = this.innCompanyNamesPerInnValue[newValue];
        },
    },
    created() {
        httpService()
            .post(this.api.settings.getAllIntegrations)
            .then((response) => {
                this.integrations = response.data.integrations;
            });
    },
    methods: {
        createUser() {
            this.loading = true;
            httpService()
                .post(this.api.account.registerAccount, {
                    form: this.createUserForm,
                })
                .then((response) => {
                    this.$emit("closeDrawer");
                    this.loading = false;
                    this.$message({
                        message: response.data.message,
                        showClose: true,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: "error",
                    });
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
.inn-selector-popper {
    max-width: var(--inn-selector-max-width);
}

.inn-selector-popper__hide {
    display: none !important;
}
</style>
