<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавление/списание"/>

            <el-form ref="form" :model="createGoodForm" size="small" label-position="top">

                <FormSelect
                    v-model="createGoodForm.good_id"
                    label="Продукция"
                    placeholder="Выберите продукцию"
                    new-item="good"
                />

                <FormSelect
                    v-model="createGoodForm.unit_id"
                    label="Склад"
                    placeholder="Выберите склад"
                    :items="units"
                />

                <FormSelect
                    v-model="createGoodForm.action_id"
                    label="Тип операции"
                    placeholder="Выберите тип операции"
                    :items="formTypes"
                />

                <FormNumber
                    v-model="createGoodForm.value"
                    label="Количество"
                    placeholder="Например, 9000"
                />

            </el-form>

        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Добавить значение" @action="doCreateGood"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import FormSelect from '@/views/components/Form/FormSelect'
import FormNumber from '@/views/components/Form/FormNumber'
import { httpService } from '@/services/http.service'

export default {
    name: 'StorehouseCreateGood',
    components: { FormNumber, FormSelect, DrawerCreateButton, DrawerHeadTitle, DrawerFramework },
    props: [
        'units',
        'formTypes',
        'goodId',
        'unitId'
    ],
    data() {
        return {
            loading: false,
            createGoodForm: {
                good_id: this.goodId ?? null,
                unit_id: this.unitId ?? null,
                action_id: '',
                value: ''
            }
        }
    },
    inject: [
        'api'
    ],
    methods: {
        doCreateGood() {
            this.loading = true
            httpService().post(this.api.storehouseValue.create, {
                module: this.createGoodForm,
                account_module_reserve_id: this.$route.params.id,
                module_key: 'storehouse'
            }).then(() => {
                this.$emit('moduleGet');
                this.$emit('closeDrawerCreateGood');
                this.$message({
                    message: 'Значение добавлено',
                    showClose: true,
                    type: 'success'
                })
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
