<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        create-button-label="Добавить взвешивание"
        @moduleGet="getValues"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="openDrawerNewValue"
        :disable-search="true"
    />

    <Filter
        :filter="filter"
        :filter-items1="goods"
        :filter-items2="operations"
        :filter-items3="companies"
        :loading="loading"
        :filter-show="filterShow"
    />

    <MobileTableFramework v-for="(item, index) in tableData" :key="index">
        <template v-slot:body>

            <MobileTableDate
                :date="item.date"
            />

            <MobileTableInfo
                title="Груз"
                :body="item.good_id ? getData(item.good_id, 'good') : '-'"
            />

            <MobileTableInfo
                title="Получатель"
                :body="item.recipient_id ? getData(item.recipient_id, 'company') : '-'"
            />

            <MobileTableInfo
                title="Нетто"
                :body="formatNumber(item.netto) + ' ' + (item.good_id ? getData(item.good_id, 'good', 'unit') : 'ед.')"
            />

            <MobileTableInfo
                title="Операция"
                :no-border="true"
                :body="item.operation_id ? getInfo(item.operation_id, operations) : ''"
            />

            <MobileTableButton
                @action="openDrawer(item)"
            />

        </template>
    </MobileTableFramework>


    <el-table
        empty-text="Информация не найдена"
        v-if="!smallMobile"
        class="mt-15 w-100"
        v-loading="loading"
        :summary-method="getSummaries"
        show-summary
        :data="tableData">

        <el-table-column
            prop="date"
            label="Дата"
            width="85px"
            sortable
            fixed>
            <template v-slot="scope">
                <div class="double">
                    <p>{{ getDateByTz(scope.row.date, 'HH:mm', this.tz) }}</p>
                    <span>{{ getDateByTz(scope.row.date, 'DD.MM.YY', this.tz) }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="good_id"
            min-width="350"
            label="Груз">
            <template v-slot="scope">
                <div class="double">
                    <p>{{ scope.row.good_id ? getData(scope.row.good_id, 'good') : '-' }}</p>
                    <span>{{ scope.row.operation_id ? getInfo(scope.row.operation_id, operations) : '' }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="netto"
            label="Нетто"
            min-width="100px"
            sortable>
            <template v-slot="scope">
                <span class="colored">{{
                        formatNumber(scope.row.netto, 2)
                    }} {{ scope.row.good_id ? getData(scope.row.good_id, 'good', 'unit') ?? 'ед.' : 'ед.' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="sender_id"
            label="Отправитель"
            min-width="190px">
            <template v-slot="scope">
                <span>{{ scope.row.sender_id ? getData(scope.row.sender_id, 'company') : '-' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="recipient_id"
            label="Получатель"
            min-width="190px">
            <template v-slot="scope">
                <span>{{ scope.row.recipient_id ? getData(scope.row.recipient_id, 'company') : '-' }}</span>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="130">
            <template v-slot="scope">
                <el-button
                    size="mini"
                    @click="openDrawer(scope.row)">Подробнее
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />

    <el-drawer
        title=""
        v-model="drawer"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <WeighingDetails
            :weighingDetails="weighingDetails"
            :operations="operations"
            :vehicles="vehicles"
            :drivers="drivers"
            @module-get="getValues"
            @close-drawer="closeDrawer"
            @openEdit="() => editValue = !editValue"
        />

    </el-drawer>

    <el-drawer
        title=""
        v-model="editValue"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <WeighingEdit
            :details="weighingDetails"
            :operations="operations"
            :vehicles="vehicles"
            :drivers="drivers"
            @moduleGet="getValues"
            @closeEdit="closeEdit"
        />

    </el-drawer>

</template>

<script>
import WeighingDetails from './WeighingDetails'
import WeighingEdit from './WeighingEdit'
import tableMixin from '../../../mixins/tableMixin'
import functionsMixin from '../../../mixins/functionsMixin'
import basicMixin from '../../../mixins/basicMixin'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import TablePagination from '../Table/TablePagination'
import TableHeadBlock from '../Table/TableHeadBlock'
import Filter from '../Table/Filter/Filter'
import getTimezone from '@/mixins/getTimezone'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableDate from '@/views/components/Mobile/Table/MobileTableDate'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    props: [
        'operations',
        'vehicles',
        'drivers',
        'updateValues',
        'tab'
    ],
    components: {
        MobileTableDate,
        MobileTableButton,
        MobileTableInfo,
        MobileTableFramework,
        Filter,
        TableHeadBlock,
        TablePagination,
        WeighingDetails,
        WeighingEdit
    },
    mixins: [
        tableMixin, functionsMixin, basicMixin, mobileCheckMixin, getTimezone
    ],
    inject: [
        'api'
    ],
    name: 'WeighingTable',
    data() {
        return {
            drawer: false,
            weighingDetails: [],
            editValue: false,
            tableData: [],
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
                good: {
                    value: null,
                    type: 'select',
                    typeSelect: 'goods',
                    items: '1'
                },
                operationId: {
                    value: null,
                    type: 'select',
                    label: 'Тип операции',
                    placeholder: 'Выберите операцию',
                    items: '2'
                },
                recipientId: {
                    value: null,
                    type: 'select',
                    label: 'Получатель',
                    placeholder: 'Получатель груза',
                    items: '3'
                },
                senderId: {
                    value: null,
                    type: 'select',
                    label: 'Отправитель',
                    placeholder: 'Отправитель груза',
                    items: '3'
                },
            },
            tz: null
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies'
        })
    },
    methods: {
        moduleGet() {
            // Прокидываем наверх перезагрузку модуля
            this.$emit('moduleGet')
        },
        openDrawerNewValue() {
            this.$emit('openDrawerNewValue')
        },
        closeDrawer() {
            this.drawer = false
        },
        getValues() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.weighingValue.getValues, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'weighing',
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.tableData = data.values.data
                this.valueLength = data.values.total
                this.tz = data.timeZone
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
                this.loadingButton = false
                this.$router.push({ name: 'logout' })
            })
        },
        openDrawer(row) {
            // Подробности взвешивания
            this.weighingDetails = []
            this.drawer = true
            this.weighingDetails = row  // все значения одной строки из массива module->values
        },
        closeEdit() {
            this.editValue = false
            this.drawer = false
        },
        getSummaries(param) {
            const { columns } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 2) {
                    let total = 0
                    this.tableData.forEach(function (value) {
                        total = total + Number(value.netto)
                    })
                    sums[index] = total.toFixed(2)
                    return
                }
            })

            return sums
        },
        filterValues() {

        }
    },
    watch: {
        'updateValues': {
            handler: function () {
                if (this.tab === 'weighings') this.getValues()
            },
            deep: true,
            immediate: true
        },
        pageSize() {
            if (this.tab === 'weighings') this.getValues()
        },
        page() {
            if (this.tab === 'weighings') this.getValues()
        },
        'filter': {
            handler: function () {
                if (this.tab === 'weighings') this.getValues()
            },
            deep: true,
            immediate: true
        },
        search() {
            if (this.tab === 'weighings') this.getValues()
        }
    }
}
</script>
