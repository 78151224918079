<template>
    <DrawerFramework>
        <template v-slot:body>
            <DrawerHeadTitle>
                <template v-slot:title>
                    Взвешивания за <span style="text-transform: lowercase">{{
                        getDateFromUnix(details.date, 'DD.MM.YY')
                    }}</span>
                </template>
            </DrawerHeadTitle>

            <DrawerTextLineItem
                name="Общий объем"
                :desc="formatNumber(details.total) + ' т.'"
            />

            <DrawerTextLineItem
                name="Кол-во отгрузок"
                :desc="formatNumber(details.count)"
            />

            <el-table
                empty-text="Информация не найдена"
                class="mt-15 w-100"
                v-loading="loading"
                :data="values">

                <el-table-column
                    prop="date"
                    label="Дата">
                    <template v-slot="scope">
                        {{ getDateFromUnix(scope.row.date) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="value"
                    label="Значение">
                    <template v-slot="scope">
                            <span class="typeAddition">+ {{
                                    formatNumber(scope.row.netto)
                                }} т.</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="value"
                    fixed="right"
                    width="70px"
                    label="">
                    <template v-slot="scope">
                        <el-button :loading="loading" type="info" size="mini" plain :icon="icons.delete"
                                   @click.prevent="deleteValue(scope.row.id)"></el-button>
                    </template>
                </el-table-column>

            </el-table>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import functionsMixin from '@/mixins/functionsMixin'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'WeighingSimpleDetails',
    props: [
        'details',
    ],
    components: { DrawerTextLineItem, DrawerHeadTitle, DrawerFramework },
    mixins: [
        functionsMixin, iconsMixin
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: false,
            values: null,
        }
    },
    created() {
        this.loading = true
        httpService().post(this.api.weighingValue.getOneDayValues, {
            account_module_reserve_id: this.$route.params.id,
            module_key: 'weighing',
            date: this.details.date
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.loading = false
            this.values = data.values
        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
            this.loading = false
        })
    },
    methods: {
        deleteValue(id) {
            this.loading = true
            this.$confirm(
                'Вы действительно хотите удалить взвешивание?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.weighingValue.remove, {
                        id: id,
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'weighing',
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.loading = true
                        this.$message({
                            message: 'Операция удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('closeDrawer')
                        this.$emit('moduleGet')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })


            this.loading = false
        }
    }
}
</script>

<style scoped>

</style>
