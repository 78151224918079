<template>
    <Auth title="Создание пользователя">
        <div class="card">
            <div class="card-body">
                <el-form v-model="user" label-position="top">
                    <el-row gutter="20">
                        <el-col :sm="14">

                            <div v-if="step === 1">
                                <h3>Укажите контактные данные пользователя</h3>

                                <FormInput
                                    v-model="user.name"
                                    placeholder="Введите имя"
                                    label="ФИО:"
                                />

                                <FormInput
                                    v-model="user.email"
                                    v-if="!user.autoEmail"
                                    placeholder="Введите почту"
                                    label="Электронная почта:"
                                />

                                <FormNumber
                                    v-model="user.phone"
                                    placeholder="Введите телефон"
                                    label="Телефон:"
                                />

                                <div>
                                    <el-checkbox
                                        v-model="user.autoPassword"
                                        label="Автоматически сгенерировать пароль"
                                    />
                                </div>


                                <el-checkbox
                                    v-model="user.autoEmail"
                                    class="mb-15"
                                    label="У сотрудника нет электронной почты"
                                />

                                <div v-if="!user.autoPassword">

                                    <el-row gutter="15">
                                        <el-col :sm="12">

                                            <FormInput
                                                v-model="user.password"
                                                label="Введите пароль:"
                                                placeholder="Введите пароль"
                                                type="password"
                                            />

                                        </el-col>
                                        <el-col :sm="12">

                                            <FormInput
                                                v-model="user.passwordVerification"
                                                label="Подтвердите пароль:"
                                                placeholder="Подтвердите пароль"
                                                type="password"
                                                :disabled="!user.password"
                                            />

                                        </el-col>
                                    </el-row>

                                </div>

                                <FormAddButton
                                    title="Продолжить"
                                    icon=""
                                    @action="createUser"
                                />

                            </div>

                            <div v-if="step === 2">
                                <h3>Выберите роль пользователя</h3>

                                <el-form-item label="Роль пользователя:">
                                    <el-radio-group class="userRoles" v-model="user.role" size="small">
                                        <el-radio v-for="role in roles.filter(el => el.name !== 'Владелец')"
                                                  :key="role.name" :label="role.name"
                                                  :disabled="role.name === 'Владелец'" border><span class="title">{{
                                                role.name
                                            }}</span>
                                            <span class="description">
                                                    {{ role.description }}
                                                </span></el-radio>
                                    </el-radio-group>
                                </el-form-item>

                                <FormAddButton
                                    title="Продолжить"
                                    icon=""
                                    @action="createUser"
                                />
                            </div>

                            <div v-if="step === 3">
                                <h3>Выберите модули, доступные пользователю</h3>


                                <FormSelect
                                    v-if="user.role === 'Оператор БСУ'"
                                    v-model="user.module"
                                    label="Выберите бетонный завод"
                                    placeholder="Выберите модуль"
                                    :items="modules.filter(el => el.module_key === 'mix')"
                                />

                                <el-form-item label="Модули, доступные пользователю:" v-else>
                                    <el-scrollbar max-height="590px" always>
                                        <el-checkbox-group class="inlineCheckbox" v-model="user.modules">
                                            <el-checkbox v-for="module in modules" :key="module.id" :label="module.id">
                                                {{
                                                    module.name
                                                }}
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </el-scrollbar>
                                </el-form-item>

                                <FormAddButton
                                    title="Зарегистрировать пользователя"
                                    icon=""
                                    @action="createUser"
                                />
                            </div>

                            <div v-if="step === 4">
                                <h3>Пользователь зарегистрирован</h3>

                                <p v-if="!user.autoEmail">Пользователь получил письмо с приглашением на почту {{ user.email }}</p>

                                <p>Вы можете вручную отправить приглашение для пользователя в мессенджерах или других каналах связи.</p>

                                <FormTextarea
                                    v-model="inviteText"
                                    label="Текст сообщения:"
                                    rows="5"
                                />

                                <FormAddButton
                                    title="Вернуться к списку пользователей"
                                    icon=""
                                    @action="$router.push({name: 'UsersIndex'})"
                                />

                            </div>
                        </el-col>
                        <el-col :sm="8">

                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth'
import {httpService} from '@/services/http.service'
import FormInput from "@/views/components/Form/FormInput";
import FormNumber from "@/views/components/Form/FormNumber";
import FormAddButton from "@/views/components/Form/FormAddButton";
import FormTextarea from "@/views/components/Form/FormTextarea";
import FormSelect from "@/views/components/Form/FormSelect.vue";

export default {
    name: "UsersEdit",
    components: {
        FormSelect,
        FormTextarea,
        FormAddButton,
        FormNumber,
        FormInput,
        Auth
    },
    data() {
        return {
            step: 1,
            user: {
                id: null,
                name: null,
                autoEmail: false,
                email: null,
                phone: '7',
                login: null,
                modules: [],
                module: null,
                autoPassword: true,
                password: null,
                passwordVerification: null,
                role: 'Пользователь',
                banned: null,
            },
            loading: true,
            modules: [],
            roles: [],
            inviteText: null,
        }
    },
    inject: [
        'api'
    ],
    created() {
        httpService().post(this.api.account_module.get).then((response) => {
            let data = response.data

            if (data.success)
                this.modules = data.modules

        })

        httpService().post(this.api.users.getRoles).then((response) => {
            let data = response.data

            if (data.success)
                this.roles = data.roles

        })

        this.loading = false
    },
    methods: {
        createUser() {
            this.loading = true

            httpService().post(this.api.user.create, {
                user: this.user,
                step: this.step
            }).then((response) => {
                let data = response.data

                if (data.success)
                    if (this.step === 3) {

                        this.$message({
                            message: 'Пользователь добавлен',
                            showClose: true,
                            type: 'success'
                        })
                        this.user.password = data.password
                        this.user.phone = data.phone
                        this.inviteText = 'Приглашение в сервис Цифра:\n\nhttps://app.cifra.ai/\n' + this.user.phone + ' (логин)\n' + this.user.password + ' (пароль)'
                    }
                this.step++

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    },
}
</script>
