import { useCommonStore } from '@/store/common';
import router from '..';

export default function reportsAvailable({to,next}) {
    const commonStore = useCommonStore()


    if(!commonStore.getUserReportAccess.length || !commonStore.getReportsAvailable) {
        router.push('/dashboard');
    }

    if (commonStore.getReportsAvailable && to.meta?.key === 'index') {
        return next();
    }

    let currentReport = commonStore.getReports.find(item => item.report_key === to.meta?.key);

    if (commonStore.getUserReportAccess.find(item => item.report_id === currentReport.id)) {
        return next();
    }

    router.push('/dashboard');
}
