<template>
    <DrawerFramework>
        <template v-slot:body>
            <div class="cashboxOperation">
                <div class="cashboxOperation__title">
                    <div v-if="operation.income" class="cashboxOperation__titleIcon Addition">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 12H18" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 18V6" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-else class="cashboxOperation__titleIcon Subtraction">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 12H18" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <h3 class="cashboxOperation__name">{{ operation.name }}</h3>
                    <p v-if="operation.income" class="cashboxOperation__underTitle">Доход</p>
                    <p v-else class="cashboxOperation__underTitle">Расход</p>
                    <div class="cashboxOperation__value">
                        <span v-if="operation.income">+ {{ formatNumber(operation.value) }} руб.</span>
                        <span v-else>- {{ formatNumber(operation.value) }} руб.</span>
                    </div>


                    <DrawerTextLineItem
                        name="Дата операции"
                        :desc="getDateByTz(operation.date, 'DD.MM.YY в HH:mm', this.tz)"
                    />

                    <DrawerTextLineItem
                        name="Категория"
                        :desc="operation.typeId ? getInfo(operation.typeId, operationTypes) : 'Не указана'"
                    />

                    <DrawerTextLineItem
                        name="Счет"
                        :desc="operation.accountId ? getInfo(operation.accountId, accounts) : 'По умолчанию'"
                    />

                    <DrawerTextLineItem
                        v-if="!operation.companyId && !operation.recipientId"
                        name="Контрагент"
                        desc="Не указан"
                    />

                    <DrawerTextLineItem
                        v-if="!operation.comment"
                        name="Комментарий"
                        desc="Не указан"
                    />

                    <el-collapse class="mt-30">

                        <DrawerCollapseItem
                            v-if="operation.companyId"
                            title="Контрагент"
                            :subtitle="getData(operation.companyId,'company')"
                        >
                            <template v-slot:body>
                                <DrawerTextLineItem
                                    name="Название"
                                    :desc="getData(operation.companyId,'company')"
                                />

                                <DrawerTextLineItem
                                    name="ИНН"
                                    :desc="inn ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="КПП"
                                    :desc="kpp ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="E-mail"
                                    :desc="email ?? 'Не указан'"
                                />

                                <DrawerTextLineItem
                                    name="Телефон"
                                    :desc="phone ?? 'Не указан'"
                                />
                            </template>
                        </DrawerCollapseItem>

                        <DrawerCollapseItem
                            v-if="operation.comment"
                            title="Комментарий"
                            subtitle="Подробнее"
                        >
                            <template v-slot:body>
                                {{ operation.comment ?? '-' }}
                            </template>
                        </DrawerCollapseItem>

                    </el-collapse>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <DrawerEditButton :loading="loadingButton" label="Редактировать операцию" @action="doEditOperation"/>
            <DrawerDeleteButton :loading="loadingButton" @action="deleteOperation(operation.id)"/>
        </template>
    </DrawerFramework>
</template>

<script>
// подробности денежной операции
import functionsMixin from '../../../mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import DrawerEditButton from "@/views/components/Drawer/Buttons/DrawerEditButton";
import DrawerDeleteButton from "@/views/components/Drawer/Buttons/DrawerDeleteButton";
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerTextLineItem from "@/views/components/Drawer/DrawerTextLineItem";
import DrawerCollapseItem from "@/views/components/Drawer/DrawerCollapseItem";
import {mapState} from "pinia/dist/pinia";
import {useCommonStore} from "@/store/common";

export default {
    components: {DrawerCollapseItem, DrawerTextLineItem, DrawerFramework, DrawerDeleteButton, DrawerEditButton},
    props: ['operation', 'loadingButton', 'recipients', 'operationTypes', 'accounts', 'tz'],
    name: 'CashboxDetails',
    data() {
        return {
            inn: null,
            kpp: null,
            email: null,
            phone: null,
        }
    },
    inject: ['api'],
    mixins: [functionsMixin],
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
        }),
    },
    created() {
        if (this?.operation?.companyId) {
                    this.getData(
                        this.operation.companyId,
                        "company",
                        "inn"
                    ).then((response) => {
                        this.inn = response.inn;
                    });

                    this.getData(
                        this.operation.companyId,
                        "company",
                        "kpp"
                    ).then((response) => {
                        this.kpp = response.kpp;
                    });

                    this.getData(
                        this.operation.companyId,
                        "company",
                        "email"
                    ).then((response) => {
                        this.email = response.email;
                    });

                    this.getData(
                        this.operation.companyId,
                        "company",
                        "phone"
                    ).then((response) => {
                        this.phone = response.phone;
                    })
        }
    },
    methods: {
        deleteOperation(id) {
            this.$confirm('Вы действительно хотите удалить операцию?', 'Подтвердите удаление', {
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отмена',
                type: 'warning'
            }).then(() => {
                httpService().post(this.api.cashboxOperation.remove, {
                    id: id,
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'cashbox',
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.drawer = false
                        this.$message({
                            message: 'Операция удалена',
                            showClose: true,
                            type: 'success'
                        })

                        if (data.accountUpdate) {

                            window.setTimeout(() => {
                                this.$message({
                                    message: 'Остаток счета обновлен',
                                    type: 'success',
                                    showClose: true,
                                })
                            }, 450)

                        }
                        this.$emit('moduleGet')

                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message,
                        showClose: true,
                        type: 'error'
                    })
                })
            }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        doEditOperation() {
            this.$emit('doEditOperation')
        }
    }
}
</script>

<style scoped>

</style>
