<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="url(#paint0_linear_1328_18669)"/>
        <path opacity="0.4"
              d="M25.32 20.94L20.52 12.3C19.875 11.1375 18.9825 10.5 18 10.5C17.0175 10.5 16.125 11.1375 15.48 12.3L10.68 20.94C10.0725 22.0425 10.005 23.1 10.4925 23.9325C10.98 24.765 11.94 25.2225 13.2 25.2225H22.8C24.06 25.2225 25.02 24.765 25.5075 23.9325C25.995 23.1 25.9275 22.035 25.32 20.94Z"
              fill="#ECAF02"/>
        <path
            d="M18 20.0625C17.6925 20.0625 17.4375 19.8075 17.4375 19.5V15.75C17.4375 15.4425 17.6925 15.1875 18 15.1875C18.3075 15.1875 18.5625 15.4425 18.5625 15.75V19.5C18.5625 19.8075 18.3075 20.0625 18 20.0625Z"
            fill="#ECAF02"/>
        <path
            d="M18 22.5C17.955 22.5 17.9025 22.4925 17.85 22.485C17.805 22.4775 17.76 22.4625 17.715 22.44C17.67 22.425 17.625 22.4025 17.58 22.3725C17.5425 22.3425 17.505 22.3125 17.4675 22.2825C17.3325 22.14 17.25 21.945 17.25 21.75C17.25 21.555 17.3325 21.36 17.4675 21.2175C17.505 21.1875 17.5425 21.1575 17.58 21.1275C17.625 21.0975 17.67 21.075 17.715 21.06C17.76 21.0375 17.805 21.0225 17.85 21.015C17.9475 20.9925 18.0525 20.9925 18.1425 21.015C18.195 21.0225 18.24 21.0375 18.285 21.06C18.33 21.075 18.375 21.0975 18.42 21.1275C18.4575 21.1575 18.495 21.1875 18.5325 21.2175C18.6675 21.36 18.75 21.555 18.75 21.75C18.75 21.945 18.6675 22.14 18.5325 22.2825C18.495 22.3125 18.4575 22.3425 18.42 22.3725C18.375 22.4025 18.33 22.425 18.285 22.44C18.24 22.4625 18.195 22.4775 18.1425 22.485C18.0975 22.4925 18.045 22.5 18 22.5Z"
            fill="#ECAF02"/>
        <defs>
            <linearGradient id="paint0_linear_1328_18669" x1="0" y1="36" x2="36" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F7DE97"/>
                <stop offset="1" stop-color="#F7DE97" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "WarningIcon"
}
</script>
