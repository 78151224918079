<template>
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.15" cx="9" cy="9" r="9" fill="#ACAFC6"/>
        <path d="M5.742 8.254H8.388V5.37H9.508V8.254H12.154V9.36H9.508V12.258H8.388V9.36H5.742V8.254Z" fill="#ACAFC6"/>
    </svg>
</template>

<script>
export default {
    name: "AddRoundIcon"
}
</script>

<style scoped>

</style>
