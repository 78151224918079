<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">{{ label }}</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5" v-for="(value, index) in values" :key="index">
                <span class="neutral300 mr-5">{{ value.name }}:</span>
                <span class="text-line-through mr-5 neutral100" v-if="value.oldData">{{ value.oldData }}</span>
                <span>{{ value.data }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import MixOrderInfo from "@/mixins/mixOrderInfo";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderDetailsGroupLogChangeStatus",
    props: ['log'],
    mixins: [MixOrderInfo, functionsMixin],
    computed: {
        label() {
            let label = 'Статус изменен'
            let status = this.log.values.find(el => el.key === 'status' && el.new)?.value

            if (status) {
                switch (status) {
                    case 'loading':
                        label = 'Старт загрузки бетона'
                        break;
                    case 'delivery':
                        label = 'Завершение загрузки. Начали доставку'
                        break;
                    case 'object':
                        label = 'Прибыл на объект'
                        break;
                    case 'return':
                        label = 'Возвращение на завод'
                        break;
                    case 'done':
                        label = 'Отгрузка завершена'
                        break;
                }
            }

            return label
        },
        values() {
            let elems = []

            this.log.values.filter(el => el.new).forEach(el => {
                let name = null
                let data = null
                let oldData = null

                let oldStatus = this.log.values.find(e => e.key === 'status' && !e.new)?.value


                switch (el.key) {
                    case 'status':
                        if (oldStatus) {
                            oldData = this.getStatusLabel(oldStatus);
                        }

                        name = 'Статус';
                        data = this.getStatusLabel(el.value);

                        break;
                    case 'comment':
                        name = 'Комментарий';
                        data = el.value;
                        break;
                    case 'distance':
                        name = 'Расстояние между ТС и объектом';
                        data = this.formatNumber(Number(el.value) * 1000) + ' метров';
                        break;
                    case 'latitude':
                        oldStatus = this.log.values.find(e => e.key === 'longitude')?.value
                        name = 'Координаты';
                        data = el.value + ', ' + oldStatus;
                        break;
                }

                if (name) {
                    elems.push({
                        name: name,
                        data: data ?? '-',
                        oldData: oldData ?? null
                    })
                }
            })

            return elems
        }
    }
}
</script>

<style scoped>

</style>
