<template>
    <Auth title="Отчеты о работе" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-search="true"
                    create-button-label="Добавить отчет"
                    :current-date="filter.date.value"
                    @moduleGet="getAll()"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateDialog()"
                    @changeDate="changeDate"
                    :new="true"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="отчетов"
                />

                <template v-if="mobile">
                    <div class="card card-border mt-10" v-for="(report, index) in displayData" :key="index">
                        <div class="card-body">
                            <div>
                                {{ report.report }}
                            </div>
                            <hr/>
                            <el-row gutter="10">
                                <el-col :span="20">
                                    <div>
                                        <span class="textMedium neutral900">Дата:</span>
                                        {{ getDateFormDB(report.created_at, 'shortDate') }}
                                    </div>
                                    <div>
                                        <span class="textMedium neutral900">Смена:</span>
                                        {{ report.shift_id ? getShiftName(report.shift_id) : '-' }}
                                    </div>
                                    <div>
                                        <span class="textMedium neutral900">Простой:</span>
                                        {{ formatNumber(report.downtime) }} мин.
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="d-block w-100 mb-5">
                                    <el-button
                                        size="mini"
                                        :icon="icons.edit"
                                        @click="openEditDialog(report)"/>
                                    </div>
                                    <div class="d-block w-100">
                                    <el-button
                                        size="mini"
                                        :icon="icons.delete"
                                        @click="remove(report.id)"/>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </template>

                <el-table
                    v-loading="loading"
                    v-show="displayData.length > 0 && !mobile"
                    :data="displayData"
                    empty-text="Информация не найдена"
                    class="mt-15 w-100"
                >
                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="85px"
                        sortable
                        fixed>
                        <template v-slot="scope">
                            <span class="textMedium neutral800">{{
                                    getDateFormDB(scope.row.created_at, 'shortDate')
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="report"
                        label="Отчет">
                        <template v-slot="scope">
                            <p class="mt-0 textRegular textSM neutral900 mb-0">{{ scope.row.report ?? '-' }}</p>
                            <span v-if="scope.row.downtime" class="textRegular textXS neutral300 d-block mt-5">
                    Простой - {{ formatNumber(scope.row.downtime) }} мин.
                </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="shift"
                        width="230"
                        label="Смена">
                        <template v-slot="scope">
                            {{ scope.row.shift_id ? getShiftName(scope.row.shift_id) : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="190"
                        align="right">
                        <template v-slot="scope">
                            <el-button
                                size="mini"
                                :icon="icons.edit"
                                @click="openEditDialog(scope.row)"/>
                            <el-button
                                size="mini"
                                :icon="icons.delete"
                                @click="remove(scope.row.id)"/>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

            </div>
        </div>

        <el-dialog
            v-model="orderCreateDialog"
            title="Новый отчет"
            :width="mobile ? '100%' : '65%'"
            :destroy-on-close="true"
        >
            <el-form label-position="top">

                <FormDateTimePicker
                    label="Дата отчета"
                    placeholder="Выберите дату"
                    v-model="form.date"
                    :required="true"
                />

                <FormTextarea
                    label="Отчет"
                    placeholder="Заполните отчет"
                    v-model="form.report"
                    :required="true"
                />

                <el-form-item label="" class="mb-0">
                    <el-checkbox v-model="form.shiftReportCheck" label="Привязать отчет к смене"/>
                </el-form-item>

                <el-form-item label="">
                    <el-checkbox v-model="form.downtimeReportCheck" label="Указать простой"/>
                </el-form-item>

                <FormSelect
                    v-if="form.shiftReportCheck"
                    label="Смена"
                    placeholder="Выберите смену"
                    v-model="form.shiftId"
                    :items="shifts"
                />

                <FormNumber
                    v-if="form.downtimeReportCheck"
                    v-model="form.downtime"
                    label="Время простоя (мин.)"
                    placeholder="Укажите в минутах"
                />

                <el-button type="primary" @click="create()" class="w-100"
                           :disabled="!form.report || !form.date">Добавить отчет
                </el-button>

            </el-form>
        </el-dialog>

        <el-dialog
            v-model="orderEditDialog"
            title="Редактирование"
            :width="mobile ? '100%' : '65%'"
            :destroy-on-close="true"
        >
            <el-form label-position="top">
                <FormDateTimePicker
                    label="Дата отчета"
                    placeholder="Выберите дату"
                    v-model="editForm.created_at"
                    :required="true"
                />

                <FormTextarea
                    label="Отчет"
                    placeholder="Заполните отчет"
                    v-model="editForm.report"
                    :required="true"
                />

                <FormSelect
                    label="Смена"
                    placeholder="Выберите смену"
                    v-model="editForm.shift_id"
                    :items="shifts"
                />

                <FormNumber
                    v-model="editForm.downtime"
                    label="Время простоя (мин.)"
                    placeholder="Укажите в минутах"
                />

                <el-button type="primary" @click="edit()" class="w-100"
                           :disabled="!editForm.report || !editForm.created_at">Сохранить отчет
                </el-button>
            </el-form>
        </el-dialog>
    </Auth>
</template>

<script>

import TableHeadBlock from '@/views/components/Table/TableHeadBlock.vue'
import tableMixin from '@/mixins/tableMixin'
import Filter from '@/views/components/Table/Filter/Filter.vue'
import FormSelect from '@/views/components/Form/FormSelect.vue'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker.vue'
import {httpService} from '@/services/http.service'
import FormTextarea from '@/views/components/Form/FormTextarea.vue'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import moment from "moment/moment";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionReportsIndex',
    components: {
        Auth,
        FormNumber,
        TablePagination, EmptyState, FormTextarea, FormDateTimePicker, FormSelect, Filter, TableHeadBlock
    },
    mixins: [tableMixin, mobileCheckMixin, iconsMixin, functionsMixin, productionTabs],
    inject: ['api'],
    data() {
        return {
            loading: false,
            orderCreateDialog: false,
            orderEditDialog: false,
            filter: {
                date: {
                    value: moment().startOf('month'),
                    type: 'date'
                }
            },
            form: {
                date: null,
                report: null,
                shiftId: null,
                shiftReportCheck: false,
                downtimeReportCheck: false,
                downtime: null,
                downtimeReport: null
            },
            editForm: {
                id: null,
                created_at: null,
                report: null,
                shift_id: null,
                downtime: null,
            },
            shifts: [],
        }
    },
    created() {
        this.getShifts()
        this.getAll()
    },
    methods: {
        openCreateDialog() {
            this.form.date = moment()
            this.orderCreateDialog = true
        },
        openEditDialog(report) {
            this.editForm = Object.assign({}, report)
            this.orderEditDialog = true
        },
        getShiftName(id) {
            if (this.shifts.length > 0) {
                return this.shifts.find(el => el.id === id).name
            } else {
                return null
            }
        },
        getAll() {
            this.loading = true

            httpService().post(this.api.productionReport.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                date: this.filter.date.value
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        remove(id) {
            this.$confirm(
                'Вы действительно хотите удалить данный отчет?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionReport.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        id: id
                    }).then(() => {
                        this.$message({
                            message: 'Отчет удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getShifts() {
            this.loading = true

            httpService().post(this.api.productionShift.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.shifts = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        create() {
            this.loading = true

            httpService().post(this.api.productionReport.create, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Отчет добавлен',
                        showClose: true,
                        type: 'success'
                    })

                this.getAll()
                this.orderCreateDialog = false
                this.form = {
                    date: null,
                    report: null,
                    shiftId: null,
                    shiftReportCheck: false,
                    downtimeReportCheck: false,
                    downtime: null,
                    downtimeReport: null
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        edit() {
            this.loading = true

            httpService().post(this.api.productionReport.edit, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production',
                form: this.editForm
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Отчет сохранен',
                        showClose: true,
                        type: 'success'
                    })

                this.getAll()
                this.orderEditDialog = false
                this.editForm = {
                    id: null,
                    created_at: null,
                    report: null,
                    shift_id: null,
                    downtime: null,
                }
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'filter': {
            handler: function () {
                this.getAll()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
