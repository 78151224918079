<template>
    <Auth title="Посты" :tabs="tabs">
        <div class="card">
            <div class="card-body">

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";

export default {
    name: 'DispatchMixes',
    components: {
        Auth
    },
    mixins: [tableMixin, basicMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            filter: {
                date: {
                    value: moment(),
                    type: 'date'
                },
            },
        }
    },
    created() {

    },
    computed: {

    },
    methods: {

    },
    watch: {
        'filter': {
            handler: function () {

            },
            deep: true,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
