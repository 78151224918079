<template>
    <el-collapse-item>
        <template #title>
            <div class="collpaseHeader">
                <div class="collpaseHeader__title text-left">
                    {{ title }}
                </div>
                <div class="collpaseHeader__subtitle text-left">
                    {{ subtitle }}
                    <slot v-if="!subtitle" name="subtitle"></slot>
                </div>
            </div>
        </template>

        <div class="collpaseDetails">
            <slot name="body"></slot>
        </div>
    </el-collapse-item>
</template>

<script>
export default {
    name: "DrawerCollapseItem",
    props: [
        'title',
        'subtitle'
    ]
}
</script>

<style scoped>

</style>
