<template>
    <Auth title="Произведено" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    @moduleGet="getAll()"
                    :disable-search="true"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateValueDrawer"
                    :new="true"
                />

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="значений"
                />

                <el-table
                    v-loading="loading"
                    v-show="displayData.length > 0"
                    :data="displayData"
                    empty-text="Информация не найдена"
                    :summary-method="getSummaries"
                    show-summary
                    class="mt-15 w-100"
                >
                    <el-table-column
                        prop="date"
                        label="Дата"
                        width="80px"
                    >
                        <template v-slot="scope">
                            <span class="textRegular neutral300">{{ getDateFromUnix(scope.row.date, 'DD.MM') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodId"
                        min-width="180"
                        label="Продукция">
                        <template v-slot="scope">
                            <div class="textSM textMedium neutral900 cutOverflow">{{ scope.row.goodId ? getData(scope.row.goodId, 'good') : '-' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="unit"
                        label="Сорт">
                        <template v-slot="scope">
                            <span class="textSM textMedium success400 cutOverflow">{{ scope.row.unit ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="shift"
                        label="Смена">
                        <template v-slot="scope">
                            <span class="textRegular neutral400 cutOverflow">{{ scope.row.shift ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="value"
                        label="Количество"
                        sort-by="value"
                        sortable>
                        <template v-slot="scope">
                            <span class="primary600 textBold">{{
                                    scope.row.value
                                }} {{
                                    scope.row.goodId ? getData(scope.row.goodId, 'good', 'unit') ?? 'ед.' : 'ед.'
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="90"
                        align="right">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                :icon="icons.delete"
                                @click="deleteValue(scope.row.id)"/>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>

        <el-drawer
            title=""
            v-model="createValueDrawer"
            direction="rtl"
            :size="drawerSize"
            :destroy-on-close="true">
            <ProductionValueCreate
                :moduleId="$route.params.id"
                @closeDrawer="openCreateValueDrawer"
                @moduleGet="getAll()"/>
        </el-drawer>
    </Auth>
</template>

<script>
// components
import ProductionValueCreate from './ProductionValueCreate.vue'

import moment from 'moment'
import tableMixin from '../../../../mixins/tableMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import TableHeadBlock from '../../Table/TableHeadBlock.vue'
import Filter from '../../Table/Filter/Filter.vue'
import TablePagination from '@/views/components/Table/TablePagination.vue'
import EmptyState from "@/views/components/EmptyState.vue";
import {httpService} from "@/services/http.service";
import iconsMixin from "@/mixins/iconsMixin";
import {ElMessage} from "element-plus";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: "ProductionValuesTab",
    components: {
        Auth,
        EmptyState,
        TablePagination,
        Filter,
        TableHeadBlock,
        ProductionValueCreate,
    },
    mixins: [
        tableMixin, functionsMixin, mobileCheckMixin, iconsMixin, productionTabs
    ],
    created() {
        this.getAll()
    },
    data() {
        return {
            loading: false,
            detailValue: [],
            detailValueDrawer: false,
            createValueDrawer: false,
            filter: {
                dateStart: {
                    value: moment().startOf('month'),
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
                goodId: {
                    value: null,
                    type: 'select',
                    typeSelect: 'goods',
                },
            },
        }
    },
    methods: {
        getAll() {
            this.loading = true

            httpService().post(this.api.productionValue.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getShifts() {
            this.loading = true

            httpService().post(this.api.productionShift.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.shifts = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        deleteValue(id) {
            this.$confirm(
                'Вы действительно хотите удалить значение?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.loading = true
                    httpService().post(this.api.productionValue.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        id: id
                    }).then((response) => {
                        let data = response.data

                        if (data.success) {
                            ElMessage({
                                type: 'success',
                                message: 'Операция удалена',
                            })

                            this.getAll()
                        }

                    }).catch((error) => {
                        ElMessage({
                            type: 'error',
                            message: error.response.data.message,
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        setDate(row, column) {
            const newdata = row[column.property]
            if (newdata === undefined) {
                return ''
            }
            return this.getDateFromUnix(newdata, 'DD.MM HH:mm')
        },
        openDetailValueDrawer(index, row) {
            this.detailValue = []
            this.detailValueDrawer = true
            this.detailValue = row
        },
        closeDetailValueDrawer() {
            this.detailValueDrawer = false
        },
        openCreateValueDrawer() {
            this.createValueDrawer = !this.createValueDrawer
        },
        getSummaries(param) {
            const {columns} = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (index === 4) {
                    let total = 0
                    this.displayData.forEach(function (value) {
                        total = total + Number(value.value)
                    })
                    sums[index] = this.formatNumber(total) + ' ед.'
                    return
                }
            })

            return sums
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.good.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.date >= moment(this.filter.dateStart.value).format("X"))
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.date <= moment(this.filter.dateEnd.value).format("X"))
            if (this.filter.goodId.value) this.filtered = this.filtered.filter(data => data.goodId === this.filter.goodId.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
