<template>
    <div>
        <el-form v-loading="loading" ref="form" size="small" label-position="top">

            <FormNumber
                v-model="price"
                :label="'Введите цену ' + (selling ? 'продажи' : 'закупки')"
                placeholder="340"
            />

        </el-form>

        <el-button
            @click="addGoodPrice()"
            type="primary"
            class="w-100">
            Добавить цену
        </el-button>
    </div>
</template>

<script>
import FormNumber from '@/views/components/Form/FormNumber'
import {httpService} from '@/services/http.service'

export default {
    name: 'GoodsAddPrice',
    components: {FormNumber},
    props: ['goodId', 'selling'],
    inject: ['api'],
    data() {
        return {
            loading: false,
            price: null
        }
    },
    methods: {
        addGoodPrice() {
            httpService().post(this.api.good.addGoodPrice, {
                goodId: this.goodId,
                price: this.price,
                selling: this.selling
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$emit('closeDialog')
                this.$emit('moduleGet')
                this.$message({
                    message: 'Цена добавлена',
                    showClose: true,
                    type: 'success'
                })

            }).catch(() => {
                this.$message({
                    message: 'Товар не найден',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'goods'})
            })
        },
    }
}
</script>

<style scoped>

</style>
