<template>
    <div class="plan">
        <div class="planHead">
            <div class="planHeadTitle">
                {{ plan.plan_name }}
            </div>
            <div v-if="!mobile">
                <el-button v-if="!plan.plan_date_fact" @click="endPlan(plan)">Завершить</el-button>
                <!--                        <el-button :icon="icons.edit"/>-->
                <el-button @click="removePlan(plan)" :icon="icons.delete"/>
            </div>
        </div>

        <div class="planUnderHead">

            <div>
                Статус: {{ plan.plan_date_fact ? 'завершен' : 'в работе' }}
            </div>

            <div v-if="!plan.plan_date_fact">
                Срок выполнения: до {{ getDateFromUnix(plan.plan_date_done, 'DD.MM') }}
            </div>
            <div v-else>
                План завершен {{ getDateFromUnix(plan.plan_date_fact, 'DD.MM.YY') }}
            </div>

        </div>

        <template v-if="plan.plan_type === 'tasks'">

            <div class="tasks" v-if="getTasks(plan.id).length === 0 && !plan.plan_date_fact">
                <div class="tasksEmpty">

                    <EmptyListIcon/>

                    <h3 class="mb-5">Список задач пуст</h3>
                    <p class="text-light mb-20 mt-0">Добавьте первую задачу</p>
                    <el-button class="mb-15" @click="openNewTaskDialog(plan.id)" type="primary">+ Добавить задачу
                    </el-button>

                </div>
            </div>

            <div class="tasks" v-if="getTasks(plan.id).length !== 0">

                <el-row :gutter="15" class="tasksHead">

                    <el-col :sm="1"/>
                    <el-col :sm="8">
                        Задача
                    </el-col>
                    <el-col :sm="4">
                        Срок (план)
                    </el-col>
                    <el-col :sm="4">
                        Завершено
                    </el-col>
                    <el-col :sm="7"/>

                </el-row>

                <RubezhPlanOneTask
                    v-for="(task, index) in getTasks(plan.id, false, true)"
                    :key="index"
                    :tasks="tasks"
                    :task="task"
                    :index="getTaskIndex(index)"
                    @getModule="$emit('getModule')"
                />

                <RubezhPlanOneTask
                    v-for="(task, index) in getTasks(plan.id, true)"
                    :key="index"
                    :tasks="tasks"
                    :task="task"
                    :index="getDoneTaskIndex(index)"
                    :done="true"
                    @getModule="$emit('getModule')"
                />

                <div class="planFooter">

                    <el-row :gutter="15">

                        <el-col :sm="1"/>
                        <el-col :sm="8">
                            <el-button v-if="!plan.plan_date_fact" class="mb-15" @click="openNewTaskDialog(plan.id)"
                                       type="text">+ Добавить задачу
                            </el-button>
                        </el-col>

                    </el-row>

                </div>


            </div>
        </template>

        <template v-else-if="plan.plan_type === 'production'">

            <RubezhPlanOnePlanProduction
                :plan="plan"
                :production="production"
                :production-histories="productionHistories"
                @openProductionHistoryDialog="openNewProductionHistoryDialog"
                @getModule="$emit('getModule')"
            />

        </template>

        <div v-if="mobile" class="mt-15 planFooter">
            <el-button v-if="!plan.plan_date_fact" @click="endPlan(plan)">Завершить</el-button>
            <!--                        <el-button :icon="icons.edit"/>-->
            <el-button @click="removePlan(plan)" :icon="icons.delete"/>
        </div>
    </div>

    <el-dialog
        v-model="newTaskDialog"
        title="Добавить задачу"
        :width="drawerSize"
        :destroy-on-close="true"
    >

        <el-form ref="form" :model="newTaskForm" size="small" label-position="top">

            <FormTextarea
                v-model="newTaskForm.task_name"
                label="Опишите задачу"
                placeholder="Например: залить пол в цеху"
            />

            <FormDateTimePicker
                v-model="newTaskForm.task_date_done"
                label="Срок выполнения"
            />

            <FormAddButton
                title="Создать задачу"
                :w100="true"
                @action="createTask()"
            />

        </el-form>

    </el-dialog>

    <el-dialog
        v-model="newProductionHistoryDialog"
        title="Добавить значение"
        :width="drawerSize"
        :destroy-on-close="true"
    >

        <el-form ref="form" :model="newProductionHistoryForm" size="small" label-position="top">

            <FormDateTimePicker
                v-model="newProductionHistoryForm.production_history_date"
                label="Дата"
            />

            <FormNumber
                v-model="newProductionHistoryForm.production_history_value"
                label="Значение"
                placeholder="Количество"
                precision="2"
                step="0.01"
            />

            <FormAddButton
                title="Добавить значение"
                :w100="true"
                @action="createProductionHistory()"
            />

        </el-form>

    </el-dialog>
</template>

<script>
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import {httpService} from "@/services/http.service";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon";
import FormTextarea from "@/views/components/Form/FormTextarea";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";
import FormAddButton from "@/views/components/Form/FormAddButton";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import moment from "moment/moment";
import RubezhPlanOneTask from "@/views/custom/rubezhPlan/components/RubezhPlanOneTask";
import FormNumber from "@/views/components/Form/FormNumber";
import RubezhPlanOnePlanProduction from "@/views/custom/rubezhPlan/components/RubezhPlanOnePlanProduction";

export default {
    name: 'RubezhPlanOnePlan',
    components: {
        RubezhPlanOnePlanProduction,
        FormNumber, RubezhPlanOneTask, FormAddButton, FormDateTimePicker, FormTextarea, EmptyListIcon
    },
    props: ['plan', 'tasks', 'production', 'productionHistories'],
    inject: ['api'],
    mixins: [iconsMixin, functionsMixin, mobileCheckMixin],
    data() {
        return {
            newTaskDialog: false,
            newProductionHistoryDialog: false,
            newTaskForm: {
                task_plan_id: null,
                task_name: null,
                task_date_done: moment()
            },
            newProductionHistoryForm: {
                production_history_value: null,
                production_history_date: moment(),
                production_history_plan_id: this.plan ? this.plan.id : null,
                production_history_production_id: this.production ? this.production.id : null
            },
        }
    },
    methods: {
        openNewProductionHistoryDialog() {
            this.newProductionHistoryDialog = true;
        },
        getTasks(planId, onlyDone, onlyNotDone) {
            if (this.tasks) {
                var arr = this.tasks.filter(function (el) {
                    return el.task_plan_id === planId.toString()
                })

                if (onlyDone) arr = arr.filter(function (el) {
                    return el.task_date_fact
                })

                if (onlyNotDone) arr = arr.filter(function (el) {
                    return !el.task_date_fact
                })

                if (arr) {
                    return arr
                } else {
                    return []
                }
            }
        },
        getDoneIndex(planId, index) {
            var notDoneLength = this.getTasks(planId, false, true).length
            return index + notDoneLength
        },
        getTaskIndex(index) {
            var number = index + 1
            return index < 10 ? '0' + number : number
        },
        getDoneTaskIndex(index) {
            const arr = this.tasks.filter(el => el.task_plan_id === this.plan.id.toString())

            const notDoneLength = arr.filter(function (el) {
                return !el.task_date_fact
            }).length

            let number = notDoneLength + index + 1

            return index < 10 ? '0' + number : number
        },
        openNewTaskDialog(planId) {
            this.newTaskForm.task_plan_id = planId
            this.newTaskDialog = true
        },
        createTask() {
            httpService().post(this.api.custom_module.doAction, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
                data: this.newTaskForm,
                action: 'createTask'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    window.setTimeout(() => {
                        this.newTaskDialog = false
                        this.newTaskForm = {
                            task_plan_id: null,
                            task_name: null,
                            task_date_done: moment()
                        }
                    }, 50)
                this.$emit('getModule')
            }).finally(() => {
                this.loadingButton = false
            })
        },
        createProductionHistory() {
            httpService().post(this.api.custom_module.doAction, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
                data: this.newProductionHistoryForm,
                action: 'createProductionHistory'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    window.setTimeout(() => {
                        this.newProductionHistoryDialog = false
                        this.newProductionHistoryForm.production_history_value = null
                        this.newProductionHistoryForm.production_history_date = moment()
                    }, 50)
                this.$emit('getModule')
            }).finally(() => {
                this.loadingButton = false
            })
        },
        removePlan(plan) {
            this.loadingButton = true

            this.$confirm(
                'Вы действительно хотите удалить план?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: plan,
                        action: 'removePlan'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        endPlan(plan) {
            this.loadingButton = true

            this.$confirm(
                'Вы подтверждаете завершение плана ' + plan.plan_name + '?',
                'Подтвердите завершение',
                {
                    confirmButtonText: 'Завершить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: plan,
                        action: 'endPlan'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
    },
    watch: {
        'plan': {
            handler: function () {
                if (this.plan) {
                    this.newProductionHistoryForm.production_history_plan_id = this.plan.id
                    this.newProductionHistoryForm.production_history_production_id = this.production ? this.production.id : null
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
