<template>
    <ManageDeliveriesOrders
        :order-group-item="orderGroupItem"
        :show-add-button="true"
        :orders="orders"
        @update="$emit('update')"
        :simple="true"
        :paginate="paginate"
    />
</template>

<script>
import ManageDeliveriesOrders from "@/views/dispatch/components/actions/ManageDeliveriesOrders.vue";

export default {
    name: 'OrderMixOrders',
    components: {ManageDeliveriesOrders},
    props: ['orders', 'orderGroupItem', 'paginate'],
    data() {
        return {

        }
    }
}
</script>

<style scoped>

</style>
