<template>
    <div v-loading="loading">
        <el-card shadow="never">
            <el-row align="middle" justify="space-between">
                <el-col :span="20">
                    <div class="text-muted-extra-small">
                        Текущий баланс
                    </div>
                    <div style="font-size: var(--el-font-size-extra-large); color:  var(--el-color-primary);">
                        {{ formatNumber(balance) }} ₽
                    </div>
                </el-col>
                <el-col :span="4" class="d-flex d-flex-end">
                    <el-button @click="openPaymentModal" type="primary">Пополнить баланс</el-button>
                </el-col>
            </el-row>
        </el-card>

        <el-card shadow="never" class="mt-30">
            <div class="text-muted-extra-small">
                Ваш тариф
            </div>
            <div class="tariff-name">
                {{ tariffName }}
                <span v-if="tariffPrice > 0"> - {{ formatNumber(tariffPrice) }} ₽ / {{period}} мес.</span>
                <span v-if="period > 1 && false"> (оплата за {{period}} {{ monthWordByMonthCount[period] }})</span>
            </div>
            <div class="mt-10" v-if="false"><strong>Подключено 2 дополнительных модуля (на сумму 3000₽)</strong></div>
            <template v-if="tariffPrice > 0 && nextPayAmount > 0">
                <div class="text-muted-extra-small" style="color: var(--el-color-info-dark-2)" v-if="tariffPayday !== 0">
                    Следующая дата списания - {{ getDateFromUnix(tariffPayday, "D MMMM YYYY") }}. Будет списано {{ formatNumber(nextPayAmount) }}₽
                    (тариф)
                </div>
                <div class="text-muted-extra-small" style="color: var(--el-color-info-dark-2)" v-else>
                    Оплата не произошла. Пополните баланс.
                </div>
            </template>

            <div class="d-flex mt-30">
                <el-button @click.prevent="$router.push('/settings/payment/plans')" type="primary">Изменить тариф</el-button>
                <router-link to="/settings/modules" class="el-button secondary-button">Настроить модули</router-link>
            </div>
        </el-card>

        <el-card shadow="never" class="mt-30">
            <div class="text-muted-extra-small">
                История движения денежных средств
            </div>
            <div class="mt-15 history-header-buttons">
                <div>
                    <div class="range-select-group">
                        <div @click="setRange('month')"
                            :class="{ 'selected': selectedRange === 'month' }">Месяц</div>
                        <div @click="setRange('quarter')"
                            :class="{ 'selected': selectedRange === 'quarter' }">Квартал</div>
                        <div @click="setRange('year')"
                            :class="{ 'selected': selectedRange === 'year' }">Год</div>
                    </div>
                </div>

                <div>
                    <div class="operation-type-select-group">
                        <div @click="setOperationType('in')"
                            :class="{ 'selected': selectedOperationType === 'in' }">Пополнения</div>
                        <div @click="setOperationType('out')"
                            :class="{ 'selected': selectedOperationType === 'out' }">Списания</div>
                        <div @click="setOperationType('all')"
                            :class="{ 'selected': selectedOperationType === 'all' }">Все</div>
                    </div>
                </div>

                <div class="d-flex d-flex-end">
                    <div class="el-button secondary-button">
                        Запросить акт
                    </div>
                </div>
            </div>

            <div class="mt-15">
                <el-table :data="events" empty-text="Платежи не найдены" style="width: 100%">
                    <el-table-column prop="date" label="Дата" sortable>
                        <template v-slot="scope">
                            <div><strong>{{ getDateFromUnix(scope.row.date, 'DD MMM YYYY') }}</strong></div>
                            <div class="text-muted-extra-small">{{ getDateFromUnix(scope.row.date, 'HH:mm') }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="text" label="Тип операции" sortable>
                        <template v-slot="scope">
                            <div>
                                <strong v-if="scope.row.type === 1">Пополнение</strong>
                                <strong v-else-if="scope.row.type === 2">Оплата тарифа</strong>
                            </div>
                            <div class="text-muted-extra-small">
                                {{ scope.row.text }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="text" label="Сумма" sortable>
                        <template v-slot="scope">
                            <div class="text-500" :class="{'color-success' : scope.row.type === 1 , 'color-error': scope.row.type === 2}">
                                {{ scope.row.type === 1 ? '+' : '-' }}{{ formatNumber(scope.row.value) }} ₽
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import functionsMixin from "../../../mixins/functionsMixin";
import { httpService } from "@/services/http.service";
import {mapActions} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: "SubscriptionAndPaymentSettings",
    mixins: [functionsMixin],
    data() {
        return {
            module: null,
            loading: true,
            loadingTitle: true,
            loadingButton: true,
            balance: 0,
            tariffName: null,
            tariffDescription: null,
            tariffOptions: [],
            tariffPayday: null,
            nextPayAmount: 0,
            period: 1,
            upgrades: [],
            events: [],
            tariffPrice: 0,
            tariffPricePeriod: 0,
            selectedRange: 'month',
            selectedOperationType: 'all',
            monthWordByMonthCount: {3: 'месяца', 6: 'месяцев', 12: 'месяцев'}
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet();
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setPaymentModalModalStatus'
        ]),
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            httpService().post(this.api.settings.getPaymentPage).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.loadingTitle = false
                this.module = data.data
                this.balance = this.module.balance
                this.tariffName = this.module.tariffName
                this.tariffDescription = this.module.tariffDescription.text
                this.tariffOptions = this.module.tariffDescription.options
                this.tariffPayday = this.module.payday
                this.period = this.module.periodInMonth
                this.tariffPrice = this.module.tariffPrice
                this.nextPayAmount = this.module.nextPayAmount
                this.tariffPricePeriod = this.module.tariffPricePeriod
                this.events = this.module.events
                this.upgrades = this.module.upgrades
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },

        setRange(range) {
            this.selectedRange = range;
        },

        setOperationType(type) {
            this.selectedOperationType = type;
        },
        openPaymentModal() {
            this.setPaymentModalModalStatus(true);
        }
    }
}
</script>

<style>
.tariff-name {
    /*font-size: var(--el-font-size-extra-large);*/
    color:  var(--el-color-primary);
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;

    letter-spacing: -0.02em;
    /*color: #1984E6;*/
}
</style>
