<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 15 3-3m0 0-3-3m3 3H4m5-5V5a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1v-2"/>
    </svg>
</template>

<script>
export default {
    name: "LogoutIcon"
}
</script>

<style scoped>

</style>
