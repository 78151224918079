<template>
    <Auth title="Отчет по работе водителей">
        <div class="card">
            <div class="card-body">
                <el-form label-position="top">

                    <el-row gutter="15">

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateStart"
                                label="Начало периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8">
                            <FormDateTimePicker
                                v-model="filter.dateEnd"
                                label="Окончание периода"
                                placeholder="Выберите дату"
                                type="datetime"
                            />
                        </el-col>

                        <el-col :sm="8">
                            <el-form-item label="Водитель">
                                <el-select v-model="driverId" filterable clearable placeholder="Выберите водителя">
                                    <el-option
                                        v-for="item in driversList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <!--            <el-col :sm="24" v-if="showDetails">-->
                        <!--                <el-form-item label="Столбцы таблицы" class="mb-0">-->
                        <!--                    <el-checkbox-group v-model="columns">-->
                        <!--                        <el-checkbox v-for="(column, index) in columnsData" :key="index" :label="column.id">-->
                        <!--                            {{ column.name }}-->
                        <!--                        </el-checkbox>-->
                        <!--                    </el-checkbox-group>-->
                        <!--                </el-form-item>-->
                        <!--            </el-col>-->

                    </el-row>

                    <div class="w-100">
                        <el-button
                            class="color-grey"
                            v-if="showDetails"
                            @click="setShowDetails(false)"
                            style="margin-top: 5px; margin-bottom: 15px;"
                            :icon="icons.top"
                            type="text">
                            Скрыть блок настроек
                        </el-button>
                    </div>

                    <div class="TableHeadBlock">

                        <div>

                            <el-button :loading="loading" type="primary"
                                       @click="moduleGet">Сформировать отчет
                            </el-button>

                            <el-button plain :icon="icons.back"
                                       @click="$router.push('/report')">Другой отчет
                            </el-button>

                            <el-button
                                @click="setShowDetails(true)"
                                v-if="!showDetails"
                                v-show="false"
                            >
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 15L21 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          stroke="currentColor"/>
                                    <path d="M3 15H5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          stroke="currentColor"/>
                                    <path
                                        d="M7.5 17.5C8.88071 17.5 10 16.3807 10 15C10 13.6193 8.88071 12.5 7.5 12.5C6.11929 12.5 5 13.6193 5 15C5 16.3807 6.11929 17.5 7.5 17.5Z"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor"/>
                                    <path d="M20 9H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          stroke="currentColor"/>
                                    <path d="M3 9H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          stroke="currentColor"/>
                                    <path
                                        d="M16.5 11.5C17.8807 11.5 19 10.3807 19 9C19 7.61929 17.8807 6.5 16.5 6.5C15.1193 6.5 14 7.61929 14 9C14 10.3807 15.1193 11.5 16.5 11.5Z"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor"/>
                                </svg>
                                <span>Фильтры</span>
                            </el-button>
                        </div>

                        <el-button plain :icon="icons.download" @click="exportToExcel">
                            Экспорт в XLSX
                        </el-button>
                    </div>

                </el-form>

                <hr style="margin-top: 20px"/>

                <div v-if="loading" class="text-center mt-30">
                    <ReportLoader/>

                    <h3>Формирование отчета</h3>
                </div>

                <div v-if="!loading">
                    <el-table
                        ref="table"
                        empty-text="Информация не найдена"
                        class="mt-15 mixDriversTable"
                        show-summary
                        :summary-method="getSummaries"
                        sum-text="Итого"
                        v-loading="loading"
                        v-if="!driverId"
                        :data="displayData">

                        <el-table-column
                            label=""
                            width="85px"
                        >
                            <template v-slot="scope">
                                <el-button :icon="icons.arrowDown" @click="toogleExpand(scope.row, 'table')"
                                           circle></el-button>
                            </template>
                        </el-table-column>
                        <el-table-column width="350" prop="name" label="ФИО">
                            <template v-slot="scope">
                                <span class="textMedium neutral900 cutOverflow">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deliveries" label="Доставок">
                            <template v-slot="scope">
                                <ReportDeliveriesIcon :not-active="scope.row.deliveries === 0"/>
                                <span :class="'textMedium ' + (scope.row.deliveries === 0 ? 'neutral400' : 'primary600')">{{
                                        formatNumber(scope.row.deliveries)
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="mileage" label="Пробег (км)">
                            <template #header>
                                <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="Фактический пробег по всем доставкам"
                                    placement="top"
                                >
                                    Пробег (км)
                                </el-tooltip>
                            </template>
                            <template v-slot="scope">
                                <ReportMixerIcon :not-active="scope.row.mileage === 0"/>
                                <span :class="'textMedium ' + (scope.row.mileage === 0 ? 'neutral400' : 'primary600')">{{
                                        formatNumber(scope.row.mileage)
                                    }} км.</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="done" label="Объем продукции">
                            <template #header>
                                <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="Сумма объема по отгрузкам"
                                    placement="top"
                                >
                                    Объем продукции
                                </el-tooltip>
                            </template>
                            <template v-slot="scope">
                                <ReportDoneIcon :not-active="scope.row.done === 0"/>
                                <span :class="'textMedium ' + (scope.row.done === 0 ? 'neutral400' : 'primary600')">{{
                                        formatNumber(scope.row.done)
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="checkColumn('plane_time_worked')" prop="plane_time_worked"
                                         label="Факт. время работы (ч)">
                            <template #header>
                                <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="Фактическое кол-во часов по доставкам"
                                    placement="top"
                                >
                                    Факт. время работы (ч)
                                </el-tooltip>
                            </template>
                            <template v-slot="scope">
                                <ReportTimeIcon :not-active="scope.row.plane_time_worked === 0"/>
                                <span :class="'textMedium ' + (scope.row.plane_time_worked === 0 ? 'neutral400' : 'success400')">{{
                                        formatNumber(scope.row.plane_time_worked)
                                    }} ч.</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="170" v-if="checkColumn('work_schedule')" prop="work_schedule" label="График работы">
                            <template #default="scope">
                                <span class="textMedium neutral200">{{ scope.row.work_schedule ?? '-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="checkColumn('payment_type')" prop="payment_type" label="Тип оплаты"/>
                        <el-table-column v-if="checkColumn('salary_amount')" :formatter="sumFormatter" prop="salary_amount"
                                         label="Размер оклада"/>
                        <el-table-column v-if="checkColumn('overtime_rate')" :formatter="sumFormatter" prop="overtime_rate"
                                         label="Стоимость переработки"/>
                        <el-table-column v-if="checkColumn('delivery_rate')" :formatter="sumFormatter" prop="delivery_rate"
                                         label="Стоимость доставки"/>
                        <el-table-column v-if="checkColumn('idle_rate')" :formatter="sumFormatter" prop="idle_rate"
                                         label="Стоимость простоя на объекте"/>
                        <el-table-column v-if="checkColumn('mileage_rate')" :formatter="sumFormatter" prop="mileage_rate"
                                         label="Стоимость км пути"/>
                        <el-table-column v-if="checkColumn('overtime_cost')" :formatter="sumFormatter" prop="overtime_cost"
                                         label="Сумма за переработки"/>
                        <el-table-column v-if="checkColumn('delivery_cost')" :formatter="sumFormatter" prop="delivery_cost"
                                         label="Сумма за доставки"/>
                        <el-table-column v-if="checkColumn('idle_cost')" :formatter="sumFormatter" prop="idle_cost"
                                         label="Сумма за простой на объекте"/>
                        <el-table-column v-if="checkColumn('mileage_cost')" :formatter="sumFormatter" prop="mileage_cost"
                                         label="Сумма за км пути"/>
                        <el-table-column v-if="checkColumn('salary')" :formatter="sumFormatter" prop="salary" label="Сумма оклада"/>
                        <el-table-column v-if="checkColumn('amount')" :formatter="sumFormatter" prop="amount" label="К оплате"/>
                        <el-table-column type="expand" width="1">
                            <template #default="props">
                                <div class="reportBody">

                                    <el-row :gutter="20">

                                        <el-col :sm="12">
                                            <div class="reportBodyItem">
                                                <div class="reportBodyItemBody">
                                                    <div class="textXS textMedium primary600 mb-15">Доставки</div>
                                                    <div class="textXS textRegular neutral300">
                                                        <div class="d-flex-full">
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.deliveries ? formatNumber(props.row.deliveries) : 0
                                                        }}</span>
                                                                <span class="cutOverflow">Кол-во доставок</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.mileage ? formatNumber(props.row.mileage) : 0
                                                        }} км.</span>
                                                                <span class="cutOverflow">Пробег факт.</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.mileage_doubtful ? formatNumber(props.row.mileage_doubtful) : 0
                                                        }} км.</span>
                                                                <span class="cutOverflow">Пробег сомнительный</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.idle_time ? formatNumber(props.row.idle_time) : 0
                                                        }} мин.</span>
                                                                <span class="cutOverflow">Простои на объектах</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :sm="12">
                                            <div class="reportBodyItem">
                                                <div class="reportBodyItemBody">
                                                    <div class="textXS textMedium primary600 mb-15">Время</div>
                                                    <div class="textXS textRegular neutral400">
                                                        <div class="d-flex-full">
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.plane_time ? formatNumber(props.row.plane_time) : 0
                                                        }} ч.</span>
                                                                <span class="cutOverflow">Рабочее время</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.plane_time_worked ? formatNumber(props.row.plane_time_worked) : 0
                                                        }} ч.</span>
                                                                <span class="cutOverflow">Отработанное время</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.overtime ? formatNumber(props.row.overtime) : 0
                                                        }} ч.</span>
                                                                <span class="cutOverflow">Переработка</span>
                                                            </div>
                                                            <div>
                                                    <span class="textMD textMedium neutral700 d-block mb-5">{{
                                                            props.row.free_time ? formatNumber(props.row.free_time) : 0
                                                        }} ч.</span>
                                                                <span class="cutOverflow">Свободное время</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>

                                    </el-row>

                                    <el-tabs v-model="reportType" class="reportTabs" type="border-card">
                                        <el-tab-pane label="Отчет о доставках" :name="false">
                                            <template v-if="props.row.ordersData.length > 0">
                                                <div class="reportMixItem">
                                                    <div class="reportMixItemBody neutral200 textRegular">
                                                        <el-row :gutter="16">
                                                            <el-col :sm="2">
                                                                № отгрузки
                                                            </el-col>
                                                            <el-col :sm="2">
                                                                Дата
                                                            </el-col>
                                                            <el-col :sm="4">
                                                                Заказчик
                                                            </el-col>
                                                            <el-col :sm="2">
                                                                ТС
                                                            </el-col>
                                                            <el-col :sm="4">
                                                                Рецепт
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <el-tooltip
                                                                    class="box-item"
                                                                    effect="dark"
                                                                    content="Объем заказанный клиентом"
                                                                    placement="top"
                                                                >
                                                                    О-З
                                                                </el-tooltip>
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <el-tooltip
                                                                    class="box-item"
                                                                    effect="dark"
                                                                    content="Объем фактический"
                                                                    placement="top"
                                                                >
                                                                    О-Ф
                                                                </el-tooltip>
                                                            </el-col>
                                                            <el-col :sm="7">
                                                                Адрес
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <el-tooltip
                                                                    class="box-item"
                                                                    effect="dark"
                                                                    content="Простой (мин.)"
                                                                    placement="top"
                                                                >
                                                                    П
                                                                </el-tooltip>
                                                            </el-col>
                                                        </el-row>
                                                    </div>
                                                </div>
                                                <div class="reportMixItem" v-for="(order, index) in props.row.ordersData" :key="index">
                                                    <div class="reportMixItemBody textRegular">
                                                        <el-row :gutter="16">
                                                            <el-col :sm="2">
                                                                <span class="neutral400">{{ order.doc ? formatNumber(order.doc) : '-' }}</span>
                                                            </el-col>
                                                            <el-col :sm="2">
                                                                <span class="textMedium neutral900">{{ order.date }}</span>
                                                            </el-col>
                                                            <el-col :sm="4">
                                                                <span class="textMedium success400">{{ order.company }}</span>
                                                            </el-col>
                                                            <el-col :sm="2">
                                                                {{ order.vehicle }}
                                                            </el-col>
                                                            <el-col :sm="4">
                                                                <span class="cutOverflow">{{ order.good }}</span>
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <span class="textMedium primary600">{{ order.total }}</span>
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <span :class="'textMedium ' + (order.total !== order.done ? 'success400' : 'primary600')">{{ order.done }}</span>
                                                            </el-col>
                                                            <el-col :sm="7">
                                                                <div class="cutOverflow w-100">{{ order.address }}</div>
                                                            </el-col>
                                                            <el-col :sm="1">
                                                                <span :class="'textMedium ' + (order.overtime ? 'warning300' : 'neutral400')">{{ order.overtime ? formatNumber(order.overtime) : '-' }}</span>
                                                            </el-col>
                                                        </el-row>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                Доставок за указанный период не найдено
                                            </template>
                                        </el-tab-pane>
                                        <el-tab-pane label="Отчет по рабочим дня" :name="true">
                                            <el-table :data="props.row.orders" ref="table_days" class="w-100">
                                                <el-table-column
                                                    label=""
                                                    width="65px"
                                                >
                                                    <template v-slot="scope">
                                                        <el-button :icon="icons.arrowDown"
                                                                   @click="toogleExpand(scope.row, 'table_days')"
                                                                   circle></el-button>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Дни" prop="data.date"/>
                                                <el-table-column label="Рабочее время (ч)" prop="data.plane_time"/>
                                                <el-table-column label="Отработано (ч)" prop="data.day_time_worked"/>
                                                <el-table-column label="Переработка (ч)" prop="data.overtime"/>
                                                <el-table-column label="Свободное время (ч)" prop="data.free_time"/>
                                                <el-table-column label="Доставок" prop="data.deliveries"/>
                                                <el-table-column label="Простои на объекте (ч)" prop="data.idle_time"/>
                                                <el-table-column label="Пробег (км)" prop="data.mileage"/>
                                                <el-table-column label="Пробег сомнительный (км)"
                                                                 prop="data.mileage_doubtful">
                                                    <template #default="scope">
                                                        <div
                                                            v-bind:class="[scope.row.data.mileage_doubtful ? 'warning' : '']">
                                                            {{
                                                                scope.row.data.mileage_doubtful ? scope.row.data.mileage_doubtful : '-'
                                                            }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <!--                            -->
                                                <!--                            <el-table-column label="Сумма за переработки" prop="data.overtime_cost"/>-->
                                                <!--                            <el-table-column label="Сумма за доставки" prop="data.delivery_cost"/>-->
                                                <!--                            <el-table-column label="Сумма за простои на объекте" prop="data.idle_cost"/>-->
                                                <!--                            <el-table-column label="Сумма за км пути" prop="data.mileage_cost"/>-->
                                                <!--                            -->
                                                <el-table-column type="expand" width="1">
                                                    <template #default="props">
                                                        <div>
                                                            <el-table :data="props.row.orders">
                                                                <el-table-column label="Отгрузки" prop="doc"/>
                                                                <el-table-column label="Отработано (ч)" prop="time"/>
                                                                <el-table-column label="Простой (ч)" prop="idle_time"/>
                                                                <el-table-column label="Пробег общий (км)"
                                                                                 prop="dist_fact"/>
                                                                <el-table-column label="Пробег сомнительный (км)">
                                                                    <template #default="scope">
                                                                        <div
                                                                            v-bind:class="[scope.row.dist_doubtful ? 'warning' : '']">
                                                                            {{
                                                                                scope.row.dist_doubtful > 0 ? scope.row.dist_doubtful : '-'
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                            </el-table>
                                                        </div>
                                                    </template>
                                                </el-table-column>

                                            </el-table>
                                        </el-tab-pane>
                                    </el-tabs>


                                </div>
                            </template>
                        </el-table-column>


                    </el-table>

                    <div v-if="driverId">
                        <div>
                            <el-row :gutter="20">
                                <el-col :span="6">
                                    <div class="widget-header-text">
                                        <p>График работы</p>
                                        <h2>
                                            {{ driverData.work_schedule }}
                                        </h2>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="widget-header-text">
                                        <p>Тип оплаты</p>
                                        <h2>
                                            -
                                        </h2>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="widget-header-text">
                                        <p>Рейт</p>
                                        <h2>
                                            {{ driverData.salary_amount }}
                                        </h2>
                                    </div>
                                </el-col>
                                <el-col :span="6">
                                    <div class="widget-header-text">
                                        <p>Доплата за переработки</p>
                                        <h2>
                                            {{ driverData.idle_cost }}
                                        </h2>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <el-table
                            ref="table-driver"
                            show-summary
                            empty-text="Информация не найдена"
                            class="mt-15"
                            v-loading="loading"
                            v-if="driverId && driverData.orders"
                            :data="driverData.orders">

                            <el-table-column prop="data.date" label="Дата"/>
                            <el-table-column prop="data.plane_time" label="Рабочее время"/>
                            <el-table-column prop="data.day_time_worked" label="Отработано"/>
                            <el-table-column prop="data.overtime" label="Переработка"/>
                            <el-table-column prop="data.idle_time" label="Простой"/>
                            <el-table-column prop="data.deliveries" label="Доставок"/>
                            <el-table-column prop="data.mileage" label="Пробег"/>
                            <el-table-column prop="data.overtime_cost" label="Сумма за переработки"/>
                            <el-table-column label="Сумма за доставки" prop="data.delivery_cost"/>
                            <el-table-column label="Сумма за простои на объекте" prop="data.idle_cost"/>
                            <el-table-column label="Сумма за км пути" prop="data.mileage_cost"/>
                            <el-table-column prop="data.salary" label="Оклад"/>
                            <el-table-column label="К оплате">
                                <template #default="scope">
                                    <div>
                                        {{
                                            (scope.row.data.overtime_cost + scope.row.data.delivery_cost + scope.row.data.idle_cost + scope.row.data.mileage_cost + scope.row.data.salary)
                                        }}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <TablePagination
                        v-model="pageSize"
                        :total="valueLength"
                        :loading="loading"
                        @action="setPage"
                    />
                </div>
            </div>
        </div>
    </Auth>
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import getTimezone from '@/mixins/getTimezone'
import {httpService} from '@/services/http.service'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from '@/mixins/iconsMixin'
import ReportMixerIcon from "@/views/components/Icons/Report/ReportMixerIcon.vue";
import ReportDeliveriesIcon from "@/views/components/Icons/Report/ReportDeliveriesIcon.vue";
import ReportTimeIcon from "@/views/components/Icons/Report/ReportTimeIcon.vue";
import ReportDoneIcon from "@/views/components/Icons/Report/ReportDoneIcon.vue";
import Auth from "@/views/layouts/Auth.vue";
import * as dayjs from "dayjs";

export default {
    name: 'ReportMixDrivers',
    components: {
        Auth,
        ReportDoneIcon,
        ReportTimeIcon,
        ReportDeliveriesIcon, ReportMixerIcon, FormDateTimePicker, ReportLoader, TablePagination
    },
    props: ['settings'],
    inject: ['api'],
    mixins: [tableMixin, getTimezone, functionsMixin, iconsMixin],
    created() {
        //this.getMixData()
        this.moduleGet()
    },
    computed: {
        driversList: function () {
            let result = []
            this.values.forEach((column) => {
                result.push({
                    id: column.id,
                    name: column.name
                })
            })
            return result
        },
        driverData: function () {
            let result = {}
            if (this.driverId) {
                this.values.forEach((row) => {
                    if (row.id === this.driverId) {
                        result = row
                    }
                })
            }
            return result
        }
    },
    data() {
        return {
            filter: {
                dateStart: this.$route.query.dateStart ? this.$route.query.dateStart : null,
                dateEnd: this.$route.query.dateEnd ? this.$route.query.dateEnd : null,
                driverId: this.$route.query.driverId ? this.$route.query.driverId : null,
                doc_number: this.$route.query.doc_number ? this.$route.query.doc_number : null,
                goods: this.$route.query.goods ? this.$route.query.goods : [],
            },
            pageSize: 50,
            total: null,
            dateStart: null,
            dateEnd: null,
            goods: [],
            companies: [],
            operations: [],
            vehicles: [],
            drivers: [],
            driverId: null,
            showDetails: false,
            loading: true,
            reportType: false,
            columns: JSON.parse(localStorage.getItem('reportMixDrivers')) ?? [
                'name',
                'plane_time',
                'plane_time_worked',
                'overtime',
                'free_time',
                'deliveries',
                'idle_time',
                'mileage',
                'mileage_doubtful',
                'work_schedule'
            ],
            columnsData: [
                {id: 'name', name: 'ФИО'},
                {id: 'plane_time', name: 'Рабочее время (ч)'},
                {id: 'plane_time_worked', name: 'Отработано (ч)'},
                {id: 'overtime', name: 'Переработка (ч)'},
                {id: 'free_time', name: 'Свободное время (ч)'},
                {id: 'deliveries', name: 'Доставок'},
                {id: 'mileage', name: 'Пробег (км)'},
                {id: 'mileage_doubtful', name: 'Пробег сомнительный (ч)'},
                {id: 'work_schedule', name: 'График работы'},
                {id: 'payment_type', name: 'Тип оплаты'},
                {id: 'salary_amount', name: 'Размер оклада'},
                {id: 'cost_processing', name: 'Стоимость переработки'},
                {id: 'cost_delivery', name: 'Стоимость доставки'},
                {id: 'cost_delivery_object', name: 'Стоимость простоя на объекте'},
                {id: 'cost_km', name: 'Стоимость км пути'},
                {id: 'sum_processing', name: 'Сумма за переработки'},
                {id: 'sum_deliveries', name: 'Сумма за доставки'},
                {id: 'sum_staying_object', name: 'Сумма за простой на объекте'},
                {id: 'sum_km_all', name: 'Сумма за км пути'},
                {id: 'amount', name: 'К оплате'},

            ]
        }
    },
    methods: {
        setShowDetails(value) {
            this.showDetails = value;
        },
        toogleExpand(row, tb) {
            let $table = this.$refs[tb]
            this.selectGood = row
            $table.toggleRowExpansion(row)
        },
        moduleGet() {
            this.loading = true
            this.showDetails = false
            this.updateRouterQuery();

            httpService().post(this.api.report.mixDriversReport, {
                reportKey: this.$route.meta.key,
                settings: this.$route.query,
                filter: this.filter,
                timezone: this.getTimezone()
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.dateStart = data.dateStart
                this.dateEnd = data.dateEnd
                this.loading = false
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getMixerName(mixer) {
            var names = ['Первый', 'Второй', 'Третий', 'Четвертый', 'Пятый', 'Шестой', 'Седьмой', 'Восьмой', 'Девятый', 'Десятый']
            return names[mixer - 1]
        },
        getMixData() {
            httpService().post(this.api.report.getMixData, {
                reportKey: this.$route.meta.key,
                moduleId: this.$route.query.moduleId
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.goods = data.goods
                this.vehicles = data.vehicles
                this.companies = data.companies
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        sumFormatter(row, column, cellValue) {
            return new Intl.NumberFormat("ru-RU").format(cellValue);
        },
        getSummaries(param) {
            const {columns, data} = param
            const cols = [2, 3, 4, 5, 7, 8, 21]
            let sums = []

            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Итого'
                    return
                }
                if (cols.includes(index)) {

                    const values = data.map((item) => Number(item[column.property]))
                    if (!values.every((value) => Number.isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!Number.isNaN(value)) {
                                return prev + curr
                            } else {
                                return prev
                            }
                        })
                        sums[index] = Intl.NumberFormat("ru-RU").format(sums[index])
                    } else {
                        sums[index] = 'N/A'
                    }
                }
            })

            return sums
        },
        updateRouterQuery() {
            let updatedQuery = {...this.$route.query, ...this.filter};
            this.$router.push({ name: 'ReportMixDrivers', query: updatedQuery });
        },
        checkColumn(name) {
            return this.columns.find(el => el === name)
        },
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        exportToExcel() {
            // this.loadingButton = true

            httpService().post(this.api.exportExcel.exportDriversReport, {
                settings: this.$route.query,
                filter: this.filter,
                columns: this.columns,
                timezone: this.getTimezone()
            }, {
                responseType: 'blob'
            }).then((response) => {
              const url = window.URL.createObjectURL(response.data)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `Отчет_БетонныйЗавод_Водители_${dayjs(this.filter.dateStart).format('DD-MM-YYYY_HH-mm')}_${dayjs(this.filter.dateEnd).format('DD-MM-YYYY_HH-mm')}.xlsx`)
              document.body.appendChild(link)
              link.click()

              let data = response.data

              if (data.success)
                this.loadingButton = false
            }).catch(() => {
              this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
              })
              this.loadingButton = false
            })
        }
    },
    watch: {
        'columns': {
            handler () {
                localStorage.setItem('reportMixDrivers', JSON.stringify(this.columns));
            }
        }
    }
}
</script>

<style>
.warning {
    color: #E6A23C;
}
</style>
