const getUrlParams = () => {
    const searchURL = new URL(window.location);

    return {
        firebase_push_id: searchURL.searchParams.get('firebase_push_id')
    }
}

const setCookie = (name, value, options = {}) => {
    options = {
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const sendCookies = () => {
    if (getUrlParams().firebase_push_id) {
        setCookie('firebase_push_id', getUrlParams().firebase_push_id, { 'max-age': 3600 })
    }

    if (getCookie('firebase_push_id')) {
        return {
            firebase_push_id: getCookie('firebase_push_id')
        }
    }
}
