<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.6654 4.82635C14.6659 4.73861 14.6491 4.65164 14.6159 4.57041C14.5827 4.48919 14.5338 4.41531 14.472 4.35302L11.6454 1.52635C11.5831 1.46456 11.5092 1.41568 11.428 1.3825C11.3468 1.34933 11.2598 1.33251 11.172 1.33302C11.0843 1.33251 10.9973 1.34933 10.9161 1.3825C10.8349 1.41568 10.761 1.46456 10.6987 1.52635L8.81204 3.41302L1.52538 10.6997C1.46359 10.762 1.4147 10.8359 1.38153 10.9171C1.34835 10.9983 1.33154 11.0853 1.33204 11.173V13.9997C1.33204 14.1765 1.40228 14.3461 1.5273 14.4711C1.65233 14.5961 1.8219 14.6664 1.99871 14.6664H4.82538C4.91866 14.6714 5.01197 14.6568 5.09926 14.6235C5.18654 14.5902 5.26585 14.5389 5.33204 14.473L12.5787 7.18635L14.472 5.33302C14.5329 5.26841 14.5825 5.19404 14.6187 5.11302C14.6251 5.05988 14.6251 5.00616 14.6187 4.95302C14.6218 4.92199 14.6218 4.89072 14.6187 4.85969L14.6654 4.82635ZM4.55204 13.333H2.66538V11.4464L9.28538 4.82635L11.172 6.71302L4.55204 13.333ZM12.112 5.77302L10.2254 3.88635L11.172 2.94635L13.052 4.82635L12.112 5.77302Z"
            fill="#49515F"/>
    </svg>
</template>

<script>
export default {
    name: "Edit"
}
</script>
