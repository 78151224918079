<template>
    <Auth title="Настройки отчета на почту">
        <div class="card" v-loading="loading">
            <div class="card-body">
                <el-row gutter="20">
                    <el-col :sm="12">

                        <el-form v-model="reportSettings" label-position="top">
                            <el-checkbox v-bind:class="{ 'mb-15': !reportSettings.enable }"
                                         v-model="reportSettings.enable">Получать отчет на почту
                            </el-checkbox>

                            <div v-if="reportSettings.enable">

                                <p>Вы будете ежедневно получать отчет на почту {{ user.email }}. В отчете будет
                                    информация
                                    о работе ваших бетонных заводов и весовых теримналах.</p>

                                <FormSelect
                                    v-model="reportSettings.hour"
                                    label="Время получения"
                                    placeholder="Выберите час"
                                    :items="hours"
                                />

                                <FormSelect
                                    v-model="reportSettings.timezone"
                                    label="Часовой пояс"
                                    placeholder="Выберите часовой пояс"
                                    :items="timezones"
                                />

                            </div>

                            <div class="d-flex">

                                <FormAddButton
                                    @action="saveSettings"
                                    :loading="loading"
                                    class="mr-15"
                                    title="Сохранить настройки"
                                />

                                <FormAddButton
                                    @action="sendReport"
                                    v-if="reportSettings.enable"
                                    :loading="loading"
                                    type="text"
                                    icon=""
                                    title="Отправить сейчас"
                                />

                            </div>

                        </el-form>

                    </el-col>
                </el-row>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import FormSelect from '@/views/components/Form/FormSelect'
import timezonesMixin from '@/mixins/timezonesMixin'
import FormAddButton from '@/views/components/Form/FormAddButton'
import hoursMixin from '@/mixins/hoursMixin'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    name: 'EmailReportSettings',
    data() {
        return {
            reportSettings: {
                enable: false,
                hour: null,
                timezone: null
            },
            loading: false,
        }
    },
    mixins: [
        timezonesMixin, hoursMixin
    ],
    components: {
        FormAddButton,
        FormSelect,
        Auth
    },
    inject: [
        'api'
    ],
    created() {
        this.loading = true

        httpService().post(this.api.mailReport.getAccountSettings).then((response) => {
            let data = response.data

            if (data.success)
                if (data.settings) {
                    this.reportSettings.enable = true
                    this.reportSettings.hour = data.settings.hour
                    this.reportSettings.timezone = data.settings.timezone
                }
            this.loading = false

        }).catch(() => {
            this.$message({
                message: 'Ошибка ответа от сервера',
                showClose: true,
                type: 'error'
            })
            this.$router.push({ name: 'logout' })
        })
    },
    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser'
        })
    },
    methods: {
        saveSettings() {
            this.loading = true

            httpService().post(this.api.mailReport.createOrUpdate, {
                settings: this.reportSettings
            }).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Настройки сохранены',
                        showClose: true,
                        type: 'success'
                    })
                this.loading = false

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        sendReport() {
            this.loading = true

            httpService().post(this.api.mailReport.sendAccountReport).then((response) => {
                let data = response.data

                if (data.success)

                    this.$message({
                        message: 'Отчет отправлен',
                        showClose: true,
                        type: 'success'
                    })
                this.loading = false

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        }
    },
}
</script>

<style scoped>

</style>
