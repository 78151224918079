<template>
    <div class="group-orders-summary">
        <div class="group-orders-summary-body">
            <el-row class="summary">
                <el-col class="date-picker-container">
                    <div class="current-day">{{ currentDayName }}</div>
                    <el-date-picker
                        class="summary-date-picker pointer"
                        v-model="filters['filter[date]']"
                        :type="'date'"
                        :format="'D MMMM'"
                        :clearable="false"
                        :editable="false"
                        :size="'default'"
                        @change="$emit('changeDate', filters['filter[date]'])"
                    />
                </el-col>
                <el-col class="summary__empty">
                </el-col>
                <el-col class="summary__orders">
                    <SummaryOrdersCount :orders="orders"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__mix-orders">
                    <SummaryMixOrdersCount :orders="orders"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__vehicles">
                    <SummaryVehiclesCount :mixOrders="mixOrders" :hours="hours"/>
                </el-col>
                <el-col class="summary__empty">
                    <div style="border-left: 1px solid #EEEEF0; height: 100%;"></div>
                </el-col>
                <el-col class="summary__mixers">
                    <SummaryMixersCount :mixOrders="mixOrders" :hours="hours"/>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment/moment'
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupStatusDeclension from "@/mixins/mixOrderGroupStatusDeclension";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from '../../../mixins/mobileCheckMixin';
import SummaryOrdersCount from "@/views/dispatch/components/summaryBlocks/SummaryOrdersCount.vue";
import SummaryMixOrdersCount from "@/views/dispatch/components/summaryBlocks/SummaryMixOrdersCount.vue";
import SummaryVehiclesCount from "@/views/dispatch/components/summaryBlocks/SummaryVehiclesCount.vue";
import SummaryMixersCount from "@/views/dispatch/components/summaryBlocks/SummaryMixersCount.vue";

export default {
    name: 'Summary',
    components: {SummaryMixOrdersCount, SummaryOrdersCount, SummaryMixersCount, SummaryVehiclesCount},
    props: {
        orders: Array,
    },
    data() {
        return {}
    },
    mixins: [mixOrderGroupStatusDeclension, functionsMixin, mobileCheckMixin],
    computed: {
        ...mapState(useOrderGroupsStore, {
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        mixOrders() {
            return this.orders.flatMap(order => order.orders).filter(el => el.type === 'delivery')
        },
        hours() {
            const now = moment().tz(this.moduleTimezone);
            const getDate = this.getDate;
            const isSameDay = now.isSame(getDate, 'day');
            const hasMixOrders = this.mixOrders.length > 0;

            if (isSameDay && hasMixOrders) {
                const earliestStartAt = moment.min(this.mixOrders.map(order => moment(order.start_at)));
                const startHour = earliestStartAt.hour();

                if (startHour >= now.hour() - 3 && startHour <= now.hour() + 4) {
                    return Array.from({ length: 8 }, (_, i) => ({
                        value: now.clone().add(i - 3, 'hour').hour(),
                        itemStyle: {
                            color: i === 3 ? '#1984e6' : '#c9ccd0'
                        }
                    }));
                } else {
                    return Array.from({ length: 7 }, (_, i) => ({
                        value: startHour + i,
                        itemStyle: {
                            color: '#c9ccd0'
                        }
                    }));
                }
            } else if (!hasMixOrders && !isSameDay) {
                return Array.from({ length: 8 }, (_, i) => ({
                    value: now.clone().add(i - 3, 'hour').hour(),
                    itemStyle: {
                        color: i === 3 ? '#1984e6' : '#c9ccd0'
                    }
                }));
            } else {
                const earliestStartAt = moment.min(this.mixOrders.map(order => moment(order.start_at)));
                const startHour = earliestStartAt.subtract(1, 'hour').hour();

                return Array.from({ length: 7 }, (_, i) => ({
                    value: startHour + i,
                    itemStyle: {
                        color: '#c9ccd0'
                    }
                }));
            }
        },
        currentDayName() {
            const format = "YYYY-MM-DD";
            const date = moment(this.getDate).toDate()
            const currentMomentDate = moment(date).format(format)
            const today = moment().format(format);

            if (currentMomentDate === today) {
                return 'Сегодня'
            }

            const tomorrow = moment().add({day: 1}).format(format);

            if (currentMomentDate === tomorrow) {
                return 'Завтра'
            }

            const lowerDate = moment(date, null, 'ru').format('dddd')
            return lowerDate.substring(0, 1).toUpperCase() + lowerDate.substring(1)
        }
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";

.order-groups {



    &__info {
        margin-top: auto;
        margin-bottom: auto;

        &__desc {

            &--yellow {
                color: #FFB500;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
</style>
