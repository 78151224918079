<template>
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M256.988 78.5541L255.53 76.1541C243.218 55.8441 220.964 43.6341 197.498 45.1221C194.472 45.2967 191.464 45.6978 188.498 46.3221C167.162 50.8881 148.604 67.0221 140.906 87.6861C136.382 99.8361 135.116 113.828 126.506 123.476C115.328 136.028 96.3558 136.418 79.6698 135.902C62.9838 135.386 44.0058 135.824 32.8698 148.424C25.9038 156.32 23.6898 167.546 24.2538 178.112C25.7358 206.096 45.2538 231.122 70.0398 243.56C94.8258 255.998 124.04 257.108 151.178 251.636C189.2 243.974 225.356 222.926 247.094 190.436C268.832 157.946 274.382 113.642 256.988 78.5541Z"
            fill="#1A1C36"/>
        <path d="M231.789 56.7202H51.8555V215.252H231.789V56.7202Z" fill="#141731"/>
        <path d="M231.789 153.252H51.8555V180.036H231.789V153.252Z" fill="#141731"/>
        <path d="M231.789 122.637H51.8555V153.285H231.789V122.637Z" fill="#1A1C36"/>
        <path d="M231.789 184.453H51.8555V215.101H231.789V184.453Z" fill="#1A1C36"/>
        <path
            d="M160.498 132.168C160.498 132.258 139.276 132.33 113.098 132.33C86.9204 132.33 65.6984 132.258 65.6984 132.168C65.6984 132.078 86.9144 132.012 113.098 132.012C139.282 132.012 160.498 132.084 160.498 132.168Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M105.762 140.81C105.762 140.894 96.7625 140.966 85.6205 140.966C74.4785 140.966 65.4785 140.894 65.4785 140.81C65.4785 140.726 74.4785 140.654 85.6205 140.654C96.7625 140.654 105.762 140.726 105.762 140.81Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M160.498 162.49C160.498 162.574 139.276 162.646 113.098 162.646C86.9201 162.646 65.6981 162.574 65.6981 162.49C65.6981 162.406 86.9141 162.334 113.098 162.334C139.282 162.334 160.498 162.4 160.498 162.49Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M160.498 94.3748C160.498 94.4589 139.276 94.5309 113.098 94.5309C86.9201 94.5309 65.6981 94.4589 65.6981 94.3748C65.6981 94.2908 86.9141 94.2188 113.098 94.2188C139.282 94.2188 160.498 94.2848 160.498 94.3748Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M105.689 170.986C105.689 171.076 96.6893 171.142 85.5474 171.142C74.4054 171.142 65.4054 171.076 65.4054 170.986C65.4054 170.896 74.4054 170.83 85.5474 170.83C96.6893 170.83 105.689 170.914 105.689 170.986Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M105.689 102.724C105.689 102.814 96.6893 102.88 85.5474 102.88C74.4054 102.88 65.4054 102.814 65.4054 102.724C65.4054 102.634 74.4054 102.568 85.5474 102.568C96.6893 102.568 105.689 102.652 105.689 102.724Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M105.689 110.488C105.689 110.578 96.6893 110.644 85.5474 110.644C74.4054 110.644 65.4054 110.578 65.4054 110.488C65.4054 110.398 74.4054 110.332 85.5474 110.332C96.6893 110.332 105.689 110.416 105.689 110.488Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M160.498 192.666C160.498 192.75 139.276 192.822 113.098 192.822C86.9204 192.822 65.6984 192.75 65.6984 192.666C65.6984 192.582 86.9144 192.51 113.098 192.51C139.282 192.51 160.498 192.552 160.498 192.666Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M105.762 201.162C105.762 201.252 96.7625 201.318 85.6205 201.318C74.4785 201.318 65.4785 201.252 65.4785 201.162C65.4785 201.072 74.4785 201.006 85.6205 201.006C96.7625 201.006 105.762 201.078 105.762 201.162Z"
            fill="white" fill-opacity="0.7"/>
        <path d="M231.789 56.5728H51.8555V71.6988H231.789V56.5728Z" fill="#474859"/>
        <path d="M231.789 70.3418H51.8555V85.4678H231.789V70.3418Z" fill="#1A1C36"/>
        <path
            d="M62.6905 63.6192C62.6869 63.9949 62.5722 64.3611 62.3608 64.6716C62.1494 64.9821 61.8508 65.2231 61.5026 65.3641C61.1544 65.5052 60.7722 65.5399 60.4043 65.464C60.0364 65.3881 59.6992 65.205 59.4352 64.9377C59.1713 64.6704 58.9924 64.3309 58.9211 63.962C58.8499 63.5932 58.8894 63.2115 59.0348 62.8651C59.1802 62.5187 59.4249 62.2231 59.7381 62.0156C60.0512 61.8082 60.4188 61.6981 60.7945 61.6992C61.0455 61.6992 61.294 61.749 61.5257 61.8458C61.7573 61.9426 61.9674 62.0844 62.1437 62.263C62.3201 62.4416 62.4593 62.6535 62.5531 62.8863C62.647 63.1191 62.6937 63.3682 62.6905 63.6192Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M69.6487 63.6194C69.6451 63.9958 69.5299 64.3626 69.3177 64.6735C69.1054 64.9844 68.8057 65.2254 68.4565 65.3658C68.1072 65.5063 67.7242 65.54 67.3558 65.4626C66.9874 65.3852 66.6502 65.2003 66.387 64.9312C66.1238 64.6621 65.9464 64.3209 65.8772 63.9509C65.8079 63.5809 65.8501 63.1987 65.9982 62.8526C66.1464 62.5066 66.3939 62.2123 66.7094 62.007C67.0249 61.8017 67.3943 61.6946 67.7707 61.6994C68.2741 61.7057 68.7544 61.9114 69.1065 62.2713C69.4585 62.6312 69.6535 63.1159 69.6487 63.6194V63.6194Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M76.6067 63.6192C76.6031 63.9946 76.4886 64.3606 76.2774 64.671C76.0663 64.9814 75.768 65.2223 75.4202 65.3636C75.0723 65.5048 74.6905 65.5399 74.3227 65.4645C73.9549 65.389 73.6177 65.2065 73.3535 64.9398C73.0893 64.6731 72.9099 64.3342 72.838 63.9657C72.766 63.5973 72.8047 63.2158 72.9492 62.8693C73.0937 62.5228 73.3375 62.2268 73.6499 62.0186C73.9622 61.8103 74.3293 61.6992 74.7047 61.6992C74.956 61.6992 75.2048 61.749 75.4367 61.8457C75.6687 61.9424 75.8792 62.0841 76.056 62.2627C76.2329 62.4412 76.3726 62.653 76.4671 62.8858C76.5616 63.1187 76.6091 63.3679 76.6067 63.6192V63.6192Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M231.801 215.318C231.801 214.31 231.765 153.518 231.711 56.5818L231.801 56.7018L51.9575 56.7378L52.0655 56.5818C52.0655 112.928 52.0655 166.592 52.0655 215.318L51.9635 215.174L231.819 215.318L51.9635 215.462H51.8555V215.318C51.8555 166.592 51.8555 112.928 51.8555 56.5818V56.4258H51.9815L231.825 56.4558H231.915V56.5818C231.831 153.518 231.801 214.28 231.801 215.318Z"
            fill="white" fill-opacity="0.7"/>
        <path
            d="M216.02 134.355H189.734C186.801 134.355 184.424 136.733 184.424 139.665V139.671C184.424 142.604 186.801 144.981 189.734 144.981H216.02C218.952 144.981 221.33 142.604 221.33 139.671V139.665C221.33 136.733 218.952 134.355 216.02 134.355Z"
            fill="#474859"/>
        <path
            d="M215.941 175.737H189.661C188.963 175.737 188.273 175.6 187.629 175.333C186.984 175.066 186.399 174.675 185.906 174.182C185.413 173.689 185.022 173.104 184.755 172.459C184.488 171.815 184.351 171.125 184.351 170.427V170.427C184.351 169.019 184.91 167.668 185.906 166.672C186.902 165.677 188.252 165.117 189.661 165.117H215.941C216.638 165.117 217.329 165.255 217.973 165.521C218.617 165.788 219.202 166.179 219.695 166.672C220.189 167.166 220.58 167.751 220.847 168.395C221.113 169.039 221.251 169.73 221.251 170.427V170.427C221.251 171.125 221.113 171.815 220.847 172.459C220.58 173.104 220.189 173.689 219.695 174.182C219.202 174.675 218.617 175.066 217.973 175.333C217.329 175.6 216.638 175.737 215.941 175.737Z"
            fill="#474859"/>
        <path
            d="M215.941 107.622H189.661C188.963 107.622 188.273 107.485 187.629 107.218C186.984 106.951 186.399 106.56 185.906 106.067C185.413 105.574 185.022 104.988 184.755 104.344C184.488 103.7 184.351 103.009 184.351 102.312V102.312C184.351 100.904 184.91 99.553 185.906 98.5572C186.902 97.5614 188.252 97.002 189.661 97.002H215.941C216.638 97.002 217.329 97.1393 217.973 97.4062C218.617 97.673 219.202 98.0641 219.695 98.5572C220.189 99.0503 220.58 99.6357 220.847 100.28C221.113 100.924 221.251 101.615 221.251 102.312V102.312C221.251 103.009 221.113 103.7 220.847 104.344C220.58 104.988 220.189 105.574 219.695 106.067C219.202 106.56 218.617 106.951 217.973 107.218C217.329 107.485 216.638 107.622 215.941 107.622Z"
            fill="#474859"/>
        <path
            d="M216.02 195.146H189.734C186.801 195.146 184.424 197.524 184.424 200.456V200.462C184.424 203.395 186.801 205.772 189.734 205.772H216.02C218.952 205.772 221.33 203.395 221.33 200.462V200.456C221.33 197.524 218.952 195.146 216.02 195.146Z"
            fill="#474859"/>
    </svg>
</template>

<script>
export default {
    name: 'DashboardNoWidgetsIcon'
}
</script>

<style scoped>

</style>
