<template>
    <p>Сменить владельца аккаунта:</p>

    <el-form v-loading="loading" ref="form" :model="registerGodForm" size="small" label-position="top">

        <FormInput
            label="ID Account"
            placeholder="ID"
            v-model="registerGodForm.accountId"
        />

        <FormInput
            label="E-mail владельца"
            placeholder="Email"
            v-model="registerGodForm.email"
        />

        <FormInput
            label="Пароль"
            placeholder="Пароль"
            v-model="registerGodForm.password"
        />

        <FormAddButton
            title="Зарегистрировать"
            :loading="loading"
            @action="registerGod"
        />

    </el-form>
</template>

<script>
import FormInput from '@/views/components/Form/FormInput'
import FormAddButton from '@/views/components/Form/FormAddButton'
import {httpService} from '@/services/http.service'
export default {
    name: 'GodModeChangeAccountUser',
    components: {FormAddButton, FormInput},
    data() {
        return {
            loading: false,
            registerGodForm: {
                email: null,
                password: null,
                accountId: null,
            }
        }
    },
    inject: ['api'],
    methods: {
        registerGod() {
            this.loading = true

            httpService().post(this.api.admin.registerGod, {
                email: this.registerGodForm.email,
                accountId: this.registerGodForm.accountId,
                password: this.registerGodForm.password
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: 'Владелец изменен',
                        showClose: true,
                        type: 'success'
                    })
                this.loading = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>
