<template>
    <div class="task">
        <el-row :gutter="15" v-bind:class="{ done: done }">

            <el-col :sm="1" class="taskIndex">{{ taskIndex }}
            </el-col>
            <el-col v-if="!mobile" :sm="8" class="taskName">
                {{ task.task_name }}
            </el-col>
            <el-col v-else :sm="8" class="taskName">
                <el-row :gutter="5">
                    <el-col :span="21">
                        {{ task.task_name }}
                    </el-col>
                    <el-col :span="3" class="text-right">
                        <el-button style=" padding-top: 0; margin-top: -4px; " @click="removeTask(task)"
                                   class="taskRemoveButton" type="text"
                                   :icon="icons.delete"/>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :sm="4">
                <template v-if="mobile">Срок (план):</template>
                {{ getDateFromUnix(task.task_date_done, 'DD.MM.YY') }}
            </el-col>
            <el-col :sm="4">
                <template v-if="mobile">Завершено:</template>
                {{ task.task_date_fact ? getDateFromUnix(task.task_date_fact, 'DD.MM.YY') : '-' }}
            </el-col>
            <el-col :sm="7" class="taskActions">
                <el-button v-if="!task.task_date_fact" @click="endTask(task)" class="mr-15" type="text">Завершить
                </el-button>
                <el-button v-if="!mobile" @click="removeTask(task)" class="taskRemoveButton" type="text"
                           :icon="icons.delete"/>
            </el-col>

        </el-row>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from '@/mixins/iconsMixin'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";

export default {
    name: 'RubezhPlanOneTask',
    props: ['task', 'tasks', 'index', 'done'],
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin, mobileCheckMixin],
    data() {
        return {
            taskIndex: this.index
        }
    },
    methods: {
        endTask(task) {
            this.loadingButton = true

            this.$confirm(
                'Вы подтверждаете завершение задачи ' + task.task_name + '?',
                'Подтвердите завершение',
                {
                    confirmButtonText: 'Завершить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: task,
                        action: 'endTask'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        removeTask(task) {
            this.loadingButton = true

            this.$confirm(
                'Вы подтверждаете удаление задачи ' + task.task_name + '?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: task,
                        action: 'removeTask'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
    },
    watch: {
        'index': {
            handler: function () {
                this.taskIndex = this.index
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
