<template>
    <svg :class="currentColor ? 'currentColor' : ''"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.696 13C5.47733 13 5.30667 12.9307 5.184 12.792C5.06133 12.6534 5 12.4667 5 12.232V3.184C5 2.76267 5.09867 2.46133 5.296 2.28C5.49867 2.09333 5.82933 2 6.28801 2H10.752C10.9707 2 11.144 2.056 11.272 2.168C11.4 2.28 11.464 2.432 11.464 2.624C11.464 2.82133 11.4 2.976 11.272 3.088C11.144 3.19466 10.9707 3.24801 10.752 3.24801H6.4V12.232C6.4 12.4667 6.336 12.6534 6.208 12.792C6.08 12.9307 5.90934 13 5.696 13Z"
              fill="#505968" />
    </svg>
</template>

<script>
export default {
    name: "GidrolotokLetterIcon",
    props: ["currentColor"]
}
</script>

<style scoped lang="scss">
    .currentColor {
        path {
            fill: currentColor;
        }
    }
</style>
