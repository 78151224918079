<template>
    <div class="mobileTableProgressBar d-flex">
        <div class="mobileTableProgressBarTitle">
            {{ title }}
        </div>
        <div class="mobileTableProgressBarProgress">

            <el-progress
                color="#00cdc4"
                :stroke-width="8"
                :percentage="progress"
            />

        </div>
    </div>
</template>

<script>
export default {
    name: "MobileTableProgressBar",
    props: [
        'title',
        'progress'
    ]
}
</script>

<style scoped>

</style>
