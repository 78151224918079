<template>
    <Auth title="Учет ГСМ">
        <div class="card">

            <div class="card-body">
                <el-tabs v-model="activeTab">

                    <el-tab-pane label="Сводка" name="info">
                        в разработке
                    </el-tab-pane>

                    <el-tab-pane label="Автопарк" name="garage">
                        <FuelGarageTab/>
                    </el-tab-pane>

                    <el-tab-pane label="Заправки" name="fuel">
                        в разработке
                    </el-tab-pane>

                    <el-tab-pane label="Резервуары" name="reservoirs">
                        в разработке
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import FuelGarageTab from "@/views/components/FuelPage/FuelGarage/FuelGarageTab.vue";


export default {
    name: 'FuelIndex',
    components: {
        FuelGarageTab,
        Auth,
    },
    data() {
        return {
            activeTab: 'info',
            loading: false,
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) this.moduleGet()
        },
        '$route.params.tab': {
            handler: function (tab) {
                if (tab) this.activeTab = tab
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
