<template>
    <div class="analyticsCard analyticsCardSummary">
        <div class="analyticsCardBody">
            <span class="analyticsCardTopTitle textXS textXSRegular neutral300">{{ title }}</span>

            <div class="analyticsCardMainBlock">
                <div class="headingXS headingRegular">
                    <AnimateNumber
                        :class="unit ? 'mr-5' : ''"
                        :count="count"
                    />
                    <good-unit v-if="unit" :unit="unit ?? 'ед.'"/>
                    {{ type === 'speedHour' ? '/ч.' : '' }}
                    <span
                        class="analyticsCardMainBlockTop textXS textXSRegular"
                        v-if="count || countOld"
                        v-bind:class="{
                                        'success300': count >= countOld,
                                        'danger300 topDown': count <= countOld,
                                    }"
                    >
                                    <el-tooltip
                                        effect="customized"
                                        raw-content
                                        placement="top"
                                    >
                                        <template #content>
                                            <span v-if="count < countOld">
                                            Это на <span class="color-error">{{
                                                    Math.round(countOld - count)
                                                }}{{ unit ? ' ' : '' }}<good-unit v-if="unit" :unit="unit ?? 'ед.'"/> меньше</span>, чем {{
                                                    periodName === 'сегодня' ? 'вчера' : ''
                                                }} за аналогичный период {{
                                                    periodName === 'неделю' ? 'период прошлой неделе' : ''
                                                }}{{
                                                    periodName === 'месяц' ? 'прошлого месяца' : ''
                                                }}{{ periodName === 'год' ? 'прошлого года' : '' }}
                                            </span>
                                                <span v-if="count > countOld">
                                            Это на <span class="color-success">{{
                                                        Math.round(count - countOld)
                                                    }}{{ unit ? ' ' : '' }}<good-unit v-if="unit"
                                                                                      :unit="unit ?? 'ед.'"/> больше</span>, чем {{
                                                        periodName === 'сегодня' ? 'вчера' : ''
                                                    }} за аналогичный период {{
                                                        periodName === 'неделю' ? 'прошлой неделе' : ''
                                                    }}{{
                                                        periodName === 'месяц' ? 'прошлого месяца' : ''
                                                    }}{{ periodName === 'год' ? 'прошлого года' : '' }}
                                            </span>
                                                <span v-if="count === countOld">
                                            Такой же показатель как вчера за аналогичный период
                                            </span>
                                        </template>
                                        <span>
                                            <Top/>
                                            <AnimateNumber
                                                style=" margin-left: 2px; "
                                                :count="getPercent"
                                            />%
                                        </span>
                                    </el-tooltip>
                                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoodUnit from "@/views/components/goodUnit.vue";
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import {Top} from "@element-plus/icons-vue";

export default {
    name: 'InfoAnalyticsCounterBlock',
    components: {Top, AnimateNumber, GoodUnit},
    props: [
        'title', // заголовок
        'count', // значение
        'countOld', // значение прошлого периода
        'type', // тип значения - speedHour скорость в час
        'unit', // единица измерения
        'periodName', // период
    ],
    computed: {
        getPercent: function () {
            if (this.count && this.countOld) {
                if (this.count < this.countOld) {
                    return 100 - (this.count / this.countOld * 100)
                } else if (this.count > this.countOld) {
                    return this.count / this.countOld * 100
                } else {
                    return 0
                }
            } else {
                if (!this.count && this.countOld !== 0) {
                    return 100
                } else if (this.count !== 0 && !this.countOld) {
                    return 100
                } else {
                    return 0
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
