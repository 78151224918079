<template>
    <div class="order-terms-item">
        <svg v-if="termsItem.status" class="order-terms-item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M4 8L6.82843 10.8284L12.4853 5.17157" stroke="#009272" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else class="order-terms-item__icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path d="M8.49996 1.50004L1.5 8.5M8.49996 8.49996L1.5 1.5" stroke="#BD2626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="order-terms-item__title">
            <slot></slot>
        </div>
        <div class="order-terms-item__message">
            {{ termsItem.message }}
        </div>
    </div>
</template>
<script>
export default {
    name: "OrderTermsItem",
    props:{
        termsItem:{
            type: [Object, null],
            default: null
        }
    },
}
</script>

<style scoped lang="scss">
.order-terms-item{

    align-items: center;
    margin-top: 12px;
    display: flex;

    &__icon{
        margin-right: 5px;
    }

    &__title{
        margin: 0 6px;
        color: #49515F;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
}
</style>
