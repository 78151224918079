<template>
    <div class="techDetailsInfoTopBlockImage" v-if="type && (type === 'mix' || type === 'truck' || type === 'excavator')">
        <img
            :class="'techBlockImg' + type"
            alt=""
            :src="require('@/assets/tech/' + type + '.png')"
            :style="height ? 'height:' + height : ''"
        />
    </div>
</template>

<script>
export default {
    name: 'TechImage',
    props: ['type', 'height']
}
</script>

<style scoped>

</style>
