<template>
    <div>
        <router-view/>
    </div>
</template>

<script>

export default {
    name: "EmptyTemplate",
}
</script>

<style scoped>

</style>
