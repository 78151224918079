<template>
    <el-col :sm="6">

        <el-form-item label="reset" class="hideLabel" v-if="hideLabel === 'show'">
            <el-button @click="$emit('resetFilter')"
                       type="info" icon="el-icon-close" :loading="loading" class="w-100"
                       size="mini" plain>
                Сбросить фильтр
            </el-button>
        </el-form-item>

        <el-button @click="$emit('resetFilter')" v-else
                   type="info" icon="el-icon-close" :loading="loading" class="w-100"
                   size="mini" plain>
            Сбросить фильтр
        </el-button>

    </el-col>
</template>

<script>
export default {
    name: "FilterResetButton",
    props: [
        'loading',
        'hideLabel'
    ]
}
</script>

<style scoped>

</style>
