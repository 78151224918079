<template>
    <div class="tableLoader">
        <div class="text-center">
            <ReportLoader/>
            <h3>Загрузка {{ text ?? 'элементов' }}</h3>
        </div>
    </div>
</template>

<script>
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: "TableDataLoader",
    components: {ReportLoader},
    props: ['text']
}
</script>

<style scoped>
.tableLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    width: 100%;
}
</style>
