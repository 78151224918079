<template>
    <DrawerFramework :loading="loadingButton">
        <template v-slot:body>
            <DrawerHeadTitle title="Создание плана"/>

            <el-form ref="form" :model="form" size="small" label-position="top">

                <FormInput
                    v-model="form.plan_name"
                    label="Название плана"
                    placeholder="Введите название"
                />
                <FormDateTimePicker
                    v-model="form.plan_date_start"
                    label="Дата начала"
                />

                <FormDateTimePicker
                    v-model="form.plan_date_done"
                    label="Дата завершения"
                />

                <el-form-item label="Тип плана">
                    <el-radio-group v-model="form.plan_type">
                        <el-radio class="mb-10" label="production" size="medium" border>Производственный (кол-во продукции)</el-radio>
                        <el-radio class="mb-10" label="tasks" size="medium" border>Обычный (только задачи)</el-radio>
                    </el-radio-group>
                </el-form-item>

                <template v-if="form.plan_type === 'production'">

                    <FormNumber
                        v-model="form.plan_work_days"
                        label="Количество рабочих дней"
                        placeholder="Укажите количество"
                        step="1"
                        precision="0"
                    />

                    <p class="text-small">Ниже укажите наименование продукции/задачи, необходимое количество и единицу
                        измерения. Например: установить осветительные приборы, 10, шт.:</p>

                    <FormInput
                        v-model="form.production_name"
                        label="Название"
                        placeholder="Введите название"
                    />

                    <FormNumber
                        v-model="form.production_total"
                        label="Количество"
                        placeholder="Введите количество"
                        precision="2"
                        step="0.01"
                    />

                    <FormInput
                        v-model="form.production_unit"
                        label="Единица измерения (короткое обозначение)"
                        placeholder="Шт, м.куб, м.кв. и тд"
                    />

                </template>

                <template v-else>

                    <p class="text-small">Задачи для данного типа плана вы сможете задать после его создания</p>

                </template>

            </el-form>

        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Добавить план" @action="createReport()"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import moment from "moment/moment";
import {httpService} from "@/services/http.service";
import FormInput from "@/views/components/Form/FormInput";
import FormNumber from "@/views/components/Form/FormNumber";

export default {
    name: 'RubezhPlanPlanDrawerCreate',
    inject: ['api'],
    components: {FormNumber, FormInput, DrawerCreateButton, FormDateTimePicker, DrawerHeadTitle, DrawerFramework},
    data() {
        return {
            form: {
                plan_name: null,
                plan_type: 'production',
                plan_date_start: moment(),
                plan_date_done: moment().add(5, 'days'),
                plan_work_days: null,
                production_name: null,
                production_total: null,
                production_unit: null
            },
            loadingButton: false
        }
    },
    methods: {
        createReport() {
            this.loadingButton = true
            httpService().post(this.api.custom_module.doAction, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
                data: this.form,
                action: 'createPlan'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    window.setTimeout(() => {
                        this.$emit('moduleGet')
                    }, 50)
                this.$emit('closeDrawer')
            }).finally(() => {
                this.loadingButton = false
            })
        }
    }
}
</script>

<style scoped>

</style>
