<template>
    <el-form-item
            :label="label"
            :required="required"
    >
        <el-autocomplete
                :disabled="disabled"
                v-model="address"
                :debounce="500"
                :label="label"
                :fetch-suggestions="querySearch"
                :trigger-on-focus="false"
                :clearable="clearable"
                value-key="name"
                class="w-100"
                :placeholder="placeholder"
                @select="handleSelect"
                @change="changeSelect"
        />
        <el-button class="selectAddressButton" style="display: none" v-if="!address" @click="selectAddressDialog=true" type="text">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 17H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 12H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 7H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </el-button>
    </el-form-item>


    <el-dialog
            v-model="selectAddressDialog"
            title="Подбор адреса"
            :width="mobile ? '100%' : '60%'"
            :destroy-on-close="true"
    >
        <div style="min-height: 250px;" class="d-block">
            <div class="d-flex selectAddressList">
                <div v-for="(city, index) in addresses" :key="index">
                    <el-button type="text" @click="selectAddress(city.id, city.level, city.name)">{{
                        city.name
                        }}
                    </el-button>
                </div>
                <div>
                    <el-button type="text" @click="closeSelectAddressDialog()">
                        Другое
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dataForAddressSelect from "@/mixins/dataForAddressSelect";

export default {
    mixins: [functionsMixin, mobileCheckMixin, dataForAddressSelect],
    inject: ["api"],
    name: "FormDadataAddressV2",
    props: ['placeholder', 'label', 'modelValue', 'disabled', 'required', 'clearable'],
    data() {
        return {
            address: null,
            addressItems: [],
            test: null,
            addresses: [{
                id: 1,
                level: 1,
                name: 'Ярославль'
            }, {
                id: 2,
                level: 1,
                name: 'Ярославская область'
            }],
            selectAddressDialog: false
        };
    },
    methods: {
        async querySearch(queryString, cb) {
            this.address = queryString;
            await this.findAddress(queryString);

            const results = queryString ? this.addressItems : this.addressItems

            cb(results)
        },

        createFilter(queryString) {
            return (address) => {
                return address.name.toLowerCase().includes(queryString.toLowerCase())
            }
        },

        handleSelect(item) {
            this.$emit('update:modelValue', item.name)
            this.$emit('action')
            if (item.info) {
                this.$emit('infoAddress', item.info)
            } else {
                httpService().get(this.api.account.getCoordinatesByAddress, { params: { query: item.name } }).then(response => {
                    if (response.data.success) {
                        this.$emit('infoAddress', { data: { geo_lat: response.data.result.geo_lat, geo_lon: response.data.result.geo_lon } })
                    }
                })
            }
        },

        async findAddress(value) {
            await httpService().get(this.api.account.suggestionAddress, { params: { query: value } }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.addressItems = data.items
                    return this.addressItems
                }
            })

            return []
        },

        changeSelect(val) {
            if (val.length === 0) {
                this.$emit('onClear');
            }
        },

        selectAddress(id, level, name) {
            let elem = this.addressesItems.find(el => (el.id === id && el.level === level))
            let nextElem = null
            let arr = []

            if (this.address) {
                this.address += name + ', '
            } else {
                this.address = name + ', '
            }
            this.$emit('update:modelValue', this.address)

            if (elem) {
                nextElem = this.addressesItems.find(el => el.parentId === id)

                if (!nextElem) {
                    if (elem.items) {
                        elem.items.sort().forEach(el => arr.push({
                            id: 0,
                            name: el,
                            level: 2,
                            parentId: id
                        }))

                        this.addresses = arr
                    } else {
                        this.closeSelectAddressDialog()
                    }
                } else {
                    this.addresses = this.addressesItems.filter(el => el.parentId === id)
                }

                if (this.addresses.length === 0) {
                    this.closeSelectAddressDialog()
                }
            } else {
                this.closeSelectAddressDialog()
            }
        },
        closeSelectAddressDialog() {
            this.selectAddressDialog = false
            this.addresses = [{
                id: 1,
                level: 1,
                name: 'Ярославль'
            }, {
                id: 2,
                level: 1,
                name: 'Ярославская область'
            }]
            this.$emit('action')
        }
    },
    mounted() {
    },
    watch: {
        // address(newValue) {
        //     this.findAddress(newValue)
        // },
        'modelValue': {
            handler: function () {
                this.address = this.modelValue
            },
            deep: true,
            immediate: true
        }
    },
}
</script>
