import { defineStore } from 'pinia';
import { toServerIsoDate } from '@/utils/common';


export const useMixStore = defineStore('mix', {
    state: () => {
        return {
            filterDate: {
                value: null,
                type: 'date'
            },
        }
    },
    actions: {
        setFilterDate(value) {
            this.filterDate.value = toServerIsoDate(value);
        },
    }
})
