<template>
    <PageH1 title="Отчеты о работе"/>

    <TableHeadBlock
        v-model="search"
        :loading="loading"
        create-button-label="Добавить отчет"
        @moduleGet="$emit('getModule')"
        @openFilterBlock="openFilterBlock"
        @openCreateValueDrawer="openNewReportDrawer"
    />

    <Filter
        :filter="filter"
        :loading="loading"
        :filter-show="filterShow"
    />

    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
        <template v-slot:body>

            <MobileTableDate
                :date="item.report_date"
                format="DD.MM.YY"
                title="Дата отчета"
            />

            <MobileTableInfo
                title="Отчет"
                :no-border="true"
                :body="item.report_text ?? '-'"
            />

            <MobileTableButton
                title="Удалить"
                @action="removeReport(item)"
            />

        </template>
    </MobileTableFramework>

    <el-table
        empty-text="Информация не найдена"
        v-loading="loading"
        :data="displayData"
        v-if="!mobile"
        class="w-100 mt-15">
        <el-table-column
            prop="report_text"
            label="Отчет">
        </el-table-column>
        <el-table-column
            prop="report_date"
            width="200px"
            label="Дата">
            <template v-slot="scope">
                {{ scope.row.report_date ? getDateFromUnix(scope.row.report_date, 'DD.MM.YY') : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label=""
            width="130">
            <template v-slot="scope">
                <el-button
                    size="mini"
                    @click="removeReport(scope.row)">Удалить
                </el-button>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        :loading="loading"
        @action="setPage"
    />

    <el-drawer
        v-model="newReportDrawer"
        title=""
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <RubezhPlanReportDrawerCreate
            @moduleGet="$emit('getModule')"
            @closeDrawer="closeNewReportDrawer"
        />

    </el-drawer>

</template>

<script>
import PageH1 from '@/views/components/PageH1'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import Filter from '@/views/components/Table/Filter/Filter'
import functionsMixin from '@/mixins/functionsMixin'
import tableMixin from '@/mixins/tableMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import TablePagination from "@/views/components/Table/TablePagination";
import {httpService} from "@/services/http.service";
import moment from "moment/moment";
import RubezhPlanReportDrawerCreate from "@/views/custom/rubezhPlan/components/RubezhPlanReportDrawerCreate";
import MobileTableFramework from "@/views/components/Mobile/Table/MobileTableFramework";
import MobileTableDate from "@/views/components/Mobile/Table/MobileTableDate";
import MobileTableInfo from "@/views/components/Mobile/Table/MobileTableInfo";
import MobileTableButton from "@/views/components/Mobile/Table/MobileTableButton";

export default {
    name: 'RubezhPlanReportTable',
    inject: ['api'],
    props: ['reports', 'loading'],
    components: {
        MobileTableButton,
        MobileTableInfo,
        MobileTableDate,
        MobileTableFramework, RubezhPlanReportDrawerCreate, TablePagination, Filter, TableHeadBlock, PageH1},
    mixins: [functionsMixin, tableMixin, mobileCheckMixin],
    data() {
        return {
            newReportDrawer: false,
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                }
            }
        }
    },
    methods: {
        openNewReportDrawer() {
            this.newReportDrawer = true;
        },
        closeNewReportDrawer() {
            this.newReportDrawer = false;
        },
        removeReport(report) {
            this.loadingButton = true

            this.$confirm(
                'Вы действительно хотите удалить отчет?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {

                    httpService().post(this.api.custom_module.doAction, {
                        module_key: this.$route.params.key,
                        module_id: this.$route.params.id,
                        data: report,
                        action: 'removeReport'
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$emit('getModule')
                    }).finally(() => {
                        this.loadingButton = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.report_text.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.report_date >= moment(this.filter.dateStart.value).format("X"))
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.report_date <= moment(this.filter.dateEnd.value).format("X"))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'reports': {
            handler: function () {
                this.values = this.reports
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
