<template>
    <Auth :title="title" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    v-model="search"
                    :loading="loadingButton"
                    create-button-label="Создать новый"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="$router.push({name: 'contractCreate', params: { id: $route.params.id },})"
                />

                <Filter
                    :filter="filter"
                    :filter-items1="statuses"
                    :filter-items2="companies"
                    :loading="loadingButton"
                    :filter-show="filterShow"
                />

                <div v-if="displayData">

                    <MobileTableFramework v-for="(item, index) in displayData" :key="index">
                        <template v-slot:body>

                            <MobileTableProgressBar
                                :title="item.status.name"
                                :progress="item.percent"
                            />

                            <MobileTableInfo
                                title="Название"
                                :body="item.name"
                            />

                            <MobileTableInfo
                                title="Контрагент"
                                :no-border="true"
                                :body="getData(item.companyId, 'company')"
                            />

                            <MobileTableButton
                                @action="$router.push({name: 'contractShow', params: { id: $route.params.id, unitId: item.id }})"
                            />

                        </template>
                    </MobileTableFramework>

                </div>

                <el-table
                    empty-text="Информация не найдена"
                    ref="table"
                    v-if="!smallMobile"
                    v-loading="loading"
                    :data="displayData"
                    class="mt-15 w-100">

                    <el-table-column
                        label="Название"
                        prop="name"/>

                    <el-table-column
                        label="Контрагент"
                        prop="company.name">
                        <template v-slot="scope">
                            {{ getData(scope.row.companyId, 'company') }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Статус"
                        prop="status.name"
                        width="150px"/>

                    <el-table-column
                        prop="id"
                        label="Прогресс"
                        width="150px"
                    >
                        <template v-slot="scope">
                            <el-progress color="#00cdc4" :percentage="scope.row.percent"></el-progress>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width="130">
                        <template v-slot="scope">
                            <el-button size="mini"
                                       @click="$router.push({name: 'contractShow', params: { id: $route.params.id, unitId: scope.row.id }})">
                                Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import tableMixin from '../../mixins/tableMixin'
import basicMixin from '../../mixins/basicMixin'
import moment from 'moment'
import TableHeadBlock from '../components/Table/TableHeadBlock'
import Filter from '../components/Table/Filter/Filter'
import TablePagination from '../components/Table/TablePagination'
import functionsMixin from '@/mixins/functionsMixin'
import MobileTableFramework from '@/views/components/Mobile/Table/MobileTableFramework'
import MobileTableInfo from '@/views/components/Mobile/Table/MobileTableInfo'
import MobileTableButton from '@/views/components/Mobile/Table/MobileTableButton'
import MobileTableProgressBar from '@/views/components/Mobile/Table/MobileTableProgressBar'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'

export default {
    name: 'ContractShow',
    data() {
        return {
            title: null,
            filter: {
                dateStart: {
                    value: null,
                    type: 'dateStart'
                },
                dateEnd: {
                    value: null,
                    type: 'dateEnd'
                },
                statusId: {
                    value: null,
                    type: 'select',
                    label: 'Статус',
                    placeholder: 'Выберите статус',
                    items: '1'
                },
                recipientId: {
                    value: null,
                    type: 'select',
                    label: 'Контрагент',
                    placeholder: 'Выберите компанию',
                    items: '2'
                },
            },
            statuses: [],
            integrations: [],
            relations: [],
        }
    },
    components: {
        MobileTableProgressBar,
        MobileTableButton,
        MobileTableInfo,
        MobileTableFramework,
        TablePagination,
        Filter,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        tableMixin, basicMixin, functionsMixin, mobileCheckMixin
    ],
    created() {
        this.moduleGet()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies'
        })
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true

            httpService().post(this.api.contract.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'contract'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.loadingButton = false
                this.values = data.contracts.reverse()
                this.statuses = data.statuses
                this.title = data.moduleName
                this.integrations = data.integrations
                this.relations = data.relations
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({ name: 'logout' })
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            if (this.filter.dateStart.value) this.filtered = this.filtered.filter(data => data.date_start >= moment(this.filter.dateStart.value).format('X'))
            if (this.filter.dateEnd.value) this.filtered = this.filtered.filter(data => data.date_end <= moment(this.filter.dateEnd.value).format('X'))
            if (this.filter.statusId.value) this.filtered = this.filtered.filter(data => data.status.id === this.filter.statusId.value)
            if (this.filter.recipientId.value) this.filtered = this.filtered.filter(data => data.companyId === this.filter.recipientId.value)

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) this.moduleGet()
        }
    },
}
</script>

<style scoped>

</style>
