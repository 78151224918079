<template>
    <DrawerFramework :loading="loadingButton">
        <template v-slot:body>
            <DrawerHeadTitle title="Новый отчет"/>

            <el-form ref="form" :model="form" size="small" label-position="top">

                <FormTextarea
                    v-model="form.report_text"
                    label="Введите отчет"
                    placeholder="Текст отчета"
                    rows="6"
                />

                <FormDateTimePicker
                    v-model="form.report_date"
                    label="Дата отчета"
                />

            </el-form>

        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton title="Добавить отчет" @action="createReport"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from "@/views/components/Drawer/DrawerFramework";
import DrawerHeadTitle from "@/views/components/Drawer/DrawerHeadTitle";
import FormTextarea from "@/views/components/Form/FormTextarea";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker";
import DrawerCreateButton from "@/views/components/Drawer/Buttons/DrawerCreateButton";
import moment from "moment/moment";
import {httpService} from "@/services/http.service";

export default {
    name: 'RubezhPlanReportDrawerCreate',
    inject: ['api'],
    components: {DrawerCreateButton, FormDateTimePicker, FormTextarea, DrawerHeadTitle, DrawerFramework},
    data() {
        return {
            form: {
                report_text: null,
                report_date: moment()
            },
            loadingButton: false
        }
    },
    methods: {
        createReport() {
            this.loadingButton = true
            httpService().post(this.api.custom_module.doAction, {
                module_key: this.$route.params.key,
                module_id: this.$route.params.id,
                data: this.form,
                action: 'createReport'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    window.setTimeout(() => {
                        this.$emit('moduleGet')
                    }, 50)
                this.$emit('closeDrawer')
            }).finally(() => {
                this.loadingButton = false
            })
        }
    }
}
</script>

<style scoped>

</style>
