import { useCommonStore } from '@/store/common'
import router from '..';

export default function moduleAvailable({to, next}) {
    const commonStore = useCommonStore()

    if (commonStore.getUserModuleAccess.find(module => module.module_reserve_id == to.params?.id && module.module_key == to.meta.moduleKey)) {
        return next();
    }

    router.push('/dashboard');
}
