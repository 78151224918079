<template>
    <Auth title="Заказы" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body dispatchCardBody">
                <Summary
                    :orders="displayData"
                    @changeDate="changeDate"
                />

                <MixOrderGroupTable
                    :loading="loading"
                    :mixes="mixes"
                    :order-groups="displayData"
                    @get="moduleGet"
                />
            </div>
        </div>
    </Auth>
</template>
<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import {httpService} from "@/services/http.service";
import Summary from "@/views/dispatch/components/Summary.vue";
import {useOrderGroupsStore} from '@/store/orderGroups'
import {mapActions, mapState} from 'pinia'
import MixOrderGroupTable from "@/views/components/MixOrderGroup/MixOrderGroupTable.vue";
import {useCommonStore} from '@/store/common'

export default {
    name: 'DispatchOrderGroups',
    provide() {
        return {
            // timezone: this.timezone
            timezone: 'Europe/Moscow'
        }
    },
    components: {
        MixOrderGroupTable,
        Summary,
        Auth,
    },
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: true,
            loadingData: false,
            //addNewMixOrderGroup: true,
            selectedGroupId: null,
            openActionDialog: false,
            filter: {
                date: {
                    value: moment(),
                    type: 'date'
                },
            },
            mixes: [],
            multipleSelection: [],
            createNewOrderShow: false,
            actionsModalShow: true,
            isAdd: false,
            firstLoad: true
        }
    },
    async created() {
        window.Echo.channel('laravel_database_ChannelDispatchOrderGroup')
            .listen(`.Algorithm.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Create.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Update.${this.$route.params.id}`, () => {
                this.moduleGet()
            })
            .listen(`.Delete.${this.$route.params.id}`, () => {
                this.moduleGet()
            });
        this.moduleGet(true)
    },
    beforeUnmount: function () {
        // window.Echo.leave(`laravel_database_ChannelMixOrderGroupCreateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderGroupUpdateNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        // window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
        window.Echo.leave(`laravel_database_ChannelDispatchOrderGroup`);
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            orderGroups: 'getOrderGroups',
            timezone: 'getTimezone',
            pagination: 'getPagination',
            filters: 'getFilters',
            getDate: 'getDate',
        }),
        ...mapState(useCommonStore, {
            modules: 'getModules',
        })
    },
    watch: {},
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullOrderGroups', 'changeOrderGroup']),
        moduleGet(loading) {
            if (this.$route.params.id === null) {
                return
            }

            if (!this.loadingData) {
                this.loadingData = true
                if (loading) this.loading = true;

                httpService().post(this.api.dispatch.orderGroups.getOrderGroups, {
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    params: this.filters
                }).then(({ data }) => {
                    if (data.success) {
                        this.values = data.values.sort((a, b) => {
                            const statusGroups = {
                                confirmed: 1,
                                work: 1,
                                done: 2,
                                completed: 2,
                                pause: 3,
                                failed: 4,
                            };

                            if (statusGroups[a.status] !== statusGroups[b.status]) {
                                return statusGroups[a.status] - statusGroups[b.status];
                            }

                            return a.firstOrderTimeDelivery - b.firstOrderTimeDelivery;
                        })
                        this.loading = false
                    }
                    this.loadingData = false
                }).catch(() => {
                    // this.$message({
                    //     message: error.response.data.message,
                    //     showClose: true,
                    //     type: 'error'
                    // })
                    this.loading = false

                    this.loadingData = false
                })
            }
        },
        selectStatuses(statuses) {
            this.filters['filter[status]'] = statuses

            this.getOrders(this.filters)
        },
        selectMixes(mixes) {
            this.filters['filter[mix_ids]'] = mixes.map(e => e.module_id)

            this.getOrders(this.filters)
        },
        changeDate(value) {
            this.filters['filter[date]'] = moment(value).format('YYYY-MM-DD')
            this.values = []
            this.moduleGet(true)
        },
        getOrders(filter) {
            if (this.$route.params.id) {
                this.loading = true
                this.pullOrderGroups(this.$route.params.id, this.$route.meta.moduleKey, filter)
                setTimeout(() => this.loading = false, 250)
            }
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        getAddress(companyId, addressId) {
            if (companyId && addressId) {
                let company = this.companies.find(el => el.id === companyId)
                if (company) {
                    let address = company.addresses.find(el => el.id === addressId)
                    if (address) {
                        return address.name
                    } else {
                        this.pullCompanies()
                        window.setTimeout(() => {
                            address = company.addresses.find(el => el.id === addressId)
                            if (address) {
                                return address.name
                            } else {
                                return null
                            }
                        }, 150)
                    }
                }
                return null
            }
            return null
        },
    }
}
</script>

<style lang="scss">
.group-order-action-dialog {
    border-radius: 12px !important;

    &__footer {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
    }

    .el-dialog__header {
        margin-right: 0;
    }
}
</style>
<style scoped lang="scss">
.dialog-header {
    display: flex;
    justify-content: end;

    .el-icon {
        cursor: pointer;
    }
}

.action-button {
    margin-left: 14px;
}

.card-body {
    &__filters {
        padding: 12px 0;
    }
}

.group-order {
    &__pagination {
        display: flex;
        justify-content: center;
    }
}
</style>
<style lang="scss" scoped>
.buyer-circle {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
}

.good-icon {
    vertical-align: middle;
    margin-right: 4px;
    width: 16px;
    height: 16px;
}


</style>
<style lang="scss">
.total-count .cell {
    padding: 0;
}


.cell-container {
    display: flex;
    justify-content: center;
}

.cell-padding {

    padding: 0 !important;

    .cell {
        padding: 6px 0 !important;
    }
}

.actions {
    display: flex;
    justify-content: space-between;
    height: 28px;

    button {
        min-height: auto;
        font-size: 12px;
        border-radius: 4px;
    }

    .plus-btn {
        border: none;
        width: 32px;
        height: 28px;
        padding: 0;
        justify-content: center;
    }

    .action-btn {
        padding: 0 12px;
    }
}

.address {
    font-size: 12px;
}
</style>
