<template>
    <SettingsFaqHowInviteNewUser v-if="component === 'how-invite-new-user'"/>
    <SettingsFaqHowCreateNewModule v-else-if="component === 'how-create-new-module'"/>

    <CashboxFaqHowCreateNewOperations v-else-if="component === 'how-create-new-operations'"/>
    <CashboxFaqHowEditDefaultAccount v-else-if="component === 'how-edit-default-account'"/>
    <template v-else>Справка не найдена</template>
</template>

<script>
import CashboxFaqHowCreateNewOperations from "@/views/components/FaqPage/cashbox/CashboxFaqHowCreateNewOperations";
import CashboxFaqHowEditDefaultAccount from "@/views/components/FaqPage/cashbox/CashboxFaqHowEditDefaultAccount";
import SettingsFaqHowInviteNewUser from "@/views/components/FaqPage/settings/SettingsFaqHowInviteNewUser";
import SettingsFaqHowCreateNewModule from "@/views/components/FaqPage/settings/SettingsFaqHowCreateNewModule";

export default {
    name: 'FaqRouter',
    components: {
        SettingsFaqHowCreateNewModule,
        SettingsFaqHowInviteNewUser, CashboxFaqHowEditDefaultAccount, CashboxFaqHowCreateNewOperations},
    props: ['component']
}
</script>

<style scoped>

</style>
