<template>
    <BaseErrorComponent :title="'Весть объем для этого заказа распределен'">
        <span>Весть объем для этого заказа распределен</span>
    </BaseErrorComponent>
</template>
<script>
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue";

export default {
    name: "EntireVolumeIsDistributed",
    components: {BaseErrorComponent}
}
</script>
<style scoped>

</style>
