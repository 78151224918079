<template>
    <Guest>
        <div class="card login">
            <div class="card-body">
                <div class="login">
                    <div class="auth-block text-center">
                        <h1>Авторизация</h1>
                        <p>Введите ваш логин и пароль для входа в систему</p>
                    </div>
                    <div class="p-20">
                        <el-form id="login-form"
                                 :label-position="labelPosition"
                                 v-loading="loading">

                            <FormInput
                                v-model="username"
                                label="E-mail, логин или номер телефона"
                                placeholder="Ваш e-mail, логин или номер телефона"
                            />

                            <FormInput
                                v-model="password"
                                type="password"
                                label="Пароль"
                                placeholder="Введите пароль"
                            />

                            <el-form-item>
                                <el-button class="w-100 guest-btn" @click.prevent="doLogin">Войти</el-button>
                            </el-form-item>
                        </el-form>
                        <p class="text-center text-light mt-30">Забыли пароль?
                            <el-link type="primary" href="/forgot">Восстановить пароль</el-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from './layouts/Guest'
import FormInput from '@/views/components/Form/FormInput'
import {mapActions} from 'pinia'
import {useCommonStore} from '@/store/common'
import {authService} from '@/services/auth.service'

export default {
    name: 'Login',
    components: {
        FormInput,
        Guest
    },
    data() {
        return {
            labelPosition: 'top',
            username: null,
            password: null,
            loading: false
        }
    },
    inject: [
        'api'
    ],
    created() {
        if (this.$route.query.token) {
            this.doLoginByToken(this.$route.query.token)
        }

        if (this.$route.name === 'Demo') {
            this.username = 'demo'
            this.password = 'demo1234'
            this.doLogin()
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setToken',
            'setUser',
            'setNav',
            'setWidgets',
            'setModules'
        ]),
        doLogin() {
            this.loading = true
            authService().doLogin(this.username.toLowerCase(), this.password).finally(() => {
                this.loading = false
            })
        },
        doLoginByToken(token) {
            this.loading = true
            authService().doLoginByToken(token).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>
