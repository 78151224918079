<template>
    <div id="setup" :class="{'status-wait': user.status === 'wait'}">
        <div class="card">
            <div class="card-body">

                <div id="logo">
                    <a href="/">
                        <DGLightLogo/>
                    </a>
                </div>

                <template v-if="user.status === 'wait'">
                    <div class="setup-body">
                        <h1 class="animation animation-1">Подготавливаем ваш аккаунт!</h1>
                        <p class="animation animation-1">С вами свяжется персональный менеджер и поможет завершить
                            настройку аккаунта. Кроме того, он расскажет, как с помощью сервиса увеличить прибыль вашего
                            бизнеса</p>
                        <p class="animation animation-1" style="font-size: 72px;line-height: 90px;">🧑🏻‍💻</p>
                    </div>
                </template>
                <template v-else>
                    <div class="setup-body" v-loading="loading">

                        <div class="step" v-if="step === 0">
                            <div class="step-body">
                                <h1 class="animation animation-1">Добро пожаловать</h1>
                                <div class="setup-img animation animation-2">
                                    <img :src="require(`@/assets/setup/setup-step0.png`)"
                                         :srcset="require(`@/assets/setup/setup-step0@2x.png`) + ' 2x'"
                                         alt="Добро пожаловать">
                                </div>
                                <p class="animation animation-3">Рады, что вы присоединились к Цифровой Платформе!
                                    Давайте
                                    познакомим вас с функционалом сервиса</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 1">
                            <div class="step-body">
                                <h1 class="animation animation-1">Чем полезен сервис?</h1>
                                <div class="setup-img animation animation-2">
                                    <img :src="require(`@/assets/setup/setup-step1.png`)"
                                         :srcset="require(`@/assets/setup/setup-step1@2x.png`) + ' 2x'"
                                         alt="Чем полезен сервис">
                                </div>
                                <p class="animation animation-3">Цифра в первую очередь помогает быстро понять,
                                    всё ли в порядке на ваших объектах</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 2">
                            <div class="step-body">
                                <h1 class="animation animation-1">Рабочий стол и виджеты</h1>
                                <div class="setup-img animation animation-2">
                                    <img :src="require(`@/assets/setup/setup-step2.png`)"
                                         :srcset="require(`@/assets/setup/setup-step2@2x.png`) + ' 2x'"
                                         alt="Рабочий стол">
                                </div>
                                <p class="animation animation-3">Добавьте на рабочий стол полезные виджеты - небольшие
                                    блоки
                                    с информацией за сегодня. Например, выведите данные об отгруженной продукции с
                                    бетонного
                                    завода</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 3">
                            <div class="step-body">
                                <h1 class="animation animation-1">Модули</h1>
                                <p class="animation animation-2">Модули - это мини-сервисы внутри сервиса. Например,
                                    есть
                                    модуль “Контракты”, который позволяет вести учет по ваши договорам и их продукции.
                                    Или
                                    модуль “Весовой терминал”, который позволит вести учет весовой</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-3">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 4">
                            <div class="step-body">
                                <h1 class="animation animation-1">Типы модулей</h1>
                                <p class="animation animation-2">Вам доступны следующие типы модулей: бетонный завод,
                                    весовой терминал, денежный учет, учет контрактов и складской учет</p>
                                <p class="animation animation-3">Вы можете создать неограниченное число модулей одного
                                    типа.
                                    Например, 3 бетонных завода для учета данных с ваших объектов</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 5">
                            <div class="step-body">
                                <h1 class="animation animation-1">Интеграции</h1>
                                <div class="setup-img animation animation-2">
                                    <img :src="require(`@/assets/setup/setup-step5.png`)"
                                         :srcset="require(`@/assets/setup/setup-step5@2x.png`) + ' 2x'"
                                         alt="Интеграции">
                                </div>
                                <p class="animation animation-3">Подключить данные с внешних источников можно, если
                                    перейти
                                    в Настройки -> Интеграции -> Добавить интеграцию. Так, например, вы сможете
                                    подключить
                                    данные с вашего бетонного завода от компании ТТС</p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 6">
                            <div class="step-body">
                                <h1 class="animation animation-1">Немного настроек</h1>

                                <div v-if="objects.length === 1" class="animation animation-2">

                                    <p>Мы уже немного настроили сервис для вас. Проверьте, верны ли название и часовой
                                        пояс
                                        вашего объекта?</p>

                                    <el-form label-position="top">

                                        <FormInput
                                            placeholder="Название объекта"
                                            v-model="objects[0].name"
                                        />

                                        <FormSelect
                                            placeholder="Часовой пояс"
                                            v-model="objects[0].timezone"
                                            noClearable="true"
                                            :items="timezones"
                                        />

                                    </el-form>

                                </div>

                                <div v-if="objects.length > 1" class="animation animation-2">

                                    <p>Мы уже немного настроили сервис для вас. Проверьте, верны ли названия и часовые
                                        пояса
                                        ваших объектов?</p>

                                    <el-form label-position="top" v-for="(object, index) in objects" :key="index">

                                        <FormInput
                                            placeholder="Название объекта"
                                            v-model="object.name"
                                        />

                                        <FormSelect
                                            placeholder="Часовой пояс"
                                            v-model="object.timezone"
                                            noClearable="true"
                                            :items="timezones"
                                        />

                                        <hr v-if="index !== objects.length - 1"/>

                                    </el-form>

                                </div>

                                <div v-if="objects.length === 0" class="animation animation-2">

                                    <p>Давайте добавим ваш объект в систему. Для этого нужно указать его название (н-р,
                                        название компании) и выбрать часовой пояс</p>

                                    <el-form label-position="top">
                                        <FormInput
                                            placeholder="Название объекта"
                                            v-model="newObject.name"
                                        />

                                        <FormSelect
                                            placeholder="Выберите часовой пояс"
                                            v-model="newObject.timezone"
                                            noClearable="true"
                                            :items="timezones"
                                        />

                                    </el-form>

                                </div>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 7">
                            <div class="step-body">
                                <h1 class="animation animation-1">Ещё немного настроек</h1>

                                <div class="animation animation-2" v-if="modules.length > 0">
                                    <p>Предпоследний шаг. Мы добавили для вас несколько
                                        модулей и настроили для них интеграцию с оборудованием от компании ТТС.
                                        Проверьте,
                                        всё ли верно?</p>

                                    <el-form label-position="top" v-for="(module, index) in modules" :key="index">

                                        <FormInput
                                            label="Название модуля"
                                            placeholder="Введите название модуля"
                                            v-model="module.name"
                                        />

                                        <FormSelect
                                            v-if="objects.length > 1"
                                            label="Объект модуля"
                                            placeholder="Выберите объект"
                                            v-model="module.factory_id"
                                            :items="objects"
                                        />

                                        <div class="moduleInfos">
                                            <span class="moduleInfo">Тип модуля: {{ module.modules.name }}</span>
                                            <span class="moduleInfo" v-if="module.factory_id">Объект: {{
                                                    getInfo(module.factory_id, objects, 'name')
                                                }}</span>
                                            <span class="moduleInfo" v-if="module.integrations.length > 0">Подключенные интеграции: <span
                                                v-for="(integration, index) in module.integrations"
                                                :key="index">{{ integration.integration.key }}<span
                                                v-if="index !== module.integrations.length - 1">, </span></span></span>
                                        </div>

                                        <hr v-if="index !== modules.length - 1"/>

                                    </el-form>
                                </div>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-3">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 8">
                            <div class="step-body">
                                <h1 class="animation animation-1">Отчет о работе на почту</h1>
                                <p class="animation animation-2">Мы будем присылать полезный отчет вам на электронную
                                    почту.
                                    Во сколько вам удобно его получать? Отключить отчет можно будет в настройках</p>

                                <el-form class="animation animation-3" label-position="top">

                                    <FormSelect
                                        v-model="report.hour"
                                        :items="hours"
                                        label="Время получения"
                                        placeholder="Выберите время"
                                        :no-clearable="true"
                                    />

                                    <FormSelect
                                        v-model="report.timezone"
                                        :items="timezones"
                                        label="Часовой пояс"
                                        placeholder="Выберите часовой пояс"
                                        :no-clearable="true"
                                    />

                                </el-form>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-4">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 9">
                            <div class="step-body">
                                <h1 class="animation animation-1">Настройка завершена</h1>
                                <p class="animation animation-2">Благодарим вас, что прошли все этапы настройки сервиса!
                                    Если у вас будут вопросы, то пишите нам в WhatsApp или Telegram по номеру <span
                                        style="white-space: nowrap;">+7 (999)
                                    765-47-17</span></p>
                            </div>
                            <el-button @click="nextStep" class="setup-btn animation animation-3">Продолжить</el-button>
                        </div>

                        <div class="step" v-if="step === 10">
                            <div class="step-body">
                                <h1 class="animation animation-1">Приятной работы</h1>
                                <div class="setup-img animation animation-2">
                                    <img :src="require(`@/assets/setup/setup-step9.png`)"
                                         :srcset="require(`@/assets/setup/setup-step9@2x.png`) + ' 2x'"
                                         alt="Приятной работы">
                                </div>
                                <p class="animation animation-3">На текущий момент Цифра находится в
                                    предрелизном состоянии. Надеемся, что своей обратной связью вы поможете нам
                                    подготовиться
                                    к большому запуску</p>
                            </div>
                            <el-button @click="endSetup" :loading="loading" class="setup-btn animation animation-4">
                                Завершить настройку
                            </el-button>
                        </div>

                    </div>
                </template>
            </div>
        </div>

        <div class="navigation">
            <a href="https://wa.me/89099775717?text=Мне%20нужна%20помощь%20с%20Цифровой%20Платформой" class="link">
                Нужна помощь
            </a>
            <span @click="doLogout" class="link">
                Выйти
            </span>
        </div>
    </div>
</template>

<script>
import DGLightLogo from '@/views/components/Logo/DGLightLogo'
import FormInput from '@/views/components/Form/FormInput'
import FormSelect from '@/views/components/Form/FormSelect'
import timezonesMixin from '@/mixins/timezonesMixin'
import getTimezone from '@/mixins/getTimezone'
import functionsMixin from '@/mixins/functionsMixin'
import hoursMixin from '@/mixins/hoursMixin'
import {httpService} from '@/services/http.service'
import {authService} from "@/services/auth.service";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'Setup',
    components: {FormSelect, FormInput, DGLightLogo},
    data() {
        return {
            step: 0,
            loading: false,
            objects: [],
            modules: [],
            newObject: {
                name: null,
                timezone: null
            },
            report: {
                hour: 20,
                timezone: this.getTimezone()
            }
        }
    },
    mixins: [
        timezonesMixin, getTimezone, functionsMixin, hoursMixin
    ],
    computed: {
        ...mapState(useCommonStore, {
            user: 'getUser',
        })
    },
    beforeCreate: function () {
        document.body.className = 'dark-bg'
    },
    beforeUnmount: function () {
        document.body.className = ''
    },
    created() {
        httpService().post(this.api.user.getSetupInfo).then((response) => {
            let data = response.data

            if (data.success) {
                this.objects = data.objects
                this.modules = data.modules


                if (this.objects.length === 0 && this.newObject.name) {
                    this.newObject = {
                        name: data.company?.short_name ?? null,
                        timezone: this.getTimezone()
                    };
                }
            }


        }).catch((error) => {
            this.$message({
                message: error.response.data.message,
                type: 'error',
                showClose: true,
            })
            this.loading = false
        })
    },
    inject: [
        'api'
    ],
    methods: {
        nextStep() {
            let next = true;
            if (this.step === 6) {
                this.objects.map(item => {
                    if (!item.name) {
                        next = false;
                        this.$message({
                            message: "Введите название объекта",
                            type: 'error',
                            showClose: true,
                        })
                    }
                });
            }

            if (next) {
                this.step++;
            }
        },
        doLogout() {
            authService().doLogout()
        },
        endSetup() {
            this.loading = true;
            if (this.newObject.name) {
                this.objects.push(this.newObject);
            }
            httpService().post(this.api.user.setup, {
                objects: this.objects,
                modules: this.modules,
                report: this.report
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.$router.go('/dashboard')

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loading = false
            })
            this.loading = false
        }
    },
    watch: {
        'step': {
            handler: function () {
                if (this.step === 7 && this.modules.length === 0) {
                    this.nextStep();
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
