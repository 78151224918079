<template>
    <el-table :data="displayData" style="width: 100%" :scrollbar-always-on="false">
        <el-table-column prop="day" label="Дата" width="220"/>
        <el-table-column prop="done" label="Объем">
            <template v-slot="scope">
                <div class="textMedium primary600 pt-5 pb-5">
                    {{
                        scope.row.done ? formatNumber(scope.row.done, 2) : '-'
                    }} м<sup>3</sup>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="count" label="Кол-во заявок">
            <template v-slot="scope">
                <div class="textMedium success400">
                    {{
                        scope.row.count ? formatNumber(scope.row.count, 2) : '-'
                    }}
                </div>
            </template>
        </el-table-column>
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination.vue'
import functionsMixin from '@/mixins/functionsMixin'

export default {
    name: 'ReportMixOrdersExpand',
    components: {TablePagination},
    props: ['orders'],
    mixins: [tableMixin, functionsMixin],
    methods: {
        filterValues() {
            this.filtered = this.orders
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
    }
}
</script>

<style scoped>

</style>
