<template>
    <el-button :loading="loading" :disabled="disabled" type="primary" icon="el-icon-plus" class="w-100"
               @click="doAction">{{ title ?? 'Создать' }}
    </el-button>
</template>

<script>
export default {
    name: "DrawerCreateButton",
    props: [
        'loading',
        'title',
        'disabled'
    ],
    methods: {
        doAction() {
            this.$emit('action')
        }
    }
}
</script>

<style scoped>

</style>
