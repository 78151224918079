<template>
    <Auth title="Аналитика">
        <div class="card">
            <div class="card-body">
                <div class="statusBlock">
                    <div v-if="factories.length > 1">
                        <el-select v-model="selectedFactoryId" placeholder="Объект" size="small">
                            <el-option
                                v-for="item in factories"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div v-else-if="factories.length === 1">
                        {{ factories[0].name }}
                    </div>
                    <div v-else>
                        -
                    </div>
                    <div class="statusBlockPeriod">
                        Текущий месяц
                    </div>
                </div>

                <div v-if="loading" class="text-center mt-30">
                    <ReportLoader/>

                    <h3>Формирование аналитических данных</h3>
                </div>

                <template v-else>
                    <div class="analyticsCard pointer"
                         @click="$router.push({name: 'AnalyticsModule', params: { moduleKey: module.type, moduleId: module.moduleAccountId }})"
                         v-for="(module, index) in modules"
                         :key="index">
                        <div class="analyticsCardBody">
                            <h3 class="mt-0">{{ module.name }}</h3>
                            <el-row :gutter="16" v-if="module.indicators">
                                <el-col :md="24" :xl="24">
                                    <AnalyticsMainBlock
                                        title="Прибыль"
                                        type="money"
                                        :data="module.indicators.profit"
                                        :loading="loading"
                                    />
                                </el-col>
                            </el-row>
                            <div class="no-data-block" v-else>
                                <NoDataIcon />
                                <p style="color: #49515f !important;">
                                    Идет анализ данных<br />Будет готово через 5 минут
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import {httpService} from '@/services/http.service'
import AnalyticsMainBlock from "@/views/components/AnalyticsPage/AnalyticsBlocks/AnalyticsMainBlock.vue";
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";
import NoDataIcon from "@/views/components/NoDataIcon.vue";

export default {
    name: 'AnalyticsIndex',
    data() {
        return {
            loading: true,
            factories: [],
            selectedFactoryId: null,
            modules: [],
        }
    },
    components: {
        NoDataIcon,
        ReportLoader,
        AnalyticsMainBlock, Auth
    },
    inject: ['api'],
    created() {
        this.getFactoriesList()
    },
    methods: {
        async getFactoriesList() {
            try {
                this.loading = true

                const response = await httpService().post(this.api.settings.getFactories);
                const data = response.data;

                if (data.success) {
                    this.factories = data.factories;

                    if (this.factories.length > 0) {
                        this.selectedFactoryId = this.factories[0].id;
                    }
                }
            } catch(error) {
                this.$message.error('Ошибка ответа от сервера')
                this.$router.push({ name: 'logout' })
            }
        },
        async getFactoryAnalytics() {
            try {
                this.loading = true

                const response = await httpService().post(this.api.analytics.get, {
                    type: 'factory',
                    id: this.selectedFactoryId
                });
                const data = response.data;

                if (data.success) {
                    this.loadingButton = false;
                    this.modules = data.data;
                    this.loading = false;
                }
            } catch (error) {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                });
                this.$router.push({name: 'logout'});
            }
        }
    },
    watch: {
        'selectedFactoryId': {
            handler: function () {
                if (this.selectedFactoryId) this.getFactoryAnalytics()
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
