<template>
    <BaseErrorComponent :title="'Не хватает машин для заказов на день'">
        <div>
            <div>Не распределён следующий объём по заказам:</div>
            <ul class="orders-list">
                <li v-for="(item, index) in items" :key="index">
                    {{ getOrderTitle(item.groupId) }} - не распределено {{ item.unallocated }} из {{ item.total }} м³
                </li>
            </ul>
        </div>
    </BaseErrorComponent>
</template>
<script>
import BaseErrorComponent from "@/views/dispatch/components/distributeErrors/BaseErrorComponent.vue";

export default {
    name: "NotEnoughVehicles",
    components: {BaseErrorComponent},
    props:['items'],
    methods:{
        getOrderTitle(id){
            if (id) return 'Заказ #'+id
            return 'Новый заказ'
        }
    }
}
</script>
<style scoped lang="scss">
.orders-list{
    padding: 0px 27px;
}
</style>
