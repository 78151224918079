<template>
    <Auth title="Каталог продукции" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    create-button-label="Добавить продукцию"
                    @moduleGet="getAll()"
                    @openCreateValueDrawer="$router.push({ name: 'ProductionCreateGood', params: { id: $route.params.id } })"
                    :new="true"
                />

                <el-table
                    empty-text="Информация не найдена"
                    v-loading="loading"
                    :data="displayData"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="name"
                        label="Продукция"
                        sortable>
                        <template v-slot="scope">
                            <span class="textMedium textSM neutral900">{{ scope.row.name ?? '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="130">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="openDetails(scope.row)"
                            >Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="70">
                        <template v-slot="scope">
                            <el-button :loading="loading" @click="remove(scope.row.id)" class="orderGroupItemDelete"
                                       :icon="icons.delete" size="small"></el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    :loading="loading"
                    @action="setPage"
                />
            </div>
        </div>
        <el-dialog
            v-model="detailsDialog"
            :title="selectedGood.name"
            :width="mobile ? '100%' : '65%'"
            :destroy-on-close="true"
        >
            <div class="orderGroupsTable">
                <div class="bg-neutral30 orderGroupItem" v-for="(step, index) in selectedGood.steps" :key="index"
                     style="padding: 20px">
                    <div class="textBold textSM neutral700">{{ index + 1 }}. {{ step.name }}</div>
                    <hr/>
                    <div>Тип этапа: {{ getStepTypeLabel(step.type) }}</div>
                    <template v-if="step.type === 'concrete'">
                        <div>Рецепт: {{ getData(step.goodId, 'good') }}</div>
                        <div>Объем отгрузки: {{ step.total }}</div>
                    </template>
                </div>
            </div>
        </el-dialog>
    </Auth>
</template>

<script>
import {httpService} from "@/services/http.service";
import tableMixin from "@/mixins/tableMixin";
import functionsMixin from "@/mixins/functionsMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import Auth from "@/views/layouts/Auth.vue";
import productionTabs from "@/mixins/productionTabs";

export default {
    name: 'ProductionGoodsCatalogIndex',
    components: {Auth, TablePagination, TableHeadBlock},
    inject: ['api'],
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, productionTabs],
    data() {
        return {
            loading: false,
            detailsDialog: false,
            selectedGood: {
                name: null,
                steps: []
            },
            modules: [],
            pageSize: 30
        }
    },
    created() {
        this.getAll()
    },
    methods: {
        getAll() {
            this.loading = true

            httpService().post(this.api.productionGood.getAll, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'production'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        openDetails(good) {
            this.selectedGood = good
            this.detailsDialog = true
        },
        getStepTypeLabel(type) {
            if (type === 'production') return 'Производство на посту'
            else if (type === 'concrete') return 'Производство бетона'
            else if (type === 'control') return 'Контроль качества'
            else if (type === 'findOrCreate') return 'Найти или произвести продукцию'
        },
        remove(id) {
            this.$confirm(
                'Вы действительно хотите удалить данную продукцию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionGood.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'production',
                        id: id
                    }).then(() => {
                        this.$message({
                            message: 'Продукция удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values
            this.valueLength = this.filtered.length
            if (this.search) this.filtered = this.values.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
