<template>
    <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.08981 8.82031C3.08981 9.02773 3.25621 9.19531 3.46246 9.19531H6.53746C6.74371 9.19531 6.91012 9.02773 6.91012 8.82031V6.52344H3.08981V8.82031ZM9.31363 0.804688H0.686291C0.399181 0.804688 0.219885 1.11758 0.364025 1.36719L2.95739 5.77344H7.04489L9.63824 1.36719C9.78004 1.11758 9.60074 0.804688 9.31363 0.804688Z" fill-opacity="0.25"/>
</svg>
</template>

<script>
export default {
    name: "FilterIcon"
}
</script>

<style scoped>

</style>
