<script>
import { ref, watch, onBeforeUnmount, computed } from 'vue';

export default {
    name: 'Timer',
    props: {
        status: {
            type: Boolean,
            default: false
        },
        startDate: {
            type: Date,
            default: null
        }
    },
    setup(props) {
        const intervalId = ref(null);
        const milliseconds = ref(0);

        const timerFormat = computed(() => {
            const totalMilliseconds = props.startDate ? Date.now() - props.startDate.getTime() : milliseconds.value;
            const seconds = Math.floor((totalMilliseconds / 1000) % 60);
            const minutes = Math.floor((totalMilliseconds / (1000 * 60)) % 60);
            const hours = Math.floor((totalMilliseconds / (1000 * 60 * 60)) % 24);

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        });

        const startTimer = () => {
            clearInterval(intervalId.value);
            milliseconds.value = 0;

            intervalId.value = setInterval(() => {
                milliseconds.value += 1000;
            }, 1000);
        };

        const resetTimer = () => {
            clearInterval(intervalId.value);
            milliseconds.value = 0;
        };

        watch(() => props.status, (newVal) => {
            if (newVal) {
                startTimer();
            } else {
                resetTimer();
            }
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId.value);
        });

        return {
            timerFormat
        };
    }
};
</script>

<template>
    <div class="d-flex" style="gap: 12px;">
        <TimerIcon />
        <div class="textSM textRegular primary600" style="font-size: 30px;">{{ timerFormat }}</div>
    </div>
</template>

<style scoped></style>
