<template>
    <Guest>
        <div class="card forgot">
            <div class="card-body">
                <div class="auth-block text-center">
                    <h1>Забыли пароль?</h1>
                    <p>Установите новый пароль, после авторизуйтесь под новыми данными на странице авторизации</p>
                </div>
                <div class="p-20">
                    <el-form id="login-form" label-position="top"
                             v-loading="loading">

                        <FormInput
                            v-model="password"
                            label="Пароль"
                            type="password"
                            placeholder="Введите пароль"
                        />

                        <FormInput
                            v-model="password_confirmation"
                            label="Подтвердите пароль"
                            type="password"
                            placeholder="Введите пароль еще раз"
                        />

                        <el-form-item>
                            <el-button class="w-100 guest-btn" @click.prevent="doPassword">Сбросить пароль
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <p class="text-center text-light mt-30">
                        <el-link type="primary" href="/login">Авторизация</el-link>
                    </p>
                </div>
            </div>
        </div>
    </Guest>
</template>

<script>

import axios from 'axios'
import Guest from './layouts/Guest'
import FormInput from "@/views/components/Form/FormInput";

export default {
    name: "Password",
    components: {
        FormInput,
        Guest
    },
    data() {
        return {
            loading: false,
            password: null,
            password_confirmation: null,
            token: this.$route.query.token,
        }
    },
    inject: [
        'api'
    ],
    methods: {
        doPassword() {
            this.loading = true
            this.$root.loadingState()

            axios.post(this.api.account.password, {
                token: this.token,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Пароль успешно изменен',
                        type: 'success'
                    })
                    this.password = null
                    this.password_confirmation = null
                } else {
                    var textError = data.message
                    if (textError === 'Password length minimum 6 symbols') textError = 'Пароль должен состоять минимум из 6 символов'
                    if (textError === 'Failed password confirmation') textError = 'Пароли не совпадают. Проверьте данные'

                    this.$message({
                        message: textError,
                        type: 'error'
                    })
                }
            }).catch((error) => {
                var textError = error.response.data.message
                if (textError === 'Password length minimum 6 symbols') textError = 'Пароль должен состоять минимум из 6 символов'
                if (textError === 'The request is missing a required parameter, includes an invalid parameter value, includes a parameter more than once, or is otherwise malformed.') {
                    if (error.response.data.hint === 'Check the `username` parameter') textError = 'Введите e-mail'
                    if (error.response.data.hint === 'Check the `password` parameter') textError = 'Введите пароль'
                }

                this.$message({
                    message: textError,
                    type: 'error'
                })
            }).finally(() => {
                this.loading = false
                this.$root.loadingState(false)
            })
        }
    }
}
</script>
