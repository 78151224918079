export default {
    data() {
        return {
            paymentMethods: [{
                id: 'bankWithVAT',
                name: 'Р/С с НДС'
            }, {
                id: 'bankWithoutVAT',
                name: 'Р/С без НДС'
            }, {
                id: 'cashbox',
                name: 'Через кассу'
            }, {
                id: 'cash',
                name: 'Наличные'
            }, {
                id: 'transfer',
                name: 'Перевод'
            }]
        }
    },
}
