export default {
    data() {
        return {
            tabs: [{
                route: 'DispatchOrderGroups',
                name: 'Заказы'
            }, {
                route: 'DispatchOrders',
                name: 'Отгрузки'
            }, {
                route: 'DispatchPosts',
                name: 'Посты'
            }, {
                route: 'DispatchMixers',
                name: 'Миксеры'
            }, {
                route: 'DispatchPumps',
                name: 'Насосы'
            }],
        }
    }
}
