<template>
    <template v-if="itemValue.length > 0">
        <span class="topCardLink" v-if="!edit" @click="() => edit = true">
            <template
                v-for="(item, index) in itemValue"
                :key="index">{{ getInfo(item, items) }}
                <template
                    v-if="index !== itemValue.length - 1">,
                </template>
            </template>
        </span>
    </template>
    <template v-else>
        <span class="topCardLink" v-if="!edit" @click="() => edit = true" type="text">{{ emptyText ?? 'любые' }}</span>
    </template>

    <template v-if="edit">

        <div class="d-flex">

            <el-select
                v-model="newValue"
                placeholder="Выберите значения"
                class="mr-15"
                multiple
                collapse-tags
                clearable
                filterable
            >
                <el-option
                    v-for="item in items"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>

            <el-button @click="doEdit()" :icon="icons.check"/>
            <el-button @click="cancelEdit()" :icon="icons.close"/>

        </div>
    </template>
</template>

<script>
import iconsMixin from '@/mixins/iconsMixin'
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'RelationButtonSelect',
    props: ['items', 'modelValue', 'emptyText'],
    mixins: [iconsMixin, functionsMixin],
    data() {
        return {
            itemValue: this.modelValue,
            newValue: this.itemValue,
            edit: false,
        }
    },
    methods: {
        cancelEdit() {
            this.edit = false
            this.newValue = this.itemValue
        },
        doEdit() {
            this.itemValue = this.newValue
            this.edit = false
        }
    },
    watch: {
        'modelValue': {
            handler: function () {
                this.itemValue = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'itemValue': {
            handler: function () {
                this.newValue = this.itemValue
                this.$emit('update:modelValue', this.itemValue)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
