<template>
    <DrawerFramework>
        <template v-slot:body>

            <DrawerHeadTitle :title="details.name"/>

            <DrawerTextLineItem
                name="Текущий остаток"
                :desc="formatNumber(details.total) + ' руб.'"
            />

            <template v-if="details.id !== 'default'">

                <DrawerTextLineItem
                    name="Р/С"
                    :desc="details.number ?? '-'"
                />

                <el-collapse class="mt-30">

                    <DrawerCollapseItem
                        title="Банк"
                        subtitle="Подробности"
                    >
                        <template v-slot:body>
                            {{ details.bank ?? 'Нет информации' }}
                        </template>
                    </DrawerCollapseItem>

                </el-collapse>
            </template>
            <template v-else>
                <p>Это виртуальный счет, который создан автоматически и выбирается по умолчанию в создании операций.
                    Остаток можно обновить в настройках модуля</p>
            </template>
        </template>
        <template v-if="details.id !== 'default'" v-slot:footer>
            <DrawerEditButton :loading="loading" @action="$emit('doEdit')"/>
            <DrawerDeleteButton :loading="loading" @action="removeAccount(details.id)"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import DrawerTextLineItem from '@/views/components/Drawer/DrawerTextLineItem'
import functionsMixin from '@/mixins/functionsMixin'
import DrawerCollapseItem from "@/views/components/Drawer/DrawerCollapseItem";
import DrawerEditButton from "@/views/components/Drawer/Buttons/DrawerEditButton";
import DrawerDeleteButton from "@/views/components/Drawer/Buttons/DrawerDeleteButton";
import {httpService} from "@/services/http.service";

export default {
    name: 'CashboxAccountDetails',
    props: ['details'],
    inject: ['api'],
    components: {
        DrawerDeleteButton,
        DrawerEditButton, DrawerCollapseItem, DrawerTextLineItem, DrawerHeadTitle, DrawerFramework
    },
    mixins: [functionsMixin],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        removeAccount(id) {
            this.loading = true

            this.$confirm(
                'Вы действительно хотите удалить счет?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.cashboxAccount.remove, {
                        account_module_reserve_id: this.$route.params.id,
                        module_key: 'cashbox',
                        accountId: id
                    }).then((response) => {
                        let answer = response.data

                        if (answer.success)
                            this.$message({
                                message: 'Счет удален',
                                showClose: true,
                                type: 'success'
                            })

                        this.$emit('moduleGet')
                        this.$emit('closeDrawer')

                        this.loading = false
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            showClose: true,
                            type: 'error'
                        })
                        this.loading = false
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
