<template>
    <div v-loading="loading">
        <p class="textRegular neutral400">Время последней активности: {{ unit.activity ? getDateFromUnix(unit.activity, 'DD.MM в HH:mm') : '-' }}</p>

        <el-tabs v-model="activeTab">
            <el-tab-pane label="Запросы из 1С" name="data">
                <p class="textXS neutral300">Последние 10 запросов из 1С в Цифру</p>
                <el-table
                    v-loading="loading"
                    :data="data"
                    empty-text="Запросы не найдены"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="120"
                    >
                        <template v-slot="scope">
                            <span class="textMedium neutral400">{{ scope.row.id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="method"
                        label="Метод"
                    >
                        <template v-slot="scope">
                            <span class="textMedium primary600">{{ getInfo(scope.row.method, oneCMethods) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="created_at"
                        label="Дата"
                        width="140"
                    >
                        <template v-slot="scope">
                            {{ getDateMoment(scope.row.created_at, 'DD.MM в HH:mm') }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Задания" name="tasks">
                <p class="textXS neutral300">Последние 10 заданий, полученных 1С</p>
                <el-table
                    v-loading="loading"
                    :data="tasks"
                    empty-text="Задания не найдены"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="120"
                    >
                        <template v-slot="scope">
                            <span class="textMedium neutral400">{{ scope.row.id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="task"
                        label="Задание"
                    >
                        <template v-slot="scope">
                            <span class="textMedium primary600">{{ getInfo(scope.row.task, oneCTasks) ?? scope.row.task }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="Статус"
                    >
                        <template v-slot="scope">
                            <span class="textMedium"
                            :class="[
                                {neutral200: scope.row.status === 'new'},
                                {primary600: scope.row.status === 'work'},
                                {success400: scope.row.status === 'done'},
                                {danger300: scope.row.status === 'failed'}
                            ]"
                            >{{ getInfo(scope.row.status, oneCStatuses) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="created_at"
                        label="Дата"
                        width="140"
                    >
                        <template v-slot="scope">
                            {{ scope.row.created_at ? getDateMoment(scope.row.created_at, 'DD.MM в HH:mm') : 'не указана' }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Настройки" name="settings">
                <el-form ref="form" :model="settings" size="small" label-position="top">
                    <el-row :gutter="15">
                        <el-col :sm="12">
                            <FormInput
                                v-model="settings.secretKey"
                                label="Ключ"
                                placeholder="Введите ключ"
                            />
                        </el-col>
                        <el-col :sm="12">

                            <FormSelect
                                v-model="unit.company_id"
                                label="К какой компании привязана интерграция"
                                placeholder="Выберите компанию"
                                new-item="company"
                                :new-item-data="unit.company_id"
                                size="large"
                            />
                        </el-col>
                    </el-row>

                    <el-row :gutter="15">
                        <el-col :sm="8">

                            <FormSelect
                                v-model="settings.mode"
                                label="Способ добавления реализации"
                                placeholder="Выберите метод"
                                :items="oneCModes"
                            />

                        </el-col>
                        <el-col :sm="8">

                            <FormSelect
                                v-model="settings.actionTrigger"
                                label="Когда создавать задание на реализацию"
                                placeholder="Выберите вариант"
                                :items="oneCActionTriggers"
                            />

                        </el-col>
                        <el-col :sm="8">
                            <FormSelect
                                v-model="settings.cashboxId"
                                label="Модуль для финансовых операций"
                                placeholder="Выберите модуль"
                                :items="modules.filter(el => el.module_key === 'cashbox')"
                            />
                        </el-col>
                    </el-row>

                    <el-form-item label="Обязательные поля:">
                        <el-checkbox-group v-model="settings.attributes" size="large" class="mb-5">
                            <el-checkbox
                                v-for="(attribute, index) in oneCAttributes"
                                :key="index"
                                :label="attribute.id">{{ attribute.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="С каким типом оплаты выгружать данные?:">
                        <el-checkbox-group v-model="settings.paymentMethods" size="large" class="mb-5">
                            <el-checkbox
                                v-for="(attribute, index) in paymentMethods"
                                :key="index"
                                :label="attribute.id">{{ attribute.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="Модули бетонных заводов:"
                                  v-if="modules.filter(el => el.module_key === 'mix').length > 0">
                        <el-checkbox-group v-model="settings.mixIds" size="large" class="mb-5">
                            <el-checkbox
                                v-for="(module, index) in modules.filter(el => el.module_key === 'mix')"
                                :key="index"
                                :label="module.module_reserve_id">{{ module.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <hr/>
        <div class="mt-15">
            <el-button
                type="primary"
                @click="editIntegration"
                :disabled="loading"
            >
                Сохранить изменения
            </el-button>
            <el-button :icon="icons.delete" @click="deleteIntegration"/>
        </div>
    </div>
</template>

<script>
import {httpService} from "@/services/http.service";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import functionsMixin from "@/mixins/functionsMixin";
import iconsMixin from "@/mixins/iconsMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import oneCInfo from "@/mixins/oneCInfo";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: "IntegrationDetailsOneC",
    components: {FormInput, FormSelect},
    props: [
        'integration'
    ],
    inject: [
        'api'
    ],
    mixins: [functionsMixin, iconsMixin, oneCInfo, paymentMethods],

    data() {
        return {
            activeTab: 'data',
            loading: true,
            settings: [],
            modules: [],
            data: [],
            tasks: [],
            unit: {
                id: null,
                activity: null,
                settings: {
                    secretKey: null,
                    mode: 'by_one',
                    mixIds: [],
                    cashboxId: null,
                    actionTrigger: 'orderCompletion',
                    paymentMethods: []
                }
            },
        }
    },
    created() {
        this.getModules()
        this.getOneCIntegration()
    },
    methods: {
        getOneCIntegration() {
            this.loading = true
            httpService().post(this.api.settings.getOneCIntegration, {
                unitId: this.integration.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.loading = false
                    this.unit = data.unit
                    this.settings = {
                        secretKey: this.unit?.settings?.secretKey ?? null,
                        mode: this.unit?.settings?.mode ?? 'by_one',
                        mixIds: this.unit?.settings?.mixIds ?? [],
                        cashboxId: this.unit?.settings?.cashboxId ?? null,
                        actionTrigger: this.unit?.settings?.actionTrigger ?? 'orderCompletion',
                        attributes: this.unit?.settings?.attributes ?? ['seller'],
                        paymentMethods: this.unit?.settings?.paymentMethods ?? [],
                    }
                    this.data = data.data
                    this.tasks = data.tasks
                }
            })
        },
        getModules() {
            this.loading = true
            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.modules = data.modules

            })
        },
        editIntegration() {
            this.loading = true
            httpService().post(this.api.settings.editIntegration, {
                integration: this.unit,
                settings: this.settings,
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$message({
                        message: 'Изменения приняты',
                        showClose: true,
                        type: 'success'
                    })
                    this.$emit('moduleGet')
                    this.$emit('closeDrawer')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        deleteIntegration() {
            this.loading = true

            this.$confirm(
                'Вы действительно хотите удалить интеграцию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.settings.removeIntegration, {
                        integration: this.unit,
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Интеграция удалена',
                                showClose: true,
                                type: 'success'
                            })
                        this.$emit('moduleGet')
                        this.$emit('closeDrawer')
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    }).finally(() => {
                        this.loading = false
                    })
                })

            this.loading = false
        },
    }
}
</script>

<style scoped>

</style>
