<template>
    <div class="w-100">
        <div class="mixTimeline"
             v-if="order.type === 'take-away'"
             :style="(order.type === 'take-away' ? 'width:200px' : '' )"
        >
            <MixPageOrderTimelineItem
                v-for="(status, index) in statuses.slice(0,2)" :key="index"
                :status="status.key"
                :name="status.name"
                :completed="completed(status.id)"
                :time="time(status.key)"
                :current="current(status.key)"
                :order="order"
            />
        </div>

        <div class="mixTimeline" v-else>
            <MixPageOrderTimelineItem
                v-for="(status, index) in statuses" :key="index"
                :status="status.key"
                :name="status.name"
                :completed="completed(status.id)"
                :time="time(status.key)"
                :current="current(status.key)"
                :order="order"
            />
        </div>
    </div>
</template>

<script>
import MixPageOrderTimelineItem from '@/views/components/MixPage/OrdersGroup/MixPageOrderTimelineItem.vue'
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: "MixPageOrderTimeline",
    props: ['order'],
    components: {MixPageOrderTimelineItem},
    mixins: [functionsMixin],
    data() {
        return {
            statuses: [
                {
                    id: 0,
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    id: 10,
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    id: 20,
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    id: 40,
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    id: 50,
                    key: 'done',
                    name: 'Конец'
                }
            ],
            filteredStatuses: this.statuses,
            currentId: null,
        }
    },
    created() {

    },
    methods: {
        completed(id) {
            if (this.order.status !== 'loading') {
                let status = this.order.status
                if (this.order.status === 'pouring') status = 'object'
                let statusId = this.statuses.find(el => el.key === status)?.id ?? 0
                if (this.order.status === 'wait') statusId = -10
                return id < statusId
            } else {
                return false
            }
        },
        current(key) {
            let status = this.order.status
            if (this.order.status === 'pouring') status = 'object'
            return key === status
        },
        time(status) {
            let time = null

            switch (status) {
                case 'loading':
                    time = this.order.load_at ?? null
                    break
                case 'delivery':
                    time = this.order.arrive_at ?? null
                    break
                case 'object':
                    time = this.order.unload_at ?? null
                    break
                case 'return':
                    time = this.order.return_at ?? null
                    break
            }

            return time ? moment.parseZone(time).format('HH:mm') : null
        }
    }
}
</script>

<style scoped>

</style>
