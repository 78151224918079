<template>

    <TableHeadBlock
        v-model="search"
        :loading="loading"
        createButtonLabel="Добавить технику"
        :disable-filter="true"
        @moduleGet="moduleGet"
        @openCreateValueDrawer="drawerCreate=true"
    />

    <EmptyState
        :values="displayData"
        :search="search"
        :loading="loading"
        list-types="транспортных средств"
    />

    <el-table
        v-loading="loading"
        :data="displayData"
        class="mt-15 w-100">
        <el-table-column
            prop="name"
            label="Название"
        />
        <el-table-column
            prop="number"
            label="Номер"
        />
        <el-table-column
            prop="fuelTank"
            label="Размер бака"
        />
        <el-table-column
            prop="fuelTotal"
            label="Остаток топлива"
        />
    </el-table>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />

    <el-drawer
        title=""
        v-model="drawerCreate"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true">

        <FuelVehicleCreate
            @moduleGet="moduleGet"
            @closeDrawer="drawerCreate=false"
        />

    </el-drawer>
</template>

<script>
import {httpService} from "@/services/http.service";
import tableMixin from "@/mixins/tableMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import FuelVehicleCreate from "@/views/components/FuelPage/FuelGarage/FuelVehicleCreate.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";

export default {
    name: 'FuelGarageTab',
    components: {TablePagination, FuelVehicleCreate, EmptyState, TableHeadBlock},
    inject: ['api'],
    mixins: [tableMixin, mobileCheckMixin],
    data() {
        return {
            loading: false,
            drawerCreate: false
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            httpService().post(this.api.fuel.getVehicles, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'fuel'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.vehicles

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
