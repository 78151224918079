<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="url(#paint0_linear_1328_18651)"/>
        <path opacity="0.4"
              d="M24.8099 15.435V20.565C24.8099 21.405 24.3599 22.185 23.6324 22.6125L19.1774 25.185C18.4499 25.605 17.5499 25.605 16.8149 25.185L12.3599 22.6125C11.6324 22.1925 11.1824 21.4125 11.1824 20.565V15.435C11.1824 14.595 11.6324 13.815 12.3599 13.3875L16.8149 10.815C17.5424 10.395 18.4424 10.395 19.1774 10.815L23.6324 13.3875C24.3599 13.815 24.8099 14.5875 24.8099 15.435Z"
              fill="#F74539"/>
        <path
            d="M18 19.3125C17.6925 19.3125 17.4375 19.0575 17.4375 18.75V14.8125C17.4375 14.505 17.6925 14.25 18 14.25C18.3075 14.25 18.5625 14.505 18.5625 14.8125V18.75C18.5625 19.0575 18.3075 19.3125 18 19.3125Z"
            fill="#F74539"/>
        <path
            d="M18 21.9375C17.9025 21.9375 17.805 21.915 17.715 21.8775C17.6175 21.84 17.5425 21.7875 17.4675 21.72C17.4 21.645 17.3475 21.5625 17.3025 21.4725C17.265 21.3825 17.25 21.285 17.25 21.1875C17.25 20.9925 17.325 20.7975 17.4675 20.655C17.5425 20.5875 17.6175 20.535 17.715 20.4975C17.9925 20.3775 18.3225 20.445 18.5325 20.655C18.6 20.73 18.6525 20.805 18.69 20.9025C18.7275 20.9925 18.75 21.09 18.75 21.1875C18.75 21.285 18.7275 21.3825 18.69 21.4725C18.6525 21.5625 18.6 21.645 18.5325 21.72C18.39 21.8625 18.2025 21.9375 18 21.9375Z"
            fill="#F74539"/>
        <defs>
            <linearGradient id="paint0_linear_1328_18651" x1="0" y1="36" x2="36" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCB3AE"/>
                <stop offset="1" stop-color="#FCB3AE" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "ErrorIcon"
}
</script>
