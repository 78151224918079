<template>
    <Auth title="Отчеты">
        <div class="card">
            <div class="card-body">

                <div v-if="!selectReport">
                    <ReportMenu
                        :reports="availableReports"
                        moduleKey="mix"
                        :loading=loading
                        :modules=modules
                        @doSelectReport=doSelectReport
                    />
                    <ReportMenu
                        :reports="availableReports"
                        moduleKey="dispatch"
                        :loading=loading
                        :modules=modules
                        @doSelectReport=doSelectReport
                    />
                    <ReportMenu
                        :reports="availableReports"
                        moduleKey="production"
                        :loading=loading
                        :modules=modules
                        @doSelectReport=doSelectReport
                    />
                    <ReportMenu
                        :reports="availableReports"
                        moduleKey="cashbox"
                        :loading=loading
                        :modules=modules
                        @doSelectReport=doSelectReport
                    />
                    <ReportMenu
                        :reports="availableReports"
                        moduleKey="weighing"
                        :loading=loading
                        :modules=modules
                        @doSelectReport=doSelectReport
                    />

                </div>

                <div v-if="selectReport">

                    <div v-if="!selectSettings.setup">
                        <h2>Параметры отчета</h2>

                        <el-form :label-position="!mobile ? 'left' : 'top'">

                            <div v-if="selectReport.report_key !== 'mixDrivers'">
                                <FormSelect
                                    v-model="selectSettings.moduleId"
                                    label="Модуль"
                                    placeholder="Выберите модуль"
                                    :items="filterModules(selectReport.module_key)"
                                    label-width="150px"
                                />
                            </div>

                            <FormDateTimePicker
                                v-model="selectSettings.dateStart"
                                label="Начало периода"
                                placeholder="Дата и время"
                                type="datetime"
                                label-width="150px"
                            />

                            <FormDateTimePicker
                                v-model="selectSettings.dateEnd"
                                label="Окончание периода"
                                placeholder="Дата и время"
                                type="datetime"
                                label-width="150px"
                            />

                            <el-form-item label="label"
                                          label-width="150px"
                                          style="margin-top: -20px;margin-bottom: 10px;"
                                          class="hideLabel"
                            >
                                <el-button type="text" @click="changePeriod('today')" class="periodSelect">
                                    Сегодня
                                </el-button>
                                <el-button type="text" @click="changePeriod('currentWeek')" class="periodSelect">
                                    <span v-if="!mobile">
                                        Текущая неделя
                                    </span>
                                    <span v-else>
                                        Неделя
                                    </span>
                                </el-button>
                                <el-button type="text" @click="changePeriod('currentMonth')" class="periodSelect">
                                    <span v-if="!mobile">
                                        Текущий месяц
                                    </span>
                                    <span v-else>
                                        Месяц
                                    </span>
                                </el-button>
                            </el-form-item>

                            <div v-if="selectReport.report_key === 'weighingValues'">

                                <FormSelect
                                    v-model="selectSettings.sender"
                                    label="Отправитель"
                                    placeholder="Компания отправитель"
                                    :items="companies"
                                    label-width="150px"
                                    new-item="company"
                                    :new-item-data="selectSettings.sender"
                                />

                                <FormSelect
                                    v-model="selectSettings.recipient"
                                    label="Получатель"
                                    placeholder="Компания получатель"
                                    :items="companies"
                                    label-width="150px"
                                    new-item="company"
                                    :new-item-data="selectSettings.recipient"
                                />

                            </div>

                            <div v-if="['mixOrders', 'goods'].indexOf(selectReport.report_key) !== -1">

                                <FormSelect
                                    v-model="selectSettings.company"
                                    label="Контрагент"
                                    placeholder="Выберите компанию"
                                    :items="companies"
                                    label-width="150px"
                                    new-item="company"
                                    :new-item-data="selectSettings.company"
                                />

                            </div>

                        </el-form>

                        <el-button :loading="loading" type="primary"
                                   @click="generateReport">Сформировать отчет
                        </el-button>

                        <el-button plain :icon="icons.back"
                                   @click="resetReport">Другой отчет
                        </el-button>
                    </div>

<!--                    <ReportWeighingValues-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'weighingValues'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

<!--                    <ReportMixOrders-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'mixOrders'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

<!--                    <ReportGoods-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'goods'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

<!--                    <ReportMixDrivers-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'mixDrivers'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

<!--                    <ReportMixGroupOrders-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'mixGroupOrders'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

<!--                    <ReportProductionGoods-->
<!--                        v-if="selectSettings.setup && selectReport.report_key === 'productionGoods'"-->
<!--                        :settings="selectSettings"-->
<!--                        @resetReportSelect="resetReport"-->
<!--                    />-->

                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import ReportMenu from '@/views/components/ReportPage/ReportMenu'
import Auth from './../layouts/Auth'
import FormDateTimePicker from '@/views/components/Form/FormDateTimePicker'
import FormSelect from '@/views/components/Form/FormSelect'
import moment from 'moment'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import mobileCheckMixin from "@/mixins/mobileCheckMixin"
import iconsMixin from '@/mixins/iconsMixin'
import setting from "@element-plus/icons-vue/dist/es/setting.mjs";

export default {
    name: 'ReportIndex',
    data() {
        return {
            loading: false,
            selectReport: null,
            selectSettings: {
                setup: false,
                moduleId: null,
                dateStart: null,
                dateEnd: null,
                recipient: null,
                sender: null,
                company: null,
            },
            modules: null,
            availableReports:[],
            availableFunctionReports:[],
        }
    },
    components: {
        FormSelect,
        FormDateTimePicker,
        ReportMenu,
        Auth,
    },
    inject: [
        'api'
    ],
    mixins: [mobileCheckMixin, iconsMixin],
    created() {
        this.getAccountModules()
        this.userAvailableReports()
        this.userAvailableFunctionReports()
        this.selectSettings.dateStart = moment().startOf('day')
        this.selectSettings.dateEnd = moment().endOf('day')
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies',
            reports: 'getReports',
            userReportAccess: 'getUserReportAccess',
        }),
    },
    methods: {
        setting() {
            return setting
        },
        userAvailableReports() {
            this.availableReports = this.reports.filter(report =>
                this.userReportAccess.some(access => report.id === access.report_id)
            );
        },
        userAvailableFunctionReports() {
            this.availableFunctionReports = this.reports.filter(report =>
                this.userReportAccess.some(access => report.id === access.report_id)
            );
        },
        getAccountModules() {
            this.loading = true
            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.modules = data.modules

            })
        },
        resetReport() {
            this.selectReport = null
            this.selectSettings = {
                setup: false,
                moduleId: null,
                dateStart: null,
                dateEnd: null,
                recipient: null,
                sender: null,
            }
        },
        changePeriod(period) {
            if (period === 'today') {
                var todayStart = moment().toDate()

                todayStart.setHours(0)
                todayStart.setMinutes(0)
                todayStart.setSeconds(0)
                todayStart.setMilliseconds(0)
                this.selectSettings.dateStart = todayStart
            }

            if (period === 'currentWeek') {
                this.selectSettings.dateStart = moment().startOf('isoweek')
            }

            if (period === 'currentMonth') {
                this.selectSettings.dateStart = moment().startOf('month')
            }

            this.selectSettings.dateEnd = moment().toDate()
        },
        doSelectReport(report) {
            this.selectReport = report

            if (this.modules.filter(module => module.module_key === this.selectReport.module_key).length === 1) {
                this.selectSettings.moduleId = this.modules.find(module => module.module_key === this.selectReport.module_key).id
            }
        },
        filterModules(key) {
            if (this.modules) {
                return this.modules.filter(module => module.module_key === key)
            } else {
                return []
            }
        },
        generateReport() {
            if (!this.selectSettings.moduleId && (this.selectReport.report_key !== 'mixDrivers')) {
                return this.$message({
                    message: 'Выберите модуль',
                    showClose: true,
                    type: 'error'
                })
            }
            if (!this.selectSettings.dateStart) {
                return this.$message({
                    message: 'Выберите дату начала периода',
                    showClose: true,
                    type: 'error'
                })
            }
            if (!this.selectSettings.dateEnd) {
                return this.$message({
                    message: 'Выберите дату окончания периода',
                    showClose: true,
                    type: 'error'
                })
            }

            switch (this.selectReport.report_key) {
                case 'weighingValues':
                    this.$router.push({name: 'ReportWeightValues', query: this.selectSettings});
                    return;
                case 'mixOrders':
                    this.$router.push({name: 'ReportMixOrders', query: this.selectSettings});
                    return;
                case 'goods':
                    this.$router.push({name: 'ReportGoods', query: this.selectSettings});
                    return;
                case 'mixDrivers':
                    this.$router.push({name: 'ReportMixDrivers', query: this.selectSettings});
                    return;
                case 'mixVehicles':
                    this.$router.push({name: 'ReportVehicles', query: this.selectSettings});
                    return;
                case 'mixGroupOrders':
                    this.$router.push({name: 'ReportMixGroupOrders', query: this.selectSettings});
                    return;
                case 'productionGoods':
                    this.$router.push({name: 'ReportProductionGoods', query: this.selectSettings});
                    return;
            }

            // this.selectSettings.setup = true
        }
    },
}
</script>

<style scoped>

</style>
