<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
    >
        <path
            d="M8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5Z"
            stroke="#1984e6"
            stroke-width="1.2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M5.3335 8.5H10.6668"
            stroke="#1984e6"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 11.1666L8 5.83325"
            stroke="#1984e6"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: "PlusIcon",
};
</script>
