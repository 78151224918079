<template>
    <el-tooltip placement="bottom-end" effect="light" :disabled="!mixOrders.length" :show-after="100">
        <template #content>
            <div style="width: 420px">
                <table class="el-table__body w-100" cellspacing="0" cellpadding="0" border="0">
                    <thead>
                    <tr>
                        <th>
                            <div class="cell textXS textRegular neutral200 pb-15 pl-5">
                                Пост
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Отгрузки
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15">
                                Объем
                            </div>
                        </th>
                        <th style="width: 70px">
                            <div class="cell textXS textRegular neutral200 pb-15 pr-5">
                                % загрузки
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(mixer, index) in mixers" :key="index" class="hover-row">
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5 cutOverflow no-wrap pl-5">
                                {{ mixer.name ?? '-' }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textRegular neutral900 pt-2-5 pb-2-5">
                                {{ mixer.count ?? '-' }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium primary600 pt-2-5 pb-2-5">
                                {{ formatNumber(mixer.total, 2) }} м³
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium success400 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(mixer.percent, 2) }}%
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <hr style="border-top: 1px solid #c9ccd0; margin: 10px 0;"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pl-5">
                                Итого
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5">
                                {{ allCount }}
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(allTotal, 2) }} м³
                            </div>
                        </td>
                        <td>
                            <div class="cell textXS textMedium neutral900 pt-2-5 pb-2-5 pr-5">
                                {{ formatNumber(allPercent, 2) }}%
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <div class='d-flex content-center pl-10 pr-10 summary-order-groups-container'>
            <div :style="`min-width: ${windowWidth < 1280 ? 'max-content' : '100px'}`">
                <div class="order-groups">
                    <img :src="require('@/assets/building.svg')" class="order-groups__icon"/>
                    <div class="order-groups__info">
                        <div class="order-groups__info__title">
                            <div class="d-flex">
                                <AnimateNumber :count="allPercent"/>
                                <span class="ml-5">%</span>
                            </div>
                        </div>
                        <div class="order-groups__info__desc">
                            Посты
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'opacity-0-1': mixersCount === 0 }" :style="{ opacity: mixersCount > 0 ? 1 : 0.2 }"
                 class="transition-opacity duration-500">
                <SummaryMixersCountBar :mixersHourlyLoads="mixersHourlyLoads" :hours="hours"/>
            </div>
        </div>
    </el-tooltip>
</template>

<script>
import AnimateNumber from "@/views/components/AnimateNumber.vue";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import SummaryMixersCountBar from "@/views/dispatch/components/summaryBlocks/SummaryMixersCountBar.vue";
import moment from 'moment/moment'
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";

export default {
    name: "SummaryMixersCount",
    components: {SummaryMixersCountBar, AnimateNumber},
    mixins: [functionsMixin, mobileCheckMixin],
    props: {
        hours: Array,
        mixOrders: Array
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
        }),
        mixersData() {
            return this.modules.find(el => el.accountModuleId == this.$route.params.id)?.mixers ?? []
        },
        mixersCount() {
            return this.mixersData.length;
        },
        allTotal() {
            return this.mixers.reduce((acc, status) => acc + status.total, 0)
        },
        allCount() {
            return this.mixers.reduce((acc, status) => acc + status.count, 0)
        },
        mixers() {
            let mixersAll = []

            this.mixersData.forEach(mixer => {
                let mixOrdersByMixer = this.mixOrders.filter(order => order.mixer_id == mixer.id)
                let mixOrdersByMixerTotal = mixOrdersByMixer.reduce((acc, order) => acc + order.total, 0)

                let minStartAt = moment.min(this.mixOrders.map(order => moment(order.start_at)))
                let maxReturnAt = moment.max(this.mixOrders.map(order => moment(order.return_at)))
                let minutesMinMax = maxReturnAt.diff(minStartAt, 'minutes')

                let minutesMixerLoading = mixOrdersByMixer.reduce((acc, order) => {
                    let loadTime = moment(order.load_at).diff(moment(order.start_at), 'minutes')
                    return acc + loadTime
                }, 0)

                let percent = Math.round((minutesMixerLoading / minutesMinMax) * 100)

                let hoursValues = this.hours.map(hour => hour.value);
                let hourlyLoads = hoursValues.map(hour => {
                    let ordersInHour = mixOrdersByMixer.filter(order => {
                        let startHour = moment(order.start_at).hour()
                        let loadHour = moment(order.load_at).hour()
                        return (startHour < hour && loadHour >= hour) || startHour === hour
                    })

                    let loadTimeInHour = ordersInHour.reduce((acc, order) => {
                        let startTime = moment(order.start_at)
                        let loadTime = moment(order.load_at)
                        let hour = startTime.hour()

                        let hourStart = startTime.clone().hour(hour).minute(0).second(0)
                        let hourEnd = startTime.clone().hour(hour).minute(59).second(59)

                        let intersectStart = startTime.isAfter(hourStart) ? startTime : hourStart
                        let intersectEnd = loadTime.isBefore(hourEnd) ? loadTime : hourEnd

                        let minutesInHour = intersectEnd.diff(intersectStart, 'minutes')

                        return acc + minutesInHour
                    }, 0)

                    let loadPercent = Math.round((loadTimeInHour / 60) * 100)

                    return {
                        hour: hour,
                        loadTimeInHour: loadTimeInHour,
                        loadPercent: loadPercent,
                        ordersInHour: ordersInHour
                    }
                })

                mixersAll.push({
                    id: mixer.id,
                    mixId: mixer.mix_id,
                    name: mixer.name,
                    count: mixOrdersByMixer.length,
                    total: mixOrdersByMixerTotal,
                    percent: percent,
                    hourlyLoads: hourlyLoads
                })
            })

            return mixersAll.sort((a, b) => a.name.localeCompare(b.name))
        },
        mixersHourlyLoads() {
            const hourlyLoads = this.hours.map(hour => {
                const mixerLoads = this.mixers.map(mixer => {
                    const hourlyLoad = mixer.hourlyLoads.find(load => load.hour === hour.value);
                    return hourlyLoad ? hourlyLoad.loadPercent : 0;
                }).filter(load => load !== 0);

                const averageLoad = mixerLoads.length > 0 ? mixerLoads.reduce((a, b) => a + b, 0) / mixerLoads.length : 0;

                return {
                    value: Math.round(averageLoad)
                };
            });

            return hourlyLoads;
        },
        allPercent() {
            const nonZeroPercentValues = this.mixers.filter(mixer => mixer.percent !== 0).map(mixer => mixer.percent);
            return nonZeroPercentValues.length === 0 ? 0 : Math.round(nonZeroPercentValues.reduce((a, b) => a + b, 0) / nonZeroPercentValues.length);
        }
    },
    methods: {}
}
</script>

<style scoped lang="scss">

</style>
