<template>
    <DrawerFramework :loading="loading">
        <template v-slot:body>
            <DrawerHeadTitle title="Добавить технику на обслуживание"/>

            <el-form v-model="newTechForm" ref="form" size="small" label-position="top">

                <FormInput
                    v-model="newTechForm.name"
                    label="Удобное название техники"
                    placeholder="Н-р: погрузчик CAT желтый"
                    :required="true"
                />

                <FormInputSuggestions
                    v-if="!loading"
                    v-model="newTechForm.number"
                    label="Гос номер ТС"
                    placeholder="Введите номер"
                    :items="vehicles"
                    property="number"
                />

                <FormNumber
                    v-model="newTechForm.fuelTank"
                    label="Объем топливного бака (л.)"
                    placeholder="Н-р: 250"
                />

                <FormNumber
                    v-model="newTechForm.fuelTotal"
                    label="Текущий остаток топлива"
                    placeholder="0"
                />


            </el-form>
        </template>
        <template v-slot:footerOneButton>
            <DrawerCreateButton :loading="loading" title="Добавить технику" @action="createNewTech"/>
        </template>
    </DrawerFramework>
</template>

<script>
import DrawerFramework from '@/views/components/Drawer/DrawerFramework'
import DrawerHeadTitle from '@/views/components/Drawer/DrawerHeadTitle'
import FormInput from '@/views/components/Form/FormInput'
import DrawerCreateButton from '@/views/components/Drawer/Buttons/DrawerCreateButton'
import {httpService} from '@/services/http.service'
import FormInputSuggestions from '@/views/components/Form/FormInputSuggestions'
import FormNumber from "@/views/components/Form/FormNumber";
import iconsMixin from "@/mixins/iconsMixin";

export default {
    name: 'FuelVehicleCreate',
    components: {FormNumber, FormInputSuggestions, DrawerCreateButton, FormInput, DrawerHeadTitle, DrawerFramework},
    mixins: [iconsMixin],
    data() {
        return {
            loading: true,
            newTechForm: {
                name: null,
                number: null,
                fuelTotal: null,
                fuelTank: null
            },
            vehicles: []
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.getVehicles()
    },
    methods: {
        getVehicles() {
            httpService().post(this.api.vehicle.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.vehicles
                this.loading = false

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loading = false
            })
        },
        createNewTech() {
            httpService().post(this.api.fuel.createVehicle, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'fuel',
                vehicle: this.newTechForm
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.newTechForm = {
                        name: null,
                        number: null,
                        fuelTotal: null,
                        fuelTank: null
                    }
                this.$message({
                    message: 'Техника добавлена',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('closeDrawer')
                this.$emit('moduleGet')

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
