import { mapState } from 'pinia'
import { useCommonStore } from '@/store/common'

export default {
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        })
    },
    methods: {
        getUnit(goods) {
            // получение общей единицы измерения
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index
            }

            var units = []
            goods.forEach(function (element) {
                if (element.good.unit) units.push(element.good.unit.short_name)
            })

            units = units.filter(onlyUnique)

            if (units.length === 0 || units.length > 1) {
                return 'ед.'
            } else {
                return units[0]
            }
        },
        getUnitFromIds(goods) {
            // получение общей единицы измерения из массива, состоящий из id грузов
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index
            }

            if (Array.isArray(goods)) {

                var units = []
                goods.forEach((element) => {
                    let goodUnit = this.goods.find((el => el.id === element))
                    if (goodUnit) {
                        if (goodUnit.unit) units.push(goodUnit.unit)
                    }
                })

                units = units.filter(onlyUnique)

                if (units.length === 1) {
                    return units[0]
                } else {
                    return 'ед.'
                }
            } else {
                return 'ед.'
            }

        },
    }
}
