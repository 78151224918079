export default {
    data() {
        return {
            faqQuestions: [{
                title: 'Как настроить интеграцию с 1С Бухгалтерия',
                module: 'cashbox',
                key: 'how-set-up-integration-with-1C-accounting',
                popular: true,
            }, {
                title: 'Операции: создание, редактирование и удаление',
                module: 'cashbox',
                key: 'how-create-new-operations',
                popular: false,
            }, {
                title: 'Как изменить баланс и значение счета по умолчанию',
                module: 'cashbox',
                key: 'how-edit-default-account',
                popular: false,
            }, {
                title: 'Как пригласить нового пользователя',
                module: 'settings',
                key: 'how-invite-new-user',
                popular: true,
            }, {
                title: 'Как добавить модуль и ссылку в меню',
                module: 'settings',
                key: 'how-create-new-module',
                popular: true,
            }, {
                title: 'Как добавить рецепты',
                module: 'mix',
                key: 'how-add-new-mix-goods',
                popular: false,
            }],
        }
    },
}
