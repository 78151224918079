<template>
    <Auth :title="moduleName" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">

                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Сводка" name="info">
                        <CashboxInfoTab
                            :tab="activeTab"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="Операции" name="operations">
                        <CashboxOperationsTab
                            :tab="activeTab"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="Счета" name="accounts">
                        <CashboxAccountsTab
                            :tab="activeTab"
                        />
                    </el-tab-pane>
                </el-tabs>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from './../layouts/Auth'
import functionsMixin from '../../mixins/functionsMixin'
import tableMixin from '../../mixins/tableMixin'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import basicMixin from '../../mixins/basicMixin'
import {httpService} from '@/services/http.service'
import CashboxOperationsTab from "@/views/components/CashboxPage/CashboxOperationsTab";
import CashboxAccountsTab from "@/views/components/CashboxPage/CashboxAccountsTab";
import CashboxInfoTab from "@/views/components/CashboxPage/CashboxInfoTab";

export default {
    name: 'CashboxShow',
    data() {
        return {
            activeTab: 'info',
            cashboxId: this.$route.params.id,
            moduleName: null,
            integrations: [],
            relations: [],
        }
    },
    components: {CashboxInfoTab, CashboxAccountsTab, CashboxOperationsTab, Auth},
    inject: ['api'],
    mixins: [functionsMixin, tableMixin, mobileCheckMixin, basicMixin,],
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true
            this.loadingButton = true
            this.drawerDetails = false
            httpService().post(this.api.cashbox.get, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'cashbox',
                tab: null
            }).then((response) => {
                let data = response.data

                if (data.success)
                this.moduleName = data.cashbox.modules.name
                this.loading = false
                this.loadingButton = false
                this.integrations = data.integrations
                this.relations = data.relations
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            }).finally(() => {
                this.loading = false
                this.loadingButton = false
            })
        },
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.activeTab = 'info'
                this.moduleGet()
            }
        }
    },
}
</script>

<style scoped>

</style>
