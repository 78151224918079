<template>
    <div v-if="order" class="mix-order-group-actions">
        <el-row :gutter="20" class="mix-order-group-actions__details">
            <el-col :sm="8" v-if="order.deliveryType === 'delivery'">
                <MixOrderGroupActionsMap :factories="factories" :address="address" :coords="coords"
                                         :vehicles="vehicles"/>
                <div
                    class="textSM neutral300 textRegular"
                    v-if="loadingVehicles"
                    style="position: relative; top: 10px;"
                >
                    Загрузка GPS-данных по транспортным средствам...
                </div>
            </el-col>
            <el-col class="mix-order-group-actions__info" :sm="order.deliveryType === 'delivery' ? 16 : 24">
                <el-row :gutter="20" class="pt-15 pb-15">
                    <el-col :sm="16">
                        <h2 class="neutral900 textMedium mt-0 mb-0">Заказ № {{ order.id }}</h2>
                    </el-col>
                    <el-col :sm="8">
                        <div class="d-flex d-flex-end">
                            <el-tooltip
                                raw-content
                                effect="dark"
                                content="Перераспределить"
                                placement="top"
                            >
                                <el-button @click="distributeOrder" :icon="icons.switch"/>
                            </el-tooltip>

                            <el-tooltip
                                raw-content
                                effect="dark"
                                content="Редактировать"
                                placement="top"
                                :show-after="200"
                            >
                                <el-button @click="editOrder" :icon="icons.edit"/>
                            </el-tooltip>
                        </div>
                    </el-col>
                </el-row>

                <OrderQuantity :without-width="true" :orders="order.orders ?? []" :total="order.total"/>

                <hr class="mb-15 mt-15"/>

                <el-row :gutter="20">
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Контрагент"
                            :text="order.company ?? '-'"
                        />
                        <CRMInfoText
                            head="Представитель"
                            :text="companyManagerText"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Тип доставки"
                            :text="order.deliveryType === 'delivery' ? 'Доставка' : 'Самовывоз'"
                        />
                        <CRMInfoText
                            head="Время первой доставки"
                            :text="order.firstOrderTimeDelivery ? moment.unix((order.firstOrderTimeDelivery)).utc().tz(this.moduleTimezone).format('HH:mm') : '-'"
                        />
                    </el-col>
                    <el-col :sm="12">
                        <CRMInfoText
                            head="Продукция"
                            :text="order.good ?? '-'"
                        />
                    </el-col>
                    <el-col :sm="12" v-if="order.deliveryType === 'delivery'">
                        <CRMInfoText
                            head="Адрес доставки"
                            :text="order.address ?? '-'"
                        />
                    </el-col>
                </el-row>
                <el-tabs class="mix-order-group-actions__tabs" v-model="tab">
                    <el-tab-pane label="Отгрузки" name="orders">
                        <!-- max-height="50vh" -->
                        <el-scrollbar class="mix-order-group-actions__orders">
                            <ManageDeliveriesOrders :hidden-footer="true"
                                                    :order-group-item="order" @update="$emit('get')"
                                                    :orders="order.orders"/>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="Документы" name="docs">
                        <PrintDocs :order-group-item="order" :orders="order.orders"/>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import CRMInfoText from "@/views/components/MixOrderGroup/components/order/CRMInfoText.vue";
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";
import OrderQuantity from "@/views/dispatch/components/OrderQuantity.vue";
import iconsMixin from "@/mixins/iconsMixin";
import ManageDeliveriesOrders from "@/views/dispatch/components/actions/ManageDeliveriesOrders.vue";
import PrintDocs from "@/views/dispatch/components/PrintDocs.vue";
import MixOrderGroupActionsMap
    from "@/views/components/MixOrderGroup/components/orderGroup/MixOrderGroupActionsMap.vue";
import {httpService} from "@/services/http.service";

export default {
    name: "MixOrderGroupActions",
    components: {MixOrderGroupActionsMap, PrintDocs, ManageDeliveriesOrders, OrderQuantity, CRMInfoText},
    mixins: [functionsMixin, iconsMixin],
    props: ['orderGroup'],
    inject: ['api'],
    computed: {
        moment() {
            return moment
        },
        factories() {
            if (this.order && this.order.orders) {
                return [...new Map(this.order.orders.map(order => [
                    `${order.mix.latitude},${order.mix.longitude}`,
                    {
                        latitude: parseFloat(order.mix.latitude),
                        longitude: parseFloat(order.mix.longitude)
                    }
                ])).values()]
            }
            return [];
        },
        address() {
            if (this.order) {
                return {
                    name: this.order.address,
                    latitude: parseFloat(this.order.latitude),
                    longitude: parseFloat(this.order.longitude)
                }
            } else {
                return null
            }
        },
        coords() {
            if (this.order) {
                return [parseFloat(this.order.latitude), parseFloat(this.order.longitude)]
            } else {
                return null
            }
        },
        companyManagerText() {
        let text = ''
            if(this.order.companyManager) {
                text = this.order.companyManager.name

                if(this.order.companyManager.phone) {
                    text += ` (${this.formatPhoneNumber(this.order.companyManager.phone)})`
                }
            } else {
                text = '-'
            }

            return text
        }
    },
    data() {
        return {
            tab: 'orders',
            order: null,
            vehicles: [],
            loadingVehicles: true,
            windowHeight: window.innerHeight
        }
    },
    created() {
        if (this.order) {
            this.getMapData()

            this.$nextTick(function () {
                this.resizeOrdersComponent()
            })
        }
    },
    mounted: function () {
        window.addEventListener('resize', this.resizeOrdersComponent);
    },
    beforeUnmount: function () {
        window.removeEventListener('resize', this.resizeOrdersComponent);
    },
    methods: {
        getMapData() {
            httpService().post(this.api.dispatch.orderGroups.getMapData, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                order_group_id: this.order.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.vehicles = data.values
                    this.loadingVehicles = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loadingVehicles = false
            })
        },
        editOrder() {
            this.$router.push(
                {
                    name: 'DispatchOrderGroupsUpdate',
                    params: {
                        id: this.$route.params.id,
                        groupId: this.orderGroup.id,
                    }
                }
            );
        },
        distributeOrder() {
            this.$router.push({
                    name: 'ReDistributeOrderGroup',
                    params: {
                        id: this.$route.params.id,
                        groupId: this.orderGroup.id,
                    }
                }
            );
        },
        resizeOrdersComponent() {
            const dialogElement = document.querySelector('.group-order-action-dialog__actions > .el-dialog__body');
            const infoElement = document.querySelector('.mix-order-group-actions__info');
            const ordersElement = document.querySelector('.mix-order-group-actions__orders > .el-scrollbar__wrap');
            const tabsElement = document.querySelector('.mix-order-group-actions__tabs > .el-tabs__header');

            const dialogHeight = dialogElement?.offsetHeight || 0;
            const tabsHeight = tabsElement?.offsetHeight || 0;

            const elementsHeight = Array.from(infoElement.children)
                .filter(el => !el.classList.contains('mix-order-group-actions__tabs'))
                .reduce((total, item) => total + item.offsetHeight, 0);

            // 30 - высота элемента hr с margin, который не учитывается в offsetHeight
            const availableHeight = dialogHeight - elementsHeight - tabsHeight - 30;

            if (ordersElement) {
                ordersElement.style.maxHeight = `${availableHeight}px`;
            }
        },
    },
    watch: {
        orderGroup: {
            handler() {
                this.order = this.orderGroup
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.mix-order-group-actions {
    height: 100%;

    &__details {
        height: 100%;
    }

    &__info {
        display: flex !important;
        flex-direction: column;
    }

    &__tabs {
        flex: 1;
        display: flex;
        flex-direction: column;

        &:deep(.el-tabs__header) {
            margin: 0;
            padding: 0 0 15px;
        }

        &:deep(.el-tabs__content) {
            flex: 1;
            .el-tab-pane {
                height: 100%;
            }
        }
    }

    &__orders {
        min-height: initial;

        &:deep(.el-scrollbar__wrap) {
            .el-scrollbar__view {
                height: 100%;

                .manage-deliveries {
                    height: 100%;

                    &__orders {
                        &--empty {
                            height: 100%;
                            min-height: initial;
                        }
                    }
                }
            }
        }
    }
}
</style>
