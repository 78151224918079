<template>
    <div class="text-center mt-30">
        <ReportLoader/>
        <h3>{{ currentLoadingTitle }}...</h3>
        <p class="textXS textRegular neutral300">Это может занять некоторое время (примерно {{ time }})...</p>
    </div>
</template>

<script>
import ReportLoader from "@/views/components/Loaders/ReportLoader.vue";

export default {
    name: "DistributeLoader",
    components: {ReportLoader},
    props: ['total'],
    data() {
        return {
            loadingTitles: [
                "Идет загрузка",
                "Определяем местоположение миксеров",
                "Проверяем загрузку заводов",
                "Запрашиваем пробки с Яндекс.Карт",
                "Рисуем красивый график",
                "Учитываем пожелания клиента по интервалам доставки"
            ],
            currentLoadingTitle: "Формирование графика на день"
        }
    },
    mounted() {
        this.startLoadingAnimation()
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    computed: {
        time() {
            let total = this.total ?? 30
            let time = Math.round(total * 0.05)

            if (time < 59) {
                return `${time} сек`
            } else {
                return `${Math.floor(time / 60)} мин ${time % 60} сек`
            }
        }
    },
    methods: {
        startLoadingAnimation() {
            let index = 0;
            this.counterInterval = setInterval(() => {
                this.currentLoadingTitle = this.loadingTitles[index];
                index = (index + 1) % this.loadingTitles.length;
            }, 1150);
        },
    }
}
</script>

<style scoped>

</style>
