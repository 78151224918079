<template>
    <el-form-item :label="label ?? 'Продукция'">
        <el-select
            class="custom-select"
            v-model="goodId"
            :placeholder="placeholder ?? 'Выберите продукцию'"
            :disabled="createModal"
            filterable
            clearable
        >
            <el-option
                value=""
                label=""
                class="newItemOption"
                @click="openModalCreate"
            >
                <AddRoundIcon/>
                <span>Создать новый</span>
            </el-option>
            <el-option
                v-for="(good, index) in goodsList"
                :key="index"
                :label="good.name"
                :value="good.id"
            ></el-option>
        </el-select>
    </el-form-item>

    <el-dialog
        v-model="createModal"
        title="Добавление новой продукции"
        :width="drawerSize">

        <el-form ref="form"
                 :rules="rules"
                 v-loading="loading"
                 :model="createGoodForm"
                 label-width="120px"
                 destroy-on-close
                 size="medium"
                 label-position="top">

            <el-form-item label="Название" prop="name" required>
                <el-input type="text" v-model="createGoodForm.name"
                          placeholder="Щебень 0-20 мм."></el-input>
            </el-form-item>

            <el-form-item label="Артикул">
                <el-input type="text" v-model="createGoodForm.sku"
                          placeholder="0000931235"></el-input>
            </el-form-item>

            <el-form-item label="Ед. измерения">
                <el-select v-model="createGoodForm.unit"
                           placeholder="Выберите единицу измерения">
                    <el-option
                        v-for="unit in units"
                        :key="unit.id"
                        :label="unit.short_name"
                        :value="unit.id">
                        <span style="float: left">{{ unit.short_name }}</span>
                        <span
                            style="float: right; color: #a2a5bf; font-size: 13px;">{{ unit.name }}</span
                        >
                    </el-option>
                </el-select>
            </el-form-item>

        </el-form>

        <template #footer>
          <span class="dialog-footer">

            <el-button @click="closeCreateModal">
                Отмена
            </el-button>

            <el-button type="primary" :loading="loading" @click="doCreateGood('form')">
                Добавить продукцию
            </el-button>

          </span>
        </template>
    </el-dialog>
</template>

<script>
import AddRoundIcon from '../Icons/AddRoundIcon'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import { mapState } from 'pinia/dist/pinia'
import { useCommonStore } from '@/store/common'
import { httpService } from '@/services/http.service'
import {mapActions} from "pinia";

export default {
    name: 'FormSelectGoods',
    components: { AddRoundIcon },
    props: [
        'modelValue',
        'goods',
        'label',
        'placeholder'
    ],
    mixins: [
        mobileCheckMixin
    ],
    data() {
        return {
            goodId: this.modelValue,
            createModal: false,
            createGoodForm: {
                name: '',
                sku: '',
                unit: '',
            },
            loading: false,
            units: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Введите название продукции',
                        trigger: 'blur',
                        type: 'string'
                    },
                ],
            }
        }
    },
    inject: [
        'api'
    ],
    created() {
        this.getUnits()
    },
    computed: {
        ...mapState(useCommonStore, {
            goodsFromStore: 'getGoods'
        }),
        goodsList() {
            return this.goods ?? this.goodsFromStore
        }
    },
    methods: {
        ...mapActions(useCommonStore, [
            'setGoods'
        ]),
        closeCreateModal() {
            this.createModal = false;
        },
        openModalCreate() {
            this.createModal = true
        },
        getUnits() {
            this.loading = true
            httpService().post(this.api.good.getUnits).then((response) => {
                let data = response.data

                if (data.success)
                    this.units = data.units

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
            })

            this.loading = false
        },
        doCreateGood(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    httpService().post(this.api.good.create, {
                        module: this.createGoodForm,
                    }).then((response) => {
                        let data = response.data

                        if (data.success) {
                            this.createGoodForm = {
                                name: '',
                                sku: '',
                                unit: '',
                            }
                        }

                        this.setGoods(data.goods);
                        this.createModal = false
                        this.$nextTick(() => {
                            this.goodId = data.goodId;
                        });

                        this.$message({
                            message: 'Продукция добавлена',
                            showClose: true,
                            type: 'success'
                        });
                        this.loading = false
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    })
                } else {
                    this.$message({
                        message: 'Необходимо заполнить все обязательные поля',
                        type: 'error',
                        showClose: true,
                    })
                    return false
                }
            })


        },
    },
    watch: {
        'goodId': {
            handler: function () {
                this.$emit('update:modelValue', this.goodId)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.goodId = this.modelValue
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
