<template>
    <div class="lineInfo">
        <div class="lineInfo__name">
            {{ name ?? '' }}
            <slot name="name"></slot>
        </div>
        <div class="lineInfo__line"></div>
        <div class="lineInfo__desc">
            {{ desc ? sliceTest(desc) : '' }}
            <slot name="desc"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DrawerTextLineItem",
    props: [
        'name',
        'desc'
    ],
    methods: {
        sliceTest(text) {
            text = String(text)
            var sliced = text.slice(0,50);
            if (sliced.length < text.length) {
                sliced += '...'
            }
            return sliced
        }
    }
}
</script>

<style scoped>

</style>
