<template>
    <div class="chart-block">
        <VueEcharts
            ref="chart"
            :option="option"
        />
    </div>
</template>

<script>
import VueEcharts from "vue-echarts";
import functionsMixin from "@/mixins/functionsMixin";
import {mapState} from "pinia";
import {useOrderGroupsStore} from "@/store/orderGroups";

export default {
    name: "SummaryMixersCountBar",
    components: {VueEcharts},
    mixins: [functionsMixin],
    props: {
        mixersHourlyLoads: Array,
        hours: Array
    },
    mounted() {
        this.chart = this.$refs.chart;
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
        }),
        values() {
            return this.mixersHourlyLoads.map(hour => {
                if (hour.value > 80) {
                    return { ...hour, itemStyle: { color: '#1984e6' } };
                } else if (hour.value >= 30 && hour.value <= 80) {
                    return { ...hour, itemStyle: { color: '#49a7fd' } };
                } else {
                    return { ...hour, itemStyle: { color: '#b8ddfe' } };
                }
            });
        },
        option() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}:00 - {c}% загруженность'
                },
                grid: {
                    left: '10%',
                    right: '0%',
                    bottom: '5%',
                    top: '0%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.hours,
                    axisLabel: {
                        color: '#c9ccd0' // Add this line to change the label color to red
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#8a909a' // Change the line color to red
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    max: 100,
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        data: this.values,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: '#F9F9F9'
                        }
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
.chart-block {
    width: 230px;
    height: 60px;
    margin-bottom: -5px;

    @media screen and (max-width: 1433px) {
        width: 180px;
        padding-right: 12px;
    }

    @media screen and (max-width: 1024px) {
        width: 160px;
        margin-block: 0px;
    }

    @media screen and (max-width: 930px) {
        width: 160px;
    }

    @media screen and (max-width: 880px) {
        width: 200px;
    }

    @media screen and (max-width: 630px) {
        width: 160px;
    }

    @media screen and (max-width: 480px) {
        width: 150px;
        padding-right: 0;
    }

    @media screen and (max-width: 440px) {
        width: 250px;
        height: 60px;
    }

    @media screen and (max-width: 360px) {
        width: 210px;
    }
}
</style>
